var ConfigSupplierCodeMatchingsController = (function(){

    var instance
        ,loader;

    function createInstance() {

        var addHandlers = function () {
            var wrapper = $('.js-cms-config-suppliercode-matchings');
            wrapper.on('submit', '.js-cms-config-form-suppliercode-matchings', onSubmit);
            wrapper.on('click', '.btn-reset-suppliercode-matchings', onReset);
            wrapper.find('#OpenAddCheckboxListItemDropDown').on('click', onClickOpenCheckBoxListItemDropDown );
            wrapper.find('#AddCheckboxListItemBtn').on('click', onClickAddCheckBoxListItem );
        };

        var checkBoxListItemAlreadyExist = function(list, id) {
            var exist = false;
            $.each(list.find('li'), function(){
                if ($(this).attr('id') === id) {
                    exist = true;
                    return false;
                }
            });
            return exist;
        };

        var onClickOpenCheckBoxListItemDropDown = function(e) {
            var btn = $(e.currentTarget)
                ,parent = btn.parent()
                ,select = parent.find('select')
                ,input = parent.find('input');
            if (select.length > 0) {
                select.select2('val', null);
            }
            if (input.length > 0) {
                input.val(null);
            }
        };

        var onClickAddCheckBoxListItem = function(e) {
            e.preventDefault();
            var btn = $(e.currentTarget)
                ,name = btn.data('name')
                ,input  = btn.parent().parent().find('input')
                ,value = input.val()
                ,parent = btn.parents('.extendablecheckboxlist')
                ,list = parent.find('.dd-list');

            if (value  && !checkBoxListItemAlreadyExist(list, name+'_li_'+value)) {
                list.prepend(
                    $('<li/>', {
                        'class' : 'dd-item config-checkbox-list',
                        'id' : name + '_li_' + value
                    })
                        .append(
                            $('<div/>', {'class':'dd-handle'})
                                .append(
                                    $('<div/>', {
                                        'class': 'checkbox dd-item__checkbox checkbox-primary checkbox-item'
                                    })
                                        .prepend(
                                            $('<input/>', {
                                                'name':name + '[]',
                                                'id': name + '_' + value,
                                                'type' : 'checkbox',
                                                'value' : value,
                                                'checked': 'checked'
                                            })
                                        ).append(
                                        $('<label/>', {
                                            'for': name + '_' + value,
                                            'text': ' '+value
                                        })
                                    )
                                )
                        )
                );
            }
            parent.find('#OpenAddCheckboxListItemDropDown').click();
            return false;
        };

        var onReset = function(e) {
            e.preventDefault();
            var btn = $(this)
                ,loaderContainer = btn.parents('.js-cms-config-suppliercode-matchings');
            $.ajax({
                url: Routing.generate('cms_config_resetsuppliercodematchings')
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "resetSupplierCodeMatchings";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
            })
            .done(function(data) {
                if (data.view) {
                    btn.parents('.js-config-formwrapper').html(data.view);
                }
            });
        };


        var onSubmit = function(e) {
            e.preventDefault();
            var form = $(this)
                ,loaderContainer = form.parents('.js-cms-config-suppliercode-matchings')
                ,inputFields = form.find('input')
                ,supplierCodeMatchings = []
                ,url = Routing.generate('cms_config_savesuppliercodematchings');

            $.each($(inputFields).serializeArray(), function (idx, kV) {
                if (kV.value !== '') {
                    supplierCodeMatchings.push(kV.value);
                }
            });

            $.ajax(url, {
                data: {
                    supplierCodeMatchings: supplierCodeMatchings
                },
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "saveConfigSupplierCodeMatchings";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
                ,method: 'POST'
            })
            .done(function(data){
                if (data.success === false) {
                    toastr.error(
                        Translator.trans('def.term.error', {}, 'cms'),
                        Translator.trans('config.section.suppliercodematchings.save.errormessage', {}, 'cms')
                    );
                }
                if (data.success === true) {
                    loaderContainer.find('.btn-reset-suppliercode-matchings').click();
                    toastr.success(
                        Translator.trans('def.term.success', {}, 'cms'),
                        Translator.trans('config.section.suppliercodematchings.save.successmessage', {}, 'cms')
                    );
                }
            });
        };

        var init = function() {
            loader = Loader.getInstance();
            addHandlers();
        };

        return {
            init: init
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();