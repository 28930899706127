/**
 * Manages Twin View/New/Edit Text Field Panel and respective options
 *
 */
var CompareTextDetailsController = (function(){

    var instance;

    var INTERNALTO = getInternalSupplier();
    var newElement = false;

    var ctlanguages = [];
    var ctlanguageleftindex = 0;
    var ctlanguagerightindex = 0;
    var cttouroperators = [];
    var ctactiveleftto = 0;
    var ctactiverightto = 1;

    var internalTexts = {};

    var ctactivelefttexts;
    var ctactiverighttexts;

    var ctgroupid;
    var ctleftarea;
    var ctrightarea;

    var scriptedtrigger = false;

    var langCC = {};

    /**
     * self explanatory - creates Instance of CompareTextDetailsController
     *
     * @return CompareTextDetailsController
     */
    var createCTDInstance = function(){

        /**
         * Adding Event Handlers to specified DOM Elements and Events
         *
         * @return void
         */
        var addEventHandlers = function(){

            $('#CompareTextAreas .closeComparePanelButton').on('click', onCloseCompareClickHandler);
            $('#CompareTextAreas .languagebar li').on('click', onChoseLanguageClickHandler);
            $('#CompareTextAreas .supplierbar button:not(.btn-create-new-language)').on('click', onChoseTOClickHandler);
            $('#CompareTextAreas .btn-masterselect').on('click', onMasterselectClickHandler);
            $('#CompareTextAreas .btn-saveint').on('click', onSaveClickHandler);

            $('#CompareTextAreas .btn-poptexts').on('click', onPopupButtonClickHandler);

            $('#CompareTextAreas textarea').on('focusout', onTextFieldFocuslostHandler);

            $('#TextCompareAndMasterForm table tbody tr input.i-checks').on('ifChanged', onMatrixTableChangeHandler);
            $('#SyncronizeTextFieldsCheckbox').on('change', onToggleChangeHandler);

            $('#CompareTextAreas select').on('change',onCategorySelectHandler);

            //EditSingleText

        };

        /**
         * Updates Twin Textfield Options by changes in / on TourOperator/Languages Matrix< Table
         *
         * @param Event e                       the (jQuery enriched) change event
         * @return void
         */
        var onMatrixTableChangeHandler = function (e){

            if(scriptedtrigger){
                scriptedtrigger = false;
                return;
            }

            var $btn = $("#CompareTextAreas .btn-masterselect");

            var to = $(this).data('tocode');
            var lang = $(this).data('lang');

            var tablechecked = $(this).prop('checked');
            $btn.each(function(){
                if($(this).attr('data-language') == lang && $(this).attr('data-tocode') == to && !$(this).hasClass("disabled")){

                    if(tablechecked && !$(this).hasClass('masterselected')){
                        $(this).removeClass('btn-default').addClass('btn-success masterselected');
                        $(this).find('span.fa').removeClass('fa-square-o').addClass('fa-check-square-o');
                    }else if($(this).hasClass('masterselected') && !tablechecked){
                        $(this).removeClass('masterselected btn-success').addClass('btn-default');
                        $(this).find('span.fa').removeClass('fa-check-square-o').addClass('fa-square-o');
                    }
                }
            });
        };

        /**
         * sets secondary textfield to the firsts language if on-toggled
         *
         * @param Event e                       the toggles change event
         * @return void
         */
        var onToggleChangeHandler = function(e){
            if(true === $(this).prop('checked')){

                $('#CompareTextAreas .languagebar li.active').removeClass('active');
                $('#CompareTextAreas .languagebar').find('[data-languageid='+ctlanguageleftindex+']').addClass('active');

                ctlanguagerightindex = ctlanguageleftindex;
                setTextDisplays("right");

            }
        };

        /**
         * Manages text-popup window
         *
         * @param Event e                       the (jQuery enriched) click event
         * @return void
         */
        var onPopupButtonClickHandler = function(e){

            var currentContent = '';

            if($(this).hasClass('btn-popleft')){
                currentContent = ctleftarea.val();
            }else{
                currentContent = ctrightarea.val();
            }

            var data = {
                content: currentContent
            };

            var url = Routing.generate("cms_contentstore_compare_texts_popup", {data: data});

            $.ajax(url, {
                success: function(data, status, xhr) {
                    var win=window.open('about:blank');

                    var doc = win.document;
                        doc.open();
                        doc.write();
                        doc.close();
                }
            });

        };

        /**
         * renders selected categories respective text from stored object
         *
         * @param Event e                     onChange Event (jQuery)
         * @return void
         */
        var onCategorySelectHandler = function(e){

            var selectedcategory    = $(this).val();
            var selectedside        = $(this).attr('id').split('_')[1];

            var rtext;
            var area;

            if("right" == selectedside){
                rtext = ctactiverighttexts[selectedcategory];
                area = ctrightarea;
            }else{
                rtext = ctactivelefttexts[selectedcategory];
                area = ctleftarea;
            }

            if("object" == typeof(rtext)){
                var t = "";
                var cnt = 0;
                for(var k in rtext){
                    t += rtext[k];
                    if(cnt > 0 && "" !== rtext[k].trim()){
                        t += "\n\n\n";
                    }
                    cnt++;
                }
                rtext = t;
            }

            area.val(rtext);

        };

        /**
         * saves / updates Text if "internal" (= editable) Edf exists or creates complete a new one
         *
         * @param Event e                       the (jQuery enriched) click event
         * @return void
         */
        var onSaveClickHandler = function(e){

            if($(this).hasClass('disabled')){
                return;
            }

            var groupid = $('.basicdata-detailchart').find('[data-name="groupid"]').text();
            var giataid = $('.basicdata-detailchart').find('[data-name="giataid"]').text();
            var goalid = $('.basicdata-detailchart').find('[data-name="goalid"]').text();

            var isGoal = (groupid == goalid);

            var data, url;

            var alertBlock = $('<div/>',{
                class: "alert alert-success",
                text: " "+Translator.trans('contentstore.general.feedback.update.success', {}, 'cms')
            }).prepend($('<i>',{ class: "fa fa-bolt" }));

            if(newElement){

                var id = (isGoal)?goalid:giataid;

                if(0 === $('#CompareTextAreas .alert-danger').length){
                    createUnsavedContentpop('#CompareTextAreas div.panel-heading');
                }

                // trimming
                var trimObject = {};
                for(var k in internalTexts){
                    if("" !== internalTexts[k] && 'undefined' != typeof(internalTexts[k])){
                        trimObject[k] = internalTexts[k];
                    }
                }

                data = new Object({
                    "Name": $('.basicdata-detailchart').find('[data-name="name"]').text(),
                    "dlc": $('.basicdata-detailchart').find('[data-name="airport"]').text(),
                    "Id": id,
                    "descriptions": internalTexts,
                    "preventexistcheck": true,
                    "isgoal": isGoal
                });

                if(0 < $('.basicdata-detailchart').find('[data-name="key"]').length){
                    data.key = $('.basicdata-detailchart').find('[data-name="key"]').text();
                }

                $('#CompareTextAreas').append($('<div/>',{ class: "loadblock" }));
                url = Routing.generate("cms_contentstore_create_edf_send", {data: data});

                $.ajax(url, {
                    success: function(data, status, xhr) {
                        var key = $('.basicdata-detailchart').find('[data-name="key"]').text(),
                            supplier = $('.basicdata-detailchart').find('[data-name="supplier"]').text(),
                            productcode = $('.basicdata-detailchart').find('[data-name="product"]').text(),
                            groupid = $('.basicdata-detailchart').find('[data-name="groupid"]').text();

                        $('.loadblock').remove();

                        if(0 < $('#CompareTextAreas .unsavedcontentpop').length){
                            CmsContentStore.getInstance().doUnregisterWarningMessage("textAreaUpdates");
                        }

                        alertBlock.insertAfter('#CompareTextAreas div.panel-heading');
                        alertBlock.hide().slideDown().delay(2000).slideUp(200, function(){$(this).remove();});


                        if(key){
                            CmsContentStore.getInstance().loadProductDetailPageByKey(supplier, productcode)
                                .done(function(){
                                    resetValues();
                                    $('#EditCompareText').click();
                                });
                        } else {
                            CmsContentStore.getInstance().loadProductDetailPageByGroup(groupid)
                                .done(function(){
                                    resetValues();
                                    $('#EditCompareText').click();
                                });
                        }

                    }
                });

            }else{

                // determine location
                var key = $('.basicdata-detailchart').find('[data-name="key"]').text();

                data = new Object({
                   groupid: groupid,
                   tocode: INTERNALTO,
                   key: key,
                   descriptions: internalTexts
                });

                $('#CompareTextAreas').append($('<div/>',{ class: "loadblock" }));
                url = Routing.generate("cms_contentstore_update_description");

                // ajax call redundant - need revisiting
                $.ajax(url, {
                    method: "POST",
                    data: data,
                    success: function(data, status, xhr) {
                        var key = $('.basicdata-detailchart').find('[data-name="key"]').text(),
                            supplier = $('.basicdata-detailchart').find('[data-name="supplier"]').text(),
                            productcode = $('.basicdata-detailchart').find('[data-name="product"]').text(),
                            groupid = $('.basicdata-detailchart').find('[data-name="groupid"]').text();
                        $('.loadblock').remove();

                        if(0 < $('#CompareTextAreas .unsavedcontentpop').length){
                            CmsContentStore.getInstance().doUnregisterWarningMessage("textAreaUpdates");
                        }

                        alertBlock.insertAfter('#CompareTextAreas div.panel-heading');
                        alertBlock.hide().slideDown().delay(2000).slideUp(200, function(){$(this).remove();});

                        if(key){
                            CmsContentStore.getInstance().loadProductDetailPageByKey(supplier, productcode)
                                .done(function(){
                                    resetValues();
                                    $('#EditCompareText').click();
                                });
                        } else {
                            CmsContentStore.getInstance().loadProductDetailPageByGroup(groupid)
                                .done(function(){
                                    resetValues();
                                    $('#EditCompareText').click();
                                });
                        }


                    }
                });

            }

        };

        /**
         * creates new "internal" edf
         *
         * @param Event e                       the (jQuery enriched) click event
         * @return void
         */
        var onCreateNewClickHandler = function(e){

            $('#CompareTextAreas .supplierbar button.btn-create-new-language').remove();

            cttouroperators.push(INTERNALTO);
            var supplierbar = $('#CompareTextAreas .supplierbar');

            var button = $('<button/>',{
                class: "btn btn-primary btn-outline btn-sm ",
                "data-toindex": cttouroperators.length-1,
                click: onChoseTOClickHandler
            });

            newElement = true;

            //internalTexts
            /*for(var i=0; i<ctlanguages.length;i++){
                internalTexts[ctlanguages[i]] = "";
            }*/

            button.text(cttouroperators[cttouroperators.length-1]);
            supplierbar.append(button);
            supplierbar.append(" ");
            button.click();

        };

        /**
         * selects displayed text (touroperator, language and textarea) as master text for the respective group
         *
         * @param Event e                       the (jQuery enriched) click event
         * @return void
         */
        var onMasterselectClickHandler = function(e){

            // switch state

            var to = $(this).attr('data-tocode');
            var lang = $(this).attr('data-language');

            if($(this).hasClass('masterselected')){
                scriptedtrigger = true;
                $(this).removeClass('btn-success masterselected').addClass('btn-default');
                $(this).find('span.fa').removeClass('fa-check-square-o').addClass('fa-square-o');
                $('#TextCompareAndMasterForm table tbody tr').find('[data-tocode='+to+'][data-lang='+lang+']').prop('checked', false);
            }else{
                scriptedtrigger = true;
                $(this).removeClass('btn-default').addClass('btn-success masterselected');
                $(this).find('span.fa').removeClass('fa-square-o').addClass('fa-check-square-o');
                $('#TextCompareAndMasterForm table tbody tr').find('[data-tocode='+to+'][data-lang='+lang+']').prop('checked', true);
            }

        };

        /**
         * selects Touroperator and updates textarea accordingly
         *
         * @param Event e                       the (jQuery enriched) click event
         * @return void
         */
        var onChoseTOClickHandler = function(e){

            $(this).parent().find('button.active').removeClass('active').addClass('btn-outline');
            $(this).removeClass('btn-outline').addClass('active');

            var toindex = $(this).data('toindex'),
                container = $(this).parent().parent();

            // side determining workaround
            if("LeftTextCompareColumn" == container.attr("id")){
                ctactiveleftto = toindex;
                retrieveText(ctgroupid, cttouroperators[ctactiveleftto], ctlanguages[ctlanguageleftindex],ctleftarea);
                crossCheckMasterMatrix(cttouroperators[ctactiveleftto], ctlanguages[ctlanguageleftindex], "left");
                refreshExistingTextLang(cttouroperators[ctactiveleftto], container);
            }else{
                ctactiverightto = toindex;
                retrieveText(ctgroupid, cttouroperators[ctactiverightto], ctlanguages[ctlanguagerightindex],ctrightarea);
                crossCheckMasterMatrix(cttouroperators[ctactiverightto], ctlanguages[ctlanguagerightindex], "right");
                refreshExistingTextLang(cttouroperators[ctactiverightto], container);
            }

        };

        /**
         * selects language and updates textarea accordingly
         *
         * @param Event e                       the (jQuery enriched) click event
         * @return void
         */
        var onChoseLanguageClickHandler = function(e){

            var selectedLanguageId = $(this).data('languageid');

            if( $('#SyncronizeTextFieldsCheckbox').prop('checked') ){

                // $('#CompareTextAreas .languagebar button.btn-info').removeClass('btn-info').addClass('btn-primary');
                // $('#CompareTextAreas .languagebar').find('[data-languageid='+selectedLanguageId+']').removeClass('btn-primary').addClass('btn-info');

                $('#CompareTextAreas .languagebar li.active').removeClass('active');
                $('#CompareTextAreas .languagebar').find('[data-languageid='+selectedLanguageId+']').addClass('active');

                ctlanguageleftindex = ctlanguagerightindex = selectedLanguageId;
                setTextDisplays();

            }else{

                $(this).parent().find('.active').removeClass('active');
                $(this).addClass('active');

                if("LeftTextCompareColumn" == $(this).parent().parent().parent().attr('id')){
                    ctlanguageleftindex = selectedLanguageId;
                    setTextDisplays('left');
                }else{
                    ctlanguagerightindex = selectedLanguageId;
                    setTextDisplays('right');
                }
            }
        };


        /**
         * closes edit/compare window
         *
         * @param Event e                       the (jQuery enriched) click event
         * @return void
         */
        var onCloseCompareClickHandler = function(e){

            if(0 < $('#CompareTextAreas .unsavedcontentpop').length){
                $('#CompareTextAreas .unsavedcontentpop').slideDown();
                return;
            }

            resetValues();
            $('#EditCompareText').removeClass('btn-success').addClass('btn-primary');
            $('#EditSingleText').removeClass('btn-success').addClass('btn-primary');
            $('#CompareTextAreas').remove();
        };


        /**
         * sets touroperator button active / changes classes
         *
         * @param jQueryDOMElement jqe                  the Button DOM Element to be set active
         * @return void;
         */
        var setButtonActive = function(jqe){
            //jqe.removeClass('btn-primary').addClass('btn-info');

            jqe.removeClass('btn-outline').addClass('active');

            return;
        };

        var setTextDisplays = function(orientation){

            if('undefined' == typeof(orientation)){
                orientation = "both";
            }

            if("undefined" === typeof(ctgroupid) || "" === ctgroupid){
                ctgroupid = "#KEY#"+$('.basicdata-detailchart').find('[data-name="key"]').text();
            }



            switch(orientation){
                case "left":
                    retrieveText(ctgroupid, cttouroperators[ctactiveleftto], ctlanguages[ctlanguageleftindex],ctleftarea);
                    crossCheckMasterMatrix(cttouroperators[ctactiveleftto], ctlanguages[ctlanguageleftindex], "left");
                    //refreshExistingTextLang(cttouroperators[ctactiveleftto], orientation);
                    break;
                case "right":
                /* falls through */
                case "both":
                /* falls through */
                default:

                    retrieveText(ctgroupid, cttouroperators[ctactiverightto], ctlanguages[ctlanguagerightindex],ctrightarea);
                    crossCheckMasterMatrix(cttouroperators[ctactiverightto], ctlanguages[ctlanguagerightindex], "right");
                    //refreshExistingTextLang(cttouroperators[ctactiverightto], orientation);
                    if("both" == orientation){
                        setTextDisplays('left');
                    }
                    break;
            }

            return;
        };

        /**
         * registers changes and prepares warning popups
         *
         * @param Event e                             onChange Event
         * @return void
         */
        var onTextFieldChangeHandler = function(e){
            if(0 === $('#CompareTextAreas .alert-danger').length){
                createUnsavedContentpop('#CompareTextAreas div.panel-heading');
            }
        };

        /**
         * creates and renders warning message if unsaved content (registered content) exists
         *
         * @param
         * @return void
         */
        var createUnsavedContentpop = function(targetselect) {
            var ccs = CmsContentStore.getInstance();
                var messagePop = $('<div/>',{
                       class: "alert alert-danger unsavedcontentpop",
                       text: " "+Translator.trans('contentstore.general.feedback.unsavedcontent', {}, 'cms'),
                       "data-name": "textAreaUpdates",
                       click: ccs.getOnClickUnsavedWarningMessageHandler()
                    }).prepend($('<i/>',{ class: 'fa fa-warning' }));

                messagePop.hide();

                var popmessagequeue = ccs.getPopmessagequeue();

                popmessagequeue.textAreaUpdates = messagePop;
                popmessagequeue.count++;

                ccs.setPopmessagequeue(popmessagequeue);

                messagePop.insertAfter(targetselect);
        };


        /**
         * sets basic button states and triggers the textdisplays
         *
         * @return void
         */
        var initTextdisplays = function() {

            var leftToCode = Object.keys(langCC)[0],
                rightToCode = Object.keys(langCC)[0];
            // set active buttons
            $('#CompareTextAreas .languagebar').each(function(){ setButtonActive($(this).find('button').eq(ctlanguageleftindex)); });

            if(0 !== $('#CompareTextAreas .supplierbar').length){
                var leftBtn = $('#CompareTextAreas .supplierbar').eq(0).find('button:first');
                setButtonActive(leftBtn);
                leftToCode = leftBtn.text();
                if(1 <= ctactiverightto){
                    setButtonActive($('#RightTextCompareColumn .supplierbar').find('button').eq(1));
                    rightToCode = $('#RightTextCompareColumn .supplierbar').find('button').eq(1).text();
                }else{
                    setButtonActive($('#RightTextCompareColumn .supplierbar').find('button:first'));
                    rightToCode = $('#RightTextCompareColumn .supplierbar').find('button:first').text();
                }
            }

            ctleftarea = $('#CompareTextAreas textarea:first');
            ctrightarea = $('#CompareTextAreas textarea').eq(1);

            ctleftarea.on('change', onTextFieldChangeHandler);
            ctrightarea.on('change', onTextFieldChangeHandler);

            //ctleftarea.text('retrieving '+cttouroperators[0]+"("+ctlanguages[ctlanguageleftindex]+")");
            //ctrightarea.text('retrieving '+cttouroperators[1]+"("+ctlanguages[ctlanguageleftindex]+")");

            ctgroupid = $('#TextCompareAndMasterForm').data('groupid');

            if(0 < $('.loadblock').length){
                $('.loadblock').remove();
            }
            refreshExistingTextLang(leftToCode, $('#LeftTextCompareColumn'));
            refreshExistingTextLang(rightToCode, $('#RightTextCompareColumn'));
            setTextDisplays();
        };

        /**
         * stores textarea val to local object
         *
         * @param Event e                           the (jquery enriched) focusout Event
         * @return void
         */
        var onTextFieldFocuslostHandler = function(e){

            var temporary_category;

            // $(this).unbind('focusout');
            if('left' == $(this).attr('name')){
                internalTexts = createObjectMemberIfEmpty(internalTexts, ctlanguages[ctlanguageleftindex]);

                temporary_category = setHotelCategoryIfMatched($('#contentcategory_left').val());

                internalTexts[ctlanguages[ctlanguageleftindex]] = createObjectMemberIfEmpty(internalTexts[ctlanguages[ctlanguageleftindex]], temporary_category);
                internalTexts[ctlanguages[ctlanguageleftindex]][temporary_category] = $(this).val();
            }else{

                internalTexts = createObjectMemberIfEmpty(internalTexts, ctlanguages[ctlanguagerightindex]);

                temporary_category = setHotelCategoryIfMatched($('#contentcategory_right').val());

                internalTexts[ctlanguages[ctlanguagerightindex]] = createObjectMemberIfEmpty(internalTexts[ctlanguages[ctlanguagerightindex]], temporary_category);
                internalTexts[ctlanguages[ctlanguagerightindex]][temporary_category] = $(this).val();

            }



        };

        /**
         * rewrites categorystring to Hotel for the updating of internal texts
         *
         * @param String param              category string to be matched
         * @param String match (optional)   value to be matched agains ("empty" will be used as default)
         * @return String                   predefined string or original value
         */
        var setHotelCategoryIfMatched = function(param, match){
            if("undefined" == typeof(match)){
                match = "empty";
            }

            if(match == param){
                return "Hotel";
            }else{
                return param;
            }
        };

        var createObjectMemberIfEmpty = function(obj, member){
            if ("undefined" == typeof(obj[member]) || ( "string" == typeof(obj[member]) && "" === obj[member] )){
                obj[member] = {};
            }
            return obj;
        };


        /**
         * looks for text according to selection (to, lang, side, internal) - internal Texts will be stored and loaded locally
         *
         * @param String groupid                        group identifier (String since Int is not necessary)
         * @param String to                             Chosen Touroperator
         * @param String lang                           Chosen Language
         * @param jQueryDOMElement delement             Matching Textarea
         *
         * @return void
         */
        var retrieveText = function(groupid, to, lang, delement){

            var url = Routing.generate('cms_contentstore_get_description');
            var activeCategory = "Hotel";
            var updateDir = delement.attr('name');

            delement.parent().parent().append($('<div />',{class: 'loadblock'}));

            var isint = (INTERNALTO == to);
            var sbutton = delement.parent().parent().parent().parent().find('.editrow .btn-saveint');

            if(isint){

                sbutton.removeClass('disabled btn-default').addClass('btn-danger');
                sbutton.show();
                delement.prop('disabled',false);
                delement.removeClass('disabled');

                if( 'undefined' != typeof(internalTexts[lang])  && 'undefined' != typeof(internalTexts[lang][activeCategory])){

                    var tCategories = [];

                    if("left" == updateDir){
                        ctactivelefttexts = internalTexts[lang];
                    }else{
                        ctactiverighttexts = internalTexts[lang];
                    }

                    for(var i in internalTexts[lang]){
                        tCategories.push(i);
                    }

                    setCategorySelectBar(tCategories, updateDir);

                    delement.empty();
                    delement.val(internalTexts[lang][activeCategory]);
                    delement.parent().parent().find('.loadblock').remove();

                    return;
                }

            }else{

                sbutton.removeClass('btn-danger').addClass('disabled btn-default');
                sbutton.hide();
                delement.prop('disabled',true);
                delement.addClass('disabled');

            }

            var keyContainer = $('.basicdata-detailchart').find('[data-name="key"]'),
                key = "";

            /*if("string" == typeof(groupid) && groupid.indexOf('#KEY#') > -1){
                // groupid is key
                key = groupid.substr(5);
                groupid = "";
            }*/
            if (keyContainer.length > 0) {
                key = keyContainer.text();
                groupid = "";
            }

            var data = {
                "GroupId": groupid,
                "TourOperatorCode": to,
                "language": lang,
                "Key": key
            };

            $.ajax({
                url: url,
                data: data,
                success: function(data, status, xhr) {

                    delement.empty();
                    delement.parent().parent().find('.loadblock').remove();

                    // get categories
                    var tCategories = [];

                    if(data.description){
                        for(var cat in data.description){
                            tCategories.push(cat);
                        }
                    }else{
                            tCategories.push("empty");
                    }
                    CompareTextDetailsController.getInstance().setCategorySelectBar(tCategories, updateDir);

                    delement.val("");

                    if(data.description) {

                        var ctactivetexts = data.description;
                        //internalTexts[lang] = new Object();

                        if(isint){
                            internalTexts[lang] = ctactivetexts;
                        }

                        if("undefined" == typeof(ctactivetexts[activeCategory])){
                            activeCategory = "empty";
                        }

                        if("string" == typeof(ctactivetexts[activeCategory])){
							delement.val(ctactivetexts[activeCategory]);
						}else{

                            if("undefined" !== typeof(ctactivetexts[activeCategory])){

                                delement.val(ctactivetexts[activeCategory][0]);

                                for(var i=1;i<ctactivetexts[activeCategory].length;i++){
                                    if("" !== ctactivetexts[activeCategory][i].trim()){
                                        delement.val(delement.val()+"\n\n\n"+ctactivetexts[activeCategory][i]);
                                    }
                                }
                            }else{
                                delement.val();
                            }
						}

                        if(updateDir === "left"){
                            ctactivelefttexts = ctactivetexts;
                        }else{
                            ctactiverighttexts = ctactivetexts;
                        }

					} else {
						delement.val(data.error);
                    }

                }
            })
			.fail(function(msg){
				delement.empty();
				delement.parent().parent().find('.loadblock').remove();
				delement.val(msg.error);
			});
        };


        /**
         * refreshes the categoryselector with given list of categories
         *
         * @param Array tCategories                Array of Categories to be displayed currently
         * @param String updateDir                 left/right string input selects side to be updated
         *
         * @return void
         */
        var setCategorySelectBar = function(tCategories, updateDir){

            var selectBar = $('#contentcategory_'+updateDir);
            selectBar.empty();

            for(var i in tCategories){
                var n = $('<option/>',{
                    'key': tCategories[i],
                    text: tCategories[i],
                    selected: (tCategories[i] === "Hotel")?true:false
                });
                selectBar.append(n);
            }
        };

        /**
         * looks up selected (and selectable) master checkboxes in matrixtable and prepares the textfield select-master-buttons accordingly
         *
         * @param String to                             chosen TourOperator
         * @param String lang                           chosen language
         * @param String orientation                    current active side
         *
         * @return void
         */
        var crossCheckMasterMatrix = function(to, lang, orientation){

            var cIdString = (orientation === "left")?'LeftTextCompareColumn':'RightTextCompareColumn';
            var tableresult = $('#TextCompareAndMasterForm table tbody tr').find('[data-tocode='+to+'][data-lang='+lang+']');

            $('#'+cIdString+' .btn-masterselect').removeClass('disabled masterselected btn-success').addClass('btn-default');

            $('#'+cIdString+' .btn-masterselect').find('span.fa').removeClass('fa-check-square-o').addClass('fa-square-o');

            $('#'+cIdString+' .btn-masterselect').attr("data-tocode", to);
            $('#'+cIdString+' .btn-masterselect').attr("data-language", lang);

            $('#'+cIdString+' .btn-masterselect').unbind('click');

            if(tableresult.length === 1){
                if(true === tableresult.prop('checked')){
                    $('#'+cIdString+' .btn-masterselect').removeClass('btn-default').addClass('btn-success masterselected');
                    $('#'+cIdString+' .btn-masterselect').find('span.fa').removeClass('fa-square-o').addClass('fa-check-square-o');
                }
                $('#'+cIdString+' .btn-masterselect').on('click',onMasterselectClickHandler);
            }else{
                $('#'+cIdString+' .btn-masterselect').addClass('disabled').prop('disabled');
            }
        };

        /**
         * fills languagebar with clickable elements / language links  and stores respective languages
         *
         * @return void
         */
        var setLangaugebar = function(){

            $('#contentstore-filter-language option').each(function(){ ctlanguages.push($(this).val()); });
            ctlanguages.shift();

            var langbar = $('#CompareTextAreas .languagebar');

            langbar.empty();

            var linkHandler = function(e){ e.preventDefault(); };

            for(var i=0;i<ctlanguages.length;i++){
                var li = $('<li/>',{
                    class: (i===0)?"active":"",
                   "data-languageid": i
                });

                var link = $('<a/>',{
                    click: linkHandler,
                    text: ctlanguages[i]
                });

                li.append(link);
                langbar.append(li);
            }
        };


        /**
         * fills supplierbar with clickable elements / language links and stores Touroperators locally
         *
         * @return void
         */
        var setTouroperatorbar = function() {

            var internalsActive = false;

            $('#TextCompareAndMasterForm table .cell-tocode').each(function(){
                cttouroperators.push($(this).text());
                if(INTERNALTO === $(this).text()){
                    internalsActive = true;
                }

                /*if($(this).data('internal')){
                    INTERNALTO = $(this).text();
                    internalsActive = true;
                }*/
            });

            var supplierbar = $('#CompareTextAreas .supplierbar');

            if(supplierbar.length === 0){
                ctactiverightto = 0;
                return;
            }

            supplierbar.empty();

            for(var i=0;i<cttouroperators.length;i++){
                var button = $('<button/>',{
                   class: "btn btn-primary btn-outline btn-sm",
                   "data-toindex": i
                });
                button.text(cttouroperators[i]);
                supplierbar.append(button);
                supplierbar.append(" ");
            }

            if(internalsActive){
                return;
            }

            // if no internal efd detected
            var internalEdf = $('<button/>',{
               class: "btn btn-success btn-sm btn-create-new-language",
               "data-toindex": -1,
               click: onCreateNewClickHandler
            });

            var btnGlyph = $('<span/>',{
                class: 'glyphicon glyphicon-plus',
                'aria-hidden': true
            });

            internalEdf.append(btnGlyph);

            supplierbar.append(internalEdf);

            if(cttouroperators.length === 1){
                ctactiverightto = 0;
            }


        };

        var refreshExistingTextLang = function(tocode, container) {
            var langbar = container.find('.languagebar');
                //_tocode = (tocode && langCC.size() > 1)?tocode:Object.keys(langCC)[0];
            $.each(langbar.find('li'), function(){
                var li =  $(this),
                    lang = li.find('a').text();
                li.removeClass('exists');
                $.each(langCC[tocode], function(k,v){
                    if(lang === v) {
                        li.addClass('exists');
                    }
                });
            });
        }


        /**
         * resets variables and empties dom elements
         *
         * @return void
         */
        var resetValues = function(){
            ctlanguages = [];
            cttouroperators = [];
            ctlanguageleftindex = ctlanguagerightindex = 0;
            ctactiveleftto = 0;
            ctactiverightto = 1;
            langCC = {};

            internalTexts = {};

            $('#CompareTextAreas .supplierbar').empty();
            $('#CompareTextAreas .languagebar').empty();

            $('#CompareTextAreas .editrow .btn-masterselect').removeClass('masterselected');

            newElement = false;
        };

        var aggregateExistingTextLangs = function() {

            $.each($('#TextCompareAndMasterForm table').find('tbody tr'), function(k,v){
                tocode = "";
                $.each($(this).find('td'), function(k,v) {
                    if(k === 0) {
                        tocode = $(this).text();
                        langCC[tocode] = [];
                    } else {
                        $.each($(this).data('exitstingtextlangs').split(','), function(k,v){
                           langCC[tocode].push(v);
                        });
                    }
                });
            });
        };

        return {

            /**
             * initializer / triggers basic setup and first calls
             *
             * @return void
             */
            init: function(){
                // loader
                // get/create languages

                resetValues();

                $('#CompareTextAreas input[data-toggle]').bootstrapToggle();
                $('#EditCompareText').removeClass('btn-primary').addClass('btn-success');

                aggregateExistingTextLangs();

                setLangaugebar();
                // get/create TOs
                setTouroperatorbar();
                addEventHandlers();

                initTextdisplays();
            },

            doCreateUnsavedContentpop: createUnsavedContentpop,
            setCategorySelectBar: setCategorySelectBar,

            getDescriptionTexts: function() {
                return internalTexts;
            }
        };
    };

    return {
        getInstance: function(){
            if(!instance){
                instance = createCTDInstance();
            }

            return instance;
        }
    };

})();