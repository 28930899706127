var ImageDetailsRenderer = (function(){

    var instance;

    function createInstance() {

        var options = {
            isCover: 'iscover'
            ,isDuplicateOf: 'isduplicateof'
        };

        function stdRenderFunction(html, imageData) {
            var imageInfo = {
                description: (imageData.hasOwnProperty('name')
                && imageData.name !== '')
                    ? imageData.name
                    : Translator.trans('def.imageinfo.nodescription', {}, 'cms')
                ,iscover: imageData.hasOwnProperty(options.isCover) ? imageData[options.isCover] : 0
                ,isduplicateof: imageData.hasOwnProperty(options.isDuplicateOf) ? imageData[options.isDuplicateOf] : ''
            };

            for (var item in imageInfo) {
                if (!imageInfo.hasOwnProperty(item)
                    || !imageInfo[item]) {
                    continue;
                }
                html += '<h5>' + Translator.trans('def.imageinfo.title.' + item, {}, 'cms') + '</h5>';
                html += '<p>' + imageInfo[item] + '</p>';
            }
            return html;
        }

        function contentRenderFunction(html, imageData) {
            var imageInfo = {
                description: (imageData.hasOwnProperty('name')
                && imageData.name !== '')
                    ? imageData.name
                    : Translator.trans('def.imageinfo.nodescription', {}, 'cms')
                ,iscover: imageData.hasOwnProperty('cover') && imageData['cover'] === 1 ? 'true' : 0
                ,isduplicateof: imageData.hasOwnProperty('isduplicateof') ? imageData['isduplicateof'] : ''
                ,tags: imageData.hasOwnProperty('tags') ? imageData['tags'] : []
                ,category: imageData.hasOwnProperty('category') ? imageData['category'] : ''
            };

            for (var item in imageInfo) {
                if (!imageInfo.hasOwnProperty(item)
                    || !imageInfo[item]) {
                    continue;
                }

                if (item === 'isduplicateof') {
                    html += '<h5>' + Translator.trans('def.imageinfo.title.' + item, {}, 'cms') + '</h5>'
                        + '<span class="imagedetails__detailtextwithbutton" title="' + imageInfo[item] + '">' + imageInfo[item] + '</span>'
                        + ' <button class="btn btn-default btn-xs imagedetails__buttonwithdetailtext imagedetails__detailduplicatebutton js-imagedetails__detailduplicatebutton" type="button" data-duplicateurl="'+ imageInfo[item] +'">'
                        + Translator.trans('def.imageinfo.filterbutton', {}, 'cms') + '</button>';
                    continue;
                }

                if (item === 'tags') {
                    if (imageInfo[item].length === 0) {
                        continue;
                    }
                    html += '<h5>' + Translator.trans('def.imageinfo.title.' + item, {}, 'cms') + '</h5>'
                        + '<ul class="list-inline imagedetails__detailtaglist imagedetails__detail' + item + 'list">';
                    imageInfo[item].forEach(function(element, index, array) {
                        html += '<li class="imagedetails__detailtaglistitem imagedetails__detail' + item + 'listitem">' + element + '</li>';
                    });
                    html +='</ul>';
                    continue;
                }

                if (item === 'iscover') {
                    html += '<h5>' + Translator.trans('def.imageinfo.title.' + item, {}, 'cms') + '</h5>';
                    html += '<p style="color: #00888D"><span class="fa fa-check-circle"></span></p>';
                    continue;
                }

                html += '<h5>' + Translator.trans('def.imageinfo.title.' + item, {}, 'cms') + '</h5>';
                html += '<p>' + imageInfo[item] + '</p>';
            }
            return html;
        }


        function renderImageData(imageData, renderFn) {
            renderFn = (typeof renderFn === 'function') ? renderFn : stdRenderFunction;
            imageData = imageData || false;
            var html = '';

            if (imageData === false) {
                return html;
            }
            return renderFn(html, imageData);
        }

        return {
            renderImageData: renderImageData
            ,contentRenderFunction: contentRenderFunction
        }
    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();
