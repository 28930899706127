/**
*
*	MapDrawWrapper
*
*/
(function(){
    var context = pwNamespace('PEAKWORK.BASIC.MAP');
    context.MapDrawWrapper = function () {
        var self = this;
        this.loader;
        this.loaderId;
        this.mod = {};
        this.markers = {};
        this.markerClusters = {};
        this.markerId = 1;
        this.openMarker = {
            '1': null
            ,'2': null
        };
        this.map = null;
        this.baseLayerControl = null;
        this.baselayers = null;
        this.infoWindow = null;
        this.bounds = null;
        this.boundsCats = null;
        this.zoomBoundsSidebar = null;
        this.mapContainerOpts = null;
        this.mapIcons = {
            HOME : '/bundles/peakworkstorecmsmap/images/mapicons/home.svg'
            ,HOMEBLUE : '/bundles/peakworkstorecmsmap/images/mapicons/home-blue.svg'
            ,HOMEBLUE_ACTIVE : '/bundles/peakworkstorecmsmap/images/mapicons/home-blue_ACTIVE.svg'
            ,HOMEYELLOW : '/bundles/peakworkstorecmsmap/images/mapicons/home-yellow.svg'
            ,HOMEYELLOW_ACTIVE : '/bundles/peakworkstorecmsmap/images/mapicons/home-yellow_ACTIVE.svg'
            ,HOMEGREY : '/bundles/peakworkstorecmsmap/images/mapicons/home-grey.svg'
            ,HOMEGREY_ACTIVE : '/bundles/peakworkstorecmsmap/images/mapicons/home-grey_ACTIVE.svg'
            ,CENTER : '/bundles/peakworkstorecmsmap/images/mapicons/home.svg'
            ,CENTER_ACTIVE : '/bundles/peakworkstorecmsmap/images/mapicons/home_ACTIVE.svg'
            ,CENTER_POLY : '/bundles/peakworkstorecmsmap/images/mapicons/home.svg'
            ,CENTER_POLY_ACTIVE : '/bundles/peakworkstorecmsmap/images/mapicons/home_ACTIVE.svg'
            ,APTCENTER : '/bundles/peakworkstorecmsmap/images/mapicons/HOMEAPT.svg'
            ,APTCENTER_ACTIVE : '/bundles/peakworkstorecmsmap/images/mapicons/HOMEAPT_ACTIVE.svg'
            ,APTCENTER_POLY : '/bundles/peakworkstorecmsmap/images/mapicons/HOMEAPT_POLY.svg'
            ,APTCENTER_POLY_ACTIVE : '/bundles/peakworkstorecmsmap/images/mapicons/HOMEAPT_POLY_ACTIVE.svg'
            ,SEARCH : '/bundles/peakworkstorecmsmap/images/mapicons/search.svg'
            ,SEARCH_ACTIVE : '/bundles/peakworkstorecmsmap/images/mapicons/search_ACTIVE.svg'
            ,BLUECIRCLE: '/bundles/peakworkstorecmsmap/images/mapicons/marker.svg'
            ,BLUECIRCLE_ACTIVE: '/bundles/peakworkstorecmsmap/images/mapicons/marker_ACTIVE.svg'
            ,MATCHEDAPTRELEVANT: '/bundles/peakworkstorecmsmap/images/mapicons/APT-relevant.svg'
            ,MATCHEDAPTRELEVANT_POLY: '/bundles/peakworkstorecmsmap/images/mapicons/APT-relevant_POLY.svg'
            ,MATCHEDAPTRELEVANT_ACTIVE: '/bundles/peakworkstorecmsmap/images/mapicons/APT-relevant_ACTIVE.svg'
            ,MATCHEDAPTRELEVANT_POLY_ACTIVE: '/bundles/peakworkstorecmsmap/images/mapicons/APT-relevant_POLY_ACTIVE.svg'
            ,MATCHEDAPTPOTENTIAL: '/bundles/peakworkstorecmsmap/images/mapicons/APT-potential.svg'
            ,MATCHEDAPTPOTENTIAL_POLY: '/bundles/peakworkstorecmsmap/images/mapicons/APT-potential_POLY.svg'
            ,MATCHEDAPTPOTENTIAL_ACTIVE: '/bundles/peakworkstorecmsmap/images/mapicons/APT-potential_POLY_ACTIVE.svg'
            ,MATCHEDAPTPOTENTIAL_POLY_ACTIVE: '/bundles/peakworkstorecmsmap/images/mapicons/APT-potential_POLY_ACTIVE.svg'
            ,SEARCHAIRPORT: '/bundles/peakworkstorecmsmap/images/mapicons/APT.svg'
            ,SEARCHAIRPORT_POLY: '/bundles/peakworkstorecmsmap/images/mapicons/APT_POLY.svg'
            ,SEARCHAIRPORT_ACTIVE: '/bundles/peakworkstorecmsmap/images/mapicons/APT_ACTIVE.svg'
            ,SEARCHAIRPORT_POLY_ACTIVE: '/bundles/peakworkstorecmsmap/images/mapicons/APT_POLY_ACTIVE.svg'
            ,SEARCHGOAL: '/bundles/peakworkstorecmsmap/images/mapicons/hotel.svg'
            ,SEARCHGOAL_ACTIVE: '/bundles/peakworkstorecmsmap/images/mapicons/hotel_ACTIVE.svg'
            ,EDF: '/bundles/peakworkstorecmsmap/images/mapicons/hotel.svg'
            ,EDF_ACTIVE: '/bundles/peakworkstorecmsmap/images/mapicons/hotel_ACTIVE.svg'
            ,HOTELGREEN: '/bundles/peakworkstorecmsmap/images/mapicons/hotel-green.svg'
            ,HOTELGREEN_ACTIVE: '/bundles/peakworkstorecmsmap/images/mapicons/hotel-green_ACTIVE.svg'
            ,HOTELBLUE: '/bundles/peakworkstorecmsmap/images/mapicons/hotel-blue.svg'
            ,HOTELBLUE_ACTIVE: '/bundles/peakworkstorecmsmap/images/mapicons/hotel-blue_ACTIVE.svg'
            ,HOTELYELLOW: '/bundles/peakworkstorecmsmap/images/mapicons/hotel-yellow.svg'
            ,HOTELYELLOW_ACTIVE: '/bundles/peakworkstorecmsmap/images/mapicons/hotel-yellow_ACTIVE.svg'
            ,HOTELGREY: '/bundles/peakworkstorecmsmap/images/mapicons/hotel-grey.svg'
            ,HOTELGREY_ACTIVE: '/bundles/peakworkstorecmsmap/images/mapicons/hotel-grey_ACTIVE.svg'
        };
        this.buttons = [];
        this.defaults = {
            baseUrl: "",
            mapPanel: '',
            maparea:'.flexmap__map',
            showCenterMarker: false,
            fullscreenButton: true,
            bounds: {
                default: ['center']
            },
            zoom: 5,
            center: L.latLng(51.2241, 6.7765), //new google.maps.LatLng(50, -13),
            centerMarkerOptions: {
                zIndex: 100
                ,pwCategory: 'center'
                ,pwCategoryId: 'home'
                ,pwMarkerIcon: 'HOME'
            },
            iconSize: {
                x: '26'
                ,y: '42'
            },
            noClear: true,
            mapTypeControl: true,
            // TODO: leaflet Control
            /*mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.TOP_CENTER
            },
            streetViewControl: true,
            streetViewControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
            },
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
            },*/
            scaleControl: true,
            scrollwheel: false,
            styles: [{
                featureType: "poi.business",
                elementType: "all",
                stylers: [
                  { visibility: "off" }
                ]
            }],
            defaultPolygonStyle: {
                fillColor: '#000'
                ,strokeColor: '#000'
                ,strokeWeight: 2
            }
            ,markerDefaults: {
                draggable: false
                ,optimized: false
            }
            ,modules: null
            ,baseModuleOptions: {}
            ,baseModules: {
                infowindow: {
                    namespace: 'PEAKWORK.MAP'
                    ,class: 'MapInfowindow'
                    ,options: {
                    }
                }
                ,mapbuttons: {
                    namespace: 'PEAKWORK.MAP'
                    ,class: 'MapButtons'
                    ,options: {
                    }
                }
            }
        };

        // Create options by extending defaults with the passed in arguments
        if (arguments[0] && typeof arguments[0] === "object") {
          this.options = $.extend({}, this.defaults, arguments[0]);
        } else {
            this.options = this.defaults;
        }
        this.options.baseModules = $.extend(true, {}, this.options.baseModules, this.options.baseModuleOptions);
        init.call(this, this.options || this.defaults);

    };

    context.MapDrawWrapper.prototype.constructor = context.MapDrawWrapper;

    var init = function(options) {
        // var _ = this;
        this.loader= Loader.getInstance();
        if (!options.center
            && options.hasOwnProperty('longitude')
            && options.hasOwnProperty('latitude')) {
            options.center = [parseFloat(options.latitude), parseFloat(options.longitude)];
        }
        this.map = L.map($(options.mapPanel + ' ' + options.maparea)[0]).setView(options.center, options.zoom);

        this.baselayers = {
            'standard': L.tileLayer('https://api.maptiler.com/maps/abcdbdfb-7c9c-436a-b648-4697195b8170/{z}/{x}/{y}.png?key=gK15hXrAid6X7aDRvWIC', {
                attribution: '<a href="https://www.maptiler.com/license/maps/" target="_blank">© MapTiler</a> ' +
                    '<a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
                maxZoom: 23
            }),
            'satellit': L.mapboxGL({
                attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
                accessToken: 'not-needed',
                style: 'https://api.maptiler.com/maps/73330873-fd5a-43e9-8d53-7dcaf6dfd02f/style.json?key=gK15hXrAid6X7aDRvWIC',
                maxZoom: 23
            })
        };

        var overlays = {};

        // TODO: comment why control.layers must to be overritten in this case
        L.Control.Layers.NeverDisable = L.Control.Layers.extend({
            _checkDisabledLayers: function(){}
        });
        this.baseLayerControl = new L.Control.Layers.NeverDisable(this.baselayers, overlays);
        this.baseLayerControl.addTo(this.map);
        this.baselayers['standard'].addTo(this.map);
        this.map.scrollWheelZoom.disable();

        // TODO: comment why "move"-event must be triggered
        this.map.on('baselayerchange', e => {
            const map = e.target;
            map.fire('move');
        });

        this.mapContainer = $(this.map.getContainer()).closest('.flexmap');
        this.mapId = resolveMapId.call(this);

        // TODO: what is this doing????
        /*this.map.setStyle(function(feature) {
            if (feature.getProperty('pwPolygonStyle')) {
                return (feature.getProperty('pwPolygonStyle'));
            } else {
                return (options.defaultPolygonStyle);
            }
        });*/
        this.centerMarkerId = null;

        // Backwards compatibility
        if (this.options.fullscreenButton) {
            this.options.baseModules.mapbuttons.options['fullscreenButton'] = true;
        }

        addHandler.call(this);

        if (this.options.baseModules) {
            initModules.call(this, this.options.baseModules);
        }

        if (this.options.modules) {
            initModules.call(this, this.options.modules);
        }

        if (this.options.showCenterMarker) {
            this.options.centerMarkerOptions.position = options.center;
            this.addBoundsCat('center', false, options.bounds.default);
            this.centerMarkerId = this.addMarker(this.options.centerMarkerOptions);
            if (options.centerMarkerOptions.hasOwnProperty('pwInfowindowContent')) {
                this.openMarkerTooltip(this.getMarker(this.options.centerMarkerOptions.pwCategory,this.centerMarkerId),this.options.centerMarkerOptions.pwInfowindowContent);
            }
        }
    };

    var resolveMapId = function() {
        var id = this.mapContainer.attr('id') || false;
        if (!id) {
            id = $.uuid();
            setMapId.call(this, id);
        }
        return id;
    };

    var setMapId = function(id) {
        this.mapContainer.attr('id', id);
    };

    var extendDefaults = function(source, properties) {
        var property;
        for (property in properties) {
          if (properties.hasOwnProperty(property)) {
            source[property] = properties[property];
          }
        }
        return source;
    };

    var initModules = function(moduleOptions) {
        var self = this;
        moduleOptions = moduleOptions || false;
        if (!moduleOptions) {
            return;
        }
        for(var moduleName in moduleOptions) {
            if (!moduleOptions.hasOwnProperty(moduleName)
                || !moduleOptions[moduleName].hasOwnProperty('class')
                || self.mod.hasOwnProperty(moduleOptions[moduleName].class) ) {
                continue;
            }
            var mCtx = pwNamespace(moduleOptions[moduleName].namespace);
            if (!mCtx.hasOwnProperty(moduleOptions[moduleName].class)) {
                continue;
            }
            //self.modules[moduleName] = mCtx[moduleOptions[moduleName].class];
            //self.modules[moduleName].init.call(self);
            var options = {};
            if (moduleOptions[moduleName].hasOwnProperty('options')) {
                options = moduleOptions[moduleName].options;
            }
            self.mod[moduleOptions[moduleName].class] = new mCtx[moduleOptions[moduleName].class](self, options);
        }
    };

    var addHandler = function() {
        $.unsubscribe('center_marker.map.' + this.mapId);
        $.subscribe('center_marker.map.' + this.mapId, this.centerMarker.call(this));
        $.unsubscribe('center_default.map.' + this.mapId);
        $.subscribe('center_default.map.' + this.mapId, this.centerDefault.call(this));
    };

    context.MapDrawWrapper.prototype.getMapId = function() {
        return this.mapId;
    };

    context.MapDrawWrapper.prototype.centerDefault = function() {
        var self = this;
        return function(e, data) {
            data = data.hasOwnProperty('bounds') ? data.bounds : self.options.bounds.default;
            self.centerMap(false, false, data);
        };
    };

    context.MapDrawWrapper.prototype.replaceMarkerContent = function(marker, content) {
        if (!marker && !marker.hasOwnProperty('pwInfowindowContent')) {
            return;
        }

        marker.pwInfowindowContent = content;

        if (marker.hasOwnProperty('pwInfoWindow')) {
            marker.pwInfoWindow.setContent(content);
        }

        return true;
    };

    context.MapDrawWrapper.prototype.createInfoWindow = function(content) {
        if (!this.infoWindow) {
            this.infoWindow = L.popup();
        }
        this.infoWindow.setContent(content);
        return this.infoWindow;
    };

    context.MapDrawWrapper.prototype.closeInfoWindow = function(marker) {
        marker = marker || null;
        if (marker && marker.hasOwnProperty('id')) {
            this.restoreIcon(marker);
            if (this.mod.hasOwnProperty('MapInfowindow')) {
                this.mod.MapInfowindow.clearInfoWindow(marker.options.pwInfowindowCol);
            } else {
                marker.options.pwInfowindow.close();
            }
            this.closePolygon(marker);
            if (this.openMarker[marker.options.pwInfowindowCol] === marker) {
                this.openMarker[marker.options.pwInfowindowCol] = null;
            }
        }
    };

    context.MapDrawWrapper.prototype.centerMarker = function() {
        var self = this;
        return function(e, data) {
            if (!data.hasOwnProperty('marker')) {
                return;
            }
            var zoomLevel = (data.hasOwnProperty('zoomLvl')) ? data.zoomLvl : self.options.zoom;
            var marker = data.marker;
            self.zoomToSingleMarker(zoomLevel, marker.getLatLng());
            if (!marker.pwMarkerIsOpen) {
                marker.fire('click');
            }

        };
    };

    context.MapDrawWrapper.prototype.zoomToSingleMarker = function(zoomLvl, pos) {
        let zoom = this.map.getZoom();
        if (zoom < zoomLvl) {
            zoom = zoomLvl;
        }
        this.map.setView(pos, zoom);
    };

    context.MapDrawWrapper.prototype.reduceMapInfoWindow = function() {
        if (this.mod.hasOwnProperty('MapInfowindow')) {
            this.mod.MapInfowindow.reduceMapInfoWindow();
        }
    };

    context.MapDrawWrapper.prototype.openMarkerTooltip = function(marker) {
        if (!marker) {
            return;
        }
        if (!marker.options.hasOwnProperty('pwInfowindowCol')) {
            marker.options.pwInfowindowCol = 1;
        }
        if (this.openMarker[marker.options.pwInfowindowCol]) {
            this.restoreIcon(this.openMarker[marker.options.pwInfowindowCol]);
            if (this.mod.hasOwnProperty('MapInfowindow')) {
                this.mod.MapInfowindow.clearInfoWindow(this.openMarker[marker.options.pwInfowindowCol].options.pwInfowindowCol);
            } else {
                this.openMarker[marker.options.pwInfowindowCol].options.pwInfowindow.close();
            }
            this.closePolygon(this.openMarker[marker.options.pwInfowindowCol]);
            if (this.openMarker[marker.options.pwInfowindowCol] === marker) {
                this.openMarker[marker.options.pwInfowindowCol] = null;
                return;
            }
            this.openMarker[marker.options.pwInfowindowCol] = null;
        }
        if (this.mod.hasOwnProperty('MapInfowindow')) {
            //var content = infoWindow.getContent();
            this.mod.MapInfowindow.setInfoWindow(marker,marker.options.pwInfowindowContent);
        } else {
            if (marker.options.hasOwnProperty(pwInfowindow) && marker.options.pwInfowindow) {
                marker.options.pwInfowindow.open(this.map, marker);
            }
        }
        this.openMarker[marker.options.pwInfowindowCol] = marker;
        this.showPolygon(marker);
        this.activeIcon(marker);
    };

    context.MapDrawWrapper.prototype.getIconSize = function(marker) {
        var iconSizeX = this.options.iconSize.x;
        var iconSizeY = this.options.iconSize.y;

        if (marker.options.hasOwnProperty('pwMarkerIconSize')) {
            iconSizeX = marker.options.pwMarkerIconSize.x;
            iconSizeY = marker.options.pwMarkerIconSize.y
        }
        return [iconSizeX, iconSizeY];
    };

    context.MapDrawWrapper.prototype.restoreIcon = function(marker) {
        if (!marker.options.hasOwnProperty('pwMarkerIcon')) {
            return;
        }
        marker.setIcon(L.icon({
            iconUrl: this.options.baseUrl + this.mapIcons[marker.options.pwMarkerIcon]
            ,iconSize: this.getIconSize(marker)
        }));
    };

    context.MapDrawWrapper.prototype.activeIcon = function(marker) {
        if (!marker.options.hasOwnProperty('pwMarkerIcon')) {
            return;
        }
        if (this.mapIcons.hasOwnProperty(marker.options.pwMarkerIcon + '_ACTIVE')) {
            marker.setIcon(L.icon({
                iconUrl: this.options.baseUrl + this.mapIcons[marker.options.pwMarkerIcon + '_ACTIVE']
                ,iconSize: this.getIconSize(marker)
            }));

        }
    };

    context.MapDrawWrapper.prototype.showPolygon = function(marker) {
        if (!marker.options.hasOwnProperty('pwPolygon') || !marker.options.pwPolygon) {
            return;
        }

        if (marker.options.hasOwnProperty('pwOpenPolygons') && marker.options.pwOpenPolygons) {
            // var self = this;
            // $.each(marker.pwOpenPolygons,function(k,v){
            //     self.map.data.remove(marker.pwOpenPolygons[k]);
            // });
            // marker.pwOpenPolygons = null;
            return;
        }
        var geoJson = {
            type: 'Feature'
            ,geometry: marker.options.pwPolygon
            ,properties: {}
        };
        if (marker.options.hasOwnProperty('pwPolygonStyle')) {
            geoJson.properties.pwPolygonStyle = marker.options.pwPolygonStyle;
        }
        marker.options.pwOpenPolygons = L.geoJSON(geoJson,'poly' + marker.id).addTo(this.map);
    };

    context.MapDrawWrapper.prototype.closePolygon = function(marker) {
        if (!marker.options.hasOwnProperty('pwPolygon') || !marker.options.pwPolygon) {
            return;
        }

        if (marker.options.hasOwnProperty('pwOpenPolygons') && marker.options.pwOpenPolygons) {
            $.each(marker.options.pwOpenPolygons._layers, function(k,v){
                v.remove();
            });
            marker.options.pwOpenPolygons = null;
        }
    };

    context.MapDrawWrapper.prototype.destoryInfoWindow = function() {
        if (!this.infoWindow) {
            return false;
        }
        this.infoWindow = null;
    };

    context.MapDrawWrapper.prototype.panTo = function(pos) {
        this.map.panTo(pos);
    };

    context.MapDrawWrapper.prototype.getCoord = function(lat, lng) {
        return [parseFloat(lat), parseFloat(lng)];
    };

    context.MapDrawWrapper.prototype.addListener = function(event, callback) {
        return this.map.on(event, callback);
    };

    context.MapDrawWrapper.prototype.removeListener = function(listener) {
        return this.map.off(listener);
    };

    context.MapDrawWrapper.prototype.centerMap = function(center, resizeMap, categories) {
        resizeMap = resizeMap || false;
        center = center || this.options.center;
        var idx;
        categories = categories || false;

        if (!categories) {
            categories = [];
            $.each(this.markers,function(k,v){
                categories.push(k)
            })
        }

        if (!$.isArray(categories)) {
            categories = [categories];
        }

        var tempcat = [];
        for (idx in categories) {
            if(this.bounds && this.bounds.hasOwnProperty(categories[idx])) {
                tempcat.push(categories[idx]);
            }
        }

        categories = tempcat;

        if (resizeMap) {
            this.map.invalidateSize(true);
        }

        if(categories.length === 1 && categories[0] === 'center') {
            this.map.setView(center, this.options.zoom);
            return;
        }

        if (categories.length === 0) {
            return;
        } else if (categories.length === 1) {
            this.map.fitBounds(this.bounds[categories[0]]);
        } else {
            const tmpBounds = []
            for (idx in categories) {
                tmpBounds.push(this.bounds[categories[idx]]);
            }
            this.map.fitBounds(tmpBounds);
        }
    };
    
    context.MapDrawWrapper.prototype.removeBounds = function() {
        this.bounds = null;
    };

    context.MapDrawWrapper.prototype.refreshBounds = function(categories) {
        categories = categories || false;

        if (!categories) {
            categories = [];
            $.each(this.markers,function(k,v){
                categories.push(k)
            })
        }

        if (!$.isArray(categories)) {
            categories = [categories];
        }

        if(!this.bounds) {
            this.bounds =  {};
        }
        for (var category in categories) {
            for(markerId in this.markers[category]) {
                if(!this.bounds.hasOwnProperty(category)) {
                    this.bounds[category] = [];
                }
                this.bounds[category].push(
                    [this.markers[category][markerId].position[0],
                    this.markers[category][markerId].position[1]
                ]);
            }
        }

    };

    context.MapDrawWrapper.prototype.addBoundsCat = function(category, label, zoomBounds) {

        if (!this.mod.hasOwnProperty('MapSidebar')) {
                return;
        }

        category = category || false;
        label = label || Translator.trans('map.sidebar.zoombounds.cat.' + category, {}, 'cms');
        zoomBounds = zoomBounds || false;

        if (!category) {
            return;
        }
        
        if(!this.boundsCats) {
            this.boundsCats =  {};
        }

        this.boundsCats[category] = {
            category: category
            ,label: label
            ,zoomBounds: zoomBounds
            ,visible: false
            ,active: false
        };
    };


    context.MapDrawWrapper.prototype.showBoundsCat = function(category) {
        if (!this.mod.hasOwnProperty('MapSidebar')) {
            return;
        }

        category = category || false;

        if (!category) {
            return;
        }

        if(!this.boundsCats) {
            this.boundsCats =  {};
        }

        if (this.boundsCats.hasOwnProperty(category)
            && !this.boundsCats[category].visible) {
            this.boundsCats[category].visible = true;
            $.publish('showCat.zoomBounds.map.' + this.mapId, this.boundsCats[category])
        }
    };

    context.MapDrawWrapper.prototype.hideBoundsCat = function(category) {
        if (!this.mod.hasOwnProperty('MapSidebar')) {
            return;
        }

        category = category || false;

        if (!category) {
            return;
        }

        if(!this.boundsCats) {
            this.boundsCats =  {};
        }

        if (this.boundsCats.hasOwnProperty(category)) {
            this.boundsCats[category].visible = false;
        }

        $.publish('hideCat.zoomBounds.map.' + this.mapId, category)
    };

    context.MapDrawWrapper.prototype.removeAllMarkersOnMapByCat = function(category) {
        //this.setMarkersOnMap(null, category);
        if (!this.markers.hasOwnProperty(category)) {
            return;
        }
        for(var id in this.markers[category]) {
            this.deleteMarkerById(id, category);
        }
        this.clearBounds(category);
        this.hideBoundsCat(category);
    };

    context.MapDrawWrapper.prototype.removeAllMarkersOnMapByCatRegex = function(regex) {
        regex = regex || false;
        if (!regex) {
            return;
        }
        for(var cat in this.markers) {
            if (typeof cat !== 'undefined' && cat.match(regex)) {
                this.removeAllMarkersOnMapByCat(cat);
            }
        }
    };

    context.MapDrawWrapper.prototype.removeAllMarkerEventsByCat = function(category) {
        if (!this.markers.hasOwnProperty(category)) {
            return;
        }
        for(var id in this.markers[category]) {
            this.removeMarkerListener(this.markers[category][id]);
        }
    };

    context.MapDrawWrapper.prototype.setMarkersOnMap = function(onMap, category) {
        for(var id in this.markers[category]) {
            if(!onMap) {
                this.removeMarkerListener(this.markers[category][id]);
                this.removeMarkerInfoWindow(this.markers[category][id]);
            }
            this.markers[category][id].setMap(onMap)
        }
        if(!onMap) {
            this.markers[category] = [];
        }
    };

    context.MapDrawWrapper.prototype.getMarkerByCategory = function(category) {
        if (!this.markers.hasOwnProperty(category)) {
            return;
        }
        return this.markers[category];
    }

    context.MapDrawWrapper.prototype.getMarker = function(category, id) {
        if (!this.markers.hasOwnProperty(category)
            || !this.markers[category].hasOwnProperty(id)) {
            return;
        }
        return this.markers[category][id];
    };

    context.MapDrawWrapper.prototype.getMarkerByCategoryId = function(category, catId) {
        if (!this.markers.hasOwnProperty(category)) {
            return;
        }
        markerId = null;
        for(var id in this.markers[category]) {
            if (catId == this.markers[category][id].pwCategoryId) {
                markerId = id;
            }
        }
        if (markerId) {
            return this.markers[category][markerId];
        }
        return null;
    };

    /**
     * addMarker
     *
     * @param options Marker Options
     *
     * Init Options:
     *
     * pwClustering: Clusteroptions
     * pwOnClickFn: Marker click function
     * pwCategory: Marker category
     * pwCategoryId: Marker category id (Airport: IATA code etc.)
     * pwMarkerIcon: key in mapIcons
     * pwMarkerIconSize: { x: <size in px>, y: <size in px> }
     * pwInfoWindowContent: HTML content of marker infowindow
     * pwInfowindowCol: column index
     * pwPolygon: GeoJSON of marker polygon
     * pwPolygonStyle: Polygon style object
     * pwShowCatchmentOnStart: <bool> Show Polygon on map start
     * pwAddMarkerZoomButton: <translation base> Füge der Map einen Button für diesen Marker mit folgendem Text hinzu: Title: <base>.title, Text: <base>.text)
     *
     * @returns {number} Marker Id
     *
     * Runtime marker keys:
     *
     * id: Marker Id
     * pwInfoWindow: the current infowindow/tooltip
     * pwMarkerIsOpen: the marker is currently open Infowindow/Tooltip
     * pwListener: Marker click event listener
     *
     */
    context.MapDrawWrapper.prototype.addMarker = function(options) {
        options = options || {};
        var map = this.map
            ,self = this;
        if (options.hasOwnProperty('pwClustering')) {
            map = null;
        }
        options['map'] = map;

        var markerOptions = $.extend({}, this.options.markerDefaults, options);

        var marker = L.marker(markerOptions.position, markerOptions);
        marker.id = this.markerId;
        this.markerId++;

        if (marker.options.hasOwnProperty('pwWithToolTip')) {
            marker.options.pwOnClickFn = function(e) {
                return self.openMarkerTooltip(marker);
            };
            self.reduceMapInfoWindow();
        }
        if (marker.options.hasOwnProperty('pwOnClickFn') && typeof marker.options.pwOnClickFn === 'function') {
            marker.pwListener = marker.on('click', marker.options.pwOnClickFn);
        }
        if (marker.options.hasOwnProperty('pwOnDragendFn') && typeof marker.options.pwOnDragendFn === 'function') {
            marker.pwListener = marker.on('dragend', marker.options.pwOnDragendFn);
        }

        if (marker.options.pwMarkerIcon && this.mapIcons.hasOwnProperty(marker.options.pwMarkerIcon)) {
            marker.setIcon(L.icon({
                iconUrl: this.options.baseUrl + this.mapIcons[marker.options.pwMarkerIcon]
                ,iconSize: this.getIconSize(marker)
            }));
        }
        if (!this.markers.hasOwnProperty(marker.options.pwCategory)) {
            this.markers[marker.options.pwCategory] = [];
        }
        this.markers[marker.options.pwCategory][marker.id] = marker;

        this.addPositionToBounds(marker.options.pwCategory, marker.options.position);
        this.showBoundsCat(marker.options.pwCategory);

        if (marker.options.hasOwnProperty('pwShowCatchmentOnStart')
            && marker.options.pwShowCatchmentOnStart
            && marker.options.hasOwnProperty('pwPolygon')
            && marker.options.pwPolygon) {
            this.showPolygon(marker);
        }
        if (marker.options.hasOwnProperty('pwAddMarkerZoomButton') && this.mod.hasOwnProperty('MapButtons')) {
            this.mod.MapButtons.addZoomToMarkerButton({
                marker: marker
            });
        }
        marker.addTo(this.map);
        return marker.id;
    };

    context.MapDrawWrapper.prototype.positionMarker = function(id, category, latLong) {
        if (this.markers[category].hasOwnProperty(id)) {
            this.markers[category][id].setLatLng(latLong);
        }
    };

    context.MapDrawWrapper.prototype.deleteMarkerById = function(id, category, refreshBounds) {
        refreshBounds = refreshBounds || false;
        if (!this.markers.hasOwnProperty(category)
            && !this.markers[category].hasOwnProperty(id)) {
            return;
        }
        if (this.markerClusters.hasOwnProperty(category)) {
            this.markerClusters[category].removeMarker(this.markers[category][id]);
        } else {
            this.markers[category][id].setMap(null);
        }
        this.removeMarkerListener(this.markers[category][id]);
        this.removeMarkerInfoWindow(this.markers[category][id]);
        this.closeInfoWindow(this.markers[category][id]);
        // this.closePolygon(this.markers[category][id]);
        delete(this.markers[category][id]);
        if(refreshBounds) {
            this.refreshBounds(category);
        }
    };

    context.MapDrawWrapper.prototype.removeMarkerListener = function(marker) {
        if (marker.hasOwnProperty('pwListener')) {
            marker.pwListener.remove();
        }
    };

    context.MapDrawWrapper.prototype.removeMarkerInfoWindow = function(marker) {
        if (marker.hasOwnProperty('pwInfoWindow')) {
            marker.pwInfoWindow.close();
            marker.pwInfoWindow = null;
        }
        if (marker.hasOwnProperty('pwMarkerIsOpen') && marker.pwMarkerIsOpen) {
            $.publish('closemarker.map.' + _.mapId, marker.id);
        }
        
    };

    context.MapDrawWrapper.prototype.addPositionToBounds = function(category,position) {
        position = position || false;
        if (!position) {
            return;
        }
        if(!this.bounds) {
            this.bounds =  {};
        }
        if(!this.bounds.hasOwnProperty(category)) {
            this.bounds[category] = [];
        }
        this.bounds[category].push([position[0], position[1]]);
    };

    context.MapDrawWrapper.prototype.clearBounds = function(category) {
        if (!this.bounds || !this.bounds.hasOwnProperty(category)) {
            return;
        }
        delete(this.bounds[category]);
    };

    context.MapDrawWrapper.prototype.addButton = function(buttonOpts) {
        this.mod.MapButtons.addButton(buttonOpts);
    };

    context.MapDrawWrapper.prototype.onHandleViewSize = function(e) {
        e.stopPropagation();
        var self = this;
        var button = $(e.currentTarget)
            ,buttonWrapper = button.parent('.mapbuttonwrapper')
            ,map = $(self.map.getContainer()).closest('.flexmap')
            ,wrapper = $('#FullScreenMap')
            ,buttonText = $('#FullScreenMapButtonText');

        if (!self.mapContainerOpts) {
            self.mapContainerOpts = {
                parent: map.parent()
                ,height: map.css('height')
            }
        }
        if (!buttonWrapper.hasClass('mapbuttonwrapper__active')) {
            buttonWrapper.addClass('mapbuttonwrapper__active');
            wrapper.addClass('fullscreenmap--active');
            wrapper.removeClass('fullscreenmap');
            buttonText.text(Translator.trans('core.map.button.normalview.text', {}, 'core'));
            button.prop('title', Translator.trans('core.map.button.normalview.title', {}, 'core'));
            map.appendTo(wrapper);
            self.map.scrollWheelZoom.enable();
            map.css('height', '100%');
        } else {
            buttonWrapper.removeClass('mapbuttonwrapper__active');
            wrapper.addClass('fullscreenmap');
            wrapper.removeClass('fullscreenmap--active');
            buttonText.text(Translator.trans('core.map.button.fullscreen.text', {}, 'core'));
            button.prop('title', Translator.trans('core.map.button.fullscreen.title', {}, 'core'));
            map.appendTo(self.mapContainerOpts.parent);
            self.map.scrollWheelZoom.disable();
            map.css('height', self.mapContainerOpts.height);
        }
        self.centerMap(false, true);
    };

    context.MapDrawWrapper.prototype.uiSetLoading = function(self, e) {
        this.loader.startLoader("",this.mapContainer);
    };

    context.MapDrawWrapper.prototype.uiUnsetLoading = function(self, e) {
        if (this.loaderId) {
            this.loader.remove(this.loaderId, this.mapContainer);
        }
    };

}());
