var ConfigCronGoalFromCentralController = (function(){

    var instance
        ,loader;

    function createInstance() {

        var addHandlers = function () {
            var wrapper = $('.js-cms-config-cron-goalfromcentral');
            wrapper.on('submit', '.js-cms-config-form-cron-goalfromcentral', onSubmit);
            wrapper.on('click', '.btn-reset-cron-goalfromcentral', onReset);
            cronJobHandler();
        };

        var cronJobHandler = function() {
            var wrapper = $('.js-cms-config-cron-goalfromcentral')
                ,advancedSwitch = wrapper.find('#crongoalfromcentral_showadvanced');
            advancedSwitch.on('change', function() {
                var checkbox = $(this);
                if (checkbox.is(':checked')) {
                    wrapper.find('.js-crongoalfromcentral_advancedform').show();
                    wrapper.find('.js-crongoalfromcentral_defaultform').hide();
                } else {
                    wrapper.find('.js-crongoalfromcentral_advancedform').hide();
                    wrapper.find('.js-crongoalfromcentral_defaultform').show();
                }
            });
            if (advancedSwitch.data('advanced')) {
                if (!advancedSwitch.is(':checked')) {
                    advancedSwitch.click();
                }
            } else {
                if (advancedSwitch.is(':checked')) {
                    advancedSwitch.click();
                }
            }
            wrapper.find('.js-crongoalfromcentral-specboxinputgroup_minutes').datetimepicker({
                format: 'm'
            });
            wrapper.find('.js-crongoalfromcentral-specboxinputgroup_hours').datetimepicker({
                format: 'H'
            });
            wrapper.find('.js-crongoalfromcentral-specboxinputgroup_day').datetimepicker({
                format: 'D'
            });
            wrapper.find('input[type="radio"]').on('click', function(e) {
                var radio = $(this)
                    ,specBoxMonth = wrapper.find('.crongoalfromcentral-specboxmonth')
                    ,specBoxDay = wrapper.find('.crongoalfromcentral-specboxday')
                    ,specBoxHour = wrapper.find('.crongoalfromcentral-specboxhour');

                if (radio.attr('id') == 'crongoalfromcentral_month') {
                    specBoxMonth.show();
                    specBoxDay.hide();
                    specBoxHour.hide();
                } else if (radio.attr('id') == 'crongoalfromcentral_day') {
                    specBoxDay.show();
                    specBoxMonth.hide();
                    specBoxHour.hide();
                } else if (radio.attr('id') == 'crongoalfromcentral_hour') {
                    specBoxHour.show();
                    specBoxMonth.hide();
                    specBoxDay.hide();
                } else {
                    specBoxMonth.hide();
                    specBoxHour.hide();
                    specBoxDay.hide();
                }
            });
        }

        var onReset = function(e) {
            e.preventDefault();
            var btn = $(this)
                ,loaderContainer = btn.parents('.js-cms-config-cron-goalfromcentral');
            $.ajax({
                url: Routing.generate('cms_config_resetcrongoalfromcentral')
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "resetCronGoalFromCentral";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
            })
                .done(function(data) {
                    if (data.view) {
                        btn.parents('.js-config-formwrapper').html(data.view);
                    }
                    cronJobHandler();
                });
        };

        var getCronString = function(form, isDefaultForm) {
            var radioField = form.find('input[type="radio"]:checked')
                ,className = (isDefaultForm) ? 'input-' + radioField.attr('id') : 'input-crongoalfromcentral_advanced'
                ,formClass = isDefaultForm ? '.js-crongoalfromcentral_defaultform' : '.js-crongoalfromcentral_advancedform'
                ,inputFields = form.find(formClass + ' .'+className)
                ,cronArray = ['*', '*', '*', '*', '*']
                ,isValidCron = true;
            $.each(inputFields, function(k,v) {
                var input = $(this);
                if (input.val() == '') {
                    isValidCron = false;
                    return false;
                }
                if (input.hasClass(className + '-minute')) {
                    cronArray[0] = input.val();
                } else if (input.hasClass(className + '-hour')) {
                    cronArray[1] = input.val();
                } else if (input.hasClass(className + '-day')) {
                    cronArray[2] = input.val();
                } else if (input.hasClass(className)) {
                    cronArray = input.val();
                }
            });
            if (!Array.isArray(cronArray)) {
                var checkCron = cronArray.split(" ");
                if (checkCron.length < 5) {
                    return false;
                }
            }
            var cronString = (Array.isArray(cronArray))? cronArray.join(' '): cronArray;
            return (isValidCron) ? cronString: false;
        };

        var onSubmit = function(e) {
            e.preventDefault();
            var form = $(this)
                ,isDefaultForm = (form.find('.js-crongoalfromcentral_defaultform').css('display') == 'block')
                ,loaderContainer = form.parents('.js-cms-config-cron-goalfromcentral')
                ,url = Routing.generate('cms_config_savecrongoalfromcentral')
                ,cronString = getCronString(form, isDefaultForm);

            if (!cronString) {
                toastr.error(
                    Translator.trans('def.term.error', {}, 'cms'),
                    Translator.trans('config.section.crongoalfromcentral.save.errormessageempty', {}, 'cms')
                );
                return false;
            }
            $.ajax(url, {
                data: {
                    cronGoalFromCentral: cronString
                    ,advanced: !isDefaultForm
                },
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "saveConfigCronGoalFromCentral";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
                ,method: 'POST'
            })
                .done(function(data){
                    if (data.success === false) {
                        toastr.error(
                            Translator.trans('def.term.error', {}, 'cms'),
                            Translator.trans('config.section.crongoalfromcentral.save.errormessage', {}, 'cms')
                        );
                    }
                    if (data.success === true) {
                        loaderContainer.find('.btn-reset-cron-goalfromcentral').click();
                        toastr.success(
                            Translator.trans('def.term.success', {}, 'cms'),
                            Translator.trans('config.section.crongoalfromcentral.save.successmessage', {}, 'cms')
                        );
                    }
                    cronJobHandler();
                });
        };

        var init = function() {
            loader = Loader.getInstance();
            addHandlers();
        };

        return {
            init: init
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();