var StatisticUploadsController = (function() {

    var instance
        ,loader
        ,filterInstance;

    var options = {
        filterFormId: 'statistic-uploads-filter-form'
        ,filterSupplierId: 'statistic-uploads-filter-tocode'
        ,filterFromDate: 'statistic-uploads-filter-from'
        ,filterToDate: 'statistic-uploads-filter-to'
        ,filterSuccessState: 'statistic-uploads-filter-success'
    };

    function createInstance() {

        var initFilter = function() {
            var datpickerOptions = {
                format: 'dd.mm.yyyy'
            };

            filterInstance = FilterForm.getInstance();
            filterInstance.init({
                formid: options.filterFormId
                ,submit: onFormSubmitHandler
            });
            filterInstance.addToCodeSelect2Field(options.filterFormId, options.filterSupplierId);
            filterInstance.addDatepickerField(options.filterFormId, options.filterFromDate, datpickerOptions);
            filterInstance.addDatepickerField(options.filterFormId, options.filterToDate, datpickerOptions);
            filterInstance.addThreeWaySwitch(options.filterFormId, options.filterSuccessState);
        };

        var addHandlers = function () {
            // var filterForm = $('#statistic-uploads-filter-form')
            //     ,datpickerOptions = {
            //         format: 'dd.mm.yyyy'
            //     };
            // filterForm.on('submit', onFormSubmitHandler );
            // filterForm.find("#FilterFormReset").on('click', onFormResetHandler );
            // filterForm.find('#statistic-uploads-filter-from').datepicker(datpickerOptions);
            // filterForm.find('#statistic-uploads-filter-to').datepicker(datpickerOptions);
            //
            // var input = $('#statistic-uploads-filter-tocode');
            // if (input.length > 0) {
            //     var tocdes = [];
            //     $.each(input.data('values'), function(k,v){
            //         tocdes.push({
            //             id: k,
            //             text: v
            //         });
            //     });
            //     input.select2({
            //         data: tocdes,
            //         createSearchChoice: function(term) {
            //             return {
            //                 id: term,
            //                 text: term + ' (new)'
            //             };
            //         }
            //     });
            // };
        };

        var onFormSubmitHandler = function() {
            var params = filterInstance.getValues(options.filterFormId)
                ,tableAction = "cms_statistic_uploads_list"
                ,tableId = "#cmsuploadstable";



            loader.startLoader("filter", $('.cmsstatistic--uploads--filter'), ["uploadsTable"]);
            // $('#statistic-uploads-filter-form input,select').each(function(k, v) {
            //     if (0 >= $(v).val().length ) {
            //         return true;
            //     }
            //     params[$(v).prop('name')] = $(v).val();
            // });
            
            var table =$(tableId).DataTable();
            table
                .ajax
                .url(Routing.generate(tableAction, params))
                .load(function() {
                });
            return false;
        };

        // var onFormResetHandler = function() {
        //     $('#statistic-uploads-filter-tocode').select2('val', null);
        //     $("#statistic-uploads-filter-form")[0].reset();
        //     $("#FilterFormSubmit").trigger('click');
        // };

        var restartImport = function(data) {
            $.ajax({
                url: Routing.generate('cms_statistic_uploads_list'),
                data: {'tocode':data['tocode']}
            })
            .done(function(data){
                if(data.data[0]) {
                    createRestartMessage(data.data[0]);
                }
            });
        };

        var createRestartMessage = function(uploadObj){
            if (uploadObj.success == "false") {
                swal({
                    title: Translator.trans('statistic.restartimport.title', {}, 'cms'),
                    text: Translator.trans('statistic.restartimport.text', {}, 'cms'),
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: Translator.trans('statistic.restartimport.confirmbutton', {}, 'cms'),
                    closeOnConfirm: false
                }, function(){
                    sendRestartRequest(uploadObj);
                });
            } else if (uploadObj.success == "true") {
                swal(Translator.trans('statistic.restartimport.alreadyfinished', {}, 'cms'));
            } else if (uploadObj.success == "") {
                swal(Translator.trans('statistic.restartimport.stillrunning', {}, 'cms'));
            }
        };

        var sendRestartRequest = function(uploadObj) {
            $.ajax({
                url: Routing.generate('cms_statistic_uploads_restartimport'),
                data: {'tocode':uploadObj['tocode'], 'filename':uploadObj['filename']}
            })
            .done(function(){
                swal(Translator.trans(
                    'statistic.restartimport.startedtitle', {}, 'cms')
                    ,Translator.trans('statistic.restartimport.startedtext', {}, 'cms')
                    ,"success"
                );
                onFormSubmitHandler();
            });
        };

        var init = function() {
            loader = Loader.getInstance();
            initFilter();
            addHandlers();
            stasticuploadstable_ini();
        };

        return {
            init: init
            ,onClickRestartImport: function(data) {
                restartImport(data);
            }
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

$(function() {
    if ($('.js-init-statisticuploads').length > 0) {
        StatisticUploadsController.getInstance().init();
    }
});

