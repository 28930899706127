var ConfigContentExportController = (function(){

    var instance
        ,mainWrapper
        ,loader
        ,brandsListContainer
        ,defaultBrandsConfigContainer
        ,customBrandsConfigContainer
        ,customIncompleteBrandsConfigContainer
        ,customInactiveBrandsConfigContainer
        ,brandsConfigTableWrapper;

    function createInstance() {
        var addHandlers = function () {
            mainWrapper = $('.js-init-config-contentexport-main')
            ,brandsListContainer = mainWrapper.find('.js-config-contentexport-settings-brandlists-overview')
            ,defaultBrandsConfigContainer = brandsListContainer.find('.js-brandlists-default')
            ,customBrandsConfigContainer = brandsListContainer.find('.js-brandlists-custom')
            ,customIncompleteBrandsConfigContainer = brandsListContainer.find('.js-brandlists-custom-incomplete')
            ,customInactiveBrandsConfigContainer = brandsListContainer.find('.js-brandlists-custom-inactive')
            ,brandsConfigTableWrapper = mainWrapper.find('.js-brandconfiglist-table-wrapper');
            brandsConfigTableWrapper.on('click', '.js-brandconfiglist-delete', onDeleteBrand);
            refreshAllComponents();
        };

        var onDeleteBrand = function(e) {
            var button = $(e.currentTarget);
            swal({
                title: Translator.trans('config.labels.prioritycontentexport.delete.title', {}, 'cms')
                ,text: Translator.trans('config.labels.prioritycontentexport.delete.description', {
                    brandidentifiers: button.data('brandidentifiers')
                }, 'cms')
                ,type: "warning"
                ,showCancelButton: true
                ,confirmButtonText: Translator.trans('def.button.confirm.title', {}, 'cms')
                ,cancelButtonText: Translator.trans('def.button.cancel.title', {}, 'cms')
                ,closeOnConfirm: true
                ,closeOnCancel: true
            }, function(isConfirm){
                if (isConfirm) {
                    deleteBrand(button.data('brandidentifiers'));
                }
            });
        };

        var deleteBrand = function(brandIdentifiers) {
            $.ajax({
                url: Routing.generate('cms_config_contentexport_delete_brand')
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = loader.getGeneratedKeyByPrefix("deletebrand");
                    loader.startLoader(e.loaderKey, brandsConfigTableWrapper);
                }
                ,data: {
                    'brandidentifiers' : brandIdentifiers
                }
            })
            .done(function(data) {
                if (data.pw_fe_errors) {
                    toastr.error(
                        Translator.trans('def.term.error', {}, 'cms'),
                        Translator.trans('config.labels.prioritycontentexport.delete.errormessage', {}, 'cms')
                    );
                    return;
                }
                toastr.success(
                    Translator.trans('def.term.success', {}, 'cms'),
                    Translator.trans('config.labels.prioritycontentexport.delete.successmessage', {}, 'cms')
                );
                refreshAllComponents();
            })
            .fail(function(e) {
                toastr.error(
                    Translator.trans('def.term.error', {}, 'cms'),
                    Translator.trans('config.labels.prioritycontentexport.delete.errormessage', {}, 'cms')
                );
            });
        };

        var refreshAllComponents = function() {
            var tablePromise = loadTable();
            refreshDefaultBrands(tablePromise);
            refreshCustomBrands(tablePromise);
            refreshIncompleteCustomBrands(tablePromise);
            refreshInactiveCustomBrands(tablePromise);
            setExistingBrandIdentifiers(tablePromise);
        };

        var setExistingBrandIdentifiers = function(tablePromise) {
            tablePromise.done(() => {
                var rows = $('.brandconfiglist-table .js-brandconfiglist-table-rows');
                var brandConfigIdentifiers = $.map(rows, (v, k) => {
                    return $(v).data('brandidentifiers');
                });
                $('#contentexportbrandconfigmodal').attr('data-brandidentifiers', brandConfigIdentifiers.join('|'));
            })
        };

        var loadTable = function() {
            var deferred = $.Deferred();
            $.ajax({
                url: Routing.generate('cms_config_contentexport_defaultandallbrandconfiguration')
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = loader.getGeneratedKeyByPrefix("brandconfiglist");
                    loader.startLoader(e.loaderKey, brandsConfigTableWrapper);
                }
            })
            .done(function(data) {
                brandsConfigTableWrapper.html(data.view);
                deferred.resolve(data.view);
            })
            .fail(function(e) {
                deferred.reject(e);
            });
            return deferred.promise();
        };

        var refreshDefaultBrands = function(tablePromise) {
            var loaderKey = loader.getGeneratedKeyByPrefix("brandswithoutconfiguration");
            loader.startLoader(loaderKey, defaultBrandsConfigContainer);
            tablePromise
                .done(function(brandConfigsData) {
                    var contentContainer = defaultBrandsConfigContainer.find('.js-brandlists-default-content');
                    var brandDefaultConfig = $(brandConfigsData).find('.js-configuration-table');
                    if (brandDefaultConfig.data('defaultconfigurations') !== "") {
                        var defaultConfigArray = brandDefaultConfig.data('defaultconfigurations').split('|');
                        let list = $('<ul/>');
                        defaultConfigArray.forEach((elm) => {
                            let listItem = $('<li/>');
                            listItem.append(elm);
                            list.append(listItem);
                        });
                        contentContainer.html(list);
                    }
                    loader.remove(loaderKey);
                })
                .fail(function() {
                    loader.remove(loaderKey);
                });
        };

        var prepareBrandsForOutput = function(parent, brandObjectsSelector) {
            let temp = [];
            let list = $('<ul/>');
            $(parent).find(brandObjectsSelector).each(function(k, v) {
                var identifier = $(v).find('.js-brandconfiglist-custom-identifier').children();
                if (identifier.length && identifier.toArray().length) {
                    let listItem = $('<li/>');
                    let item = identifier.toArray().map((element, index) => {
                        return element.outerHTML;
                    }).join(', ');
                    listItem.append(item);
                    temp.push(listItem);
                }
            });
            // return temp.join(' | ');
            return list.append(temp);
        };

        var refreshCustomBrands = function(tablePromise) {
            var loaderKey = loader.getGeneratedKeyByPrefix("brandscustomconfiguration");
            loader.startLoader(loaderKey, customBrandsConfigContainer);
            tablePromise
                .done(function(data) {
                    if (data) {
                        customBrandsConfigContainer.find('.js-brandlists-custom-content').html(
                            prepareBrandsForOutput(data, '.js-brandconfiglist-custom')
                        );
                    }
                    loader.remove(loaderKey);
                })
                .fail(function(e) {
                    loader.remove(loaderKey);
                });
        };

        var refreshIncompleteCustomBrands = function(tablePromise, brandsPromise) {
            var loaderKey = loader.getGeneratedKeyByPrefix("incompletebrandscustomconfiguration");
            loader.startLoader(loaderKey, customIncompleteBrandsConfigContainer);
            tablePromise
                .done(function(data) {
                    if (data) {
                        customIncompleteBrandsConfigContainer.find('.js-brandlists-custom-incomplete-content').html(
                            prepareBrandsForOutput(data, '.js-brandconfiglist-custom-incomplete')
                        );
                    }
                    loader.remove(loaderKey);
                })
                .fail(function(e) {
                    loader.remove(loaderKey);
                });
        };

        var refreshInactiveCustomBrands = function(tablePromise) {
            var loaderKey = loader.getGeneratedKeyByPrefix("inactivebrandscustomconfiguration");
            loader.startLoader(loaderKey, customInactiveBrandsConfigContainer);
            tablePromise
                .done(function(data) {
                    if (data) {
                        customInactiveBrandsConfigContainer.find('.js-brandlists-custom-inactive-content').html(
                            prepareBrandsForOutput(data, '.js-brandconfiglist-custom-inactive')
                        );
                    }
                    loader.remove(loaderKey);
                })
                .fail(function(e) {
                    loader.remove(loaderKey);
                });
        };


        var init = function() {
            loader = Loader.getInstance();
            addHandlers();
            ConfigContentExportModalController.getInstance().init();
        };

        return {
            init: init,
            refresh: refreshAllComponents
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();