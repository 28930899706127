statisticunavailableimagestable_ini = function() {
    var loader = Loader.getInstance()
        ,table = $('#cmsunavailableimagestable')
        ,dtRoutingParameter = {}
        ,startvalues = false;

    var $formWithStartvalues = $('.js-filterform--has-startvalues');
    if ($formWithStartvalues.length > 0) {
        startvalues = $formWithStartvalues.data('startvalues');
    }
    if (startvalues) {
        dtRoutingParameter = startvalues;
    }
    table
    .on('draw.dt', function(e, settings) {
    })
    .on('xhr.dt', function ( e, settings, data ) {
    })
    .on( 'page.dt', function(e, settings) {
    })
    .dataTable({
        ajax: {
            url: Routing.generate('cms_statistic_unavailableimages_list', dtRoutingParameter)
            ,beforeSend: function(e, xhr, options) {
                e.loaderKey = "unavailableimagesTable";
                loader.startLoader(e.loaderKey, table);
            }
        },
        serverSide: true,
        lengthChange: true,
        searching: false,
        pagingType: 'simple_numbers',
        pageLength: 10,
        "order": [],
        columns: [
            {data: 'suppliercode', name:'suppliercode', sortable: false},
            {data: 'goalid', name:'goalid', sortable: false},
            {data: 'url', name:'url', sortable: false},
            {data: 'offlinesince', name:'offlinesince', sortable: false}
        ],
        language: {
            paginate: {
                next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
                previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
            },
            thousands: '.',
            info: Translator.trans('contentstore.producttable.info', {}, 'cms'),
            infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms'),
            emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
        },
        fnDrawCallback: function( oSettings ){
            var settings = this.fnSettings();

            if( settings.fnRecordsTotal() <= settings._iDisplayLength){
                $('#cmscontentstoreproducttable_paginate').hide();
            }else{
                $('#cmscontentstoreproducttable_paginate').show();
            }
        }
    });
};
