statisticgoalkeeperexportstable_ini = function() {
    var loader = Loader.getInstance()
        ,table = $('#cmsexportstable');

    table
        .on('draw.dt', function(e, settings) {})
        .on('xhr.dt', function ( e, settings, data ) {})
        .on('page.dt', function(e, settings) {})
        .dataTable({
            ajax: {
                url: Routing.generate('cms_statistic_export_list')
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "goalkeeperExportsTable";
                    loader.startLoader(e.loaderKey, table);
                    var lastupdate = new Date();
                    $('#goalkeeperexportlastupdate').html(
                        Translator.trans('statistic.goalkeeperexports.table.lastupdated', {}, 'cms') + ': ' +
                            lastupdate.getDate() + '.' +
                            (lastupdate.getMonth() + 1) + '.' +
                            lastupdate.getFullYear() + ' ' +
                            ("0" + lastupdate.getHours()).slice(-2) + ':' +
                            ("0" + lastupdate.getMinutes()).slice(-2) + ':' +
                            ("0" + lastupdate.getSeconds()).slice(-2)
                    );
                }
            },
            serverSide: true,
            lengthChange: true,
            searching: false,
            pagingType: 'simple_numbers',
            pageLength: 10,
            "order": [],
            columns: [
                {data: 'filename', name:'filename', sortable: false},
                {data: 'supplier', name:'supplier', sortable: false},
                {data: 'exporttype', name:'exporttype', sortable: false},
                {data: function(row, type, set, meta) {
                    if (row.total) {
                        return row.total;
                    } else {
                        return (row.end)?row.processed:'';
                    }
                }, name:'total', sortable: false},
                {data: function(row, type, set, meta) {
                    if (row.total) {
                        return (row.processed * 100 / row.total).toFixed(2) + ' %';
                    } else {
                        return row.processed;
                    }
                }, name:'processed', sortable: false},
                {data: 'start', name:'start', sortable: false},
                {data: 'end', name:'end', sortable: false},
                {data: 'updatedat', name:'updatedat', sortable: false},
                {data: function(row, type, set, meta) {
                    if (row.success === 'true') {
                        return $( "<div/>", {'class': 'statistic__statusicons'}).append(
                            $( "<i/>", {'class':'fa fa-check-circle statistic__statusiconsitem statistic__statusiconsitem--success'})
                        )[0].outerHTML;
                    } else if (row.success === 'false') {
                        return $( "<div/>", {'class': 'statistic__statusicons'}).append(
                            $( "<i/>", {'class':'fa fa-exclamation-triangle statistic__statusiconsitem statistic__statusiconsitem--failed'})
                        )[0].outerHTML;
                    } else {
                        return $( "<div/>")[0].outerHTML;
                    }
                }, name:'success', sortable: false}
            ],
            language: {
                paginate: {
                    next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
                    previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
                },
                thousands: '.',
                info: Translator.trans('contentstore.producttable.info', {}, 'cms'),
                infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms'),
                emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
            },
            fnDrawCallback: function( oSettings ){
                var settings = this.fnSettings();

                if(settings.fnRecordsTotal() <= settings._iDisplayLength){
                    $('#cmsexportstable_paginate').hide();
                } else {
                    $('#cmsexportstable_paginate').show();
                }
            }
        });
};
