var basicdataairporttable_ini = function() {
    var loadingDetailsPage = false;
    var dtRoutingParameter = {};
    var startvalues = false;
    var loader = Loader.getInstance();
    var table = $('#cmsairporttable');

    var $formWithStartvalues = $('.js-filterform--has-startvalues');
    if ($formWithStartvalues.length > 0) {
        startvalues = $formWithStartvalues.data('startvalues');
    }
    if (startvalues) {
        dtRoutingParameter = startvalues;
    }

    table
        .on('draw.dt', function(e, settings) {
            $('#cmsairporttable .edit-btn').each(function(k, v){
               $(v).on('click',BasicDataAirportController.getInstance().loadBasicDataAirportDetailPage );
            });
        })
        .on('xhr.dt', function ( e, settings, data ) {

        })
        .on( 'page.dt', function(e, settings) {

        })
        .dataTable({
            ajax: {
                url: Routing.generate('cms_basicdata_airport_list', dtRoutingParameter)
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "airportList";
                    loader.startLoader(e.loaderKey, table);
                }
            },
            serverSide: true,
            lengthChange: true,
            searching: false,
            pagingType: 'simple_numbers',
            pageLength: 10,
            columns: [
                {data: 'iatacode', name:'iatacode', sortable: false},
                {data: 'stationtypename', name:'stationtypename', sortable: false},
                {data: 'name', name:'name', sortable: false},
                {data: 'city', name:'city', sortable: false},
                {data: 'countrycode' , name: 'countrycode', sortable: false},
                {data: function(row, type, set, meta) {
                    return  $( "<div/>", {
                        'class': "btn btn-default edit-btn",
                        'data-toggle': 'tooltip',
                        'data-original-title': Translator.trans('contentstore.producttable.show', {}, 'cms'),
                        'data-key':row.iatacode
                    }).append(
                        $( "<span/>", {class: "fa fa-eye" } )
                    )[0].outerHTML;
                }, sortable: false}
            ],
            language: {
                paginate: {
                    next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
                    previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
                },
                thousands: '.',
                info: Translator.trans('contentstore.producttable.info', {}, 'cms'),
                infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms'),
                emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
            },
            fnDrawCallback: function( oSettings ) {
                // var settings = this.fnSettings();

                if( oSettings.fnRecordsTotal() === 1) {
                    BasicDataAirportController.getInstance().loadBasicDataAirportDetailPageByIata(oSettings.json.data[0].iatacode);
                }

                if (oSettings.fnRecordsTotal() <= oSettings._iDisplayLength) {
                    $('#cmsairporttable_paginate').hide();
                } else {
                    $('#cmsairporttable_paginate').show();
                }
            }
        });
};