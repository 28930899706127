var GoalController = (function(){

    var instance
        ,loader
        ,tocodeData = {}
        ,mappingData = {}
        ,alertDefaults = {
            title: "Are you sure?"
            ,text: "This is the default message!"
            ,type: "warning"
            ,showCancelButton: true
            ,closeOnConfirm: true
            ,confirmButtonColor: "#DD6B55"
            ,confirmButtonText: "Yes, I am sure!"
        }
        ,removedExistingTags = []
        ,options = {
            filterFormId: 'goal-filter-form'
            ,filterGoalId: 'goal-filter-goalid'
            ,filterProductCode: 'goal-filter-hotelcode'
            ,filterName: 'goal-filter-name'
            ,filterRevisionTag: 'goal-filter-revisiontag'
            ,filterArrivalAirport: 'goal-filter-dlc'
            ,filterArrivalAirportSearchOption: 'dlc-searchoption'
            ,filterShowExcludedSubtypes: 'goal-filter-showexcludedsubtypes'
            ,filterSupplier: 'goal-filter-tocode'
            ,filterCityName: 'goal-filter-cityname'
            ,filterCityNameResolve: 'goal-filter-cityname-resolve'
            ,filterRegionName: 'goal-filter-regionname'
            ,filterRegionNameResolve: 'goal-filter-regionname-resolve'
            ,filterCountryName: 'goal-filter-countryname'
            ,filterCountryNameResolve: 'goal-filter-countryname-resolve'
            ,filterGoalStates: 'goal-filter-goalstates'
        };

    function createInstance() {

        var isModalSelect2Loaded = false
            , goalHistoryController
            ,filterInstance;

        var initFilter = function() {
            filterInstance = FilterForm.getInstance();

            var stdFieldsSel = '#' + options.filterGoalId
                + ', #' + options.filterProductCode
                + ', #' + options.filterName
                + ', #' + options.filterArrivalAirport
                + ', #' + options.filterArrivalAirportSearchOption
                + ', #' + options.filterShowExcludedSubtypes
                + ', #' + options.filterCountryNameResolve
                + ', #' + options.filterCityNameResolve
                + ', #' + options.filterRegionNameResolve
                + ', #' + options.filterRevisionTag;

            try {
                filterInstance.init({
                    formid: options.filterFormId
                    ,submit: onSubmit
                    ,resetAddFn: onFromResetAdditionalFn
                    ,addfields: stdFieldsSel
                });
                filterInstance.addToCodeSelect2Field(options.filterFormId, options.filterSupplier);
                filterInstance.addInputLabelSwitch(options.filterFormId, options.filterCityName, switchFunctionId);
                filterInstance.addInputLabelSwitch(options.filterFormId, options.filterRegionName, switchFunctionId);
                filterInstance.addInputLabelSwitch(options.filterFormId, options.filterCountryName, switchFunctionCode);
                filterInstance.addThreeWaySwitch(options.filterFormId, options.filterGoalStates);
            } catch(err) {
                console.log(err);
            }

        };

        var addHandlers = function () {

            window.onpopstate = function (e) {
                location.reload();
            };

            addScrollButtons([
                {title: 'Filter', anchor: '.cmscontentstore--filter'}
                , {title: 'List', anchor: '.cmscontentstore--goaltable'}
                , {title: 'Detail', anchor: '.cmscontentstore--goaldetail'}
                , {title: 'History', anchor: '.cmscontentstore--goalhistory'}
            ]);

            $('.cmscontentstore--goaldetail').on('click', '.js-goaldata-field--novalidurl', function (e) {
                e.preventDefault();
            });

            initFilter();

            var detailContainer = $('.cmscontentstore--goaldetail');
            detailContainer.on('click', '.js-statusdata__actions__release, .js-statusdata__actions__deprecate', onDetailsActionClickHandler);
            detailContainer.on('click', '.js-statusdata__actions__needsrevision, .js-revisiondata__actions__editrevision', onEditRevision);
            detailContainer.on('click', '.js-revisioninfomodal-edit-reset', onResetRevisionChanges);
            detailContainer.on('click', '.js-revisioninfomodal-edit-delete', onDeleteRevision);
            detailContainer.on('click', '.js-revisioninfomodal-edit-save', onSaveRevision);
            detailContainer.on('click', '.js-revisioninfo--openaddtag', onOpenAddRevisionTag);
            detailContainer.on('click', '.js-revisioninfo--addtag', onAddRevisionTag);
            detailContainer.on('click', '.js-revisioninfo--deletetag', onDeleteRevisionTag);
            detailContainer.on('click', '.js-statusdata__actions__verifygoal', onVerifyGoalClickHandler );
            goalHistoryController = GoalHistoryController.getInstance();
            goalHistoryController.init();
        };

        var onVerifyGoalClickHandler = function(e) {
            var $target = $(e.currentTarget)
                ,parent = $target.parent()
                ,goalId = $target.data('goalid') || false
                ,loaderContainer = $('.cmscontentstore--goaldetail');

            $.ajax({
                url: Routing.generate('cms_goal_verifygoal')
                ,data: {
                    'goalid': goalId
                }
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "verifyGoal";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
            }).done(function (data) {
                loadGoalDetailPage({
                    'key' : goalId
                    ,'requestType' : 'detail'
                }, loaderContainer, false);
            });
        };

        var switchFunctionId = function (value) {
            return (value !== '' && isIntegerId(value));
        };

        var switchFunctionCode = function (value) {
            return (value !== '' && value.length < 3)
        };

        var isIntegerId = function (value) {
            if ((undefined === value) || (null === value)) {
                return false;
            }
            return value % 1 == 0;
        };

        var prepareSelectData = function (data) {
            var tocdes = [];
            $.each(data, function (k, v) {
                tocdes.push({
                    id: k,
                    text: v
                });
            });
            return tocdes;
        };

        var onOpenAddRevisionTag = function (e) {

            var btn = $(e.currentTarget)
                , parent = btn.parent()
                , select = parent.find('select')
                , input = parent.find('input');

            if (select.length > 0) {
                if (!select.data('select2')) {
                    select.select2();
                } else {
                    select.select2('val', null);
                }
            }
            if (input.length > 0) {
                input.val(null);
            }
        };

        var onAddRevisionTag = function (e) {
            var btn = $(e.currentTarget)
                , name = btn.data('name')
                , select = btn.parents('.statusdata-panel').find('.js-revisioninfo--tagblock select')
                , value = select.val()
                , parent = btn.parents('.revisioninfo--tagblock')
                , list = parent.find('.revisioninfo--taglist');
            if (value && !checkTagAlreadyExist(list, value)) {
                list.append(
                    $('<li/>', {
                        'class': 'newrevisiontag'
                        , 'data-tag': value
                        , 'text': " " + Translator.trans('goal.revision.tag.' + value, {}, 'cms')
                    }).prepend(
                        $('<a/>', {
                            'class': 'js-revisioninfo--deletetag revisioninfo--deletetag'
                            , 'data-tag': value
                        }).append($('<span/>', {class: 'fa fa-times'}))
                    )
                );
            }
            parent.find('.js-revisioninfo--openaddtag').click();
            return false;
        };

        var checkTagAlreadyExist = function (list, tag) {
            var exist = false;
            $.each(list.find('li'), function () {
                if ($(this).attr('data-tag') === tag) {
                    exist = true;
                    return false;
                }
            });
            return exist;
        };

        var onDeleteRevisionTag = function (e) {
            var button = $(e.currentTarget);
            var parent = button.parent('li');
            parent.remove();
        };

        var onDeleteRevision = function (e) {
            var button = $(this)
                , statusPanel = $('.statusdata-panel')
                , goalId = button.data('goalid');

            swal({
                    title: Translator.trans('goal.detail.revision.warning.delete.title', {}, 'cms'),
                    text: Translator.trans('goal.detail.revision.warning.delete.text', {}, 'cms'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: Translator.trans('goal.detail.revision.warning.delete.confirmbutton', {}, 'cms'),
                    cancelButtonText: Translator.trans('goal.detail.revision.warning.delete.cancelbutton', {}, 'cms'),
                    closeOnConfirm: true
                },
                function (isConfirm) {
                    if (isConfirm !== true) {
                        return false;
                    } else {
                        deleteRevision(statusPanel, goalId);
                    }
                }
            );
        };

        var deleteRevision = function (statusPanel, goalId) {
            $.ajax({
                url: Routing.generate('cms_goal_saverevision')
                ,data: {
                    'goalid': goalId
                    ,'needsrevision': false
                }
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = loader.getGeneratedKeyByPrefix("deleteRevision");
                    loader.startLoader(e.loaderKey, statusPanel);
                }
            }).done(function (data) {
                if (data.status == "success") {
                    refreshStatusFilter();
                    reloadTable();
                    refreshStatusPanel(statusPanel, goalId);
                }
            });
        };

        var onSaveRevision = function (e) {
            var button = $(this)
                ,statusPanel = $('.statusdata-panel')
                ,goalId = button.data('goalid')
                ,tagList = statusPanel.find('.js-revisioninfo--taglist')
                ,commentContainer = statusPanel.find('.js-revisioninfo--comment')
                ,comment = (commentContainer.length) ? $.trim(commentContainer.val()) : ""
                ,tags = []
                ,hasOtherTag = false
                ,loaderTargetContainer = statusPanel;

            tagList.children().each(function (k, v) {
                var tag = $(v).data('tag');
                tags.push(tag);
                if (tag === 'OTHER') {
                    hasOtherTag = true;
                }
            });
            if (hasOtherTag && !comment) {
                loader.remove(loaderId, loaderTargetContainer);
                swal({
                    title: Translator.trans('goal.detail.revision.error.nocomment.title', {}, 'cms'),
                    text: Translator.trans('goal.detail.revision.error.nocomment.text', {}, 'cms'),
                    type: 'error'
                });
                return;
            }
            if (tags.length === 0) {
                loader.remove(loaderId, loaderTargetContainer);
                swal({
                    title: Translator.trans('goal.detail.revision.error.notag.title', {}, 'cms'),
                    text: Translator.trans('goal.detail.revision.error.notag.text', {}, 'cms'),
                    type: 'error'
                });
                return;
            }
            $.ajax({
                url: Routing.generate('cms_goal_saverevision'),
                data: {
                    'goalid': goalId
                    , 'needsrevision': true
                    , 'revisiontags': tags
                    , 'revisioncomment': comment
                }
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = loader.getGeneratedKeyByPrefix("saveRevision");
                    loader.startLoader(e.loaderKey, loaderTargetContainer);
                }
            }).done(function (data) {
                if (data.status == "success") {
                    refreshStatusFilter();
                    reloadTable();
                    refreshStatusPanel(statusPanel, goalId);
                }
            });
        };

        var refreshStatusFilter = function() {
            var statusFilterContainer = $('.goalstatusresultlist ')
                ,loaderTargetContainer = statusFilterContainer.parents('.form-group')
                ,deferred = new $.Deferred();
            $.ajax({
                url: Routing.generate('cms_goal_refreshStatus')
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = loader.getGeneratedKeyByPrefix("refreshStatusFilter");
                    loader.startLoader(e.loaderKey, loaderTargetContainer);
                }
            })
            .done(function(data){
                if (data.status) {
                    var status = data.status;
                    for(var statusItem in status) {
                        var count = statusFilterContainer
                            .find('.status-count--'+statusItem);
                        count.text(status[statusItem]);
                    }
                }
                deferred.resolve(true);
            })
            .fail(function(){
                deferred.fail();
            });
            return deferred.promise();
        };


        var onResetRevisionChanges = function (e) {
            var statusPanel = $('.statusdata-panel')
                ,tagList = statusPanel.find('.js-revisioninfo--taglist')
                ,commentBlock = statusPanel.find('.revisioninfo--commentblock');
            restoreRemoveTags(tagList);

            commentBlock.find('textarea').val(commentBlock.data('origcomment'));
        };

        var storeTags = function(tagList) {
            removedExistingTags = [];
            $.each(tagList, function(k,v){
                removedExistingTags.push($(this));
            });
        };

        var restoreRemoveTags = function(tagList) {
            tagList.empty();
            for(var tag in removedExistingTags) {
                tagList.append(removedExistingTags[tag]);
            }
        };

        var refreshStatusPanel = function(statusPanel, goalId) {
            var deferred = new $.Deferred();
            $.ajax({
                url: Routing.generate('cms_goal_statusAndRevision'),
                data: {
                    'goalid': goalId
                }
            }).done(function(data){
                if (data.view) {
                    statusPanel.html(data.view);
                }
                deferred.resolve(true);
            });
            return deferred.promise();
        };

        var onEditRevision = function(e) {
            var button = $(this)
                ,parent = button.parents('.statusdata-panel')
                ,$modal = parent.find('.js-goaldata-revisioninfo')
                ,commentBlock = parent.find('.revisioninfo--commentblock')
                ,commentTextArea = commentBlock.find('textarea')
                ,comment = commentTextArea.text()
                ,tagList = parent.find('.js-revisioninfo--taglist');
            if (button.hasClass('btn-default')) {
                button.addClass('revisioninfo-btnorange').removeClass('btn-default');
                $modal.addClass('revisioninfo--edit');
                if (commentTextArea.length == 0) {
                    commentBlock.append($("<textarea/>"
                        ,{class:"js-revisioninfo--comment revisioninfo--comment form-control",'text':comment, rows:10})
                    );
                }
                commentTextArea.removeAttr('readonly');
                storeTags(tagList.children());
                commentBlock.attr('data-origcomment', comment);
            } else {
                button.removeClass('revisioninfo-btnorange').addClass('btn-default');
                $modal.removeClass('revisioninfo--edit');
                commentTextArea.attr('readonly', true).val(commentBlock.data('origcomment'));
                if (commentBlock.data('origcomment') == "") {
                    commentTextArea.remove();
                }
                $.each(tagList.children(), function(k,v) {
                    var listItem = $(this);
                    if (listItem.hasClass('newrevisiontag')) {
                        listItem.remove();
                    }
                });
                restoreRemoveTags(tagList);
            }
        };

        var onDetailsActionClickHandler = function(e) {
            var $target = $(e.currentTarget);
            var goalId = $target.data('goalid') || false;
            var action = false;
            var alert = {};
            if (!goalId) {
                return;
            }
            if ($target.hasClass('js-statusdata__actions__deprecate')) {
                // PHP 5.4 fix:
                // action = "deprecate";
                action = "deprecated";
                alert.text = Translator.trans('goal.detail.actions.deprecate.message', {}, 'cms');
                alert.confirmButtonText = Translator.trans('goal.detail.actions.deprecate.confirmbutton', {}, 'cms');
            }
            if ($target.hasClass('js-statusdata__actions__release')) {
                action = "release";
                alert.text = Translator.trans('goal.detail.actions.release.message', {}, 'cms');
                alert.confirmButtonText = Translator.trans('goal.detail.actions.release.confirmbutton', {}, 'cms');
            }
            if (action) {
                alert.confirmButtonColor = $target.css('background-color');
                showAlertWithConfirmFn(alert, function(){
                    setGoalIdStatus(goalId, action);
                });
            }
        }

        var onFromResetAdditionalFn = function() {
            window.history.pushState({}, window.document.title, Routing.generate('cms_goal_homepage'));
        };

        var onSubmit = function(e) {
            e.preventDefault();
            filterSearch(false);
        };

        var filterSearch = function(redraw) {
            var params = {}
                ,tableAction = "cms_goal_list"
                ,tableId = "#cmsgoaltable";
            loader.startLoader("filter", $(".cmscontentstore--filter"), ["goalTable"]);

            $('.cmscontentstore--goaldetail').html("");
            goalHistoryController.destroyTable();

            $('#goal-filter-form input, select').each(function(k, v) {
                if (!$(v).val() || 0 >= $(v).val().length) {
                    return true;
                }
                var item = $(v);

                var itemName = item.prop('name');
                var itemValue = item.val().trim();

                if (itemName === 'countryname' && itemValue && itemValue.length == 2) {
                    itemName = 'countrycode';
                }

                if (itemName === 'regionname' && itemValue && !isNaN(itemValue)) {
                    itemName = 'regionid';
                }

                if (itemName === 'cityname' && itemValue && !isNaN(itemValue)) {
                    itemName = 'cityid';
                }

                if (item.is(':checkbox')) {
                    if (item.is(':checked')) {
                        params[itemName] = itemValue;
                    }
                } else if (item.is(':radio')) {
                    if (item.is(':checked')) {
                        params[itemName] = itemValue;
                    }
                } else {
                    params[itemName] = itemValue;
                }
            });

            var table =$(tableId).DataTable();
            if(redraw) {
                table.ajax.reload(function() {
                }, false );
            } else {
                table.ajax.url(Routing.generate(tableAction, params))
                    .load(function() {
                    });
            }
            return false;
        };

        var reloadTable = function() {
            var deferred = new $.Deferred()
                ,wrapper = $("#cmsgoaltable_wrapper")
                ,table =$("#cmsgoaltable").DataTable();
            table.ajax.reload(function() {
                deferred.resolve(true);
            }, false );
            return deferred.promise();
        };

        var showAlertWithConfirmFn = function(alert,fn) {

            if (typeof fn !== 'function') {
                return;
            }
            var options = {};
            $.extend(options, alertDefaults, alert);
            swal(options, fn);
        };

        var setGoalIdStatus = function(goalId, status) {
            var statusPanel = $('.statusdata-panel')
                ,data = { goalId: goalId, requestType: status };

            $.ajax(Routing.generate('cms_goal_setgoalstatus'), {
                data: data
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = loader.getGeneratedKeyByPrefix("setGoalIdStatus");
                    loader.startLoader(e.loaderKey, statusPanel);
                }
            })
            .done(function() {
                if (status=='deprecated') {
                    $('.cmscontentstore--goaldetail').empty();
                    $('.cmscontentstore--goalhistory').hide();
                } else {
                    refreshStatusPanel(statusPanel, goalId);
                }
                refreshStatusFilter();
                reloadTable();
            })
        };
        
        var loadGoalDetailPage = function(data, loaderTargetContainer, jumpToDetailContainer) {
            var detailContainer = $('.cmscontentstore--goaldetail')
                ,targetContainer = loaderTargetContainer || $('.content');

            var url = Routing.generate('cms_goal_detail');
            var _jumpToDetailContainer = (jumpToDetailContainer !== undefined)?jumpToDetailContainer:true;
            return $.ajax(url, {
                data: data
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = loader.getGeneratedKeyByPrefix("goalDetail");
                    loader.startLoader(e.loaderKey, targetContainer);
                }
            })
            .done(function(data){

                var optionData = {};
                if (data.hasOwnProperty('airports')) {
                    optionData.airports = data.airports;
                }
                var historyContainer = $('.cmscontentstore--goalhistory');
                tocodeData = data.codes;
                mappingData = data.mappings;
                detailContainer.html(data.view);
                goalmappingtable_ini();
                GoalNestedListTabsController.getInstance().init();
                GoalMapController.getInstance().init(optionData);
                GoalEditController.getInstance().init();
                if (historyContainer.find('.fa-chevron-up').length > 0) {
                    historyContainer.find('.showhide').click();
                }
                historyContainer.show();
                if (_jumpToDetailContainer) {
                    $('.cmscontentstore--goaldetail-btn').click();
                }
            });
        };

        var handlePanels = function(openPanel) {
            var panels = ['#goallistresultwrapper', '.cmscontentstore--filter'];
            var panelToogleClass = (openPanel)?'fa-chevron-down':'fa-chevron-up';
            for(var i in panels) {
                if($(panels[i]).css('display')!=='none') {
                    if($(panels[i]+' .panel-tools .fa').hasClass(panelToogleClass)){
                        $(panels[i]+' .panel-tools .showhide').click();
                    }
                }
            }
        };

        var releaseMatching = function(releaseData) {
            var loaderTargetContainer = $('.js-goaldetail-mapping')
                ,errorTitleTrans = 'goal.tocodetable.release.swal.error.unreleasetitle'
                ,errorTextTrans = 'goal.tocodetable.release.swal.error.unreleasetitle';
            if (releaseData.release) {
                errorTitleTrans = 'goal.tocodetable.release.swal.error.releasetitle';
                errorTextTrans = 'goal.tocodetable.release.swal.error.releasetext';
            }
            $.ajax(Routing.generate("cms_goal_releasematching"), {
                data: releaseData
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = loader.getGeneratedKeyByPrefix("releaseMatching");
                    loader.startLoader(e.loaderKey, loaderTargetContainer);
                }
            })
            .done(function(data, textStatus, jqXHR){
                if (data.status === 'error') {
                    swal({
                        title:  Translator.trans(errorTitleTrans, {}, 'cms'),
                        text:  Translator.trans(errorTextTrans, {}, 'cms'),
                        type: 'error'});
                } else {
                    $('#cmsmappingstable').DataTable().ajax.reload();

                }
            })
            .fail(function(jqXHR, textStatus, errorThrown){
                swal({
                    title:  Translator.trans(errorTitleTrans, {}, 'cms'),
                    text:  Translator.trans(errorTextTrans, {}, 'cms'),
                    type: 'error'});
            });
        };

        var moveMatching = function(goalId, supplier, productcode) {
            var loaderTargetContainer = $('.js-goaldetail-mapping');
            $.ajax(Routing.generate("cms_goal_movematchingtogoal"), {
                data: {
                    goalid: goalId
                    ,supplier: supplier
                    ,productcode: productcode
                }
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = loader.getGeneratedKeyByPrefix("movemaichingtogoal");
                    loader.startLoader(e.loaderKey, loaderTargetContainer);
                }
            })
                .done(function(data, textStatus, jqXHR){
                    $('#cmsmappingstable').DataTable().ajax.reload();
                });
        };

        var moveToGoalEventHandler = function(goalId, supplier, productcode) {
            swal({
                    title:  Translator.trans('goal.tocodetable.movetoothergoal.swal.title', {}, 'cms'),
                    text:  Translator.trans('goal.tocodetable.movetoothergoal.swal.text', {
                        id:goalId,
                        supplier: supplier,
                        productcode: productcode
                    }, 'cms'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: Translator.trans('goal.tocodetable.movetoothergoal.swal.confirmbutton', {}, 'cms'),
                    cancelButtonText: Translator.trans('goal.tocodetable.movetoothergoal.swal.cancelbutton', {}, 'cms'),
                    closeOnConfirm: true
                },
                function(isConfirm) {
                    if (isConfirm !== true) {
                        return false;
                    } else {
                        var marginBottom = (parseInt($('.js-goaldetail-mapping').find('.panel').first().css('marginBottom')));
                        var panelHeader = (parseInt($('.js-goaldetail-mapping').find('.panel-heading').first().outerHeight()));
                        var offSet = marginBottom + panelHeader;
                        $("html, body").animate({ scrollTop: $('.js-goaldetail-mapping').offset().top - offSet}, "slow");
                        moveMatching(goalId, supplier, productcode);
                    }
                });
            return false;
        };

        var removeMatchingToGoalEventHandler = function(oldGoalId, supplier, productcode) {
            swal({
                    title:  Translator.trans('', {}, 'cms'),
                    text:  Translator.trans('contentstore.general.feedback.removegoalidmatching.confirmtext', {
                        goalid: oldGoalId,
                        supplier: supplier,
                        productcode: productcode
                    }, 'cms'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: Translator.trans('Yes', {}, 'cms'),
                    cancelButtonText: Translator.trans('No', {}, 'cms'),
                    closeOnConfirm: true
                },
                function(isConfirm) {
                    if (isConfirm !== true) {
                        return false;
                    }
                    removeMatchingToGoal(supplier, productcode);
                });
        };

        var removeMatchingToGoal = function(supplier, productcode) {
            var loaderTargetContainer = $('.js-goaldetail-mapping');
            $.ajax({
                url: Routing.generate('cms_goal_removematchingtogoal'),
                data: {
                    supplier: supplier
                    ,productcode: productcode
                },
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = loader.getGeneratedKeyByPrefix("removematchingtogoal");
                    loader.startLoader(e.loaderKey, loaderTargetContainer);
                }
            })
                .done(function(data){
                    $('#cmsmappingstable').DataTable().ajax.reload(null, false);
                });
        };

        var getStatus = function() {
            var loaderTargetContainer = $('.cmscontentstore--goalsummary');
            $.ajax(Routing.generate("cms_goal_getstatus"), {
                    beforeSend: function(e, xhr, options) {
                        e.loaderKey = loader.getGeneratedKeyByPrefix("getstatus");
                        loader.startLoader(e.loaderKey, loaderTargetContainer);
                    }
                })
                .done(function(data, textStatus, jqXHR){
                    loaderTargetContainer.find('.goalsummary__body').html(data.view);
                });
        };

        var loadGoalArchiveDetailPage = function(key) {
            var detailContainer = $('.cmscontentstore--goaldetail')
                ,targetContainer = $('.content');

            var url = Routing.generate('cms_goalarchive_detail');
            return $.ajax(url, {
                    data: {
                        'key':key
                    }
                    ,beforeSend: function(e, xhr, options) {
                        e.loaderKey = loader.getGeneratedKeyByPrefix("goalDetail");
                        loader.startLoader(e.loaderKey, targetContainer);
                    }
                })
                .done(function(data){
                    var optionData = {};
                    if (data.hasOwnProperty('airports')) {
                        optionData.airports = data.airports;
                    }
                    detailContainer.html(data.view);
                    GoalMapController.getInstance().init(optionData);
                    $('.cmscontentstore--goaldetail-btn').click();
                });
        };

        var init = function() {
            loader = Loader.getInstance();
            getStatus();
            addHandlers();
            goaltable_ini();
        };

        return {
            init: init
            ,loadGoalDetailPage: function(e) {
                loadGoalDetailPage({
                    'key' : $(this).data('key')
                    ,'requestType' : 'detail'
                });
            }
            ,loadGoalDetailPageByKey: function(key, loaderTargetContainer, jumpToDetailContainer) {
                loadGoalDetailPage({
                    'key' : key
                    ,'requestType' : 'detail'
                }, loaderTargetContainer, jumpToDetailContainer);
            }
            ,updateGoalList: function() {
                filterSearch(true);
            }
            ,handlePanels :function(openPanel) {
                handlePanels(openPanel);
            }
            ,getTocodes : function() {
                return tocodeData;
            }
            ,getMappings : function() {
                return mappingData;
            }
            ,moveToGoalEventHandler: function(goalId, supplier, productcode) {
                moveToGoalEventHandler(goalId, supplier, productcode);
            }
            ,removeMatchingToGoalEventHandler: function(oldGoalId, supplier, productcode) {
                removeMatchingToGoalEventHandler(oldGoalId, supplier, productcode);
            }
            ,loadGoalArchiveDetailPage: function(e) {
                loadGoalArchiveDetailPage($(this).data('key'));
            }
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

$(function() {
    if($('.js-init-goal').length > 0) {
        GoalController.getInstance().init();
        GoalEditController.getInstance().init();
    }
});










