/**
 *
 * CONTENTSTORE EDF view
 * extends AbstractMapComponent
 *
 */
(function() {
    var ctx = pwNamespace('PEAKWORK.CONTENTSTORE')
        ,parentCtx = pwNamespace('PEAKWORK.MAP')
        ,mapWrapper = null
        ,_ = null;
    ctx.ContentStoreMapComponent = function (mapWrapperObj, options) {
        mapWrapper = mapWrapperObj;
        _ = this;
        var defaultoptions = {
            markerCat: 'edf'
            ,markerRegex: 'edf'
            ,infowindowCol: 2
            ,bounds: {
                default: ['center','goal', 'giata', 'edf']
            }
            ,zindexdefault: 200
        };

        if (options && typeof options === "object") {
            var opts = this.extendDefaults(defaultoptions, options);
        }
        parentCtx.AbstractMapComponent.call(_, mapWrapper, opts || defaultoptions);

        _.init();

    };
    ctx.ContentStoreMapComponent.prototype = $.extend(
        Object.create(parentCtx.AbstractMapComponent.prototype), {
            constructor: ctx.ContentStoreMapComponent
            ,init: function () {
                _.mapId = mapWrapper.getMapId();
                _.addHandler();
                if (_.options.hasOwnProperty('edf')) {
                    _.loadEdf(null, _.options.edf);
                }
            }

            ,addHandler: function() {
                $.unsubscribe('cms.contentstore.map.load_data', _.loadData);
                $.subscribe('cms.contentstore.map.load_data', _.loadData);
            }

            ,loadData: function(e, data) {
                _.clearComponentMarkers();
                _.addDataMarker(data);
            }

            ,clearComponentMarkers: function(regex) {
                regex = regex || false;
                var _ = this;
                if (!regex && _.options.hasOwnProperty('markerRegex')) {
                    regex = _.options.markerRegex;
                }
                mapWrapper.removeAllMarkersOnMapByCatRegex(regex);
            }

            ,addDataMarker: function(edfs) {
                var self = this;
                $.each(edfs,function(k,v){
                    if(k == 'edf') {
                        $.each(edfs[k], function(k1,v1){
                            if (!isNaN(k1)) {
                                var key = k + '' + k1;
                                edfs[key] = self.prepareMarker('edf', 'HOTELGREEN', edfs[k][k1], 1);
                            }
                        });
                        delete edfs[k];
                    } else if (edfs[k].type == 'goal') {
                        edfs[k] = self.prepareMarker('goal', 'HOTELBLUE', edfs[k], 2);
                    } else if (edfs[k].type == 'giata') {
                        edfs[k] = self.prepareMarker('giata', 'HOTELYELLOW', edfs[k], 2);
                    }
                });
                _.addMarkers(edfs);
                $.publish('center_default.map.' + _.mapId, { bounds: self.options.bounds.default });
                return false;
            }

            ,prepareMarker: function(cat, markerIcon, edf, col) {
                edf['pwCategory'] = cat;
                edf['pwInfowindowCol'] = col;
                edf['pwMarkerIcon'] = markerIcon;
                edf['latitude'] = edf.lat;
                edf['longitude'] = edf.lon;
                return edf;
            }
        }
    );
})();