
/**
 *
 * Map Component Functions Abstract
 *
 */
(function() {
    var ctx = pwNamespace('PEAKWORK.MAP')
        ,mapWrapper = null;
    ctx.AbstractMapComponent = function (mapWrapperObj,options) {
        mapWrapper = mapWrapperObj;


        if (this.constructor === ctx.AbstractMapComponent) {
            throw new Error('Cannot instanciate abstract class');
        }
        this.options = {
            infowindowCol: 1
            ,zindexdefault: 1
            ,zindex: {}
            ,cluster: {}
            ,defaultcategory: 'default'
        };

        if (options && typeof options === "object") {
            this.options = this.extendDefaults(this.options, options);
        }

    };

    ctx.AbstractMapComponent.prototype = {
        constructor : ctx.AbstractMapComponent
        ,init : function() {
            this.mapId = mapWrapper.getMapId();
            this.addHandler();
        }
        ,extendDefaults : function(source, properties) {
            var property;
            for (property in properties) {
                if (properties.hasOwnProperty(property)) {
                    source[property] = properties[property];
                }
            }
            return source;
        }
        ,addHandler : function() {

        }

        ,onMarkerClick: function (marker) {
            return mapWrapper.openMarkerTooltip(marker);
        }

        ,addMarkers: function(markers) {
            var self = this;
            $.each(markers, function(k,v){
                self.addMarker(k, markers[k]);
            });
        }
        
        ,addMarker: function (id,data) {
            var _ = this;

            data = data || false;
            if(!data) {
                return;
            }

            if (!data.hasOwnProperty('latitude') || !data.hasOwnProperty('longitude')) {
                return;
            }

            data.pwCategory = data.pwCategory || _.options.defaultcategory;
            var clustering = false;
            if (data.hasOwnProperty('pwCategory') && _.options.cluster.hasOwnProperty(data.pwCategory)) {
                clustering = _.options.cluster[data.pwCategory];
            }

            var zIndex = _.options.zindexdefault;
            if (data.hasOwnProperty('pwCategory') && _.options.zindex.hasOwnProperty(data.pwCategory)) {
                zIndex = _.options.zindex[data.pwCategory];
            }

            var iconCategory = data.pwCategory.toUpperCase();
            if (data.hasOwnProperty('pwMarkerIcon')) {
                iconCategory = data.pwMarkerIcon;
            }
            if (data.catchment) {
                iconCategory = iconCategory + '_POLY';
            }
            if (mapWrapper.mapIcons.hasOwnProperty(iconCategory)) {
                data.pwMarkerIcon = iconCategory;
            }

            if (!data.hasOwnProperty('pwInfowindowContent')) {
                data.pwInfowindowContent = this.createInfoWindowContent(data.pwCategory,id,data);
            } else {
                data.pwInfowindowContent = $(data.pwInfowindowContent)[0];
            }
            if (!data.hasOwnProperty('pwInfowindowCol')) {
                data.pwInfowindowCol = _.options.infowindowCol;
            }

            var markerOptions = {
                position: mapWrapper.getCoord(data.latitude, data.longitude)
                ,zIndex: zIndex
                ,pwCategory: data.pwCategory
                ,pwCategoryId: id
                ,pwPolygon: data.catchment
                ,pwInfowindowCol: data.pwInfowindowCol
                ,pwInfowindowContent: data.pwInfowindowContent
                ,pwMarkerIcon: data.pwMarkerIcon
                ,pwOnClickFn: data.pwOnClickFn
            };

            if (data.hasOwnProperty('pwPolygonStyle')) {
                markerOptions['pwPolygonStyle'] = data.pwPolygonStyle;
            }

            if (data.hasOwnProperty('pwShowCatchmentOnStart')) {
                markerOptions['pwShowCatchmentOnStart'] = data.pwShowCatchmentOnStart;
            }

            if (clustering) {
                markerOptions['pwClustering'] = clustering;
            }

            if (!markerOptions.hasOwnProperty('pwOnClickFn') || typeof markerOptions.pwOnClickFn !== 'function') {
                markerOptions.pwOnClickFn = function(e) {
                    var marker = mapWrapper.getMarker(data.pwCategory, data.markerid);
                    return _.onMarkerClick(marker);
                };
            }

            if (data.hasOwnProperty('pwAddMarkerZoomButton')) {
                markerOptions['pwAddMarkerZoomButton'] = data.pwAddMarkerZoomButton;
            }

            data.markerid = mapWrapper.addMarker(markerOptions);

        }

        ,clearComponentMarkers: function(regex) {
            regex = regex || false;
            var _ = this;
            if (!regex && _.options.hasOwnProperty('markerRegex')) {
                regex = _.options.markerRegex;
            }
            mapWrapper.removeAllMarkersOnMapByCatRegex(regex);
        }

        ,createInfoWindowContent: function (category,id,data) {
            var _ = this;
            var containerClass = _.options.class.infowindowcontainer + ' ' + _.options.class.infowindowcontainer + '--' + category;
            var headlineText = id;
            var headlineClass = _.options.class.infowindowcontainer + '-headline ' + _.options.class.infowindowcontainer + '-headline--' + category;

            var content = $('<p />', { text: Translator.trans('map.infowindow.errortext', {}, 'cms') })

            var pwInfowindowContent = $('<div/>', { class: containerClass})
                .append($('<h4/>', { class: headlineClass, text: headlineText }))
                .append(content)[0];

            return pwInfowindowContent;
        }
    };
})();