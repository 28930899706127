/**
 * Created by kyoung on 16.06.16.
 */
var BasicDataPoiDataController = (function() {

    var instance;

    function createInstance() {

        var loader = Loader.getInstance();
        var loaderId = false;

        var addHandlers = function () {
            var form = $('#basicdata-poidata-filter-form');
            addScrollButtons([
                {title:'Filter', anchor:'.cmspoidata--filter'}
                ,{title:'List', anchor:'.cmscontentstore--poidatatable'}
                ,{title:'Detail', anchor:'.cmscontentstore--poidatadetail'}
            ]);
            form.on('submit', onSubmit );
            form.find('#FilterBasicDataPoiDataReset').on('click', onFormResetHandler);
        };

        /**
         * resets filterform
         * @param Event e form event
         */
        var onFormResetHandler = function(e) {
            e.preventDefault();
            //$('#basicdata-airports-stationtype').select2("val", null);
            $("#basicdata-poidata-filter-form")[0].reset();
            $("#FilterFormSubmit").trigger('click');
        };


        var onSubmit = function(e) {
            e.preventDefault();
            var params = {};
            var tableAction = "cms_basicdata_poidata_list";
            var tableId = "#cmspoidatatable";
            $('.cmscontentstore--poidatadetail').html("");
            if (!loaderId) {
                loaderId = loader.add($('.content'));
            }

            $('#basicdata-poidata-filter-form input,select').each(function(k, v) {
                if (!$(v).val() || 0 >= $(v).val().length ) {
                    return true;
                }
                var item = $(v);

                var itemName = item.prop('name');
                params[itemName] = item.val();

            });

            var table =$(tableId).DataTable();
            table
                .ajax
                .url(Routing.generate(tableAction, params))
                .load(function() {
                    loader.remove(loaderId);
                    loaderId = false;
                });
            return false;
        };


        var loadBasicDataPoiDataDetailPage = function(data) {
            if (!loaderId) {
                loaderId = loader.add($('.content'));
            }
            var url = Routing.generate('cms_basicdata_poidata_detail');
            return $.ajax(url, {
                data: data,
                success: function(data, status, xhr) {
                    var detailContainer = $('.cmscontentstore--poidatadetail');
                    loader.remove(loaderId);
                    loaderId = false;
                    detailContainer.html(data);
                }
            });
        };

        return {
            init: function() {
                addHandlers();
                basicdatapoidatatable_ini();
            }
            ,loadBasicDataPoiDataDetailPage: function(e) {
                loadBasicDataPoiDataDetailPage({
                    'key' : $(this).data('key')
                });
            }
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

$(function() {
    if ($('.js-init-poidata').length > 0) {
        BasicDataPoiDataController.getInstance().init();
    }
});

