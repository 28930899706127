var GiataGeoDataController = (function() {

    var instance;

    function createInstance() {

        var addHandlers = function () {
            SearchObjectHandlerController.getInstance().addController(
                new GiataGeoDataSearchListController({
                    type:'country'
                    ,routeId:'cms_giata_geodata_list'
                    ,component: 'CountrySearchComponent'
                    ,data:{}
                })
                ,'country'
            );
            addTranslationHandler('.js-giatageo__translationcontainer');
        };

        var addTranslationHandler = function (selector) {
            $selector = $(selector);
            if ($selector.length > 0) {
                $selector.editable({
                    url: Routing.generate('cms_giata_geodata_transsave')
                    ,selector: '.giatageo__translationinput'
                });
            }
        }

        return {
            init: function() {
                addHandlers();
            }
        };
    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

$(function() {
    GiataGeoDataController.getInstance().init();
});

