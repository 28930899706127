var productfilterlist_ini = function() {


    var loadingDetailsPage = false;
    var dtRoutingParameter = {};
    var startvalues = false;
    var loader = Loader.getInstance();
    var loaderId;
    var loaderTargetContainer;

    var table = $('#cmscontentstoreproducttable');
    var $formWithStartvalues = $('.js-filterform--has-startvalues');
    if ($formWithStartvalues.length > 0) {
        startvalues = $formWithStartvalues.data('startvalues');
    }
    if (startvalues) {
        dtRoutingParameter = startvalues;
        //loaderTargetContainer = $formWithStartvalues.closest('.content');
        //loaderId = loader.add();
    } else {

    }

    var getColumms = function() {
        var movetogoal = false;
        if (table.parents('.js-cmscontentstore-producttable').data('movetogoal')) {
            movetogoal = table.parents('.js-cmscontentstore-producttable').data('movetogoal');
        }

        return [
            {data: 'goalid', name: 'goalid'},
            {data: 'giataid', name:'giataid'},
            {data: 'tocode', name: 'tocode'},
            {data: 'code', name: 'code'},
            {data: 'name' , name: 'name'},
            {data: 'key', name:'key', sortable: false},
            {data: 'airport', name:'airport', sortable: false},
            {data: function(row, type, set, meta) {
                var groupBtn = '';
                if (row.groupid !== '') {
                    var groupBtn = $("<button/>", {
                        'class': "btn btn-default edit-group-btn",
                        'data-toggle': 'tooltip',
                        'type': 'button',
                        'data-original-title': Translator.trans('contentstore.producttable.editgroup', {}, 'cms'),
                        "data-groupid":row.groupid
                    }).append(
                        $("<span/>", {class:"fa fa-group"})
                    )[0].outerHTML;
                }

                var editBtn = $( "<div/>", {
                    class: "btn btn-default edit-btn",
                    'data-toggle': 'tooltip',
                    'data-original-title': Translator.trans('contentstore.producttable.show', {}, 'cms'),
                    'data-key':row.key,
                    'data-supplier': row.tocode,
                    'data-productcode': row.code,
                    'data-roomlocalization': getRoomLocalization()
                } ).append(
                    $("<span/>", {class: "fa fa-eye" } )
                )[0].outerHTML;

                if (row.showOpenGoalButton) {
                    var goalBtn = $( "<a/>", {
                        class: "btn btn-default goal-btn",
                        'data-toggle': 'tooltip',
                        'data-original-title': Translator.trans('contentstore.producttable.jumptogoal', {}, 'cms'),
                        'target': '_blank',
                        'href': Routing.generate('cms_goal_homepage', { 'id': row.goalid })
                    } ).append(
                        $("<span/>", {class: "fa fa-external-link-square" } )
                    )[0].outerHTML;
                    return editBtn +' '+ goalBtn +' '+ groupBtn;
                }
                return editBtn +' '+ groupBtn;

            }, sortable: false, 'className': 'nowrap'}
            ,{data: function(row, type, set, meta) {
                var label = $('<label/>', {'text':'Goal Id', 'for':'goalid', 'class':'contentstore__movetogoallabel'});
                var input = $('<input/>', {
                    'class': 'form-control js-contentstore-goalid contentstore__movetogoalinput'
                    ,'placeholder': 'Goal Id'
                    ,'name': 'goalid'
                })[0].outerHTML;
                var button = $('<button/>', {
                    'class': 'btn btn-default js-contentstore-movetogoal contentstore__movetogoalbutton',
                    'type': 'submit',
                    'data-toggle': 'tooltip',
                    'data-template': '<div class="tooltip changegoalidmatchintooltip" role="tooltip"><div class="tooltip-inner"></div></div>',
                    'title': Translator.trans('contentstore.general.warning.emptygoalidmatching', {}, 'cms'),
                }).append($('<span/>', {
                    'class': 'fa fa-arrow-right'
                }))[0].outerHTML;
                var form = $('<form/>', {
                    'class': 'js-contentstore-movetogoalform'
                    ,'data-supplier': row.tocode
                    ,'data-productcode': row.code
                    ,'data-goalid': row.goalid
                }).prepend(label).append(input).append(button)[0].outerHTML;
                return form;
            }, sortable: false, visible: movetogoal}
        ];
    };

    var moveMatching = function(goalId, supplier, productcode) {
        var loaderTargetContainer = $('.js-manualgoal-matching');
        $.ajax(Routing.generate("cms_goal_movematchingtogoal"), {
            data: {
                goalid: goalId
                ,supplier: supplier
                ,productcode: productcode
            }
            ,beforeSend: function(e, xhr, options) {
                e.loaderKey = loader.getGeneratedKeyByPrefix("movemappingtogoal");
                loader.startLoader(e.loaderKey, loaderTargetContainer);
            }
        })
            .done(function(data, textStatus, jqXHR){
                table.DataTable().ajax.reload(null, false);
            })
    };

    var removeMatching = function(supplier, productcode) {
        var loaderTargetContainer = $('.js-manualgoal-matching');
        $.ajax({
            url: Routing.generate('cms_goal_removematchingtogoal'),
            data: {
                supplier: supplier
                ,productcode: productcode
            },
            beforeSend: function(e, xhr, options) {
                e.loaderKey = me.config.loader.getGeneratedKeyByPrefix("submitRequestRemoveGoalID");
                me.config.loader.startLoader(e.loaderKey, loaderTargetContainer);
            }
        })
            .done(function(data){
                table.DataTable().ajax.reload(null, false);
            });
    };

    var moveToGoalEventHandler = function(goalId, oldGoalId, supplier, productcode) {
        if (goalId === '') {
            swal({
                    title:  Translator.trans('', {}, 'cms'),
                    text:  Translator.trans('contentstore.general.feedback.removegoalidmatching.confirmtext', {
                        goalid: oldGoalId,
                        supplier: supplier,
                        productcode: productcode
                    }, 'cms'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: Translator.trans('Yes', {}, 'cms'),
                    cancelButtonText: Translator.trans('No', {}, 'cms'),
                    closeOnConfirm: true
                },
                function(isConfirm) {
                    if (isConfirm !== true) {
                        return false;
                    }
                    removeMatching(supplier, productcode);
                });
            return false;
        }
        swal({
                title:  Translator.trans('goal.tocodetable.movetoothergoal.swal.title', {}, 'cms'),
                text:  Translator.trans('goal.tocodetable.movetoothergoal.swal.text', {
                    id:goalId,
                    supplier: supplier,
                    productcode: productcode
                }, 'cms'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: Translator.trans('goal.tocodetable.movetoothergoal.swal.confirmbutton', {}, 'cms'),
                cancelButtonText: Translator.trans('goal.tocodetable.movetoothergoal.swal.cancelbutton', {}, 'cms'),
                closeOnConfirm: true
            },
            function(isConfirm) {
                if (isConfirm !== true) {
                    return false;
                }
                moveMatching(goalId, supplier, productcode);
            });
    };

    table
        .on('draw.dt', function(e, settings) {
            $('#cmscontentstoreproducttable .update-group-button').each(function(k, v) {
                $(v).on('click', CmsContentStore.getInstance().updateGroupInList );
            });
            $('#cmscontentstoreproducttable .edit-btn').each(function(k, v){
                $(v).on('click', CmsContentStore.getInstance().loadProductDetailPage );
            });
            $('#cmscontentstoreproducttable .rel-btn').each(function(k, v){
                $(v).on('click', CmsContentStore.getInstance().onRelevantHandler );
            });
            $('#cmscontentstoreproducttable .edit-group-btn').each(function(k, v){
                $(v).on('click', CmsContentStore.getInstance().loadProductDetailPageByGroup );
            });
            $.each($(e.currentTarget).find('.js-contentstore-movetogoal'), function(k, v){
                var form = $(v).parent();
                form.on('submit', function(e) {
                    e.preventDefault();
                    var goalform = $(form)
                        ,supplier = goalform.data('supplier')
                        ,productcode = goalform.data('productcode')
                        ,oldGoalId = goalform.data('goalid')
                        ,goalId = goalform.find('input').val();

                    moveToGoalEventHandler(goalId, oldGoalId, supplier, productcode);
                });
            });
        })
        .on('preXhr.dt', function( e, settings, data ) {
        })
        .on('xhr.dt', function( e, settings, data ) {
            loadingDetailsPage = false;
        })
        .on( 'page.dt', function(e, settings) {
        })

        .dataTable({
            ajax: {
                url: Routing.generate('cms_contentstore_product_list', dtRoutingParameter)
                ,beforeSend  : function(e, xhr, options) {
                    e.loaderKey = "productList";
                    loader.startLoader(e.loaderKey, $('#cmscontentstoreproducttable'));
                }
            },
            serverSide: true,
            lengthChange: true,
            searching: false,
            pagingType: 'simple_numbers',
            pageLength: 10,
            order: [],
            columns: getColumms(),
            language: {
                paginate: {
                    next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
                    previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
                },
                thousands: '.',
                info: Translator.trans('contentstore.producttable.info', {}, 'cms'),
                infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms'),
                emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
            },
            fnDrawCallback: function( oSettings ){
                // var settings = this.fnSettings();

                if( oSettings.fnRecordsTotal() === 1) {
                    loadingDetailsPage = true;
                    CmsContentStore.getInstance().loadProductDetailPageByKey(oSettings.json.data[0].tocode, oSettings.json.data[0].code);
                }

                if( oSettings.fnRecordsTotal() <= oSettings._iDisplayLength){
                    $('#cmscontentstoreproducttable_paginate').hide();
                }else{
                    $('#cmscontentstoreproducttable_paginate').show();
                }
            }
        }
    );
};


