var CmsContentStore = (function() {
    var instance;

    var mapsCtx = pwNamespace('PEAKWORK.BASIC.MAP');
    var popmessagequeue = {};
        popmessagequeue.count = 0;

    var $contentStore
        ,sysLangs
        ,map
        ,loader
        ,presenterCtx = pwNamespace('PEAKWORK.CMS.IMAGE')
        ,contentImagePresenter = false;

    function createInstance() {

        var isModalSelect2Loaded = false
            ,unsavedChangeHandler = UnsavedChangesController.getInstance()
            ,preventLoadDetailClassArray = [ '.js-detailstext' ]
            ,$detailsTextContainer
            ,editViewTextsIsOpen = false
            ,editViewImagesIsOpen = false
            ,goalMatchingHistoryController;

        var options = {
            imagePresenter: {
                id: 'contentimagespresenter'
                ,presenter: {
                    groupList: {
                        options: {
                            groupListContainerId: 'contentimagespresenter__grouplist'
                        }
                    }
                    ,imageList: {
                        options: {
                            imageListContainerId: 'contentimagespresenter__imagelist'
                            //,imageListSort: { name: 'isduplicateof', options: { order: 'asc' } }
                        }
                    }
                    ,imageDetails: {
                        options: {
                            imageDetailContainerId: 'contentimagespresenter__details'
                            ,imageTextRenderFn: ImageDetailsRenderer.getInstance().contentRenderFunction
                        }
                    }
                }
            }
        };

        var addHandlers = function () {
            window.onpopstate = function(e){
                location.reload();};

            let scrollButtons = [
                {title:'Filter', anchor:'.cmscontentstore--filter'}
                ,{title:'List', anchor:'.cmscontentstore--producttable'}
                ,{title:'Detail', anchor:'.cmscontentstore--productdetail'}
                ,{title:'Rooms', anchor:'.js-content__rooms'}
            ];
            if ($('.js-init-contentstore').data('servertype') === 'central') {
                scrollButtons.push({title:'history', anchor:'.cmscontentstore--goalmatchinghistory'});
            }

            addScrollButtons(scrollButtons);
            addFilterHandler();

            if (!isModalSelect2Loaded) {
                var sysLangs = createSysLangOptionItems($('.js-init-contentstore').data('syslangs'));
                initializeModalSelect2Filter(
                    $('#contentstorefiledownloadmodal').find('#js-filedownload-select-inclang'), sysLangs);
                initializeModalSelect2Filter(
                    $('#contentstorefiledownloadmodal').find('#js-filedownload-select-exclang'), sysLangs);
                isModalSelect2Loaded = true;
            }

            var productDetailContainer = $('.cmscontentstore--productdetail');
            productDetailContainer.on('ifChanged', '.panel-text-form  input', onMasterCheckChangeHandler );
            productDetailContainer.on('click', '#DownloadEdfButton, #EditCompareText, #EditSingleText, #EditCompareImages, .contentimagespresenter__detailduplicatebutton, .contentimages__closebutton, .createNewGoalID', handelClickEvent);
            goalMatchingHistoryController = GoalMatchingHistoryController.getInstance();
        };

        var createSysLangOptionItems = function(sysLangs) {
            var options = '';
            for (var lang in sysLangs) {
                options += $('<option/>',{'value':sysLangs[lang],'text':sysLangs[lang]})[0].outerHTML;
            }
            return options;
        };

        var initializeModalSelect2Filter = function(select, values) {
            select.html();
            select.append(values);
            select.select2();
        };

        var handelClickEvent = function (e) {
            e.preventDefault();
            var $target = $(e.currentTarget);
            var targetId = $target.attr('id');
            if (targetId == 'DownloadEdfButton') {
                onDownloadEdfHandler($target);
            } else if (targetId == 'EditCompareText' || targetId == 'EditSingleText') {
                showEditCompareDetailsClickHandler($target);
            } else if (targetId == 'EditCompareImages') {
                onViewImagesClickHandler($target);
            } else if ($target.hasClass('contentimagespresenter__detailduplicatebutton')) {
                onDetailDuplicateButtonClickHandler($target);
            } else if ($target.hasClass('contentimages__closebutton')) {
                onViewImagesClickHandler();
            } else if ($target.hasClass('createNewGoalID')) {
                onAssignToNewGoalId($target);
            }
        };

        var onAssignToNewGoalId = function($target) {
            var supplier = $target.data('supplier')
                ,productcode = $target.data('productcode');
            swal({
                    title: Translator.trans('', {}, 'cms'),
                    text: Translator.trans('contentstore.general.feedback.assigntonewgoalid.confirmtext', {}, 'cms'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: Translator.trans('Yes', {}, 'cms'),
                    cancelButtonText: Translator.trans('No', {}, 'cms'),
                    closeOnConfirm: true
                },
                function(isConfirm) {
                    if (isConfirm !== true) {
                        return false;
                    } else {
                        assignToNewGoalId(supplier, productcode);
                    }
                });
        };

        var assignToNewGoalId = function(supplier, productcode) {
            var _supplier = supplier
                ,_productcode = productcode
                ,loaderTargetContainer = $('.cmscontentstore--productdetail');

            $.ajax({
                url: Routing.generate('cms_goal_createnewgoalbyproduct'),
                data: {
                    supplier: _supplier
                    ,productcode: _productcode
                },
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = loader.getGeneratedKeyByPrefix("assignToNewGoalId");
                    loader.startLoader(e.loaderKey, loaderTargetContainer);
                }
            })
            .done(function(data){
                var url = Routing.generate('cms_contentstore_product_search', {
                    'tocode': _supplier
                    ,'productcode': _productcode
                });
                swal({
                        title:  Translator.trans('', {}, 'cms'),
                        text:  Translator.trans('contentstore.general.feedback.assigntonewgoalid.success', {}, 'cms'),
                        type: 'success',
                        confirmButtonColor: '#3085d6',
                        closeOnConfirm: true
                    },
                    function() {
                        document.location.href = url;
                    });
            });
        };

        var onDetailDuplicateButtonClickHandler = function($target) {
            var duplicateUrl = $target.data('duplicateurl');
            $.publish('presentersearch.contentimagespresenter', duplicateUrl);
        };

        var onDownloadEdfHandler = function($btn) {
            window.location.href = Routing.generate('cms_contentstore_downloadedf', {'supplier': $btn.data('supplier'), 'productcode': $btn.data('productcode') });
        };

        var addFilterHandler =  function() {
            var input = $('#contentstore-filter-tocode');
            if (input.length > 0) {
                var tocdes = [];
                $.each(input.data('values'), function(k,v){
                    tocdes.push({
                        id: k,
                        text: v
                    });
                });
                input.select2({
                    data: tocdes,
                    /*createSearchChoice: function(term) {
                        return {
                            id: term,
                            text: term + ' (new)'
                        };
                    }*/
                });
            }

            $('#contentstorefilterpreferencesmodal').on('shown.bs.modal', onShowPreferenceModalHandler);
            $('#contentstorefiledownloadmodal').on('shown.bs.modal', onShowFileDownloadModalHandler);

            $("#contentstore-filter-form button[type='reset']").on('click', onFormResetHandler);
            $('#contentstore-filter-form').on('submit', onFormSubmitHandler );
        };

        var onShowFileDownloadModalHandler = function(e) {
            var modalform = $(e.currentTarget);
            /*if (!isModalSelect2Loaded) {
                modalform.find('#js-filedownload-select-inclang').select2();
                modalform.find('#js-filedownload-select-exclang').select2();
                isModalSelect2Loaded = true;
            }*/
            modalform.find('#SubmitRequestDownload').off('click', onSubmitRequestDownload);
            modalform.find('#SubmitRequestDownload').on('click', onSubmitRequestDownload);
        };

        var onSubmitRequestDownload = function(e) {
            var modalContainer = $('#contentstorefiledownloadmodal')
                ,formContainer = modalContainer.find('#CsvDownloadForm')
                ,incLangContainer = formContainer.find('#js-filedownload-select-inclang')
                ,excLangContainer = formContainer.find('#js-filedownload-select-exclang')
                ,data = {
                    'incLang' : incLangContainer.val()
                    ,'excLang' : excLangContainer.val()
                }
                ,loaderTargetContainer = modalContainer.find('.modal-content');
            $.ajax({
                url: Routing.generate('cms_contentstore_requestlanguagedownload'),
                data: data,
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "submitRequestDownload";
                    loader.startLoader(e.loaderKey, loaderTargetContainer);
                }
            })
            .done(function(data){
                var promise = DownloadController.getInstance().startCheckStatus(data.data);
                promise.done(function(){
                    incLangContainer.select2("val", null);
                    excLangContainer.select2("val", null);
                    modalContainer.modal('hide');
                });
            });
            return false;
        };

        /**
         * displays filter preference modal / popup
         *
         * @param Event e               show modal event
         * @return void
         */
        var onShowPreferenceModalHandler = function (e) {

            var filters = Cookies.get('contentstore_filter_filter'),
                preselectedFilters;

            $('#contentstorefilterpreferencesmodal input').prop('checked', false);

            if (typeof filters === "undefined") {
                $('#contentstorefilterpreferencesmodal input').prop('checked', true);
                return;
            }

            preselectedFilters = filters.split(';');

            $(preselectedFilters).each(function(k, v) {
                $('#contentstore-filter-preferences-modal-' + v).prop('checked', true);
            });

        };

        /**
         * initializes form reset and re-filters list with the now empty set
         *
         * @param Event e                   form reset Event
         * @return void
         */
        var onFormResetHandler = function(e){
            e.preventDefault();
            $("#contentstore-filter-form .select2 ").select2("val", null);
            // disable rematching button
            CMS.CONTENTSTORE.MATCHING.Products.getInstance().initRematchingProducts();
            // $("#contentstore-filter-form")[0].reset();
            $("#contentstore-filter-form .form-control").val('');
            $("#FilterFormSubmit").trigger('click');
        };

        var showEditCompareDetailsClickHandler = function($btn) {
            if (unsavedChangeHandler.checkUnsavedChange(preventLoadDetailClassArray)) {
                return;
            }
            $btn = $btn || $('#EditCompareText, #EditSingleText');
            if(editViewTextsIsOpen) {
                $btn.removeClass('btn-success').addClass('btn-primary');
                hideEditViewText();
                editViewTextsIsOpen = false;
            } else {
                $btn.removeClass('btn-primary').addClass('btn-success');
                showEditViewText();
                editViewTextsIsOpen = true;
            }

        };

        var hideEditViewText = function() {
            $detailsTextContainer.addClass('content__detailstextcontainer--hidden');
        };

        var showEditViewText = function() {
            $detailsTextContainer.removeClass('content__detailstextcontainer--hidden');
        };

        var hideEditViewImages = function() {
            $detailsImagesContainer.addClass('content__detailsimagescontainer--hidden');
        };

        var showEditViewImages = function() {
            $detailsImagesContainer.removeClass('content__detailsimagescontainer--hidden');
        };

        var initDetailsTextContainer = function() {
            $detailsTextContainer = $('.js-content__detailstextcontainer');
            editViewTextsIsOpen = false;
        };

        var initDetailsImagesContainer = function() {
            $detailsImagesContainer = $('.js-content__detailsimagescontainer');
            editViewImagesIsOpen = false;
        };

        /**
         * submits filterform
         *
         * @param Event e           form submit event
         * @return Boolean          ... false
         */
        var onFormSubmitHandler = function(e) {
            e.preventDefault();

            if(0 < popmessagequeue.count){
                displayUnsavedChangesWarning();
                return;
            }
            window.history.pushState({}, window.document.title, Routing.generate('cms_contentstore_product'));

            filterSearch(true);

            return false;
        };

        /**
         * executes filtering and (if wanted) re-initializing/-drawing of tables
         *
         * @param Boolean openPanels
         * @param Boolean redraw
         *
         * @return void
         */
        var filterSearch = function(openPanels, redraw) {
            loader.startLoader("filter", $("#contentstore-filter-form"), ["productList", "productDetail"]);

            $('.cmscontentstore--productdetail').html("");
            goalMatchingHistoryController.destroyTable();
            // resetCreateEdfButton();
            var params = {};

            var listgrouping  = $('#contentstore-filter-productlistgrouping').val();

            var tableAction =  "cms_contentstore_product_list";
            var tableId = "#cmscontentstoreproducttable";

            if("true" === listgrouping){
                tableAction =  "cms_contentstore_product_groupedlist";
                tableId = "#cmscontentstoregroupedtable";
            }

            $('#contentstore-filter-form input,select').each(function(k, v) {
                if ($(v).val() === null || 0 >= $(v).val().length ) {
                    return true;
                }

                if($(v).prop('name') === 'dlc'){
                    params[$(v).prop('name')] = $(v).val().toUpperCase();
                }else{
                    params[$(v).prop('name')] = $(v).val();
                }
            });

            if(listgrouping === "true"){
                $('#productlistresultwrapper').hide();
                $('#groupedlistresultwrapper').show();
            }else{
                $('#productlistresultwrapper').show();
                $('#groupedlistresultwrapper').hide();

            }

            var table =$(tableId).DataTable();
            if(redraw) {
                table.ajax.reload(function() {}, false );
            } else {
                table
                    .ajax
                    .url(Routing.generate(tableAction, params))
                    .load(function() {});
            }
        };

        /**
         * initial function call
         *
         * @return void
         */
        var init = function($contentstoreElement) {
            loader = Loader.getInstance();
            $contentStore = $contentstoreElement;
            sysLangs = $contentStore.data('syslangs');
            addHandlers();
            loader.startLoader("filter", $("#contentstore-filter-form"), ["productList", "productDetail"]);
            productfilterlist_ini();
        };

        /**
         * triggers (and processes) ajax product details
         *
         * @param String action     routing key
         * @param Object data   data to be submitted to the targeted service
         *
         * @return void
         */
        var loadProductDetail = function(action, data, targetContainer, jumpToPanelBtn) {
            if (unsavedChangeHandler.checkUnsavedChange(preventLoadDetailClassArray)) {
                return;
            }

            var loaderTargetContainer = targetContainer || $('.content')
                ,url = Routing.generate(action, data)
                ,jumpToPanelBtnClass = jumpToPanelBtn || '.cmscontentstore--productdetail-btn';

            $.ajax(url, {
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "productDetail";
                    loader.startLoader(e.loaderKey, loaderTargetContainer);
                }
            })
            .done(function(data) {
                var detailContainer = $('.cmscontentstore--productdetail');
                var historyContainer = $('.js-goalmatchinghistory-panel');
                if (data.view) {
                    detailContainer.html(data.view);
                    detailContainer.on('ifChecked', '.table-responsive .checkbox input[type=checkbox]', function(e){
                        var self = $(this),
                            parentTD = self.parent().parent().parent(),
                            parentTRs = parentTD.parent().siblings();
                        if (parentTRs.length>0) {
                            parentTRs.each(function(i,v){
                                var checkbox = $(v).find('.'+parentTD.attr('class')+' .checkbox input[type=checkbox]');
                                setTimeout(function(){
                                    checkbox.prop('checked', false);
                                }, 1);
                            });
                        }
                    });
                    if ($('.js-contentstore-featurelist').length > 0) {
                        FeatureDetailsController.getInstance().init();
                    }
                    RoomsController.getInstance().init();
                    ContentTextsController.getInstance().init(sysLangs);
                    ProductGlobalTypesController.getInstance().init();
                    $(jumpToPanelBtnClass).click();
                    initDetailsTextContainer();
                    initDetailsImagesContainer();
                    if ($('.js-contentstore-map').length > 0) {
                        initMap();
                    }
                    initContentImagePresenter();
                    goalMatchingHistoryController.init();
                    if (historyContainer.find('.fa-chevron-up').length > 0) {
                        historyContainer.find('.showhide').click();
                    }
                    CMS.CONTENTSTORE.AssignManualGoalID.getInstance();
                }
            });
        };

        var initMap = function() {
            var mapContainer = $('#MapContainer')
                ,loaderTargetContainer = mapContainer.find('.panel')
                ,edfList =  $('.js-contentstoregroup-edfs')
                ,goalData = getGoalData(mapContainer.data('goalid'))
                ,giataData = getGiataData(mapContainer.data('giataid'))
                ,edfTemplates = getEdfTemplates(edfList)
                ,self = this
                ,groupLoaderMember = [];
            if (mapContainer.data('goalid')) {
                groupLoaderMember.push('goalData');
            }
            if (mapContainer.data('giataid')) {
                groupLoaderMember.push('giataData');
            }
            if (edfList.length > 0) {
                groupLoaderMember.push("edfTmpl");
            }
            if (groupLoaderMember.length > 0) {
                loader.startGroupLoader("initMap", loaderTargetContainer, groupLoaderMember);
            }
            $.when(goalData, giataData, edfTemplates)
                .done(function(goalResponse, giataResponse, edfResponse) {
                    var $map = $('.content__map .flexmap__map');
                    var coords = {};
                    var addMarkers = false;
                    if (edfList.length > 0 && edfResponse[0] && edfResponse[0].data) {
                        var edfArray = [];
                        $.each(edfResponse[0].data, function(k, v){
                            var edf = v;
                            if (edf.lat && edf.lon) {
                                edfArray.push(edf);
                            }
                        });
                        coords.edf = edfArray;
                        addMarkers = true;
                    }
                    if( goalResponse[0] && goalResponse[0].goal ) {
                        var goal = goalResponse[0].goal;
                        if (goal.latitude && goal.longitude) {
                            coords.goal= {
                                    type:'goal'
                                    ,id: goal.goalid.value
                                    ,lat:goal.latitude.value
                                    ,lon:goal.longitude.value
                                    ,pwInfowindowContent: goalResponse[0].pwInfowindowContent
                                    ,pwAddMarkerZoomButton: 'map.buttons.zoomtogoal'
                                };
                            addMarkers = true;
                        }

                    }
                    if( giataResponse[0] && giataResponse[0].giata ) {
                        var giata = giataResponse[0].giata;
                        if (giata.latitude && giata.longitude) {
                            coords.giata = {
                                    type:'giata'
                                    ,id: giata.giataid.value
                                    ,lat:giata.latitude.value
                                    ,lon:giata.longitude.value
                                    ,pwInfowindowContent: giataResponse[0].pwInfowindowContent
                                    ,pwAddMarkerZoomButton: 'map.buttons.zoomtogiata'
                                };
                            addMarkers = true;
                        }

                    }
                    if ($map.length > 0) {
                        var options = $map.data('mapoptions');
                        options['center'] = [options.latitude, options.longitude];
                        new mapsCtx.MapDrawWrapper(options);
                        if (addMarkers) {
                            $.publish('cms.contentstore.map.load_data', coords);
                        }
                    }
                });
        };

        var getEdfTemplates = function(edfList) {
            var promise = $.Deferred().resolve({'status':'noedftemplates'}).promise();
            if (edfList.length > 0) {
                var edfs = [];
                $.each(edfList.children(), function (k, v) {
                    var edf = $(this);
                    edfs.push({
                        id: edf.data('key')
                        ,latitude:edf.data('lat')
                        ,longitude:edf.data('lon')
                    });
                });
                promise = $.ajax({
                    url:Routing.generate('cms_contentstore_edfiwtemplates', edfs),
                    beforeSend: function(e, xhr, options) {
                        e.groupLoaderKey = "initMap";
                        e.groupLoaderMemberKey = "edfTmpl";
                    }
                });
            }
            return promise;
        };

        var getGoalData = function(goalid) {
            var promise = $.Deferred().resolve({'status':'nogoal'}).promise();
            if (goalid) {
                promise = $.ajax({
                    url:Routing.generate('cms_contentstore_goal', {'goalid':goalid}),
                    beforeSend: function(e, xhr, options) {
                        e.groupLoaderKey = "initMap";
                        e.groupLoaderMemberKey = "goalData";
                    }
                });
            }
            return promise;
        };

        var getGiataData = function(giataid) {
            var promise = $.Deferred().resolve({'status':'nogiata'}).promise();
            if (giataid) {
                promise = $.ajax({
                    url:Routing.generate('cms_contentstore_giata', {'giataid':giataid}),
                    beforeSend: function(e, xhr, options) {
                        e.groupLoaderKey = "initMap";
                        e.groupLoaderMemberKey = "giataData";
                    }
                });
            }
            return promise;
        };

        /**
         * adds unsaved content popmessage to TextMaster Select Table
         *
         * @param Event e                       change Event
         * @return void
         */
        var onMasterCheckChangeHandler = function(e){
            if(0 === $('.panel-text-form .alert-danger').length){

                var messagePop = $('<div/>',{
                   class: "alert alert-danger unsavedcontentpop",
                   text: " "+Translator.trans('contentstore.general.feedback.unsavedcontent', {}, 'cms'),
                   "data-name": "masterTextSelectTable",
                   click: onClickUnsavedWarningMessageHandler
                }).prepend($('<i/>',{ class: 'fa fa-warning' }));

                messagePop.hide();

                popmessagequeue.masterTextSelectTable = messagePop;
                popmessagequeue.count++;

                $('.panel-text-form .panel-heading').after(messagePop);
            }

            $('#TextCompareAndMasterForm button.btn-save').addClass('btn-danger').removeClass('btn-outline btn-default');

        };

        var onClickUnsavedWarningMessageHandler = function(e){
            if( "undefined" != typeof(popmessagequeue[$(this).data("name")]) ){
                popmessagequeue[$(this).data("name")].slideUp(100, function(){$(this).remove();});
                popmessagequeue.count--;
            }
        };

        var unregisterWarningMessage = function(key) {

            popmessagequeue[key].remove();
            popmessagequeue.count--;
        };

        var displayUnsavedChangesWarning = function(){
            toastr.error("There are edited Elements on this page. Changes will be lost if left unsaved! ", "Attention");

            for(var i in popmessagequeue){
                if('count' == i)
                    continue;

                popmessagequeue[i].slideDown();
            }
        };


        /**
         * switches visibility of image overview
         *
         * @param Event e
         * @return void
         */
        var onViewImagesClickHandler = function($btn) {
            $btn = $btn || $('#EditCompareImages');
            if(editViewImagesIsOpen) {
                $btn.removeClass('btn-success').addClass('btn-primary');
                hideEditViewImages();
                editViewImagesIsOpen = false;
                if (contentImagePresenter) {
                    contentImagePresenter.destroy();
                    contentImagePresenter = false;
                }
            } else {
                $btn.removeClass('btn-primary').addClass('btn-success');
                var marginBottom = (parseInt($('.js-content__detailsimagescontainer').find('.panel').first().css('marginBottom')));
                $("html, body").animate({ scrollTop: $('.js-content__detailsimagescontainer').offset().top - marginBottom}, "slow");
                contentImagePresenter = new presenterCtx.Presenter(options.imagePresenter);
                showEditViewImages();
                editViewImagesIsOpen = true;
                contentImagePresenter.load();
            }
        };

        var saveFilterPreferences = function (e) {
            var cookieValues = [];

            $('#contentstorefilterpreferencesmodal .checkbox input:checked').each(function(k,v) {
                //cookieValues.push($(v).val());
                cookieValues.push(this.value);
            });

            $('#contentstorefilterpreferencesmodal .hidden input').each(function() {
                cookieValues.push(this.value);
            });

            Cookies.set('contentstore_filter_filter', cookieValues.join(';'));

            $.ajax(Routing.generate('cms_contentstore_product_filter'), {
                success: function(data, status, xhr) {
                    $('.cmscontentstore--filter').html(data);
                    addFilterHandler();
                    // synchronizing special hidden fields values manually:
                    $('#contentstore-filter-productlistgrouping').val($('#groupedlistresultwrapper').is(':visible'));

                    var inputs = $('#contentstorefilterpreferencesmodal').find('.checkbox input[type=checkbox]');

                    inputs.each(function(k,v){
                        if($.inArray($(v).val(), cookieValues) != -1) {
                            $(v).prop('checked', true);
                        }
                    });
                    filterSearch(true);
                }
            });
        };

        var initContentImagePresenter = function() {
            if ($('.js-contentimagespresenter').length < 1) {
                return false;
            }
            if (contentImagePresenter) {
                contentImagePresenter.destroy();
                contentImagePresenter = false;
            }
        };


        return {
            saveFilterPreferences: saveFilterPreferences,

            loadProductDetailPage: function(e) {
                loadProductDetail('cms_contentstore_product_detail', {
                    supplier: $(this).data('supplier'),
                    productcode: $(this).data('productcode'),
                    roomlocalization: $(this).data('roomlocalization')
                });
            },
            loadProductDetailPageByKey: function(supplier, productcode, loaderTargetContainer) {
                return loadProductDetail('cms_contentstore_product_detail', {supplier: supplier, productcode: productcode, roomlocalization: getRoomLocalization()}, loaderTargetContainer);
            },
            loadProductDetailPageByKeyAndRoomLocalization: function(supplier, productcode, roomlocalization, loaderTargetContainer, jumpToPanelBtn) {
                return loadProductDetail('cms_contentstore_product_detail', {supplier: supplier, productcode: productcode, roomlocalization}, loaderTargetContainer, jumpToPanelBtn);
            },
            loadProductDetailPageByGroup: function(e) {
                var groupid = (typeof e == "object")?$(this).data('groupid'):e;
                return loadProductDetail('cms_contentstore_product_detail_by_group', {groupid: groupid});
            },
            loadProductDetailPageByGroupId: function(groupId, loaderTargetContainer) {
                return loadProductDetail('cms_contentstore_product_detail_by_group', {groupid: groupId}, loaderTargetContainer);
            },
            updateProductList : function(){
                filterSearch(false, true);
            },
            setPopmessagequeue: function(param) {
                popmessagequeue = param;
            },
            getPopmessagequeue: function(){
                return popmessagequeue;
            },
            getOnClickUnsavedWarningMessageHandler: function(){
              return onClickUnsavedWarningMessageHandler;
            },
            doUnregisterWarningMessage: function(param){
                unregisterWarningMessage(param);
            },
            filterSearch: function(a,b){
                filterSearch(a,b);
            },
            loadProductDetail: function(a, b, loaderTargetContainer){
                loadProductDetail(a,b, loaderTargetContainer);
            },
            loadContentStoreDataTable: productfilterlist_ini
            ,init: init
            ,showEditCompareDetailsClickHandler: showEditCompareDetailsClickHandler
        };
    }
    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

$(function() {
    var $element = $('.js-init-contentstore');
    if ($element.length > 0) {
        CmsContentStore.getInstance().init($element);
        // CmsContentStore.getInstance().loadContentStoreDataTable();
    }
});
