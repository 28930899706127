$(function() {

    var scrollToTopEvent = function() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    };

    var scrollToBottomEvent = function() {
        $("html, body").animate({ scrollTop: document.body.scrollHeight }, "slow");
        return false;
    };




    if (!Element.prototype.scrollIntoViewIfNeeded) {
        Element.prototype.scrollIntoViewIfNeeded = function (centerIfNeeded) {
            function withinBounds(value, min, max, extent) {
                if (false === centerIfNeeded || max <= value + extent && value <= min + extent) {
                    return Math.min(max, Math.max(min, value));
                } else {
                    return (min + max) / 2;
                }
            }

            function makeArea(left, top, width, height) {
                return  { "left": left, "top": top, "width": width, "height": height
                    , "right": left + width, "bottom": top + height
                    , "translate":
                        function (x, y) {
                            return makeArea(x + left, y + top, width, height);
                        }
                    , "relativeFromTo":
                        function (lhs, rhs) {
                            var newLeft = left, newTop = top;
                            lhs = lhs.offsetParent;
                            rhs = rhs.offsetParent;
                            if (lhs === rhs) {
                                return area;
                            }
                            for (; lhs; lhs = lhs.offsetParent) {
                                newLeft += lhs.offsetLeft + lhs.clientLeft;
                                newTop += lhs.offsetTop + lhs.clientTop;
                            }
                            for (; rhs; rhs = rhs.offsetParent) {
                                newLeft -= rhs.offsetLeft + rhs.clientLeft;
                                newTop -= rhs.offsetTop + rhs.clientTop;
                            }
                            return makeArea(newLeft, newTop, width, height);
                        }
                };
            }

            var parent, elem = this, area = makeArea(
                this.offsetLeft, this.offsetTop,
                this.offsetWidth, this.offsetHeight);
            while ((parent = elem.parentNode) instanceof HTMLElement) {
                var clientLeft = parent.offsetLeft + parent.clientLeft;
                var clientTop = parent.offsetTop + parent.clientTop;

                // Make area relative to parent's client area.
                area = area.
                relativeFromTo(elem, parent).
                translate(-clientLeft, -clientTop);

                parent.scrollLeft = withinBounds(
                    parent.scrollLeft,
                    area.right - parent.clientWidth, area.left,
                    parent.clientWidth);

                parent.scrollTop = withinBounds(
                    parent.scrollTop,
                    area.bottom - parent.clientHeight, area.top,
                    parent.clientHeight);

                // Determine actual scroll amount by reading back scroll properties.
                area = area.translate(clientLeft - parent.scrollLeft,
                    clientTop - parent.scrollTop);
                elem = parent;
            }
        };
    }

    $(document)
        .scroll(function(){
          if ($(this).scrollTop() > 100) {
            $('#to-top').fadeIn();
          } else {
            $('#to-top').fadeOut();
          }
          if ($(this).scrollTop() < document.body.scrollHeight - 2000) {
              $('#to-bottom').fadeIn();
          } else {
              $('#to-bottom').fadeOut();
          }
        }
    );

    $(document.body)
        .append(
            $('<a/>',{
                style: 'opacity:1;',
                id: 'to-top',
                href: '#',
                'data-toggle': 'tooltip',
                'data-placement': 'left',
                'data-original-title': Translator.trans('core.tooltips.scrolltotop', {}, 'core'),
                click: scrollToTopEvent
            }).prepend($('<i/>',{ class: 'fa fa-chevron-up' }))
        )
        .append(
            $('<a/>',{
                style: 'opacity:1;',
                id: 'to-bottom',
                href: '#',
                'data-toggle': 'tooltip',
                'data-placement': 'left',
                'data-original-title': Translator.trans('core.tooltips.scrolltobottom', {}, 'core'),
                click: scrollToBottomEvent
            }).prepend($('<i/>',{ class: 'fa fa-chevron-down' }))
        );

    if (!getRoomLocalization()) {
        setRoomLocalization('en');
    }
});

var addScrollButtons = function(options) {

    if (!options) {
        return;
    }
    var parentDiv  = $('<div/>',{class:'anchornav'})
        ,hPanelMarginBottom = 0;
    $(document.body)
        .append(
            parentDiv
        );
    $.each(options, function(key, object){
        var button = {}
            ,top = ""
            ,titleKey = object.title.replace(/ /g,'').toLowerCase();
        hPanelMarginBottom = (!hPanelMarginBottom)
                ? (parseInt($(object.anchor).find('.hpanel').first().css('marginBottom')) || 0)
                : hPanelMarginBottom;
        if (key > 0) {
            button = $('#JumpToButton-'+(key-1));
            if (button.length > 0) {
                top = parseInt(button.css('top')) + button.outerWidth(true);
                top  = 'top:' + top + 'px;';
            }
        }
        parentDiv
            .append(
                $('<a/>',{
                    style: top,
                    id: 'JumpToButton-'+key,
                    class: 'jumptobtn btn btn-primary ' + object.anchor.substring(1) +'-btn',
                    text: Translator.trans('core.anchorbuttons.'+titleKey, {}, 'core'),
                    'data-toggle': 'tooltip',
                    'data-placement': 'left',
                    'data-original-title': Translator.trans('core.tooltips.scrollto'+titleKey, {}, 'core'),
                    click: function(e) {
                        e.preventDefault();
                        $("html, body").animate({ scrollTop: (($(object.anchor).offset().top - hPanelMarginBottom) - $('#header').height()) }, "slow");
                    }
                })
            );
    });


    return false;
};


var getInternalSupplier = function() {
    return $("html, body").data('internalsupplier');
};

var extractSupplierAndProductCodeFromEDFKey = function(edfkey) {
    var supplierProduct = edfkey.split('EDF-----')[1],
        supplierProductArray = supplierProduct.split('-');
    return {
        'supplier': supplierProductArray[0],
        'productcode': supplierProductArray[1]
    };
};

var setRoomLocalization = function(locale) {
    localStorage.setItem('cos-room-localization', locale);
};

var getRoomLocalization = function() {
    return localStorage.getItem('cos-room-localization');
};
