var FeatureMatchingController = (function(){

    var instance
        ,featureMappingContainer = '.cmsfm--resulttable'
        ,resultProductDatatable = '#FmResultProductTable'
        ,unsavedInputIds = {}
        ,loader = Loader.getInstance()
        ,resultProductDatatableLoaderId
        ,filterInstance
        ,table
        ,isCentral
        ,options = {
            filterFormId: 'featurematching-filter-form'
            ,matchingStatus: 'matchingstatus-ismatched'
            ,filterSupplier: 'featurematching-filter-tocode'
        };


    function createInstance() {

        var isModalSelect2Loaded = false;

        /**
         * collection of eventhandler binds
         *
         * @return void
         */
        var addHandlers = function () {
            filterInstance = FilterForm.getInstance();
            if(isCentral) {
                $('#FilterFeatureMatchingFileDownloadModal').hide();
            }
            // $('#featurematching-filter-form').on('submit', onFormSubmitHandler );
            // $("#featurematching-filter-form button[type='reset']").on('click', onFormResetHandler);
            $('#featurematchingfiledownloadmodal').on('shown.bs.modal', onShowFileDownloadModalHandler);
            $('#fm_saveall').on('click', saveAllEventHandler );
            $('#fm_cancelall').on('click', cancelAllEventHandler );
            filterInstance.addToCodeSelect2Field(options.filterFormId, options.filterSupplier);
            filterInstance.addThreeWaySwitch(options.filterFormId, options.matchingStatus);

            if (!isModalSelect2Loaded) {
                $('#featurematchingfiledownloadmodal').find('#js-filedownload-select-globaltypes').select2({
                    minimumInputLength: 2,
                    placeholder: ' ',
                    ajax: {
                        url: Routing.generate('cms_featurematching_grouptypelist'),
                        dataType: 'json',
                        data: function (term, page) {
                            return {
                                'type': 'globalType',
                                'query': term,
                                'count': 20,
                                'page': page
                            };
                        },
                        results: function (data, page) {
                            var more = (page * 20) < data.total_count;
                            return {
                                results: data.items,
                                more: more
                            };
                        }
                    },
                    escapeMarkup: function (m) { return m; },
                    initSelection: function(element, callback) {
                        callback({
                            'id': $(element).data('org'),
                            'label': $(element).data('text')
                        });
                    },
                    formatSelection: function(element) {
                        var item = '',
                            caption = '',
                            label = '';

                        if (typeof element.id  !== 'undefined') {
                            item = item + element.id;
                            caption = $( "<div/>", {
                                'class': 'select2_option_item_text'
                                ,'title': (typeof element.label  !== 'undefined' && element.label.length > 0)
                                    ?element.label:''
                            }).append(element.id)[0].outerHTML;
                            item = caption;
                        }
                        /*if (typeof element.label  !== 'undefined' && element.label.length > 0) {
                            label = $( "<div/>", {
                                'class': 'select2_option_item_label',
                                'title': element.label
                            }).append(element.label)[0].outerHTML;
                            item = item + ' ' + label;
                        }*/
                        return item;
                    },
                    formatResult: function(result) {
                        var color = 'iherit';
                        if (result.alternative==="true") {
                            color = '#005DCC';
                        }

                        var item = $(
                            '<span style="font-weight:bold;color:'+ color +';">' + result.id + '</span>'  +
                            '<div>' + result.label + '</div>'
                        );

                        return item;
                    }
                });
                isModalSelect2Loaded = true;
            }
        };

        var initFilter = function() {
            loader.startLoader("filter", $('#'+options.filterFormId), ["featureMatchingTable"]);
            filterInstance = FilterForm.getInstance();
            filterInstance.init({
                formid: options.filterFormId
                ,submit: onFormSubmitHandler
                ,addfields: true
            });

        };


        /**
        * initialze DataTable
        *
        */
        var initDatatable = function() {
            var dtRoutingParameter = {};
            var startvalues = false;
            var $formWithStartvalues = $('.js-filterform--has-startvalues');
            if ($formWithStartvalues.length > 0) {
                startvalues = $formWithStartvalues.data('startvalues');
            }
            if (startvalues) {
                dtRoutingParameter = startvalues;
            }
            table
                .on( 'init.dt', function (e) {
                    /*if (loaderId) {
                        loader.remove(loaderId);
                        loaderId = false;
                    }*/
                })
                .on('preXhr.dt', function ( e, settings, data ) {
                    e.preventDefault();
                    e.stopPropagation();
                    //loaderId = loader.add(table);
                    return false;
                } )
                .on('xhr.dt', function ( e, settings, data ) {
                    //if(e.currentTarget.clickedPaging) {
                        /*if (loaderId) {
                            loader.remove(loaderId);
                            loaderId = false;
                        }*/
                    //}
                    //e.currentTarget.clickedPaging = false;
                })
                .on( 'length.dt', function(e,s,l) {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    return false;
                })
                .on( 'order.dt', function(e){
                    /*
                    if(0 < unsavedChanges){
                        displayUnsavedContentWarning();
                        e.stopImmediatePropagation();
                        return false;
                    }
                    */
                })
                .on( 'page.dt', function(e, settings) {
                    /*if (!loaderId) {
                        loaderId = loader.add($(e.delegateTarget));
                    }*/
                    e.currentTarget.clickedPaging = true;
                })

                .on( 'draw.dt', function(e, settings) {
                    attachSelectControlsForGlobalTypes();
                })

                .dataTable({

                    ajax: {
                        url: Routing.generate('cms_featurematching_list',dtRoutingParameter),
                        beforeSend: function(e, xhr, options) {
                            e.loaderKey = "featureMatchingTable";
                            loader.startLoader(e.loaderKey, table);
                        }
                    },

                    serverSide: true,
                    lengthChange: true,
                    searching: false,
                    pagingType: 'simple_numbers',
                    order: [ [ isCentral ? 2 : 3, 'asc' ]],
                    pageLength: 10,
                    columns: getColumns(isCentral),
                    language: {
                        "lengthMenu": "Display _MENU_ records",
                        paginate: {
                            next: Translator.trans('featurematching.table.paginate.next', {}, 'cms'),
                            previous: Translator.trans('featurematching.table.paginate.previous', {}, 'cms')
                        },
                        thousands: ".",
                        info: Translator.trans('featurematching.table.info', {}, 'cms'),
                        infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms'),
                        emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
                    },
                    drawCallback: function( oSettings ){
                        // var oSettings = this.fnSettings();
                        if( oSettings.fnRecordsTotal() <= oSettings._iDisplayLength){
                            $(resultProductDatatable+'_paginate').hide();
                        }else{
                            $(resultProductDatatable+'_paginate').show();
                        }
                        recalculateGtItemEvenOddAll();
                    }
                });
        };

        var getColumns = function(isCentral) {
            var columns = [];
            columns.push({name:'charged',
                    data: function (row, type, set, meta) {
                    if(row.charged === "true") {
                        return  $( "<div/>").append(
                            $( "<i/>", {'class':'fa fa-money'})
                        )[0].outerHTML;
                    } else {
                        return  $( "<div/>")[0].outerHTML;
                    }
                }, sortable: false, width: '2em' });
            columns.push({name:'id', data: 'id',  sortable: false, visible: false});
            columns.push({name:'feature', data: 'feature',  sortable: true});
            columns.push({name:'relation', data: 'relation',  sortable: false});
            columns.push({name:'supplier', data: 'supplier',  sortable: false});
            columns.push({name:'language',data: 'language',  sortable: true, width: '1em'});
            columns.push({name:'gts', data: function (row, type, set, meta) {
                    var output = '';
                    row.gts.forEach(function(el, index, array){
                        output = output + generateGtTableContent('globaltype', row.id, index, el);
                    });
                    if (row.gts.length === 0) {
                        output = generateGtTableContent('globaltype', row.id, 0, {});
                    }
                    return output;
                },  sortable: false});

            columns.push({name: 'gts', data: function (row, type, set, meta) {
                    var output = '';
                    row.gts.forEach(function(el, index, array){
                        output = output + generateGtTableContent('subtype', row.id, index, el);
                    });
                    if (row.gts.length === 0) {
                        output = generateGtTableContent('subtype', row.id, 0, {});
                    }
                    return output;
                },  sortable: false});

            columns.push({name: 'gts', data: function (row, type, set, meta) {
                    var output = '';
                    row.gts.forEach(function(el, index, array){
                        output = output + generateGtTableContent('additionaltype', row.id, index, el);
                    });
                    if (row.gts.length === 0) {
                        output = generateGtTableContent('additionaltype', row.id, 0, {});
                    }
                    return output;

                },  sortable: false});
            columns.push({data: function (row, type, set, meta) {
                    //create butttons for the 'action' column
                    var addBtn = $( "<button/>", {
                            'id':row.id + '-addgtbtn',
                            'class': 'btn btn-xs btn-default add-gt-btn',
                            'type': 'button',
                            'data-id':row.id,
                            'data-toggle': 'tooltip',
                            'data-original-title': Translator.trans('featurematching.table.row.addgtbutton', {}, 'cms'),
                            'data-language':row.language,
                            'data-feature':row.feature,
                            'data-key': row.key
                        } ).append(
                        $( "<span/>", {
                            'class': 'fa fa-plus',
                            'aria-hidden':true
                        } )
                        )[0].outerHTML,
                        saveBtn = $( "<button/>", {
                            'id':row.id + '-savebtn',
                            'class': 'btn btn-xs btn-default save-feature-btn hide',
                            'type': 'button',
                            'data-id':row.id,
                            'data-toggle': 'tooltip',
                            'data-original-title': Translator.trans('featurematching.table.row.savebutton', {}, 'cms'),
                            'data-language':row.language,
                            'data-feature':row.feature,
                            'data-key': row.key
                        } ).append(
                        $( "<span/>", {
                            'class': 'fa fa-save',
                            'aria-hidden':true
                        } )
                        )[0].outerHTML,
                        deleteBtn = $( "<button/>", {
                            'id':row.id + '-deletebtn',
                            'class': 'btn btn-xs btn-default delete-btn',
                            'type': 'button',
                            'data-toggle':'tooltip',
                            'data-id':row.id,
                            'data-original-title': Translator.trans('featurematching.table.row.deletebutton', {}, 'cms'),
                            'data-language':row.language,
                            'data-feature':row.feature,
                            'data-key': row.key
                        } ).append(
                            $( "<span/>", {
                                'class': 'fa fa-trash-o',
                                'aria-hidden':true
                            } )
                        )[0].outerHTML,

                        resetBtn = $( "<button/>", {
                            'id':row.id + '-resetbtn',
                            'class': 'btn btn-xs btn-default reset-btn',
                            'type': 'button',
                            'data-toggle': 'tooltip',
                            'data-original-title': Translator.trans('featurematching.table.row.resetbutton', {}, 'cms'),
                            'data-id':row.id,
                            'data-language':row.language,
                            'data-feature':row.feature,
                            'data-key': row.key
                        } ).append(
                            $( "<span/>", {
                                'class': 'fa fa-undo',
                                'aria-hidden':true
                            } )
                        )[0].outerHTML;
                    return addBtn + ' ' +
                        saveBtn + ' ' +
                        deleteBtn + ' ' +
                        resetBtn + ' ';

                },  sortable: false, width: '7em'});

            return columns;
        };

        /**
         * submits filterform
         *
         * @param Event e form submit event
         * @return Boolean false
         */
        var onFormSubmitHandler = function(e) {
            e.preventDefault();

            if(0 < getUnsavedChangesCount()){
                displayUnsavedContentWarning();
                return;
            }

            filterSearch();
            return false;
        };

        /**
         * download matching as csv file
         * @param Event e form event
         */
        var onShowFileDownloadModalHandler = function(e) {
            var modalform = $(e.currentTarget);
            modalform.find('#SubmitPrepareDownload').off('click', onSubmitPrepareDownload);
            modalform.find('#SubmitPrepareDownload').on('click', onSubmitPrepareDownload);
        };

        var onSubmitPrepareDownload = function(e) {
            e.preventDefault();
            var modalContainer = $('#featurematchingfiledownloadmodal')
                ,form = modalContainer.find('form')
                ,btn = $(this)
                ,infoMessage = form.find('.featurematchingfiledownloadmodal__infomessage')
                ,gtSelect = form.find('#js-filedownload-select-globaltypes')
                ,settypeRadio = form.find('.filedownload-radio-set:checked')
                ,referenceidRadio = form.find('.filedownload-radio-referenceid:checked')
                ,addgoalglobaltypes = form.find('.js-filedownload-check-goalgt')
                ,data = {
                    'gt' : gtSelect.val()
                    ,'set' : settypeRadio.val()
                    ,'referenceid' : referenceidRadio.val()
                    ,'addgoalglobaltypes': addgoalglobaltypes.prop('checked')
                };
            $.ajax({
                url: Routing.generate('cms_featurematching_requestdownloadglobaltypes'),
                data: data,
                beforeSend: function(e, xhr , options) {
                    e.loaderKey = "submitPrepareDownload";
                    loader.startLoader(e.loaderKey, modalContainer.find('.modal-content'));
                }
            })
            .done(function(data){
                DownloadController.getInstance().startCheckStatus(data.data);
                gtSelect.select2("val", null);
                modalContainer.modal('hide');
            });

            return false;
        };

        var onSubmitDownload = function(e) {
            var modalContainer = $('#featurematchingfiledownloadmodal')
                ,btn = $(this);
            btn.hide();
            btn.parent().find('#SubmitPrepareDownload').show();
            modalContainer.modal('toggle');
        };

        /**
         * executes filter action
         * @param {event} e
         */
        var filterSearch = function(e) {

            var params = {};
            var tableAction = "cms_featurematching_list";
            var tableId = "#FmResultProductTable";

            //var loaderId = loader.add($('.content'));
            loader.startLoader("filter", $(".cmsfeaturematching--filter"), ["featureMatchingTable"]);
            var params = filterInstance.getValues(options.filterFormId);
            // $('#featurematching-filter-form input,select').each(function(k, v) {
            //     if (0 >= $(v).val().length ) {
            //         return true;
            //     }
            //     params[$(v).prop('name')] = $(v).val();
            // });
            $('#featurematching-filter-form input, select').each(function(k, v) {
                if (!$(v).val() || 0 >= $(v).val().length) {
                    return true;
                }
                var item = $(v);

                var itemName = item.prop('name');
                var itemValue = item.val();

                if (item.is(':checkbox')) {
                    if (item.is(':checked')) {
                        params[itemName] = itemValue;
                    }
                } else if (item.is(':radio')) {
                    if (item.is(':checked')) {
                        params[itemName] = itemValue;
                    }
                } else {
                    params[itemName] = itemValue;
                }
            });

            var table =$(tableId).DataTable();
            table
                .ajax
                .url(Routing.generate(tableAction, params))
                .load(function() {
                    //loader.remove(loaderId);
                });
        };

        /**
         * cancels all changes on "one" page
         * @param {event} e
         */
        var cancelAllEventHandler = function(e){

            if(0 < getUnsavedChangesCount()){
                swal({
                    title: Translator.trans('featurematching.sweetalert.unsavedcontent.title', {}, 'cms'),
                    text: Translator.trans('featurematching.sweetalert.unsavedcontent.text', {}, 'cms'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: Translator.trans('featurematching.sweetalert.unsavedcontent.confirmButton', {}, 'cms'),
                    cancelButtonText: Translator.trans('featurematching.sweetalert.unsavedcontent.cancelButton', {}, 'cms'),
                    closeOnConfirm: true
                },
                function() {

                    // unsavedChanges = 0;
                    reloadDataTable();
                    clearUnsavedChanges();
                    // checkUnsavedChangesCount();

                });
            }
        };

        /**
         * toasts 'search disabled' error message
         */
        var displayUnsavedContentWarning = function() {
            toastr.error(
                Translator.trans('featurematching.toastr.unsavedcontent.message', {}, 'cms'),
                Translator.trans('featurematching.toastr.unsavedcontent.title', {}, 'cms')
            );
        };

        /**
         * attaches select2 control to textboxes inside the dataTable
         * for the GlobalType "autocomplete" functionality
         */
        var attachSelectControlsForGlobalTypes = function($elements){
            $elements = $elements || $( resultProductDatatable + ' tbody .cms-fm-datatable-textbox');
            $elements.each(function(){
                var $element = $(this);
                // var type = $(this).attr('id').split('-')[2],
                //     id = $(this).attr('id').split('-')[0],
                //     fieldCount = $(this).attr('id').split('-')[1];
                var id = $element.data('id'),
                    index = $element.data('index'),
                    type = $element.data('type'),
                    parent = '',
                    searchFor = '',
                    search_mapping =  {
                        'globaltype': 'globaltype',
                        'subtype': 'globaltype',
                        'additionaltype': 'subtype' },
                    type_mapping = {
                        'globaltype': 'globalType',
                        'subtype': 'subType',
                        'additionaltype': 'additionalType' };

                switch(type) {
                    case 'globaltype':
                        parent = '';
                        break;
                    case 'subtype':
                        parent = 'globaltype';
                        break;
                    case 'additionaltype':
                        parent = 'subtype';
                        break;
                }

                $(this).select2({
                    minimumInputLength: 2,
                    placeholder: ' ',
                    dropdownCssClass : 'bigdrop',
                    allowClear: true,
                    quietMillis: 250,
                    ajax: {
                        url: function() {

                            if (type!=='globaltype') {
                                searchFor = $('#' + id + '-' + search_mapping[type] + '-' + index).val();
                                // searchFor = $('#' + id + '-' + fieldCount + '-' + search_mapping[type]).val();
                            }
                            if (type==='additionaltype' && searchFor ==='') {
                                searchFor = $('#' + id + '-' + search_mapping['globaltype'] + '-' + index).val();
                                // searchFor = $('#' + id + '-' + fieldCount + '-' + search_mapping['globaltype']).val();
                            }
                            var uri = Routing.generate('cms_featurematching_grouptypelist', {
                                'type': type_mapping[type],
                                'gt': searchFor,
                                'count': 20
                            });
                            return uri;
                        },

                        dataType: 'json',
                        data: function (term, page) {
                            return {
                                query: term,
                                page: page
                            };
                        },
                        results: function (data, page) {
                            var more = (page * 20) < data.total_count;
                            return {
                                results: data.items,
                                 more: more
                            };
                        }
                    },
                    escapeMarkup: function (m) { return m; },
                    initSelection: function(element, callback) {
                        callback({
                            'id': $(element).data('org'),
                            'label': $(element).data('text')
                        });
                    },
                    formatSelection: function(element) {
                        var item = '',
                            caption = '',
                            label = '';

                        if (typeof element.id  !== 'undefined') {
                            item = item + element.id;
                            caption = $( "<div/>", {
                                'class': 'select2_option_item_text'
                            }).append(element.id)[0].outerHTML;
                            item = caption;
                        }
                        if (typeof element.label  !== 'undefined' && element.label.length > 0) {
                            label = $( "<div/>", {
                                'class': 'select2_option_item_label',
                                'title': element.label
                            }).append(element.label)[0].outerHTML;
                            item = item + ' ' + label;
                        }
                        return item;
                    },
                    formatResult: function(result) {

                        var color = 'iherit';
                        if (result.alternative==="true") {
                            color = '#005DCC';
                        }

                        var item = $(
                            '<span style="font-weight:bold;color:'+ color +';">' + result.id + '</span>'  +
                            '<div>' + result.label + '</div>'
                        );

                        return item;
                    }
                }).on("change", function(e) {

                    var $input,
                        $el = $(this),
                        index = $el.data('index'),
                        id = $el.data('id');

                    if (e.val) {
                        // $('#' + id + '-'+type).parent().addClass('recordEdited');
                        if( parent==='') {
                            $('#' + id + '-subtype-'+index).prop('disabled',false).select2('val', null);
                                // .parent().removeClass('cellDisabled');

                            $('#' + id + '-additionaltype-'+index).prop('disabled',false).select2('val', null);
                                // .parent().removeClass('cellDisabled');
                        }
                        addIdToUnsavedChanges(e.currentTarget.getAttribute('id'));
                    } else {
                        if ($el.data('type') === 'globaltype') {
                            var orgValue = $el.data('org') || false;
                            if (!orgValue) {
                                removeIdFromUnsavedChanges(id + '-globaltype-'+index);
                            } else {
                                addIdToUnsavedChanges(e.currentTarget.getAttribute('id'));
                            }

                            $('#' + id + '-subtype-'+index)
                                .prop('disabled',true)
                                .select2('val', null);
                            removeIdFromUnsavedChanges(id + '-subtype-'+index);

                            $('#' + id + '-additionaltype-'+index)
                                .prop('disabled',true)
                                .select2('val', null);
                            removeIdFromUnsavedChanges(id + '-additionaltype-'+index);

                                // .parent().removeClass('cellDisabled');
                        } else {
                            addIdToUnsavedChanges(e.currentTarget.getAttribute('id'));
                        }
                    }
                    checkUnsavedChangesCount();

                }).on('select2-focus', function(e){

                })
                ;

            });
        };

        /**
         * popsup a info alert
         * * * * * debug * * * *
         * @param {event} e
         */
        var infoButtonHandler = function(e) {
            var btn = $(this),
                id = btn.data('id');

                alert(
                    'gType : ' +  $('#'+id+"-globaltype").val() + '\n' +
                    'sType : ' +  $('#'+id+"-subtype").val()  + '\n' +
                    'aType : ' +  $('#'+id+"-additionaltype").val()  + '\n\n' +

                    'gType : ' +  $('#'+id+"-globaltype").attr('data-org') + '\n' +
                    'sType : ' +  $('#'+id+"-subtype").attr('data-org')  + '\n' +
                    'aType : ' +  $('#'+id+"-additionaltype").attr('data-org')  + '\n\n'
                );

        };

        var onDeactivateHandler = function(e) {
            var btn = $(this)
                ,loaderTargetContainer = btn.parents('#FmResultProductTable');
            $.ajax({
                    url: Routing.generate('cms_featurematching_changeactivationstatus'),
                    data: {
                        'featureKey': btn.data('key')
                        ,'action': 'deactivate'
                    },
                    beforeSend: function(e, xhr, options) {
                        e.loaderKey = "deactivateFeature";
                        loader.startLoader(e.loaderKey, loaderTargetContainer);
                    }
                })
                .done(function(data){
                    reloadDataTable(resultProductDatatableLoaderId);
                });
        };

        var onActivateHandler = function(e) {
            var btn = $(this)
                ,loaderTargetContainer = btn.parents('#FmResultProductTable');
            $.ajax({
                    url: Routing.generate('cms_featurematching_changeactivationstatus'),
                    data: {
                        'featureKey': btn.data('key')
                        ,'action': 'activate'
                    },
                    beforeSend: function(e, xhr, options) {
                        e.loaderKey = "activateFeature";
                        loader.startLoader(e.loaderKey, loaderTargetContainer);
                    }
                })
                .done(function(data){
                    reloadDataTable(resultProductDatatableLoaderId);
                });
        };

        /**
        * handler to reset all inputfields (and attached select2s) to their original value
        *
        * @param event  jquery event by triggering button
        *
        */

        var onResetTypesHandler = function(event) {

            var btn = $(this),
                id = btn.data('id');

            // Decrease unsaved changes count for additional fields with values and remove fields
            $('.featurematching__typecontainer--' + id + '[data-reset="false"]').each(function() {
                var $item = $(this).find('input.gtype');
                if ($item.select2('val') !== '') {
                    removeIdFromUnsavedChanges($item.attr('id'));
                }
            }).remove();

            var $inactiveTypeContainer = $('.featurematching__typecontainer--' + id + '[data-active="false"]');
            $inactiveTypeContainer.each(function() {
                var $container = $(this);
                var $item = $('input.gtype', $container);
                $container.attr('data-active', 'true');
                removeIdFromUnsavedChanges($item.attr('id'));
            });

            // re-apply odd/even class
            recalculateGtItemEvenOdd(id);

            $('input.'+id+"-type"    ).each(function(k,v) {
                var $obj = $(v),
                    value = $obj.val(),
                    orgValue = $obj.data('org'),
                    type = $obj.data('type');
                if ( value !== orgValue ) {
                    $(this).parent().removeClass('recordEdited');
                    $obj.select2('val', orgValue );
                    removeIdFromUnsavedChanges($obj.attr('id'));
                }
                if (type == 'globaltype' && orgValue !== '') {
                    var index = $obj.data('index');
                    $('#' + id + '-subtype-'+index)
                        .prop('disabled',false);
                    $('#' + id + '-additionaltype-'+index)
                        .prop('disabled',false);
                }
            });
            checkUnsavedChangesCount();
        };


        /**
         * save all changed content
         *
         */
        var saveAllEventHandler = function(e){

            var params = {
                action: 'save',
                features: getChangedFeaturesByChangedIds()
            };
            //give params to the matching edit handler
            updateFeatureMatchings(params);
        };

        var getChangedFeaturesByChangedIds = function () {
            var featureKeys = {};
            var features = [];
            getIdsFromUnsavedChanges().forEach(function(id) {
                var $container = $('#' + id).closest('.featurematching__typecontainer');
                var featureId = $container.data('id');
                var key = $('#' + featureId + '-savebtn').data('key');
                if (featureKeys.hasOwnProperty(featureId)) {
                    return;
                }
                featureKeys[featureId] = getFeatures(featureId, key);
            });
            Object.keys(featureKeys).forEach(function(feature){
                features.push(featureKeys[feature]);
            });
            return features;
        };

        /**
         *  delete single table row (record)
         */

        var deleteRowEventHandler = function() {
            var btn = $(this),
                id = btn.data('id')
            $('.featurematching__typecontainer--globaltype.featurematching__typecontainer--' + id + '[data-active="true"]').each(function() {
                $(this).find('.js-featurematching__typedeletebutton').click();
            });
        };

        var getFeatures = function (id, key) {
            var featuretypes = getFeatureTypesForId(id);

            var param = {
                id: id,
                key: key,
                featurematchingtypes: featuretypes
            };

            return param;

        };

        var getFeatureTypesForId = function (id) {
            var featureMatchingTypes = [];
            $('.featurematching__typecontainer--globaltype.featurematching__typecontainer--' + id + '[data-active="true"]').each(function() {
                var $gt = $('input.gtype',$(this));
                var index = $gt.data('index');
                var $st = $('input.gtype[data-index="' + index + '"][data-id="' + id + '"][data-type="subtype"]');
                var $at = $('input.gtype[data-index="' + index + '"][data-id="' + id + '"][data-type="additionaltype"]');
                var featuretype = {
                    globaltype: $gt.val() || '',
                    subtype: $st.val() || '',
                    additionaltype: $at.val() || ''
                };
                featureMatchingTypes.push(featuretype);
            });
            return featureMatchingTypes;
        };

        var resetAllOverridesEventHandler = function() {
            swal({
                    title:  Translator.trans('featurematching.sweetalert.resetalloverride.title', {}, 'cms'),
                    text:  Translator.trans('featurematching.sweetalert.resetalloverride.text', {}, 'cms'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: Translator.trans('featurematching.sweetalert.resetalloverride.confirmButton', {}, 'cms'),
                    cancelButtonText: Translator.trans('featurematching.sweetalert.resetalloverride.cancelButton', {}, 'cms'),
                    closeOnConfirm: true
                },
                function(isConfirm) {
                    if (isConfirm !== true) {
                        return false;
                    } else {
                        resetOverride({
                            'action' : 'all'
                        });
                    }
                });

        };

        var resetOverrideEventHandler = function(e) {
            var btn = $(this);
            swal({
                    title:  Translator.trans('featurematching.sweetalert.resetoverride.title', {}, 'cms'),
                    text:  Translator.trans('featurematching.sweetalert.resetoverride.text', {}, 'cms'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: Translator.trans('featurematching.sweetalert.resetoverride.confirmButton', {}, 'cms'),
                    cancelButtonText: Translator.trans('featurematching.sweetalert.resetoverride.cancelButton', {}, 'cms'),
                    closeOnConfirm: true
                },
                function(isConfirm) {
                    if (isConfirm !== true) {
                        return false;
                    } else {
                        resetOverride({
                            'action': 'single'
                            ,'key': btn.data('key')
                        });
                    }
                });
        };

        var addGtEventHandler = function(e) {
            var el = {
                globaltype: ''
                ,subtype: ''
                ,additionaltype: ''
            };
            var $btn = $(this);
            var id = $btn.data('id');
            var $row = $btn.closest('tr');
            var $gtItems = $('.featurematching__typecontainer--globaltype', $row);
            var index = $gtItems.length;

            var $globaltypeCell = $('.featurematching__typecontainer--globaltype', $row).parent();
            var $subtypeCell = $('.featurematching__typecontainer--subtype', $row).parent();
            var $additionaltypeCell = $('.featurematching__typecontainer--additionaltype', $row).parent();

            $globaltypeCell.append(generateGtTableContent('globaltype',id ,index ,el ,true));
            $subtypeCell.append(generateGtTableContent('subtype',id ,index ,el ,true));
            $additionaltypeCell.append(generateGtTableContent('additionaltype',id ,index ,el ,true));

            var $newInputs = $('.cms-fm-datatable-textbox--added', $row);
            $newInputs.removeClass('cms-fm-datatable-textbox--added');
            recalculateGtItemEvenOdd(id);
            attachSelectControlsForGlobalTypes($newInputs);
        };

        var deactivateGtItem = function(e) {
            var $btn = $(this);
            var index = $btn.data('index');
            var id = $btn.data('id');
            $('.featurematching__typecontainer--' + id + '[data-index="' + index + '"]').each(function() {
                var $item = $(this);
                var $input = $('input.gtype', $item);
                if ($item.data('reset') === false && $input.select2('val') !=='') {
                    removeIdFromUnsavedChanges($input.attr('id'));
                } else if ($item.data('reset') === true && $input.select2('val') !== '') {
                    addIdToUnsavedChanges($input.attr('id'));
                }
            }).attr('data-active', 'false');
            checkUnsavedChangesCount();
            recalculateGtItemEvenOdd(id);
        };

        var recalculateGtItemEvenOddAll = function() {
            $('.save-feature-btn').each(function(k,v){
                var $btn = $(v);
                var id = $btn.data('id') || false;
                if (id) {
                    recalculateGtItemEvenOdd(id);
                }
            });
        };

        var recalculateGtItemEvenOdd = function(id) {
            var types = ['globaltype', 'subtype', 'additionaltype'];
            types.forEach(function(type){
                var even = false;
                $('.featurematching__typecontainer--' + type + '.featurematching__typecontainer--' + id + '[data-active="true"]').each(function() {
                    var $item = $(this);
                    if (even) {
                        $item.addClass('featurematching__typecontainer--even');
                    } else {
                        $item.removeClass('featurematching__typecontainer--even');
                    }
                    even = !even;
                });
            });
        }

        var resetOverride = function(params) {

            $.ajax({
                    url: Routing.generate( 'cms_featurematching_resetoverride' , params),
                    beforeSend: function(e, xhr, options) {
                        e.loaderKey = "resetOverride";
                        loader.startLoader(e.loaderKey, $(resultProductDatatable));
                    }
                })
                .done(function(data) {
                    var title,text;
                    if (params.action === 'single') {

                        text = Translator.trans('featurematching.alert.resetoverridetext', {}, 'cms');
                        title = Translator.trans('featurematching.alert.resetoverridetitle', {}, 'cms');
                    } else {
                        title  = Translator.trans('featurematching.alert.resetoverridestitle', {}, 'cms');
                        text =  Translator.trans('featurematching.alert.resetoverridestext', {}, 'cms');
                    }
                    toastr.success(
                        text,
                        title,
                        {
                            showEasing: 'easeInOutBack',
                            progressBar: true
                        }
                    );
                    reloadDataTable(resultProductDatatableLoaderId);
                })
                .fail(function(){
                    title = Translator.trans('featurematching.alert.errortitle', {}, 'cms'),
                        text = Translator.trans('featurematching.alert.errortext.roerror', {}, 'cms');
                    swal({
                        'title':title
                        ,'text': text
                        ,type:"error"
                    }, function() {
                        // loader.remove(resultProductDatatableLoaderId);
                    });
                });
        }

        var updateFeatureMatchings = function(params) {
            $.ajax({
                url: Routing.generate( 'cms_featurematching_update' , params),
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "updateFeatureMatching";
                    loader.startLoader(e.loaderKey, $(resultProductDatatable));
                }
            })
            .done(function(data) {
                var title,text;
                if (params.action === 'save') {

                    text = Translator.trans('featurematching.alert.savetext', {}, 'cms');
                    title = Translator.trans('featurematching.alert.savetitle', {}, 'cms');
                } else {
                    title  = Translator.trans('featurematching.alert.deletetitle', {}, 'cms');
                    text =  Translator.trans('featurematching.alert.deletetext', {}, 'cms');
                }
                toastr.success(
                    text,
                    title,
                    {
                        showEasing: 'easeInOutBack',
                        progressBar: true
                    }
                );
                clearUnsavedChanges();
                reloadDataTable(resultProductDatatableLoaderId);
                checkUnsavedChangesCount();
            })
            .fail(function(){
                title = Translator.trans('featurematching.alert.errortitle', {}, 'cms'),
                text = Translator.trans('featurematching.alert.errortext.conerror', {}, 'cms');
                swal({
                    'title':title
                    ,'text': text
                    ,type:"error"
                }, function() {
                    // loader.remove(resultProductDatatableLoaderId);
                });
            });
        };

        /**
         * modify the result table dynamicly, rather then sending a update request
         * @param {array} params
         */
        var reloadDataTable = function(loaderId) {

            var table = $(resultProductDatatable).DataTable();
            table.ajax.reload(function() {
                // if (loaderId) {
                //     loader.remove(loaderId);
                // }
                // loader.remove(resultProductDatatableLoaderId);
            }, false );

        };

        var getUnsavedChangesCount = function() {
            return Object.keys(unsavedInputIds).length;
        };

        var clearUnsavedChanges = function () {
            unsavedInputIds = {};
            checkUnsavedChangesCount();
        };

        var checkUnsavedChangesCount = function() {
            var unsavedCount = Object.keys(unsavedInputIds).length;
            if (0 === unsavedCount) {
                $('#fm_saveall').addClass('h-hidden');
                $('#fm_cancelall').addClass('h-hidden');
            } else {
                $('#fm_saveall').removeClass('h-hidden');
                $('#fm_cancelall').removeClass('h-hidden');
            }
        };

        var addIdToUnsavedChanges = function(id) {
            if (!unsavedInputIds.hasOwnProperty(id)) {
                unsavedInputIds[id] = 0;
            }
            unsavedInputIds[id]++;
        };

        var removeIdFromUnsavedChanges = function(id) {
            if (unsavedInputIds.hasOwnProperty(id)) {
                delete unsavedInputIds[id];
            }
        };

        var getIdsFromUnsavedChanges = function() {
            return Object.keys(unsavedInputIds);
        };

        /**
         * returns a list of ids of textfield which content was modified as array
         * @returns {Array}
         */
        var getChangedInputFieldIdsAsArray = function(elmClass) {

            if (!pwArrayFunctions) {
                var pwArrayFunctions = pwNamespace('PEAKWORK.TOOLKIT.UTIL.ARRAY.UNIQUE');
            }

            var idList = [];
            $(elmClass).each(function(){
                //only pick modified items
                if ($(this).attr('data-org') !== $(this).val() ) {
                    //idList.push( $(this).attr('id').split('-')[0] );
                    idList.push( $(this) );
                }
            });
            //remove dublicated array elements
            idList = pwArrayFunctions.Unique( idList );

            return idList;

        };

        var generateGtTableContent = function(type, rowId, index, el, additionalGt) {
            gtValue = el.globaltype || '';
            additionalGt = additionalGt || false;
            var additionalInputClass = '';
            if (additionalGt) {
                additionalInputClass = ' cms-fm-datatable-textbox--added';
            }
            var $action;
            if (type === 'globaltype') {
                $action =  $('<button/>', {
                    'text': index + 1,
                    'type': 'button',
                    'class': 'btn btn-xs btn-info fa featurematching__typedeletebutton js-featurematching__typedeletebutton',
                    'data-index': index,
                    'data-id': rowId
                });
            } else {
                $action = $('<span/>', {
                    'text': index + 1,
                    'class': 'featurematching__typeinfo',
                    'data-index': index
                });
            }
            var displayInput = $('<div/>', {
                'class': 'featurematching__typecontainer featurematching__typecontainer--active featurematching__typecontainer--' + type + ' featurematching__typecontainer--' + rowId
                ,'data-index': index
                ,'data-id': rowId
                ,'data-active': true
                ,'data-reset': !additionalGt
            }).append(
                $('<label/>', {
                    'class': 'featurematching__typelabel',
                    'for': rowId + '-'+ type + '-' + index,
                }).append(
                   $action
                ).append(
                    $( "<input/>", {
                        'id':rowId + '-'+ type + '-' + index,
                        'value':((el[type])?el[type]:''),
                        'class':rowId + '-type gtype cms-fm-datatable-textbox' + additionalInputClass,
                        'data-org':((el[type])?el[type]:''),
                        'data-text':((el[type + 'label'])?el[type + 'label']:''),
                        'data-index': index,
                        'data-type': type,
                        'data-id': rowId,
                        'disabled': (type !== 'globaltype' && gtValue === '') ? true : false
                    })
                )
            );
            if (additionalGt) {
                return displayInput;
            }
            return displayInput[0].outerHTML;
        };

        return {
            init: function() {
                table = $(resultProductDatatable);
                isCentral = table.data('iscentral');
                initDatatable();
                initFilter();
                addHandlers();
                $(featureMappingContainer + ' .listresult')
                    .on('click', '.delete-btn', deleteRowEventHandler )
                    .on('click', '.reset-btn', onResetTypesHandler )
                    .on('click', '.info-feature-btn', infoButtonHandler )
                    .on('click', '.reset-override-btn', resetOverrideEventHandler )
                    .on('click', '.add-gt-btn', addGtEventHandler )
                    .on('click', '.js-featurematching__typedeletebutton', deactivateGtItem )
                    .on('click', '.deactivate-btn', onDeactivateHandler )
                    .on('click', '.activate-btn', onActivateHandler );
            },
            deleteMatching: function() {
                alert("Delete item");
            },
            generateGtTableContent: generateGtTableContent
        };

    }
    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

$(function() {
    if ($('.js-init-featurematching').length > 0) {
        FeatureMatchingController.getInstance().init();
    }
});
