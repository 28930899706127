var GoalHistoryController = (function() {

    var instance;

    function createInstance() {

        var panelContainer
            ,detailContainer;

        var addHandlers = function() {
            panelContainer = $('.cmscontentstore--goalhistory');
            detailContainer = $('.cmscontentstore--goaldetail');
            panelContainer.on('click', '.showhide', function(e) {
                var btn = $(this);
                if (btn.find('.fa').hasClass('fa-chevron-down')) {
                    loadDatatable();
                }

            });
        };

        var loadDatatable = function() {
            var goalId = detailContainer.find('.basicdata-panel').data('goalid');
            var table = panelContainer.find('#cmsgoalhistorytable').DataTable();
            panelContainer.show();
            if(table) {
                table.destroy();
            }
            if (goalId) {
                historytable_ini(goalId);
            }
        };

        var destroyTable = function() {
            if (panelContainer) {
                panelContainer.hide();
                var table = panelContainer.find('#cmsgoalhistorytable').DataTable();
                if(table) {
                    table.destroy();
                }
            }
        };

        return {
            init: function() {
                addHandlers();
            }
            ,loadDatatable: function() {
                loadDatatable();
            }
            ,destroyTable: function() {
                destroyTable();
            }
        };
    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();