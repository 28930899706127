/**
 *
 * CitySearchComponent
 * extends AbstractGiataSearchComponent
 *
 */
(function(){
var ctx = pwNamespace('PEAKWORK.STORECMS.GIATA.GEODATA.COMPONENTS');
ctx.CitySearchComponent = function() {
    opts = {
        wrapperClassName: '#GiataGeodataCityTab-1'
        ,inputId: '#GiataSearchCityInput'
    };
    if (arguments[0] && typeof arguments[0] === "object") {
        opts = this.extendDefaults(opts, arguments[0]);
    }

    ctx.AbstractGiataSearchComponent.call(this, opts);
}
ctx.CitySearchComponent.prototype = $.extend(
    Object.create(ctx.AbstractGiataSearchComponent.prototype), {
        constructor : ctx.CitySearchComponent
        ,addButtonEvents : function(ddList) {
            var self = this;
            ctx.AbstractGiataSearchComponent.prototype.addButtonEvents.call(this, ddList);

           /* ddList.find('.dd-handle').on('click', '.remove-city-item-btn', function(e) {
                e.stopPropagation();
                console.log('removed');
            });
            ddList.find('.dd-handle').on('click', '.edit-city-item-btn', function(e) {
                self.onClickDetail(self, e);
            });*/
        }
        ,onClickItem: function(obj, e) {
            ctx.AbstractGiataSearchComponent.prototype.onClickItem.call(this, obj, e);
            var loader = Loader.getInstance();

            var btn = $(e.currentTarget)
                ,li = btn.parent()
                ,data = li.data()
                ,cityname = ''
                ,lat = ''
                ,lng = ''
                ,translations = '';
            if (data['uiAutocompleteItem'] && data['uiAutocompleteItem']['_attributes']) {
                cityname = data['uiAutocompleteItem']['_attributes']['cityname'];
                lat = data['uiAutocompleteItem']['_attributes']['citylat'];
                lng = data['uiAutocompleteItem']['_attributes']['citylong'];
            }
            translations = [];
            if (data.hasOwnProperty('translations') && data.translations !== '') {
                translations = $.parseJSON(decodeURI(data.translations));
            }
            var url = Routing.generate( 'cms_giata_geodata_detail', { type: 'city' });
            $.ajax(url, {
                data: {
                    countrycode: data['countrycode']
                    ,regionid: data['regionid']
                    ,cityid: data['cityid']
                    ,name: cityname
                    ,lat: lat
                    ,lng: lng
                    ,translations: translations
                }
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "";
                    loader.startLoader(e.loaderKey, $(".js-giatageo__translationcontainer"));
                }
            })
            .done(function(data){
                var detailContainer = $('.js-giatageo__translationcontainer');
                //obj.handlePanels(false);
                detailContainer.html(data);
            });
            return false;
        }
        ,handlePanels : function(openPanel) {
            var panels = ['#airportlistresultwrapper'];
            var panelToogleClass = (openPanel)?'fa-chevron-down':'fa-chevron-up';
            for(var i in panels) {
                if($(panels[i]).css('display')!=='none') {
                    if($(panels[i]+' .panel-tools .fa').hasClass(panelToogleClass)){
                        $(panels[i]+' .panel-tools .showhide').click();
                    }
                }
            }
        }
        ,onClickDetail: function(obj, e) {
            ctx.AbstractGiataSearchComponent.prototype.onClickItem.call(this, obj, e);
            e.stopPropagation();

        }
        ,resetSearchList: function() {
            var wrapper = $(this.options.wrapperClassName)
                ,nestableContainer = wrapper.find('.dd')
                ,ddlist = nestableContainer.find('.dd-list');
            nestableContainer.find('.resultcount').html(
                Translator.trans(
                    'basicdata.geodata.overview.searchcity.resultcount',
                    {
                        'count':0
                        ,'total':0
                    },
                    'cms'
                )
            );
            ddlist.html('');
        }
        ,renderItem : function ( ul, item, index ) {
            $translations = '';
            if (item.hasOwnProperty('translation')) {
                $translations = encodeURI(JSON.stringify(item.translations));
            }
            var li = $( "<li>", {
                'class': "dd-item  basicdata-geodata-city-list"
                ,'data-countrycode': ul.attr('data-countrycode')
                ,'data-regionid': ul.attr('data-regionid')
                ,'data-cityid': item._attributes.cityid
                ,'data-translations': $translations
            })
                .append(
                    $('<div/>', {'class':'dd-handle'})
                        .append(' '+item._attributes.cityname)
                        //.append(this.addCostumButton('remove', 'city', 'right'))
                        //.append(this.addCostumButton('edit', 'city', 'right'))
                ).appendTo( ul );
            return li;
        }
        ,renderMenu : function( acObj, ul, items ) {
            var that = acObj
                ,ddList = $('#nestable-geodata-city .dd-list')
                ,resultCountContainer = ddList.parent().find('.resultcount')
                ,cities = items[0]
                ,count = (items[1].value)?items[1].value:0
                ,citiesCount = Object.keys(cities).length;
            ul.addClass('hide');
            resultCountContainer.html(
                Translator.trans(
                    'basicdata.geodata.overview.searchcity.resultcount',
                    {
                        'count':citiesCount
                        ,'total':count
                    },
                    'cms'
                )
            );

            if(ddList.length > 0) {
                ddList.html('');
            } else {
                $('<ol/>', { 'class':'dd-list'}).appendTo($('#nestable-geodata-city'));
            }
            $.each( cities, function( index, item ) {
                that._renderItemData( ddList, item, index );
            });
            this.addButtonEvents(ddList);
        }
    }
);
})();