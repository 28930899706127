/**
 *
 * Map MapButtons
 *
 */
(function() {
    var ctx = pwNamespace('PEAKWORK.MAP')
        ,mapWrapper = null
        ,_ = null;
    ctx.MapButtons = function (mapWrapperObj,options) {

        mapWrapper = mapWrapperObj;
        _ = this;
        var defaultoptions = {
            jsselector: {
            },
            resetZoomButton: false
            ,fullscreenButton: false
        };

        if (options && typeof options === "object") {
            this.options = $.extend({}, defaultoptions, options);
        }

        _.init();

        return {
            addButton: _.addButton
            ,addZoomToMarkerButton: _.addZoomToMarkerButton()
        };
    };
    ctx.MapButtons.prototype = {
        constructor: ctx.MapButtons
        ,init: function () {
            _.mapId = mapWrapper.getMapId();

            _.addHandler();
        }

        ,addHandler: function() {
            _.uiInit();
        }

        ,addZoomToMarkerButton: function() {
            var self  = this;
            return function(data) {

                var marker = data.hasOwnProperty('marker') ? data.marker : false;
                if (!marker) {
                    return;
                }
                var defaultFn = function(e) {
                    $.publish('center_marker.map.' + self.mapId, { zoomLvl: 5, marker: marker });
                };
                var translationString = marker.options.hasOwnProperty('pwAddMarkerZoomButton') ? marker.options.pwAddMarkerZoomButton : false;
                if (marker && translationString) {
                    var theFn = (data.hasOwnProperty('callback') && typeof data.callback === 'function') ? data.callback : defaultFn;
                    self.addButton.call(self, {
                        'idx': translationString.replace(/\./g, '-') + '-' + marker.id
                        ,classx : translationString + '-' + marker.id
                        ,title: Translator.trans(translationString + '.title', {}, 'cms')
                        ,text: Translator.trans(translationString + '.text', {}, 'cms')
                        ,'callback': theFn
                    });
                }

            }
        }

        ,addResetZoomButton: function(bounds) {
            _.addButton({
                'idx': 'MapDefaultCenter' + '-' + _.mapId
                ,classx : 'mapdefaultcenter' + '-' + _.mapId
                ,title: Translator.trans('map.buttons.defaultcenter.title', {}, 'cms')
                ,text: Translator.trans('map.buttons.defaultcenter.text', {}, 'cms')
                ,'callback': function(e){
                    e.preventDefault();
                    $.publish('center_default.map.' + _.mapId, {bounds: bounds});
                }
            });
        }

        ,addFullscreenButton: function() {
            _.addButton({
                idx: 'FullScreen' + '-' + _.mapId
                ,classx : 'fullscreen fullscreen' + '-' + _.mapId
                ,title: Translator.trans('core.map.button.fullscreen.title', {}, 'core')
                ,text: Translator.trans('core.map.button.fullscreen.text', {}, 'core')
                ,callback: mapWrapper.onHandleViewSize
            });
        }

        /*
         *
         * UI Functions
         *
         *
         *
         *
         */

        ,uiInit: function() {
            _.uiButtonsInit();
        }
        
        ,uiButtonsInit: function() {
            if (_.options.fullscreenButton) {
                _.addFullscreenButton();
            }
            if (_.options.resetZoomButton) {
                _.addResetZoomButton(_.options.resetZoomButton);
            }
        }

        ,addButton: function(buttonOpts) {
            const button = L.Control.extend({
                options: {
                    position: 'topright'
                    //control position - allowed: 'topleft', 'topright', 'bottomleft', 'bottomright'
                },
                onAdd: function (map) {
                    var paddings = {
                        top: [5, 7, 1, 2, 3]
                        ,left: [5, 6, 4, 1, 10]
                        ,right: [12, 7, 8, 9, 3]
                        ,bottom: [11, 10, 12, 6, 9]
                    };
                    const controlDiv = L.DomUtil.create('div', 'mapbuttonwrapper');
                    const uiDiv = L.DomUtil.create('div', 'mapbuttonwrapper', controlDiv);
                    uiDiv.id = buttonOpts.idx+'MapButtonWrapper';
                    uiDiv.title = buttonOpts.title;
                    const textDiv = L.DomUtil.create('button', buttonOpts.classx + ' mapbutton btn btn-default btn-xs', uiDiv);
                    textDiv.innerHTML = buttonOpts.text;
                    textDiv.id = buttonOpts.idx + '-mapbutton';
                    $(mapWrapper.map.getContainer()).on('click', '#' + textDiv.id, function(e) {
                        buttonOpts.callback.call(mapWrapper, e);
                    });
                    controlDiv.index = 1;
                    if ($.inArray(buttonOpts.position, paddings.top) > -1) {
                        controlDiv.style['padding-top'] = '10px';
                    }
                    if ($.inArray(buttonOpts.position, paddings.left) > -1) {
                        controlDiv.style['padding-left'] = '10px';
                    }
                    if ($.inArray(buttonOpts.position, paddings.right) > -1) {
                        controlDiv.style['padding-right'] = '10px';
                    }
                    if ($.inArray(buttonOpts.position, paddings.bottom) > -1) {
                        //controlDiv.style['padding-bottom'] = '10px';
                    }
                    return controlDiv;
                }
            });
            mapWrapper.map.addControl(new button());
        }

}
})();