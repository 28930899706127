/**
 * Unique.js - Removes duplicate values from an array
 * @link http://stackoverflow.com/questions/12551635/jquery-remove-duplicates-from-an-array-of-strings/12551709#12551709
 * @version: 1.0
 */
(function () {
    var ctx = pwNamespace('PEAKWORK.TOOLKIT.UTIL.ARRAY.UNIQUE');
        
    ctx.Unique = function (list) {
        var result = [];
        $.each(list, function(i, e) {
            if ($.inArray(e, result) === -1) {
                result.push(e);
            }
        });
        
        return result;
    };
})();
