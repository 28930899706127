var GoalNestedListTabsController = (function() {

    var instance
        ,loader;


    function createInstance() {

        var relevantAirports = [];
        var panelContainer;
        var listHasChanged = false;

        var ctx = pwNamespace('PEAKWORK.GOAL')

        var addHandlers = function() {
            panelContainer = $('#RelevantAirportsPanel');
            panelContainer.on('click', '#SaveAirportsButton', function(e) {
                onSaveAirports(e)
            } );
            panelContainer.on('click', '.remove-airport-item-btn', onRemoveItem );
            panelContainer.on('click', '.add-airport-item-btn', onAddToRelevantList );
            initAutocompletion();
        };

        var updateMapAirports = function(airports) {
            airports = airports || null;
            if (!airports) {
                return;
            }

            $.publish('changeMatchedAirports.goal.cms', airports);
        };
        
        var initAirportLists = function() {
            var goalId = panelContainer.data('goalid');
            $.ajax({
                url: Routing.generate('cms_goal_getrelpotairportlist'),
                data: {
                    goalid: goalId
                }
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = loader.getGeneratedKeyByPrefix("airportlist");
                    loader.startLoader(e.loaderKey, panelContainer);
                }
            })
            .done(function(data) {
                panelContainer.html(data.view);
                aggregateAllRelevantAirports();
                listHasChanged = false;
                initAutocompletion();
                updateMapAirports(data.airports)
            });
        };

        var onSaveAirports = function(e) {
            e.preventDefault();
            var goalId = panelContainer.data('goalid');

            $.ajax({
                url: Routing.generate('cms_goal_saverelevantairports'),
                dataType: "json",
                data: {
                    goalid: goalId
                    ,airports: relevantAirports
                }
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = loader.getGeneratedKeyByPrefix("saveAirports");
                    loader.startLoader(e.loaderKey, panelContainer);
                }
            })
            .done(function(data) {
                initAirportLists();
                if (data.status !== 'success') {
                    showMessage('goal.airportlist.message.error.savefailed', false);
                }
            });
            return false;
        };

        var initAutocompletion = function() {
            var acInstance = panelContainer.find( "#SearchAllInput" ).autocomplete({
                source: function( request, response ) {
                    $.ajax({
                        url: Routing.generate('cms_goal_airportlist'),
                        dataType: "json",
                        data: {
                            iata: request.term
                            ,start: '0'
                            ,length: '5'
                        },
                        success: function( data ) {
                            response( data );
                        }
                    });
                },
                minLength: 0
                ,messages: {
                    noResults: ''
                }
            }).autocomplete( "instance" );
            acInstance._renderItem = function( ul, item, index ) {
                var li = $( "<li>", {
                        'class': "dd-item goal-airport-list"
                        ,'data-id': item.iatacode.value
                    })
                    .append(
                        $('<div/>', {'class':'dd-handle'})
                            .prepend(
                                createMoveButton('left')
                            )
                            .append(' '+item.iatacode.value)
                    ).appendTo( ul );
                return li;
            };
            acInstance._renderMenu = function( ul, items ) {
                var that = this
                    ,ddList = panelContainer.find('#nestable3 .dd-list')
                    ,resultCountContainer = ddList.parent().find('.resultcount')
                    ,airports = items[0]
                    ,count = (items[1].value)?items[1].value:0
                    ,airportsCount = Object.keys(airports).length;
                ul.addClass('hide');
                resultCountContainer.html(
                    Translator.trans(
                        'goal.airportlist.searchall.resultcount',
                        {
                            'count':airportsCount
                            ,'total':count
                        },
                        'cms'
                    )
                );

                if(ddList.length > 0) {
                    ddList.html('');
                } else {
                    $('<ol/>', { 'class':'dd-list'}).appendTo(panelContainer.find('#nestable3'));
                }
                $.each( airports, function( index, item ) {
                    that._renderItemData( ddList, item, index );
                });
            };
            if(acInstance.element) {
                acInstance.element.autocomplete('search', '');
            }
        };

        var onAddToRelevantList = function(e) {
            var btn = $(e.currentTarget)
                ,handle = btn.parent()
                ,li = handle.parent()
                ,direction = ''
                ,saveButton = panelContainer.find('#SaveAirportsButton');

            if ($.inArray(li.data('id'), relevantAirports) === -1) {
                if(handle.data('nestedlist') === 'potential') {
                    direction = 'right';
                }
                relevantAirports.push(li.data('id'));
                handle.find('.add-airport-item-btn').remove();
                handle.append(createMoveButton(direction));
                panelContainer.find('#nestable1 .dd-list').append(li);

                if (!listHasChanged) {
                    saveButton.removeClass('disabled');
                    listHasChanged = true;
                }

                return false;
            }
            showMessage('goal.airportlist.message.error.alreadyexists', false);
        };

        var showMessage = function(transKey, isSuccess) {
            var alertClass = "alert "+((isSuccess)?"alert-success":"alert-danger")
                ,iconClass = "fa "+((isSuccess)?"fa-bolt":"alert-warning")
                ,alertBlock = $('<div/>', {
                    class: alertClass,
                    text: " "+Translator.trans(transKey, {}, 'cms'),
                    click: function(e){ $(this).slideUp(100, function(e){$(this).remove();}); }
                }).prepend($('<i/>',{ class: iconClass }));

            panelContainer.find(".panel-heading").after(alertBlock);
            alertBlock.hide().slideDown();
            setTimeout(function(){
                alertBlock.slideUp(100, function(e){$(this).remove();})
            },3000);
        };

        var onRemoveItem = function(e) {
            var btn = $(this)
                ,handle = btn.parent()
                ,li = handle.parent()
                ,listWrapper = $('#nestable2')
                ,saveButton = panelContainer.find('#SaveAirportsButton');

            removeAirportFromRelevant(li.data('id'));
            if(handle.data('nestedlist') !== 'potential') {
                li.remove();
                if (!listHasChanged) {
                    saveButton.removeClass('disabled');
                    listHasChanged = true;
                }

                return false;
            }
            btn.remove();
            handle.prepend(
                createMoveButton('left')
            );
            listWrapper.find('.dd-list').append(li);

            return false;
        };

        var createMoveButton = function(direction) {
            var iconClass = 'fa fa-times'
                ,buttonClass = 'btn btn-default btn-xs remove-airport-item-btn';

            if (direction === 'left') {
                iconClass = 'fa fa-arrow-' + direction;
                buttonClass = 'btn btn-default btn-xs add-airport-item-btn';
            } else if (direction === 'right') {
                iconClass = 'fa fa-arrow-' + direction;
                buttonClass = 'btn btn-default btn-xs remove-airport-item-btn';
            }
            return $('<a/>', {'class':buttonClass})
                .append($('<span/>', {'class':iconClass}))
        };


        var removeAirportFromRelevant = function(removeItem){
            relevantAirports = jQuery.grep(relevantAirports, function(value) {
                return value != removeItem;
            });
        };

        var aggregateAllRelevantAirports = function() {
            relevantAirports = [];
            $.each($('#nestable1 .dd-list').children(), function() {
                relevantAirports.push($(this).data('id'));
            });
        };

        var init = function() {
            loader = Loader.getInstance();
            aggregateAllRelevantAirports();
            addHandlers();
        };

        return {
            init: init
        };
    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();