$(function () {
    var context = pwNamespace('CMS.GLOBALTYPE.TRANSLATIONS')
        ,backendCtx = pwNamespace('PEAKWORK.BACKEND');
    context.TranslationsList = (function () {
        var instance = null;

        var injection = function() {
            return {}
        };

        var initInstance = function() {
            var me = {
                config: {
                    filter: '#globaltypetranslations-filter-form'
                    ,tableId: '#GlobaltypeTranslationsTable'
                    ,loader: Loader.getInstance()
                    ,loaderTargetContainer: $('.content')
                    ,table: $('#GlobaltypeTranslationsTable')
                    ,isGTCreated:false
                    ,translationFormClass: 'js-form-download-globaltype-translations'
                    ,addTranslationButtonClass: 'js-add-globaltype-translation-language'
                    ,languageCodeCssClass: 'js-download-globaltype-translations-language-code'
                    ,countryCodeCssClass: 'js-download-globaltype-translations-country-code'
                }
                ,init: function() {
                    me.initDataTable();
                    me.eventHandler();
                }
                ,eventHandler: function() {
                    var body = $('body')
                        ,filterInstance = FilterForm.getInstance()
                        ,modal = $('#cmsglobaltypetranslationsmodal')
                        ,modalDownloadTranslation = $('#cmsglobaltypedownloadtranslationsmodal');
                    filterInstance.init({
                        'formid': 'globaltypetranslations-filter-form'
                        ,'submit': me.onSubmitFilter
                    });
                    modal.on('shown.bs.modal', me.onCreateGlobalTypeTranslation);
                    modal.on('hidden.bs.modal', me.onResetModal);
                    modal.on('keyup', 'input[name="language"]', me.onCheckLanguageCode );
                    modal.on('keyup', 'input[name="country"]', me.onCheckCountryCode );
                    body.on('click', '.js-collection-add', me.onAddTranslation);
                    body.on('click', '.js-collection-remove', me.onRemoveTranslation);
                    body.on('click', '.js-savetranslations', me.onSaveTranslations);
                    body.on('click', '.js-deletetranslation', me.onDeleteTranslation);
                    modalDownloadTranslation.on('show.bs.modal', me.onShowLanguagesToDownload);
                    modalDownloadTranslation.on('hidden.bs.modal', me.onResetLanguagesToDownload);
                    modalDownloadTranslation.on('click', '.js-downloadtranslations', me.onDownloadGlobalTypeTranslations);
                    modalDownloadTranslation.on('keyup', 'input[name="language"]', me.onCheckLanguageCode);
                    modalDownloadTranslation.on('keyup', 'input[name="country"]', me.onCheckCountryCode);
                    $('.' + me.config.addTranslationButtonClass).on('click', me.onAddLanguagesToDownload);
                    body.on('click', '.js-download-translation-remove', me.onRemoveDownloadTranslation);
                }

                ,handleDownloadModalErrorMessage: function(modalContainer, message) {
                    var errorContainer = modalContainer.find('.globaltypetranslationsdownload-error')
                        ,errorContent = errorContainer.find('.globaltypetranslationsdownload-errorcontent');
                    errorContent.html(' '+message);
                    errorContainer.fadeIn(600, function() {
                        setTimeout(function() {
                            errorContainer.fadeOut("slow");
                        }, 2000);
                    });
                }

                ,onShowLanguagesToDownload: function() {
                    var form = $('.' + me.config.translationFormClass)
                        ,languageRows = form.find('div');
                    $.each(languageRows, function() {
                        var languageField = $(this).find('.' + me.config.languageCodeCssClass)
                            ,countryField = $(this).find('.' + me.config.countryCodeCssClass);
                        $(languageField).removeClass('globaltypetranslations-inputerror');
                        $(countryField).removeClass('globaltypetranslations-inputerror');
                    });
                }

                ,onDownloadGlobalTypeTranslations: function(e) {
                    var form = $('.' + me.config.translationFormClass)
                        ,button = $(e.currentTarget)
                        ,modalContainer = button.parents('.modal')
                        ,languageRows = form.find('div')
                        ,formError = false
                        ,errorLanguage = ''
                        ,errorCountry = ''
                        ,languageCodes = {
                            'languages' : []
                        };

                    $.each(languageRows, function() {
                        var languageField = $(this).find('.' + me.config.languageCodeCssClass)
                            ,countryField = $(this).find('.' + me.config.countryCodeCssClass)
                            ,fieldError = false;

                        if ('' === languageField.val()) {
                            fieldError = true;
                            $(languageField).addClass('globaltypetranslations-inputerror');
                            errorLanguage = '<br>' + Translator.trans('globaltypetranslations.errormessage.mandatorylanguage', {}, 'cms');
                        }

                        if ('' === countryField.val()) {
                            fieldError = true;
                            $(countryField).addClass('globaltypetranslations-inputerror');
                            errorCountry = '<br>' + Translator.trans('globaltypetranslations.errormessage.mandatorycountry', {}, 'cms');
                        }

                        if (!fieldError) {
                            languageCodes['languages'].push( languageField.val() + '_' + countryField.val());
                        }
                        formError = formError || fieldError;
                    });

                    if (formError) {
                        me.handleDownloadModalErrorMessage(modalContainer, errorLanguage + ' ' + errorCountry );
                        return false;
                    }

                    $.ajax({
                        url: Routing.generate('cms_globaltype_requestlanguagedownload'),
                        data: languageCodes,
                        beforeSend: function(e, xhr, options) {
                            e.loaderKey = "submitGlobaltypeTranslationRequestDownload";
                            me.config.loader.startLoader(e.loaderKey, $('.js-form-download-globaltype-translations'));
                        }
                    })
                    .done(function(data){
                        var promise = DownloadController.getInstance().startCheckStatus(data.data);
                            me.config.loader.remove(e.loaderKey);
                        promise.done(function(){
                            modalContainer.modal('hide');
                            me.resetDownloadLanguage();
                        });
                    });
                }

                ,resetDownloadLanguage: function() {
                    var form = $('.' + me.config.translationFormClass)
                        ,languageRows = form.find('div');
                    $.each(languageRows, function(idx) {
                        if (idx !== 0) {
                            languageRows[idx].remove();
                        } else {
                            var languageField = $(this).find('.' + me.config.languageCodeCssClass)
                                ,countryField = $(this).find('.' + me.config.countryCodeCssClass);
                            $(languageField).val('');
                            $(countryField).val('');
                        }
                    });
                }

                ,onCheckCountryCode: function() {
                    me.checkInputCode($(this), true);
                }

                ,onCheckLanguageCode: function() {
                    me.checkInputCode($(this), false);
                }

                ,checkInputCode: function(input, toUpperCase) {
                    var value = input.val();
                    if (value.length > 2) {
                        value = value.substring(0, 2);
                    }
                    if (toUpperCase) {
                        input.val(value.toUpperCase());
                    } else {
                        input.val(value.toLowerCase());
                    }
                    if (value.length === 2) {
                        if (input.hasClass('globaltypetranslations-inputerror')) {
                            input.removeClass('globaltypetranslations-inputerror');
                        }
                    }
                    if (value.length < 2) {
                        if (!input.hasClass('globaltypetranslations-inputerror')) {
                            input.addClass('globaltypetranslations-inputerror');
                        }
                    }
                }

                ,onAddLanguagesToDownload: function(e) {
                    e.preventDefault();
                    var formPanel = $('.js-form-download-globaltype-translations')
                        ,errorContainer = $('.globaltypetranslationsdownload-error');
                    if (errorContainer) {
                        errorContainer.hide();
                    }
                    formPanel.append(me.addTranslation());
                }

                ,addTranslation: function() {
                    var rowTranslation = $('<div/>', {'class' :'flexform__row form-group js-form-download-select-translations'})
                                .append(
                                    $('<input/>', {
                                        'type': 'text'
                                        ,'value': ''
                                        ,'name': 'language'
                                        ,'class': 'form-control js-download-globaltype-translations-language-code'
                                        ,'placeholder':Translator.trans('globaltype.tables.translations.placeholder.language', {}, 'cms')
                                    })
                                )
                                .append(
                                    $('<input/>', {
                                        'type': 'text'
                                        ,'value': ''
                                        ,'name': 'country'
                                        ,'class': 'form-control js-download-globaltype-translations-country-code'
                                        ,'placeholder':Translator.trans('globaltype.tables.translations.placeholder.country', {}, 'cms')
                                    })
                                )
                                .append(
                                    $('<button/>', {
                                        'class': 'btn btn-xs btn-danger js-download-translation-remove'
                                        ,'type': 'button'
                                    })
                                    .append($('<span/>', {
                                        'class': 'fa fa-trash'
                                    }))
                                )
                        //)
                    ;

                    return rowTranslation;
                }
                /**
                 *
                 * remove all <div> in the form
                 */
                ,onResetLanguagesToDownload: function(e) {
                    e.preventDefault();
                    var formPanel = $('.js-form-download-select-translations');
                    formPanel.find('div').remove();
                }

                ,onDeleteTranslation: function(e) {
                    e.preventDefault();
                    var button = $(e.currentTarget)
                        ,id = button.data('id');
                    swal({
                        title:  Translator.trans('globaltypetranslations.sweetalert.delete.title', {}, 'cms'),
                        text:  Translator.trans('globaltypetranslations.sweetalert.delete.text', {}, 'cms'),
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: Translator.trans('globaltypetranslations.sweetalert.confirmButton', {}, 'cms'),
                        cancelButtonText: Translator.trans('globaltypetranslations.sweetalert.cancelButton', {}, 'cms'),
                        closeOnConfirm: true
                    },
                    function(isConfirm) {
                        if (isConfirm !== true) {
                            return false;
                        } else {
                            me.deleteTranslation(id);
                        }
                    });
                }

                ,deleteTranslation: function(id) {
                    $.ajax({
                        url: Routing.generate('cms_globaltype_translations_delete_ajax')
                        ,data: {
                            id: id
                        }
                        ,method:'POST'
                        ,beforeSend: function(e, xhr, options) {
                            e.loaderKey = "deletetranslation";
                            me.config.loader.startLoader(e.loaderKey, $('.js-cmsglobaltypetranslations'));
                        }
                    })
                    .done(function() {
                        me.onSubmitFilter();
                    });
                }

                ,onSaveTranslations: function(e) {
                    e.preventDefault();
                    var button = $(e.currentTarget)
                        ,modal = button.parents('.modal')
                        ,gtContainer = modal.find('.globaltypes-textbox')
                        ,table = modal.find('.js-collection-content')
                        ,globalType = {'gt':'','st':'','at':''}
                        ,translations = []
                        ,id = (button.attr('data-id'))?button.attr('data-id'):''
                        ,twoLetterError = false
                        ,descriptionEmpty = false
                        ,hasError = false
                        ,errorMessage = [];
                    $.each(gtContainer, function() {
                        var input = $(this);
                        if (input.data('type') === 'globaltype') {
                            globalType.gt = input.val();
                        }
                        if (input.data('type') === 'subtype') {
                            globalType.st = input.val();
                        }
                        if (input.data('type') === 'additionaltype') {
                            globalType.at = input.val();
                        }
                    });

                    $.each(table.children(), function() {
                        var child = $(this)
                            ,name = child.find('textarea[name="name"]').val()
                            ,languageInputField = child.find('input[name="language"]')
                            ,language = languageInputField.val().toLowerCase()
                            ,countryInputField = child.find('input[name="country"]')
                            ,country = countryInputField.val().toUpperCase()
                            ,translation = {'name':name, 'language':language, 'country':country};
                        child.find('input[name="language"]').removeClass('globaltypetranslations-inputerror');
                        child.find('input[name="country"]').removeClass('globaltypetranslations-inputerror');
                        child.find('textarea[name="name"]').removeClass('globaltypetranslations-inputerror');
                        if (language.length !== 2) {
                            twoLetterError = true;
                            child.find('input[name="language"]').addClass('globaltypetranslations-inputerror');
                        }
                        if (country.length !== 2) {
                            twoLetterError = true;
                            child.find('input[name="country"]').addClass('globaltypetranslations-inputerror');
                        }
                        if (name === "") {
                            descriptionEmpty = true;
                            child.find('textarea[name="name"]').addClass('globaltypetranslations-inputerror');
                        }
                        translations.push(translation);
                    });
                    if (!globalType.gt) {
                        errorMessage.push(Translator.trans('globaltypetranslations.errormessage.noglobaltype', {}, 'cms'));
                        hasError = true;
                    }
                    if (twoLetterError) {
                        errorMessage.push(Translator.trans('globaltypetranslations.errormessage.twolettererror', {}, 'cms'));
                        hasError = true;
                    }
                    if (descriptionEmpty) {
                        errorMessage.push(Translator.trans('globaltypetranslations.errormessage.notranslation', {}, 'cms'));
                        hasError = true;
                    }
                    if (hasError) {
                        var message = "";
                        $.each(errorMessage, function(k,v) {
                            if (k !== 0) {
                                message += '<br>'
                            }
                            message += v;
                        });
                        me.handleModalErrorMessage(modal
                            ,message);
                        return;
                    }
                    $.ajax({
                        url: Routing.generate('cms_globaltype_translations_save_ajax')
                        ,data: {
                            id: id
                            ,globaltype: globalType
                            ,translations: translations
                        }
                        ,method:'POST'
                        ,beforeSend: function(e, xhr, options) {
                            e.loaderKey = "updatetranslation";
                            me.config.loader.startLoader(e.loaderKey, modal.find('.modal-footer'));
                        }
                    })
                    .done(function(data) {
                        if (data.status == "error") {
                            var errorKey = 'globaltypetranslations.errormessage.' + data.errorkey
                                ,error = Translator.trans(errorKey, {}, 'cms');
                            me.handleModalErrorMessage(modal, error);
                        } else {
                            modal.modal('hide');
                            me.onSubmitFilter();
                        }
                    });
                }

                ,handleModalErrorMessage: function(modal, message) {
                    var errorContainer = modal.find('.globaltypetranslations-error')
                        ,errorContent = errorContainer.find('.globaltypetranslations-errorcontent');
                    errorContent.html(' '+message);
                    errorContainer.fadeIn(600, function() {
                        setTimeout(function() {
                            errorContainer.fadeOut("slow");
                        }, 2000);
                    });
                }

                ,onResetModal: function(e) {
                    var modal = $(e.currentTarget)
                        ,gtContainer = modal.find('.globaltypes-textbox')
                        ,table = modal.find('.js-collection-content')
                        ,saveButton = modal.find('.js-savetranslations');
                    saveButton.attr('data-id', '');
                    $.each(gtContainer, function() {
                        var input = $(this);
                        if (input.data('type') !== 'globaltype') {
                            input.attr('disabled', true);
                        }
                        input.select2("val", null);
                    });
                    $.each(table.children(), function() {
                        var child = $(this);
                        if (!child.hasClass('js-collection-item-first')) {
                            child.remove();
                        } else {
                            child.find('input[name="language"]').removeClass('globaltypetranslations-inputerror');
                            child.find('input[name="country"]').removeClass('globaltypetranslations-inputerror');
                            child.find('textarea[name="name"]').removeClass('globaltypetranslations-inputerror');
                            child.find('input[name="language"]').val("");
                            child.find('input[name="country"]').val("");
                            child.find('textarea[name="name"]').val("");
                        }
                    })
                }

                ,onRemoveDownloadTranslation: function(e) {
                    e.preventDefault();
                    var button = $(e.currentTarget);
                    var div = button.parents('.js-form-download-select-translations');
                    div.remove();
                }

                ,onRemoveTranslation: function(e) {
                    e.preventDefault();
                    var button = $(e.currentTarget)
                        ,tr = button.parents('.js-collection-item');
                    tr.remove();
                }

                ,onAddTranslation: function(e) {
                    e.preventDefault();
                    me.createTranslationRow();
                }

                ,createTranslationRow: function(translation) {
                    var language = ''
                        ,country = ''
                        ,name = '';
                    if (translation) {
                        language = (translation.Language)?translation.Language:'';
                        country = (translation.Country)?translation.Country:'';
                        name = (translation.Name)?translation.Name:'';
                    }
                    $('.js-collection-content')
                        .append(
                            $('<tr/>', {'class':'js-collection-item'})
                                .append(
                                    $('<td/>', {'class':'collection-item-container'})
                                        .append(
                                            $('<div/>')
                                                .append(
                                                    $('<input/>', {
                                                        'type':'text'
                                                        ,'value': language
                                                        ,'name': 'language'
                                                        ,'class':'form-control collection-item-input collection-item-inputfirst'
                                                        ,'placeholder':Translator.trans('globaltype.tables.translations.placeholder.language', {}, 'cms')})
                                                )
                                                .append(
                                                    $('<input/>', {
                                                        'type':'text'
                                                        ,'value': country
                                                        ,'name': 'country'
                                                        ,'class':'form-control collection-item-input'
                                                        ,'placeholder':Translator.trans('globaltype.tables.translations.placeholder.country', {}, 'cms')})
                                                )
                                        )
                                        .append(
                                            $('<div/>')
                                                .append(
                                                    $('<textarea/>', {
                                                        'class': 'form-control collection-item-textarea'
                                                        ,'value': name
                                                        ,'text': name
                                                        ,'name': 'name'
                                                        ,'placeholder':Translator.trans('globaltype.tables.translations.placeholder.description', {}, 'cms')})
                                                )
                                        )
                                )
                                .append(
                                    $('<td/>')
                                        .append(
                                            $('<button/>', {'class':'btn btn-danger btn-block collection-remove js-collection-remove'})
                                                .append(
                                                    $('<i/>', {'class':'fa fa-trash'})
                                                )
                                        )
                                )
                        );
                }

                ,onCreateGlobalTypeTranslation: function(e) {
                    var modal = $(e.currentTarget)
                        ,button = $(e.relatedTarget);
                    if (!me.isGTCreated) {
                        var elm = {}
                            ,gtContent = me.generateGtTableContent('globaltype', 0, 0, elm)
                            ,stContent = me.generateGtTableContent('subtype', 0, 0, elm)
                            ,atContent = me.generateGtTableContent('additionaltype', 0, 0, elm)
                            ,form = modal.find('#createglobaltypetranslationform');

                        form.find('.js-globaltype-select2').append(gtContent);
                        form.find('.js-subtype-select2').append(stContent);
                        form.find('.js-additionaltype-select2').append(atContent);
                        me.attachSelectControlsForGlobalTypes();
                        me.isGTCreated = true;
                    }
                    me.initModalFields({
                        id : button.data('id')
                        ,gt : button.data('gt')
                        ,st : button.data('st')
                        ,at : button.data('at')
                        ,translations : button.data('translations')
                    }, modal);
                }

                ,initModalFields: function(obj, modal) {
                    var data = obj
                        ,activateGlobalType = false;
                    $.each(modal.find('input.globaltypes-textbox'), function() {
                        var input = $(this);
                        if (input.data('type') === 'globaltype') {
                            input.select2('data', { id:data.gt, title: data.gt});
                            if (data.gt) {
                                activateGlobalType = true;
                            }
                        }
                        if (input.data('type') === 'subtype') {
                            if (activateGlobalType) {
                                input.attr('disabled', false);
                            }
                            input.select2('data', { id:data.st, title: data.st});
                        }
                        if (input.data('type') === 'additionaltype') {
                            if (activateGlobalType) {
                                input.attr('disabled', false);
                            }
                            input.select2('data', { id:data.at, title: data.at});
                        }
                    });
                    if (data.translations) {
                        $.each(data.translations, function(k,v) {
                            if (k === 0) {
                                var item = modal.find('.js-collection-item');
                                item.find('input[name="language"]').val(this.Language);
                                item.find('input[name="country"]').val(this.Country);
                                item.find('textarea[name="name"]').val(this.Name);
                            } else {
                                me.createTranslationRow(this);
                            }

                        });
                    }
                    modal.find('.globaltypetranslations-error').hide();
                    modal.find('.js-savetranslations').attr('data-id', data.id);
                }

                ,onSubmitFilter: function(e) {
                    me.config.table.DataTable().ajax.reload(function() {
                    }, false );
                    return false;
                }

                ,initDataTable: function() {
                    me.config.table.DataTable({
                        ajax: {
                            url: Routing.generate('cms_globaltype_translations_list_ajax')
                            ,beforeSend: function(e, xhr, options) {
                                e.loaderKey = "translationTable";
                                me.config.loader.startLoader(e.loaderKey, me.config.table);
                            }
                            ,data: function (d) {
                                $.each($(me.config.filter).serializeArray(), function (idx, kV) {
                                    if (kV.value !== '') {
                                        d[kV.name] = kV.value;
                                    }
                                });
                                return d;
                            }
                            ,method: 'POST'
                        },
                        serverSide: true,
                        lengthChange: true,
                        searching: false,
                        pagingType: 'simple_numbers',
                        pageLength: 10,
                        columns: [
                            {data: 'id', name:'id', sortable: false},
                            {data: 'gt', name:'gt', sortable: false},
                            {data: 'st', name:'st', sortable: false},
                            {data: 'at', name:'at', sortable: false},
                            {name:'translations', data: function (row, type, set, meta) {
                                var translations = row.translations
                                    ,div = $('<div/>', {
                                        'class':'clearfix'
                                    });
                                $.each(translations, function(){
                                    div.append(
                                        $('<div/>', {'class':'globaltypetranslations__translationitem'})
                                            .append(
                                                $('<span/>', {'class':'globaltypetranslations__translationitemlabel'
                                                    ,'text':Translator.trans('globaltype.tables.translations.description', {}, 'cms') + ': '})
                                            )
                                            .append(
                                                $('<span/>', {'class':'globaltypetranslations__translationitemtext'
                                                    ,'text':this.Name + ' | '})
                                            )
                                            .append(
                                                $('<span/>', {'class':'globaltypetranslations__translationitemlabel'
                                                    ,'text':Translator.trans('globaltype.tables.translations.language', {}, 'cms') + ': '})
                                            )
                                            .append(
                                                $('<span/>', {'class':'globaltypetranslations__translationitemtext'
                                                    ,'text':this.Language + ' | '})
                                            )
                                            .append(
                                                $('<span/>', {'class':'globaltypetranslations__translationitemlabel'
                                                    ,'text':Translator.trans('globaltype.tables.translations.country', {}, 'cms') + ': '})
                                            )
                                            .append(
                                                $('<span/>', {'class':'globaltypetranslations__translationitemtext'
                                                    ,'text':this.Country})
                                            )
                                    )
                                });
                                return div[0].outerHTML;
                            }, sortable: false},
                            {data: function(row, type, set, meta) {
                                var editButton = $('<a/>', {
                                    'class': 'btn btn-default'
                                    ,'type': 'button'
                                    ,'data-id':row.id
                                    ,'data-gt':row.gt
                                    ,'data-st':row.st
                                    ,'data-at':row.at
                                    ,'data-translations': JSON.stringify(row.translations)
                                    ,'data-toggle':'modal'
                                    ,'data-keyboard':'false'
                                    ,'data-backdrop':'static'
                                    ,'data-target':'#cmsglobaltypetranslationsmodal'
                                }).append($('<span/>', {
                                    'class': 'fa fa-edit'
                                }))[0].outerHTML;
                                var deleteButton = $('<a/>', {
                                    'class': 'btn btn-default js-deletetranslation'
                                    ,'type': 'button'
                                    ,'data-id':row.id
                                }).append($('<span/>', {
                                    'class': 'fa fa-trash'
                                }))[0].outerHTML;
                                return editButton + ' ' + deleteButton;
                            }, sortable: false}
                        ],
                        language: {
                            paginate: {
                                next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
                                previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
                            },
                            thousands: '.',
                            info: Translator.trans('contentstore.producttable.info', {}, 'cms'),
                            infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms'),
                            emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
                        },
                        fnDrawCallback: function( oSettings ){
                            var settings = this.fnSettings();

                            if( settings.fnRecordsTotal() <= settings._iDisplayLength){
                                $('#GlobaltypeTranslationsTable_paginate').hide();
                            }else{
                                $('#GlobaltypeTranslationsTable_paginate').show();
                            }
                        }
                    });
                }

                ,generateGtTableContent : function(type, rowId, index, el, additionalGt) {
                    var gtValue = el.globaltype || '';
                    additionalGt = additionalGt || false;
                    var additionalInputClass = '';
                    if (additionalGt) {
                        additionalInputClass = ' globaltypes-textbox--added';
                    }
                    var label = "GT";
                    if (type === 'subtype') {
                        label = "ST"
                    }
                    if (type === 'additionaltype') {
                        label = "AT"
                    }
                    var $action;
                    if (type === 'globaltype') {
                        $action =  $('<button/>', {
                            'text': label,
                            'type': 'button',
                            'class': 'btn btn-xs btn-info fa featurematching__typedeletebutton js-globaltypes__typedeletebutton',
                            'data-index': index,
                            'data-id': rowId
                        });
                    } else {
                        $action = $('<span/>', {
                            'text': label,
                            'class': 'featurematching__typeinfo',
                            'data-index': index
                        });
                    }
                    var displayInput = $('<div/>', {
                        'class': 'featurematching__typecontainer featurematching__typecontainer--active featurematching__typecontainer--' + type + ' featurematching__typecontainer--' + rowId
                        ,'data-index': index
                        ,'data-id': rowId
                        ,'data-active': true
                        ,'data-reset': !additionalGt
                    }).append(
                        $('<label/>', {
                            'class': 'featurematching__typelabel',
                            'for': rowId + '-'+ type + '-' + index,
                        }).append(
                            $action
                        ).append(
                            $( "<input/>", {
                                'id':rowId + '-'+ type + '-' + index,
                                'value':((el[type])?el[type]:''),
                                'class':rowId + '-type gtype globaltypes-textbox' + additionalInputClass,
                                'data-org':((el[type])?el[type]:''),
                                'data-text':((el[type + 'label'])?el[type + 'label']:''),
                                'data-index': index,
                                'data-type': type,
                                'data-id': rowId,
                                'disabled': (type !== 'globaltype' && gtValue === '') ? true : false
                            })
                        )
                    );
                    if (additionalGt) {
                        return displayInput;
                    }
                    return displayInput[0].outerHTML;
                }

                ,attachSelectControlsForGlobalTypes : function() {
                    var $elements = $( '#cmsglobaltypetranslationsmodal .form-group .globaltypes-textbox');
                    $elements.each(function(){
                        var $element = $(this);
                        var id = $element.data('id'),
                            index = $element.data('index'),
                            type = $element.data('type'),
                            parent = '',
                            searchFor = '',
                            search_mapping =  {
                                'globaltype': 'globaltype',
                                'subtype': 'globaltype',
                                'additionaltype': 'subtype' },
                            type_mapping = {
                                'globaltype': 'globalType',
                                'subtype': 'subType',
                                'additionaltype': 'additionalType' };

                        switch(type) {
                            case 'globaltype':
                                parent = '';
                                break;
                            case 'subtype':
                                parent = 'globaltype';
                                break;
                            case 'additionaltype':
                                parent = 'subtype';
                                break;
                        }

                        $(this).select2({
                            minimumInputLength: 2,
                            placeholder: ' ',
                            dropdownCssClass : 'bigdrop',
                            allowClear: true,
                            quietMillis: 250,
                            ajax: {
                                url: function() {
                                    if (type!=='globaltype') {
                                        searchFor = $('#' + id + '-' + search_mapping[type] + '-' + index).val();
                                        // searchFor = $('#' + id + '-' + fieldCount + '-' + search_mapping[type]).val();
                                    }
                                    if (type==='additionaltype' && searchFor ==='') {
                                        searchFor = $('#' + id + '-' + search_mapping['globaltype'] + '-' + index).val();
                                        // searchFor = $('#' + id + '-' + fieldCount + '-' + search_mapping['globaltype']).val();
                                    }
                                    var uri = Routing.generate('cms_featurematching_grouptypelist', {
                                        'type': type_mapping[type],
                                        'gt': searchFor,
                                        'count': 20
                                    });
                                    return uri;
                                },
                                dataType: 'json',
                                data: function (term, page) {
                                    return {
                                        query: term,
                                        page: page
                                    };
                                },
                                results: function (data, page) {
                                    var more = (page * 20) < data.total_count;
                                    return {
                                        results: data.items,
                                        more: more
                                    };
                                }
                            },
                            escapeMarkup: function (m) { return m; },
                            initSelection: function(element, callback) {
                                callback({
                                    'id': $(element).data('org'),
                                    'label': $(element).data('text')
                                });
                            },
                            formatSelection: function(element) {
                                var item = '',
                                    caption = '',
                                    label = '';

                                if (typeof element.id  !== 'undefined') {
                                    item = item + element.id;
                                    caption = $( "<div/>", {
                                        'class': 'select2_option_item_text'
                                    }).append(element.id)[0].outerHTML;
                                    item = caption;
                                }
                                if (typeof element.label  !== 'undefined' && element.label.length > 0) {
                                    label = $( "<div/>", {
                                        'class': 'select2_option_item_label',
                                        'title': element.label
                                    }).append(element.label)[0].outerHTML;
                                    item = item + ' ' + label;
                                }
                                return item;
                            },
                            formatResult: function(result) {
                                var color = 'iherit';
                                if (result.alternative==="true") {
                                    color = '#005DCC';
                                }
                                var item = $(
                                    '<span style="font-weight:bold;color:'+ color +';">' + result.id + '</span>'  +
                                    '<div>' + result.label + '</div>'
                                );
                                return item;
                            }
                        }).on("change", function(e) {
                            var $input,
                                $el = $(this),
                                index = $el.data('index'),
                                id = $el.data('id');

                            if (e.val) {
                                if( parent==='') {
                                    $('#' + id + '-subtype-'+index).prop('disabled',false).select2('val', null);
                                    $('#' + id + '-additionaltype-'+index).prop('disabled',false).select2('val', null);
                                }
                            } else {
                                if ($el.data('type') === 'globaltype') {
                                    $('#' + id + '-subtype-'+index)
                                        .prop('disabled',true)
                                        .select2('val', null);
                                    $('#' + id + '-additionaltype-'+index)
                                        .prop('disabled',true)
                                        .select2('val', null);
                                }
                            }
                        });
                    });
                }
            };

            /* public */
            var baseObj = {
                'updateGlobalTypes' : me.updateGlobalTypes
            };

            /* attach public functions to singleton */
            $.each(injection.call(me, me), function(idx, prop) {
                baseObj[idx] = prop;
            });

            /* execute init method */
            me.init();

            return baseObj;
        };

        return {
            inject: function(inject) {
                injection = inject;
            }
            ,getInstance: function() {
                if (instance === null) {
                    instance = initInstance();
                }
                return instance;
            }
        }
    })();
});

$(function() {
    if ($('.js-cmsglobaltypetranslations').length > 0) {
        CMS.GLOBALTYPE.TRANSLATIONS.TranslationsList.getInstance();
    }
});
