/*
 * @copy 2015 peakwork AG
 * @author Kemal Secer <kemal.secer@peakwork.com>
 */


;(function ( $, window, document, undefined ) {
    "use strict";

    var pluginName = "keyValueList",
        defaults = {
            uuid: $.uuid(),
            width: '100px',
            caption: '',
            class: 'form-control',
            'name': '',
            'header': {'key':'', 'value':''},
            //event functions
            drawComplete: null,
            deleteRow: null,
            //misc
            dataStorage: [],
            _presentationDiv: '',
            _dataDiv: '',
            _pluginName: pluginName,
            language: {
                deleteConfirmationTitle: 'Delete Row',
                deleteConfirmationText: 'Are you sure ?',
                resetConfirmationTitle: 'Restore Changes',
                resetConfirmationText: 'Should i restore all items ?'
            },
            showResetButton: true
        };

    // The actual plugin constructor
    function keyValueList ( element, options ) {
        this.element = element;
        this.settings = $.extend( true, {}, defaults, options );
        this._defaults = defaults;
        this._name = pluginName;

        this.init();
    }


    var createUpdateTextfieldEvent = function(settings) {
        return function() {
            var newValue = [],
                //id = $(this).parent().parent().attr('id').split('-row-')[0];
                id = $(this).parent().parent().attr('id');
            //extract values
            $(this).parent().parent().find ('input[type="text"]').each(function(){
                //..and push them into an array
                newValue.push( $(this).val() );
            });
            //update "data input"
            $('#' + id + '-input').val( newValue.join('|'));
        };
    };

    var createAddButtonEvent = function(settings) {
        return function() {
            var btn = $(this),
                id = btn.data('id'),
                newIndex = id + 1;
            //add a new "row" (key / value input pair & button)
            addRow(settings, newIndex  , $('<input/>',{type: 'text', value:''}), 'add' );
            //change current button from "add" to "delete"
            switchButtonType( id );
        };
    };

    var createDeleteButtonEvent = function(settings) {
        return function() {
            var btn = $(this),
                id = btn.data('id'),
                title = settings.language.deleteConfirmationTitle,
                text = settings.language.deleteConfirmationText;

            getConfirmation(title, text, function(isConfirm){
                if (isConfirm) {
                    deleteRow ( settings.uuid + '-row-' + id );
                    deleteRow ( settings.uuid + '-row-' + id + '-input');
                }
            });
            /*if (getConfirmation(settings.language.deleteConfirmation) === true) {
                deleteRow ( settings.uuid + '-row-' + id );
                deleteRow ( settings.uuid + '-row-' + id + '-input');
            }*/
        };
    };
    var createRestoreButtonEvent = function(settings) {
        return function() {
            /*var title = settings.language.resetConfirmationTitle,
                text = settings.language.resetConfirmationText;

            getConfirmation(title, text, function(isConfirm){
                if (isConfirm) {
                    resetControl(settings);
                }
            });
            if (getConfirmation(settings.language.resetConfirmation) === true) {
                resetControl(settings);
            }*/
            resetControl(settings);
        };
    };




    var getConfirmation = function(title, text, callback) {
        swal({
            title: title
            ,text: text
            ,type: "warning"
            ,showCancelButton: true
            ,confirmButtonText: Translator.trans('def.button.confirm.title', {}, 'cms')
            ,cancelButtonText: Translator.trans('def.button.cancel.title', {}, 'cms')
            ,closeOnConfirm: true
            ,closeOnCancel: true
        }, callback);
        //return confirm(questionText);
    };


    var initKeyValueList = function(element, settings ) {

        var index = 1,
            counter = 1,
            button =
            $( "<button/>", {
                'id': settings.uuid  + '-reset',
                'class': 'btn btn-default reset-btn',
                'type': 'button',
                'data-toggle':'tooltip',
                'data-id': index,
                'data-placement':'top',
                'data-original-title': 'Restore Original Data'
            } ).append(
            $( "<span/>", {
                'class': 'fa fa-undo'
            } )
            )[0].outerHTML;
        if (!settings.showResetButton || settings.readonly) {
            button = "";
        }

        settings._presentationDiv = settings._pluginName + '-' +settings.uuid + '-presentation';
        settings._dataDiv = settings._pluginName + '-' +settings.uuid + '-data';

        //set caption AND append group divs
        $(element).css('width', settings.width)
            .attr('id', settings._pluginName + '-' +settings.uuid )
            .prepend( $('<div/>', {
                    style: 'margin-bottom:5px;'
                }).append( button + ' ' + settings.caption )
            )
            .append( $('<div/>', { id: settings._presentationDiv }))
            .append( $('<div/>', { id: settings._dataDiv }))
            //attach  the object events
            .on('click','.add-btn', createAddButtonEvent(settings))
            .on('click','.delete-btn', createDeleteButtonEvent(settings))
            .on('click','.reset-btn', createRestoreButtonEvent(settings))
            .on('keyup','.input-group-lg',createUpdateTextfieldEvent(settings))
            //loop through input[text] array
            .find('input[type="text"]').each(function() {
                //store data into storage (for the restore function)
                settings.dataStorage[index] = $(this).val();
                //create display and data rows
                addRow(settings, index , $(this), 'delete' );
                index++;
            });
            //add a new "row" (key / value input pair & button)
            if (!settings.readonly) {
                addRow(settings, index, $('<input/>', {type: 'text', value: ''}), 'add');
            }
            addHeader(settings, element, settings._presentationDiv);
    };

    // add Header
    var addHeader = function(settings, element, presentationDiv) {
        var presContainer = $(element).find('#'+presentationDiv)
            ,width = presContainer.find('.form-group:first').width()
            ,headerObject = $('<div/>', {
                'class': 'form-inline'
            })
            .append( $('<div/>', {
                    'class': 'form-group form-group--header'
                }).append( $('<span/>', {
                    'text': settings.header.key
                    ,'style': 'width:' + width + 'px;display:inline-block;margin:0 0 3px 5px;font-weight:bold;'
                }))
            )
            .append( $('<div/>', {
                    'class': 'form-group form-group--header'
                }).append( $('<span/>', {
                    'text': settings.header.value
                    ,'style': 'width:' + width + 'px;display:inline-block;margin:0 0 3px 5px;font-weight:bold;'
                }))
            );
        headerObject.insertBefore(presContainer);
    };

    // "Row" actions
    var addRow = function(settings, index, elm, buttonType) {
        var data = $(elm).val(),
            key = data.split('|')[0],
            value = data.split('|')[1],
            row_uuid = settings.uuid + '-row-' + index;

        $(elm).attr('id', row_uuid + '-input' );
        $(elm).attr('name', settings.name + '[]' );

        $( '#' + settings._dataDiv ).append( $(elm) ); //move
        $(elm).hide(); //hide

        $( '#' + settings._presentationDiv ).append(
            assembleNewHtmlControls(settings, row_uuid, index, key, value, buttonType )
        );
    };

    var deleteRow = function( rowId ) {
        $('#' + rowId).remove();


    };

    var switchButtonType = function(rowIndex){
        $('*[data-id="' + rowIndex + '"]')
                .removeClass('add-btn')
                .addClass('delete-btn')
                .data('original-title', 'Delete this pair')
        ;
        $('*[data-id="' + rowIndex + '"] > span')
                .removeClass('fa-plus')
                .addClass('fa-trash-o')
        ;
    };

    /**
     * renders a key / value pair row with corresponding delete button
     * @param {array} settings
     * @param {string} row_uuid
     * @param {integer} index
     * @param {string} key
     * @param {string} value
     * @param {string} buttonType "add" or "delete"
     */
    var assembleNewHtmlControls = function(settings, row_uuid, index, key, value, buttonType) {

        var iconImage = '',
            toolTip = '';

        switch (buttonType) {
            case 'delete':
                iconImage = 'fa-trash-o';
                toolTip = 'Delete this pair';
                break;
            case 'add':
                iconImage = 'fa-plus';
                toolTip = 'Add New';
                break;

        }
        var row =
            $('<div/>', {
                id: row_uuid,
                class: 'form-inline',
                style: 'margin-bottom:5px;',
                'data-row-index': index
            });

        //construct additional html elements
        var keyElm =
            $('<div/>',{class: 'form-group'})
            .append(
                $('<input/>', {
                    type: 'text',
                    class: settings.class + ' input-group-lg key-elm',
                    style: 'max-width: 200px;',
                    value: key,
                    disabled: (settings.readonly)?true:false
                })
            )[0].outerHTML,
            valueElm =
            $('<div/>',{class: 'form-group'})
            .append(
                $('<input/>', {
                    type: 'text',
                    class: settings.class + ' input-group-lg val-elm',
                    style: 'max-width: 200px;',
                    value: value,
                    disabled: (settings.readonly)?true:false
                })
            )[0].outerHTML,
            button =
            $( "<button/>", {
                'id': row_uuid + '-'+buttonType,
                'class': 'btn btn-default '+buttonType+'-btn',
                'type': 'button',
                'data-toggle':'tooltip',
                'data-id': index,
                'data-placement':'top',
                'data-original-title': toolTip
            } ).append(
            $( "<span/>", {
                'class': 'fa '+ iconImage
            } )
            )[0].outerHTML;
        if (settings.readonly) {
            button = "";
        }

        row.append( $(keyElm+' '+valueElm+' '+button) );
        //return html elememts
        return row;

    };

    var resetControl = function(settings) {
        //clear control
        $( '#' + settings._dataDiv ).empty();
        $( '#' + settings._presentationDiv ).empty();
        //rebuild from dataStorage
        var index;
        for(index=1;index < settings.dataStorage.length; index++){
            //add a new "row" (key / value input pair & button)
            addRow(settings, index , $('<input/>', {
                type: 'text',
                value: settings.dataStorage[index]
            }), 'delete' );
        }
        //add a new "row" (key / value input pair & button)
        addRow(settings, index , $('<input/>', {type: 'text', value:''}), 'add' );

    };

    // Avoid Plugin.prototype conflicts
    $.extend(keyValueList.prototype, {
        init: function () {
            initKeyValueList(this.element, this.settings);

        },
        deleteRow: function(rowIndex) {
            deleteRow( rowIndex );
        }
    });

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[ pluginName ] = function ( options ) {
        return this.each(function() {
            if ( !$.data( this, "plugin_" + pluginName ) ) {
                $.data( this, "plugin_" + pluginName, new keyValueList( this, options ) );
            }
        });
    };

}(jQuery, window, document));

