$(function () {
    var context = pwNamespace('CMS.CONTENTSTORE.MATCHING');

    context.Products = (function () {
        var instance = null;

        var injection = function() {
            return {}
        };

        var initInstance = function() {
            me = {
                config: {
                    formId: 'contentstore-filter-form'
                    ,inputFieldsList: {}
                    /**
                     * Use allowRematching as mutex to activate/deactivate the rematching button
                     * The button is active if only and only if allowRematching > 0
                     */
                    ,allowRematching: 0
                    ,rematchingButtonId: '#FilterSendToMatching'
                    ,loaderTargetContainer: $('.panel-body')
                    ,loader: Loader.getInstance()
                    ,submitButtonId: '#FilterSendToMatching'
                }
                ,init: function() {
                    me.initInputFieldsList();
                    me.addEventListeners();
                }
                ,initInputFieldsList: function() {
                    var inputFields = $('#' + me.config.formId).find('input')
                        ,selectFields = $('#' + me.config.formId).find('select');

                    $.each(inputFields, function(idx, input) {
                        me.config.inputFieldsList[$(input).context.id] = '';
                        me.checkInputFormField(input);
                        me.addEventChangeListener(input);
                    });
                    $.each(selectFields, function(idx, input) {
                        me.config.inputFieldsList[$(input).context.id] = '';
                        me.checkInputFormField(input);
                        me.addEventChangeListener(input);
                    });
                }
                ,addEventListeners: function() {
                    $(me.config.submitButtonId).on('click', me.onSend);
                }
                ,onResetForm: function() {
                    $.each(me.config.inputFieldsList, function(id) {
                        me.config.inputFieldsList[id] = '';
                    });
                    me.config.allowRematching = 0;
                    me.updateButtonAvailability();
                }
                ,addEventChangeListener: function(field) {
                    $('#' + ($(field).context.id) ).on('change', me.checkInputFormField);
                }
                ,checkInputFormField: function(e) {
                    var input = (e.currentTarget)?e.currentTarget:$(e)[0];
                    if ('' === me.config.inputFieldsList[input.id] && '' === input.value) {
                        me.config.inputFieldsList[input.id] = input.value;
                        return;
                    }
                    if ('' !== me.config.inputFieldsList[input.id] && '' !== input.value) {
                        me.config.inputFieldsList[input.id] = input.value;
                        return;
                    }
                    if ('' === input.value) {
                        me.updateInputFieldsList(input.id, input.value);
                        me.decreaseMutex();
                    }
                    if ('' !== input.value) {
                        me.updateInputFieldsList(input.id, input.value);
                        me.increaseMutex();
                    }
                    me.updateButtonAvailability();
                }
                ,decreaseMutex: function() {
                    me.config.allowRematching--;
                }
                ,increaseMutex: function() {
                    me.config.allowRematching++;
                }
                ,updateInputFieldsList: function(id, value) {
                    me.config.inputFieldsList[id] = value;
                }
                ,updateButtonAvailability: function() {
                    if (me.config.allowRematching > 0) {
                        $(me.config.rematchingButtonId).removeAttr('disabled');
                    }
                    if (me.config.allowRematching === 0) {
                        $(me.config.rematchingButtonId).attr('disabled', true);
                    }
                }
                ,onSend: function(e) {
                    e.preventDefault();
                    swal({
                        title:  Translator.trans('', {}, 'cms'),
                        text:  Translator.trans('contentstore.general.rematching.products.send.confirmtext', {}, 'cms'),
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: Translator.trans('Yes', {}, 'cms'),
                        cancelButtonText: Translator.trans('No', {}, 'cms'),
                        closeOnConfirm: true
                    },
                    function(isConfirm) {
                        if (isConfirm !== true) {
                            return false;
                        } else {
                            me.send(e);
                        }
                    });
                }
                ,send: function(e) {
                    $.ajax({
                        url: Routing.generate('cms_contentstore_rematching_products'),
                        data: me.config.inputFieldsList,
                        beforeSend: function(e, xhr, options) {
                            e.loaderKey = "submitRematchingProducts";
                            me.config.loader.startLoader(e.loaderKey, me.config.loaderTargetContainer);
                        }
                    })
                    .done(function(data){
                        swal({
                                title:  Translator.trans('', {}, 'cms'),
                                text:  Translator.trans('contentstore.general.rematching.products.done', {}, 'cms'),
                                type: 'success',
                                confirmButtonColor: '#3085d6',
                                closeOnConfirm: true
                            },
                            function() {
                                me.config.loader.remove(e.loaderKey, me.config.loaderTargetContainer);
                            }
                        );
                    });
                }
            };
            /**
             * public methods
             * list public methods you want to share
             */
            var publicObj = {
                initRematchingProducts: me.onResetForm
            }
            /**
             * Attach public method's to singleton
             */
            $.each(injection.call(me, me), function(idx, prop) {
                publicObj[idx] = prop;
            });

            /**
             * execute init method
             */
            me.init();

            return publicObj;
        };

        return {
            inject: function(inject) {
                injection = inject;
            }
            ,getInstance: function() {
                if (instance === null) {
                    instance = initInstance();
                }
                return instance;
            }
        }
    })();
});

$(function() {
    if ($('.js-cmsmatchingproducts').length > 0) {
        CMS.CONTENTSTORE.MATCHING.Products.getInstance();
    }
});