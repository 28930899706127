var goaltable_ini = function() {
    var loadingDetailsPage = false
        ,dtRoutingParameter = {}
        ,startvalues = false
        ,loader = Loader.getInstance()
        ,table = $('#cmsgoaltable');

    var $formWithStartvalues = $('.js-filterform--has-startvalues');
    if ($formWithStartvalues.length > 0) {
        startvalues = $formWithStartvalues.data('startvalues');
    }
    if (startvalues) {
        dtRoutingParameter = startvalues;
    }

    $('.revisioninfo-modal').on('show.bs.modal', function (e) {
        var $button = $(e.relatedTarget)
            ,popupInfo = $button.data('popup')
            ,$modal = $(this);
        if (popupInfo) {
            $('.modal-body', $modal).html($.parseHTML($("<textarea/>").html(popupInfo).val()));
            $('.revisioninfo-modal__goalid', $modal).text($button.data('goalid'));
        }
    });
    var goalTableLoaderKey = loader.getGeneratedKeyByPrefix("goalTable");
    loader.startLoader("filter", $(".cmscontentstore--filter"), [goalTableLoaderKey]);
    table
        .on('draw.dt', function(e, settings) {
            $.each($(e.currentTarget).find('.edit-btn'), function(k, v){
                $(v).on('click',GoalController.getInstance().loadGoalDetailPage );
            });
            $.each($(e.currentTarget).find('.view-btn'), function(k, v){
                $(v).on('click',GoalController.getInstance().loadGoalArchiveDetailPage );
            });
        })
        .on('xhr.dt', function ( e, settings, data ) {
        })
        .on('preXhr.dt', function ( e, settings, data ) {
        })
        .dataTable({
            dom:
                "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-4'i><'col-sm-8'p>>"
            ,ajax: {
                url: Routing.generate('cms_goal_list', dtRoutingParameter)
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = goalTableLoaderKey;
                    loader.startLoader(e.loaderKey, table);
                }
            },
            serverSide: true,
            lengthChange: true,
            searching: false,
            pagingType: 'simple_numbers',
            pageLength: 10,
            "order": [],
            columns: [
                {data: 'goalid', name:'goalid', sortable: true},
                {data: 'giataid', name:'giataid', sortable: false},
                {data: 'name' , name: 'name', sortable: true},
                {data: 'street', name:'street', sortable: true},
                {data: 'zipcode', name:'zipcode', sortable: true},
                {data: function(row, type, set, meta) {
                    if (row.hasOwnProperty('cityname') && row.cityname !== '') {
                        return row.cityname;
                    } else {
                        return row.cityid
                    }
                }, name:'cityname', sortable: false},
                {data: function(row, type, set, meta) {
                    if (row.hasOwnProperty('regionname') && row.regionname !== '') {
                        return row.regionname;
                    } else {
                        return row.regionid
                    }
                }, name:'region', sortable: false},
                {data: function(row, type, set, meta) {
                    if (row.hasOwnProperty('countryname') && row.countryname !== '') {
                        return row.countryname;
                    } else {
                        return row.countrycode
                    }
                }, name:'country', sortable: false},
                {data: 'airport', name:'airport', sortable: false},
                {data: function(row, type, set, meta) {
                    if (!row.hasOwnProperty('status')) {
                        return '';
                    }
                    if (row.status.length == 0) {
                        return '';
                    }
                    var states = $( "<ul/>", {
                        'class': "list-unstyled"
                    });
                    $.each(row.status, function(index,value) {
                        if (value === 'needsrevision'
                            && row.hasOwnProperty('revisioninfo')
                            && (row.revisioninfo.hasOwnProperty('tags')
                                || row.revisioninfo.hasOwnProperty('comment'))) {
                            var revText = '';
                            var revHtml = false;
                            var revClasses = {
                                class: 'btn btn-link goaldata__statusitem--revisioninfolink'
                            };
                            if (row.revisioninfo.hasOwnProperty('tags')) {
                                revText += row.revisioninfo.tags.join();
                            }
                            if (row.revisioninfo.hasOwnProperty('comment')) {
                                if (revText !== '') {
                                    revText += ' | ';
                                }
                                revText += row.revisioninfo.comment;
                            }
                            if (revText !== '') {
                                revClasses['title'] = revText;
                            }
                            if (row.revisioninfo.hasOwnProperty('html')) {
                                revHtml = row.revisioninfo.html;
                                $.extend(revClasses, {
                                    'data-toggle': 'modal'
                                    ,'data-target': '.revisioninfo-modal'
                                    ,'data-popup': revHtml
                                    ,'data-goalid': row.goalid
                                });
                            }
                            states
                                .append($('<li/>', {
                                    'class': "label label-default cmsgoaltable__itemstatus cmsgoaltable__itemstatus--" + value + (revHtml ? ' cmsgoaltable__itemstatus--revisioninfo' : '')
                                })
                                .append($('<button>', revClasses)
                                .text(Translator.trans('goal.goaltable.status.' + value, {}, 'cms'))));
                        } else if (value === 'released') {
                            // Do not show label
                        } else {
                            states.append($('<li/>', {
                                'class': "label label-default cmsgoaltable__itemstatus cmsgoaltable__itemstatus--" + value
                            }).text(Translator.trans('goal.goaltable.status.' + value, {}, 'cms')));
                        }
                    });
                    return states[0].outerHTML;
                }, sortable: false},
                {data: function(row, type, set, meta) {
                    var title = Translator.trans('contentstore.producttable.show', {}, 'cms')
                        ,className = "btn btn-default edit-btn"
                        ,faClassName = "fa fa-eye";
                    if (row.status.indexOf('deprecated')!=-1) {
                        title = Translator.trans('contentstore.producttable.show', {}, 'cms');
                        className = "btn btn-default view-btn";
                        faClassName = "fa fa-eye";
                    }
                    return  $( "<div/>", {
                        'class': className
                        ,'data-toggle': 'tooltip'
                        ,'data-original-title': title
                        ,'data-key':row.goalid
                    }).append(
                        $( "<span/>", {class: faClassName } )
                    )[0].outerHTML;
                }, sortable: false}
            ],
            language: {
                paginate: {
                    next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
                    previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
                },
                thousands: '.',
                info: Translator.trans('contentstore.producttable.info', {}, 'cms'),
                infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms'),
                emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
            },
            fnDrawCallback: function( oSettings ){
                if( oSettings.fnRecordsTotal() === 1) {
                    loadingDetailsPage = true;
                    GoalController.getInstance().loadGoalDetailPageByKey(oSettings.json.data[0].goalid);
                }

                if( oSettings.fnRecordsTotal() <= oSettings._iDisplayLength){
                    $('#cmsgoaltable_paginate').hide();
                }else{
                    $('#cmsgoaltable_paginate').show();
                }
            }
        });
};

var historytable_ini = function(id) {
    var table = $('#cmsgoalhistorytable'),
        loader = Loader.getInstance();
    table
        .on( 'init.dt', function () {
            $('.cmscontentstore--goalhistory-btn').click();
        } )
        .dataTable({
            ajax: {
                url: Routing.generate('cms_goal_listhistory', {'id':id})
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = loader.getGeneratedKeyByPrefix("goalHistory");
                    loader.startLoader(e.loaderKey, table);
                }
            },
            serverSide: false,
            lengthChange: true,
            searching: true,
            pagingType: 'simple_numbers',
            pageLength: 10,
            "order": [],
            columns: [
                {data: 'goalid', name:'goalid', sortable: false},
                {data: 'timestamp', name:'timestamp', sortable: false},
                {data: function(row, type, set, meta) {
                    var contributor = "";
                    if (row.contributorname) {
                        contributor = 'Name: ' + row.contributorname + '<br/>'
                    }
                    if (row.contributoremail) {
                        contributor += 'Email: ' + row.contributoremail + '<br/>'
                    }
                    if (row.contributorunit) {
                        contributor += 'Unit: ' + row.contributorunit
                    }

                    return contributor;
                },sortable: false},
                {data: 'field', name:'field', sortable: false},
                {data: 'action', name:'action', sortable: false},
                {data: function(row, type, set, meta) {
                    return  historyTableFormatChange(row.oldvalue);
                },sortable: false},
                {data: function(row, type, set, meta) {
                    return  historyTableFormatChange(row.newvalue);
                },sortable: false},

            ],
            language: {
                paginate: {
                    next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
                    previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
                },
                thousands: '.',
                info: Translator.trans('contentstore.producttable.info', {}, 'cms'),
                infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms'),
                emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
            },
            fnDrawCallback: function( oSettings ){
                var settings = this.fnSettings();
                if( settings.fnRecordsTotal() <= settings._iDisplayLength){
                    $('#cmsgoalhistorytable_paginate').hide();
                }else{
                    $('#cmsgoalhistorytable_paginate').show();
                }
            }
        });
    function historyTableFormatChange(content) {
        return $('<span />', {
            class: "cmsgoalhistorytable__dbvalues"
        }).text(content)[0].outerHTML;
    }
};

var moveToGoalEventHandler = function(goalId, oldGoalId, supplier, productcode) {
    if (goalId === '') {
        GoalController.getInstance().removeMatchingToGoalEventHandler(oldGoalId, supplier, productcode);
        return false;
    }
    GoalController.getInstance().moveToGoalEventHandler(goalId, supplier, productcode);
    return false;
};

var goalmappingtable_ini = function() {
    var basicPanel = $('.cmscontentstore--goaldetail .basicdata-panel')
        ,loader = Loader.getInstance()
        ,moveToGoal = false
        ,table = $('#cmsmappingstable');

    if (table.parents('.js-goaldetail-mapping').data('movetogoal')) {
        moveToGoal = table.parents('.js-goaldetail-mapping').data('movetogoal');
    }
    table
        .on('draw.dt', function(e, settings) {
            $.each($(e.currentTarget).find('.js-goalmatching-movetogoal'), function(k, v){
                var form = $(v).parent();
                form.on('submit', function(e) {
                    e.preventDefault();
                    var goalform = $(form),
                        supplier = goalform.data('supplier'),
                        productcode = goalform.data('productcode'),
                        oldGoalId = table.data('goalid'),
                        goalId = goalform.find('input').val();

                    moveToGoalEventHandler(goalId, oldGoalId, supplier, productcode);
                });
            });
        })
        .dataTable({
            ajax: {
                url: Routing.generate('cms_goal_getmappings', {'goalid':basicPanel.data('goalid')})
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = loader.getGeneratedKeyByPrefix("mappingsTable");
                    loader.startLoader(e.loaderKey, table);
                }
            }
            ,serverSide: true
            ,lengthChange: false
            ,searching: false
            ,pagingType: 'simple_numbers'
            ,pageLength: 10
            ,"order": []
            ,columns: [
                {data: 'supplier', name:'supplier', sortable: false}
                ,{data: 'code', name:'code', sortable: false}
                ,{data: 'giataid', name:'giataid', sortable: false}
                ,{data: 'lastupdate', name:'lastupdate', sortable: false}
                ,{data: function(row, type, set, meta) {
                    return $('<a/>', {
                        'class': 'btn btn-default',
                        'type': 'button',
                        'target': '_blank',
                        'href': Routing.generate('cms_contentstore_product_search', { 'tocode': row.supplier, 'productcode': row.code })
                    }).append($('<span/>', {
                        'class': 'fa fa-external-link-square'
                    }))[0].outerHTML;
                }, sortable: false}
                ,{data: function(row, type, set, meta) {
                    var label = $('<label/>', {'text':'Goal Id', 'for':'goalid', 'class':'goalmatching__movetogoallabel'});
                    var input = $('<input/>', {
                        'class': 'form-control js-goalmatching-goalid',
                        'placeholder': 'Goal Id',
                        'name': 'goalid'
                    })[0].outerHTML;
                    var button = $('<button/>', {
                        'class': 'btn btn-default js-goalmatching-movetogoal goalmatching__movetogoalbutton',
                        'type': 'submit',
                        'data-toggle': 'tooltip',
                        'data-template': '<div class="tooltip changegoalidmatchintooltip" role="tooltip"><div class="tooltip-inner"></div></div>',
                        'title': Translator.trans('contentstore.general.warning.emptygoalidmatching', {}, 'cms')
                    }).append($('<span/>', {
                        'class': 'fa fa-arrow-right'
                    }))[0].outerHTML;
                    var form = $('<form/>', {
                        'class': 'js-goalmatching-movetogoalform',
                        'data-supplier': row.supplier,
                        'data-productcode': row.code,
                    }).prepend(label).append(input).append(button)[0].outerHTML;
                    return form;
                }, sortable: false, visible: moveToGoal}
            ]
            ,language: {
                paginate: {
                    next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
                    previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
                }
                ,thousands: '.'
                ,info: Translator.trans('contentstore.producttable.info', {}, 'cms')
                ,infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms')
                ,emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
            }
            ,fnDrawCallback: function( oSettings ){
                var settings = this.fnSettings();

                if( settings.fnRecordsTotal() <= settings._iDisplayLength){
                    $('#cmsmappingstable_paginate').hide();
                }else{
                    $('#cmsmappingstable_paginate').show();
                }
            }
        });
};
