/*
    FE Error handler

    call via event:

    $.publish('cms.basic.messagehandler.show', { html: <html>, fn: <callback function> } );

    <html> rendered via PeakworkStoreCmsBasicBundle:Error:fe-error-items.html.twig

 */

var BasicMessageHandler = (function() {

    var instance
        ,$container
        ,$messagelist;

    function createInstance() {

        var addHandlers = function () {
            $( document ).ajaxComplete(function( event, xhr, settings ) {
                if ( xhr.responseJSON ) {
                    data = xhr.responseJSON;
                    if (data.hasOwnProperty('pw_fe_errors') && data.pw_fe_errors) {
                        $.publish('cms.basic.messagehandler.show', { html: data.pw_fe_errors } );
                    }
                }
            });

            $container = $('.js-fe-message');
            $messagelist = $('.js-fe-message__list', $container);

            $.subscribe('cms.basic.messagehandler.show', newMessages);

            if ($messagelist.children().length > 0) {
                showMessages();
            }
        };

        var newMessages = function(e, data) {
            if (!data.hasOwnProperty('fn')) {
                data.fn = false;
            }
            $messagelist.append(data.html);
            showMessages(data.fn);
        };

        var showMessages = function(fn) {
            swal({
                title: Translator.trans('core.message.title', {}, 'core')
                ,type: "error"
                ,text: $messagelist.html()
                ,html: true
                ,customClass: 'fe-message__swal'
            }, function(okButton) {
                if (typeof fn === 'function') {
                    fn();
                }
                removeMessages();
            });
        };

        var removeMessages = function() {
            $messagelist.html('');
        };

        return {
            init: function() {
                addHandlers();
            }
        };
    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

$(function() {
    if ($('.js-fe-message').length > 0) {
        BasicMessageHandler.getInstance().init();
    }
});

