var BasicDataAirportController = (function() {

    var instance;

    function createInstance() {

        var loader;

        var addHandlers = function () {
            var form = $('#basicdata-airports-filter-form');
            addScrollButtons([
                {title:'Filter', anchor:'.cmsairport--filter'}
                ,{title:'List', anchor:'.cmscontentstore--airporttable'}
                ,{title:'Detail', anchor:'.cmscontentstore--airportdetail'}
            ]);
            form.on('submit', onSubmit );
            form.find('#FilterBasicDataAirportsReset').on('click', onFormResetHandler);
            // form.find("#basicdata-airports-filter-stationtype  option[value='A']").prop('selected', true);
            window.onpopstate = function(e){
                location.reload();
            };
        };

        /**
         * resets filterform
         * @param Event e form event
         */
        var onFormResetHandler = function(e) {
            e.preventDefault();
            //$('#basicdata-airports-stationtype').select2("val", null);
            $("#basicdata-airports-filter-form")[0].reset();
            $("#basicdata-airports-filter-form .select2 ").select2("val", null);
            $("#basicdata-airports-filter-form .form-control").val('');
            $("#FilterBasicDataAirportsSubmit").trigger('click');
        };


        var onSubmit = function(e) {
            e.preventDefault();
            var params = {};
            var tableAction = "cms_basicdata_airport_list";
            var tableId = "#cmsairporttable";
            loader.startLoader("filter", $("#basicdata-airports-filter-form"), ["airportList", "airportBasicData"]);
            window.history.pushState({}, window.document.title, Routing.generate('cms_basicdata_airport_homepage'));
            $('.cmscontentstore--airportdetail').html("");

            $('#basicdata-airports-filter-form input,select').each(function(k, v) {
                if (!$(v).val() || 0 >= $(v).val().length ) {
                    return true;
                }
                var item = $(v);

                var itemName = item.prop('name');
                params[itemName] = item.val();

            });

            var table =$(tableId).DataTable();
            table
                .ajax
                .url(Routing.generate(tableAction, params))
                .load(function() {});
            return false;
        };


        var loadBasicDataAirportDetailPage = function(data, targetContainer) {
            var url = Routing.generate('cms_basicdata_airport_detail');
            var loaderTargetContainer = targetContainer || $('.content')
            return $.ajax(url, {
                data: data,
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "airportBasicData";
                    loader.startLoader(e.loaderKey, loaderTargetContainer);
                },
                success: function(data, status, xhr) {
                    var detailContainer = $('.cmscontentstore--airportdetail');
                    detailContainer.html(data);
                    initMap();
                    // BasicDataMapController.getInstance().init();
                    $('.cmscontentstore--airportdetail-btn').click();
                }
            });
        };

        var initMap = function() {
            var mapsCtx = pwNamespace('PEAKWORK.BASIC.MAP');
            $map = $('.basicdata-airport__map .flexmap__map');
            if (mapsCtx && $map.length > 0) {
                var options = $map.data('mapoptions');
                options['center'] = new google.maps.LatLng(options.latitude, options.longitude);
                new mapsCtx.MapDrawWrapper(options);
            }
        };


        var handlePanels = function(openPanel) {
            var panels = ['#airportlistresultwrapper'];
            var panelToogleClass = (openPanel)?'fa-chevron-down':'fa-chevron-up';
            for(var i in panels) {
                if($(panels[i]).css('display')!=='none') {
                    if($(panels[i]+' .panel-tools .fa').hasClass(panelToogleClass)){
                        $(panels[i]+' .panel-tools .showhide').click();
                    }
                }
            }
        };

        return {
            init: function() {
                loader = Loader.getInstance();
                addHandlers();
                loader.startLoader("filter", $("#basicdata-airports-filter-form"), ["airportList", "airportBasicData"]);
                basicdataairporttable_ini();

            }
            ,loadBasicDataAirportDetailPage: function(e) {
                loadBasicDataAirportDetailPage({
                    'key' : $(this).data('key')
                });
            }
            ,loadBasicDataAirportDetailPageByIata: function(iata, loaderTargetContainer) {
                iata = iata || false;
                if (!iata
                    || iata.length !== 3) {
                    return;
                }
                loadBasicDataAirportDetailPage({
                    'key' : iata
                }, loaderTargetContainer);
            }
            ,handlePanels :function(openPanel) {
                handlePanels(openPanel);
            },
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

$(function() {
    if ($('.js-init-airport').length > 0) {
        BasicDataAirportController.getInstance().init();
    }
});

