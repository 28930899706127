var Loader = (function() {

    var instance;

    function createInstance() {

        var loaderCollection = {}
            ,dependencies = {}
            ,groupLoaderCollection = [];

        var addHandlers = function () {
        };

        var init = function () {
            $( document ).ajaxComplete(function( event, xhr, settings ) {
                if ( xhr.loaderKey ) {
                    removeDependencies(xhr.loaderKey);
                    setTimeout(function(){
                        remove(xhr.loaderKey);
                    }, 1);

                    // console.log("Loader: "+xhr.loaderKey+ " has been removed!");
                }
                if ( xhr.groupLoaderKey && xhr.groupLoaderMemberKey ) {
                    setTimeout(function(){
                        removeGroup(xhr.groupLoaderKey, xhr.groupLoaderMemberKey);
                    }, 1);
                    // console.log("GroupLoader: "+xhr.groupLoaderKey+ " has been removed!");
                }
            });
        };

        var getGeneratedKeyByPrefix = function(key) {
            return key + (new Date()).getTime();
        };

        var add = function(targetContainer) {
            if (!targetContainer) {
                return false;
            }
            var id = $.uuid();
            targetContainer.append($('<div/>', {'class':'loadblock','id':id}));
            return id;
        };

        var startLoader = function(key, targetContainer, dependOn) {
            var id = $.uuid();
            if (loaderCollection[key]) {
                throw new Error('Loader Key "'+ key +'" already exists!');
            }
            loaderCollection[key] = {
                id : id,
                container: targetContainer
            };
            if (dependOn) {
                for (var index in dependOn) {
                    var dependOnKey = dependOn[index];
                    if (!dependencies[dependOnKey]) {
                        dependencies[dependOnKey] = [];
                    }
                    dependencies[dependOnKey].push(key);
                }
            }
            targetContainer.append($('<div/>', {'class':'loadblock','id':id}));
        };

        var startGroupLoader = function(groupKey, targetContainer, groupMember) {
            var id = $.uuid();
            if (groupLoaderCollection[groupKey]) {
                throw new Error('GroupLoaderKey already exists!');
            }
            if (!groupMember || groupMember.length == 0) {
                throw new Error('There must be at least one GroupMember!');
            }
            groupLoaderCollection[groupKey] = {
                id : id,
                container: targetContainer,
                group: groupMember
            };
            targetContainer.append($('<div/>', {'class':'loadblock','id':id}));
        };

        var removeGroup = function(groupKey, groupMember) {
            if (!groupKey || !groupLoaderCollection[groupKey]) {
                return false;
            }
            var loaderEntry = groupLoaderCollection[groupKey];
            if (loaderEntry.group && loaderEntry.group.length > 0) {
                loaderEntry.group.splice($.inArray(groupMember, loaderEntry.group), 1);
            }
            if (loaderEntry.group && loaderEntry.group.length == 0) {
                var _id = '#'+loaderEntry.id;
                loaderEntry.container.find(_id).remove();
                delete groupLoaderCollection[groupKey];
            }
        };

        var removeDependencies = function(key) {
            if (dependencies[key]) {
                for (var dependency in dependencies[key]) {
                    remove(dependencies[key][dependency]);
                }
            }
        };

        var remove = function(key) {
            if (!key || !loaderCollection[key]) {
                return false;
            }
            var loaderEntry = loaderCollection[key];
            var _id = '#'+loaderEntry.id;
            loaderEntry.container.find(_id).remove();
            delete loaderCollection[key];
        };

        return {
            init: init
            ,add: function(targetContainer) {
                return add(targetContainer);
            }
            ,startLoader: function(key, targetContainer, dependOn) {
                return startLoader(key, targetContainer, dependOn);
            }
            ,startGroupLoader: function(groupKey, targetContainer, dependOn) {
                return startGroupLoader(groupKey, targetContainer, dependOn);
            }
            ,remove: function(key) {
                remove(key);
                return true;
            }
            ,getGeneratedKeyByPrefix: function(key) {
                return getGeneratedKeyByPrefix(key);
            }
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
                instance.init();
            }
            return instance;
        }
    };
})();