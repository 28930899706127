var UnsavedChangesController = (function() {

    var instance;
    var navSelectors = {
        '.cms-nav__logoutlink': true
        ,'#navigation > ul > li a': true
    };

    function createInstance() {

        var unsavedChangesList = []
            ,addedBeforeUnload = false;

        var init = function(additionalWatchSelectorArray) {
            additionalWatchSelectorArray = additionalWatchSelectorArray ? additionalWatchSelectorArray : [];
            additionalWatchSelectorArray = $.isArray(additionalWatchSelectorArray) ? additionalWatchSelectorArray : [ additionalWatchSelectorArray ];
            var length = additionalWatchSelectorArray.length - 1;
            if (length < 0) {
                return;
            }
            var i;
            for (var i = 0; i <= length; i++) {
                navSelectors[additionalWatchSelectorArray[i]] = true;
            }

            // console.log(Object.keys(navSelectors));

            if (addedBeforeUnload) {
                removeNavWatcher();
                addNavWatcher();
            }
        };

        var registerMultipleUnsavedChanges = function (elements, action, panel) {
            $.each(elements, function() {
                $(this).on(action, function() {
                    registerUnsavedChanges(panel);
                })
            });
        };

        var addWatchEvents = function() {
            addBeforeUnLoad();
            addNavWatcher();
        };

        var checkWatcherRemoval = function() {
            if (unsavedChangesList.length < 1) {
                removeBeforeUnload();
                removeNavWatcher();
            }
        };

        var addNavWatcher = function() {
            var selectors = Object.keys(navSelectors).join();
            // console.log('addNavWatcher: ' + selectors);
            $('body').on('click.unsavedWatcher', selectors, unsavedChanges);
        };

        var removeNavWatcher = function() {
            $('body').off('click.unsavedWatcher');
        };

        var addBeforeUnLoad = function() {
            if (!addedBeforeUnload) {
                addedBeforeUnload = true;
                $(window).on('beforeunload', function() {
                    return false;
                });
            }
        };

        var removeBeforeUnload = function() {
            if (addedBeforeUnload) {
                addedBeforeUnload = false;
                $(window).off('beforeunload');
            }
        };

        var checkUnsavedChange = function(classArray) {
            var length = classArray.length - 1;
            if (length < 0) {
                return;
            }
            var i;
            for (var i = 0; i <= length; i++) {
                if (unsavedChangeAlreadyExist(classArray[i])) {
                    return true;
                }
            }
            return false;
        };

        var unsavedChangeAlreadyExist = function(unsavedChange){
            if (unsavedChangesList.indexOf(unsavedChange) !== -1) {
                return true;
            }
            return false;
        };

        var registerUnsavedChanges = function(unsavedChanges) {
            // console.log('registerUnsavedChanges');
            if (!unsavedChangeAlreadyExist(unsavedChanges)) {
                // console.log('Add unsaved: ' + unsavedChanges);
                addWatchEvents();
                unsavedChangesList.push(unsavedChanges);
            }
        };

        var unsavedChanges = function() {
            // console.log('unsavedChanges');
            if (unsavedChangesList.length > 0) {
                $.each(unsavedChangesList, function(k,v) {
                    var panel = $(v);

                    // Get Title, ignore child elements like panel-tools
                    var title = panel.find('.panel-heading').contents().filter(function() {
                        return this.nodeType == 3;
                    }).text();

                    if (panel.find('.unsavedcontentpop').length == 0) {
                        panel.css('border-color', 'red');
                        toastr.error("There are edited Elements in <span style='color:red'>'" +
                            title + "'</span> on this page. Changes will be lost if left unsaved! ", "Attention");
                        var messagePop = $('<div/>',{
                            class: "alert alert-danger unsavedcontentpop",
                            text: " "+Translator.trans('contentstore.general.feedback.unsavedcontent', {}, 'cms'),
                            "data-panel": v,
                            click: removeSingleRegisteredUnsavedChanges
                        }).prepend($('<i/>',{ class: 'fa fa-warning' }));
                        panel.find('.panel-heading').after(messagePop);
                    }
                });
                return false;
            }
            return true;
        };

        var removeSingleRegisteredUnsavedChanges = function() {
            var self = $(this);
            self.parent().css('border-color', '#ddd');
            self.slideUp(100, function(){
                $(this).remove();
            });
            unsavedChangesList.splice(unsavedChangesList.indexOf(self.data('panel')), 1);
            checkWatcherRemoval();
            return false;
        };

        var removeRegisteredUnsavedChange = function(unsavedChange) {
            unsavedChangesList.splice(unsavedChangesList.indexOf(unsavedChange), 1);
            checkWatcherRemoval();
            return false;
        };

        var removeRegisteredUnsavedChanges = function() {
            $.each(unsavedChangesList, function(k,v) {
                $(v).css('border-color', '#ddd');
                $('.unsavedcontentpop').slideUp(100, function(){
                    $(this).remove();
                });
            });
            unsavedChangesList = [];
            checkWatcherRemoval();
        };

        return {
            init: init
            ,registerMultipleUnsavedChanges: registerMultipleUnsavedChanges
            ,registerUnsavedChanges: registerUnsavedChanges
            ,unsavedChanges: unsavedChanges
            ,removeSingleRegisteredUnsavedChanges: removeSingleRegisteredUnsavedChanges
            ,removeRegisteredUnsavedChanges: removeRegisteredUnsavedChanges
            ,checkUnsavedChange: checkUnsavedChange
            ,removeRegisteredUnsavedChange: removeRegisteredUnsavedChange
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();