stasticuploadstable_ini = function() {
    var loader = Loader.getInstance()
        ,table = $('#cmsuploadstable');

    table
        .on('draw.dt', function(e, settings) {
            var restartLinks = $(e.currentTarget).find('.js-statistic-restart');
            $.each(restartLinks, function(k,v){
                $(v).on('click', function(){
                    var self = $(this);
                    StatisticUploadsController.getInstance().onClickRestartImport({
                        'filename':self.data('filename')
                        ,'tocode':self.data('tocode')
                    });
                });
            });
        })
        .on('xhr.dt', function ( e, settings, data ) {
        })
        .on( 'page.dt', function(e, settings) {
        })
        .dataTable({
            ajax: {
                url: Routing.generate('cms_statistic_uploads_list')
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "uploadsTable";
                    loader.startLoader(e.loaderKey, table);
                }
            },
            serverSide: true,
            lengthChange: true,
            searching: false,
            pagingType: 'simple_numbers',
            pageLength: 10,
            order: [],
            columns: [
                //{data: 'success', name:'success', sortable: false},
                {data: function(row, type, set, meta) {
                    if (row.success === 'true') {
                        return $( "<div/>", {'class': 'statistic__statusicons'}).append(
                            $( "<i/>", {'class':'fa fa-check-circle statistic__statusiconsitem statistic__statusiconsitem--success'})
                        )[0].outerHTML;
                    } else if (row.success === 'false') {
                        return $( "<div/>", {'class': 'statistic__statusicons'})
                            .append(
                                $( "<a/>", {
                                    'class': 'js-statistic-restart'
                                    ,'data-filename': row.filename
                                    ,'data-tocode':row.tocode
                                    ,'data-toggle': Translator.trans('contentstore.producttable.status.failure', {}, 'cms')
                                })
                                .append(
                                    $( "<i/>", {'class':'fa fa-exclamation-triangle statistic__statusiconsitem statistic__statusiconsitem--failed'})
                                )
                            )[0].outerHTML;
                    } else {
                        return $( "<div/>")[0].outerHTML;
                    }
                }, name:'success', sortable: false},
                {data: 'tocode', name:'tocode', sortable: false},
                {data: 'filename', name:'filename', sortable: false},
                {data: 'start', name:'start', sortable: false},
                {data: 'end', name:'end', sortable: false},
                {data: 'updatedat', name:'updatedat', sortable: false},
                {data: 'filecount' , name: 'filecount', sortable: false},
                {data: 'filecountimported' , name: 'filecountimported', sortable: false},
                {data: 'filecounterror' , name: 'filecounterror', sortable: false},
                {data: 'filecountskipped' , name: 'filecountskipped', sortable: false}
            ],
            language: {
                paginate: {
                    next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
                    previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
                },
                thousands: '.',
                info: Translator.trans('contentstore.producttable.info', {}, 'cms'),
                infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms'),
                emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
            },
            fnDrawCallback: function( oSettings ){
                var settings = this.fnSettings();

                if( settings.fnRecordsTotal() <= settings._iDisplayLength){
                    $('#cmscontentstoreproducttable_paginate').hide();
                }else{
                    $('#cmscontentstoreproducttable_paginate').show();
                }
            }
        });
};
