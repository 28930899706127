(function() {
    var ctx = pwNamespace('PEAKWORK.CMS.IMAGE');
    ctx.Presenter = function(options) {

        this.loader = {};

        this.defaults = {
            presenter: {
                groupList: {
                    class: ctx.GroupList
                    ,options: {}
                },
                imageList: {
                    class: ctx.ImageList
                    ,options: {}
                }
                ,imageDetails: {
                    class: ctx.ImageDetail
                    ,options: {}
                }
            }
            ,id: 'imagepresenter'
            ,jsclass: {
                imagePresenter: 'js-imagepresenter'
                ,imageGroupList: 'js-imagegrouplist'
            }
            ,dataAttr: {
                presenterImageData: 'images'
                ,presenterIsContentGroup: 'isgroup'
                ,presenterContentKey: 'key'
                ,presenterContentGroupId: 'groupid'
                ,presenterContentSupplier: 'supplier'
                ,presenterContentProductCode: 'productcode'
            }
            ,events: {
                datachange: 'presenterdatachange'
            }
            ,parm: {
                previewImageSize: 100
                ,url: 'cms_contentstore_requestcontentimages'
            }
        };

        // Create options by extending defaults with the passed in arguments
        if (arguments[0] && typeof arguments[0] === "object") {
            this.options = $.extend(true, {}, this.defaults, options);
        }

        return init.call(this);

    };

    ctx.Presenter.prototype.constructor = ctx.Presenter;

    function init() {
        // console.log('PEAKWORK.CMS.IMAGE.Presenter Init');
        if (this.initPresenter()) {
            this.getAdditionalOptions();
            this.initPresenterModules();
        }
        return this.getExports();
    }

    ctx.Presenter.prototype.getExports = function () {
        var self = this;
        return {
            load: function() {
                if (!self.$presenter) {
                    return false;
                }
                self.updateImageData.call(self);
            }
            ,destroy: function() {
                if (!self.$presenter) {
                    return false;
                }
                self.destroyPresenter.call(self);
            }
        }
    };

    ctx.Presenter.prototype.destroyPresenter = function () {
        $.unsubscribe('.' + this.presenterNS);
    };

    ctx.Presenter.prototype.initPresenterModules = function() {
        var moduleKeys = Object.keys(this.options.presenter);
        var self = this;

        moduleKeys.forEach(function(element, index, array) {
            // console.log(element);
            // console.log(self.options.presenter[element]);
            if (!self.options.presenter[element].hasOwnProperty('class')
                || !self.options.presenter[element].class) {
                return false;
            }
            if (!self.options.presenter[element].hasOwnProperty('options')
                || typeof self.options.presenter[element].options !== 'object') {
                self.options.presenter[element].options = {};
            }
            self.options.presenter[element].el = new self.options.presenter[element].class(self.presenterNS, self.options.presenter[element].options);
        });
    };

    ctx.Presenter.prototype.initPresenter = function() {
        var $presenter = $('#' + this.options.id);
        if ($presenter.length < 1) {
            return false;
        }
        this.$presenter = $presenter;
        this.presenterNS = this.getPresenterId();
        this.initEventNames();
        this.initLoader();
        return true;
    };

    ctx.Presenter.prototype.initEventNames = function() {
        var self = this;
        Object.keys(this.options.events).forEach(function(element, index, array) {
            self.options.events[element] = self.options.events[element] + '.' + self.presenterNS;
        });
    };

    ctx.Presenter.prototype.getAdditionalOptions = function() {
        // this.imageData = this.$presenter.data(this.options.dataAttr.presenterImageData) || [];
        this.isGroup = this.$presenter.data(this.options.dataAttr.presenterIsContentGroup) || false;
        this.contentKey = this.$presenter.data(this.options.dataAttr.presenterContentKey) || false;
        this.contentGroupId = this.$presenter.data(this.options.dataAttr.presenterContentGroupId) || false;
        this.contentSupplier = this.$presenter.data(this.options.dataAttr.presenterContentSupplier) || false;
        this.contentProductCode = this.$presenter.data(this.options.dataAttr.presenterContentProductCode) || false;
        // Use Content image request, not data
        // this.options.presenter.groupList.options.values = { suppliers: imageData };
        // this.options.presenter.imageList.options.values = { suppliers: imageData };
    };

    ctx.Presenter.prototype.updateImageData = function() {
        var self = this;
        // console.log('updateImageData');
        this.retrieveImages()
            .pipe(self.updateCache.call(self))
            .pipe(self.transformAjaxData.call(self))
            .pipe(self.sendDataUpdateEvent.call(self))
            .done(function() {
                // self.removeLoadingIndicator.call(self);
            })
    };

    ctx.Presenter.prototype.retrieveImages = function() {
        var promise = this.getImagesFromCachePromise();
        if (promise) {
            return promise;
        }
        // this.addLoadingIndicator();
        return this.getAjaxRequest();
    };

    ctx.Presenter.prototype.getImagesFromCachePromise = function() {
        var defer;
        if (!this.hasOwnProperty('imageCache')) {
            this.imageCache = {};
        }
        var cacheKey = this.getCacheKey();
        if (this.imageCache.hasOwnProperty(cacheKey)) {
            defer = $.Deferred().resolve(this.imageCache[cacheKey]);
            return defer.promise();
        } else {
            return false;
        }
    };

    ctx.Presenter.prototype.transformAjaxData = function() {
        var self = this;
        return function (data) {
            return data;
        };
    };

    ctx.Presenter.prototype.sendDataUpdateEvent = function() {
        var self = this;
        return function (data) {
            // console.log('sendDataUpdateEvent:' + self.options.events.datachange);
            // console.log(data);
            $.publish(self.options.events.datachange, data);
            return data;
        };
    };

    ctx.Presenter.prototype.updateCache = function() {
        var self = this;
        return function (data) {
            if (!self.hasOwnProperty('imageCache')) {
                self.imageCache = {};
            }
            var cacheKey = self.getCacheKey.call(self);
            self.imageCache[cacheKey] = data;
            return data;
        };
    };

    ctx.Presenter.prototype.getAjaxRequest = function() {
        var self = this;
        return $.ajax(Routing.generate(self.options.parm.url), {
            data: {
                "isgroup": self.isGroup
                ,"supplier": self.contentSupplier
                ,"productcode": self.contentProductCode
                ,"groupid": self.contentGroupId
                ,previewsize: self.options.parm.previewImageSize
            },
            beforeSend: function(e, xhr, options) {
                e.loaderKey = self.presenterNS;
                self.loader.startLoader(e.loaderKey, self.$presenter);
            }
        });

    };

    ctx.Presenter.prototype.getCacheKey = function() {
        if (this.isGroup) {
            return this.contentGroupId;
        } else {
            return this.contentKey;
        }
    };

    ctx.Presenter.prototype.getPresenterId = function() {
        var id = this.$presenter.attr('id') || false;
        if (!id) {
            id = $.uuid();
            this.setPresenterId(id);
        }
        return id;
    };

    ctx.Presenter.prototype.setPresenterId = function(id) {
        this.$presenter.attr('id', id);
    };

    ctx.Presenter.prototype.addLoadingIndicator = function() {
        this.loaderId = this.loader.add(this.$presenter);
    };

    ctx.Presenter.prototype.removeLoadingIndicator = function() {
        if (this.loaderId) {
            this.loader.remove(this.loaderId);
        } else {
            this.loader.remove(this.$presenter);
        }
    };

    ctx.Presenter.prototype.initLoader = function() {
        this.loader = Loader.getInstance();
        this.loaderId = false;
    };

})();
