var StatisticGiataImportsController = (function() {

    var instance
        ,loader        ,filterInstance;

    var options = {
        filterFormId: 'statistic-giataimports-filter-form'
        ,filterFromDate: 'statistic-giataimports-filter-from'
        ,filterToDate: 'statistic-giataimports-filter-to'
        ,filterSuccessState: 'statistic-giataimports-filter-successful'
    };

    function createInstance() {

        var initFilter = function() {
            var datpickerOptions = {
                format: 'dd.mm.yyyy'
            };

            // var stdFieldsSel = '#' + options.filterSource;

            filterInstance = FilterForm.getInstance();
            filterInstance.init({
                formid: options.filterFormId
                ,submit: onFormSubmitHandler
                // ,addfields: stdFieldsSel
            });
            filterInstance.addDatepickerField(options.filterFormId, options.filterFromDate, datpickerOptions);
            filterInstance.addDatepickerField(options.filterFormId, options.filterToDate, datpickerOptions);
            filterInstance.addThreeWaySwitch(options.filterFormId, options.filterSuccessState);
        };

        var addHandlers = function () {
            // var filterForm = $('#statistic-giataimports-filter-form')
            //     ,datpickerOptions = {
            //     format: 'dd.mm.yyyy'
            // };
            // filterForm.on('submit', onFormSubmitHandler );
            // filterForm.find("#FilterGiataImportsFormReset").on('click', onFormResetHandler );
            // filterForm.find('#statistic-giataimports-filter-from').datepicker(datpickerOptions);
            // filterForm.find('#statistic-giataimports-filter-to').datepicker(datpickerOptions);
        };

        var onFormSubmitHandler = function() {
            var params = filterInstance.getValues(options.filterFormId)
            var tableAction = "cms_statistic_giataimports_list";
            var tableId = "#cmsgiataimportstable";
            loader.startLoader("filter", $('.cmsstatistic--giata--filter'), ["giataImportsTable"]);
            // $('#statistic-giataimports-filter-form input,select').each(function(k, v) {
            //     if (0 >= $(v).val().length ) {
            //         return true;
            //     }
            //     params[$(v).prop('name')] = $(v).val();
            // });

            var table =$(tableId).DataTable();
            table
                .ajax
                .url(Routing.generate(tableAction, params))
                .load(function() {
                });
            return false;
        };

        var onFormResetHandler = function() {
            $("#statistic-giataimports-filter-form")[0].reset();
            $("#FilterGiataImportsFormSubmit").trigger('click');
        };

        return {
            init: function() {
                loader = Loader.getInstance();
                initFilter();
                addHandlers();
                statisticgiataimportstable_ini();
            }
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

$(function() {
    if ($('.js-init-statisticgiata').length > 0) {
        StatisticGiataImportsController.getInstance().init();
    }

});

