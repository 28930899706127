var GeoMatchingResultsController = (function(){

    var instance;
    var filterInstance;
    var datatable;
    var loadingDetailsPage = false;
    var loader;

    var options = {
        filterFormId: 'geomatchingservicestatus-filter-form'
        ,filterSupplierId: 'geomatchingservicestatus-filter-supplier'
        ,filterProductcode: 'geomatchingservicestatus-filter-productcode'
        ,filterValid: 'geomatchingservicestatus-filter-valid'
        ,filterStatus: 'geomatchingservicestatus-filter-status'
        ,filterCreatedAtFrom: 'geomatchingservicestatus-filter-createdfrom'
        ,filterCreatedAtTo: 'geomatchingservicestatus-filter-createdto'
        ,filterMatchedAtFrom: 'geomatchingservicestatus-filter-matchedatfrom'
        ,filterMatchedAtTo: 'geomatchingservicestatus-filter-matchedatto'
        ,filterStates: 'geomatchingservicestatus-filter-states'
        ,tableId: 'geomatchresult__table'
        ,tableAction: 'cms_goalmatching_geomatchingserviceresultlist'
    };

    function createInstance() {

        var addHandlers = function () {
        };

        var initFilter = function() {
            var datpickerOptions = {
                format: 'dd.mm.yyyy'
            };
            var stdFieldsSel = '#' + options.filterProductcode
                + ', #' + options.filterValid + ' input'
                + ', #' + options.filterStatus;

            loader.startLoader("filter", $(".cmscontentstore--filter"), ["goalMatchingStatusTable"]);
            filterInstance = FilterForm.getInstance();
            filterInstance.init({
                formid: options.filterFormId
                ,submit: onSubmit
                ,addfields: stdFieldsSel
            });
            filterInstance.addToCodeSelect2Field(options.filterFormId, options.filterSupplierId);
            // filterInstance.addField(options.filterFormId, options.filterProductcode);
            // filterInstance.addRadioField(options.filterFormId, options.filterValid);
            // filterInstance.addField(options.filterFormId, options.filterStatus);
            filterInstance.addDatepickerField(options.filterFormId, options.filterCreatedAtFrom, datpickerOptions);
            filterInstance.addDatepickerField(options.filterFormId, options.filterCreatedAtTo, datpickerOptions);
            filterInstance.addDatepickerField(options.filterFormId, options.filterMatchedAtFrom, datpickerOptions);
            filterInstance.addDatepickerField(options.filterFormId, options.filterMatchedAtTo, datpickerOptions);
            // filterInstance.addField(options.filterFormId, options.filterCreatedAt).datepicker(datpickerOptions);
            // filterInstance.addField(options.filterFormId, options.filterMatchedAt).datepicker(datpickerOptions);
            filterInstance.addThreeWaySwitch(options.filterFormId, options.filterStates);
        };

        var onSubmit = function(e) {
            e.preventDefault();
            var params = filterInstance.getValues(options.filterFormId);
            loader.startLoader("filter", $(".cmscontentstore--filter"), ["goalMatchingStatusTable"]);

            $('#' + options.tableId).DataTable()
                .ajax
                .url(Routing.generate(options.tableAction, params))
                .load(function() {
                });
            return false;
        };

        function _initDatatable() {

            var dtRoutingParameter = {};
            var startvalues = false;
            var $table = $('#' + options.tableId);

            startvalues = filterInstance.getStartValues(options.filterFormId);
            if (startvalues) {
                dtRoutingParameter = startvalues;
            }

            datatable = $table
                .on('draw.dt', function(e, settings) {
                })
                .on('xhr.dt', function ( e, settings, data ) {
                })
                .on('preXhr.dt', function ( e, settings, data ) {
                })
                .dataTable({
                    dom:
                        "<'row'<'col-sm-6'l><'col-sm-6'f>>" +
                        "<'row'<'col-sm-12'tr>>" +
                        "<'row'<'col-sm-4'i><'col-sm-8'p>>"
                    ,ajax: {
                        url: Routing.generate(options.tableAction, dtRoutingParameter)
                        ,beforeSend: function(e, xhr, options) {
                            e.loaderKey = "goalMatchingStatusTable";
                            loader.startLoader(e.loaderKey, $table);
                        }
                    },
                    serverSide: true,
                    lengthChange: true,
                    searching: false,
                    pagingType: 'simple_numbers',
                    pageLength: 10,
                    "order": [],
                    columns: [
                        {data: 'supplier', name:'supplier', sortable: false},
                        {data: 'productcode', name:'productcode', sortable: false},
                        {data: 'status' , name: 'status', sortable: false},
                        {data: getBooleanIcon('states-valid'), name: 'states-valid', sortable: false},
                        {data: getBooleanIcon('states-matched'), name:'states-matched', sortable: false},
                        {data: 'matchedat', name:'matchedat', sortable: false},
                        {data: 'matchedgoalid', name:'matchedgoalid', sortable: false},
                        {data: getCandidatesTableElement('candidates'), name:'candidates', sortable: false},
                        {data: 'created', name:'matchedat', sortable: false}
                    ],
                    language: {
                        paginate: {
                            next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
                            previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
                        },
                        thousands: '.',
                        info: Translator.trans('contentstore.producttable.info', {}, 'cms'),
                        infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms'),
                        emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
                    },
                    fnDrawCallback: function( oSettings ){
                        // var settings = this.fnSettings();

                        if( oSettings.fnRecordsTotal() === 1) {
                            loadingDetailsPage = true;
                        }

                        if( oSettings.fnRecordsTotal() <= oSettings._iDisplayLength){
                            $('.pagination', $table).hide();
                        } else {
                            $('.pagination', $table).show();
                        }
                    }
                });
        }

        function getBooleanIcon(item) {
            var theFn = function(row, type, set, meta) {
                if (!row.hasOwnProperty(item)) {
                    return;
                }
                var iconClass = (row[item] === 'true')
                    ? 'fa fa-check-circle cms__fa--ok geomatchresulttable__resulticon'
                    : 'fa fa-circle-o cms__fa--fail geomatchresulttable__resulticon';
                return  $( "<i/>", {
                    'class': iconClass,
                })[0].outerHTML;
            };
            return theFn;
        }

        function getCandidatesTableElement(item) {
            var theFn = function(row, type, set, meta) {
                if (!row.hasOwnProperty(item)) {
                    return;
                }
                var count = row[item].length;
                return  $( "<span/>", {
                    'class': 'geomatchresulttable__resultcandidates',
                    'text': count,
                    'data-content': row[item]
                })[0].outerHTML;
            };
            return theFn;
        }

        var init = function() {
            loader = Loader.getInstance();
            initFilter();
            _initDatatable();
            addHandlers();
            // goalmatchingtable_ini();
        };

        return {
            init: init
        }
    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

$(function() {
    if($('.js-init-geomatchresult').length > 0) {
        GeoMatchingResultsController.getInstance().init();
    }
});
