var ConfigPriorityContentExportImagesController = (function(){

    var instance
        ,loader
        ,wrapper
        ,list;

    function createInstance() {
        var addHandlers = function () {
            wrapper = $('.js-cms-config-prioritycontentexport-images');
            list = wrapper.find('.checkboxlist');
            initNestable();
        };

        var reset = function() {
            list.remove();
            wrapper.remove();
        };

        var initNestable = function() {
            if (!list.data('readonly')) {
                list.nestable({group:1, maxDepth:1});
            }
            list.find('.dd-item__checkbox--draggable').on('mousedown', function(e) {
                e.stopPropagation();
            });
        };

        var getPrioritySupplierList = function() {
            var form = wrapper.find('.js-cms-config-prioritycontentexport-images')
                ,checkbox = form.find('input')
                ,prioritySupplierList = [];

            $.each($(checkbox).serializeArray(), function (idx, kV) {
                if (kV.value !== '') {
                    prioritySupplierList.push(kV.value);
                }
            });
            return prioritySupplierList;
        };

        var init = function() {
            loader = Loader.getInstance();
            addHandlers();
        };

        return {
            init: init,
            reset: reset,
            getPrioritySupplierList: getPrioritySupplierList
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();