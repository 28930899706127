$(function () {
    var context = pwNamespace('CMS.GLOBALTYPECATEGORIES');
    context.CategoriesUpload = (function () {
        var instance = null;

        var injection = function() {
            return {}
        };

        var initInstance = function() {
            var me = {
                categoriesContextObj: context.CategoriesList.getInstance()
                ,config: {
                    inputFieldId: '.fileinput-button #globaltypecategoriesuploadxmlfileinput'
                    ,uploadButton: $('.js-uploadcsvtranslations')
                    ,progressBar: $('#progress .progress-bar')
                    ,errorField: $('.globaltypecategoriesdownload-error')
                    ,successField: $('.globaltypecategoriesdownload-success')
                    ,closeButton: $('.js-globaltypecategoriesuploadclosebutton')
                    ,modalContent: $('#cmsglobaltypecategoriesuploadmodal')
                    ,modalErrorContainerCss: 'globaltypecategoriesdownload-error'
                    ,modalErrorContentCss: 'globaltypecategoriesdownload-errorcontent'
                    ,modalSuccessContainerCss: 'globaltypecategoriesdownload-success'
                    ,modalSuccessContentCss: 'globaltypecategoriesdownload-successcontent'
                    ,tableId: '#GlobaltypeTranslationsTable'
                    ,resetButton: $('.js-globaltypecategoriesuploadresetbutton')
                    ,progressBarsContainer: $(".js-globaltypecategoriesuploadprogressbars")
                    ,uploadSuccess: false
                }
                ,init: function() {
                    me.hideErrorField();
                    me.hideSuccessField();
                    me.initFileUploadField();
                    me.addEventListeners();
                }

                ,addEventListeners: function()
                {
                    me.config.closeButton.on('click', me.closeButtonUpload);
                    me.config.resetButton.on('click', me.resetUploadForm);
                    me.config.modalContent.on('hidden.bs.modal', function () {
                        me.closeButtonUpload();
                    });
                    $(me.config.inputFieldId).on('change', function(e){
                        me.config.progressBarsContainer.show();
                        setTimeout(function() {
                            var input = $(me.config.inputFieldId)
                                ,button = input.parents('.fileinput-button');
                            input.attr('disabled', true);
                            button.addClass('fileinput-button__deactivated');
                        }, 1);
                    });
                }

                ,resetUploadForm: function(e) {
                    me.closeButtonUpload(e);
                    $(e.currentTarget).hide();
                }

                ,closeButtonUpload: function(e) {
                    var input = $(me.config.inputFieldId)
                        ,button = input.parents('.fileinput-button');
                    input.attr('disabled', false);
                    button.removeClass('fileinput-button__deactivated');
                    me.setProgressStatus(me.config.progressBar, 0);
                    me.config.progressBar.css('background-color', '#337ab7');
                    me.setErrorMessage('');
                    me.hideErrorField();
                    me.hideSuccessField();
                    me.config.progressBarsContainer.hide();
                    $(me.config.resetButton).hide();
                }

                ,getErrorContentArea: function() {
                    var errorContentArea = me.config.modalContent.find('.' + me.config.modalErrorContainerCss).find('.' + me.config.modalErrorContentCss);
                    return errorContentArea;
                }

                ,setErrorMessage: function(message) {
                    me.getErrorContentArea().html(message);
                }

                ,hideErrorField: function() {
                    me.config.errorField.hide();
                    me.setErrorMessage(null);
                }

                ,getSuccessContentArea: function() {
                    var successContentArea = me.config.modalContent.find('.' + me.config.modalSuccessContainerCss).find('.' + me.config.modalSuccessContentCss);
                    return successContentArea;
                }

                ,setSuccessMessage: function(message) {
                    me.getSuccessContentArea().html(message);
                }

                ,hideSuccessField: function() {
                    me.config.successField.hide();
                    me.setSuccessMessage(null);
                }

                ,setProgressStatus: function(progressBar, percent) {
                    progressBar.css(
                        'width',
                        percent + '%'
                    );
                }

                ,showMessages: function(title, text, type) {
                    swal({
                        title: Translator.trans(title, {}, 'cms')
                        ,type: type
                        ,text: Translator.trans(text, {}, 'cms')
                        ,html: true
                        ,customClass: 'fe-message__swal'
                    });
                }

                ,initFileUploadField: function() {
                    me.config.uploadSuccess = false;
                    $(me.config.inputFieldId).fileupload({
                        dataType: 'json',
                        progress: function(e, data) {
                            e.preventDefault();
                            me.setProgressStatus(me.config.progressBar, 100);
                        },
                        done: function(e, data) {
                            if (data && data.result && data.result.success) {
                                me.setSuccessMessage('XML file successfully imported.');
                                me.config.uploadSuccess = true;
                            }
                            var deferred = $.Deferred();
                            var promise = deferred.promise();
                            var maxTry = 5;
                            var interval = setInterval(function() {
                                var attrStyle = me.config.progressBar.attr('style');
                                if ( attrStyle.indexOf('width: 100%;') != -1 ) {
                                    deferred.resolve(me.config.uploadSuccess);
                                } else {
                                    maxTry = maxTry - 1;
                                }
                                if (maxTry < 1) {
                                    deferred.resolve(me.config.uploadSuccess);
                                }
                            }, 1000);

                            promise.done(function(param) {
                                clearInterval(interval);
                                if (param) {
                                    me.categoriesContextObj.updateCategoriesList();
                                    me.config.successField.show();
                                } else {
                                    me.setErrorMessage('There was a problem to import the file.');
                                    me.config.errorField.show();
                                }
                                $(me.config.resetButton).show();
                            });
                        },
                        fail: function(e, data) {
                            var resp = data.response().jqXHR.responseJSON
                                ,msgError;

                            if (resp.msgError) {
                                msgError = '<p>' + resp.msgError + '</p>';
                            }
                            me.setErrorMessage(msgError);
                            me.config.errorField.show();
                        }
                    });
                }
            };

            /* public */
            var baseObj = {

            };

            /* attach public functions to singleton */
            $.each(injection.call(me, me), function(idx, prop) {
                baseObj[idx] = prop;
            });

            /* execute init method */
            me.init();

            return baseObj;
        };

        return {
            inject: function(inject) {
                injection = inject;
            }
            ,getInstance: function() {
                if (instance === null) {
                    instance = initInstance();
                }
                return instance;
            }
        }
    })();
});

$(function() {
    if ($('.js-uploadxmlglobaltypecategories').length > 0) {
        CMS.GLOBALTYPECATEGORIES.CategoriesUpload.getInstance();
    }
});
