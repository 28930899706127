/**
 *
 * Map Infowindow
 *
 */
(function() {
    var ctx = pwNamespace('PEAKWORK.MAP')
        ,mapWrapper = null
        ,_ = null;
    // console.log('Map geo search');
    ctx.MapInfowindow = function (mapWrapperObj,options) {

        mapWrapper = mapWrapperObj;
        _ = this;
        var defaultoptions = {
            infowindowIsReducedOnStart: false
        };

        if (options && typeof options === "object") {
            this.options = $.extend({}, defaultoptions, options);
        }

        _.init();

        return {
            setInfoWindow: _.setInfoWindow
            ,clearInfoWindow: _.clearInfoWindow
            ,getInfoWindowMarker: _.getInfoWindowMarker
            ,getInfoWindowMarkerFromElement: _.getInfoWindowMarkerFromElement
            ,reduceMapInfoWindow: _.reduceInfowindow
        };

    };
    ctx.MapInfowindow.prototype = {
        constructor: ctx.MapInfowindow
        ,init: function () {
            _.mapId = mapWrapper.getMapId();
            _.addHandler();
            _.clearInfoWindow();

        }

        ,addHandler: function() {
            _.$mapContainer = $(mapWrapper.options.mapPanel);
            _.$flexmapContainer = $('.flexmap', _.$mapContainer);
            _.$infowindow = $('.js-flexmap__info',_.$mapContainer);
            if (_.$infowindow.length > 0) {
                if (_.options.infowindowIsReducedOnStart) {
                    _.$infowindow.addClass('flexmap__info--reduced');
                    _.stateInfowindowIsFullHeight = false;
                } else {
                    _.$infowindow.removeClass('flexmap__info--reduced');
                    _.stateInfowindowIsFullHeight = true;
                }
                _.subscribeToEvents();
                _.uiInit();
            }
        }

        ,subscribeToEvents: function() {
            $.subscribe('closemarker.map.' + _.mapId,_.closeMarkerEvent());
        }

        ,reduceInfowindow: function() {
            if (_.stateInfowindowIsFullHeight) {
                _.uiInfowindowReduce();
                _.stateInfowindowIsFullHeight = false;
                return;
            }
            _.stateInfowindowIsFullHeight = true;
            _.uiInfowindowFull();
        }

        ,closeMarkerEvent: function() {
            return function(e,markerId) {
                //console.log('MapInfowindow: Event cms.map.closemarker');
                if (_.hasOwnProperty('infowindowCols')) {
                    $.each(_.infowindowCols, function(idx) {
                        if (this.hasOwnProperty('openMarkerId')
                            && this.openMarkerId == markerId) {
                            _.clearInfoWindow(idx);
                        }
                    });
                }
            }
        }

        ,getInfowindowCount: function() {
            return _.uiGetInfoWindows().length;
        }
        ,closeInfowindow: function(windowNumber) {
            var theInfowindowCol = _.getInfoWindow(windowNumber);
            if (!theInfowindowCol) {
                return false;
            }
            if (theInfowindowCol.hasOwnProperty('marker')
                && theInfowindowCol.marker !== null) {
                mapWrapper.closeInfoWindow(theInfowindowCol.marker);
            }
        }

        ,clearInfoWindow: function(windowNumber) {
            windowNumber = windowNumber || false;
            if (windowNumber) {
                _.setInfoWindowMarker(windowNumber,null);
                _.uiUpdateInfoWindow(windowNumber, '');
            }
            if (!windowNumber) {
                for (windowNumber = 0; windowNumber < _.getInfowindowCount(); windowNumber++) {
                    _.setInfoWindowMarker(windowNumber,null);
                    _.uiUpdateInfoWindow(windowNumber, '');
                }
            }
            _.uiAutoShowHideInfoWindow();
        }
        ,setInfoWindow: function(marker,content) {
            marker = marker || false;
            content = content || false;
            if (!content || !marker) {
                return false;
            }
            if (!marker.options.hasOwnProperty('pwInfowindowCol')) {
                return false;
            }
            _.setInfoWindowMarker(marker.options.pwInfowindowCol,marker);
            _.uiUpdateInfoWindow(marker.options.pwInfowindowCol,content);
            _.uiAutoShowHideInfoWindow();
        }
        
        ,setInfoWindowMarker: function(windowNumber,marker) {
            var theInfowindowCol = _.getInfoWindow(windowNumber);
            if (!theInfowindowCol) {
                return false;
            }
            if (marker !== null && marker.hasOwnProperty('id')) {
                marker.pwMarkerIsOpen = true;
                theInfowindowCol.marker = marker;
                theInfowindowCol.openMarkerId = marker.id;
            } else {
                if (theInfowindowCol.hasOwnProperty('marker') &&  theInfowindowCol.marker !== null && theInfowindowCol.marker.hasOwnProperty('pwMarkerIsOpen')) {
                    theInfowindowCol.marker.pwMarkerIsOpen = false;
                }
                theInfowindowCol.marker = null;
                theInfowindowCol.openMarkerId = null;
            }
        }

        ,getInfoWindow: function(windowNumber) {
            if (!_.hasOwnProperty('infowindowCols')) {
                _.infowindowCols = {};
            }
            if(!_.infowindowCols.hasOwnProperty(windowNumber)) {
                _.infowindowCols[windowNumber] = {};
                var $container =  $('.js-flexmap__infocol--' + windowNumber,_.$flexmapContainer);
                if ($container.length > 0) {
                    _.infowindowCols[windowNumber].container = $container;
                    _.infowindowCols[windowNumber].openMarkerId = null;
                    return _.infowindowCols[windowNumber];
                }
            }
            if (_.infowindowCols[windowNumber].hasOwnProperty('container')) {
                return _.infowindowCols[windowNumber];
            }
            return null;
        }

        ,getInfoWindowMarker: function(windowNumber) {
            theInfowindow = _.getInfoWindow(windowNumber);
            if (!theInfowindow) {
                return false;
            }
            if (theInfowindow.hasOwnProperty('marker')
                && theInfowindow.marker !== null) {
                return theInfowindow.marker;
            }
            return null;
        }

        ,getInfoWindowMarkerFromElement: function($target) {
            var $infowindowCol = $target.closest('.js-flexmap__infocol');
            if ($infowindowCol.length < 1) {
                return null;
            }

            var infowindowColNumber = $infowindowCol.data('infocolnumber');

            return _.getInfoWindowMarker(infowindowColNumber);
        }

        /*
         *
         * UI Functions
         *
         *
         *
         *
         */

        ,uiInit: function() {
            if (_.$infowindow.length > 0) {
                _.$infowindow.on('click', '.js-flexmap__infotoggle', _.reduceInfowindow);
                _.$infowindow.on('click', '.js-flexmap__infowindow-close', _.uiInfowindowCloseClick)
            }
        }
        ,uiGetInfoWindows: function() {
            return $('.js-flexmap__infocol');
        }
        ,uiAutoShowHideInfoWindow: function() {
            if (!_.hasOwnProperty('infowindowCols')) {
                return;
            }
            var hasInfowindowContent = Object.keys(_.infowindowCols).reduce(function(prev,curr){
                return prev || (_.infowindowCols[curr].hasOwnProperty('marker') && _.infowindowCols[curr].marker !== null);
            },false);
            if (hasInfowindowContent) {
                _.$infowindow.removeClass('flexmap__info--hide');
            } else {
                _.$infowindow.addClass('flexmap__info--hide');
            }
        }
        ,uiInfowindowCloseClick: function(e) {
            var $target = $(e.currentTarget);
            var $infowindowCol = $target.closest('.js-flexmap__infocol');
            if ($infowindowCol.length > 0) {
                var infowindowColNumber = $infowindowCol.data('infocolnumber');
                _.closeInfowindow(infowindowColNumber);
            }
        }
        ,uiInfowindowReduce: function() {
            _.$infowindow.addClass('flexmap__info--reduced');
        }
        ,uiInfowindowFull: function() {
            _.$infowindow.removeClass('flexmap__info--reduced');
        }
        ,uiUpdateInfoWindow: function(windowNumber, content) {
            var theInfowindowCol = _.getInfoWindow(windowNumber);
            if (!theInfowindowCol) {
                return false;
            }
            theInfowindowCol.container
                .addClass('flexmap__infocol--hide');
            setTimeout(function(){
                theInfowindowCol.container
                    .html(content)
                    .removeClass('flexmap__infocol--hide');
            }, 200);
            ;
        }
    }
})();