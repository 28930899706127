var ConfigImageCategoriesController = (function(){

    var instance
        ,loader;

    function createInstance() {

        var addHandlers = function () {
            var wrapper = $('.js-cms-config-image-categories')
                ,list = wrapper.find('.js-config-sortlist');
            wrapper.on('submit', '.js-cms-config-form-image-categories', onSubmit);
            wrapper.on('click', '.btn-reset-image-categories', onReset);
            initNestable(list);

        };

        var onReset = function(e) {
            e.preventDefault();
            var btn = $(this)
                ,loaderContainer = btn.parents('.js-cms-config-image-categories');
            $.ajax({
                url: Routing.generate('cms_config_resetimagecategories')
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "resetPrefferedImages";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
            })
            .done(function(data) {
                if (data.view) {
                    btn.parents('.js-config-formwrapper').html(data.view);
                    initNestable(loaderContainer.find('.js-config-sortlist'));
                }
            });
        };

        var initNestable = function(list) {
            if (!list.data('readonly')) {
                list.nestable({group: 1, maxDepth: 1});
            }
        };

        var onSubmit = function(e) {
            e.preventDefault();
            var form = $(this)
                ,loaderContainer = form.parents('.js-cms-config-image-categories')
                ,inputFields = form.find('li')
                ,imageCategories = []
                ,url = Routing.generate('cms_config_saveimagecategories');

            $.each($(inputFields), function (idx, kV) {
                var value = kV.getAttribute('data-id');
                if (value !== '') {
                    imageCategories.push(value);
                }
            });

            $.ajax(url, {
                data: {
                    imageCategories: imageCategories
                },
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "saveConfigImageCategories";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
                ,method: 'POST'
            })
            .done(function(data){
                if (data.success === false) {
                    toastr.error(
                        Translator.trans('def.term.error', {}, 'cms'),
                        Translator.trans('config.section.imagecategories.save.errormessage', {}, 'cms')
                    );
                }
                if (data.success === true) {
                    loaderContainer.find('.btn-reset-image-categories').click();
                    toastr.success(
                        Translator.trans('def.term.success', {}, 'cms'),
                        Translator.trans('config.section.imagecategories.save.successmessage', {}, 'cms')
                    );
                }
            });
        };

        var init = function() {
            loader = Loader.getInstance();
            addHandlers();
        };

        return {
            init: init
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();