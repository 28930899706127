var StatisticUnavailableImagesController = (function() {

    var instance
        ,loader
        ,filterInstance;

    var options = {
        filterFormId: 'statistic-unavailableimages-filter-form'
        ,filterGoalId: 'statistic-unavailableimages-filter-goalid'
    };

    function createInstance() {

        var initFilter = function() {
            var stdFieldsSel = '#' + options.filterGoalId;

            filterInstance = FilterForm.getInstance();
            filterInstance.init({
                formid: options.filterFormId
                ,submit: onFormSubmitHandler
                ,addfields: stdFieldsSel
            });
        };


        var addHandlers = function () {
            $('#unavailableimagesactionswrapper .js-downloadunavailableimagescsv').on('click', onClickDownloadButton);
        };

        var onClickDownloadButton = function() {
            window.location.href = Routing.generate('cms_statistic_unavailableimages_downloadcsv');
        };

        var onFormSubmitHandler = function() {
            var params = filterInstance.getValues(options.filterFormId);
            var tableAction = "cms_statistic_unavailableimages_list";
            var tableId = "#cmsunavailableimagestable";

            var table =$(tableId).DataTable();
            table
                .ajax
                .url(Routing.generate(tableAction, params))
                .load(function() {
                });
            return false;
        };

        return {
            init: function() {
                loader = Loader.getInstance();
                initFilter();
                addHandlers();
                statisticunavailableimagestable_ini();
            }
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

$(function() {
    if ($('.js-init-statisticunavailableimages').length > 0) {
        StatisticUnavailableImagesController.getInstance().init();
    }
});

