var SearchObjectHandlerController = (function() {

    var instance;

    function createInstance() {

        var controller = {

        };


        return {
            init: function() {
            },
            addController: function(newController, type) {
                controller[type] = newController;
            },
            getController: function(type) {
                return controller[type];
            },
            hasController: function(type) {
                return (controller[type])?true:false;
            }
        };
    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

$(function() {
    SearchObjectHandlerController.getInstance().init();
});