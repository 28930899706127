var ConfigExportCronjobsController = (function() {

    var instance
        ,wrapperContainer
        ,addTargetButton
        ,addTargetBox
        ,newTarget = 0
        ,loader;

    function createInstance() {

        var exportRequestConfig = {
            'GOOGLE': {'needRequestBox':false, 'descriptionkey':'config.section.exportcronjobs.exporttype.descriptions.google'}
            ,'HUB_AIRPORT': {'needRequestBox':false, 'descriptionkey':'config.section.exportcronjobs.exporttype.descriptions.hubairport'}
            ,'GOAL_GIATA_MAPPING': {'needRequestBox':false, 'descriptionkey':'config.section.exportcronjobs.exporttype.descriptions.goalgiatamapping'}
            ,'PORTFOLIO_FULL_HOTEL': {'needRequestBox':false, 'descriptionkey':'config.section.exportcronjobs.exporttype.descriptions.portfoliofullhotel'}
            ,'PORTFOLIO_FULL_GT': {'needRequestBox':false, 'descriptionkey':'config.section.exportcronjobs.exporttype.descriptions.portfoliofullgt'}
            ,'PRIORITY_CONTENT_EXPORT': {'needRequestBox':false, 'descriptionkey':'config.section.exportcronjobs.exporttype.descriptions.prioritycontentexport'}
            ,'MATCHING': {'needRequestBox':true, 'tocodes':true,'includeextern':true, 'descriptionkey':'config.section.exportcronjobs.exporttype.descriptions.mnatching'}
            ,'MATCHINGCSV': {'needRequestBox':true, 'tocodes':true,'includeextern':true, 'descriptionkey':'config.section.exportcronjobs.exporttype.descriptions.matchingcsv'}
            ,'PLAYER': {'needRequestBox':true, 'includeextern':true, 'descriptionkey':'config.section.exportcronjobs.exporttype.descriptions.player'}
            ,'LANGUAGES': {'needRequestBox':true, 'includelang':true, 'excludelang':true, 'descriptionkey':'config.section.exportcronjobs.exporttype.descriptions.languages'}
            ,'GLOBALTYPES': {'needRequestBox':true, 'globaltypes':true, 'set':true, 'referenceid':true, 'addgoalglobaltypes':true, 'descriptionkey':'config.section.exportcronjobs.exporttype.descriptions.globaltypes'}
            ,'HUB': {'needRequestBox':true, 'includedeprecated':true, 'descriptionkey':'config.section.exportcronjobs.exporttype.descriptions.hub'}
            ,'HUB_GIATA_GEO': {'needRequestBox':true, 'countrycode':true, 'descriptionkey':'config.section.exportcronjobs.exporttype.descriptions.hubgiatageo'}
            ,'HUB_GOAL_GEO': {'needRequestBox':true, 'countrycode':true, 'descriptionkey':'config.section.exportcronjobs.exporttype.descriptions.hubgoalgeo'}
            ,'GOAL': {'needRequestBox':true, 'countrycode':true, 'productcodematching':true, 'productcodematchingcount':true, 'descriptionkey':'config.section.exportcronjobs.exporttype.descriptions.goal'}
            ,'FULL_HUB': {'needRequestBox':true, 'multicountrycode':true, 'descriptionkey':'config.section.exportcronjobs.exporttype.descriptions.fullhub'}
        };

        var addHandlers = function () {
            wrapperContainer = $('.cms-config-settings-exportcronjobs');
            addTargetBox = wrapperContainer.find('.addtargettypebox');
            addTargetButton = wrapperContainer.find('#AddTarget');
            addTargetButton.on('click', onAddTarget);
            wrapperContainer.find('#AddTargetDirectory').on('click', onOpenTargetTab);
            wrapperContainer.find('.btn-saveexportcronjob').on('click', onAddCronjobToList);
            wrapperContainer.on('click', '.edit-target-btn', onEditTarget);
            wrapperContainer.find('#ExportCronjobTargetTab').on('click', resetTarget);
            wrapperContainer.on('click', '#ResetAddExportCronjob', reset);
            wrapperContainer.find('#AddTargetButton').on('click', onAddTargetToList);
            wrapperContainer.find('#TargetTypeSelect').on('change', onShowTarget);
            wrapperContainer.on('change', '.form-control, .js-select2-value, .js-ajax-select2-value, input[type=checkbox], input[type=radio]', onCheckFields);
            wrapperContainer.on('keyup', '.form-control, .js-select2-value, .js-ajax-select2-value, input[type=checkbox], input[type=radio]', onCheckFields);
            wrapperContainer.on('click', '.delete-target-btn', onDeleteTarget);
            wrapperContainer.on('click', '.edit-exportcronjob-btn', onEditCronjob);
            wrapperContainer.find('#ExportTypeSelect').on('change', onShowExportRequestBox);
            wrapperContainer.on('click', '.delete-export-cronjob-btn', onDeleteCronjob);
            wrapperContainer.find('#CancelTargetButton').on('click', onAddTarget);

            cronJobHandler();
        };

        var cronJobHandler = function() {
            var outerContainer = $('.cms-config-settings-exportcronjobs')
                ,wrapper = outerContainer.find('.exportcronform')
                ,specBoxMinutesContainer = wrapper.find('.js-exportcronjobs-specboxinputgroup_minutes')
                ,specBoxHoursContainer = wrapper.find('.js-exportcronjobs-specboxinputgroup_hours')
                ,specBoxDayContainer = wrapper.find('.js-exportcronjobs-specboxinputgroup_day');
            wrapper.on('change', '#exportcronjobs_showadvanced', function() {
                var checkbox = $(this);
                if (checkbox.is(':checked')) {
                    wrapper.find('.js-exportcronjobs_advancedform').show();
                    wrapper.find('.js-exportcronjobs_defaultform').hide();
                } else {
                    wrapper.find('.js-exportcronjobs_advancedform').hide();
                    wrapper.find('.js-exportcronjobs_defaultform').show();
                }
            });
            specBoxMinutesContainer.datetimepicker({
                format: 'm'
            });
            specBoxMinutesContainer.on('dp.change', onCheckFields);
            specBoxHoursContainer.datetimepicker({
                format: 'H'
            });
            specBoxHoursContainer.on('dp.change', onCheckFields);
            specBoxDayContainer.datetimepicker({
                format: 'D'
            });
            specBoxDayContainer.on('dp.change', onCheckFields);
            wrapper.find('input[name="exportcronjobs"]').on('click', function(e) {
                var radio = $(this)
                    ,specBoxMonth = wrapper.find('.exportcronjobs-specboxmonth')
                    ,specBoxDay = wrapper.find('.exportcronjobs-specboxday')
                    ,specBoxHour = wrapper.find('.exportcronjobs-specboxhour');

                if (radio.attr('id') == 'exportcronjobs_month') {
                    specBoxMonth.show();
                    specBoxDay.hide();
                    specBoxHour.hide();
                } else if (radio.attr('id') == 'exportcronjobs_day') {
                    specBoxDay.show();
                    specBoxMonth.hide();
                    specBoxHour.hide();
                } else if (radio.attr('id') == 'exportcronjobs_hour') {
                    specBoxHour.show();
                    specBoxMonth.hide();
                    specBoxDay.hide();
                } else {
                    specBoxMonth.hide();
                    specBoxHour.hide();
                    specBoxDay.hide();
                }
            });
        };


        var onDeleteCronjob = function() {
            var btn = $(this);
            swal({
                title: "Are you sure?"
                ,text: "Do you really want to delete the cronjob!"
                ,type: "warning"
                ,showCancelButton: true
                ,confirmButtonText: "Yes, delete it!"
                ,cancelButtonText: "Cancel"
                ,closeOnConfirm: true
                ,closeOnCancel: true
            }, function(isConfirm){
                if (isConfirm) {
                    reset();
                    btn.parent().parent().remove();
                    $('.cms-config-settings-exportcronjobs-btn').click();
                    saveData(aggregateData());
                }
            });
        };

        var onShowExportRequestBox = function(e) {
            initExportRequestBox(exportRequestConfig[$(this).val()]);
            initExportTypeDescription(exportRequestConfig[$(this).val()]);
        };

        var initExportTypeDescription = function(exportType) {
            var container = wrapperContainer.find('.js-exportcronjobs-exporttypedescription');
            if (exportType && exportType.descriptionkey) {
                var translation = Translator.trans(exportType.descriptionkey, {}, 'cms');
                container.text(translation);
            } else {
                container.text(null);
            }
        };

        var initExportRequestBox = function(selection) {
            var parent = wrapperContainer.find('.js-exportrequest');
            resetExportRequests();
            if (!selection || !selection.needRequestBox) {
                return;
            }
            if (selection.tocodes) {
                parent.find('.js-exportrequest-tocodes').show();
                initTocodesSelect2();
            }
            if (selection.globaltypes) {
                parent.find('.js-exportrequest-globaltypes').show();
                initGlobalTypesSelect2();
            }
            if (selection.includelang) {
                parent.find('.js-exportrequest-inclang').show();
                parent.find('#ExportRequestIncLangSelect').select2();
            }
            if (selection.excludelang) {
                parent.find('.js-exportrequest-exclang').show();
                parent.find('#ExportRequestExcLangSelect').select2();
            }
            if (selection.set) {
                parent.find('.js-exportrequest-set').show();
            }
            if (selection.referenceid) {
                parent.find('.js-exportrequest-referenceid').show();
            }
            if (selection.addgoalglobaltypes) {
                parent.find('.js-exportrequest-addgoalglobaltypes').show();
            }
            if (selection.includeextern) {
                parent.find('.js-exportrequest-includeextern').show();
            }
            if (selection.includedeprecated) {
                parent.find('.js-exportrequest-includedeprecated').show();
            }
            if (selection.language) {
                parent.find('.js-exportrequest-language').show();
                parent.find('#ExportRequestLanguageSelect').select2();
            }
            if (selection.countrycode) {
                var input = parent.find('.js-exportrequest-countrycode');
                input.show();
            }
            if (selection.multicountrycode) {
                var input = parent.find('.js-exportrequest-multicountrycode');
                input.show();
            }
            if (selection.productcodematching) {
                parent.find('.js-exportrequest-productcodematching').show();
                parent.find('#ExportRequestProductCodeMatchingSelect').select2();
            }
            if (selection.productcodematchingcount) {
                parent.find('.js-exportrequest-productcodematchingcount').show();
            }
            parent.show();
        };

        var initGlobalTypesSelect2 = function() {
           wrapperContainer.find('#ExportRequestGlobalTypesSelect').select2({
                minimumInputLength: 2,
                placeholder: ' ',
                ajax: {
                    url: Routing.generate('cms_featurematching_grouptypelist'),
                    dataType: 'json',
                    data: function (term, page) {
                        return {
                            'type': 'globalType',
                            'query': term,
                            'count': 20,
                            'page': page
                        };
                    },
                    results: function (data, page) {
                        var more = (page * 20) < data.total_count;
                        return {
                            results: data.items,
                            more: more
                        };
                    }
                },
                escapeMarkup: function (m) { return m; },
                initSelection: function(element, callback) {
                    callback({
                        'id': $(element).data('org'),
                        'label': $(element).data('text')
                    });
                },
                formatSelection: function(element) {
                    var item = '',
                        caption = '';

                    if (typeof element.id  !== 'undefined') {
                        caption = $( "<div/>", {
                            'class': 'select2_option_item_text'
                        }).append(element.id)[0].outerHTML;
                        item = caption;
                    }
                    return item;
                },
                formatResult: function(result) {

                    var color = 'inherit';
                    if (result.alternative==="true") {
                        color = '#005DCC';
                    }

                    var item = $(
                        '<span style="font-weight:bold;color:'+ color +';">' + result.id + '</span>'  +
                        '<div>' + result.label + '</div>'
                    );

                    return item;
                }
            });
        };

        var initTocodesSelect2 = function() {
            var input = wrapperContainer.find('#ExportRequestToCodesSelect');
            if (input.length > 0) {
                var tocdes = [];

                $.each(input.data('tocodes'), function(k,v){
                    tocdes.push({
                        id: k,
                        text: k
                    });
                });
                input.select2({
                    data: tocdes,
                    multiple: true,
                    createSearchChoice: function(term) {
                        return {
                            id: term,
                            text: term + ' (new)'
                        };
                    }
                });
            }
        };

        var onEditCronjob = function(e) {
            var btn = $(this)
                ,li = btn.parent().parent()
                ,json = ($.type(li.data('exportdetail')) == 'string')
                    ?$.parseJSON(li.data('exportdetail'))
                    :li.data('exportdetail');
            if (btn.hasClass('btn-default')) {
                reset();
                btn.removeClass('btn-default').addClass('btn-primary');
                fillBasicFormByJson(json);
                fillExportRequestFromByJson(json.exportrequest);
                fillTargetList(json.targets);
                wrapperContainer.find('.btn-saveexportcronjob').data('id', json.id);
                onCheckFields();
            } else {
                reset();
                btn.removeClass('btn-primary').addClass('btn-default');
                wrapperContainer.find('.btn-saveexportcronjob').data('id', '');
            }

        };

        var fillBasicFormByJson = function(json) {
            var selectType = wrapperContainer.find('#ExportTypeSelect')
                ,selectDeleteAfterSuccess = wrapperContainer.find('#DeleteAfterSuccess')
                ,basicContainer = wrapperContainer.find('#ExportCronjobBasic');
            selectType.val(json.type).change();
            selectDeleteAfterSuccess.val(json.deleteaftersuccess).change();
            basicContainer.find('input[name=name]').val(json.name);
            if (json.advancedcron === "true") {
                var cronstring = json.cron.minute+' '+json.cron.hour
                +' '+json.cron.dayofmonth+' '+json.cron.month + ' ' + json.cron.weekday;
                basicContainer.find('.input-exportcronjobs_advanced').val(cronstring);
                if (!basicContainer.find('#exportcronjobs_showadvanced').is(':checked')) {
                    basicContainer.find('#exportcronjobs_showadvanced').click();
                }
            } else {
                if (basicContainer.find('#exportcronjobs_showadvanced').is(':checked')) {
                    basicContainer.find('#exportcronjobs_showadvanced').click();
                }
                if (json.cron.dayofmonth !== '*') {
                    basicContainer.find('.input-exportcronjobs_month-day').val(json.cron.dayofmonth);
                    basicContainer.find('.input-exportcronjobs_month-hour').val(json.cron.hour);
                    basicContainer.find('.input-exportcronjobs_month-minute').val(json.cron.minute);
                    basicContainer.find('#exportcronjobs_month').click();
                } else if (json.cron.dayofmonth === '*' && json.cron.hour !== '*') {
                    basicContainer.find('.input-exportcronjobs_day-hour').val(json.cron.hour);
                    basicContainer.find('.input-exportcronjobs_day-minute').val(json.cron.minute);
                    basicContainer.find('#exportcronjobs_day').click();
                } else if (json.cron.hour === '*' && json.cron.minute !== '*') {
                    basicContainer.find('.input-exportcronjobs_hour-minute').val(json.cron.minute);
                    basicContainer.find('#exportcronjobs_hour').click();
                } else {
                    basicContainer.find('#exportcronjobs_minute').click();
                }
            }
        };

        var fillExportRequestFromByJson = function(json) {
            var parent = wrapperContainer.find('.js-exportrequest');
            $.each(parent.find('.form-group'), function() {
                var element = $(this);
                if (json[element.data('name')]) {
                    if (element.find('.js-select2-value').length > 0) {
                        element.find('.js-select2-value').val(json[element.data('name')]).trigger('change');
                    } else if (element.find('input[type=radio]').length > 0) {
                        var inputs = (element.find('input[type=checkbox]').length > 0)
                            ?element.find('input[type=checkbox]'):element.find('input[type=radio]');
                        $.each(inputs, function() {
                            var input = $(this);
                            if (json[element.data('name')] && !input.is(':checked') && json[element.data('name')]===input.val()) {
                                input.click();
                            }
                        });
                    } else if (element.find('input[type=checkbox]').length > 0) {
                        var input = element.find('input[type=checkbox]');
                        input.prop('checked', true);
                    } else if (element.find('input.js-ajax-select2-value').length > 0) {
                        element.find('input.js-ajax-select2-value').select2('data', {
                            id: json[element.data('name')], a_key: json[element.data('name')]
                        });
                    } else if (element.find('input[type=text]').length > 0) {
                        element.find('input[type=text]').val(json[element.data('name')]);
                    }
                } else if (element.find('input[type=checkbox]').length > 0) {
                    element.find('input[type=checkbox]').prop('checked', false);
                }
            });
        };

        var fillTargetList = function(targets) {
            for (var i=0; i <= targets.length-1; i++) {
                createTargetListElement(targets[i]);
            }
        };

        var createTargetListElement = function(target) {
            var id = (target.id)?target.id:"NEW-"+newTarget
                ,json = ($.type(target) == 'string')
                    ?$.parseJSON(target)
                    :target
                props = "";
            if (json.ftpconfig) {
                var config = json.ftpconfig
                    ,password = '*****';

                props = config.user + ':' + password + '@' + config.host + ':' + config.port + '/' + config.directory;
            } else {
                var config = json.filesystem
                props = config.directory;
            }
            wrapperContainer.find('#TargetList')
                .append($('<li/>', {class:'exportcronlist__item exportcronlist__itemtargetvalue', 'data-id':id})
                .append($('<div/>', {class:'exportcronlist__itemcell exportcronlist__itemcelltargettype', 'text':json.type})
                    .append($('<span/>', {class:'exportcronlist__itemcelltargetprops', 'text': props}))
                )
                .append($('<div/>', {class:'exportcronlist__itemcell'})
                    .append($('<button/>', {
                            class:'btn btn-default edit-target-btn'
                            ,'type':'button'
                            ,'data-toggle':'tooltip'
                            ,'data-original-title':'Edit'
                            ,'data-target':JSON.stringify(json).replace(/'/g, "\\'")
                            ,'data-placement':'top'
                        }).append($('<span/>', {class:'fa fa-edit'}))
                    )
                    .append($('<button/>', {
                            class:'btn btn-default delete-target-btn'
                            ,'type':'button'
                            ,'data-toggle':'tooltip'
                            ,'data-original-title':'Delete'
                            ,'data-placement':'top'
                        }).append($('<span/>', {class:'fa fa-trash-o'}))
                    )
                )
            );
        };

        var onDeleteTarget = function(e) {
            var btn  =$(this)
                ,li = btn.parent().parent();
            li.remove();
            onCheckFields();
        };

        var onCheckFields = function(e) {
            if (e) {
                e.stopPropagation();
            }
            var basicFormReady = true
                ,targetFromReady = true
                ,currentTarget = (e)?$(e.currentTarget):null
                ,isDefaultForm = (wrapperContainer.find('.js-exportcronjobs_defaultform').css('display') === 'block')
                ,ccContainer = $('.js-exportrequest-countrycode')
                ,mccContainer = $('.js-exportrequest-multicountrycode')
                ,gtContainer = $('.js-exportrequest-globaltypes');

            if (ccContainer.css('display') === 'block') {
                if (currentTarget === null || currentTarget.find('input[name="countrycode"]').length === 0) {
                    currentTarget = ccContainer.find('input[name="countrycode"]');
                }
                if (!specialFieldIsValid(currentTarget, ccContainer)) {
                    basicFormReady = false;
                }
            }
            if (mccContainer.css('display') === 'block') {
                if (currentTarget === null || currentTarget.find('input[name="multicountrycode"]').length === 0) {
                    currentTarget = mccContainer.find('input[name="multicountrycode"]');
                }
                if (!specialFieldIsValid(currentTarget, mccContainer)) {
                    basicFormReady = false;
                }
            }
            if (gtContainer.css('display') === 'block') {
                if (currentTarget === null || currentTarget.find('input[name="globaltype"]').length === 0) {
                    currentTarget = gtContainer.find('input[name="globaltype"]');
                }
                if (!specialFieldIsValid(currentTarget, gtContainer)) {
                    basicFormReady = false;
                }
            }
            $.each(wrapperContainer.find('#ExportCronjobBasic form.js-exportcronjobs-basicform > .js-exportcronjobs-basicformgroup .form-control'), function(k,v) {
                if ($(this).val() === '' || $(this).val() === null) {
                    basicFormReady = false;
                }
            });
            if (!extractCronString(wrapperContainer, isDefaultForm)) {
                basicFormReady = false;
            }
            if (wrapperContainer.find('#TargetList li').length == 0) {
                targetFromReady = false;
            }
            if (basicFormReady && targetFromReady) {
                wrapperContainer.find('.js-exportcronjobs_missingfieldsmessage').hide();
                var addToListButton = wrapperContainer.find('.btn-saveexportcronjob');
                addToListButton.removeAttr('disabled');
                if (e && e.keyCode && e.keyCode === 13) {
                    addToListButton.click();
                }
            } else {
                wrapperContainer.find('.js-exportcronjobs_missingfieldsmessage').show();
                wrapperContainer.find('.btn-saveexportcronjob').prop('disabled', true);
            }
        };

        var specialFieldIsValid = function(input, parent) {
            if (parent.find('.js-input-text-value').length > 0) {
                input =  parent.find('.js-input-text-value');
            }

            if (parent && parent.css('display') === 'none') {
                return true;
            }
            if (input.attr('name') === 'countrycode') {
                var value, cc;

                value = input.val();

                if (value === "") {
                    input.prev().text(Translator.trans('config.labels.exportcronjobs.table.error.empty', {}, 'cms'));
                    input.prev().show();
                    return false;
                }
                cc = value.trim().split('_');
                if (!isValidCountryCode(cc, input, false)) {
                    return false;
                }
                input.prev().hide();
                return true;
            }
            if (input.attr('name') === 'multicountrycode') {
                var value, countrycodes, cc;

                value = input.val();

                if (value === "") {
                    input.prev().text(Translator.trans('config.labels.exportcronjobs.table.error.empty', {}, 'cms'));
                    input.prev().show();
                    return false;
                }
                countrycodes = value.split(',');
                for (var countrycode in countrycodes) {
                    cc = countrycodes[countrycode].trim().split('_');
                    if (!isValidCountryCode(cc, input, true)) {
                        return false;
                    }
                }
                input.prev().hide();
                return true;
            }
            if (input.attr('name') === 'globaltype') {
                var value, countrycodes, cc;
                value = input.val();

                if (!value) {
                    return false;
                }
                return true;
            }
            return true;
        };

        var isValidCountryCode = function(cc, input, isMulti) {
            if (cc.length !== 2 || cc[0].length !== 2 || cc[1].length !== 2) {
                var langKey = (isMulti)?'config.labels.exportcronjobs.table.error.multicountrycode'
                    :'config.labels.exportcronjobs.table.error.countrycode';
                input.prev().text(Translator.trans(langKey, {}, 'cms'));
                input.prev().show();
                return false;
            }
            return true;
        }

        var onShowTarget = function(e) {
            var option = $(this).val()
                ,fsContainer = addTargetBox.find('.targettype-fs')
                ,ftpContainer = addTargetBox.find('.targettype-ftp');
            if (option === 'FILESYSTEM') {
                fsContainer.show();
                ftpContainer.hide();
            }
            if (option === 'FTP') {
                ftpContainer.show();
                fsContainer.hide();
            }
        };

        var onAddTargetToList = function(e) {
            e.preventDefault();
            var btn = $(this)
                ,parent = btn.parents('.addtargettypebox')
                ,fsContainer = parent.find('.targettype-fs')
                ,ftpContainer = parent.find('.targettype-ftp')
                ,id = addTargetBox.find('#AddTargetButton').attr('data-id')
                ,targetList = wrapperContainer.find('#TargetList')
                ,targetIsValid = false
                ,result = {};
            if (ftpContainer.is(':visible')) {
                var host = ftpContainer.find('input[name=host]').val()
                    ,port = ftpContainer.find('input[name=port]').val()
                    ,user = ftpContainer.find('input[name=user]').val()
                    ,password = ftpContainer.find('input[name=password]').val()
                    ,directory = ftpContainer.find('input[name=directory]').val();
                if (host && port && user && password && directory) {
                    targetIsValid = true;
                }
                result["type"] = "FTP";
                result["id"] = (id)?id:"NEW-"+newTarget;
                result["ftpconfig"] = {
                    "host":host
                    ,"port":port
                    ,"user":user
                    ,"password":password
                    ,"directory":directory
                };
            }
            if (fsContainer.is(':visible')) {
                var directory = fsContainer.find('input[name=directory]').val();
                result["type"] = "FILESYSTEM";
                if (id) {
                    result["id"] = id;
                }
                result["filesystem"] = {"directory":directory};
                if (directory) {
                    targetIsValid = true;
                }
            }
            if (!targetIsValid) {
                $('#ExportCronjobBasic').find('.target-warning-message').show();
                return;
            }

            if (id) {
                $.each(targetList.find('li'), function(k,v) {
                    if ($(this).data('id') === id) {
                        $(this).find('.edit-target-btn').attr('data-target', JSON.stringify(result).replace(/'/g, "\\'"));
                        $(this).find('.exportcronlist__itemcelltargettype').text(result.type);
                    }
                })
            } else {
                createTargetListElement(result);
            }
            resetTarget();
            onCheckFields();
            $('#ExportCronjobTarget').find('.target-warning-message').hide();
            newTarget++;
        };

        var onEditTarget = function(e) {
            var btn = $(this)
                ,orgAttr
                ,json;
            if (btn.hasClass('btn-default')) {
                orgAttr = btn.attr('data-target');
                json = ($.type(orgAttr) == 'string')? $.parseJSON(orgAttr):orgAttr;
                wrapperContainer.find('.edit-target-btn').removeClass('btn-primary').addClass('btn-default');
                btn.removeClass('btn-default').addClass('btn-primary');
                openAddTarget();
                fillTargetFormByJson(json);
                addTargetBox.find('#AddTargetButton').attr('data-id', json.id);
            } else {
                btn.removeClass('btn-primary').addClass('btn-default');
                resetTarget();
            }
        };

        var fillTargetFormByJson = function(json) {
            var select = wrapperContainer.find('#TargetTypeSelect')
                ,fsContainer = addTargetBox.find('.targettype-fs')
                ,ftpContainer = addTargetBox.find('.targettype-ftp')
                ,config = [];
            select.val(json.type).change();
            select.data('orgData', json.type);
            if (json.type === 'FTP') {
                config = json.ftpconfig;
                var inputHost = ftpContainer.find('input[name=host]')
                    ,inputPort = ftpContainer.find('input[name=port]')
                    ,inputUser = ftpContainer.find('input[name=user]')
                    ,inputPassword = ftpContainer.find('input[name=password]')
                    ,inputDirectory = ftpContainer.find('input[name=directory]');
                inputHost.data('orgData', config.host);
                inputHost.val(config.host);
                inputPort.data('orgData', config.port);
                inputPort.val(config.port);
                inputUser.data('orgData', config.user);
                inputUser.val(config.user);
                inputPassword.data('orgData', config.password);
                inputPassword.val(config.password);
                inputDirectory.data('orgData', config.directory);
                inputDirectory.val(config.directory);
            }
            if (json.type === 'FILESYSTEM') {
                config = json.filesystem;
                fsContainer.find('input[name=directory]').val(config.directory);
            }
        };

        var extractCronString = function(basicForm, isDefaultForm) {
            var radioField = basicForm.find('input[name="exportcronjobs"]:checked')
                ,className = (isDefaultForm)?'input-' + radioField.attr('id'):'input-exportcronjobs_advanced'
                ,formClass = isDefaultForm
                    ?'.js-exportcronjobs_defaultform':'.js-exportcronjobs_advancedform'
                ,inputFields = basicForm.find(formClass + ' .'+className)
                ,cronArray = ['*', '*', '*', '*', '*']
                ,isValidCron = true;
            if (isDefaultForm && radioField.length === 0) {
                return false;
            }
            $.each(inputFields, function() {
                var input = $(this);
                if (input.val() == '') {
                    isValidCron = false;
                    return false;
                }
                if (input.hasClass(className + '-minute')) {
                    cronArray[0] = input.val();
                } else if (input.hasClass(className + '-hour')) {
                    cronArray[1] = input.val();
                } else if (input.hasClass(className + '-day')) {
                    cronArray[2] = input.val();
                } else if (input.hasClass(className)) {
                    cronArray = input.val();
                }
            });
            if (!isValidCron) {
                return false;
            }
            if (!Array.isArray(cronArray)) {
                cronArray = cronArray.split(" ");
                if (cronArray.length < 5) {
                    return false;
                }
            }
            return {
                'minute': cronArray[0]
                ,'hour': cronArray[1]
                ,'dayofmonth': cronArray[2]
                ,'month': cronArray[3]
                ,'weekday': cronArray[4]
            };
        };

        var onAddCronjobToList = function(e) {
            var btn = $(this)
                ,targets = []
                ,id = btn.data('id')
                ,result = {}
                ,basicForm = wrapperContainer.find('#ExportCronjobBasic form')
                ,isDefaultForm = (basicForm.find('.js-exportcronjobs_defaultform').css('display') === 'block')
                ,exportRequestContainer = basicForm.find('.js-exportrequest')
                ,exportCronJobs = [];

            if (id) {
                result['id'] = id;
            }
            result['type'] = basicForm.find('#ExportTypeSelect').val();
            result['name'] = basicForm.find('input[name=name]').val();
            result['deleteaftersuccess'] = basicForm.find('#DeleteAfterSuccess').val();
            result['cron'] = extractCronString(basicForm, isDefaultForm);
            result['advancedcron'] = !isDefaultForm;
                result['exportrequest'] = {};
            if (exportRequestContainer.css('display') === 'block') {
                $.each(exportRequestContainer.find('.form-group'), function(k,v) {
                    var formgroup = $(this);
                    if (formgroup.css('display') === 'block') {
                        var inputs = formgroup.find('input,select');
                        for(var i=0; i <= inputs.length-1; i++) {
                            var input = $(inputs[i]);
                            if (input.hasClass('js-select2-value') || input.hasClass('js-ajax-select2-value')
                                || input.is(':checked') || input.hasClass('js-input-text-value')) {
                                result['exportrequest'][formgroup.data('name')] = $(input).val();
                            }
                        }
                    }
                });
            }

            $.each(wrapperContainer.find('#TargetList li'), function(k,v) {
                var li = $(this)
                    ,json = ($.type(li.find('.edit-target-btn').attr('data-target')) == 'string')
                        ?$.parseJSON(li.find('.edit-target-btn').attr('data-target'))
                        :li.find('.edit-target-btn').attr('data-target');
                targets.push(json);
            });
            result['targets'] = targets;
            exportCronJobs = aggregateData(result);
            reset();
            saveData(exportCronJobs);
        };

        var aggregateData = function(additionalData) {
            var exportCronJobs = []
                ,cronlist = wrapperContainer.find('#ExportCronJobsList');
            if (additionalData) {
                exportCronJobs.push(additionalData);
            }
            $.each(cronlist.find('li.exportcronlist__itemvalue'), function() {
                var li = $(this);
                if (!additionalData || li.data('id') !== additionalData.id) {
                    exportCronJobs.push(li.data('exportdetail'));
                }
            });
            return exportCronJobs;
        };

        var saveData = function(data) {
            var url = Routing.generate('cms_config_saveexportcronjobs')
                ,loaderContainer = wrapperContainer;
            $.ajax(url, {
                data: {
                    exportCronJobs: data
                },
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "saveConfigExportCronJobs";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
                ,method: 'POST'
            })
            .done(function(data){
                if (data.success === false) {
                    toastr.error(
                        Translator.trans('def.term.error', {}, 'cms'),
                        Translator.trans('config.section.exportcronjobs.save.errormessage', {}, 'cms')
                    );
                }
                if (data.success === true) {
                    refreshExportCronJobsList();
                    toastr.success(
                        Translator.trans('def.term.success', {}, 'cms'),
                        Translator.trans('config.section.exportcronjobs.save.successmessage', {}, 'cms')
                    );
                }
            });
            return false;
        };

        var refreshExportCronJobsList = function() {
            var url = Routing.generate('cms_config_exportcronjobslist')
                ,loaderContainer = wrapperContainer;
            $.ajax(url, {
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "getConfigExportCronJobs";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
            })
            .done(function(data){
                loaderContainer.find('.js-cms-config-export-job-list').html(data.view);
            });
        };


        var reset = function() {
            wrapperContainer.find('.edit-exportcronjob-btn').removeClass('btn-primary').addClass('btn-default');
            wrapperContainer.find('.btn-saveexportcronjob').data('id', '');
            resetBasicData();
            resetTarget(null, true);
            wrapperContainer.find('.btn-saveexportcronjob').prop('disabled', true);
            wrapperContainer.find('#ExportCronjobBasicTab').click();
        };

        var resetBasicData = function() {

            $.each(wrapperContainer.find('#ExportCronjobBasic form .form-control'), function(k,v) {
                var field = $(this);
                if (field.attr('id') == 'ExportTypeSelect') {
                    field.val(0).change();
                } else if (field.attr('id') == 'DeleteAfterSuccess') {
                    field.val('true');
                } else {
                    field.val('');
                }
                if (field.attr('name') == 'exportcronjobs') {
                    field.removeAttr('checked');
                }
            });
            wrapperContainer.find('.js-exportcronjobs-specbox').hide();
            resetExportRequests();
        };

        var resetExportRequests = function() {
            var parent = wrapperContainer.find('.js-exportrequest')
                ,intersectionRadio = parent.find('#ExportRequestSetIntersection')
                ,unionRadio = parent.find('#ExportRequestSetUnion')
                ,input = parent.find('#ExportRequestIncludeExtern');

            parent.hide();
            parent.find('.form-group').hide();
            if (input.is(':checked')) {
                input.click();
            }
            parent.find('.js-input-text-value').val('');
            parent.find('.js-select2-value').select2("val", null);
            parent.find('.js-ajax-select2-value').select2("val", null);
            if (unionRadio.is(':checked')) {
                intersectionRadio.click();
            }
        };

        var resetTarget = function(e, hard) {
            closeAddTarget();
            addTargetBox.find('#AddTargetButton').attr('data-id', "");
            wrapperContainer.find('.edit-target-btn').removeClass('btn-primary').addClass('btn-default');
            wrapperContainer.find('#TargetTypeSelect').val('');
            wrapperContainer.find('.targettype').hide();
            $.each(addTargetBox.find('.form-control'), function(k,v){
                $(this).val('');
            });
            if (hard) {
                wrapperContainer.find('.targettypeslist > ul').html('');
            }
        };

        var onOpenTargetTab = function(e) {
            wrapperContainer.find('#ExportCronjobTargetTab').click();
        };

        var onAddTarget = function(e) {
            if (!openAddTarget()) {
                wrapperContainer.find('.edit-target-btn').removeClass('btn-primary').addClass('btn-default');
                resetTarget();
            }
        };

        var openAddTarget = function() {
            var btn = addTargetButton
                ,innerBtn = btn.find('span');
            if (innerBtn.hasClass('fa-plus')) {
                resetTarget();
                addTargetBox.show();
                innerBtn.removeClass('fa-plus').addClass('fa-minus');
                return true;
            }
            return false;
        };

        var closeAddTarget = function() {
            var btn = addTargetButton
                ,innerBtn = btn.find('span');
            if (innerBtn.hasClass('fa-minus')) {
                addTargetBox.hide();
                innerBtn.addClass('fa-plus').removeClass('fa-minus');
                return true;
            }
            return false;
        };

        return {
            init: function() {
                loader = Loader.getInstance();
                addHandlers();
            }
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();