$(document).ready(function() {
    $(document).ajaxError(function (event, jqXHR) {
        if (403 === jqXHR.status) {
            if (
                'undefined' !== typeof jqXHR.responseJSON &&
                'undefined' !== typeof jqXHR.responseJSON.redirect &&
                '' !== jqXHR.responseJSON.redirect
            ) {
                window.location = jqXHR.responseJSON.redirect;
            } else {
                window.location.reload();
            }
        }
    });
});