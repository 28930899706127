var ConfigSupplierCodeMappingsController = (function(){

    var instance
        ,loader
        ,wrapper;

    function createInstance() {

        var addHandlers = function () {
            wrapper = $('.js-cms-config-suppliercode-mappings');
            wrapper.on('submit', '.js-cms-config-form-suppliercode-mappings', onSubmit);
            wrapper.on('click', '.btn-reset-suppliercode-mappings', onReset);
            wrapper.find('.js-config-sortlist').nestable({group:1, maxDepth:1});

            initKeyList(wrapper.find('.js-cms-config-form-suppliercode-mappings-keyList'));
        };

        var initKeyList = function(elem) {
            var serverType = wrapper.data('servertype')
                ,headerKey = 'Extern'
                ,headerValue = 'Intern';
            if (serverType === 'central') {
                headerKey = 'Global';
                headerValue = 'Goalkeeper';
            }
            elem.keyValueList({
                uuid: $.uuid(),
                width: '450px',
                caption: '',
                name: elem.data('name'),
                header: {
                    'key': headerKey,
                    'value': headerValue
                },
                showResetButton: false,
                readonly: false,
                language: {
                    deleteConfirmationTitle: Translator.trans('config.labels.suppliercodemappings.delete.title', {}, 'cms'),
                    deleteConfirmationText: Translator.trans('config.labels.suppliercodemappings.delete.description', {}, 'cms')
                }
            });
            elem.find('.delete-btn').addClass('js-unsaveddata-button');
        };

        var onReset = function(e) {
            e.preventDefault();
            var btn = $(this)
                ,loaderContainer = btn.parents('.js-cms-config-suppliercode-mappings');
            $.ajax({
                url: Routing.generate('cms_config_resetsuppliercodemappings')
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "resetSupplierCodeMappings";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
            })
            .done(function(data) {
                if (data.view) {
                    btn.parents('.js-config-formwrapper').html(data.view);
                    initKeyList(loaderContainer.find('.js-cms-config-form-suppliercode-mappings-keyList'));
                }
            });
        };


        var onSubmit = function(e) {
            e.preventDefault();
            var form = $(this)
                ,loaderContainer = form.parents('.js-cms-config-suppliercode-mappings')
                ,supplierCodeMappings = []
                ,url = Routing.generate('cms_config_savesuppliercodemappings');

            $.each(form.find('input[name^=suppliercodemappings]'), function (idx, kV) {
                if (kV.value !== '') {
                    supplierCodeMappings.push(kV.value);
                }
            });

            $.ajax(url, {
                data: {
                    supplierCodeMappings: supplierCodeMappings
                },
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "saveConfigSupplierCodeMappings";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
                ,method: 'POST'
            })
            .done(function(data){
                if (data.success === false) {
                    toastr.error(
                        Translator.trans('def.term.error', {}, 'cms'),
                        Translator.trans('config.section.suppliercodemapppings.save.errormessage', {}, 'cms')
                    );
                }
                if (data.success === true) {
                    loaderContainer.find('.btn-reset-suppliercode-mappings').click();
                    toastr.success(
                        Translator.trans('def.term.success', {}, 'cms'),
                        Translator.trans('config.section.suppliercodemapppings.save.successmessage', {}, 'cms')
                    );
                }
            });
        };

        var init = function() {
            loader = Loader.getInstance();
            addHandlers();
        };

        return {
            init: init
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();/**
 * Created by kyoung on 10.05.17.
 */
