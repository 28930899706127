/**
 *
 * GOAL relevant / potential APT Marker
 * extends AbstractMapComponent
 *
 */
(function() {
    var ctx = pwNamespace('PEAKWORK.GOAL')
        ,parentCtx = pwNamespace('PEAKWORK.MAP')
        ,mapWrapper = null
        ,_ = null;
    // console.log('GOAL relevant / potential APT Marker');
    ctx.GoalMapAirportComponent = function (mapWrapperObj, options) {
        mapWrapper = mapWrapperObj;
        _ = this;
        // console.log('GoalMapAirportComponent');
        var defaultoptions = {
            infowindowCol: 1
            ,class: {
                infowindowcontainer: 'flexmap__infowindow'
            }
            ,zindex: {
                matchedaptpotential: 20
                ,matchedaptrelevant: 21
            }
            ,markerRegex: /^matched/
            ,polygonStyles: {
                matchedaptpotential: {
                    fillColor: '#ffb606'
                    ,strokeColor: '#ffb606'
                    ,strokeWeight: 2
                }
                ,matchedaptrelevant: {
                    fillColor: '#3498db'
                    ,strokeColor: '#3498db'
                    ,strokeWeight: 2
                }
            }
            ,bounds: {
                default: ['center', 'matchedaptrelevant', 'matchedaptpotential']
            }
        };

        if (options && typeof options === "object") {
            var opts = this.extendDefaults(defaultoptions, options);
        }

        parentCtx.AbstractMapComponent.call(_, mapWrapper, opts || defaultoptions);

        _.init();

    };
    ctx.GoalMapAirportComponent.prototype = $.extend(
        Object.create(parentCtx.AbstractMapComponent.prototype), {
            constructor: ctx.GoalMapAirportComponent
            ,init: function () {
                _.mapId = mapWrapper.getMapId();

                mapWrapper.addBoundsCat('matchedaptrelevant',false,_.options.bounds.default);
                mapWrapper.addBoundsCat('matchedaptpotential',false,_.options.bounds.default);
                //
                _.addHandler();

                _.updateMatchedAirports(_.getMatchedAirportDataFromOptions());

            }

            ,addHandler: function() {
                $.subscribe('changeMatchedAirports.goal.cms', _.changeMatchedAirports);
            }
    
            ,changeMatchedAirports: function(e, data) {
                _.updateMatchedAirports(data);
            }
    
            ,getMatchedAirportDataFromOptions: function () {
                if (mapWrapper.hasOwnProperty('options')
                    && mapWrapper.options.hasOwnProperty('data')
                    && mapWrapper.options.data.hasOwnProperty('airports')) {
                    return mapWrapper.options.data.airports;
                }
            }

            ,parseMatchedAirportData: function (airportData) {
                var self = this;
                airportData = airportData || false;
                if (!airportData && !airportData.hasOwnProperty('potentialairports')) {
                    return null;
                }
                var potentialApts = airportData.potentialairports;
                var relevantApts = {};
                var resultApts = {};
                if (airportData.hasOwnProperty('relevantairports')) {
                    relevantApts = airportData.relevantairports.map(function(d){
                        if (d.hasOwnProperty('iata')
                            && d.iata.hasOwnProperty('value')) {
                            return d.iata.value;
                        }
                    });
                }
                for(var aptKey in potentialApts) {
                    var apt = potentialApts[aptKey];
                    var relevant = false;
                    if (!apt || !apt.iata) {
                        continue;
                    }
                    if ($.inArray(apt.iata.value,relevantApts) > -1) {
                        relevant = true;
                    }
                    var category = (relevant ? 'matchedaptrelevant' : 'matchedaptpotential');
                    resultApts[apt.iata.value] = {
                        citymatch: (apt.hasOwnProperty('citymatch') && apt.citymatch.hasOwnProperty('value') ? (apt.citymatch.value == "true")  : false)
                        ,countrymatch: (apt.hasOwnProperty('countrymatch') && apt.countrymatch.hasOwnProperty('value') ? (apt.countrymatch.value == "true")  : false)
                        ,distance: (apt.hasOwnProperty('distance') && apt.distance.hasOwnProperty('value') ? (Math.round(parseInt(apt.distance.value) / 1000)) + ' km'  : false)
                        ,osmpolygonmatch: (apt.hasOwnProperty('osmpolygonmatch') && apt.osmpolygonmatch.hasOwnProperty('value') ? (apt.osmpolygonmatch.value == "true")  : false)
                        ,regionmatch: (apt.hasOwnProperty('regionmatch') && apt.regionmatch.hasOwnProperty('value') ? (apt.regionmatch.value == "true")  : false)
                        ,relevance: (apt.hasOwnProperty('relevance') && apt.relevance.hasOwnProperty('value') ? parseInt(apt.relevance.value) : false)
                        ,relevant: relevant
                        ,pwCategory: category
                        ,pwInfowindowCol: this.options.infowindowCol
                    };
                    if (this.options.polygonStyles.hasOwnProperty(category)) {
                        resultApts[apt.iata.value]['pwPolygonStyle'] = this.options.polygonStyles[category];
                    }
                }

                //Add non-potential relevant Airports:
                $.each(relevantApts,function(k,v){
                    if(!resultApts.hasOwnProperty(v)) {
                        resultApts[v] = {
                            relevant: true
                            ,pwCategory: 'matchedaptrelevant'
                        };
                        if (self.options.polygonStyles.hasOwnProperty('matchedaptrelevant')) {
                            resultApts[v]['pwPolygonStyle'] = self.options.polygonStyles['matchedaptrelevant'];
                        }
                    }
                });
                return resultApts;
            }

            ,mergeMatchedAirportGeoData: function (data) {
                data = data || false;
                if (!data) {
                    return false;
                }
                if (!data.hasOwnProperty('data')) {
                    return false;
                }
                dataArray = data.data;
                if (!$.isArray(dataArray)) {
                    return false;
                }
                for (var key in dataArray) {
                    if (dataArray.hasOwnProperty(key)
                        && dataArray[key].hasOwnProperty('iatacode')
                        && _.rApts.hasOwnProperty(dataArray[key].iatacode)) {
                        //rApts[dataArray[key].iatacode].longitude = (dataArray[key].hasOwnProperty('longitude') ? dataArray[key].longitude : false);
                        //rApts[dataArray[key].iatacode].latitude = (dataArray[key].hasOwnProperty('latitude') ? dataArray[key].latitude : false);
                        //$.extend(_.rApts[dataArray[key].iatacode],dataArray[key]);
                        _.rApts[dataArray[key].iatacode] = dataArray[key];
                    }
                }
                return true;
            }

            ,addMatchedAirportGeoData: function () {
                var iataCodes = [];
                for(var iataCode in _.rApts) iataCodes.push(iataCode);
                if (iataCodes.length < 1) {
                    return null;
                }
                var data = {
                    // iatacodes: iataCodes
                    iatacodes: _.rApts
                    //,coordinatesonly: true
                    ,polygon: true
                };
                var url = Routing.generate('cms_basicdata_airport_iatageo');
                return $.ajax(url, {
                    data: data
                }).pipe(function(data){
                    _.mergeMatchedAirportGeoData(data);
                });
            }

            ,showMatchedAirportData: function (airports) {
                _.addMarkers(airports);
                mapWrapper.centerMap(false,true);
            }

            ,excludeMatchesFromGeoSearch: function (airports) {

                var iataCodes = [];
                for(var iataCode in _.rApts) iataCodes.push(iataCode);
                if (iataCodes.length < 1) {
                    return null;
                }
                $.publish('changeGeoSearchExcludes.basicdata.cms',['airport','GoalMapAirportComponent',iataCodes]);
            }

            ,getMatchedAirports: function () {
                return _.rApts;
            }

            ,updateMatchedAirports: function (airports) {
                _.clearComponentMarkers();
                _.rApts = _.parseMatchedAirportData(airports);
                var getData = _.addMatchedAirportGeoData();
                if (getData === null) {
                    return;
                }
                getData.done(function(data){
                    _.showMatchedAirportData(_.rApts);
                    _.excludeMatchesFromGeoSearch(_.rApts);
                });
            }
            
        }
    );
})();