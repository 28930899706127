(function (Translator) {
    // en
    Translator.add("backend.breadcrumb.home", "Dashboard", "peakwork", "en");
    Translator.add("backend.dashboard.ajaxloaderror", "An error has occurred while loading the content.", "peakwork", "en");
    Translator.add("backend.dashboard.todetailpage", "Go to page", "peakwork", "en");
    Translator.add("backend.editable.fieldisrequired", "This field must not be empty and valid.", "peakwork", "en");
    Translator.add("backend.header.long", "<strong>p<\/strong>eakwork <strong>c<\/strong>ontent <strong>m<\/strong>anagement <strong>s<\/strong>ystem (%servername%)", "peakwork", "en");
    Translator.add("backend.header.short", "pcms", "peakwork", "en");
    Translator.add("backend.header.user.pwcustomer", "PWCustomer", "peakwork", "en");
    Translator.add("backend.header.user.pwoffice", "PWOffice", "peakwork", "en");
    Translator.add("backend.header.user.execution", "Execution", "peakwork", "en");
    Translator.add("backend.initloading.text", "Loading content...", "peakwork", "en");
    Translator.add("backend.initloading.title", "peakwork AG - CMS", "peakwork", "en");
    Translator.add("backend.logout", "Logout", "peakwork", "en");
    Translator.add("backend.menue.language.select", "Select language", "peakwork", "en");
    Translator.add("backend.navigation.home", "Dashboard", "peakwork", "en");
    Translator.add("backend.navigation.search", "Filter Navigation", "peakwork", "en");
    Translator.add("backend.table.addorseecomment", "Add or look up comments", "peakwork", "en");
    Translator.add("backend.table.addorseestatus", "Add or look up internal status", "peakwork", "en");
    Translator.add("backend.table.empty", "No data found", "peakwork", "en");
    Translator.add("backend.table.filterdfrom_MAX_", "(filtered from _MAX_ total records)", "peakwork", "en");
    Translator.add("backend.table.loading", "Loading...", "peakwork", "en");
    Translator.add("backend.table.operationnumber", "Operation No.", "peakwork", "en");
    Translator.add("backend.table.page_PAGE_of_PAGES_", "Showing page _PAGE_ of _PAGES_", "peakwork", "en");
    Translator.add("backend.table.paginate.first", "First", "peakwork", "en");
    Translator.add("backend.table.paginate.last", "Last", "peakwork", "en");
    Translator.add("backend.table.paginate.next", "Next", "peakwork", "en");
    Translator.add("backend.table.paginate.previous", "Previous", "peakwork", "en");
    Translator.add("backend.table.select.cells", "%d cells selected", "peakwork", "en");
    Translator.add("backend.table.select.columns", "%d columns selected", "peakwork", "en");
    Translator.add("backend.table.select.rows", "%d rows selected", "peakwork", "en");
    Translator.add("backend.table.processing", "Loading data", "peakwork", "en");
    Translator.add("backend.table.recordsperpage_MENU_", "Show _MENU_", "peakwork", "en");
    Translator.add("backend.table.search", "Search", "peakwork", "en");
    Translator.add("backend.table.sortAscending", "activate to sort column ascending", "peakwork", "en");
    Translator.add("backend.table.sortDescending", "activate to sort column descending", "peakwork", "en");
    Translator.add("backend.title", "peakwork AG", "peakwork", "en");
    Translator.add("backend.togglemenu", "Toggle menu", "peakwork", "en");
    Translator.add("backend.user", "User", "peakwork", "en");
    Translator.add("backend.error.body.erroroccurred", "An error occurred while loading the requested page. Please contact Administration!", "peakwork", "en");
    Translator.add("backend.error.body.notfound", "The requested page could not be located. Checkout for any URL misspelling!", "peakwork", "en");
    Translator.add("backend.error.body.notpermitted", "Your user is not permitted to see the requested page. Checkout for any URL misspelling!", "peakwork", "en");
    Translator.add("backend.error.body.button.returntohome", "Return To Home", "peakwork", "en");
    Translator.add("usermanagement.list.description", "List of all users", "peakwork", "en");
    Translator.add("usermanagement.list.table.actions", "Actions", "peakwork", "en");
    Translator.add("usermanagement.list.table.delete.action", "Deleting user", "peakwork", "en");
    Translator.add("usermanagement.list.table.delete.confirm", "Delete user?", "peakwork", "en");
    Translator.add("usermanagement.list.table.delete.confirmtext", "Do you really want to delete the user?", "peakwork", "en");
    Translator.add("usermanagement.list.table.delete.error", "Error", "peakwork", "en");
    Translator.add("usermanagement.list.table.delete.success", "Delete user successfully", "peakwork", "en");
    Translator.add("usermanagement.list.table.delete.successtext", "The user was deleted successfully.", "peakwork", "en");
    Translator.add("usermanagement.list.table.delete.unexpectederror", "Unexpected error", "peakwork", "en");
    Translator.add("usermanagement.list.table.delete.unexpectederrortext", "An unexpected error has occurred. Please try again later.", "peakwork", "en");
    Translator.add("usermanagement.list.table.delete.usernotfound", "The user could not be found.", "peakwork", "en");
    Translator.add("usermanagement.list.table.deleteuser", "Delete user", "peakwork", "en");
    Translator.add("usermanagement.list.table.disabled", "Disabled", "peakwork", "en");
    Translator.add("usermanagement.list.table.edit.cancel", "Cancel", "peakwork", "en");
    Translator.add("usermanagement.list.table.edit.close", "Close", "peakwork", "en");
    Translator.add("usermanagement.list.table.edit.email", "Email", "peakwork", "en");
    Translator.add("usermanagement.list.table.edit.enabled", "Enabled", "peakwork", "en");
    Translator.add("usermanagement.list.table.edit.password", "Password", "peakwork", "en");
    Translator.add("usermanagement.list.table.edit.roles", "Roles", "peakwork", "en");
    Translator.add("usermanagement.list.table.edit.save", "Save", "peakwork", "en");
    Translator.add("usermanagement.list.table.edit.title", "Edit user", "peakwork", "en");
    Translator.add("usermanagement.list.table.edit.username", "Username", "peakwork", "en");
    Translator.add("usermanagement.list.table.edit.usernotfound", "The user could not be found.", "peakwork", "en");
    Translator.add("usermanagement.list.table.edituser", "Edit user", "peakwork", "en");
    Translator.add("usermanagement.list.table.email", "Email", "peakwork", "en");
    Translator.add("usermanagement.list.table.enabled", "Enabled", "peakwork", "en");
    Translator.add("usermanagement.list.table.lastlogin", "Last login", "peakwork", "en");
    Translator.add("usermanagement.list.table.roles", "Roles", "peakwork", "en");
    Translator.add("usermanagement.list.table.title", "Title", "peakwork", "en");
    Translator.add("usermanagement.list.table.username", "Username", "peakwork", "en");
    Translator.add("usermanagement.list.title", "User list", "peakwork", "en");
    Translator.add("usermanagement.login.password.description", "Please enter you password", "peakwork", "en");
    Translator.add("usermanagement.login.password.label", "Password", "peakwork", "en");
    Translator.add("usermanagement.login.remember", "Remember login", "peakwork", "en");
    Translator.add("usermanagement.login.submit", "Login", "peakwork", "en");
    Translator.add("usermanagement.login.title", "Please login into backend", "peakwork", "en");
    Translator.add("usermanagement.login.username.description", "Please enter you username", "peakwork", "en");
    Translator.add("usermanagement.login.username.label", "Username", "peakwork", "en");
})(Translator);
