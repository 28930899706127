var ConfigPriorityContentExportDescriptionsController = (function(){

    var instance
        ,loader
        ,globalTypeExists = false
        ,alwaysAddGlobalType = false
        ,wrapper
        ,list;

    function createInstance() {

        var addHandlers = function () {
            wrapper = $('.js-cms-config-prioritycontentexport-descriptions');
            list = wrapper.find('.checkboxlist');
            initNestable();
            checkIfGTExists();
            checkAlwaysIncludeGT(wrapper.find('.js-prioritycontentexport-descriptions-alwaysglobaltype'));
            wrapper.find('.js-select2-filter-language').select2();
            wrapper.find('.js-select2-filter-supplier').select2();
            wrapper.on('change', '.prioritycontentexport-descriptions-radio', onChangeSwitch);
            wrapper.on('change', '.js-prioritycontentexport-descriptions-alwaysglobaltype', onChangeAlwaysGT);
            wrapper.on('click', '.prioritycontentexport-descriptions-filter-button .btn-save', onAddToList);
            wrapper.on('click', '.js-prioritycontentexport-descriptions-editbtn', onEditEntry);
            wrapper.on('click', '.js-prioritycontentexport-descriptions-deletebtn', onDeleteEntry);
            wrapper.find('.js-config-prioritycontentexport-openaddcheckboxitemdropdown').parent().on('hidden.bs.dropdown', onHiddenDropDown);
        };

        var reset = function() {
            list.remove();
            wrapper.remove();
        };

        var onChangeAlwaysGT = function(e) {
            checkAlwaysIncludeGT($(this));
        };

        var checkAlwaysIncludeGT = function(checkBox) {
            var parent = checkBox.parents('.js-cms-config-prioritycontentexport-descriptions')
                ,addGtRadio = parent.find('#prioritycontentexport-descriptions-globaltype')
                ,list = parent.find('.checkboxlist ol.dd-list');
            if (checkBox.is(':checked')) {
                addGtRadio.attr('disabled', true);
                alwaysAddGlobalType = true;
                if (globalTypeExists) {
                    var gtItem = $('.checkboxlist ol.dd-list [data-rulesettype="globaltype"]');
                    gtItem.remove();
                    globalTypeExists = false;
                }
            } else {
                addGtRadio.attr('disabled', false);
                alwaysAddGlobalType = false;
            }
        };

        var checkIfGTExists = function() {
            $.each(list.find('li'), function(){
                var li = $(this);
                if (li.data('rulesettype') === 'globaltype') {
                    globalTypeExists = true;
                    return false;
                }
            });
            return false;
        };

        var onHiddenDropDown = function() {
            var button = $(this)
                ,outerParent = button.parents('.js-cms-config-prioritycontentexport-descriptions')
                ,parent = outerParent.find('.js-cms-config-prioritycontentexport-descriptions-filter')
                ,contentContainer = parent.find('.prioritycontentexport-descriptions-supplier')
                ,lang = contentContainer.find('select.js-select2-filter-language');

            lang.removeClass('mandatory-field-error');
            resetFilter(parent);
            parent.find('.prioritycontentexport-descriptions-filter-button .btn-save').attr('data-editedItemIndex', null);
        };

        var onEditEntry = function(e) {
            var button = $(this)
                ,li = button.parents('li')
                ,outerParent = button.parents('.js-cms-config-prioritycontentexport-descriptions')
                ,parent = outerParent.find('.js-cms-config-prioritycontentexport-descriptions-filter')

                ,supplierRadioButton = parent.find('#prioritycontentexport-descriptions-supplier');
            supplierRadioButton.click();
            if (li.attr('data-lang')) {
                parent.find('.js-select2-filter-language').select2("val", li.attr('data-lang').split(','));
            }
            parent.find('.js-select2-filter-supplier').select2("val", li.attr('data-supplier'));
            parent.find('.prioritycontentexport-descriptions-filter-button .btn-save').attr('data-editedItemIndex', li.index());
            parent.find('.js-config-prioritycontentexport-openaddcheckboxitemdropdown').dropdown("toggle");
            return false;
        };

        var onDeleteEntry = function(e) {
            var button = $(this)
                ,li = button.parents('.dd-item');
            if (li.data('rulesettype') === 'globaltype') {
                globalTypeExists = false;
            }
            button.parents('.dd-item').remove();
        };

        var getDescriptionsList = function() {
            var form = wrapper.find('.js-cms-config-form-prioritycontentexport-descriptions')
                ,loaderContainer = form.parents('.js-cms-config-prioritycontentexport-descriptions')
                ,liItems = form.find('li');
            return liItems;
        }
        var supplierAndLanguageExist = function(suppl, lang) {
            var liItems = getDescriptionsList()
                ,exists = false;
            $.each(liItems, function (idx, kV) {
                var li = $(this);
                if (li.data('rulesettype') === 'supplier') {
                    if (li.attr('data-supplier') === suppl && li.attr('data-lang') === lang.join()) {
                        exists = true;
                        return false;
                    }
                }
            });
            return exists;
        };

        var onAddToList = function(e) {
            var button = $(this)
                ,li
                ,parent = button.parents('.js-cms-config-prioritycontentexport-descriptions-filter')
                ,outerParent = parent.parents('.js-cms-config-prioritycontentexport-descriptions')
                ,contentContainer = parent.find('.prioritycontentexport-descriptions-supplier')
                ,lang = contentContainer.find('select.js-select2-filter-language')
                ,supplier = contentContainer.find('select.js-select2-filter-supplier')
                ,currentLang = lang.val();

            if (currentLang && supplierAndLanguageExist(supplier.val(), currentLang)) {
                swal({
                    title: Translator.trans('config.labels.prioritycontentexport.modal.validation.descriptions.addtolist.title', {}, 'cms')
                    ,text: Translator.trans('config.labels.prioritycontentexport.modal.validation.descriptions.addtolist.description', {}, 'cms')
                    ,type: "warning"
                    ,showCancelButton: false
                    ,closeOnConfirm: true
                });
                return false;
            }

            if (contentContainer.css('display') === 'block'
                && button.attr('data-editedItemIndex') !== undefined && button.attr('data-editedItemIndex') !== null) {
                if (!currentLang) {
                    lang.addClass('mandatory-field-error');
                    return;
                }
                $.each(outerParent.find('.dd-list li'), function() {
                    var li = $(this);
                    if (li.index() === parseInt(button.attr('data-editedItemIndex'))) {
                        li.attr('data-supplier', supplier.val());
                        li.attr('data-lang', currentLang);
                        li.find('.prioritycontentexport-descriptions-list-item-text-supplier').text(supplier.val());
                        li.find('.prioritycontentexport-descriptions-list-item-text-language').text(currentLang);
                        return false;
                    }
                });
                button.attr('data-editedItemIndex', null);
                resetFilter(contentContainer);
                parent.find('.js-config-prioritycontentexport-openaddcheckboxitemdropdown').click();
                return false;
            }
            if (contentContainer.css('display') === 'block') {
                if (!currentLang) {
                    lang.addClass('mandatory-field-error');
                    return;
                }
                li = $('<li/>', {
                    'class':'dd-item config-checkbox-list'
                    ,'data-supplier': supplier.val()
                    ,'data-lang': currentLang
                    ,'data-rulesettype':'supplier'
                })
                    .append(
                        $('<div/>', {'class':'dd-handle config-checkbox-list__handle prioritycontentexport-descriptions-list__handle'})
                            .append(
                                $('<div/>', {'class':'dd-item__prioritycontentexport-descriptions--draggable'})
                                    .append(
                                        $('<div/>', {
                                            'class': 'prioritycontentexport-descriptions-list-item-text'
                                        })
                                        .append(
                                            $('<div/>', {
                                                'class':'prioritycontentexport-descriptions-list-item-text-supplier'
                                                ,'text': supplier.val()
                                            })
                                        )
                                        .append(
                                            $('<div/>', {
                                                'class':'prioritycontentexport-descriptions-list-item-text-language'
                                                ,'text': currentLang
                                            })
                                        )
                                    )
                                    .append(
                                        $('<div/>', {'class':'prioritycontentexport-descriptions-list-item-buttons'})
                                            .append(
                                                $('<button/>', {'class':'btn btn-xs btn-default js-prioritycontentexport-descriptions-editbtn'})
                                                    .append(
                                                        $('<span/>', {'class':'fa fa-edit'})
                                                    )
                                            )
                                            .append(
                                                $('<button/>', {'class':'btn btn-xs btn-default js-prioritycontentexport-descriptions-deletebtn'})
                                                    .append(
                                                        $('<span/>', {'class':'fa fa-trash-o'})
                                                    )
                                            )
                                    )
                            )
                    );

            } else {
                if (!globalTypeExists) {
                    li = $('<li/>', {
                        'class':'dd-item config-checkbox-list'
                        ,'data-rulesettype':'globaltype'
                    })
                        .append(
                            $('<div/>', {'class':'dd-handle config-checkbox-list__handle prioritycontentexport-descriptions-list__handle'})
                                .append(
                                    $('<div/>', {'class':'dd-item__prioritycontentexport-descriptions--draggable'})
                                        .append(
                                            $('<div/>', {
                                                'class': 'prioritycontentexport-descriptions-list-item-text'
                                                ,'text': 'GLOBAL_TYPES'
                                            })
                                        )
                                        .append(
                                            $('<div/>', {'class':'prioritycontentexport-descriptions-list-item-buttons'})
                                                .append(
                                                    $('<button/>', {'class':'btn btn-xs btn-default js-prioritycontentexport-descriptions-deletebtn'})
                                                        .append(
                                                            $('<span/>', {'class':'fa fa-trash-o'})
                                                        )
                                                )
                                        )
                                )
                        );
                }
                globalTypeExists = true;
            }
            outerParent.find('.dd-list').append(li);
            resetFilter(contentContainer);
            parent.find('.js-config-prioritycontentexport-openaddcheckboxitemdropdown').click();
            return false;
        };

        var onChangeSwitch = function(e) {
            var radio = $(this)
                ,id = radio.attr('id');
            if (radio.is(':checked')) {
                var contentContainer = radio.parents('.form-horizontal')
                    .find('.prioritycontentexport-descriptions-supplier');
                if (id === 'prioritycontentexport-descriptions-supplier') {
                    contentContainer.show();
                } else {
                    contentContainer.hide();
                }
                resetFilter(contentContainer)
            }
        };

        var resetFilter = function(contentContainer) {
            contentContainer.find('select.js-select2-filter-language').select2("val", "");
            contentContainer.find('select.js-select2-filter-supplier').select2("val", "");
            contentContainer.find('#prioritycontentexport-descriptions-supplier').click();
        };

        var initNestable = function() {
            if (!list.data('readonly')) {
                list.nestable({group:1, maxDepth:1});
            }
            list.on('mousedown', '.dd-item__prioritycontentexport-descriptions--draggable', function(e) {
                e.stopPropagation();
            });
        };

        var getAlwaysAddGlobalType = function() {
            return alwaysAddGlobalType;
        };

        var getPriorityRuleSetList = function() {
            var liItems = getDescriptionsList()
                ,priorityRuleSetList = [];

            $.each(liItems, function (idx, kV) {
                var li = $(this)
                    ,entry = {};
                if (li.data('rulesettype') === 'supplier') {
                    entry.type = 'supplier';
                    entry.supplier = li.attr('data-supplier');
                    entry.lang = li.attr('data-lang');
                } else {
                    entry.type = 'globaltype';
                }
                priorityRuleSetList.push(entry);
            });
            return priorityRuleSetList;
        };

        var init = function() {
            loader = Loader.getInstance();
            addHandlers();
        };

        return {
            init: init,
            reset: reset,
            getPriorityRuleSetList: getPriorityRuleSetList,
            getAlwaysAddGlobalType: getAlwaysAddGlobalType
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();