(function (Translator) {
    // en
    Translator.add("core.header.title.central", "<strong>p<\/strong>eakwork <strong>g<\/strong>oalkeeper (%servername%)", "core", "en");
    Translator.add("core.header.title.local", "<strong>p<\/strong>eakwork <strong>c<\/strong>ontentplayer (%servername%)", "core", "en");
    Translator.add("core.header.download.title", "Downloads", "core", "en");
    Translator.add("core.header.download.status.title", "Status", "core", "en");
    Translator.add("core.header.download.status.progress", "In Progress", "core", "en");
    Translator.add("core.header.download.status.finished", "Finished", "core", "en");
    Translator.add("core.header.download.status.failed", "Failed", "core", "en");
    Translator.add("core.header.download.status.empty", "no download requests available", "core", "en");
    Translator.add("core.header.download.requested.title", "Requested", "core", "en");
    Translator.add("core.header.download.notification", "Your Downloadrequest has been added to the Header downloadlist!", "core", "en");
    Translator.add("core.header.version.frontend", "Frontend Version", "core", "en");
    Translator.add("core.header.version.backend", "Backend Version", "core", "en");
    Translator.add("core.anchorbuttons.filter", "Filter", "core", "en");
    Translator.add("core.anchorbuttons.list", "List", "core", "en");
    Translator.add("core.anchorbuttons.detail", "Detail", "core", "en");
    Translator.add("core.anchorbuttons.general", "General", "core", "en");
    Translator.add("core.anchorbuttons.generalcronjobs", "General Cronjobs", "core", "en");
    Translator.add("core.anchorbuttons.goal", "Goal", "core", "en");
    Translator.add("core.anchorbuttons.giata", "Giata", "core", "en");
    Translator.add("core.anchorbuttons.history", "History", "core", "en");
    Translator.add("core.anchorbuttons.exportcronjobs", "Export Cronjobs", "core", "en");
    Translator.add("core.anchorbuttons.savebutton", "To Save Button", "core", "en");
    Translator.add("core.anchorbuttons.assignment", "Assignment", "core", "en");
    Translator.add("core.anchorbuttons.release", "Release\/Edit", "core", "en");
    Translator.add("core.anchorbuttons.rooms", "Rooms", "core", "en");
    Translator.add("core.anchorbuttons.prioritycontentexport", "Priority Content Export", "core", "en");
    Translator.add("core.anchorbuttons.apiv2config", "API v2 Config", "core", "en");
    Translator.add("core.tooltips.scrolltotop", "Scroll up to the top", "core", "en");
    Translator.add("core.tooltips.scrolltobottom", "Scroll down to the bottom", "core", "en");
    Translator.add("core.tooltips.scrolltofilter", "Scroll to Filter", "core", "en");
    Translator.add("core.tooltips.scrolltolist", "Scroll to List", "core", "en");
    Translator.add("core.tooltips.scrolltodetail", "Scroll to Detail", "core", "en");
    Translator.add("core.tooltips.scrolltogeneral", "Scroll to General", "core", "en");
    Translator.add("core.tooltips.scrolltogeneralcronjobs", "Scroll to General Cronjobs", "core", "en");
    Translator.add("core.tooltips.scrolltogoal", "Scroll to Goal", "core", "en");
    Translator.add("core.tooltips.scrolltogiata", "Scroll to Giata", "core", "en");
    Translator.add("core.tooltips.scrolltohistory", "Scroll to History", "core", "en");
    Translator.add("core.tooltips.scrolltoexportcronjobs", "Scroll to Export Cronjobs", "core", "en");
    Translator.add("core.tooltips.scrolltosavebutton", "Scroll To Save Button", "core", "en");
    Translator.add("core.tooltips.scrolltoassignment", "Scroll To Assignment", "core", "en");
    Translator.add("core.tooltips.scrolltorelease", "Scroll To Release\/Edit", "core", "en");
    Translator.add("core.tooltips.scrolltoprioritycontentexport", "Scroll to Priority Content Export", "core", "en");
    Translator.add("core.tooltips.scrolltorooms", "Scroll To Rooms", "core", "en");
    Translator.add("core.map.button.fullscreen.title", "Set to Fullscreen", "core", "en");
    Translator.add("core.map.button.fullscreen.text", "Fullscreen", "core", "en");
    Translator.add("core.map.button.normalview.title", "Set to Normal View", "core", "en");
    Translator.add("core.map.button.normalview.text", "Normal View", "core", "en");
    Translator.add("core.dashboard.navigation", "Dashboard", "core", "en");
    Translator.add("core.error.title", "Error", "core", "en");
    Translator.add("core.errormessages.title", "Error", "core", "en");
    Translator.add("core.errormessages.default", "An undefined error occured", "core", "en");
    Translator.add("core.errormessages.request.denied", "Access to request denied", "core", "en");
    Translator.add("core.message.title", "Error", "core", "en");
})(Translator);
