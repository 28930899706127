$(function () {
    var context = pwNamespace('CMS.GLOBALTYPECATEGORIES');
    context.CategoriesList = (function () {
        var instance = null;

        var injection = function() {
            return {}
        };

        var initInstance = function() {
            var me = {
                categoriesTabId: "#globaltypecategoriestab"
                ,globalTypeCategoryClass: ".js-globaltypecategories-category"
                ,categoriesListId: "#globaltypecategorieslist"
                ,addCategoryButtonClass: '.js-globaltypecategories-add-button'
                ,categoryCount: 1
                ,editMode: false
                ,translationContextObj: context.TranslationList.getInstance()
                ,globalTypeContextObj: context.GlobalTypeList.getInstance()
                ,loader: Loader.getInstance()

                ,init: function() {
                    me.loadData();
                    me.addEventListeners();
                }

                ,loadData: function() {
                    me.loader.startLoader("footer", $('.footer'), ["loadCategories"]);
                    $.ajax({
                        url: Routing.generate('cms_globaltypecategories_list_ajax')
                        ,method: 'GET'
                        ,beforeSend: function(e, xhr, options) {
                            e.loaderKey = "loadCategories";
                            me.loader.startLoader(e.loaderKey, $('.js-cmsglobaltypecategories .pw-tablebufferwrapper'));
                        }
                    })
                    .done(function(data) {
                        if (data.data) {
                            $.each(data.data, function(key, value) {
                                me.handleLoadGlobalTypeCategory(key, value);
                            })
                        }
                    });
                }

                ,addEventListeners: function() {
                    $('body').on('click', me.addCategoryButtonClass, me.handleAddCategory)
                        .on('click', me.globalTypeCategoryClass, me.handleActivateCategory)
                        .on('click', '.save-btn', me.handleSaveCategory)
                        .on('click', '.edit-btn', me.handleEditCategory)
                        .on('click', '.delete-btn', me.handleDeleteCategory)
                        .on('click', '.reset-btn', me.handleUndoCategory)
                        .on('submit', '#cms-categories', me.handleSubmitForm)
                        .on('keyup', '.js-globaltypecategories-input', me.handleChangeCategory)
                    ;
                }

                ,handleSubmitForm: function(e) {
                    e.preventDefault();

                    var categoriesTabContainer = $('#globaltypecategoriestab')
                        ,parent = categoriesTabContainer.parents('.js-cmsglobaltypecategories')
                        ,dataContent = categoriesTabContainer.find('.js-globaltypecategories-category')
                        ,sendData = true
                        ,saveData = [];

                    $.each(dataContent, function(key, value) {
                        var globaltype = $(value).attr('data-globaltype');

                        saveData[key] = {
                            "CategoryNames": $(value).attr('data-category')
                            ,"GlobalTypeList": globaltype
                        };
                    });
                    if (sendData) {
                        me.loader.startLoader("footer", $('.footer'), ["saveCategories"]);
                        $.ajax({
                            url: Routing.generate('cms_globaltypecategories_save_list_ajax')
                            ,method: 'POST'
                            ,data: { 'savedata':saveData }
                            ,beforeSend: function(e, xhr, options) {
                                e.loaderKey = "saveCategories";
                                me.loader.startLoader(e.loaderKey, $('.js-cmsglobaltypecategories .pw-tablebufferwrapper'));
                            }
                        })
                        .done(function(data) {
                            me.showMessages('globaltypecategories.messages.success.title'
                                ,'globaltypecategories.messages.success.saved.text'
                                ,'success');
                            if (data.data) {
                                $.each(data.data, function(key, value) {
                                    me.handleLoadGlobalTypeCategory(key, value);
                                })
                            }
                        });
                    }

                }

                ,resetLists: function() {
                    var categoriesTabContainer = $('#globaltypecategoriestab')
                        ,parent = categoriesTabContainer.parents('.js-cmsglobaltypecategories');
                    categoriesTabContainer.find('.dd-list').html(null);
                    parent.find('#globaltypecategories-translations-tab .dd-list').html(null);
                    parent.find('#globaltypecategories-globaltypes-tab .dd').html(null);
                }

                ,showMessages: function(title, text, type) {
                    swal({
                        title: Translator.trans(title, {}, 'cms')
                        ,type: type
                        ,text: Translator.trans(text, {}, 'cms')
                        ,html: true
                        ,customClass: 'fe-message__swal'
                    });
                }

                ,isEmptyGlobaltype: function(globaltype) {
                    var list = $.parseJSON(globaltype)
                        ,isEmpty = false;

                    if (list.length === 0) {
                        return true;
                    }
                    $.each(list, function(key, value) {
                        if (value.hasOwnProperty('GT') &&  value.GT === '' ) {
                            isEmpty = true;
                            return false;
                        }
                    });
                    return isEmpty;
                }

                ,handleUndoCategory: function(e) {
                    e.preventDefault();
                    var button = $(this)
                        ,parent = button.parents('.flexform__row').find('.js-globaltypecategories-category-content')
                        ,span = parent.find('span.js-globaltypecategories-category-content-title')
                        ,input = parent.find('input')
                        ,originalValue = button.attr('data-original');
                    if (input.val() !== originalValue) {
                        input.val(originalValue);
                        span.text(originalValue);
                    }
                }

                ,handleDeleteCategory: function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    var button = $(e.currentTarget)
                        ,parent = button.parents('.js-globaltypecategories-category');

                    me.handleEditMode(false);
                    //reset tranlation list if and only if is active
                    if (parent.hasClass('active')) {
                        me.translationContextObj.resetTranslationsList();
                        me.globalTypeContextObj.resetGlobalTypesList();
                    }
                    parent.remove();
                    me.handleDeactivateTranslationButton(true);
                    me.handleDeactivateGlobalTypeButton(true);
                }

                ,handleActivateCategory: function(e) {
                    e.preventDefault();
                    if (true === me.editMode) {
                        return;
                    }
                    var categoryItem = $(e.currentTarget)
                        ,gts = $.parseJSON(categoryItem.attr('data-globaltype'))
                        ,id = categoryItem.data('id');

                    categoryItem.parent().find('li').removeClass('active');
                    categoryItem.addClass('active');
                    me.handleDeactivateTranslationButton(false);
                    me.handleDeactivateGlobalTypeButton(false);

                    me.globalTypeContextObj.resetGlobalTypesList();
                    me.globalTypeContextObj.loadGlobalTypes(id, gts);
                    me.translationContextObj.resetTranslationsList();

                    //load translations
                    $.each($.parseJSON(categoryItem.attr('data-category')), function(key, value) {
                        me.translationContextObj.loadTranslation(value, categoryItem.attr('data-id'));
                    });
                    me.handleEditMode(false);
                }

                ,handleDeactivateTranslationButton: function(isDisabled) {
                    var translationButton = $('.js-globaltypecategories-translations-add-button');
                    translationButton.attr('disabled', isDisabled);
                }

                ,handleDeactivateGlobalTypeButton: function(isDisabled) {
                    var translationButton = $('.js-globaltypecategories-globaltypes-add-button');
                    translationButton.attr('disabled', isDisabled);
                }

                ,handleEditCategory: function(e) {
                    var button = $(e.currentTarget)
                        ,prevContainer = button.parent().prev()
                        ,span = prevContainer.find('span.js-globaltypecategories-category-content-title')
                        ,buttonEditSaveIcon = button.find('span')
                        ,deleteButton = button.next()
                        ,buttonDeleteResetIcon = deleteButton.find('span')
                        ,input = prevContainer.find('input')
                        ,parent = button.parents('.js-globaltypecategories-category')
                        ,list = parent.parent();

                    span.hide();
                    input.show();
                    buttonEditSaveIcon.removeClass('fa-edit').addClass('fa-save');
                    buttonDeleteResetIcon.removeClass('fa-trash').addClass('fa-undo');
                    button.removeClass('edit-btn').addClass('save-btn');
                    deleteButton.removeClass('delete-btn').addClass('reset-btn');
                    me.handleEditMode(true);
                    list.find('.js-globaltypecategories-category').removeClass('active');
                    parent.addClass('active');
                    input.focus();
                    me.handleDeactivateTranslationButton(true);
                    me.handleDeactivateGlobalTypeButton(true);
                    me.translationContextObj.resetTranslationsList();

                    //load translations
                    $.each($.parseJSON(parent.attr('data-category')), function(key, value) {
                        me.translationContextObj.loadTranslation(value, parent.attr('data-id'));
                    });
                }

                ,handleSaveCategory: function(e) {
                    var button = $(e.currentTarget)
                        ,divContentContainer = button.parent().prev()
                        ,liContentContainer = divContentContainer.parents('.js-globaltypecategories-category')
                        ,input = divContentContainer.find('input');

                    if ('' === input.val()) {
                        return;
                    }

                    //update english name of the category
                    updatedCategoriesJson = $.parseJSON(liContentContainer.attr('data-category'));
                    updatedCategoriesJson[0].Name = input.val();
                    liContentContainer.attr('data-category', JSON.stringify(updatedCategoriesJson).replace(/'/g, "\\'"));

                    me.switchToViewMode(e);
                    me.handleEditMode(false);
                    me.handleDeactivateTranslationButton(false);
                    me.handleDeactivateGlobalTypeButton(false);
                }

                ,switchToViewMode: function(e) {
                    var button = $(e.currentTarget)
                        ,divContentContainer = button.parent().prev()
                        ,span = divContentContainer.find('span')
                        ,input = divContentContainer.find('input')
                        ,buttonEditSaveIcon = button.find('span')
                        ,resetButton = button.next()
                        ,buttonDeleteResetIcon = resetButton.find('span')
                    ;
                    span.show();
                    input.hide();
                    buttonEditSaveIcon.removeClass('fa-save').addClass('fa-edit');
                    buttonDeleteResetIcon.removeClass('fa-undo').addClass('fa-trash');
                    button.removeClass('save-btn').addClass('edit-btn');
                    resetButton.removeClass('reset-btn').addClass('delete-btn');
                }

                ,handleChangeCategory: function(e) {
                    var input = $(e.currentTarget)
                        ,span = input.parent().find('span.js-globaltypecategories-category-content-title');
                    span.text(input.val());
                }

                ,handleLoadGlobalTypeCategory: function(key, globalTypeCategory) {
                    var div = $('#globaltypecategorieslist')
                        ,list = div.find('.dd-list');
                    if (key === 0) {
                        me.resetLists();
                    }
                    if (globalTypeCategory.CategoryNames.length > 0) {
                        $.each(globalTypeCategory.CategoryNames, function(key, value) {

                            if ('en' === globalTypeCategory.CategoryNames[key].Language) {
                                var rowCategory = '<div class="flexform"><div class="flexform__row"><div class="flexform__shrink">' +
                                    '<a class="btn btn-xs btn-default handle-btn" type="button"><span class="fa fa-reorder">' +
                                    '</span></a></div><div class="flexform__grow globaltypecategories-category-content js-globaltypecategories-category-content">' +
                                    '<input class="form-control input-sm globaltypecategories-input js-globaltypecategories-input" value="' +
                                    globalTypeCategory.CategoryNames[key].Name +'">' +
                                    '<span class="js-globaltypecategories-category-content-title">' + globalTypeCategory.CategoryNames[key].Name + '</span>' + '<br/>' +
                                    '<span class="globaltypecategories-category-content-subtitle">ID: ' + globalTypeCategory.CategoryNames[key].Id + '</span>' +
                                    '</div><div class=" flexform__shrink globaltypecategorieslist--buttongroup">' +
                                    '<button class="btn btn-xs btn-default edit-btn" type="button"><span ' +
                                    'class="fa fa-edit"></span></button><button data-original="' +
                                    globalTypeCategory.CategoryNames[key].Name + '" class="btn btn-xs btn-default ' +
                                    'delete-btn" type="button"><span class="fa fa-trash"></span></button></div></div></div>';

                                var li = $( "<li>", {
                                        'class': "dd-item globaltypecategories-category js-globaltypecategories-category"
                                        ,'data-id': me.categoryCount
                                        ,'data-category': JSON.stringify(globalTypeCategory.CategoryNames).replace(/'/g, "\\'")
                                        ,'data-globaltype': JSON.stringify(globalTypeCategory.GlobalTypeList).replace(/'/g, "\\'")
                                    })
                                    .append(
                                        $(rowCategory)
                                    )
                                    .appendTo(list)
                                    ;
                                div.nestable({
                                    group:1
                                    ,maxDepth:1
                                    ,handleClass: 'handle-btn'
                                });
                                me.categoryCount++
                            }
                        });
                    }
                }

                ,handleAddCategory: function() {
                    me.handleEditMode(true);
                    me.handleDeactivateTranslationButton(true);
                    me.handleDeactivateGlobalTypeButton(true);
                    me.translationContextObj.resetTranslationsList();
                    me.globalTypeContextObj.resetGlobalTypesList();
                    var div = $('#globaltypecategorieslist')
                        ,list = div.find('.dd-list');

                    var rowCategory = '<div class="flexform"><div class="flexform__row"><div class="flexform__shrink"><a class="btn btn-xs btn-default handle-btn" type="button"><span class="fa fa-reorder"></span></a></div>' +
                        '<div class="flexform__grow globaltypecategories-category-content">' +
                        '<input style="display:block" autofocus class="globaltypecategories-input js-globaltypecategories-input">' +
                        '<span hidden  class="js-globaltypecategories-category-content-title"></span></div>' +
                        '<div class="globaltypecategorieslist--buttongroup flexform__shrink"><button class="btn btn-xs btn-default save-btn" type="button" data-original="save"><span class="fa fa-save"></span></button>' +
                        '<button class="btn btn-xs btn-default delete-btn" type="button"><span class="fa fa-trash"></span></button></div>' +
                        '</div></div>';
                    div.find('li').removeClass('active');

                    var li = $( "<li>", {
                        'class': "dd-item globaltypecategories-category js-globaltypecategories-category active"
                        ,'data-id': me.categoryCount
                        ,'data-category': JSON.stringify([{Name: '', Language: 'en', Country: 'GB'}]).replace(/'/g, "\\'")
                        ,'data-globaltype': JSON.stringify([]).replace(/'/g, "\\'")
                    })
                        .append(
                            $(rowCategory)
                        )
                        .appendTo(list)
                    ;
                    list.find("[data-id='"+ me.categoryCount +"']").find('input').focus();
                    div.nestable({
                        group:1
                        ,maxDepth:1
                        ,handleClass: 'handle-btn'
                    });
                    me.categoryCount++
                }

                ,handleEditMode: function(isDisabled) {
                    me.editMode = isDisabled;
                    var addButton = $('.js-globaltypecategories-add-button');
                    var editButtons = $('#globaltypecategorieslist').find('.edit-btn');
                    var deleteButtons = $('#globaltypecategorieslist').find('.delete-btn');
                    editButtons.attr('disabled', isDisabled);
                    addButton.attr('disabled', isDisabled);
                    deleteButtons.attr('disabled', isDisabled);
                }
                
                ,updateGlobalTypes: function(globalTypes) {
                    var div = $('#globaltypecategorieslist')
                        ,list = div.find('.dd-list')
                        ,activeListItem = list.find('li.active');
                    activeListItem.attr('data-globaltype', JSON.stringify(globalTypes).replace(/'/g, "\\'"));
                }

            };
            /* public */
            var baseObj = {
                'updateGlobalTypes' : me.updateGlobalTypes,
                'updateCategoriesList': me.loadData
            };

            /* attach public functions to singleton */
            $.each(injection.call(me, me), function(idx, prop) {
                baseObj[idx] = prop;
            });

            /* execute init method */
            me.init();

            return baseObj;
        };

        return {
            inject: function(inject) {
                injection = inject;
            }
            ,getInstance: function() {
                if (instance === null) {
                    instance = initInstance();
                }
                return instance;
            }
        }
    })();
});

$(function() {
    if ($('.js-cmsglobaltypecategories').length > 0) {
        CMS.GLOBALTYPECATEGORIES.CategoriesList.getInstance();
    }
});
