var UserManagementController = (function(){
    var instance;

    function createInstance() {

        return {
            init: function(){
                initDatatable();
            }
        };
    }
    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }

            return instance;
        }
    };
})();

$(function() {
    if(0 < $('.cmsum--list').length) {
        UserManagementController.getInstance().init();
    }
});