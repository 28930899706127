/**
 *
 * AbstractGiataSearchComponent 
 *
 */
(function(){
var ctx = pwNamespace('PEAKWORK.STORECMS.GIATA.GEODATA.COMPONENTS');

ctx.AbstractGiataSearchComponent = function() {
    if (this.constructor === ctx.AbstractGiataSearchComponent) {
        throw new Error('Cannot instanciate abstract class');
    }
    this.objectHandler = SearchObjectHandlerController.getInstance();
    this.options = {
        wrapperClassName: ''
        ,inputId: ''
        ,data:''
        ,chain:''
    };

    if (arguments[0] && typeof arguments[0] === "object") {
        this.options = this.extendDefaults(this.options, arguments[0]);
    }
    this.init();

};
ctx.AbstractGiataSearchComponent.prototype = {
    constructor : ctx.AbstractGiataSearchComponent
    ,init: function(data) {
        if(data) {
            this.options.data = data;
        }
        var wrapper = $(this.options.wrapperClassName)
            ,input = wrapper.find(this.options.inputId)
            ,ddlist = wrapper.find('.dd-list');
        this.resetSearchList();
        ddlist.html('');
        this.appendDataAttrToList(ddlist);
        input.val('');
        input.removeAttr('disabled');
    }
    ,reset: function() {
        var wrapper = $(this.options.wrapperClassName)
            ,input = wrapper.find(this.options.inputId);
        input.val('');
        input.prop('disabled',true);
        this.resetSearchList();
    }
    ,resetSearchList: function() {

    }
    ,appendDataAttrToList: function(ddlist) {
        if(this.options.data) {
            $.each(this.options.data, function(k,v){
                ddlist.attr('data-' + k, v);
            });
        }
    }
    ,addAutocompleteEvents : function() {

    }
    ,addButtonEvents : function(ddList) {
        var self = this;
        ddList.find('.dd-handle').on('click', function(e) {
            self.onClickItem(self, e);
        });
    }
    ,onClickItem: function(obj, e) {
        var liItem =$(e.currentTarget).parent();
        liItem.parent().find('.dd-item').removeClass('active');
        liItem.addClass('active');
    }
    ,extendDefaults : function(source, properties) {
        var property;
        for (property in properties) {
            if (properties.hasOwnProperty(property)) {
                source[property] = properties[property];
            }
        }
        return source;
    }
    ,getInputId : function(){
        return this.options.inputId;
    }
    ,initNewSearchListController: function(type, routId, component, data){
       if(!this.objectHandler.hasController(type)) {
           this.objectHandler.addController(
               new GiataGeoDataSearchListController({
                   type:type
                   ,routeId:routId
                   ,component: component
                   ,data: data
               }),
               type
           );
       } else {
           if(this.options.chain) {
               var count = 0;
               for (var chainType in this.options.chain) {
                   var searchListController= this.objectHandler.getController(this.options.chain[chainType]);
                   if(searchListController) {
                       if(count!==0) {
                           searchListController.deactivate();
                           continue;
                       }
                       searchListController.reset(data);
                   }
                   count++;
               }
           }
       }
    }
    ,deactivateChildren: function() {
        if(this.options.chain) {
            for (var chainType in this.options.chain) {
                var object = this.objectHandler.getController(this.options.chain[chainType]);
                if(object) {
                    object.deactivate();
                }
            }
        }
    }
    ,addCostumButton : function(buttontype, searchtype, position) {
        var iconClass = ''
            ,buttonClass = '';
        if(!buttontype || !searchtype) {
            throw new Error('Buttontype and Searchtype must be set!');
        }

        if (buttontype === 'edit') {
            iconClass = 'fa fa-edit';
            buttonClass = 'btn btn-default btn-xs edit-' + searchtype + '-item-btn ' + position;
        } else if (buttontype === 'remove') {
            iconClass = 'fa fa-times';
            buttonClass = 'btn btn-default btn-xs remove-' + searchtype + '-item-btn ' + position;
        }
        return $('<a/>', {'class':buttonClass})
            .append($('<span/>', {'class':iconClass}))
    }
};
})();
