var ConfigUnsavedChangesController = (function() {

    var instance;

    function createInstance() {

        var unsavedChangesList = []
            ,addedBeforeUnload = false;

        var registerMultipleUnsavedChanges = function (elements, action, panel) {
            $.each(elements, function() {
                $(this).on(action, function() {
                    registerUnsavedChanges(panel);
                })
            });
        };

        var addBeforeUnLoad = function() {
            if (!addedBeforeUnload) {
                addedBeforeUnload = true;
                $(window).on('beforeunload', function() {
                    return false;
                });
            }
        }

        var removeBeforeUnload = function() {
            if (addedBeforeUnload) {
                addedBeforeUnload = false;
                $(window).off('beforeunload');
            }
        }

        var unsavedChangeAlreadyExist = function(unsavedChange){
            if (unsavedChangesList.indexOf(unsavedChange) !== -1) {
                return true;
            }
            return false;
        };

        var registerUnsavedChanges = function(unsavedChanges) {
            if (!unsavedChangeAlreadyExist(unsavedChanges)) {
                addBeforeUnLoad();
                unsavedChangesList.push(unsavedChanges);
            }
        };

        var unsavedChanges = function() {
            if (unsavedChangesList.length > 0) {
                $.each(unsavedChangesList, function(k,v) {
                    var panel = $(v)
                        ,title = panel.find('.panel-heading').text();
                    if (panel.find('.unsavedcontentpop').length == 0) {
                        panel.css('border-color', 'red');
                        toastr.error("There are edited Elements in <span style='color:red'>'" +
                            title + "'</span> on this page. Changes will be lost if left unsaved! ", "Attention");
                        var messagePop = $('<div/>',{
                            class: "alert alert-danger unsavedcontentpop",
                            text: " "+Translator.trans('contentstore.general.feedback.unsavedcontent', {}, 'cms'),
                            "data-panel": v,
                            click: removeSingleRegisteredUnsavedChanges
                        }).prepend($('<i/>',{ class: 'fa fa-warning' }));
                        panel.find('.panel-heading').after(messagePop);
                    }
                });
                return false;
            }
            return true;
        };

        var removeSingleRegisteredUnsavedChanges = function() {
            var self = $(this);
            self.parent().css('border-color', '#ddd');
            self.slideUp(100, function(){
                $(this).remove();
            });
            unsavedChangesList.splice(unsavedChangesList.indexOf(self.data('panel')), 1);
            removeBeforeUnload();
            return false;
        };

        var removeRegisteredUnsavedChanges = function() {
            $.each(unsavedChangesList, function(k,v) {
                $(v).css('border-color', '#ddd');
                $('.unsavedcontentpop').slideUp(100, function(){
                    $(this).remove();
                });
            });
            unsavedChangesList = [];
            removeBeforeUnload();
        };

        return {
            init: function() {
            }
            ,registerMultipleUnsavedChanges: function(elements, action, panel) {
                return registerMultipleUnsavedChanges(elements, action, panel);
            }
            ,registerUnsavedChanges: function(unsavedChanges) {
                return registerUnsavedChanges(unsavedChanges);
            }
            ,unsavedChanges: function() {
                return unsavedChanges();
            }
            ,removeSingleRegisteredUnsavedChanges: function() {
                return removeSingleRegisteredUnsavedChanges();
            }
            ,removeRegisteredUnsavedChanges : function() {
                return removeRegisteredUnsavedChanges();
            }
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();