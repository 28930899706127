/**
 * Manages Twin View/New/Edit Text Field Panel and respective options
 *
 */
var ContentTextsController;
ContentTextsController = (function () {

    var createInstance;
    var instance;

    var $container
        ,internalSupplierLanguagesOnLoad = false
        ,internalSupplierCode
        ,internalSupplierKey
        ,sysLangs
        ,sysLangsCount
        ,isGroupDetail
        ,key
        ,supplier
        ,productCode
        ,groupId
        ,$langLists = {}
        ,langLists = {}
        ,langListCurrentSupplier = {}
        ,supplierLanguages
        ,$langListShowAll
        ,$langContainer = {}
        ,textCache = {}
        ,textCacheKeys = []
        ,$textareas = {}
        ,$categoryLists = {}
        ,categoryLists = {}
        ,$supplierLists = {}
        ,supplierLists = {}
        ,intTexts = false
        ,changedTextFlag = false
        ,unsavedChangesHandler
        ,$saveButton
        ,loader
        ,activeSummernoteContainer = false
        ,internalSupplier = getInternalSupplier();

    var o = {
        watchSelectors: [ '#EditCompareText', '#EditSingleText', '.detailstext__closebutton', '#cmscontentstoreproducttable .edit-btn', '#cmscontentstoreproducttable .edit-group-btn', '#contentstore-filter-form button' ]
        ,defaultCategory: 'Hotel'
        ,internalSupplier: internalSupplier
        ,internalSupplierAdd: '+ ' + internalSupplier
        ,maxTextCacheLength: 10
        ,dataTextContainer: 'textcontainerelement'
        ,containerJsClass: 'js-detailstext'
        ,saveButtonClass: 'detailstext__savebutton'
        ,saveButtonDisabledClass: 'btn-default'
        ,saveButtonEnabledClass: 'btn-primary'
        ,closeButtonJsClass: 'js-detailstext__closebutton'
        ,containerDataIsGroupDetail: 'isgroup'
        ,containerDataKey: 'key'
        ,containerDataGroupId: 'groupid'
        ,containerDataSupplier: 'supplier'
        ,containerDataProductCode: 'productcode'
        ,containerDataInternalCode: 'internalcode'
        ,containerDataInternalKey: 'internalkey'
        ,containerDataSupplierLangListData: 'supplierlanglist'
        ,detailsShowCompareClass: 'js-detailstextcompare__input'
        ,originalContainer: 'js-detailstext__textcontainer--original'
        ,compareContainer: 'js-detailstext__textcontainer--compare'
        ,showCompareClass: 'detailstext__textcontainer--showcompare'
        ,originalLangList: 'original'
        ,compareLangList: 'compare'
        ,langListContainerClass: 'js-langcat-textselection__languages'
        ,langListSupplierButtonClass: 'js-langcat-textselection__supplierbutton'
        ,langListSupplierButtonActiveClass: 'langcat-textselection__supplierbutton--active'
        ,langListJsClass: 'js-langcat__langlist'
        ,langListItemJsClass: 'js-langcat__langlistitem'
        ,langlistItemCodeClass: 'langlist__code'
        ,langListShowAllClass: 'js-langcat-textselection__showallinput'
        ,langListDataAvailable: 'available'
        ,langListDataActive: 'active'
        ,langListTemplate: '<li class="listjs__listitem langcat__langlistitem js-langcat__langlistitem" data-active="" data-available=""><span class="langlist__code"></span></li>'
        ,catListContainerClass: 'js-langcat__categorycontainer'
        ,catListJsClass: 'js-langcat__categorylist'
        ,catListItemJsClass: 'js-langcat__catlistitem'
        ,catListItemValueClass: 'langcat__catbutton'
        ,catListItemActiveData: 'active'
        ,catListItemCategoryData: 'category'
        ,catListItemLangCodeData: 'langcode'
        ,catListItemButtonJsClass: 'js-langcat__catbutton'
        ,catListTemplate: '<li class="langcat__catlistitem js-langcat__catlistitem" data-langcode="" data-category="" data-active=""><button class="btn btn-default btn-xs langcat__catbutton js-langcat__catbutton"></button></li>'
        ,supplierListContainerJsClass: 'js-langcat-textselection__supplier'
        ,supplierListJsClass: 'js-langcat__supplierlist'
        ,supplierListItemJsClass: 'js-langcat__supplierlistitem'
        ,supplierListItemValueClass: 'langcat-textselection__supplierbutton'
        ,supplierListTemplate: '<li class="langcat__supplierlistitem js-langcat__supplierlistitem" data-active="" data-supplier="" data-listselection="" data-intactive=""><button class="btn btn-primary btn-outline btn-sm langcat-textselection__supplierbutton js-langcat-textselection__supplierbutton"></button></li>'
        ,supplierListItemActiveData: 'active'
        ,supplierListItemSupplierData: 'supplier'
        ,supplierListItemIntActiveData: 'intactive'
        ,supplierListItemListSelectionData: 'listselection'
        ,textareaBaseClass: 'langcat__text'
        ,textareaDisabledClass: 'langcat__text--disabled'
        ,textAreaLanguageCodeData: 'languagecode'
        ,textAreaChangedData: 'changed'
        ,summernoteClass: 'js-langcat__summernote'
        ,editableTextareaClass: 'js-editable-text'
        ,activesummernoteToggleClass: 'js-detailstext-activesummernote'
        ,deleteIntTextButtonClass: 'js-detailstext__textcontainer--original .js-delete-int-text'
        ,deleteAllIntTextButtonClass: 'js-detailstext__textcontainer--original .js-delete-all-INT-texts'
    };

    createInstance = function () {

        function initEvents() {
            var clickClasses = '.' + o.langListItemJsClass
                + ', .' + o.langListSupplierButtonClass
                + ', .' + o.catListItemButtonJsClass
                + ', .' + o.saveButtonClass
                + ', .' + o.closeButtonJsClass
                + ', .' + o.deleteIntTextButtonClass
                + ', .' + o.deleteAllIntTextButtonClass;

            var changeClasses = '.' + o.langListShowAllClass
                + ', .' + o.detailsShowCompareClass + ', .' + o.activesummernoteToggleClass;

            $container.on('click', clickClasses, handleClickEvents);
            $container.on('change', changeClasses, handleChangeEvents);
        }

        function handleClickEvents(e) {
            var $target = $(e.currentTarget);
            if ($target.hasClass(o.langListItemJsClass)) {
                handleLanguageClick($target);
            }
            if ($target.hasClass(o.catListItemButtonJsClass)) {
                handleCategoryClick($target);
            }
            if ($target.hasClass(o.langListSupplierButtonClass)) {
                handleSupplierButtonClick($target);
            }
            if ($target.hasClass(o.saveButtonClass)) {
                handleSaveButtonClick();
            }
            if ($target.hasClass(o.closeButtonJsClass)) {
                handleCloseButtonClick();
            }
            if ($target.hasClass('js-delete-int-text')) {
                handleDeleteLanguageClick();
            }
            if ($target.hasClass('js-delete-all-INT-texts')) {
                handleDeleteAllINTLanguagesClick();
            }
        }

        function handleDeleteAllINTLanguagesClick() {
            var languages = Object.assign({}, supplierLanguages[internalSupplier].languages);
            swal({
                    title:  Translator.trans('', {}, 'cms'),
                    text:  Translator.trans('contentstore.textdetails.deleteall.confirmtext', {}, 'cms') +' ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: Translator.trans('Yes', {}, 'cms'),
                    cancelButtonText: Translator.trans('No', {}, 'cms'),
                    closeOnConfirm: true
                },
                function(isConfirm) {
                    if (isConfirm !== true) {
                        return false;
                    } else {
                        doDeleteLanguage(languages)
                    }
                });
        }

        function handleDeleteLanguageClick() {
            var lang = getSelectedIntLanguage();
            if ('' !== lang) {
                var langObj = {};
                langObj[lang] = lang;
                swal({
                    title:  Translator.trans('', {}, 'cms'),
                    text:  Translator.trans('contentstore.textdetails.delete.confirmtext', {}, 'cms') + ' ' + lang +' ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: Translator.trans('Yes', {}, 'cms'),
                    cancelButtonText: Translator.trans('No', {}, 'cms'),
                    closeOnConfirm: true
                },
                function(isConfirm) {
                    if (isConfirm !== true) {
                        return false;
                    } else {
                        doDeleteLanguage(langObj)
                    }
                });
            } else {
                swal({
                    title:  Translator.trans('', {}, 'cms'),
                    text:  Translator.trans('contentstore.textdetails.delete.languagenotfound', {}, 'cms'),
                    type: 'warning',
                    showCancelButton: false,
                    closeOnConfirm: true
                });
            }
        }

        function getSelectedIntLanguage()
        {
            return $textareas['original'].data(o.textAreaLanguageCodeData);
        }

        function doDeleteLanguage(languages) {
            $.ajax(Routing.generate('cms_contentstore_delete_inttext'), {
                data: {
                    languages: languages,
                    key: $container.data(o.containerDataInternalKey) 
                },
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "deleteTexts";
                    loader.startLoader("deleteTexts", $container);
                }
            }).done(function (data) {
                intTexts = false;
                $.each(languages, function(k,v){
                    delete supplierLanguages[langListCurrentSupplier[o.originalLangList]].languages[v];
                });                
                $textareas[o.originalLangList].data(o.textAreaChangedData, false);
                switchSupplier(o.originalLangList, o.internalSupplier);
                deactiveAllTextAreaChanges();
                refreshLanguageOverview(languages);
            });
        }

        function refreshLanguageOverview(deletedLanguages) {
            var parentContainer = $('#TextCompareAndMasterForm')
                ,table = parentContainer.find('.contentdetail-transtable');
            $.each(table[0].tBodies[0].children, function(k,v) {
                var row = $(this)
                    ,supplier = row.data('supplier');
                if (supplier !== o.internalSupplier) {
                    return true;
                }
                $.each(row[0].cells, function() {
                    var cellObj = $(this);
                    var existingTextLangs = cellObj.data('exitstingtextlangs');
                    if (deletedLanguages.hasOwnProperty(existingTextLangs)) {
                        var span = cellObj.find('span');
                        span.removeClass('fa-check-circle contentdetail-transtable__lang--available');
                        span.addClass('fa-ban contentdetail-transtable__lang--notavailable');
                        span.removeAttr('title');
                    }
                });
            });
        }

        function handleChangeEvents(e) {
            var $target = $(e.target);
            if ($target.hasClass(o.langListShowAllClass)) {
                handleShowAllChange($target);
            }
            if ($target.hasClass(o.detailsShowCompareClass)) {
                handleShowCompareChange($target);
            }
            if ($target.hasClass(o.activesummernoteToggleClass)) {
                handleActiveSummernoteChange($target);
            }
        }

        function handleActiveSummernoteChange($target) {
            activeSummernoteContainer = $target.prop('checked');
            if ($target.prop('checked')) {
                $('.note-editor').show();
                $('.' + o.editableTextareaClass).hide();
            } else {
                $('.note-editor').hide();
                $('.' + o.editableTextareaClass).show();
            }
        }

        function handleCloseButtonClick() {
            CmsContentStore.getInstance().showEditCompareDetailsClickHandler();
        }

        function enableSaveButton() {
            if ($saveButton.prop('disabled')) {
                $saveButton.prop('disabled', false);
                $saveButton.removeClass(o.saveButtonDisabledClass);
                $saveButton.addClass(o.saveButtonEnabledClass);
            }
        }

        function disableSaveButton() {
            if (!$saveButton.prop('disabled')) {
                $saveButton.prop('disabled', true);
                $saveButton.removeClass(o.saveButtonEnabledClass);
                $saveButton.addClass(o.saveButtonDisabledClass);
            }
        }

        function handleSaveButtonClick() {
            var listSelection = o.originalLangList;
            if (langListCurrentSupplier[listSelection] === o.internalSupplier) {
                saveText(listSelection);
            }
            saveInternalTextsToBackend();
        }

        function saveInternalTextsToBackend() {
            var url;
            var internalTexts = {};
            for (lang in intTexts) {
                if (false === intTexts[lang] || '' === intTexts[lang]) {
                    continue;
                }
                if ('<p><br></p>' !== intTexts[lang][0] && '' !== intTexts[lang][0]) {
                    internalTexts[lang] = {};
                    internalTexts[lang][o.defaultCategory] = intTexts[lang];
                } else {
                    internalTexts[lang] = {};
                    internalTexts[lang][o.defaultCategory] = '';
                }
            }
            intTexts = {};

            if (internalSupplierKey) {
                url = Routing.generate('cms_contentstore_update_description');
                var data = new Object({
                    key: internalSupplierKey
                    ,tocode: o.internalSupplier
                    ,descriptions: internalTexts
                });
            } else {
                url = Routing.generate('cms_contentstore_create_edf_send');
                data = new Object({
                    data: {
                        "Name": $('.basicdata-detailchart').find('[data-name="name"]').text()
                        ,"dlc": $('.basicdata-detailchart').find('[data-name="airport"]').text()
                        ,"Id": groupId
                        ,"descriptions": internalTexts
                        ,"preventexistcheck": true
                        // "isgoal": isGoal
                    }
                });
            }

            saveToBackend(url, data)
                .done(function (data, textStatus) {
                    if (data.hasOwnProperty('_attributes')
                        && data._attributes.hasOwnProperty('success')
                        && data._attributes.success) {
                        toastr.success(
                            Translator.trans('contentstore.general.feedback.descriptions.saved', {}, 'cms')
                            ,Translator.trans('def.term.success', {}, 'cms')
                        );
                        deactivateTextareaChanges();
                    } else if (data.hasOwnProperty('_attributes')
                        && data._attributes.hasOwnProperty('key')
                        && data._attributes.key){
                        toastr.success(
                            Translator.trans('contentstore.general.feedback.descriptions.intcreated', {}, 'cms')
                            ,Translator.trans('def.term.success', {}, 'cms')
                        );
                        deactivateTextareaChanges();
                    } else {
                        toastr.error(
                            Translator.trans('contentstore.general.feedback.descriptions.notsaved', {}, 'cms')
                            ,Translator.trans('def.term.error', {}, 'cms')
                        );
                        switchSupplier(o.originalLangList, langListCurrentSupplier[o.originalLangList]);
                        return;
                    }

                    if (isGroupDetail) {
                        CmsContentStore.getInstance().loadProductDetailPageByGroupId(groupId, $('.cmscontentstore--productdetail'));
                    } else {
                        var supplierAndProductCode = extractSupplierAndProductCodeFromEDFKey(key);
                        CmsContentStore.getInstance().loadProductDetailPageByKey(
                            supplierAndProductCode.supplier,
                            supplierAndProductCode.productcode,
                            $('.cmscontentstore--productdetail')
                        );
                    }
                    activeSummernoteContainer = false;
                })
                .fail(function() {
                    toastr.error(
                        Translator.trans('contentstore.general.feedback.descriptionsnotsaved', {}, 'cms')
                        ,Translator.trans('def.term.error', {}, 'cms')
                    );
                    switchSupplier(o.originalLangList, langListCurrentSupplier[o.originalLangList]);
                });
        }

        function saveToBackend(url, data) {
            //cms_contentstore_update_description
            return $.ajax({
                method: "POST"
                ,url: url
                ,data: data
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "saveToBackend";
                    loader.startLoader("saveToBackend", $container);
                }
            });
        }

        function deactivateTextareaChanges() {
            changedTextFlag = false;
            unsavedChangesHandler.removeRegisteredUnsavedChange('.' + o.containerJsClass);
            disableSaveButton();
        }

        function deactiveAllTextAreaChanges() {
            changedTextFlag = false;
            unsavedChangesHandler.removeRegisteredUnsavedChanges();
            disableSaveButton();
        }

        function addInternalLanguage(languageCode) {
            if (!supplierLanguages.hasOwnProperty(o.internalSupplier)) {
                supplierLanguages[o.internalSupplier] = {
                    supplier: o.internalSupplier
                    ,code: internalSupplierCode
                    ,languages: {}
                };
            }
            if (!supplierLanguages[o.internalSupplier]['languages'].hasOwnProperty(languageCode)) {
                supplierLanguages[o.internalSupplier]['languages'][languageCode] = languageCode;
            }
            if (!intTexts) {
                intTexts = {};
            }
            if (!intTexts.hasOwnProperty(languageCode)) {
                intTexts[languageCode] = false;
            }
        }

        function saveInternalTexts(languageCode, data) {
            if (!intTexts) {
                intTexts = {};
            }
            //if (!intTexts.hasOwnProperty(languageCode)) {
                //intTexts[languageCode] = false;
            //}
            if (data.hasOwnProperty('description')
                && data.description.hasOwnProperty(o.defaultCategory)) {
                intTexts[languageCode] = '';
                intTexts[languageCode] = data.description[o.defaultCategory];
            }
        }

        function init(sysLanguages) {
            loader = Loader.getInstance();
            internalSupplierLanguagesOnLoad = false;
            intTexts = false;
            changedTextFlag = false;

            if (!sysLanguages) {
                return;
            }
            sysLangs = sysLanguages;
            sysLangsCount = sysLangs.length;

            $container = $('.' + o.containerJsClass);
            if ($container.length < 1) {
                return;
            }

            isGroupDetail = $container.data(o.containerDataIsGroupDetail) || false;
            key = $container.data(o.containerDataKey) || false;
            groupId = $container.data(o.containerDataGroupId) || false;
            supplier = $container.data(o.containerDataSupplier) || false;
            productCode = $container.data(o.containerDataProductCode) || false;
            $saveButton = $('.' + o.saveButtonClass);

            initUnsavedChanges();

            initLangList();
            initSupplierLists();
            initCategoryLists();
            initTextareas();

            initEvents();
        }

        function initUnsavedChanges() {
            if (typeof unsavedChangesHandler === 'undefined') {
                unsavedChangesHandler = UnsavedChangesController.getInstance();
                unsavedChangesHandler.init(o.watchSelectors);
            }
        }

        function initTextareas() {
            $textareas[o.originalLangList] = $('.' + o.textareaBaseClass + '--' + o.originalLangList, $langContainer[o.originalLangList]);
            $textareas[o.compareLangList] = $('.' + o.textareaBaseClass + '--' + o.compareLangList, $langContainer[o.compareLangList]);
        }

        function initSupplierLists() {
            $supplierLists[o.originalLangList] = $('.' + o.supplierListContainerJsClass, $langContainer[o.originalLangList]);
            $supplierLists[o.compareLangList] = $('.' + o.supplierListContainerJsClass, $langContainer[o.compareLangList]);

            var supplierListOptions = {
                valueNames: [
                    o.supplierListItemValueClass
                    ,{data: [o.supplierListItemActiveData]}
                    ,{data: [o.supplierListItemSupplierData]}
                    ,{data: [o.supplierListItemListSelectionData]}
                    ,{data: [o.supplierListItemIntActiveData]}
                ]
                ,listClass: o.supplierListJsClass
                , item: o.supplierListTemplate
            };
            if ($supplierLists[o.originalLangList].length > 0) {
                supplierLists[o.originalLangList] = new List($supplierLists[o.originalLangList][0], supplierListOptions);
            }
            if ($supplierLists[o.compareLangList].length > 0) {
                supplierLists[o.compareLangList] = new List($supplierLists[o.compareLangList][0], supplierListOptions);
            }

            if (isGroupDetail) {
                addSupplier($supplierLists[o.originalLangList]);
            }
        }

        function initCategoryLists() {

            $categoryLists[o.originalLangList] = $('.' + o.catListContainerClass, $langContainer[o.originalLangList]);
            $categoryLists[o.compareLangList] = $('.' + o.catListContainerClass, $langContainer[o.compareLangList]);

            var categoryListOptions = {
                valueNames: [
                    o.catListItemValueClass
                    ,{data: [o.catListItemActiveData]}
                    ,{data: [o.catListItemLangCodeData]}
                    ,{data: [o.catListItemCategoryData]}
                ]
                ,listClass: o.catListJsClass
                , item: o.catListTemplate
            };
            categoryLists[o.originalLangList] = new List($categoryLists[o.originalLangList][0], categoryListOptions);
            if ($categoryLists[o.compareLangList].length > 0) {
                categoryLists[o.compareLangList] = new List($categoryLists[o.compareLangList][0], categoryListOptions);
            }
        }

        function initLangList() {

            $langContainer[o.originalLangList] = $('.' + o.originalContainer, $container);
            if ($langContainer[o.originalLangList].length < 1) {
                return;
            }


            $langLists[o.originalLangList] = $('.' + o.langListContainerClass, $langContainer[o.originalLangList]);
            if ($langLists[o.originalLangList].length < 1) {
                return;
            }

            $langListShowAll = $('.' + o.langListShowAllClass);
            supplierLanguages = $container.data(o.containerDataSupplierLangListData);
            internalSupplierCode = $container.data(o.containerDataInternalCode) || false;
            internalSupplierKey = $container.data(o.containerDataInternalKey) || false;
            if (supplierLanguages.hasOwnProperty(o.internalSupplier)) {
                internalSupplierLanguagesOnLoad = supplierLanguages[o.internalSupplier]['languages'];
            }
            $langContainer[o.compareLangList] = $('.' + o.compareContainer, $container);
            $langLists[o.compareLangList] = $('.' + o.langListContainerClass, $langContainer[o.compareLangList]);

            var langListOptions = {
                valueNames: [
                    o.langlistItemCodeClass
                    ,{data: [o.langListDataAvailable]}
                    ,{data: [o.langListDataActive]}
                ]
                , listClass: o.langListJsClass
                , item: o.langListTemplate
            };
            langListCurrentSupplier[o.originalLangList] = $langLists[o.originalLangList].data('currentsupplier');
            langLists[o.originalLangList] = new List($langLists[o.originalLangList][0], langListOptions);
            addSupplierLanguages(o.originalLangList);

            if ($langLists[o.compareLangList].length > 0) {
                langListCurrentSupplier[o.compareLangList] = $langLists[o.compareLangList].data('currentsupplier');
                langLists[o.compareLangList] = new List($langLists[o.compareLangList][0], langListOptions);
                addSupplierLanguages(o.compareLangList);
            }
        }

        var filterAvailable = function (item) {
            return !!item._values.available;
        };

        function handleShowAllChange($target) {
            var listSelection = $target.data(o.dataTextContainer);
            switchShowAll(listSelection);
        }

        function handleShowCompareChange($target) {
            if ($target.prop('checked')) {
                $langContainer[o.compareLangList].addClass(o.showCompareClass);
            } else {
                $langContainer[o.compareLangList].removeClass(o.showCompareClass);
            }
        }

        function switchShowAll(listSelection) {
            if (!langLists.hasOwnProperty(listSelection)) {
                return;
            }
            if ($langListShowAll.filter('[data-' + o.dataTextContainer + '="' + listSelection + '"]').prop('checked')) {
                langLists[listSelection].filter();
            } else {
                langLists[listSelection].filter(filterAvailable);
            }
        }

        function handleLanguageClick($target) {
            var languageCode = $target.text();
            var listSelection = $target.closest('.' + o.langListJsClass).data(o.dataTextContainer);
            var listItem = langLists[listSelection].get(o.langlistItemCodeClass, languageCode);
            if (listItem.length < 1) {
                return;
            }
            listItem = listItem[0];
            setLanguage(listItem, listSelection, languageCode);
        }

        function handleCategoryClick($target) {
            var $listItem = $target.closest('.' + o.catListItemJsClass);
            var languageCode = $listItem.data(o.catListItemLangCodeData);
            var category = $listItem.data(o.catListItemCategoryData);
            var listSelection = $target.closest('.' + o.catListJsClass).data(o.dataTextContainer);

            setText(listSelection, languageCode, category);
        }

        function handleSupplierButtonClick($target) {
            var $listElement = $target.closest('.' + o.supplierListItemJsClass);
            var listSelection = $listElement.data(o.supplierListItemListSelectionData);
            var supplier = $listElement.data(o.supplierListItemSupplierData);
            switchSupplier(listSelection, supplier);
        }

        function setText(listSelection, languageCode, category) {
            retrieveTexts(listSelection, languageCode)
                .pipe(updateCache(listSelection, languageCode))
                .pipe(updateText(listSelection, languageCode, category))
                .done(function (success) {
                    if (success) {
                        setActiveLanguage(listSelection, languageCode);
                    }
                })
        }

        function setLanguage(listItem, listSelection, languageCode, category) {
            category = category || o.defaultCategory;
            languageCode = languageCode || false;
            if (!langLists.hasOwnProperty(listSelection) || !languageCode) {
                return;
            }
            if (!listItem.values().available) {
                setActiveLanguage(listSelection, languageCode);
                setCategories(listSelection, languageCode, [ o.defaultCategory ]);
                clearTextarea(listSelection, languageCode);
                return;
            }
            setText(listSelection, languageCode, category);
        }

        function updateCache(listSelection, languageCode) {
            return function (data) {
                if (langListCurrentSupplier[listSelection] === o.internalSupplier) {
                    saveInternalTexts(languageCode, data);
                    return data;
                }
                var cacheKey = getCacheKey(langListCurrentSupplier[listSelection], languageCode);
                if (!textCache.hasOwnProperty(cacheKey)) {
                    textCache[cacheKey] = data;
                    textCacheKeys.push(cacheKey);
                    if (textCacheKeys.length > o.maxTextCacheLength) {
                        var keyToRemove = textCacheKeys.shift();
                        delete textCache[keyToRemove];
                    }
                }
                return data;
            };
        }

        function updateText(listSelection, languageCode, category) {
            return function (data) {
                if (!data
                    || typeof data !== 'object'
                    || !data.hasOwnProperty('description')) {
                    return false;
                }
                var categories = Object.keys(data.description);
                if (categories.length == 0) {
                    categories = ['Hotel'];
                    data.description['Hotel'] = '';
                }
                setCategories(listSelection, languageCode, categories, category);
                if (data.description.hasOwnProperty(category)) {
                    setTextarea(listSelection, data.description[category], languageCode);
                }
                return true;
            }
        }

        function setTextarea(listSelection, text, languageCode) {
            var text = (text)? text.join('\n\n'): text;
            languageCode = languageCode || '';
            if ($textareas[listSelection].data(o.textAreaChangedData)) {
                saveText(listSelection);
            }
            $textareas[listSelection].off();
            $textareas[listSelection].val(text);
            $textareas[listSelection].data(o.textAreaLanguageCodeData, languageCode);
            $textareas[listSelection].data(o.textAreaChangedData, false);
            if (langListCurrentSupplier[listSelection] === o.internalSupplier
                && languageCode !== '') {
                $('.' + o.activesummernoteToggleClass).parent().show();
                hideTextarea(listSelection);
                activateSummernote(listSelection, text);
                initTextArea(listSelection, text);
                return;
            } else {
                $('.' + o.activesummernoteToggleClass).parent().hide();
            }
            showTextarea(listSelection);
        }

        function activateSummernote(listSelection, text) {
            resetSummernoteArea();
            destroySummernoteArea();
            showSummernoteArea(listSelection);
            initSummernoteArea(listSelection, text);
            if (!activeSummernoteContainer) {
                $('.note-editor').hide();
            }
        }

        function initTextArea(listSelection, text) {
            var editableTextArea = $('.' + o.editableTextareaClass);
            editableTextArea.val(text);
            editableTextArea.on('change', function(e) {
                $('.' + o.summernoteClass).summernote('code', editableTextArea.val());
                unsavedChangesHandler.registerUnsavedChanges('.' + o.containerJsClass);
            });
        }

        function initSummernoteArea(listSelection, text) {
            var summernoteObj = $('.' + o.summernoteClass);
            summernoteObj.summernote({
                focus: true
                ,callbacks: {
                    onInit: function() {
                        firstLoad = false;
                        $textareas[listSelection].off();
                        $textareas[listSelection].data(o.textAreaChangedData, true);
                        setActiveCategoryButton(listSelection, o.defaultCategory);
                        var languageCode = $textareas[listSelection].data(o.textAreaLanguageCodeData);
                        addInternalLanguage(languageCode);
                        var langOptions = {};
                        langOptions[o.langListDataAvailable] = true;
                        setActiveLanguage(listSelection, languageCode, langOptions);
                        // if ('' !== text.join('\n\n')) {
                            firstLoad = true;
                            $('.' + o.summernoteClass).summernote('code', text);
                        // }
                        enableSaveButton();
                    }
                    ,onChange: function(e) {
                        if (!firstLoad) {
                            $('.' + o.editableTextareaClass).val(e);
                            unsavedChangesHandler.registerUnsavedChanges('.' + o.containerJsClass);
                        }
                        firstLoad = false;
                    }
                }
                ,minHeight: 374
                ,toolbar: [
                    ['headline', ['style']]
                    ,['style', ['bold', 'italic', 'underline']]
                    ,['textsize', ['fontsize']]
                    ,['alignment', ['ul', 'ol', 'paragraph', 'lineheight']]
                ]
            });
        }

        function resetSummernoteArea() {
            var container = $('.' + o.summernoteClass).eq(0);
            if (container.length > 0) {
                container.summernote('reset');
            }
        }
        function destroySummernoteArea() {
            var container = $('.' + o.summernoteClass).eq(0);
            if (container.length > 0) {
                container.summernote('destroy');
            }
        }

        function hideTextarea(listSelection) {
            if (!$textareas[listSelection].prop('hidden')) {
                $textareas[listSelection].addClass('hidden');
                $textareas[listSelection].prop('hidden', true);
            }
        }

        function showTextarea(listSelection) {
            if ($textareas[listSelection].prop('hidden')) {
                $textareas[listSelection].removeClass('hidden');
                $textareas[listSelection].prop('hidden', false);
            }
        }

        function clearTextarea(listSelection, languageCode) {
            languageCode = languageCode || '';
            setTextarea(listSelection, [''], languageCode);
        }

        function showSummernoteArea() {
            summernoteArea = $('.js-langcat__summernote');
            if (summernoteArea.prop('hidden')) {
                summernoteArea.prop('hidden', false);
            }
        }

        function hideSummernoteArea() {
            summernoteArea = $('.js-langcat__summernote');
            if ('block' === summernoteArea.css('display')) {
                summernoteArea.hide();
            }
        }

        function retrieveTexts(listSelection, languageCode) {
            var defer;
            if (langListCurrentSupplier[listSelection] === o.internalSupplier
                && intTexts.hasOwnProperty(languageCode)
                && intTexts[languageCode]) {
                var intData = {description: {}};
                intData.description[o.defaultCategory] = [ intTexts[languageCode] ];
                defer = $.Deferred().resolve(intData);
                return defer.promise();
            }
            var cacheKey = getCacheKey(langListCurrentSupplier[listSelection], languageCode);
            if (textCache.hasOwnProperty(cacheKey)) {
                defer = $.Deferred().resolve(textCache[cacheKey]);
                return defer.promise();
            }
            return $.ajax(Routing.generate('cms_contentstore_get_description'), {
                data: {
                    "GroupId": groupId
                    , "TourOperatorCode": langListCurrentSupplier[listSelection]
                    , "language": languageCode
                    , "supplier": supplier
                    , "productCode": productCode
                },
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "retrieveTexts";
                    loader.startLoader("retrieveTexts", $langContainer[listSelection]);
                }
            });
        }

        function getCacheKey(supplier, languageCode) {
            if (!groupId) {
                return 'cache' + key + languageCode
            }
            return 'cache' + groupId + supplier + languageCode
        }

        function setCategories(listSelection, laguageCode, categories, activeCategory) {
            var item;
            categoryLists[listSelection].clear();
            categoriesCount = categories.length;
            for (var i = 0; i < categoriesCount; i++) {
                item = {};
                item[o.catListItemValueClass] = categories[i];
                item[o.catListItemCategoryData] = categories[i];
                item[o.catListItemLangCodeData] = laguageCode;
                item[o.catListItemActiveData] = false;
                if (activeCategory == categories[i]) {
                    item[o.catListItemActiveData] = true;
                }
                categoryLists[listSelection].add(item);
            }
            categoryLists[listSelection].sort(o.catListItemValueClass);
        }

        function clearCategories(listSelection) {
            categoryLists[listSelection].clear();
        }

        function switchSupplier(listSelection, supplier) {
            langListCurrentSupplier[listSelection] = supplier;
            if (langLists.hasOwnProperty(listSelection)) {
                clearTextarea(listSelection);
                resetSummernoteArea();
                destroySummernoteArea();
                hideSummernoteArea();
                clearCategories(listSelection);
                addSupplierLanguages(listSelection);
                setActiveSupplierButton(listSelection, supplier);
            }
            if (supplier === o.internalSupplier && listSelection === 'original') {
                activateDeleteAllINTLanguageButton();
            } else if (listSelection === 'original'){
                deactivateDeleteAllINTLanguageButton();
            }
            if (supplier !== o.internalSupplier ) {
                resetSummernoteArea();
                destroySummernoteArea();
                hideSummernoteArea();
            }
            if (listSelection === 'original') {
                deactivateDeleteLanguageButton();  
            }
        }

        function setActiveLanguage(listSelection, languageCode, change) {
            change = change || false;
            var options = {
                list: langLists[listSelection]
                ,dataActiveSelector: o.langListDataActive
                ,dataNewValueSelector: o.langlistItemCodeClass
                ,newValue: languageCode
            };
            if(change) {
                options.change = change;
            }
            setListItemActive(options);
            if (langListCurrentSupplier[listSelection] === o.internalSupplier && listSelection === 'original') {
                activateDeleteLanguageButton();
            } else if (langListCurrentSupplier[listSelection] !== o.internalSupplier && listSelection === 'original') {
                deactivateDeleteLanguageButton();
            }
        }

        function activateDeleteAllINTLanguageButton() {
            var deleteButton = $('.' + o.deleteAllIntTextButtonClass);
            if (deleteButton.hasClass('hidden')) {
                deleteButton.removeClass('hidden');
            }
        }

        function deactivateDeleteAllINTLanguageButton() {
            var deleteButton = $('.' + o.deleteAllIntTextButtonClass);
            if (!deleteButton.hasClass('hidden')) {
                deleteButton.addClass('hidden');
            }
        }

        function activateDeleteLanguageButton() {
            var deleteButton = $('.' + o.deleteIntTextButtonClass);
            if (deleteButton.hasClass('hidden')) {
                deleteButton.removeClass('hidden');
            }
        }

        function deactivateDeleteLanguageButton() {
            var deleteButton = $('.' + o.deleteIntTextButtonClass);
            if (!deleteButton.hasClass('hidden')) {
                deleteButton.addClass('hidden');
            }
        }

        function setActiveCategoryButton(listSelection, category) {
            var options = {
                list: categoryLists[listSelection]
                ,dataActiveSelector: o.catListItemActiveData
                ,dataNewValueSelector: o.catListItemCategoryData
                ,newValue: category
            };

            setListItemActive(options);
        }

        function setActiveSupplierButton(listSelection, supplier) {
            if (!supplierLists.hasOwnProperty(listSelection)) {
                return;
            }
            var options = {
                list: supplierLists[listSelection]
                ,dataActiveSelector: o.supplierListItemActiveData
                ,dataNewValueSelector: o.supplierListItemSupplierData
                ,newValue: supplier
            };
            if (supplier === o.internalSupplier
                && !supplierLanguages.hasOwnProperty(o.internalSupplier)) {
                options.change = {};
                options.change[o.supplierListItemIntActiveData] = true;
                options.change[o.supplierListItemValueClass] = o.internalSupplier;
            }
            setListItemActive(options);
        }

        function setListItemActive(a) {
            var oldActive = a.list.get(a.dataActiveSelector, true);
            if (oldActive.length > 0) {
                var oldActiveValues = oldActive[0].values();
                oldActiveValues.active = false;
                oldActive[0].values(oldActiveValues);
            }
            var newActive = a.list.get(a.dataNewValueSelector, a.newValue)[0];
            var newActiveValues = newActive.values();
            newActiveValues.active = true;

            if (a.hasOwnProperty('change')) {
                for (var key in a.change) {
                    if (!a.change.hasOwnProperty(key)) {
                        continue;
                    }
                    newActiveValues[key] = a.change[key];
                }
            }
            newActive.values(newActiveValues);
        }

        function addSupplier(originalContainer) {
            var item
                ,hasINT = false;
            for (var listSelection in supplierLists) {
                if (!supplierLists.hasOwnProperty(listSelection)) {
                    continue;
                }
                for (var supplier in supplierLanguages) {
                    if (!supplierLanguages.hasOwnProperty(supplier)) {
                        continue;
                    }
                    if (supplier === o.internalSupplier
                        && listSelection === o.compareLangList) {
                        continue;
                    }
                    item = {};
                    item[o.supplierListItemValueClass] = supplier;
                    item[o.supplierListItemActiveData] = false;
                    item[o.supplierListItemSupplierData] = supplier;
                    item[o.supplierListItemListSelectionData] = listSelection;
                    item[o.supplierListItemIntActiveData] = false;
                    if (supplier === langListCurrentSupplier[listSelection]) {
                        item[o.supplierListItemActiveData] = true;
                    }
                    if (supplier === o.internalSupplier) {
                        item[o.supplierListItemIntActiveData] = true;
                    }
                    supplierLists[listSelection].add(item);
                }
                if (!supplierLanguages.hasOwnProperty(o.internalSupplier)
                    && listSelection === o.originalLangList) {
                    // do not show INT button on goalkeeper
                    if ( 'central' !== originalContainer.data('servertype')) {
                        item = {};
                        item[o.supplierListItemValueClass] = o.internalSupplierAdd;
                        item[o.supplierListItemActiveData] = false;
                        item[o.supplierListItemIntActiveData] = false;
                        item[o.supplierListItemSupplierData] = o.internalSupplier;
                        item[o.supplierListItemListSelectionData] = listSelection;
                        supplierLists[listSelection].add(item);
                    }
                }
            }
        }

        function addSupplierLanguages(listSelection) {
            var item;
            if (!langLists.hasOwnProperty(listSelection)) {
                return;
            }
            if (langListCurrentSupplier[listSelection] !== o.internalSupplier
                && !supplierLanguages.hasOwnProperty(langListCurrentSupplier[listSelection])) {
                return;
            }
            langLists[listSelection].clear();
            var supplierLangs = {};
            if (supplierLanguages.hasOwnProperty(langListCurrentSupplier[listSelection])) {
                supplierLangs = supplierLanguages[langListCurrentSupplier[listSelection]]['languages'] || {};
            }
            for (var cCode in supplierLangs) {
                if (!supplierLangs.hasOwnProperty(cCode)) {
                    continue;
                }
                item = {};
                item[o.langListDataAvailable] = true;
                item[o.langListDataActive] = false;
                item[o.langlistItemCodeClass] = cCode;
                langLists[listSelection].add(item);
            }
            for (var i = 0; i < sysLangsCount; i++) {
                if (supplierLangs.hasOwnProperty(sysLangs[i])) {
                    continue;
                }
                item = {};
                item[o.langListDataAvailable] = false;
                item[o.langListDataActive] = false;
                item[o.langlistItemCodeClass] = sysLangs[i];
                langLists[listSelection].add(item);
            }
            langLists[listSelection].sort(o.langlistItemCodeClass);
            if (langListCurrentSupplier[listSelection] === o.internalSupplier
                && !supplierLanguages.hasOwnProperty(langListCurrentSupplier[listSelection]) ) {
                $langListShowAll.filter('[data-' + o.dataTextContainer + '="' + listSelection + '"]').prop('checked', true);
            }
            switchShowAll(listSelection);
        }

        function saveText(listSelection) {
            var languageCode = $textareas[listSelection].data(o.textAreaLanguageCodeData) || "";
            if (languageCode === "") {
                return;
            }
            addInternalLanguage(languageCode);
            if (activeSummernoteContainer) {
                intTexts[languageCode] = $('.' + o.summernoteClass).summernote('code');
            } else {
                intTexts[languageCode] = $('.' + o.editableTextareaClass).val();
            }
        }

        return {
            init: init
        };
    };

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }

            return instance;
        }
    };

})();
