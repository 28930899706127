/**
 * Created by kyoung on 16.06.16.
 */
var basicdatapoidatatable_ini = function() {
    var loader = Loader.getInstance();
    var loaderId = false;

    $('#cmspoidatatable')
        .on('draw.dt', function(e, settings) {
            $('#cmspoidatatable .edit-btn').each(function(k, v){
                $(v).on('click',BasicDataPoiDataController.getInstance().loadBasicDataPoiDataDetailPage );
            });
        })
        .on('xhr.dt', function ( e, settings, data ) {
            // if (data.hasOwnProperty('pw_fe_errors') && data.pw_fe_errors) {
            //     $.publish('cms.basic.messagehandler.show', { html: data.pw_fe_errors } );
            // }
            if(e.currentTarget.clickedPaging) {
                loader.remove(loaderId);
                loaderId = false;
            }
            e.currentTarget.clickedPaging = false;
        })
        .on( 'page.dt', function(e, settings) {
            if (!loaderId) {
                loaderId = loader.add($(e.delegateTarget));
            }
            e.currentTarget.clickedPaging = true;
        })
        .dataTable({
            ajax: {
                url: Routing.generate('cms_basicdata_poidata_list')
            },
            serverSide: true,
            lengthChange: true,
            searching: false,
            pagingType: 'simple_numbers',
            pageLength: 10,
            columns: [
                {data: 'id', name:'id', sortable: false},
                {data: 'name', name:'name', sortable: false},
                {data: 'cityname', name:'cityname', sortable: false},
                {data: 'subareaname', name:'subareaname', sortable: false},
                {data: 'regionname', name:'regionname', sortable: false},
                {data: 'countrycode' , name: 'countrycode', sortable: false},
                {data: 'themeid' , name: 'themeid', sortable: false},
                {data: 'highlight' , name: 'highlight', sortable: false},
                {data: 'topbeach' , name: 'topbeach', sortable: false},
                /*{data: function(row, type, set, meta) {
                    return  $( "<div/>", {
                        'class': "btn btn-default edit-btn",
                        'data-toggle': 'tooltip',
                        'data-original-title': Translator.trans('contentstore.producttable.edit', {}, 'cms'),
                        'data-key':row.id
                    }).append(
                        $( "<span/>", {class: "fa fa-edit" } )
                    )[0].outerHTML;
                }, sortable: false}*/
            ],
            language: {
                paginate: {
                    next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
                    previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
                },
                thousands: '.',
                info: Translator.trans('contentstore.producttable.info', {}, 'cms'),
                infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms'),
                emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
            },
            fnDrawCallback: function( oSettings ){
                var settings = this.fnSettings();

                if( settings.fnRecordsTotal() <= settings._iDisplayLength){
                    $('#cmscontentstoreproducttable_paginate').hide();
                }else{
                    $('#cmscontentstoreproducttable_paginate').show();
                }
            }
        });
};