var ConfigMainController = (function(){

    var instance
        ,loader
        ,anchorButtons = [];

    function createInstance() {

        var initGeneralConfig = function() {
            var langContainerExists = $('.js-cms-config-languages').length > 0
                ,preferredDescriptionContainerExists = $('.js-cms-config-languages').length > 0
                ,preferredImageContainerExists = $('.js-cms-config-preferred-images').length > 0
                ,imageCategoriesContainerExists = $('.js-cms-config-image-categories').length > 0
                ,supplierCodeMappingsContainerExists = $('.js-cms-config-suppliercode-mappings').length > 0
                ,supplierCodeMatchingsContainerExists = $('.js-cms-config-suppliercode-matchings').length > 0
                ,supplierWhitelistedGoalIdContainerExists = $('.js-cms-config-supplier-whitelisted-goalid').length > 0
                ,thirdPartyContentSourceContainerExists = $('.js-cms-config-thirdpartycontentsource').length > 0;

            if (langContainerExists) {
                ConfigLanguagesController.getInstance().init();
            }
            if (preferredDescriptionContainerExists) {
                ConfigPreferredDescriptionsController.getInstance().init();
            }
            if (preferredImageContainerExists) {
                ConfigPreferredImagesController.getInstance().init();
            }
            if (imageCategoriesContainerExists) {
                ConfigImageCategoriesController.getInstance().init();
            }
            if (supplierCodeMappingsContainerExists) {
                ConfigSupplierCodeMappingsController.getInstance().init();
            }
            if (supplierCodeMatchingsContainerExists) {
                ConfigSupplierCodeMatchingsController.getInstance().init();
            }
            if (supplierWhitelistedGoalIdContainerExists) {
                ConfigSupplierWhitelistedGoalIdController.getInstance().init();
            }
            if (thirdPartyContentSourceContainerExists) {
                ConfigThirdPartyContentSourceController.getInstance().init();
            }
            if (!langContainerExists && !preferredDescriptionContainerExists && !preferredImageContainerExists
                && !imageCategoriesContainerExists && !supplierCodeMappingsContainerExists
                && !supplierCodeMatchingsContainerExists && !supplierWhitelistedGoalIdContainerExists) {
                $('.cms-config-settings-general').hide();
            }
            else {
                anchorButtons.push({title:'General', anchor:'.cms-config-settings-general'});
            }
        };

        var initApiV2ConfigurationConfig = function() {
            var globaltypeCategoryTranslationFilterContainerExists = $('.js-cms-config-form-apiconfiguration-globaltypecategorytranslationfilter').length > 0;
            if (globaltypeCategoryTranslationFilterContainerExists) {
                ConfigGlobaltypeCategoryTranslationFilterController.getInstance().init();
            }
            if (!globaltypeCategoryTranslationFilterContainerExists) {
                $('.cms-config-settings-apiv2configuration').hide();
            }
            else {
                anchorButtons.push({title:'API v2 Config', anchor:'.cms-config-settings-apiv2configuration'});
            }
        };

        var initGeneralCronJobConfig = function() {
            var cronUploadContainerExists = $('.js-cms-config-cron-upload').length > 0
                ,cronGeographyContainerExists = $('.js-cms-config-cron-geography').length > 0
                ,cronGoalFromCentralExists = $('.js-cms-config-cron-goalfromcentral').length > 0;
            if (cronUploadContainerExists) {
                ConfigCronUploadController.getInstance().init();
            }
            if (cronGeographyContainerExists) {
                ConfigCronGeographyController.getInstance().init();
            }
            if (cronGoalFromCentralExists) {
                ConfigCronGoalFromCentralController.getInstance().init();
            }
            if (!cronUploadContainerExists && !cronGeographyContainerExists
                && !cronGoalFromCentralExists) {
                $('.cms-config-settings-generalcronjob').hide();
            } else {
                anchorButtons.push({title:'General Cronjobs', anchor:'.cms-config-settings-generalcronjob'});
            }
        };

        var initExportCronJobsConfig = function() {
            var exportCronJobsContainerExists = $('.js-cms-config-export-job-list').length > 0;
            if (exportCronJobsContainerExists) {
                ConfigExportCronjobsController.getInstance().init();
            }
            if (!exportCronJobsContainerExists) {
                $('.cms-config-settings-exportcronjobs').hide();
            } else {
                anchorButtons.push({title:'Export Cronjobs', anchor:'.cms-config-settings-exportcronjobs'});
            }
        };

        var initExportToGiataConfig = function() {
            var exportImagesContainerExists = $('.js-cms-config-prioritycontentexport-images').length > 0;
            var exportDescriptionsContainerExists = $('.js-cms-config-prioritycontentexport-descriptions').length > 0;
            var exportLanguagesContainerExists = $('.js-cms-config-prioritycontentexport-languages').length > 0;
            if (exportImagesContainerExists) {
                ConfigPriorityContentExportImagesController.getInstance().init();
            }
            if (exportDescriptionsContainerExists) {
                ConfigPriorityContentExportDescriptionsController.getInstance().init();
            }
            if (exportLanguagesContainerExists) {
                ConfigPriorityContentExportLanguagesController.getInstance().init();
            }
            if (!exportImagesContainerExists && !exportDescriptionsContainerExists) {
                $('.cms-config-settings-prioritycontentexport').hide();
            } else {
                anchorButtons.push({title:'Priority content export', anchor:'.cms-config-settings-prioritycontentexport'});
            }
        };

        var init = function() {
            loader = Loader.getInstance();
            initGeneralConfig();
            initApiV2ConfigurationConfig();
            initGeneralCronJobConfig();
            initExportCronJobsConfig();
            //initExportToGiataConfig();
            addScrollButtons(anchorButtons);
        };

        return {
            init: init
        };
    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();
$(function() {
    if ($('.js-init-config-main').length > 0 ) {
        ConfigMainController.getInstance().init();
    }
    if ($('.js-init-config-contentexport-main').length > 0) {
        ConfigContentExportController.getInstance().init();
    }
});
