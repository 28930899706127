var ConfigSupplierWhitelistedGoalIdController = (function(){

    var instance
        ,loader;

    function createInstance() {

        var addHandlers = function () {
            var wrapper = $('.js-cms-config-supplier-whitelisted-goalid');
            wrapper.on('submit', '.js-cms-config-form-supplier-whitelisted-goalid', onSubmit);
            wrapper.on('click', '.btn-reset-supplier-whitelisted-goalid', onReset);
        };

        var onReset = function(e) {
            e.preventDefault();
            var btn = $(this)
                ,loaderContainer = btn.parents('.js-cms-config-supplier-whitelisted-goalid');
            $.ajax({
                url: Routing.generate('cms_config_resetsupplierwhitelistedgoalid')
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "resetSupplierWhitelistedGoalId";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
            })
            .done(function(data) {
                if (data.view) {
                    btn.parents('.js-config-formwrapper').html(data.view);
                }
            });
        };


        var onSubmit = function(e) {
            e.preventDefault();
            var form = $(this)
                ,loaderContainer = form.parents('.js-cms-config-supplier-whitelisted-goalid')
                ,inputFields = form.find('input')
                ,supplierWhitelistedGoalId = []
                ,url = Routing.generate('cms_config_savesupplierwhitelistedgoalid');

            $.each($(inputFields).serializeArray(), function (idx, kV) {
                if (kV.value !== '') {
                    supplierWhitelistedGoalId.push(kV.value);
                }
            });

            $.ajax(url, {
                data: {
                    supplierWhitelistedGoalId: supplierWhitelistedGoalId
                },
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "saveConfigSupplierWhitelistedGoalId";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
                ,method: 'POST'
            })
            .done(function(data){
                if (data.success === false) {
                    toastr.error(
                        Translator.trans('def.term.error', {}, 'cms'),
                        Translator.trans('config.section.supplierwhitelistedforgoalid.save.errormessage', {}, 'cms')
                    );
                }
                if (data.success === true) {
                    loaderContainer.find('.btn-reset-supplier-whitelisted-goalid').click();
                    toastr.success(
                        Translator.trans('def.term.success', {}, 'cms'),
                        Translator.trans('config.section.supplierwhitelistedforgoalid.save.successmessage', {}, 'cms')
                    );
                }
            });
        };

        var init = function() {
            loader = Loader.getInstance();
            addHandlers();
        };

        return {
            init: init
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();