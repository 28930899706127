$(function () {
    var context = pwNamespace('CMS.GLOBALTYPECATEGORIES');
    context.TranslationList = (function () {
        var instance = null;

        var injection = function() {
            return {}
        };

        var initInstance = function() {
            var me = {
                catagoriesTabId: "#globaltypecategories-translations-tab"
                ,addTranslationButtonClass: ".js-globaltypecategories-translations-add-button"
                ,translationContainer: '#globaltypecategories-translations'
                ,editMode: false
                ,transId: 1

                ,init: function() {
                    me.addEventListeners();
                }

                ,addEventListeners: function() {
                    $('body').on('click', me.addTranslationButtonClass, me.addTranslation)
                        .on('click', '.edit-translation-btn', me.handleEditTranslation)
                        .on('click', '.save-translation-btn', me.handleSaveTranslation)
                        .on('click', '.delete-translation-btn', me.handleDeleteTranslation)
                        .on('click', '.reset-translation-btn', me.handleResetTranslation)
                        .on('keyup', '.js-globaltypecategories-translation-name-input', me.handleChangeTranslation)
                        .on('keyup', '.js-globaltypecategories-translation-language-input', me.handleChangeTranslation)
                        .on('keyup', '.js-globaltypecategories-translation-country-input', me.handleChangeTranslation)
                    ;
                }

                ,handleChangeTranslation: function(e) {
                    var input = $(e.currentTarget)
                        ,span = input.prev()
                        ,value = input.val();
                    if (input.hasClass('js-globaltypecategories-translation-language-input')) {
                        value = me.getPreparedInputCode(input, false);
                    } else if (input.hasClass('js-globaltypecategories-translation-country-input')) {
                        value = me.getPreparedInputCode(input, true);
                    }
                    span.text(value);
                }

                ,getPreparedInputCode: function(input, toUpperCase) {
                    var value = input.val();
                    if (value.length > 2) {
                        value = value.substring(0,2);
                    }
                    if (toUpperCase) {
                        value = value.toUpperCase();
                    } else {
                        value = value.toLowerCase();
                    }
                    input.val(value);
                    return value;
                }

                ,handleResetTranslation: function(e) {
                    var button = $(e.currentTarget)
                        ,categoriesContentContainer = $('.js-globaltypecategories-category.active')
                        ,divTranslationContainer = button.parents('.js-globaltypecategories-translation')
                        ,id = divTranslationContainer.attr('data-id')
                        ,updatedCategoriesJson = $.parseJSON(categoriesContentContainer.attr('data-category'))
                        ,inputName = divTranslationContainer.find('.js-globaltypecategories-translation-name-input')
                        ,spanName = inputName.prev()
                        ,inputLanguage = divTranslationContainer.find('.js-globaltypecategories-translation-language-input')
                        ,spanLanguage = inputLanguage.prev()
                        ,inputCountry = divTranslationContainer.find('.js-globaltypecategories-translation-country-input')
                        ,spanCountry = inputCountry.prev()
                    ;

                    inputName.val(updatedCategoriesJson[id].Name);
                    spanName.text(updatedCategoriesJson[id].Name);
                    inputLanguage.val(updatedCategoriesJson[id].Language);
                    spanLanguage.text(updatedCategoriesJson[id].Language);
                    inputCountry.val(updatedCategoriesJson[id].Country);
                    spanCountry.text(updatedCategoriesJson[id].Country);

                }

                ,handleDeleteTranslation: function(e) {
                    var button = $(e.currentTarget)
                        ,parent = button.parents('.js-globaltypecategories-translation')
                        ,categoriesContentContainer = $('.js-globaltypecategories-category.active')
                        ,divTranslationContainer = button.parents('.js-globaltypecategories-translation')
                        ,inputNameValue = divTranslationContainer.find('.js-globaltypecategories-translation-name-input').val()
                        ,inputLanguageValue = divTranslationContainer.find('.js-globaltypecategories-translation-language-input').val()
                        ,inputCountryValue = divTranslationContainer.find('.js-globaltypecategories-translation-country-input').val()
                    ;

                    updatedCategoriesJson = $.parseJSON(categoriesContentContainer.attr('data-category'));
                    //TODO: search the element and remove it: do not use the data-id
                    $.each(updatedCategoriesJson, function(key, value) {
                        if (null !== value) {
                            if (inputNameValue === value.Name
                                && inputLanguageValue === value.Language
                                && inputCountryValue === value.Country
                            ) {
                                updatedCategoriesJson.splice(key, 1);
                                return false;
                            }
                        }
                    });
                    categoriesContentContainer.attr('data-category', JSON.stringify(updatedCategoriesJson).replace(/'/g, "\\'"));
                    me.handleEditMode(false);
                    me.switchToViewMode(e);
                    parent.remove();
                }

                ,handleSaveTranslation: function(e) {
                    var button = $(e.currentTarget)
                        ,categoriesContentContainer = $('.js-globaltypecategories-category.active')
                        ,divTranslationContainer = button.parents('.js-globaltypecategories-translation')
                        ,inputName = divTranslationContainer.find('.js-globaltypecategories-translation-name-input')
                        ,inputLanguage = divTranslationContainer.find('.js-globaltypecategories-translation-language-input')
                        ,inputCountry = divTranslationContainer.find('.js-globaltypecategories-translation-country-input');

                    if ('' === inputName.val() || '' === inputCountry.val() || '' === inputLanguage.val()) {
                        return;
                    }
                    updatedCategoriesJson = $.parseJSON(categoriesContentContainer.attr('data-category'));
                    updatedCategoriesJson[divTranslationContainer.attr('data-id')] = {
                        'Name': inputName.val()
                        ,'Language': inputLanguage.val()
                        ,'Country': inputCountry.val()
                    };
                    categoriesContentContainer.attr('data-category', JSON.stringify(updatedCategoriesJson).replace(/'/g, "\\'"));

                    me.handleEditMode(false);
                    me.switchToViewMode(e);
                }

                ,switchToViewMode: function(e) {
                    var button = $(e.currentTarget)
                        ,divContentContainer = button.parent().prev()
                        ,span = divContentContainer.find('span')
                        ,input = divContentContainer.find('input')
                        ,buttonEditSaveIcon = button.find('span')
                        ,resetButton = button.next()
                        ,buttonDeleteResetIcon = resetButton.find('span')
                        ;
                    span.show();
                    input.hide();
                    buttonEditSaveIcon.removeClass('fa-save').addClass('fa-edit');
                    buttonDeleteResetIcon.removeClass('fa-undo').addClass('fa-trash');
                    button.removeClass('save-translation-btn').addClass('edit-translation-btn');
                    resetButton.removeClass('reset-translation-btn').addClass('delete-translation-btn');
                }

                ,handleEditTranslation: function(e) {
                    var button = $(e.currentTarget)
                        ,prevContainer = button.parent().prev()
                        ,span = prevContainer.find('span')
                        ,buttonEditSaveIcon = button.find('span')
                        ,deleteButton = button.next()
                        ,buttonDeleteResetIcon = deleteButton.find('span')
                        ,input = prevContainer.find('input')
                        ,parent = button.parents('.js-globaltypecategories-translation')
                        ,list = parent.parent();

                    span.hide();
                    input.show();
                    buttonEditSaveIcon.removeClass('fa-edit').addClass('fa-save');
                    buttonDeleteResetIcon.removeClass('fa-trash').addClass('fa-undo');
                    button.removeClass('edit-translation-btn').addClass('save-translation-btn');
                    deleteButton.removeClass('delete-translation-btn').addClass('reset-translation-btn');
                    me.handleEditMode(true);
                    list.find('.js-globaltypecategories-translation').removeClass('active');
                    parent.addClass('active');
                    input.focus();
                    me.handleDeactivateTranslationButtons(true);

                }

                ,handleEditMode: function(isDisabled) {
                    //TODO:
                    me.editMode = isDisabled;
                    var addCategoryButton = $('.js-globaltypecategories-add-button');
                    var addGlobalTypeButton = $('.js-globaltypecategories-globaltypes-add-button');
                    var addButton = $('.js-globaltypecategories-translations-add-button');
                    var editCategoryButtons = $('#globaltypecategorieslist').find('.edit-btn');
                    var deleteCategoryButtons = $('#globaltypecategorieslist').find('.delete-btn');
                    var editTranslationButtons = $('#globaltypecategories-translations').find('.edit-translation-btn');
                    var deleteTranslationButtons = $('#globaltypecategories-translations').find('.delete-translation-btn');
                    editCategoryButtons.attr('disabled', isDisabled);
                    addButton.attr('disabled', isDisabled);
                    addCategoryButton.attr('disabled', isDisabled);
                    deleteCategoryButtons.attr('disabled', isDisabled);
                    editTranslationButtons.attr('disabled', isDisabled);
                    deleteTranslationButtons.attr('disabled', isDisabled);
                    addGlobalTypeButton.attr('disabled', isDisabled);
                }
                ,handleDeactivateTranslationButtons: function(value) {

                }
                //,handleDeactivateGlobalTypeButton: function(value) {
                //
                //}
                ,addTranslation: function() {
                    me.handleEditMode(true);
                    var div = $('#globaltypecategories-translations')
                        ,categoriesContentContainer = $('.js-globaltypecategories-category.active')
                        ,id = categoriesContentContainer.attr('data-id')
                        ,list = div.find('.dd-list')
                        ,rowTranslation = '<div class=" flexform__row">' +
                        '<div class="globaltypecategories-translation-content flexform__grow flexform__grow--even">' +
                        '<div class="row">' +
                        '<div class="globaltypecategories-translation-contentitem col-md-4">' +
                        '<label>' + Translator.trans('globaltype.tables.translations.name', {}, 'cms') + ': </label>' +
                        '<span class="js-globaltypecategories-translation-name" hidden></span>' +
                        '<input class="globaltypecategories-translation-input js-globaltypecategories-translation-name-input input-sm" value="" placeholder="' + Translator.trans('globaltype.tables.translations.placeholder.name', {}, 'cms') + '"></div>' +
                        '<div class="globaltypecategories-translation-contentitem col-md-4">' +
                        '<label>' + Translator.trans('globaltype.tables.translations.language', {}, 'cms') + ': </label>' +
                        '<span class="js-globaltypecategories-translation-language" hidden></span>' +
                        '<input class="globaltypecategories-translation-input js-globaltypecategories-translation-language-input input-sm" value="" placeholder="' + Translator.trans('globaltype.tables.translations.placeholder.language', {}, 'cms') + '"></div>' +
                        '<div class="globaltypecategories-translation-contentitem col-md-4">' +
                        '<label>' + Translator.trans('globaltype.tables.translations.country', {}, 'cms') + ': </label>' +
                        '<span class="js-globaltypecategories-translation-country" hidden></span>' +
                        '<input class="globaltypecategories-translation-input js-globaltypecategories-translation-country-input input-sm" value="" placeholder="' + Translator.trans('globaltype.tables.translations.placeholder.country', {}, 'cms') + '"></div>' +
                        '</div></div>' +
                        '<div class="globaltypecategorieslist--buttongroup flexform__shrink">' +
                        '<button class="btn btn-xs btn-default save-translation-btn" type="button"><span class="fa fa-save"></span></button>' +
                        '<button class="btn btn-xs btn-default delete-translation-btn" type="button"><span class="fa fa-trash"></span></button></div>' +
                        '</div>';
                    var li = $( "<li>", {
                        'class': "dd-item globaltypecategories-translation js-globaltypecategories-translation"
                        ,'data-id': me.transId
                    })
                        .append(
                            $(rowTranslation)
                        )
                        .appendTo(list);
                    me.transId++;
                }

                ,loadTranslation: function(translation) {

                    if (null !== translation) {
                        var buttonsDisabled = ('en' === translation.Language)
                            ,disabledAttr = buttonsDisabled?'disabled':''
                            ,editButtonClass = buttonsDisabled ? '': ' edit-translation-btn'
                            ,deleteButtonClass = buttonsDisabled ? '': ' delete-translation-btn'
                            ,buttons =
                                '<div class="globaltypecategorieslist--buttongroup flexform__shrink">' +
                                '<button class="btn btn-xs btn-default'+ editButtonClass +'" '+disabledAttr+' type="button">' +
                                '<span class="fa fa-edit"></span></button>' +
                                '<button class="btn btn-xs btn-default'+ deleteButtonClass +'" '+disabledAttr+' type="button">' +
                                '<span class="fa fa-trash"></span></button></div>';

                        var div = $('#globaltypecategories-translations')
                            ,list = div.find('.dd-list')
                            ,rowTranslation = '<div class="flexform__row">' +
                            '<div class="globaltypecategories-translation-content flexform__grow flexform__grow--even">' +
                            '<div class="row">' +
                            '<div class="globaltypecategories-translation-contentitem col-md-4">' +
                            '<label>' + Translator.trans('globaltype.tables.translations.name', {}, 'cms') + ': </label>' +
                            '<span class="js-globaltypecategories-translation-name">' + translation.Name + '</span>' +
                            '<input class="globaltypecategories-translation-input js-globaltypecategories-translation-name-input input-sm" value="' + translation.Name + '" placeholder="' + Translator.trans('globaltype.tables.translations.placeholder.name', {}, 'cms') + '" hidden></div>' +
                            '<div class="globaltypecategories-translation-contentitem col-md-4">' +
                            '<label>' + Translator.trans('globaltype.tables.translations.language', {}, 'cms') + ': </label>' +
                            '<span class="js-globaltypecategories-translation-language">' + translation.Language + '</span>' +
                            '<input class="globaltypecategories-translation-input js-globaltypecategories-translation-language-input input-sm" value="' + translation.Language + '" placeholder="' + Translator.trans('globaltype.tables.translations.placeholder.language', {}, 'cms') + '" hidden></div>' +
                            '<div class="globaltypecategories-translation-contentitem col-md-4">' +
                            '<label>' + Translator.trans('globaltype.tables.translations.country', {}, 'cms') + ': </label>' +
                            '<span class="js-globaltypecategories-translation-country">' + translation.Country + '</span>' +
                            '<input class="globaltypecategories-translation-input js-globaltypecategories-translation-country-input input-sm" value="' + translation.Country + '" placeholder="' + Translator.trans('globaltype.tables.translations.placeholder.country', {}, 'cms') + '" hidden></div>' +
                            '</div></div>' + buttons + '</div>';
                        var li = $( "<li>", {
                            'class': "dd-item globaltypecategories-translation js-globaltypecategories-translation"
                            ,'data-id': me.transId
                        })
                            .append(
                                $(rowTranslation)
                            )
                            .appendTo(list);
                        me.transId++;
                    }
                }

                ,resetTranslationsList: function() {
                    $(me.translationContainer).find('.dd-list').empty();
                    me.transId = 0;
                }
            };

            /* public */
            var baseObj = {
                'loadTranslation' : me.loadTranslation
                ,'resetTranslationsList' : me.resetTranslationsList
                ,'resetTransId' : me.resetTransId
            };

            /* attach public functions to singleton */
            $.each(injection.call(me, me), function(idx, prop) {

                baseObj[idx] = prop;
            });

            /* execute init method */
            me.init();
            return baseObj;
        };

        return {
            inject: function(inject) {
                injection = inject;
            }
            ,getInstance: function() {
                if (instance === null) {
                    instance = initInstance();
                }
                return instance;
            }
        }
    })();
});

$(function() {
    if ($('.js-cmsglobaltypecategories').length > 0) {
        CMS.GLOBALTYPECATEGORIES.TranslationList.getInstance();
    }
});
