var GiataController = (function(){

    var instance
        ,loader
        ,tocodeData = {}
        ,mappingData = {}
        ,alertDefaults = {
            title: "Are you sure?"
            ,text: "This is the default message!"
            ,type: "warning"
            ,showCancelButton: true
            ,closeOnConfirm: true
            ,confirmButtonColor: "#DD6B55"
            ,confirmButtonText: "Yes, I am sure!"
        }
        ,filterInstance;

    var options = {
        filterFormId: 'giata-filter-form'
        ,filterName: 'giata-filter-name'
        ,filterCity: 'giata-filter-city'
        ,filterRegion: 'giata-filter-region'
        ,filterCountry: 'giata-filter-country'
        ,filterGiataId: 'giata-filter-giataid'
    };

    function createInstance() {

        var initFilter = function() {

            var stdFieldsSel = '#' + options.filterName
                + ', #' + options.filterCity
                + ', #' + options.filterRegion
                + ', #' + options.filterCountry
                + ', #' + options.filterGiataId;

            filterInstance = FilterForm.getInstance();
            filterInstance.init({
                formid: options.filterFormId
                ,submit: onSubmit
                ,addfields: stdFieldsSel
            });
        };


        var isModalSelect2Loaded = false;

        var addHandlers = function () {
            addScrollButtons([
                {title:'Filter', anchor:'.cmscontentstore--filter'}
                ,{title:'List', anchor:'.cmscontentstore--giatatable'}
                ,{title:'Detail', anchor:'.cmscontentstore--giatadetail'}
            ]);
            // $('#giata-filter-form').on('submit', onSubmit );
            // $("#giata-filter-form #FilterFormReset").on('click', onFormResetHandler);
            $('#giatadownloadmodal').on('shown.bs.modal', onShowToCodeFileDownloadModalHandler);
            $('.cmscontentstore--giatadetail').on('click', '.js-basicdata__actions__release, .js-basicdata__actions__deprecate, .js-basicdata__actions__needsrevision' ,onDetailsActionClickHandler);

            if (!isModalSelect2Loaded) {
                var input = $('#giatadownloadmodal').find('#js-filedownload-select-tocode');
                if (input.length > 0) {
                    input.select2({
                        multiple: true,
                        data: prepareSelectData(input.data('tocodes')),
                        createSearchChoice: function(term) {
                            return {
                                id: term,
                                text: term + ' (new)'
                            };
                        }
                    });
                    isModalSelect2Loaded = true;
                }
            }
        };

        var prepareSelectData = function(data) {
            var tocdes = [];
            $.each(data, function(k,v){
                tocdes.push({
                    id: k,
                    text: v
                });
            });
            return tocdes;
        }

        var onDetailsActionClickHandler = function(e) {
            var $target = $(e.currentTarget);
            var giataId = $target.data('giataid') || false;
            var action = false;
            var alert = {};
            if (!giataId) {
                return;
            }
            if ($target.hasClass('js-basicdata__actions__needsrevision')) {
                action = "revision";
                alert.text = Translator.trans('giata.detail.actions.needsrevision.message', {}, 'cms');
                alert.confirmButtonText = Translator.trans('giata.detail.actions.needsrevision.confirmbutton', {}, 'cms');
            }
            if ($target.hasClass('js-basicdata__actions__deprecate')) {
                action = "deprecate";
                alert.text = Translator.trans('giata.detail.actions.deprecate.message', {}, 'cms');
                alert.confirmButtonText = Translator.trans('giata.detail.actions.deprecate.confirmbutton', {}, 'cms');
            }
            if ($target.hasClass('js-basicdata__actions__release')) {
                action = "release";
                alert.text = Translator.trans('giata.detail.actions.release.message', {}, 'cms');
                alert.confirmButtonText = Translator.trans('giata.detail.actions.release.confirmbutton', {}, 'cms');
            }
            if (action) {
                alert.confirmButtonColor = $target.css('background-color');
                showAlertWithConfirmFn(alert, function(){
                    setGiataIdStatus(giataId, action);
                });
            }
        }

        var onShowToCodeFileDownloadModalHandler = function(e) {
            var modalform = $(e.currentTarget);
            modalform.find('#ToCodeMatchingSubmitDownload').on('click', onSubmitMultiCodeDownload);
            modalform.find('#GoogleSubmitDownload').on('click', onSubmitGoogleDownload);
        };

        var onSubmitMultiCodeDownload = function(e) {
            var modalContainer = $('#giatadownloadmodal')
                ,formContainer = modalContainer.find('#ToCodeCsvDownloadForm')
                ,tocodeContainer = formContainer.find('#js-filedownload-select-tocode')
                ,includeExtContainer = formContainer.find('#js-filedownload-checkbox-includeExt')
                ,formatContainer = formContainer.find('.filedownload-radio-format:checked')
                ,infoMessage = formContainer.find('.giatadownloadmodal__infomessage')
                ,btn = $(this)
                ,data = {
                    'tocode' : tocodeContainer.val()
                    ,'includeExtern' : includeExtContainer.is(':checked')
                    ,'format': formatContainer.val()
                };

            $.ajax({
                url: Routing.generate('cms_giata_pptocodematching')
                ,data: data
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "submitMultiCodeDownload";
                    loader.startLoader(e.loaderKey, modalContainer.find('.modal-content'));
                }
            })
            .done(function(data){
                var promise = DownloadController.getInstance().startCheckStatus(data.data);
                infoMessage.show();
                btn.hide();
                tocodeContainer.select2("val", null);
                includeExtContainer.prop('checked', false);
                tocodeContainer.parent().hide();
                includeExtContainer.parent().hide();
                modalContainer.modal('hide');
                promise.done(function(){
                    infoMessage.hide();
                    tocodeContainer.parent().show();
                    includeExtContainer.parent().show();
                    btn.show();
                });
            });

            return false;
        };

        var onSubmitGoogleDownload = function(e) {
            var modalContainer = $('#giatadownloadmodal')
                ,formContainer = modalContainer.find('#GoogleDownloadForm')
                ,btn = $(this);

            $.ajax({
                    url: Routing.generate('cms_giata_ppgooglelocalfeed')
                    ,beforeSend: function(e, xhr, options) {
                        e.loaderKey = "submitGoogleDownload";
                        loader.startLoader(e.loaderKey, modalContainer.find('.modal-content'));
                    }
                })
                .done(function(data){
                    var promise = DownloadController.getInstance().startCheckStatus(data.data);
                    btn.hide();
                    modalContainer.modal('hide');
                    promise.done(function(){
                        btn.show();
                    });
                });

            return false;
        };

        /**
         * resets filterform
         * @param Event e form event
         */
        // var onFormResetHandler = function(e) {
        //     e.preventDefault();
        //     $("#giata-filter-form")[0].reset();
        //     $("#FilterFormSubmit").trigger('click');
        // };


        var onSubmit = function(e) {
            e.preventDefault();
            var params = filterInstance.getValues(options.filterFormId);
            var tableAction = "cms_giata_list";
            var tableId = "#cmsgiatatable";
            $('.cmscontentstore--giatadetail').html("");

            loader.startLoader("filter", $(".cmscontentstore--filter"), ["giataTable"]);
            // $('#giata-filter-form input,select').each(function(k, v) {
            //     if (!$(v).val() || 0 >= $(v).val().length ) {
            //         return true;
            //     }
            //     var item = $(v);
            //     var itemName = item.prop('name');
            //     if (itemName === 'status') {
            //         params[item.val()] = true;
            //     } else {
            //         params[itemName] = item.val();
            //     }
            // });
            
            var table =$(tableId).DataTable();
            table
                .ajax
                .url(Routing.generate(tableAction, params))
                .load(function() {
                    //loader.remove(loaderId);
                });
            return false;
        };

        var showAlertWithConfirmFn = function(alert,fn) {

            if (typeof fn !== 'function') {
                return;
            }
            var options = {};
            $.extend(options, alertDefaults, alert);
            swal(options, fn);
        }
        var setGiataIdStatus = function(giataId, status) {

            //var loaderId = loader.add($('.content'));

            var data = { giataId: giataId, requestType: status };
            var url = Routing.generate('cms_giata_setgiatastatus');
            return $.ajax(url, {
                data: data
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "setGiataIdStatus";
                    loader.startLoader(e.loaderKey, $('.content'));
                }
                ,success: function(data, status, xhr) {
                    loadGiataDetailPage({
                        'key' : giataId
                        ,'requestType' : 'detail'
                    }, $('.cmscontentstore--giatadetail'));
                }
                ,error: function(xhr, status, error) {
                    //loader.remove(loaderId);
                    // TODO: Show SweetAlert with Error Message
                }
            });
        };
        
        var loadGiataDetailPage = function(data, loaderTargetContainer) {
            var url = Routing.generate('cms_giata_detail');
            var targetContainer = loaderTargetContainer || $('.content')
            return $.ajax(url, {
                data: data
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "giataDetail";
                    loader.startLoader(e.loaderKey, targetContainer);
                }
            })
            .done(function(data) {
                var detailContainer = $('.cmscontentstore--giatadetail');
                tocodeData = data.codes;
                mappingData = data.mappings;
                detailContainer.html(data.view);
                giatatocodetable_ini();
                giatamappingtable_ini();
                GiataMapController.getInstance().init();
                $('.cmscontentstore--giatadetail-btn').click();
            });
        };

        return {
            init: function() {
                loader = Loader.getInstance();
                initFilter();
                addHandlers();
                giatatable_ini();
            }
            ,loadGiataDetailPage: function(e) {
                loadGiataDetailPage({
                    'key' : $(this).data('key')
                    ,'requestType' : 'detail'
                });
            }
            ,handlePanels :function(openPanel) {
                handlePanels(openPanel);
            }
            ,getTocodes : function() {
                return tocodeData;
            }
            ,getMappings : function() {
                return mappingData;
            }
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

$(function() {
    if ($('.js-init-giata').length > 0) {
        GiataController.getInstance().init();
    }
});










