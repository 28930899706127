var ConfigThirdPartyContentSourceController = (function(){

    var instance
        ,loader;

    function createInstance() {

        var addHandlers = function () {
            var wrapper = $('.js-cms-config-thirdpartycontentsource')
                ,list = wrapper.find('.checkboxlist');

            initNestable(list);
            wrapper.on('submit', '.js-cms-config-form-thirdpartycontentsource', onSubmit);
            wrapper.on('click', '.js-cms-config-form-thirdpartycontentsource .btn-reset', onReset);
            wrapper.on('click', '.thirdpartycontentsource-filter-button .btn-save', onAddToList);
            wrapper.on('click', '.js-thirdpartycontentsource-editbtn', onEditEntry);
            wrapper.on('click', '.js-thirdpartycontentsource-deletebtn', onDeleteEntry);
            wrapper.find('.js-config-thirdpartycontentsource-openaddcheckboxitemdropdown').parent().on('hidden.bs.dropdown', onHiddenDropDown);
        };

        var onHiddenDropDown = function() {
            var button = $(this)
                ,outerParent = button.parents('.js-cms-config-thirdpartycontentsource')
                ,parent = outerParent.find('.js-cms-config-thirdpartycontentsource-filter');
            resetFilter(parent);
            parent.find('.thirdpartycontentsource-filter-button .btn-save').attr('data-editedItemIndex', null);
        };

        var onEditEntry = function(e) {
            var button = $(this)
                ,li = button.parents('li')
                ,outerParent = button.parents('.js-cms-config-thirdpartycontentsource')
                ,parent = outerParent.find('.js-cms-config-thirdpartycontentsource-filter')
                ,brandRadioButton = parent.find('#thirdpartycontentsource-brand');
            brandRadioButton.click();
            parent.find('.js-select2-filter-brand').val(li.attr('data-supplier'));
            parent.find('.thirdpartycontentsource-filter-button .btn-save').attr('data-editedItemIndex', li.index());
            parent.find('.js-config-thirdpartycontentsource-openaddcheckboxitemdropdown').dropdown("toggle");
            return false;
        };

        var onDeleteEntry = function(e) {
            var button = $(this)
                ,li = button.parents('.dd-item');
            button.parents('.dd-item').remove();
        };

        var onAddToList = function(e) {
            var button = $(this)
                ,li
                ,parent = button.parents('.js-cms-config-thirdpartycontentsource-filter')
                ,outerParent = parent.parents('.js-cms-config-thirdpartycontentsource')
                ,contentContainer = parent.find('.thirdpartycontentsource-brand')
                ,sourcedata = contentContainer.find('.js-radio-filter-sourcedata')
                ,supplier = contentContainer.find('input.js-select2-filter-brand');
            if (!supplier.val()) {
                supplier.addClass('thirdpartycontentsource-input-error');
                parent.find('.thirdpartycontentsource-filter-error-message').slideDown("slow",
                    function() {
                        setTimeout(function() {
                            parent.find('.thirdpartycontentsource-filter-error-message').slideUp("slow");
                            supplier.removeClass('thirdpartycontentsource-input-error');
                        }, 2000);
                    });
                return;
            }
            if (sourcedata.length > 1) {
                $.each(sourcedata, function(key, source){
                    console.log(key);
                    console.log(source);
                    if (source.checked) {
                        sourcedata = $(source);
                        return false;
                    }
                });
            }
            if (contentContainer.css('display') === 'block'
                && button.attr('data-editedItemIndex') !== undefined && button.attr('data-editedItemIndex') !== null) {
                $.each(outerParent.find('.dd-list li'), function() {
                    var li = $(this);
                    if (li.index() === parseInt(button.attr('data-editedItemIndex'))) {
                        li.attr('data-supplier', supplier.val());
                        li.attr('data-sourcedata', sourcedata.val());
                        li.find('.thirdpartycontentsource-list-item-text-supplier').text(supplier.val());
                        li.find('.thirdpartycontentsource-list-item-text-sourcedata').text(sourcedata.val());
                        return false;
                    }
                });
                button.attr('data-editedItemIndex', null);
                resetFilter(contentContainer);
                parent.find('.js-config-thirdpartycontentsource-openaddcheckboxitemdropdown').click();
                return false;
            }
            if (contentContainer.css('display') === 'block' && (supplier.val() && sourcedata.val())) {
                li = $('<li/>', {
                    'class':'dd-item config-checkbox-list'
                    ,'data-supplier': supplier.val()
                    ,'data-sourcedata': sourcedata.val()
                    ,'data-rulesettype':'supplier'
                })
                    .append(
                        $('<div/>', {'class':'dd-handle config-checkbox-list__handle thirdpartycontentsource-list__handle'})
                            .append(
                                $('<div/>', {'class':'dd-item__thirdpartycontentsource--draggable'})
                                    .append(
                                        $('<div/>', {
                                            'class': 'thirdpartycontentsource-list-item-text'
                                        })
                                            .append(
                                                $('<div/>', {
                                                    'class':'thirdpartycontentsource-list-item-text-supplier'
                                                    ,'text': supplier.val()
                                                })
                                            )
                                            .append(
                                                $('<div/>', {
                                                    'class':'thirdpartycontentsource-list-item-text-sourcedata'
                                                    ,'text': sourcedata.val()
                                                })
                                            )
                                    )
                                    .append(
                                        $('<div/>', {'class':'thirdpartycontentsource-list-item-buttons'})
                                            .append(
                                                $('<button/>', {'class':'btn btn-xs btn-default js-thirdpartycontentsource-editbtn'})
                                                    .append(
                                                        $('<span/>', {'class':'fa fa-edit'})
                                                    )
                                            )
                                            .append(
                                                $('<button/>', {'class':'btn btn-xs btn-default js-thirdpartycontentsource-deletebtn'})
                                                    .append(
                                                        $('<span/>', {'class':'fa fa-trash-o'})
                                                    )
                                            )
                                    )
                            )
                    );

            }
            outerParent.find('.dd-list').append(li);
            resetFilter(contentContainer);
            parent.find('.js-config-thirdpartycontentsource-openaddcheckboxitemdropdown').click();
            return false;
        };

        var resetFilter = function(contentContainer) {
            contentContainer.find('select.js-select2-filter-brand').val("");
            contentContainer.find('#thirdpartycontentsource-brand').click();
        };

        var initNestable = function(list) {
            if (!list.data('readonly')) {
                list.nestable({group:1, maxDepth:1});
            }
            list.on('mousedown', '.dd-item__thirdpartycontentsource--draggable', function(e) {
                e.stopPropagation();
            });
        };

        var onReset = function(e) {
            e.preventDefault();
            var btn = $(this)
                ,loaderContainer = btn.parents('.js-cms-config-thirdpartycontentsource');
            $.ajax({
                url: Routing.generate('cms_config_resetthirdpartycontentsource')
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "resetthirdpartycontentsource";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
            })
                .done(function(data) {
                    if (data.view) {
                        btn.parents('.js-config-formwrapper').html(data.view);
                        initNestable(loaderContainer.find('.checkboxlist'));
                    }
                });
        };


        var onSubmit = function(e) {
            e.preventDefault();
            var form = $(this)
                ,loaderContainer = form.parents('.js-cms-config-thirdpartycontentsource')
                ,liItems = form.find('li')
                ,thirdpartycontentsourceList = []
                ,url = Routing.generate('cms_config_savethirdpartycontentsource');

            $.each(liItems, function (idx, kV) {
                var li = $(this)
                    ,entry = {};
                entry.type = 'supplier';
                entry.supplier = li.data('supplier');
                entry.sourcedata = li.data('sourcedata');
                thirdpartycontentsourceList.push(entry);
            });
            $.ajax(url, {
                data: {
                    thirdPartyContentSource: thirdpartycontentsourceList
                },
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "saveConfigThirdPartyContentSource";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
                ,method: 'POST'
            })
                .done(function(data){
                    if (data.success === false) {
                        toastr.error(
                            Translator.trans('def.term.error', {}, 'cms'),
                            Translator.trans('config.section.thirdpartycontentsource.save.errormessage', {}, 'cms')
                        );
                    }
                    if (data.success === true) {
                        loaderContainer.find('.btn-reset-thirdpartycontentsource').click();
                        toastr.success(
                            Translator.trans('def.term.success', {}, 'cms'),
                            Translator.trans('config.section.thirdpartycontentsource.save.successmessage', {}, 'cms')
                        );
                    }
                });
        };

        var init = function() {
            loader = Loader.getInstance();
            addHandlers();
        };

        return {
            init: init
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();