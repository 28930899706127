var giatatable_ini = function() {
    var loader
        ,loaderTargetContainer
        ,table = $('#cmsgiatatable');

    loader = Loader.getInstance();

    table
        .on('draw.dt', function(e, settings) {
            $.each($(e.currentTarget).find('.edit-btn'), function(k, v){
                $(v).on('click',GiataController.getInstance().loadGiataDetailPage );
            });
        })
        .on('xhr.dt', function ( e, settings, data ) {
        })
        .on('preXhr.dt', function ( e, settings, data ) {
        })
        .dataTable({
            ajax: {
                url: Routing.generate('cms_giata_list')
                ,beforeSend: function (e, xhr, options) {
                    e.loaderKey = "giataTable";
                    loader.startLoader(e.loaderKey, table);
                }
            }
            ,serverSide: true
            ,lengthChange: true
            ,searching: false
            ,pagingType: 'simple_numbers'
            ,pageLength: 10
            ,columns: [
                {data: 'giataid', name:'giataid', sortable: false}
                ,{data: 'name' , name: 'name', sortable: false}
                ,{data: 'city' , name: 'city', sortable: false}
                ,{data: 'region' , name: 'region', sortable: false}
                ,{data: 'country' , name: 'country', sortable: false}
                ,{data: 'chain', name:'chain', sortable: false}
                ,{data: function(row, type, set, meta) {
                    return  $( "<div/>", {
                        'class': "btn btn-default edit-btn",
                        'data-toggle': 'tooltip',
                        'data-original-title': Translator.trans('giata.giatatable.action.show.title', {}, 'cms'),
                        'data-key':row.giataid
                    }).append(
                        $( "<span/>", {class: "fa fa-eye" } )
                    )[0].outerHTML;
                }, sortable: false}
            ]
            ,language: {
                paginate: {
                    next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
                    previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
                }
                ,thousands: '.'
                ,info: Translator.trans('contentstore.producttable.info', {}, 'cms')
                ,infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms')
                ,emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
            }
            ,fnDrawCallback: function( oSettings ){
                var settings = this.fnSettings();

                if( settings.fnRecordsTotal() <= settings._iDisplayLength){
                    $('#cmsgiatatable_paginate').hide();
                }else{
                    $('#cmsgiatatable_paginate').show();
                }
            }
        });

        //groupedfilterlist_ini();

};

var giatatocodetable_ini = function() {
    $('#cmstocodestable')
        .dataTable({
            data: GiataController.getInstance().getTocodes()
            ,serverSide: false
            ,lengthChange: true
            ,searching: true
            ,pagingType: 'simple_numbers'
            ,pageLength: 10
            ,"order": []
            ,columns: [
                {data: 'type', name:'type', sortable: false}
                ,{data: 'tocode', name:'tocode', sortable: false}
                ,{data: 'code', name:'code', sortable: false}
            ]
            ,language: {
                paginate: {
                    next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
                    previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
                }
                ,thousands: '.'
                ,info: Translator.trans('contentstore.producttable.info', {}, 'cms')
                ,infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms')
                ,emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
            }
            ,fnDrawCallback: function( oSettings ){
                var settings = this.fnSettings();

                if( settings.fnRecordsTotal() <= settings._iDisplayLength){
                    $('#cmstocodestable_paginate').hide();
                }else{
                    $('#cmstocodestable_paginate').show();
                }
            }
        });
};

var giatamappingtable_ini = function() {
    $('#cmsmappingstable')
        .dataTable({
            data: GiataController.getInstance().getMappings()
            ,serverSide: false
            ,lengthChange: true
            ,searching: true
            ,pagingType: 'simple_numbers'
            ,pageLength: 10
            ,"order": []
            ,columns: [
                {data: 'supplier', name:'supplier', sortable: false}
                ,{data: 'code', name:'code', sortable: false}
                ,{data: 'lastupdate', name:'lastupdate', sortable: false}
            ]
            ,language: {
                paginate: {
                    next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
                    previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
                }
                ,thousands: '.'
                ,info: Translator.trans('contentstore.producttable.info', {}, 'cms')
                ,infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms')
                ,emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
            }
            ,fnDrawCallback: function( oSettings ){
                var settings = this.fnSettings();

                if( settings.fnRecordsTotal() <= settings._iDisplayLength){
                    $('#cmsmappingstable_paginate').hide();
                }else{
                    $('#cmsmappingstable_paginate').show();
                }
            }
        });
};


