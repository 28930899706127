(function() {
    var ctx = pwNamespace('PEAKWORK.CMS.IMAGE');
    var internalSupplier = getInternalSupplier();
    ctx.ImageList = function(namespace, options) {
        var self = this;
        this.namespace = namespace || '';
        this.loader = {};
        this.data = {};
        this.currentImage = false;
        this.currentGroup = false;
        this.$imageListContainer = false;
        this.$imgList = false;
        this.loader;

        this.defaults = {
            jsclass: {
                imageListContainer: 'js-imagepresenter__header'
                ,imageList: 'js-imagelist'
                ,imageListItem: 'js-imagelist__item'
                ,imageListItemValue: 'js-imagelistitem__group'
                ,imageListItemCount: 'js-imagelistitem__groupcount'
                ,imageListSearchInput: 'js-imagelist__searchinput'
                ,imageListClearSearchButton: 'js-imagelist__clearsearch'
                ,imageListPreview: 'js-imagelistitem__imagepreview'
                ,imageListPreviewImageNoneClass: 'imagelistitem__previewimagenone'
                ,imageListPreviewImage: 'js-imagelistitem__previewimage'
                ,imageListPreviewImageAlt: 'js-imagelistitem__previewimagealt'
                ,imageListPreviewImageTitle: 'js-imagelistitem__previewimagetitle'
                ,imageListPreviewDescription: 'js-imagelistitem__previewdescription'
                ,imageListDeleteButton: 'js-imagelist__itemdeletebtn'
                ,imageListTeaserButton: 'js-imagelist__itemteaserbtn'
            }
            ,attr: {
                src: 'src'
                ,alt: 'alt'
                ,title: 'title'
            }
            ,dataAttr: {
                imageGroup: 'group'
                ,imageIndex: 'index'
                ,imageIsCover: 'iscover'
                ,imageIsDuplicateOf: 'isduplicateof'
                ,imageIsActive: 'active'
                ,imageOriginalUrl: 'originurl'
                ,imageEncodedUrl: 'encodedurl'
                ,imageIsINT: 'isint'
                ,imageKey: 'key'
                ,imageIsDeletable: 'isdeletable'
            }
            ,propKeys: {
                group: 'group'
                ,groupValues: {
                    groupList: 'suppliers'
                    ,count: 'count'
                    ,supplier: 'tocode'
                    ,code: 'code'
                    ,images: 'images'
                    ,isDuplicateOf: 'isduplicateof'
                    ,originurl: 'originurl'
                    ,encodedurl: 'encodedurl'
                    ,key: 'key'
                }
                ,imageValues: {

                }
            }
            ,events: {
                groupchange: this.namespace === '' ? 'presentergroupchange' : 'presentergroupchange.' + this.namespace
                ,datachange: this.namespace === '' ? 'presenterdatachange' : 'presenterdatachange.' + this.namespace
                ,imagechange: this.namespace === '' ? 'presenterimagechange' : 'presenterimagechange.' + this.namespace
                ,search: this.namespace === '' ? 'presentersearch' : 'presentersearch.' + this.namespace
                ,addImage: this.namespace === '' ? 'imagelistaddimage' : 'imagelistaddimage.' + this.namespace
            }
            ,imageListContainerId: 'imagepresenter__imagelist'
            ,imageListTemplate: ''
            + '<li class="imagelist__item js-imagelist__item"  data-group data-index data-iscover data-isduplicateof data-encodedurl data-isint data-isdeletable>'
            + '<div class="imagelistitem__imagepreview js-imagelistitem__imagepreview">'
            + '<img class="imagelistitem__previewimage js-imagelistitem__previewimage js-imagelistitem__previewimagetitle js-imagelistitem__previewimagealt" src="" title="" alt="">'
            + '<div class="imagelist__itembtn--outerwrapper"><div class="imagelist__itembtn--innerwrapper">'
            + '<a class="imagelist__itemteaserbtn js-imagelist__itemteaserbtn" title="' + Translator.trans('def.images.buttons.teaser.title', {}, 'cms') + '"><span class="fa fa-star-o"></span></a>'
            + '<a class="imagelist__itemdeletebtn js-imagelist__itemdeletebtn" title="' + Translator.trans('def.images.buttons.remove.title', {}, 'cms') + '"><span class="fa fa-close"></span></a>'
            + '</div></div>'
            + '<figcaption class="imagelistitem__previewdescription js-imagelistitem__previewdescription">'
            + '</figcaption></figure></li>'
            ,imageListSort: false
            ,parmCdnBaseUrl: 'https://cdn1.peakwork.com/'
            ,parmPreviewImageSize: 100
            ,values: false
        };

        // Create options by extending defaults with the passed in arguments
        if (options && typeof options === "object") {
            this.options = $.extend(true, {}, this.defaults, options);
        }
        return init.call(this);
    };

    ctx.ImageList.prototype.constructor = ctx.ImageList;

    function init() {
        this.loader = Loader.getInstance();

        if (!this.initImageListContainer()
            || !this.initImageList()) {
            return false;
        }
        this.setInitialData();
        this.addHandlers();
        return this.getExports();
    }

    ctx.ImageList.prototype.initImageListContainer = function() {
        var $imageListContainer = $('#' + this.options.imageListContainerId);
        var $imageList = $('.' + this.options.jsclass.imageList, $imageListContainer);

        if ($imageListContainer.length < 1
            || $imageList.length < 1) {
            return false;
        }

        this.$imageListContainer = $imageListContainer;
        this.$imageList = $imageList;
        return true;
    };

    ctx.ImageList.prototype.addHandlers = function() {
        var listenerSelector = '.' + this.options.jsclass.imageListItem
            + ', .' + this.options.jsclass.imageListClearSearchButton
            + ', .' + this.options.jsclass.imageListDeleteButton
            + ', .' + this.options.jsclass.imageListTeaserButton;
        this.$imageListContainer.off('click').on('click.' + this.namespace, listenerSelector, this.getHandleClick.call(this));
        $.subscribe(this.options.events.datachange, this.getHandleDataChange.call(this));
        $.subscribe(this.options.events.groupchange, this.getHandleGroupChange.call(this));
        $.subscribe(this.options.events.search, this.getHandleSearch.call(this));
        $.subscribe(this.options.events.addImage, this.addImage.call(this));
    };

    ctx.ImageList.prototype.getHandleDataChange = function() {
        var self = this;
        return function(e, data) {
            self.data = data;
            self.updateImageList();
        }
    };

    ctx.ImageList.prototype.getHandleSearch = function() {
        var self = this;
        return function(e, data) {
            var $searchInput = self.$imageListContainer.find('.' + self.options.jsclass.imageListSearchInput);
            $searchInput.val(data); //.focus();
            self.imageList.search(data);
        }
    };

    ctx.ImageList.prototype.getHandleGroupChange = function() {
        var self = this;
        return function(e, data) {
            if (data.hasOwnProperty(self.options.propKeys.group)) {
                self.currentGroup = data[self.options.propKeys.group];
                self.handleClearSearchButtonClick.call(self);
                self.updateImageList();
            }
        }
    };

    ctx.ImageList.prototype.addImage = function() {
        var self = this;
        return function(e, data) {
            var dataINTImages = self.data[self.options.propKeys.groupValues.groupList][internalSupplier][self.options.propKeys.groupValues.images]
                ,loaderTargetContainer = $(self.imageList.list).parents('#contentimagespresenter')
                ,groupId = loaderTargetContainer.data('groupid')
                ,groupListItem = $('.js-grouplist', loaderTargetContainer).find('[data-groupkey="' + internalSupplier + '"]')
                ,newImage = {
                    category : ''
                    ,cover : 0
                    ,editedurl : data.imageUrl
                    ,encodedoriginalurl : encodeURIComponent(data.imageUrl)
                    ,encodedurl : ''
                    ,height : ''
                    ,isduplicateof : ''
                    ,key : ''
                    ,name : ''
                    ,originurl : data.imageUrl
                    ,tags : []
                    ,width : ''
                };
            dataINTImages.push(newImage);
            self.savePreferredImages(groupId, dataINTImages, loaderTargetContainer, "addimage")
                .done(function(data) {
                    self.data[self.options.propKeys.groupValues.groupList][internalSupplier][self.options.propKeys.groupValues.images] = data.images;
                    groupListItem.find('.js-grouplistitem__groupcount').html(dataINTImages.length);
                    $.publish(self.options.events.imagechange, {});
                    self.updateImageList();
                });
        }
    };

    ctx.ImageList.prototype.getHandleClick = function() {
        var self = this;
        return function(e) {
            var $target = $(e.currentTarget);
            if ($target.hasClass(self.options.jsclass.imageListItem)) {
                self.handlePreviewImageClick.call(self, $target);
            } else if ($target.hasClass(self.options.jsclass.imageListClearSearchButton)) {
                self.handleClearSearchButtonClick.call(self, $target);
            } else if ($target.hasClass(self.options.jsclass.imageListDeleteButton)) {
                self.handleDeleteButtonClick.call(self, $target, e);
            } else if ($target.hasClass(self.options.jsclass.imageListTeaserButton)) {
                self.handleTeaserButtonClick.call(self, $target, e);
            }

        }
    };

    ctx.ImageList.prototype.handleTeaserButtonClick = function($target, e) {
        e.stopPropagation();
        var self = this
            ,parent = $target.parents('.js-imagelist__item')
            ,description = parent.find('.js-imagelistitem__previewdescription')
            ,dataINTImages = this.data[this.options.propKeys.groupValues.groupList][internalSupplier][this.options.propKeys.groupValues.images]
            ,loaderTargetContainer = $(this.imageList.list).parents('#contentimagespresenter')
            ,groupId = loaderTargetContainer.data('groupid')
            ,currentObj = this.findImageByEncodedURLInArray(parent.data('encodedurl'), dataINTImages )
            ,isCover = 0;

        if (parent.attr('data-iscover') === '0') {
            this.resetCoverImages(dataINTImages);
            this.resetCoverInDomList();
            currentObj.cover = 1;
            parent.attr('data-iscover', 1);
            description.text(Translator.trans('contentstore.rooms.teaser', {}, 'cms'));
            isCover = 1;
        } else {
            this.resetCoverImages(dataINTImages);
            this.resetCoverInDomList();
        }
        this.imageList.reIndex();
        this.savePreferredImages(groupId, dataINTImages, loaderTargetContainer, "teaserimage")
            .done(function(){
                var allImages = self.data[self.options.propKeys.groupValues.groupList]['allImages'][self.options.propKeys.groupValues.images]
                self.resetCoverImages(allImages);
                var currentAllImagesObj = self.findImageByEncodedURLInArray(currentObj.encodedurl, allImages);
                currentAllImagesObj.cover = isCover;
                parent.click();
            });
        return false;
    };

    ctx.ImageList.prototype.resetCoverImages = function(dataArray) {
        for (var index in dataArray) {
            dataArray[index].cover = 0;
        }
    };

    ctx.ImageList.prototype.resetCoverInDomList = function() {
        $(this.imageList.list).children().each(function(){
            var li = $(this)
                ,description = li.find('.js-imagelistitem__previewdescription')
                ,img = li.find('img');
            li.attr('data-iscover', 0);
            description.text(img.prop('title'));
        });
        this.imageList.reIndex();
    };

    ctx.ImageList.prototype.handleDeleteButtonClick = function($target, e) {
        e.stopPropagation();
        var parent = $target.parents('.js-imagelist__item')
            ,dataINTImages = this.data[this.options.propKeys.groupValues.groupList][internalSupplier][this.options.propKeys.groupValues.images]
            ,allImages = this.data[this.options.propKeys.groupValues.groupList]['allImages'][this.options.propKeys.groupValues.images]
            ,loaderTargetContainer = $(this.imageList.list).parents('#contentimagespresenter')
            ,groupId = loaderTargetContainer.data('groupid')
            ,isRemoved = this.removeFromArray(parent.data('encodedurl'), dataINTImages)
            ,groupListItem = $('.js-grouplist', loaderTargetContainer).find('[data-groupkey="' + internalSupplier + '"]')
            ,self = this;
        if (isRemoved) {
            this.savePreferredImages(groupId, dataINTImages, loaderTargetContainer, "removeimage")
                .done(function() {
                    var currentAllImagesObj = self.findImageByEncodedURLInArray(parent.data('encodedurl'), allImages);
                    currentAllImagesObj.cover = 0;
                    self.imageList.remove("index", parent.data('index'));
                    groupListItem.find('.js-grouplistitem__groupcount').html(dataINTImages.length);
                    $.publish(self.options.events.imagechange, {});
                    $(self.imageList.list).children().each(function(k,v) {
                        $(this).attr('data-index', k);
                    });
                    self.imageList.reIndex();
                });
        }

        return false;
    };

    ctx.ImageList.prototype.handleClearSearchButtonClick = function($target) {
        var $searchInput = this.$imageListContainer.find('.' + this.options.jsclass.imageListSearchInput);
        $searchInput.val('');
        this.imageList.search();
    };

    ctx.ImageList.prototype.handlePreviewImageClick = function($target) {

        var imageGroup = $target.data(this.options.dataAttr.imageGroup) || '';
        var imageIndex = $target.attr('data-' + this.options.dataAttr.imageIndex) || '';

        if (imageGroup === '' || imageIndex === '') {
            return false;
        }

        var data = this.data[this.options.propKeys.groupValues.groupList];

        var imageInfo = {
            group: imageGroup
            ,index: imageIndex
            ,data: data[imageGroup][this.options.propKeys.groupValues.images][imageIndex]
        };

        if (!data.hasOwnProperty(imageGroup)
            || typeof data[imageGroup][this.options.propKeys.groupValues.images][imageIndex] === 'undefined') {
            return false;
        }
        $.publish(this.options.events.imagechange, imageInfo);
        this.setActivePreviewImage(imageIndex);
     };

    ctx.ImageList.prototype.setActivePreviewImage = function(imageIndex) {
        imageIndex = imageIndex || false;
        if (!imageIndex) {
            return false;
        }

        var oldActive = this.imageList.get(this.options.dataAttr.imageIsActive, true);
        if (oldActive.length > 0) {
            var oldActiveValues = oldActive[0].values();
            oldActiveValues[this.options.dataAttr.imageIsActive] = false;
            oldActive[0].values(oldActiveValues);
        } else {
            $(this.imageList.list).children().each(function() {
                $(this).attr('data-active', false);
            });
        }

        var newActive = this.imageList.get(this.options.dataAttr.imageIndex, imageIndex);
        if (newActive.length > 0) {
            newActive = newActive[0];
            var newActiveValues = newActive.values();
            newActiveValues[this.options.dataAttr.imageIsActive] = true;
            newActive.values(newActiveValues);
            return true;
        }
        return false;
    };

    ctx.ImageList.prototype.initImageList = function() {
        var imageListOptions = {
            valueNames: [
                this.options.jsclass.imageListPreviewDescription
                ,{ data: [this.options.dataAttr.imageGroup]}
                ,{ data: [this.options.dataAttr.imageIndex]}
                ,{ data: [this.options.dataAttr.imageIsCover]}
                ,{ data: [this.options.dataAttr.imageIsDuplicateOf]}
                ,{ data: [this.options.dataAttr.imageIsActive]}
                ,{ data: [this.options.dataAttr.imageOriginalUrl]}
                ,{ data: [this.options.dataAttr.imageEncodedUrl]}
                ,{ data: [this.options.dataAttr.imageIsINT]}
                ,{ data: [this.options.dataAttr.imageKey]}
                ,{ data: [this.options.dataAttr.imageIsDeletable]}
                ,{ name: this.options.jsclass.imageListPreviewImageAlt, attr: this.options.attr.alt }
                ,{ name: this.options.jsclass.imageListPreviewImageTitle, attr: this.options.attr.title }
                ,{ name: this.options.jsclass.imageListPreviewImage, attr: this.options.attr.src }
            ]
            ,listClass: this.options.jsclass.imageList
            ,item: this.options.imageListTemplate
        };
        this.imageList = new List(this.options.imageListContainerId, imageListOptions);
        return !!this.imageList;
    };

    ctx.ImageList.prototype.getExports = function() {
        return {
        };
    };

    ctx.ImageList.prototype.setInitialData = function() {
        if (this.options.values) {
            this.data = this.options.values;
            return this.updateImageList(this.options.values);
        }
        return false;
    };

    ctx.ImageList.prototype.updateImageList = function() {
        var self = this;
        var dataINTEncodedURLS = [];
        var dataINTImages = [];
        if (!this.hasOwnProperty('data')
            || !this.data.hasOwnProperty(this.options.propKeys.groupValues.groupList)
            || this.data[this.options.propKeys.groupValues.groupList].length === 0) {
            return false;
        }
        var data = this.data[this.options.propKeys.groupValues.groupList];
        if (this.data[this.options.propKeys.groupValues.groupList][internalSupplier]) {
            dataINTImages = this.data[this.options.propKeys.groupValues.groupList][internalSupplier][this.options.propKeys.groupValues.images];
        }

        if (!this.hasOwnProperty('groups')) {
            this.groups = Object.keys(data);
        }

        this.imageList.clear();

        if (!this.hasOwnProperty('currentGroup')
            || this.groups.indexOf(this.currentGroup) === -1) {
            this.currentGroup = this.groups[0];
        }

        if (!data[this.currentGroup].hasOwnProperty(this.options.propKeys.groupValues.images)
            || data[this.currentGroup][this.options.propKeys.groupValues.images].length < 1) {
            return false;
        }

        if (dataINTImages.length > 0) {
            $(dataINTImages).each(function(k,v) {
                dataINTEncodedURLS.push($(this)[0].encodedurl);
            });
        }
        data[this.currentGroup][this.options.propKeys.groupValues.images].forEach(function(element, index, array) {

            var text = element.name || Translator.trans('contentstore.rooms.nodescription', {}, 'cms');
            var description = text;
            if (element.cover) {
                description = Translator.trans('contentstore.rooms.teaser', {}, 'cms');
            }
            var src = self.options.parmCdnBaseUrl + element.encodedurl + '/' + self.options.parmPreviewImageSize + 'x' + self.options.parmPreviewImageSize + '/smart/' + element.encodedoriginalurl;
            var item = {};
            var duplicateOf = element.hasOwnProperty(self.options.propKeys.groupValues.isDuplicateOf)
                ? element[self.options.propKeys.groupValues.isDuplicateOf]
                : '';
            var originalUrl = element.hasOwnProperty(self.options.propKeys.groupValues.originurl)
                ? element[self.options.propKeys.groupValues.originurl]
                : '';
            var encodedURL = element.hasOwnProperty(self.options.propKeys.groupValues.encodedurl)
                ? element[self.options.propKeys.groupValues.encodedurl]
                : '';
            var key = element.hasOwnProperty(self.options.propKeys.groupValues.key)
                ?  element[self.options.propKeys.groupValues.key]
                : '';
            var isCover = element.cover;
            if (dataINTEncodedURLS.indexOf(encodedURL) !== -1 && self.currentGroup !== internalSupplier) {
                item[self.options.dataAttr.imageIsINT] = true;
                if (self.currentGroup === 'allImages') {
                    var currentINTObj = dataINTImages[dataINTEncodedURLS.indexOf(encodedURL)]
                       ,currentAllImagesObj = self.findImageByEncodedURLInArray(encodedURL, data[self.currentGroup][self.options.propKeys.groupValues.images]);
                    isCover = currentINTObj.cover;
                    currentAllImagesObj.cover = isCover;
                    if (isCover) {
                        description = Translator.trans('contentstore.rooms.teaser', {}, 'cms');
                    }

                }
            }

            if (self.currentGroup === internalSupplier) {
                item[self.options.dataAttr.imageIsDeletable] = true;
            }

            item[self.options.jsclass.imageListPreviewDescription] = description;
            item[self.options.dataAttr.imageGroup] = self.currentGroup;
            item[self.options.dataAttr.imageIndex] = index;
            item[self.options.dataAttr.imageIsCover] = isCover;
            item[self.options.dataAttr.imageOriginalUrl] = originalUrl;
            item[self.options.dataAttr.imageIsDuplicateOf] = duplicateOf;
            item[self.options.dataAttr.imageEncodedUrl] = encodedURL;
            item[self.options.dataAttr.imageKey] = key;
            item[self.options.jsclass.imageListPreviewImageAlt] = text;
            item[self.options.dataAttr.imageIsActive] = false;
            item[self.options.jsclass.imageListPreviewImageTitle] = text;
            item[self.options.jsclass.imageListPreviewImage] = src;


            self.imageList.add(item);

            if (self.options.imageListSort) {
                var sortName = false,
                    sortOptions = {};
                if (self.options.imageListSort.hasOwnProperty('name') && self.options.imageListSort.name) {
                    sortName = self.options.imageListSort.name;
                    sortOptions = self.options.imageListSort.hasOwnProperty('options') ? self.options.imageListSort.options : {};
                    self.imageList.sort(sortName, sortOptions);
                }
            }
        });
        this.initImagesLoaded();
        return true;
    };

    ctx.ImageList.prototype.initImagesLoaded = function() {
        var self = this;
        var dataAllImages = [];
        var dataINTImages = [];
        if (this.data[this.options.propKeys.groupValues.groupList]['allImages']) {
            dataAllImages = this.data[this.options.propKeys.groupValues.groupList]['allImages'][this.options.propKeys.groupValues.images];
        }
        if (this.data[this.options.propKeys.groupValues.groupList][internalSupplier]) {
            dataINTImages = this.data[this.options.propKeys.groupValues.groupList][internalSupplier][this.options.propKeys.groupValues.images];
        }
        this.$imageList.imagesLoaded()
            .progress( function( instance, image ) {
                if (!image.isLoaded) {
                    self.setImageLoadError.call(self, image);
                }
            })
            .done( function( instance ) {
                self.initImageSet(instance, dataINTImages, dataAllImages);
            })
            .fail(function(instance){
                if (instance.isComplete && instance.images && instance.images.length > 0) {
                    self.initImageSet(instance, dataINTImages, dataAllImages);
                }
            });
    };

    ctx.ImageList.prototype.initImageSet = function(instance, dataINTImages, dataAllImages) {
        var self = this
            ,loaderTargetContainer = $(instance.elements[0]).parents('#contentimagespresenter')
            ,groupId = loaderTargetContainer.data('groupid');
        if (self.currentGroup !== internalSupplier) {
            if (dataAllImages.length > 0) {
                self.initDraggable(loaderTargetContainer.find('.imagelist'));
                self.initDroppable(loaderTargetContainer, groupId, dataINTImages, dataAllImages);
            }
        } else {
            if (dataINTImages.length > 0) {
                self.setSortable(groupId, dataINTImages, loaderTargetContainer);
            }
        }
    };

    ctx.ImageList.prototype.initDraggable = function(container) {
        $( "li",  container).draggable({
            cancel: "a.ui-icon"
            ,revert: "valid"
            ,containment: "document"
            ,helper: "clone"
            ,cursor: "move"
        });
    };

    ctx.ImageList.prototype.initDroppable = function(container, groupId, dataINTImages, dataAllImages) {
        var self = this;
        container.find('#content__detailsimagescontainer-dropfield').droppable({
            accept: ".imagelist > li"
            ,hoverClass: 'drop-hover'
            ,drop: function( event, ui ) {
                var dropField = $(this)
                    ,li = $(ui.draggable)
                    ,encodedurl = li.data('encodedurl')
                    ,groupListItem = $('.js-grouplist').find('[data-groupkey="' + internalSupplier + '"]')
                    ,currentImage = self.findImageByEncodedURLInArray(encodedurl, dataAllImages);

                if (!self.isObjectInData(encodedurl, dataINTImages) && groupId && currentImage) {
                    li.attr('data-isint', true);
                    dataINTImages.push(currentImage);
                    self.savePreferredImages(groupId, dataINTImages, container, "addimages")
                        .done(function(data) {
                            dropField
                                .removeClass('content__detailsimagescontainer-dropfieldduplicate')
                                .addClass( "content__detailsimagescontainer-dropfieldadded" )
                                .find( "p" )
                                .html( Translator.trans('def.images.dropfield.addedtoint', {}, 'cms') );
                            groupListItem.find('.js-grouplistitem__groupcount').html(dataINTImages.length);
                            groupListItem.find('button').addClass('content__detailsimagescontainer--grouplistitemchanged');
                            groupListItem.effect('pulsate', {times:3}, 1000, function() {
                                groupListItem.find('button').removeClass('content__detailsimagescontainer--grouplistitemchanged');
                            });
                            self.replaceDropField(dropField, 1000);
                        });
                } else {
                    dropField
                        .removeClass( "content__detailsimagescontainer-dropfieldadded" )
                        .addClass('content__detailsimagescontainer-dropfieldduplicate')
                        .find( "p" )
                        .html( Translator.trans('def.images.dropfield.alreadyinint', {}, 'cms') );
                    self.replaceDropField(dropField, 1000);
                }
            }
        });
    };

    ctx.ImageList.prototype.removeFromArray = function(encodedurl, dataArray) {
        var index = false;
        for (var idx in dataArray) {
            if (typeof dataArray[idx] === 'undefined') {
                dataArray.splice(idx, 1);
                continue;
            }
            if (!encodedurl || !dataArray[idx].encodedurl) {
                continue;
            }
            if (encodedurl == dataArray[idx].encodedurl) {
                index = idx;
                break;
            }
        }
        if (index !== false) {
            dataArray.splice(index, 1);
            return true;
        }
        return false;
    };

    ctx.ImageList.prototype.findImageByEncodedURLInArray = function(encodedurl, dataArray) {
        for (var index in dataArray) {
            if (encodedurl == dataArray[index].encodedurl) {
                return dataArray[index];
            }
        }
        return false;
    };

    ctx.ImageList.prototype.savePreferredImages = function(groupId, dataINTImages, loaderTargetContainer, loaderKey) {
        var self = this;
        return $.ajax(Routing.generate("cms_contentstore_savepreferredimages"), {
            type: "POST"
            ,data: {
                'goalid': groupId
                ,'previewsize': self.defaults.parmPreviewImageSize
                ,'preferredimages': dataINTImages
            }
            ,beforeSend: function(e, xhr, options) {
                if (loaderKey) {
                    e.loaderKey = loaderKey;
                    self.loader.startLoader(e.loaderKey, loaderTargetContainer);
                }
            }
        });
    };

    ctx.ImageList.prototype.setSortable = function(groupId, dataINTImages, loaderTargetContainer) {
        var self = this;
        loaderTargetContainer.find('.imagelist').sortable({
            update: function(event, ui) {
                var item = ui.item
                    ,newIndex = item.index()
                    ,oldIndex = item.attr('data-index')
                    ,removedElement = dataINTImages.splice(oldIndex, 1)[0];
                if (removedElement) {
                    dataINTImages.splice(newIndex, 0, removedElement);
                    $(event.target).children().each(function(k,v) {
                        $(this).attr('data-index', k);
                    });
                    self.imageList.reIndex();
                    self.savePreferredImages(groupId, dataINTImages, loaderTargetContainer, 'saveorder')
                    .done(function(data) {
                    });
                }
            }
        });
    };

    ctx.ImageList.prototype.replaceDropField = function(container, duration) {
        setTimeout(function(){
            container
                .removeClass( "content__detailsimagescontainer-dropfieldadded" )
                .removeClass('content__detailsimagescontainer-dropfieldduplicate')
                .html(
                    $('<p/>'
                        ,{
                            'class':'content__detailsimagescontainer-dropfieldtitle'
                            ,'text': Translator.trans('def.images.dropfield.addtoint'
                                , {}, 'cms')
                        }
                    ).prepend(
                        $('<span/>', {'class':'fa fa-plus'})
                    )
                );
        }, duration);
    };

    ctx.ImageList.prototype.isObjectInData = function(encodedurl, dataArray) {
        for (var index in dataArray) {
            if (encodedurl == dataArray[index].encodedurl) {
                return true;
            }
        }
        return false;
    };

    ctx.ImageList.prototype.setImageLoadError = function(image) {
        var $img = $(image.img);
        $parent = $img.parent('.' + this.options.jsclass.imageListPreview);
        $img.hide();
        $parent.prepend($('<i />', {
            class: 'fa fa-ban ' + this.options.jsclass.imageListPreviewImageNoneClass
            ,title: Translator.trans('contentstore.rooms.imageinfo.notavailable', {}, 'cms')
        }));
    }

})();
