(function (Translator) {
    // en
    Translator.add("def.term.name", "Name", "cms", "en");
    Translator.add("def.term.street", "Street", "cms", "en");
    Translator.add("def.term.zipcode", "Zip code", "cms", "en");
    Translator.add("def.term.city", "City", "cms", "en");
    Translator.add("def.term.region", "Region", "cms", "en");
    Translator.add("def.term.politicalregion", "Political Region", "cms", "en");
    Translator.add("def.term.countrycode", "Country code", "cms", "en");
    Translator.add("def.term.status", "Status", "cms", "en");
    Translator.add("def.term.action", "Action", "cms", "en");
    Translator.add("def.term.unknown", "Unknown", "cms", "en");
    Translator.add("def.term.none", "None", "cms", "en");
    Translator.add("def.term.goalid", "Goal Id", "cms", "en");
    Translator.add("def.term.giataid", "Giata Id", "cms", "en");
    Translator.add("def.term.suppliercode", "Supplier code", "cms", "en");
    Translator.add("def.term.productcode", "Product code", "cms", "en");
    Translator.add("def.term.providertype", "Provider type", "cms", "en");
    Translator.add("def.term.arrivalairport", "Arrival airport", "cms", "en");
    Translator.add("def.term.short.goalid", "Goal Id", "cms", "en");
    Translator.add("def.term.short.giataid", "Giata Id", "cms", "en");
    Translator.add("def.term.short.suppliercode", "Sup. Code", "cms", "en");
    Translator.add("def.term.short.productcode", "Prod. code", "cms", "en");
    Translator.add("def.term.short.arrivalairport", "Arrivals", "cms", "en");
    Translator.add("def.term.choice.goalid", "{0}Goal Ids|{1}Goal Id|{2,Inf}Goal Ids", "cms", "en");
    Translator.add("def.term.choice.giataid", "{0}Giata Ids|{1}Giata Id|{2,Inf}Giata Ids", "cms", "en");
    Translator.add("def.term.choice.suppliercode", "{0}Supplier Codes|{1}Supplier Code|{2,Inf}Supplier Codes", "cms", "en");
    Translator.add("def.term.choice.productcode", "{0}Productcodes|{1}Productcode|{2,Inf}Productcodes", "cms", "en");
    Translator.add("def.term.choice.providertype", "{0}Provider types|{1}Provider type|{2,Inf}Provider types", "cms", "en");
    Translator.add("def.term.choice.arrivalairport", "{0}Arrivalairports|{1}Arrivalairport|{2,Inf}Arrivalairports", "cms", "en");
    Translator.add("def.term.choice.short.goalid", "{0}Goal Ids|{1}Goal Id|{2,Inf}Goal Ids", "cms", "en");
    Translator.add("def.term.choice.short.giataid", "{0}Giata Ids|{1}Giata Id|{2,Inf}Giata Ids", "cms", "en");
    Translator.add("def.term.choice.short.suppliercode", "{0}Sup. Codes|{1}Sup. Code|{2,Inf}Sup. Codes", "cms", "en");
    Translator.add("def.term.choice.short.productcode", "{0}Prod. codes|{1}Prod. code|{2,Inf}Prod. codes", "cms", "en");
    Translator.add("def.term.choice.short.providertype", "{0}Prov. types|{1}Prov. type|{2,Inf}Prov. types", "cms", "en");
    Translator.add("def.term.choice.short.arrivalairport", "Arrivals", "cms", "en");
    Translator.add("def.term.success", "Success", "cms", "en");
    Translator.add("def.term.error", "Error", "cms", "en");
    Translator.add("def.verb.search", "search", "cms", "en");
    Translator.add("def.lang.code.ab", "Abkhazian", "cms", "en");
    Translator.add("def.lang.code.aa", "Afar", "cms", "en");
    Translator.add("def.lang.code.af", "Afrikaans", "cms", "en");
    Translator.add("def.lang.code.sq", "Albanian", "cms", "en");
    Translator.add("def.lang.code.am", "Amharic", "cms", "en");
    Translator.add("def.lang.code.ar", "Arabic", "cms", "en");
    Translator.add("def.lang.code.an", "Aragonese", "cms", "en");
    Translator.add("def.lang.code.hy", "Armenian", "cms", "en");
    Translator.add("def.lang.code.as", "Assamese", "cms", "en");
    Translator.add("def.lang.code.ay", "Aymara", "cms", "en");
    Translator.add("def.lang.code.az", "Azerbaijani", "cms", "en");
    Translator.add("def.lang.code.ba", "Bashkir", "cms", "en");
    Translator.add("def.lang.code.eu", "Basque", "cms", "en");
    Translator.add("def.lang.code.bn", "Bengali (Bangla)", "cms", "en");
    Translator.add("def.lang.code.dz", "Bhutani", "cms", "en");
    Translator.add("def.lang.code.bh", "Bihari", "cms", "en");
    Translator.add("def.lang.code.bi", "Bislama", "cms", "en");
    Translator.add("def.lang.code.br", "Breton", "cms", "en");
    Translator.add("def.lang.code.bg", "Bulgarian", "cms", "en");
    Translator.add("def.lang.code.my", "Burmese", "cms", "en");
    Translator.add("def.lang.code.be", "Byelorussian (Belarusian)", "cms", "en");
    Translator.add("def.lang.code.km", "Cambodian", "cms", "en");
    Translator.add("def.lang.code.ca", "Catalan", "cms", "en");
    Translator.add("def.lang.code.zh", "Chinese", "cms", "en");
    Translator.add("def.lang.code.co", "Corsican", "cms", "en");
    Translator.add("def.lang.code.hr", "Croatian", "cms", "en");
    Translator.add("def.lang.code.cs", "Czech", "cms", "en");
    Translator.add("def.lang.code.da", "Danish", "cms", "en");
    Translator.add("def.lang.code.nl", "Dutch", "cms", "en");
    Translator.add("def.lang.code.en", "English", "cms", "en");
    Translator.add("def.lang.code.eo", "Esperanto", "cms", "en");
    Translator.add("def.lang.code.et", "Estonian", "cms", "en");
    Translator.add("def.lang.code.fo", "Faeroese", "cms", "en");
    Translator.add("def.lang.code.fa", "Farsi", "cms", "en");
    Translator.add("def.lang.code.fj", "Fiji", "cms", "en");
    Translator.add("def.lang.code.fi", "Finnish", "cms", "en");
    Translator.add("def.lang.code.fr", "French", "cms", "en");
    Translator.add("def.lang.code.fy", "Frisian", "cms", "en");
    Translator.add("def.lang.code.gl", "Galician", "cms", "en");
    Translator.add("def.lang.code.gd", "Gaelic (Scottish)", "cms", "en");
    Translator.add("def.lang.code.gv", "Gaelic (Manx)", "cms", "en");
    Translator.add("def.lang.code.ka", "Georgian", "cms", "en");
    Translator.add("def.lang.code.de", "German", "cms", "en");
    Translator.add("def.lang.code.el", "Greek", "cms", "en");
    Translator.add("def.lang.code.kl", "Greenlandic", "cms", "en");
    Translator.add("def.lang.code.gn", "Guarani", "cms", "en");
    Translator.add("def.lang.code.gu", "Gujarati", "cms", "en");
    Translator.add("def.lang.code.ht", "Haitian Creole", "cms", "en");
    Translator.add("def.lang.code.ha", "Hausa", "cms", "en");
    Translator.add("def.lang.code.he", "Hebrew", "cms", "en");
    Translator.add("def.lang.code.iw", "Hebrew", "cms", "en");
    Translator.add("def.lang.code.hi", "Hindi", "cms", "en");
    Translator.add("def.lang.code.hu", "Hungarian", "cms", "en");
    Translator.add("def.lang.code.is", "Icelandic", "cms", "en");
    Translator.add("def.lang.code.io", "Ido", "cms", "en");
    Translator.add("def.lang.code.id", "Indonesian", "cms", "en");
    Translator.add("def.lang.code.in", "Indonesian", "cms", "en");
    Translator.add("def.lang.code.ia", "Interlingua", "cms", "en");
    Translator.add("def.lang.code.ie", "Interlingue", "cms", "en");
    Translator.add("def.lang.code.iu", "Inuktitut", "cms", "en");
    Translator.add("def.lang.code.ik", "Inupiak", "cms", "en");
    Translator.add("def.lang.code.ga", "Irish", "cms", "en");
    Translator.add("def.lang.code.it", "Italian", "cms", "en");
    Translator.add("def.lang.code.ja", "Japanese", "cms", "en");
    Translator.add("def.lang.code.jv", "Javanese", "cms", "en");
    Translator.add("def.lang.code.kn", "Kannada", "cms", "en");
    Translator.add("def.lang.code.ks", "Kashmiri", "cms", "en");
    Translator.add("def.lang.code.kk", "Kazakh", "cms", "en");
    Translator.add("def.lang.code.rw", "Kinyarwanda (Ruanda)", "cms", "en");
    Translator.add("def.lang.code.ky", "Kirghiz", "cms", "en");
    Translator.add("def.lang.code.rn", "Kirundi (Rundi)", "cms", "en");
    Translator.add("def.lang.code.ko", "Korean", "cms", "en");
    Translator.add("def.lang.code.ku", "Kurdish", "cms", "en");
    Translator.add("def.lang.code.lo", "Laothian", "cms", "en");
    Translator.add("def.lang.code.la", "Latin", "cms", "en");
    Translator.add("def.lang.code.lv", "Latvian (Lettish)", "cms", "en");
    Translator.add("def.lang.code.li", "Limburgish ( Limburger)", "cms", "en");
    Translator.add("def.lang.code.ln", "Lingala", "cms", "en");
    Translator.add("def.lang.code.lt", "Lithuanian", "cms", "en");
    Translator.add("def.lang.code.mk", "Macedonian", "cms", "en");
    Translator.add("def.lang.code.mg", "Malagasy", "cms", "en");
    Translator.add("def.lang.code.ms", "Malay", "cms", "en");
    Translator.add("def.lang.code.ml", "Malayalam", "cms", "en");
    Translator.add("def.lang.code.mt", "Maltese", "cms", "en");
    Translator.add("def.lang.code.mi", "Maori", "cms", "en");
    Translator.add("def.lang.code.mr", "Marathi", "cms", "en");
    Translator.add("def.lang.code.mo", "Moldavian", "cms", "en");
    Translator.add("def.lang.code.mn", "Mongolian", "cms", "en");
    Translator.add("def.lang.code.na", "Nauru", "cms", "en");
    Translator.add("def.lang.code.ne", "Nepali", "cms", "en");
    Translator.add("def.lang.code.no", "Norwegian", "cms", "en");
    Translator.add("def.lang.code.oc", "Occitan", "cms", "en");
    Translator.add("def.lang.code.or", "Oriya", "cms", "en");
    Translator.add("def.lang.code.om", "Oromo (Afaan Oromo)", "cms", "en");
    Translator.add("def.lang.code.ps", "Pashto (Pushto)", "cms", "en");
    Translator.add("def.lang.code.pl", "Polish", "cms", "en");
    Translator.add("def.lang.code.pt", "Portuguese", "cms", "en");
    Translator.add("def.lang.code.pa", "Punjabi", "cms", "en");
    Translator.add("def.lang.code.qu", "Quechua", "cms", "en");
    Translator.add("def.lang.code.rm", "Rhaeto-Romance", "cms", "en");
    Translator.add("def.lang.code.ro", "Romanian", "cms", "en");
    Translator.add("def.lang.code.ru", "Russian", "cms", "en");
    Translator.add("def.lang.code.sm", "Samoan", "cms", "en");
    Translator.add("def.lang.code.sg", "Sangro", "cms", "en");
    Translator.add("def.lang.code.sa", "Sanskrit", "cms", "en");
    Translator.add("def.lang.code.sr", "Serbian", "cms", "en");
    Translator.add("def.lang.code.sh", "Serbo-Croatian", "cms", "en");
    Translator.add("def.lang.code.st", "Sesotho", "cms", "en");
    Translator.add("def.lang.code.tn", "Setswana", "cms", "en");
    Translator.add("def.lang.code.sn", "Shona", "cms", "en");
    Translator.add("def.lang.code.ii", "Sichuan Yi", "cms", "en");
    Translator.add("def.lang.code.sd", "Sindhi", "cms", "en");
    Translator.add("def.lang.code.si", "Sinhalese", "cms", "en");
    Translator.add("def.lang.code.ss", "Siswati", "cms", "en");
    Translator.add("def.lang.code.sk", "Slovak", "cms", "en");
    Translator.add("def.lang.code.sl", "Slovenian", "cms", "en");
    Translator.add("def.lang.code.so", "Somali", "cms", "en");
    Translator.add("def.lang.code.es", "Spanish", "cms", "en");
    Translator.add("def.lang.code.su", "Sundanese", "cms", "en");
    Translator.add("def.lang.code.sw", "Swahili (Kiswahili)", "cms", "en");
    Translator.add("def.lang.code.sv", "Swedish", "cms", "en");
    Translator.add("def.lang.code.tl", "Tagalog", "cms", "en");
    Translator.add("def.lang.code.tg", "Tajik", "cms", "en");
    Translator.add("def.lang.code.ta", "Tamil", "cms", "en");
    Translator.add("def.lang.code.tt", "Tatar", "cms", "en");
    Translator.add("def.lang.code.te", "Telugu", "cms", "en");
    Translator.add("def.lang.code.th", "Thai", "cms", "en");
    Translator.add("def.lang.code.bo", "Tibetan", "cms", "en");
    Translator.add("def.lang.code.ti", "Tigrinya", "cms", "en");
    Translator.add("def.lang.code.to", "Tonga", "cms", "en");
    Translator.add("def.lang.code.ts", "Tsonga", "cms", "en");
    Translator.add("def.lang.code.tr", "Turkish", "cms", "en");
    Translator.add("def.lang.code.tk", "Turkmen", "cms", "en");
    Translator.add("def.lang.code.tw", "Twi", "cms", "en");
    Translator.add("def.lang.code.ug", "Uighur", "cms", "en");
    Translator.add("def.lang.code.uk", "Ukrainian", "cms", "en");
    Translator.add("def.lang.code.ur", "Urdu", "cms", "en");
    Translator.add("def.lang.code.uz", "Uzbek", "cms", "en");
    Translator.add("def.lang.code.vi", "Vietnamese", "cms", "en");
    Translator.add("def.lang.code.vo", "Volap\u00fck", "cms", "en");
    Translator.add("def.lang.code.wa", "Wallon", "cms", "en");
    Translator.add("def.lang.code.cy", "Welsh", "cms", "en");
    Translator.add("def.lang.code.wo", "Wolof", "cms", "en");
    Translator.add("def.lang.code.xh", "Xhosa", "cms", "en");
    Translator.add("def.lang.code.yi", "Yiddish", "cms", "en");
    Translator.add("def.lang.code.ji", "Yiddish", "cms", "en");
    Translator.add("def.lang.code.yo", "Yoruba", "cms", "en");
    Translator.add("def.lang.code.zu", "Zulu", "cms", "en");
    Translator.add("def.button.cvsdl.title", "Download CSV source data", "cms", "en");
    Translator.add("def.button.confirm.title", "Ok", "cms", "en");
    Translator.add("def.button.cancel.title", "Cancel", "cms", "en");
    Translator.add("def.filter.suppliercode.all", "(ALL)", "cms", "en");
    Translator.add("def.filter.suppliercode.int", "internalSupplier (Internal)", "cms", "en");
    Translator.add("def.imageinfo.nodescription", "(no description)", "cms", "en");
    Translator.add("def.imageinfo.filterbutton", "Filter", "cms", "en");
    Translator.add("def.imageinfo.title.description", "Description", "cms", "en");
    Translator.add("def.imageinfo.title.isduplicateof", "Duplicate of", "cms", "en");
    Translator.add("def.imageinfo.title.tags", "Tags", "cms", "en");
    Translator.add("def.imageinfo.title.category", "Category", "cms", "en");
    Translator.add("def.imageinfo.title.iscover", "Is Teaser Image", "cms", "en");
    Translator.add("def.images.title", "Images", "cms", "en");
    Translator.add("def.images.supplier.allimages", "All Images", "cms", "en");
    Translator.add("def.images.dropfield.addtoint", " Drop Here To Add To Internal", "cms", "en");
    Translator.add("def.images.dropfield.addedtoint", "Successfully Added To Internal!", "cms", "en");
    Translator.add("def.images.dropfield.alreadyinint", "Already In Internal!", "cms", "en");
    Translator.add("def.images.buttons.remove.title", "Remove Image", "cms", "en");
    Translator.add("def.images.buttons.teaser.title", "Change Teaser Status", "cms", "en");
    Translator.add("def.images.addimage.placeholder", "Add Image URL", "cms", "en");
    Translator.add("def.images.addimage.label", "Add Image URL", "cms", "en");
    Translator.add("def.images.warning.unavailable.text", "Some images might not be available.", "cms", "en");
    Translator.add("def.images.warning.unavailable.linktext", "More Information", "cms", "en");
    Translator.add("contentstore.navigation", "Content store", "cms", "en");
    Translator.add("contentstore.filter.title", "Filter", "cms", "en");
    Translator.add("contentstore.filter.search", "Search", "cms", "en");
    Translator.add("contentstore.filter.reset", "Reset", "cms", "en");
    Translator.add("contentstore.filter.goalid", "GOAL ID", "cms", "en");
    Translator.add("contentstore.filter.giataid", "GIATA ID", "cms", "en");
    Translator.add("contentstore.filter.productname", "Name", "cms", "en");
    Translator.add("contentstore.filter.tocode", "Supplier code", "cms", "en");
    Translator.add("contentstore.filter.productcode", "Product code", "cms", "en");
    Translator.add("contentstore.filter.source", "Source", "cms", "en");
    Translator.add("contentstore.filter.dlc", "Arrival airports", "cms", "en");
    Translator.add("contentstore.filter.stars", "Category", "cms", "en");
    Translator.add("contentstore.filter.language", "Language", "cms", "en");
    Translator.add("contentstore.filter.productlistgrouping", "Group results", "cms", "en");
    Translator.add("contentstore.filter.renderplainlist", "no", "cms", "en");
    Translator.add("contentstore.filter.rendergroupedlist", "yes", "cms", "en");
    Translator.add("contentstore.filter.groupid", "Master Id", "cms", "en");
    Translator.add("contentstore.filter.preferences", "Filter Preferences", "cms", "en");
    Translator.add("contentstore.filter.any", "", "cms", "en");
    Translator.add("contentstore.filter.zero", "0.0", "cms", "en");
    Translator.add("contentstore.filter.zerofive", "0.5", "cms", "en");
    Translator.add("contentstore.filter.one", "1.0", "cms", "en");
    Translator.add("contentstore.filter.onefive", "1.5", "cms", "en");
    Translator.add("contentstore.filter.two", "2.0", "cms", "en");
    Translator.add("contentstore.filter.twofive", "2.5", "cms", "en");
    Translator.add("contentstore.filter.three", "3.0", "cms", "en");
    Translator.add("contentstore.filter.threefive", "3.5", "cms", "en");
    Translator.add("contentstore.filter.four", "4.0", "cms", "en");
    Translator.add("contentstore.filter.fourfive", "4.5", "cms", "en");
    Translator.add("contentstore.filter.five", "5.0", "cms", "en");
    Translator.add("contentstore.filter.fivefive", "5.5", "cms", "en");
    Translator.add("contentstore.filter.six", "6.0", "cms", "en");
    Translator.add("contentstore.filter.modal.download.title", "Download", "cms", "en");
    Translator.add("contentstore.filter.modal.download.csvtitle", "CSV Download", "cms", "en");
    Translator.add("contentstore.filter.modal.download.submit", "Download", "cms", "en");
    Translator.add("contentstore.filter.modal.download.prepare", "Prepare Downloadfile", "cms", "en");
    Translator.add("contentstore.filter.modal.download.request", "Request Downloadfile", "cms", "en");
    Translator.add("contentstore.filter.modal.download.infomessage", "Your request is in process!", "cms", "en");
    Translator.add("contentstore.filter.modal.download.languages.included", "Included Language", "cms", "en");
    Translator.add("contentstore.filter.modal.download.languages.excluded", "Excluded Language", "cms", "en");
    Translator.add("contentstore.filter.modal.upload.request", "Upload File", "cms", "en");
    Translator.add("contentstore.details.title", "Details", "cms", "en");
    Translator.add("contentstore.details.showcompare", "Show comparison", "cms", "en");
    Translator.add("contentstore.details.header.langlist", "Languages", "cms", "en");
    Translator.add("contentstore.details.header.text", "Text", "cms", "en");
    Translator.add("contentstore.details.langlist.showall", "Show all", "cms", "en");
    Translator.add("contentstore.details.editor.switch", "Use WYSIWYG Editor", "cms", "en");
    Translator.add("contentstore.productdetails.title", "Product details", "cms", "en");
    Translator.add("contentstore.productdetails.grouptitle", "Groupdetails", "cms", "en");
    Translator.add("contentstore.productdetails.productcode", "Product code", "cms", "en");
    Translator.add("contentstore.productdetails.groupid", "Master Id", "cms", "en");
    Translator.add("contentstore.productdetails.code", "Product code", "cms", "en");
    Translator.add("contentstore.productdetails.name", "Name", "cms", "en");
    Translator.add("contentstore.productdetails.tocode", "Supplier code", "cms", "en");
    Translator.add("contentstore.productdetails.key", "Key", "cms", "en");
    Translator.add("contentstore.productdetails.airport", "Arrival airports", "cms", "en");
    Translator.add("contentstore.productdetails.giataid", "GIATA ID", "cms", "en");
    Translator.add("contentstore.productdetails.goalid", "GOAL ID", "cms", "en");
    Translator.add("contentstore.productdetails.editedf", "Edit", "cms", "en");
    Translator.add("contentstore.productdetails.stars", "Category", "cms", "en");
    Translator.add("contentstore.productdetails.setrelevant", "Relevant", "cms", "en");
    Translator.add("contentstore.productdetails.setirrelevant", "Irrelevant", "cms", "en");
    Translator.add("contentstore.productdetails.matchedkeys", "Matched productkeys", "cms", "en");
    Translator.add("contentstore.productdetails.rooms", "Room count", "cms", "en");
    Translator.add("contentstore.productdetails.street", "Street", "cms", "en");
    Translator.add("contentstore.productdetails.postalcode", "Postal code", "cms", "en");
    Translator.add("contentstore.productdetails.city", "City", "cms", "en");
    Translator.add("contentstore.productdetails.country", "Country", "cms", "en");
    Translator.add("contentstore.productdetails.email", "Email", "cms", "en");
    Translator.add("contentstore.productdetails.website", "Website", "cms", "en");
    Translator.add("contentstore.productdetails.phone", "Phone", "cms", "en");
    Translator.add("contentstore.productdetails.latlon", "Latitude\/Longitude", "cms", "en");
    Translator.add("contentstore.productdetails.tooltip.downloadedf", "Download EDF", "cms", "en");
    Translator.add("contentstore.productdetails.createdat", "Created at", "cms", "en");
    Translator.add("contentstore.productdetails.updatedat", "Updated at", "cms", "en");
    Translator.add("contentstore.productdetails.lastseen", "Last seen at", "cms", "en");
    Translator.add("contentstore.productdetails.editable", "Die mit (*) gekennzeichneten Felder sind editierbar!", "cms", "en");
    Translator.add("contentstore.producttable.title", "Products", "cms", "en");
    Translator.add("contentstore.producttable.groupedtitle", "Grouped products", "cms", "en");
    Translator.add("contentstore.producttable.paginate.next", "Next", "cms", "en");
    Translator.add("contentstore.producttable.paginate.previous", "Previous", "cms", "en");
    Translator.add("contentstore.producttable.info", "Showing _START_ to _END_ of&nbsp;_TOTAL_&nbsp;entries", "cms", "en");
    Translator.add("contentstore.producttable.emptyTable", "No Results", "cms", "en");
    Translator.add("contentstore.producttable.emptyinfo", "", "cms", "en");
    Translator.add("contentstore.producttable.goalid.title", "GOAL ID", "cms", "en");
    Translator.add("contentstore.producttable.productcode.title", "Product code", "cms", "en");
    Translator.add("contentstore.producttable.name.title", "Name", "cms", "en");
    Translator.add("contentstore.producttable.action.title", "Action", "cms", "en");
    Translator.add("contentstore.producttable.masterid.title", "Master Id", "cms", "en");
    Translator.add("contentstore.producttable.groupaction.title", "Group", "cms", "en");
    Translator.add("contentstore.producttable.airport.title", "Arrival airports", "cms", "en");
    Translator.add("contentstore.producttable.giataid.title", "GIATA ID", "cms", "en");
    Translator.add("contentstore.producttable.tocode.title", "Supplier code", "cms", "en");
    Translator.add("contentstore.producttable.key.title", "Key", "cms", "en");
    Translator.add("contentstore.producttable.group.label", "Display", "cms", "en");
    Translator.add("contentstore.producttable.group.UNUSEDactive", "Grouped", "cms", "en");
    Translator.add("contentstore.producttable.group.UNUSEDinactive", "List All", "cms", "en");
    Translator.add("contentstore.producttable.group.active", "Productgroups", "cms", "en");
    Translator.add("contentstore.producttable.group.inactive", "Products\/EDF", "cms", "en");
    Translator.add("contentstore.producttable.create.newedf", "Create New EDF", "cms", "en");
    Translator.add("contentstore.producttable.relevant.title", "Relevant", "cms", "en");
    Translator.add("contentstore.producttable.irelevant.title", "Irrelevant", "cms", "en");
    Translator.add("contentstore.producttable.edit", "Edit", "cms", "en");
    Translator.add("contentstore.producttable.show", "Show", "cms", "en");
    Translator.add("contentstore.producttable.editgroup", "Show Group", "cms", "en");
    Translator.add("contentstore.producttable.addgroup", "Add to Group", "cms", "en");
    Translator.add("contentstore.producttable.changegroup", "Change Group", "cms", "en");
    Translator.add("contentstore.producttable.jumptogoal", "Open Goal", "cms", "en");
    Translator.add("contentstore.producttable.status.failure", "Restart Import", "cms", "en");
    Translator.add("contentstore.languageoverview.title", "Language overview", "cms", "en");
    Translator.add("contentstore.contentoverview.title", "Content overview", "cms", "en");
    Translator.add("contentstore.contentoverview.images", "Images", "cms", "en");
    Translator.add("contentstore.contentoverview.source", "Source", "cms", "en");
    Translator.add("contentstore.contentoverview.language", "Language", "cms", "en");
    Translator.add("contentstore.contentoverview.imagecount", "# Images", "cms", "en");
    Translator.add("contentstore.contentoverview.editviewimages", "Edit\/View Images", "cms", "en");
    Translator.add("contentstore.contentoverview.viewimages", "View Images", "cms", "en");
    Translator.add("contentstore.contentoverview.edittext", "Edit Text", "cms", "en");
    Translator.add("contentstore.contentoverview.videos", "Clips", "cms", "en");
    Translator.add("contentstore.contentoverview.videocount", "# Clips", "cms", "en");
    Translator.add("contentstore.contentoverview.audio", "Audio", "cms", "en");
    Translator.add("contentstore.contentoverview.audiocount", "# Audio", "cms", "en");
    Translator.add("contentstore.contentoverview.featurestitle", "Product features", "cms", "en");
    Translator.add("contentstore.contentoverview.groupfeaturestitle", "Group Features and GlobalTypes", "cms", "en");
    Translator.add("contentstore.contentoverview.featurestab", "Features", "cms", "en");
    Translator.add("contentstore.contentoverview.globaltypestab", "GlobalTypes", "cms", "en");
    Translator.add("contentstore.contentoverview.editviewtexts", "Edit\/View Texts", "cms", "en");
    Translator.add("contentstore.contentoverview.viewtexts", "View Texts", "cms", "en");
    Translator.add("contentstore.contentoverview.rooms.title", "Rooms", "cms", "en");
    Translator.add("contentstore.contentoverview.rooms.tableheader", "Sources \/ Room codes", "cms", "en");
    Translator.add("contentstore.contentoverview.productglobaltypestitle", "Product GlobalTypes", "cms", "en");
    Translator.add("contentstore.contenteditform.retievingtext", "retrieving text ...", "cms", "en");
    Translator.add("contentstore.contenteditform.select", "Select", "cms", "en");
    Translator.add("contentstore.contenteditform.selectcategory", "Select category", "cms", "en");
    Translator.add("contentstore.contenteditform.synchronizetextfields", "Synchronize languages", "cms", "en");
    Translator.add("contentstore.contenteditform.selecttouroperator", "Select supplier code", "cms", "en");
    Translator.add("contentstore.contenteditform.editimages.title", "Images", "cms", "en");
    Translator.add("contentstore.contenteditform.editimages.masterimage", "TO is Master", "cms", "en");
    Translator.add("contentstore.contenteditform.editimages.coverimage", "Coverimage", "cms", "en");
    Translator.add("contentstore.contenteditform.editimages.beginediting", "Edit Image", "cms", "en");
    Translator.add("contentstore.contenteditform.editimages.reset", "Reset", "cms", "en");
    Translator.add("contentstore.contenteditform.editimages.update", "Update Crop Rect", "cms", "en");
    Translator.add("contentstore.contenteditform.editimages.doscale", "Scale Image to", "cms", "en");
    Translator.add("contentstore.contenteditform.editimages.aspect", "Keep Current Aspect Ratio", "cms", "en");
    Translator.add("contentstore.contenteditform.editimages.width", "Width", "cms", "en");
    Translator.add("contentstore.contenteditform.editimages.height", "Height", "cms", "en");
    Translator.add("contentstore.contenteditform.editimages.cancel", "Cancel\/Close", "cms", "en");
    Translator.add("contentstore.contenteditform.editimages.updatesorting", "Update Sorting", "cms", "en");
    Translator.add("contentstore.general.warning.emptygoalidmatching", "Warning: If you leave the Goal ID field empty, the matching to the current Goal ID will be removed.", "cms", "en");
    Translator.add("contentstore.general.buttons.assigntonewgoalid", "Assign To New Goal ID", "cms", "en");
    Translator.add("contentstore.general.buttons.addnewgoalid", "New GOAL ID", "cms", "en");
    Translator.add("contentstore.general.buttons.cancel", "Cancel", "cms", "en");
    Translator.add("contentstore.general.buttons.changegoalid", "Change Goal ID", "cms", "en");
    Translator.add("contentstore.general.buttons.close", "Close", "cms", "en");
    Translator.add("contentstore.general.buttons.save", "Save", "cms", "en");
    Translator.add("contentstore.general.feedback.unsavedcontent", "There seem to be unsaved updates (click bar to ignore)", "cms", "en");
    Translator.add("contentstore.general.feedback.update.success", "Update Successful", "cms", "en");
    Translator.add("contentstore.general.feedback.update.fail", "Update Failed", "cms", "en");
    Translator.add("contentstore.general.feedback.relevant.successtitle", "Relevant", "cms", "en");
    Translator.add("contentstore.general.feedback.relevant.successtext", "The Group has been successfully set to relevant!", "cms", "en");
    Translator.add("contentstore.general.feedback.irrelevant.successtitle", "Irrelevant", "cms", "en");
    Translator.add("contentstore.general.feedback.irrelevant.successtext", "The Group has been successfully set to irrelevant!", "cms", "en");
    Translator.add("contentstore.general.feedback.createedf.alreadyexiststitle", "Code already exists!", "cms", "en");
    Translator.add("contentstore.general.feedback.createedf.alreadyexistsmessage", "The Code you've entered already exists! Please enter another Code", "cms", "en");
    Translator.add("contentstore.general.feedback.descriptions.saved", "Texts saved.", "cms", "en");
    Translator.add("contentstore.general.feedback.descriptions.notsaved", "Texts could not be saved!", "cms", "en");
    Translator.add("contentstore.general.feedback.descriptions.intcreated", "Internal texts created.", "cms", "en");
    Translator.add("contentstore.general.feedback.assigngoalid.done", "A new GOAL ID has been successfully assigned.", "cms", "en");
    Translator.add("contentstore.general.feedback.assigntonewgoalid.confirmtext", "Do you really want to assign this product to a new Goal ID?", "cms", "en");
    Translator.add("contentstore.general.feedback.assigntonewgoalid.success", "The Assignment to a new Goal ID has been successfully done", "cms", "en");
    Translator.add("contentstore.general.feedback.removegoalidmatching.confirmtext", "Do you really want to remove the matching of the Goal ID (%goalid%) to the product (%supplier%, %productcode%)?", "cms", "en");
    Translator.add("contentstore.general.feedback.removegoalidmatching.success", "The matching has been successfully removed.", "cms", "en");
    Translator.add("contentstore.general.rematching.products.done", "The filtered products has been send to the matching service", "cms", "en");
    Translator.add("contentstore.general.rematching.products.send.confirmtext", "Do You Really Want To Rematch The Current Products?", "cms", "en");
    Translator.add("contentstore.featuretable.supplier", "Supplier code", "cms", "en");
    Translator.add("contentstore.featuretable.title", "Title", "cms", "en");
    Translator.add("contentstore.featuretable.text", "Text", "cms", "en");
    Translator.add("contentstore.featuretable.description", "Description", "cms", "en");
    Translator.add("contentstore.featuretable.globaltypes", "GlobalTypes", "cms", "en");
    Translator.add("contentstore.featuretable.subtypes", "SubTypes", "cms", "en");
    Translator.add("contentstore.featuretable.additionaltypes", "AdditionalTypes", "cms", "en");
    Translator.add("contentstore.rooms.paneltitle", "Rooms", "cms", "en");
    Translator.add("contentstore.rooms.header.roomlist", "Rooms", "cms", "en");
    Translator.add("contentstore.rooms.header.langlist", "Languages", "cms", "en");
    Translator.add("contentstore.rooms.header.details", "Details", "cms", "en");
    Translator.add("contentstore.rooms.header.texttab", "Texts", "cms", "en");
    Translator.add("contentstore.rooms.header.imgtab", "Images", "cms", "en");
    Translator.add("contentstore.rooms.header.images", "Images", "cms", "en");
    Translator.add("contentstore.rooms.header.imagedetail", "Image details", "cms", "en");
    Translator.add("contentstore.rooms.teaser", "Teaser", "cms", "en");
    Translator.add("contentstore.rooms.nodescription", "(no description)", "cms", "en");
    Translator.add("contentstore.rooms.imageinfo.notavailable", "Image not available", "cms", "en");
    Translator.add("contentstore.rooms.imageinfo.title.description", "Description", "cms", "en");
    Translator.add("contentstore.rooms.imageinfo.title.iscover", "Is Cover", "cms", "en");
    Translator.add("contentstore.map.title", "Map", "cms", "en");
    Translator.add("contentstore.map.buttons.goal.title", "Zoom To Goal", "cms", "en");
    Translator.add("contentstore.map.buttons.goal.text", "Zoom To Goal", "cms", "en");
    Translator.add("contentstore.map.buttons.giata.title", "Zoom To Giata", "cms", "en");
    Translator.add("contentstore.map.buttons.giata.text", "Zoom To Giata", "cms", "en");
    Translator.add("contentstore.map.buttons.defaultcenter.title", "Reset Zoom", "cms", "en");
    Translator.add("contentstore.map.buttons.defaultcenter.text", "Reset Zoom", "cms", "en");
    Translator.add("contentstore.map.infowindow.giata.title", "Giata", "cms", "en");
    Translator.add("contentstore.map.infowindow.giata.label.latitude", "Latitude", "cms", "en");
    Translator.add("contentstore.map.infowindow.giata.label.longitude", "Longitude", "cms", "en");
    Translator.add("contentstore.map.infowindow.goal.title", "Goal", "cms", "en");
    Translator.add("contentstore.map.infowindow.goal.label.latitude", "Latitude", "cms", "en");
    Translator.add("contentstore.map.infowindow.goal.label.longitude", "Longitude", "cms", "en");
    Translator.add("contentstore.map.infowindow.edf.title", "EDF", "cms", "en");
    Translator.add("contentstore.map.infowindow.edf.label.latitude", "Latitude", "cms", "en");
    Translator.add("contentstore.map.infowindow.edf.label.longitude", "Longitude", "cms", "en");
    Translator.add("contentstore.images.title", "Images", "cms", "en");
    Translator.add("contentstore.textdetails.title.edit", "Edit\/View Text", "cms", "en");
    Translator.add("contentstore.textdetails.title.view", "View Text", "cms", "en");
    Translator.add("contentstore.textdetails.delete.confirmtext", "Do you really want to delete the description for the language ", "cms", "en");
    Translator.add("contentstore.textdetails.delete.languagenotfound", "It was not possible to find the language that you want delete.", "cms", "en");
    Translator.add("contentstore.textdetails.delete.button", "Delete Internal Text", "cms", "en");
    Translator.add("contentstore.textdetails.deleteall.confirmtext", "Do you really want to delete all Internal descriptions", "cms", "en");
    Translator.add("contentstore.textdetails.deleteall.button", "Delete All Internal Texts", "cms", "en");
    Translator.add("contentstore.goalmatchinghistorytable.title", "Goal Matching History", "cms", "en");
    Translator.add("contentstore.goalmatchinghistorytable.goalid", "Goal Id", "cms", "en");
    Translator.add("contentstore.goalmatchinghistorytable.matchedat", "Matched at", "cms", "en");
    Translator.add("contentstore.goalmatchinghistorytable.matchingsource", "Matching source", "cms", "en");
    Translator.add("contentstore.goalmatchinghistorytable.productcode", "Product code", "cms", "en");
    Translator.add("contentstore.goalmatchinghistorytable.suppliercode", "Supplier code", "cms", "en");
    Translator.add("contentstore.goalmatchinghistorytable.tooltips.user", "User", "cms", "en");
    Translator.add("contentstore.goalmatchinghistorytable.tooltips.matchingservice", "Matching Service", "cms", "en");
    Translator.add("contentstore.goalmatchinghistorytable.tooltips.supplier", "Supplier", "cms", "en");
    Translator.add("contentstore.goalmatchinghistorytable.tooltips.roundtrip", "Roundtrip", "cms", "en");
    Translator.add("statistic.navigation.default", "Statistic", "cms", "en");
    Translator.add("statistic.navigation.translations", "Translations", "cms", "en");
    Translator.add("statistic.navigation.uploads", "Content Imports", "cms", "en");
    Translator.add("statistic.navigation.giataimports", "Giata imports", "cms", "en");
    Translator.add("statistic.navigation.matchingimports", "Matching imports", "cms", "en");
    Translator.add("statistic.navigation.cronjobs", "Cronjobs", "cms", "en");
    Translator.add("statistic.navigation.export", "Exports", "cms", "en");
    Translator.add("statistic.navigation.unavailableimages", "Unavailable Images", "cms", "en");
    Translator.add("statistic.overview.title", "Statistic", "cms", "en");
    Translator.add("statistic.filter.title", "Filter", "cms", "en");
    Translator.add("statistic.filter.send", "Send", "cms", "en");
    Translator.add("statistic.filter.dlc", "Arrivals", "cms", "en");
    Translator.add("statistic.filter.stars", "Category", "cms", "en");
    Translator.add("statistic.filter.any", "", "cms", "en");
    Translator.add("statistic.table.title", "Results: %totalcount%\/TEXT %language% - missing codes", "cms", "en");
    Translator.add("statistic.table.group.inactive", "List", "cms", "en");
    Translator.add("statistic.table.id", "Id", "cms", "en");
    Translator.add("statistic.table.name", "Name", "cms", "en");
    Translator.add("statistic.table.key", "Key", "cms", "en");
    Translator.add("statistic.table.tocode", "Supplier code", "cms", "en");
    Translator.add("statistic.table.code", "Code", "cms", "en");
    Translator.add("statistic.uploads.navigation", "Content Imports", "cms", "en");
    Translator.add("statistic.uploads.filter.title", "Filter", "cms", "en");
    Translator.add("statistic.uploads.filter.tocode", "Supplier code", "cms", "en");
    Translator.add("statistic.uploads.filter.from", "From", "cms", "en");
    Translator.add("statistic.uploads.filter.to", "To", "cms", "en");
    Translator.add("statistic.uploads.filter.success", "Success", "cms", "en");
    Translator.add("statistic.uploads.filter.successstate.label", "Success", "cms", "en");
    Translator.add("statistic.uploads.filter.successstate.none", "Ignore upload success state", "cms", "en");
    Translator.add("statistic.uploads.filter.successstate.yes", "Show successful uploads", "cms", "en");
    Translator.add("statistic.uploads.filter.successstate.no", "Show unsuccessful uploads", "cms", "en");
    Translator.add("statistic.uploads.table.title", "Content Imports", "cms", "en");
    Translator.add("statistic.uploads.table.tocode.title", "Supplier code", "cms", "en");
    Translator.add("statistic.uploads.table.filename.title", "Filename", "cms", "en");
    Translator.add("statistic.uploads.table.start.title", "Start", "cms", "en");
    Translator.add("statistic.uploads.table.end.title", "End", "cms", "en");
    Translator.add("statistic.uploads.table.updatedat.title", "Update Time", "cms", "en");
    Translator.add("statistic.uploads.table.filecount.title", "Total File Count", "cms", "en");
    Translator.add("statistic.uploads.table.filecountimported.title", "Imported File Count", "cms", "en");
    Translator.add("statistic.uploads.table.filecountskipped.title", "Skipped File Count", "cms", "en");
    Translator.add("statistic.uploads.table.success.title", "Success", "cms", "en");
    Translator.add("statistic.uploads.table.filecounterror.title", "Error File Count", "cms", "en");
    Translator.add("statistic.giataimports.navigation", "Giata imports", "cms", "en");
    Translator.add("statistic.giataimports.filter.title", "Filter", "cms", "en");
    Translator.add("statistic.giataimports.filter.successful", "Successful", "cms", "en");
    Translator.add("statistic.giataimports.filter.any", "", "cms", "en");
    Translator.add("statistic.giataimports.filter.istrue", "True", "cms", "en");
    Translator.add("statistic.giataimports.filter.isfalse", "False", "cms", "en");
    Translator.add("statistic.giataimports.filter.from", "From", "cms", "en");
    Translator.add("statistic.giataimports.filter.to", "To", "cms", "en");
    Translator.add("statistic.giataimports.filter.success.label", "Success", "cms", "en");
    Translator.add("statistic.giataimports.filter.success.none", "Ignore import success state", "cms", "en");
    Translator.add("statistic.giataimports.filter.success.yes", "Show successful imports", "cms", "en");
    Translator.add("statistic.giataimports.filter.success.no", "Show unsuccessful imports", "cms", "en");
    Translator.add("statistic.giataimports.table.title", "Giata imports", "cms", "en");
    Translator.add("statistic.giataimports.table.user.title", "User", "cms", "en");
    Translator.add("statistic.giataimports.table.imported.title", "Imported", "cms", "en");
    Translator.add("statistic.giataimports.table.citycount.title", "City count", "cms", "en");
    Translator.add("statistic.giataimports.table.regioncount.title", "Region count", "cms", "en");
    Translator.add("statistic.giataimports.table.countrycount.title", "Country count", "cms", "en");
    Translator.add("statistic.giataimports.table.started.title", "Started", "cms", "en");
    Translator.add("statistic.giataimports.table.finished.title", "Finished", "cms", "en");
    Translator.add("statistic.giataimports.table.success.title", "Success", "cms", "en");
    Translator.add("statistic.matchingimports.navigation", "Giata imports", "cms", "en");
    Translator.add("statistic.matchingimports.filter.title", "Filter", "cms", "en");
    Translator.add("statistic.matchingimports.filter.successful", "Successful", "cms", "en");
    Translator.add("statistic.matchingimports.filter.any", "", "cms", "en");
    Translator.add("statistic.matchingimports.filter.istrue", "True", "cms", "en");
    Translator.add("statistic.matchingimports.filter.isfalse", "False", "cms", "en");
    Translator.add("statistic.matchingimports.filter.from", "From", "cms", "en");
    Translator.add("statistic.matchingimports.filter.to", "To", "cms", "en");
    Translator.add("statistic.matchingimports.filter.source", "Source", "cms", "en");
    Translator.add("statistic.matchingimports.filter.success.label", "Success", "cms", "en");
    Translator.add("statistic.matchingimports.filter.success.none", "Ignore import success state", "cms", "en");
    Translator.add("statistic.matchingimports.filter.success.yes", "Show successful imports", "cms", "en");
    Translator.add("statistic.matchingimports.filter.success.no", "Show unsuccessful imports", "cms", "en");
    Translator.add("statistic.matchingimports.table.title", "Matching imports", "cms", "en");
    Translator.add("statistic.matchingimports.table.source.title", "Source", "cms", "en");
    Translator.add("statistic.matchingimports.table.totalobjects.title", "Total objects", "cms", "en");
    Translator.add("statistic.matchingimports.table.validobjects.title", "Valid objects", "cms", "en");
    Translator.add("statistic.matchingimports.table.badgc.title", "Bad GC", "cms", "en");
    Translator.add("statistic.matchingimports.table.goodmatches.title", "Good matches", "cms", "en");
    Translator.add("statistic.matchingimports.table.badmatches.title", "Bad matches", "cms", "en");
    Translator.add("statistic.matchingimports.table.nogoodmatches.title", "No good matches", "cms", "en");
    Translator.add("statistic.matchingimports.table.nomatches.title", "No matches", "cms", "en");
    Translator.add("statistic.matchingimports.table.success.title", "Success", "cms", "en");
    Translator.add("statistic.matchingimports.table.successgoodmatches.title", "Success good matches", "cms", "en");
    Translator.add("statistic.matchingimports.table.successbadmatches.title", "Success bad matches", "cms", "en");
    Translator.add("statistic.matchingimports.table.successnogoodmatches.title", "Success no good matches", "cms", "en");
    Translator.add("statistic.matchingimports.table.successnomatches.title", "Success no matches", "cms", "en");
    Translator.add("statistic.matchingimports.table.created.title", "Created", "cms", "en");
    Translator.add("statistic.matchingimports.table.matchingfinished.title", "Matching finished", "cms", "en");
    Translator.add("statistic.matchingimports.table.importfinished.title", "Import finished", "cms", "en");
    Translator.add("statistic.cronjobs.navigation", "Cronjobs", "cms", "en");
    Translator.add("statistic.cronjobs.filter.title", "Filter", "cms", "en");
    Translator.add("statistic.cronjobs.filter.name", "Name", "cms", "en");
    Translator.add("statistic.cronjobs.filter.type", "Type", "cms", "en");
    Translator.add("statistic.cronjobs.filter.from", "From", "cms", "en");
    Translator.add("statistic.cronjobs.filter.to", "To", "cms", "en");
    Translator.add("statistic.cronjobs.filter.success", "Success", "cms", "en");
    Translator.add("statistic.cronjobs.filter.successstate.label", "Success", "cms", "en");
    Translator.add("statistic.cronjobs.filter.successstate.none", "Ignore cronjob success state", "cms", "en");
    Translator.add("statistic.cronjobs.filter.successstate.yes", "Show successful cronjobs", "cms", "en");
    Translator.add("statistic.cronjobs.filter.successstate.no", "Show unsuccessful cronjobs", "cms", "en");
    Translator.add("statistic.cronjobs.table.title", "Cronjobs", "cms", "en");
    Translator.add("statistic.cronjobs.table.name.title", "Name", "cms", "en");
    Translator.add("statistic.cronjobs.table.type.title", "Type", "cms", "en");
    Translator.add("statistic.cronjobs.table.start.title", "Start", "cms", "en");
    Translator.add("statistic.cronjobs.table.end.title", "End", "cms", "en");
    Translator.add("statistic.cronjobs.table.success.title", "Success", "cms", "en");
    Translator.add("statistic.goalkeeperexports.filter.title", "Filter", "cms", "en");
    Translator.add("statistic.goalkeeperexports.filter.from", "From", "cms", "en");
    Translator.add("statistic.goalkeeperexports.filter.to", "To", "cms", "en");
    Translator.add("statistic.goalkeeperexports.filter.supplier", "Supplier code", "cms", "en");
    Translator.add("statistic.goalkeeperexports.filter.success", "Successful", "cms", "en");
    Translator.add("statistic.goalkeeperexports.table.title", "Content Exports", "cms", "en");
    Translator.add("statistic.goalkeeperexports.table.lastupdated", "Content updated on", "cms", "en");
    Translator.add("statistic.goalkeeperexports.table.supplier.title", "Supplier code", "cms", "en");
    Translator.add("statistic.goalkeeperexports.table.type.title", "Typ", "cms", "en");
    Translator.add("statistic.goalkeeperexports.table.numberoffiles.title", "Total files", "cms", "en");
    Translator.add("statistic.goalkeeperexports.table.processedfiles.title", "Processed files", "cms", "en");
    Translator.add("statistic.goalkeeperexports.table.filename.title", "Filename", "cms", "en");
    Translator.add("statistic.goalkeeperexports.table.started.title", "Started", "cms", "en");
    Translator.add("statistic.goalkeeperexports.table.finished.title", "Finished", "cms", "en");
    Translator.add("statistic.goalkeeperexports.table.updatedat.title", "Update Time", "cms", "en");
    Translator.add("statistic.goalkeeperexports.table.success.title", "Success", "cms", "en");
    Translator.add("statistic.restartimport.title", "Restart Import", "cms", "en");
    Translator.add("statistic.restartimport.text", "Do you want to restart the import?", "cms", "en");
    Translator.add("statistic.restartimport.confirmbutton", "Yes, restart it!", "cms", "en");
    Translator.add("statistic.restartimport.alreadyfinished", "Import has already been finished successfully!", "cms", "en");
    Translator.add("statistic.restartimport.stillrunning", "Import is still running!", "cms", "en");
    Translator.add("statistic.restartimport.startedtitle", "Restarted!", "cms", "en");
    Translator.add("statistic.restartimport.startedtext", "The Import has been restarted.", "cms", "en");
    Translator.add("statistic.unavailableimages.navigation", "Unavailable Images", "cms", "en");
    Translator.add("statistic.unavailableimages.actions.title", "Actions", "cms", "en");
    Translator.add("statistic.unavailableimages.filter.title", "Filter", "cms", "en");
    Translator.add("statistic.unavailableimages.filter.goalid", "Goal ID", "cms", "en");
    Translator.add("statistic.unavailableimages.table.title", "Unavailable Images", "cms", "en");
    Translator.add("statistic.unavailableimages.table.supplier.title", "Supplier Code", "cms", "en");
    Translator.add("statistic.unavailableimages.table.goal.title", "Goal ID", "cms", "en");
    Translator.add("statistic.unavailableimages.table.imageurl.title", "Image URL", "cms", "en");
    Translator.add("statistic.unavailableimages.table.offlinesince.title", "Offline Since", "cms", "en");
    Translator.add("featurematching.navigation", "Feature matching", "cms", "en");
    Translator.add("featurematching.filter.title", "Filter", "cms", "en");
    Translator.add("featurematching.filter.search", "Search", "cms", "en");
    Translator.add("featurematching.filter.reset", "Reset", "cms", "en");
    Translator.add("featurematching.filter.feature", "Name", "cms", "en");
    Translator.add("featurematching.filter.language", "Language", "cms", "en");
    Translator.add("featurematching.filter.tocode", "Supplier Code", "cms", "en");
    Translator.add("featurematching.filter.globaltype", "GlobalType", "cms", "en");
    Translator.add("featurematching.filter.subtype", "SubType", "cms", "en");
    Translator.add("featurematching.filter.additionaltype", "AdditionalType", "cms", "en");
    Translator.add("featurematching.filter.matchingstatus", "Matching Status", "cms", "en");
    Translator.add("featurematching.filter.de", "German", "cms", "en");
    Translator.add("featurematching.filter.en", "English", "cms", "en");
    Translator.add("featurematching.filter.it", "Italian", "cms", "en");
    Translator.add("featurematching.filter.fr", "French", "cms", "en");
    Translator.add("featurematching.filter.pt", "Portuguese", "cms", "en");
    Translator.add("featurematching.filter.es", "Spanish", "cms", "en");
    Translator.add("featurematching.filter.tr", "Turkish", "cms", "en");
    Translator.add("featurematching.filter.zh", "Chinese", "cms", "en");
    Translator.add("featurematching.filter.modal.download.title", "GlobalTypes export download", "cms", "en");
    Translator.add("featurematching.filter.modal.download.request", "Request download", "cms", "en");
    Translator.add("featurematching.filter.modal.download.infomessage", "Your request is in process!", "cms", "en");
    Translator.add("featurematching.filter.modal.globaltypes.title", "GlobalTypes", "cms", "en");
    Translator.add("featurematching.filter.modal.set.title", "Set type", "cms", "en");
    Translator.add("featurematching.filter.modal.set.intersection.title", "Intersection", "cms", "en");
    Translator.add("featurematching.filter.modal.set.union.title", "Union", "cms", "en");
    Translator.add("featurematching.filter.modal.referenceid.title", "Reference ID", "cms", "en");
    Translator.add("featurematching.filter.modal.referenceid.goalid.title", "GOAL ID", "cms", "en");
    Translator.add("featurematching.filter.modal.referenceid.giataid.title", "GIATA ID", "cms", "en");
    Translator.add("featurematching.filter.modal.goalgt.title", "Include Goal GlobalType", "cms", "en");
    Translator.add("featurematching.overview.title", "Feature matching", "cms", "en");
    Translator.add("featurematching.table.title", "Feature matching", "cms", "en");
    Translator.add("featurematching.table.paginate.next", "Next", "cms", "en");
    Translator.add("featurematching.table.paginate.previous", "Previous", "cms", "en");
    Translator.add("featurematching.table.info", "Showing _START_ to _END_ of _TOTAL_ entries", "cms", "en");
    Translator.add("featurematching.table.emptyTable", "No Results", "cms", "en");
    Translator.add("featurematching.table.emptyinfo", "", "cms", "en");
    Translator.add("featurematching.table.charged", "Chrg.", "cms", "en");
    Translator.add("featurematching.table.charged-title", "Charged", "cms", "en");
    Translator.add("featurematching.table.id", "ID", "cms", "en");
    Translator.add("featurematching.table.language", "L.", "cms", "en");
    Translator.add("featurematching.table.language-title", "Language", "cms", "en");
    Translator.add("featurematching.table.name", "Name", "cms", "en");
    Translator.add("featurematching.table.relation", "Relation", "cms", "en");
    Translator.add("featurematching.table.supplier", "Supplier", "cms", "en");
    Translator.add("featurematching.table.gtype", "GlobalType", "cms", "en");
    Translator.add("featurematching.table.atype", "AdditionalType", "cms", "en");
    Translator.add("featurematching.table.stype", "SubType", "cms", "en");
    Translator.add("featurematching.table.ctype", "CustomType", "cms", "en");
    Translator.add("featurematching.table.action", "Action", "cms", "en");
    Translator.add("featurematching.table.row.savebutton", "Save featuremapping", "cms", "en");
    Translator.add("featurematching.table.row.deletebutton", "Delete featuremapping", "cms", "en");
    Translator.add("featurematching.table.row.resetbutton", "Reset input fields", "cms", "en");
    Translator.add("featurematching.table.row.resetoverridebutton", "Reset override", "cms", "en");
    Translator.add("featurematching.table.row.addgtbutton", "Add another GlobalType", "cms", "en");
    Translator.add("featurematching.feedback.unsavedcontent.message", "There is unsaved content", "cms", "en");
    Translator.add("featurematching.buttons.close", "Close", "cms", "en");
    Translator.add("featurematching.buttons.collapse", "Collapse", "cms", "en");
    Translator.add("featurematching.buttons.expand", "Expand", "cms", "en");
    Translator.add("featurematching.buttons.unsavedcontent.cancel", " Cancel all", "cms", "en");
    Translator.add("featurematching.buttons.unsavedcontent.saveall", " Save all", "cms", "en");
    Translator.add("featurematching.sweetalert.unsavedcontent.title", "Cancel changed content. Are you sure?", "cms", "en");
    Translator.add("featurematching.sweetalert.unsavedcontent.text", "All unsaved modifications will be lost!", "cms", "en");
    Translator.add("featurematching.sweetalert.unsavedcontent.confirmButton", "Yes", "cms", "en");
    Translator.add("featurematching.sweetalert.unsavedcontent.cancelButton", "NO", "cms", "en");
    Translator.add("featurematching.sweetalert.resetmatching.title", "Delete featuremapping", "cms", "en");
    Translator.add("featurematching.sweetalert.resetmatching.text", "Are you sure?", "cms", "en");
    Translator.add("featurematching.sweetalert.resetmatching.confirmButton", "Yes, delete it!", "cms", "en");
    Translator.add("featurematching.sweetalert.resetmatching.cancelButton", "NO", "cms", "en");
    Translator.add("featurematching.sweetalert.resetalloverride.title", "Reset All Overrides", "cms", "en");
    Translator.add("featurematching.sweetalert.resetalloverride.text", "Are you sure?", "cms", "en");
    Translator.add("featurematching.sweetalert.resetalloverride.confirmButton", "Yes, reset it!", "cms", "en");
    Translator.add("featurematching.sweetalert.resetalloverride.cancelButton", "NO", "cms", "en");
    Translator.add("featurematching.sweetalert.resetoverride.title", "Reset Override", "cms", "en");
    Translator.add("featurematching.sweetalert.resetoverride.text", "Are you sure?", "cms", "en");
    Translator.add("featurematching.sweetalert.resetoverride.confirmButton", "Yes, reset it!", "cms", "en");
    Translator.add("featurematching.sweetalert.resetoverride.cancelButton", "NO", "cms", "en");
    Translator.add("featurematching.toastr.unsavedcontent.message", "There is unsaved content on this page. Due to this, search is disabled. Please, reset or save first!", "cms", "en");
    Translator.add("featurematching.toastr.unsavedcontent.title", "Attention", "cms", "en");
    Translator.add("featurematching.alert.savetitle", "Saved!", "cms", "en");
    Translator.add("featurematching.alert.savetext", "The featuremapping has been saved!", "cms", "en");
    Translator.add("featurematching.alert.deletetitle", "Deleted!", "cms", "en");
    Translator.add("featurematching.alert.deletetext", "The featuremapping has been deleted!", "cms", "en");
    Translator.add("featurematching.alert.errortitle", "Error!", "cms", "en");
    Translator.add("featurematching.alert.errortext.nogt", "Cannot save without a globaltype!", "cms", "en");
    Translator.add("featurematching.alert.errortext.conerror", "The featuremapping couldn't be saved!", "cms", "en");
    Translator.add("featurematching.alert.errortext.roerror", "Could not reset!", "cms", "en");
    Translator.add("featurematching.alert.resetoverridetitle", "Reset!", "cms", "en");
    Translator.add("featurematching.alert.resetoverridetext", "The override has been reset!", "cms", "en");
    Translator.add("featurematching.alert.resetoverridestitle", "Reset!", "cms", "en");
    Translator.add("featurematching.alert.resetoverridestext", "All overrides have been reset!", "cms", "en");
    Translator.add("config.navigation.default", "Configuration", "cms", "en");
    Translator.add("config.navigation.general", "General", "cms", "en");
    Translator.add("config.navigation.contentexport", "Content Export", "cms", "en");
    Translator.add("config.main.title", "Configuration", "cms", "en");
    Translator.add("config.section.languages.local.header", "Languages", "cms", "en");
    Translator.add("config.section.languages.local.tooltip", "Languages", "cms", "en");
    Translator.add("config.section.languages.local.description", "The selected languages are treated preferentially in the user interface. This provides a more clear presentation! Active languages will be taken into account in case of a content export.", "cms", "en");
    Translator.add("config.section.languages.central.header", "Languages", "cms", "en");
    Translator.add("config.section.languages.central.tooltip", "Languages ", "cms", "en");
    Translator.add("config.section.languages.central.description", "The selected languages are treated preferentially in the user interface. This provides a more clear presentation!", "cms", "en");
    Translator.add("config.section.languages.save.errormessage", "There was a problem to save the languages. Please try again later.", "cms", "en");
    Translator.add("config.section.languages.save.successmessage", "You have successfully saved the configuration of the languages.", "cms", "en");
    Translator.add("config.section.preferreddescriptions.header", "Preferred Descriptions", "cms", "en");
    Translator.add("config.section.preferreddescriptions.tooltip", "Preferred Descriptions", "cms", "en");
    Translator.add("config.section.preferreddescriptions.description", "Preferred Descriptions", "cms", "en");
    Translator.add("config.section.preferreddescriptions.save.errormessage", "There was a problem to save the preferred descriptions. Please try again later.", "cms", "en");
    Translator.add("config.section.preferreddescriptions.save.successmessage", "You have successfully saved the configuration of the preferred descriptions.", "cms", "en");
    Translator.add("config.section.preferredimages.header", "Preferred Images", "cms", "en");
    Translator.add("config.section.preferredimages.tooltip", "Preferred Images", "cms", "en");
    Translator.add("config.section.preferredimages.description", "Preferred Images", "cms", "en");
    Translator.add("config.section.preferredimages.save.errormessage", "There was a problem to save the preferred images. Please try again later.", "cms", "en");
    Translator.add("config.section.preferredimages.save.successmessage", "You have successfully saved the configuration of the preferred images.", "cms", "en");
    Translator.add("config.section.imagecategories.header", "Image Categories", "cms", "en");
    Translator.add("config.section.imagecategories.tooltip", "Image Categories", "cms", "en");
    Translator.add("config.section.imagecategories.description", "Allows to set an orders for images in the output. Images will be sorted accordingly to their Peakwork Image Categories (if they are provided by the Content Supplier). If an image set for a GOAL ID has been selected those images will be prioritized.", "cms", "en");
    Translator.add("config.section.imagecategories.save.errormessage", "There was a problem to save the categories of the images. Please try again later.", "cms", "en");
    Translator.add("config.section.imagecategories.save.successmessage", "You have successfully saved the configuration of the images' categories.", "cms", "en");
    Translator.add("config.section.suppliercodemapppings.header", "Supplier Code Mappings", "cms", "en");
    Translator.add("config.section.suppliercodemapppings.tooltip", "Supplier Code Mappings", "cms", "en");
    Translator.add("config.section.suppliercodemapppings.local.description", "Maps the supplier codes used by the Content Player to externally used codes for content request.", "cms", "en");
    Translator.add("config.section.suppliercodemapppings.central.description", "Maps the supplier codes used by the Goalkeeper to the Peakwork global supplier codes.", "cms", "en");
    Translator.add("config.section.suppliercodemapppings.save.errormessage", "There was a problem to save the mapping for the supplier code. Please try again later.", "cms", "en");
    Translator.add("config.section.suppliercodemapppings.save.successmessage", "You have successfully saved the configuration of the mapping for the supplier code.", "cms", "en");
    Translator.add("config.section.suppliercodematchings.local.header", "Supplier Code For Content Import", "cms", "en");
    Translator.add("config.section.suppliercodematchings.local.tooltip", "Supplier Code For Content Import", "cms", "en");
    Translator.add("config.section.suppliercodematchings.local.description", "Content of selected suppliers will be imported into the Content Player.", "cms", "en");
    Translator.add("config.section.suppliercodematchings.central.header", "Supplier Code For The Content-EDF Import", "cms", "en");
    Translator.add("config.section.suppliercodematchings.central.tooltip", "Supplier Code For the Content-EDF Import", "cms", "en");
    Translator.add("config.section.suppliercodematchings.central.description", "Content EDFs of the activated suppliers will be imported into the Goalkeeper.", "cms", "en");
    Translator.add("config.section.suppliercodematchings.save.errormessage", "There was a problem to save the configuration for the supplier code matching. Please try again later.", "cms", "en");
    Translator.add("config.section.suppliercodematchings.save.successmessage", "You have successfully saved the configuration for the supplier code matching.", "cms", "en");
    Translator.add("config.section.supplierwhitelistedforgoalid.header", "Whitelisted Suppliers For Goal", "cms", "en");
    Translator.add("config.section.supplierwhitelistedforgoalid.tooltip", "Whitelisted Suppliers For Goal", "cms", "en");
    Translator.add("config.section.supplierwhitelistedforgoalid.description", "Selected suppliers are integrated in the process of creating new Goals.", "cms", "en");
    Translator.add("config.section.supplierwhitelistedforgoalid.save.errormessage", "There was a problem to save the whitelisted supplier for the GoalId. Please try again later.", "cms", "en");
    Translator.add("config.section.supplierwhitelistedforgoalid.save.successmessage", "You have successfully saved the whitelisted suppliers for the GoalId.", "cms", "en");
    Translator.add("config.section.cronupload.local.header", "Content Import", "cms", "en");
    Translator.add("config.section.cronupload.local.tooltip", "Content Import", "cms", "en");
    Translator.add("config.section.cronupload.local.description", "Time interval for testing if new content is available.", "cms", "en");
    Translator.add("config.section.cronupload.central.header", "Content-EDF Import", "cms", "en");
    Translator.add("config.section.cronupload.central.tooltip", "Content-EDF Import", "cms", "en");
    Translator.add("config.section.cronupload.central.description", "Time interval for testing if new Content-EDFs are available.", "cms", "en");
    Translator.add("config.section.cronupload.save.errormessageempty", "Please fill out all fields or check your cron string!", "cms", "en");
    Translator.add("config.section.cronupload.save.errormessage", "There was a problem to save the configuration for cron upload. Please try again later.", "cms", "en");
    Translator.add("config.section.cronupload.save.successmessage", "You have successfully saved the configuration for the cron upload.", "cms", "en");
    Translator.add("config.section.thirdpartycontentsource.header", "Third Party Contentsource Mapping", "cms", "en");
    Translator.add("config.section.thirdpartycontentsource.tooltip", "Third Party Contentsource Mapping", "cms", "en");
    Translator.add("config.section.thirdpartycontentsource.description", "Mapping for each brand to a third party contentsource, which can be accessed by an IBE. The default contentsource is the contentdata from the contentplayer itself.", "cms", "en");
    Translator.add("config.section.thirdpartycontentsource.addlist.errormessage", "Brand must not be empty!", "cms", "en");
    Translator.add("config.section.thirdpartycontentsource.save.errormessage", "There was a problem to save the configuration for the brand contentsource mapping. Please try again later.", "cms", "en");
    Translator.add("config.section.thirdpartycontentsource.save.successmessage", "You have successfully saved the configuration for the brand contentsource mapping.", "cms", "en");
    Translator.add("config.section.thirdpartycontentsource.brand", "Brand", "cms", "en");
    Translator.add("config.section.thirdpartycontentsource.thirdpartycontentsource", "Third Party Contentsource:", "cms", "en");
    Translator.add("config.section.preferences.header", "Preferred Sources", "cms", "en");
    Translator.add("config.section.preferences.tooltip", "Preferred Sources", "cms", "en");
    Translator.add("config.section.codemappings.header", "Code mappings", "cms", "en");
    Translator.add("config.section.codemappings.tooltip", "The external supplier codes will be mapped to the internals.", "cms", "en");
    Translator.add("config.section.additional.header", "Additional settings", "cms", "en");
    Translator.add("config.section.additional.tooltip", "Additional settings", "cms", "en");
    Translator.add("config.section.cron.header", "Cron upload", "cms", "en");
    Translator.add("config.section.cron.tooltip", "Time interval for importing EDFs from the FTP-server", "cms", "en");
    Translator.add("config.section.cronmatching.header", "Cron Goal product code matching", "cms", "en");
    Translator.add("config.section.cronmatching.tooltip", "Time interval for synchronising the local Goal-product-code-matchings", "cms", "en");
    Translator.add("config.section.tocodematching.header", "Product code matching", "cms", "en");
    Translator.add("config.section.tocodematching.tooltip", "Product code of the supplier-EDFs that should be loaded into the CMS", "cms", "en");
    Translator.add("config.section.crongeography.central.header", "Geography Import", "cms", "en");
    Translator.add("config.section.crongeography.central.tooltip", "Geography Import", "cms", "en");
    Translator.add("config.section.crongeography.central.description", "Scheduled import of the geography from the geo-service. The following data will be imported: cities, countries, political regions and touristic regions.", "cms", "en");
    Translator.add("config.section.crongeography.save.errormessageempty", "Please fill out all fields or check your cron string!", "cms", "en");
    Translator.add("config.section.crongeography.save.errormessage", "There was a problem to save the configuration for the cron geography. Please try again later.", "cms", "en");
    Translator.add("config.section.crongeography.save.successmessage", "You have successfully saved the configuration for the cron geography.", "cms", "en");
    Translator.add("config.section.crongoalfromcentral.header", "Import Goal Data From Central", "cms", "en");
    Translator.add("config.section.crongoalfromcentral.tooltip", "Import Goal Data From Central", "cms", "en");
    Translator.add("config.section.crongoalfromcentral.description", "Time interval for importing Goal Data from the central Peakwork system.", "cms", "en");
    Translator.add("config.section.crongoalfromcentral.save.errormessageempty", "Please fill out all fields or check your cron string!", "cms", "en");
    Translator.add("config.section.crongoalfromcentral.save.errormessage", "There was a problem to save the configuration for the cronjob (Goal Data From Central Import). Please try again later.", "cms", "en");
    Translator.add("config.section.crongoalfromcentral.save.successmessage", "You have successfully saved the configuration for the cronjob (Goal Data From Central Import).", "cms", "en");
    Translator.add("config.section.crongiatadelta.header", "Cron Giata delta", "cms", "en");
    Translator.add("config.section.crongiatadelta.tooltip", "Time interval for importing Giata data changes", "cms", "en");
    Translator.add("config.section.crongiatafull.header", "Cron Giata full", "cms", "en");
    Translator.add("config.section.crongiatafull.tooltip", "Time interval for a full Giata import", "cms", "en");
    Translator.add("config.section.subtypes.header", "Exclude subtypes", "cms", "en");
    Translator.add("config.section.subtypes.tooltip", "Select subtypes that should be excluded", "cms", "en");
    Translator.add("config.section.exportcronjobs.header", "Scheduled Export Jobs", "cms", "en");
    Translator.add("config.section.exportcronjobs.tooltip", "Scheduled Export Jobs", "cms", "en");
    Translator.add("config.section.exportcronjobs.description", "Schedule export jobs which are written either to the filesystem or to a FTP-Server. Choose an Exporttype to see a more detailed description for each specific export job!<br\/><br\/><b>Name:<\/b> You can add the current timestamp to the filename by including '${timestamp}'.<br\/><br\/><b>Delete After Success:<\/b> Will delete temporary generated files if no errors occurred<br\/><br>", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.header", "Exporttype Description", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.descriptions.google", "Export of the localfeed for Google.", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.descriptions.hubairport", "CSV Export of an airportlist and the basicdata for the HUB.", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.descriptions.goalgiatamapping", "CSV Export of the Mapping from GOAL to GIATA ids.", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.descriptions.portfoliofullhotel", "Export the GOAL data hotel with GOAL matchings as a zip file with JSON files for the Panels.", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.descriptions.portfoliodeltahotel", "", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.descriptions.portfoliofullgt", "Exports the globalTypes of a product based on the Feature Matching. The JSON file for each GOAL id is included in the export zip file.", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.descriptions.prioritycontentexport", "contentexport description", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.descriptions.mnatching", "matching description", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.descriptions.matchingcsv", "matchingcsv description", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.descriptions.player", "player description", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.descriptions.languages", "A language filtered export of content with a minimum rating of 3 stars. The export includes the supplier code, product code, default reference id of the Content Player and the information in with languages hotel descriptions are available.", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.descriptions.globaltypes", "Exports the GlobalType for each reference id based on the Feature Matching. SetType: Choose weather a GlobalTypes needs to be set of all (INTERSECTION) or at least one (UNION) in order to be included in the export.", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.descriptions.hub", "hub description", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.descriptions.hubgiatageo", "CSV Export of the GIATA geography for the HUB.", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.descriptions.hubgoalgeo", "CSV Export of the GOAL geography for the HUB.", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.descriptions.goal", "goal description", "cms", "en");
    Translator.add("config.section.exportcronjobs.exporttype.descriptions.fullhub", "CSV Export the Goal Data for the HUB. The Export includes both the hotel and geography data.", "cms", "en");
    Translator.add("config.section.exportcronjobs.form.header", "Add\/Edit ExportCronJob", "cms", "en");
    Translator.add("config.section.exportcronjobs.button.newcronjob", "New CronJob", "cms", "en");
    Translator.add("config.section.exportcronjobs.list.header", "ExportCronJob List", "cms", "en");
    Translator.add("config.section.exportcronjobs.save.errormessage", "There was a problem to save the export-cronjobs. Please try again later.", "cms", "en");
    Translator.add("config.section.exportcronjobs.save.successmessage", "You have successfully saved the configuration of the export-cronjobs.", "cms", "en");
    Translator.add("config.section.images.header", "Image preferences", "cms", "en");
    Translator.add("config.section.images.tooltip", "Custom image preferences", "cms", "en");
    Translator.add("config.section.prioritycontentexport.additionalflags.header", "Additional Flags", "cms", "en");
    Translator.add("config.section.prioritycontentexport.additionalflags.tooltip", "List of additional flags used for the export.", "cms", "en");
    Translator.add("config.section.prioritycontentexport.additionalflags.description", "List of additional flags used for the export.", "cms", "en");
    Translator.add("config.section.prioritycontentexport.additionalflags.save.errormessage", "There was a problem to save the list of Additional Flags used for the export. Please try again later.", "cms", "en");
    Translator.add("config.section.prioritycontentexport.additionalflags.save.successmessage", "You have successfully saved the list of Additional Flags used for the export.", "cms", "en");
    Translator.add("config.section.prioritycontentexport.images.header", "Images", "cms", "en");
    Translator.add("config.section.prioritycontentexport.images.tooltip", "Prioritized list of suppliers used for the images export.", "cms", "en");
    Translator.add("config.section.prioritycontentexport.images.description", "Prioritized list of suppliers used for the images export.", "cms", "en");
    Translator.add("config.section.prioritycontentexport.images.save.errormessage", "There was a problem to save the prioritized list of suppliers used for the images export. Please try again later.", "cms", "en");
    Translator.add("config.section.prioritycontentexport.images.save.successmessage", "You have successfully saved the prioritized list of suppliers used for the images export.", "cms", "en");
    Translator.add("config.section.prioritycontentexport.descriptions.header", "Descriptions", "cms", "en");
    Translator.add("config.section.prioritycontentexport.descriptions.tooltip", "Prioritized list of suppliers, language pairs and possibly globaltype used for the descriptions export.", "cms", "en");
    Translator.add("config.section.prioritycontentexport.descriptions.description", "Prioritized list of suppliers, language pairs and possibly globaltype used for the descriptions export.", "cms", "en");
    Translator.add("config.section.prioritycontentexport.descriptions.save.errormessage", "There was a problem to save the prioritized list used for descriptions export. Please try again later.", "cms", "en");
    Translator.add("config.section.prioritycontentexport.descriptions.save.successmessage", "You have successfully saved the prioritized list used for descriptions export.", "cms", "en");
    Translator.add("config.section.prioritycontentexport.languages.header", "Languages", "cms", "en");
    Translator.add("config.section.prioritycontentexport.languages.tooltip", "List of languages used for the export.", "cms", "en");
    Translator.add("config.section.prioritycontentexport.languages.description", "List of languages used for the export.", "cms", "en");
    Translator.add("config.section.prioritycontentexport.languages.notempty.errormessage", "The language list must not be empty!", "cms", "en");
    Translator.add("config.section.prioritycontentexport.languages.save.errormessage", "There was a problem to save the list of languages used for the export. Please try again later.", "cms", "en");
    Translator.add("config.section.prioritycontentexport.languages.save.successmessage", "You have successfully saved the list of languages used for the export.", "cms", "en");
    Translator.add("config.section.globaltypecategorytranslationfilter.header", "GlobalTypes", "cms", "en");
    Translator.add("config.section.globaltypecategorytranslationfilter.tooltip", "API v2 Configuration: GlobalTypes", "cms", "en");
    Translator.add("config.section.globaltypecategorytranslationfilter.description", "This configuration allows to manipulate the amount and kind of GlobalTypes that are included in the content player's response if a BrandRequest is used. If no BrandRequest is used to retrieve content, the behavior of the API v2 is not changed.", "cms", "en");
    Translator.add("config.section.globaltypecategorytranslationfilter.save.errormessage", "There was a problem while saving the configuration changes for the GlobalTypes. Please try again later.", "cms", "en");
    Translator.add("config.section.globaltypecategorytranslationfilter.save.successmessage", "You have successfully saved the configuration changes for the GlobalTypes.", "cms", "en");
    Translator.add("config.labels.cat.general", "General", "cms", "en");
    Translator.add("config.labels.cat.generalcronjob", "General Cronjobs", "cms", "en");
    Translator.add("config.labels.cat.goal", "Goal", "cms", "en");
    Translator.add("config.labels.cat.giata", "Giata", "cms", "en");
    Translator.add("config.labels.cat.exportcronjobs", "Export Cronjobs", "cms", "en");
    Translator.add("config.labels.cat.prioritycontentexport", "Priority Content Export", "cms", "en");
    Translator.add("config.labels.cat.apiv2configuration", "API v2 Configuration", "cms", "en");
    Translator.add("config.labels.form.addtolist", "Add To List", "cms", "en");
    Translator.add("config.labels.form.save", "Save", "cms", "en");
    Translator.add("config.labels.form.reset", "Reset", "cms", "en");
    Translator.add("config.labels.form.addchange", "Add\/Change", "cms", "en");
    Translator.add("config.labels.form.cancel", "Cancel", "cms", "en");
    Translator.add("config.labels.languages.languages", "Languages", "cms", "en");
    Translator.add("config.labels.preferences.source.description", "Source for descriptions", "cms", "en");
    Translator.add("config.labels.preferences.source.images", "Source for images", "cms", "en");
    Translator.add("config.labels.images.imagetypeorder", "Image type output order", "cms", "en");
    Translator.add("config.labels.codemappings.supplier", "The external supplier codes will be mapped to the internals.", "cms", "en");
    Translator.add("config.labels.additional.preferredglobaltypeset", "GlobalTypes prefered cut set", "cms", "en");
    Translator.add("config.labels.showadvanced", "Show Advanced", "cms", "en");
    Translator.add("config.labels.cronstring", "Cron String", "cms", "en");
    Translator.add("config.labels.cron.cron", "Time interval for importing EDFs from the FTP-server", "cms", "en");
    Translator.add("config.labels.cron.input.minute", "Minute", "cms", "en");
    Translator.add("config.labels.cron.input.hour", "Hour", "cms", "en");
    Translator.add("config.labels.cron.input.dayofmonth", "Day of month", "cms", "en");
    Translator.add("config.labels.cron.input.month", "Month", "cms", "en");
    Translator.add("config.labels.cron.input.weekday", "Weekday", "cms", "en");
    Translator.add("config.labels.cron.input.everyminute", "Every Minute", "cms", "en");
    Translator.add("config.labels.cron.input.everyhour", "Every Hour", "cms", "en");
    Translator.add("config.labels.cron.input.everyday", "Every Day", "cms", "en");
    Translator.add("config.labels.cron.input.everymonth", "Every Month", "cms", "en");
    Translator.add("config.labels.cronmatching.cronmatching", "Time interval for synchronising the local Goal-hotelcode-matchings", "cms", "en");
    Translator.add("config.labels.cronmatching.input.minute", "Minute", "cms", "en");
    Translator.add("config.labels.cronmatching.input.hour", "Hour", "cms", "en");
    Translator.add("config.labels.cronmatching.input.dayofmonth", "Day of month", "cms", "en");
    Translator.add("config.labels.cronmatching.input.month", "Month", "cms", "en");
    Translator.add("config.labels.cronmatching.input.weekday", "Weekday", "cms", "en");
    Translator.add("config.labels.cronmatching.input.everymonth", "Every Month", "cms", "en");
    Translator.add("config.labels.tocodematching.tocodematching", "Supplier codes of the supplier-EDFs that should be loaded into the CMS", "cms", "en");
    Translator.add("config.labels.cronfeature.cronfeature", "Time interval for feature updates", "cms", "en");
    Translator.add("config.labels.cronfeature.input.minute", "Minute", "cms", "en");
    Translator.add("config.labels.cronfeature.input.hour", "Hour", "cms", "en");
    Translator.add("config.labels.cronfeature.input.dayofmonth", "Day of month", "cms", "en");
    Translator.add("config.labels.cronfeature.input.month", "Month", "cms", "en");
    Translator.add("config.labels.cronfeature.input.weekday", "Weekday", "cms", "en");
    Translator.add("config.labels.cronfeature.input.everyminute", "Every Minute", "cms", "en");
    Translator.add("config.labels.cronfeature.input.everyhour", "Every Hour", "cms", "en");
    Translator.add("config.labels.cronfeature.input.everyday", "Every Day", "cms", "en");
    Translator.add("config.labels.cronfeature.input.everymonth", "Every Month", "cms", "en");
    Translator.add("config.labels.crongoalfromcentral.crongoalfromcentral", "Time interval for goal from cetnral updates", "cms", "en");
    Translator.add("config.labels.crongoalfromcentral.input.minute", "Minute", "cms", "en");
    Translator.add("config.labels.crongoalfromcentral.input.hour", "Hour", "cms", "en");
    Translator.add("config.labels.crongoalfromcentral.input.dayofmonth", "Day of month", "cms", "en");
    Translator.add("config.labels.crongoalfromcentral.input.month", "Month", "cms", "en");
    Translator.add("config.labels.crongoalfromcentral.input.weekday", "Weekday", "cms", "en");
    Translator.add("config.labels.crongoalfromcentral.input.everyminute", "Every Minute", "cms", "en");
    Translator.add("config.labels.crongoalfromcentral.input.everyhour", "Every Hour", "cms", "en");
    Translator.add("config.labels.crongoalfromcentral.input.everyday", "Every Day", "cms", "en");
    Translator.add("config.labels.crongoalfromcentral.input.everymonth", "Every Month", "cms", "en");
    Translator.add("config.labels.crongiatadelta.crongiatadelta", "Time interval for importing Giata data changes", "cms", "en");
    Translator.add("config.labels.crongiatadelta.input.minute", "Minute", "cms", "en");
    Translator.add("config.labels.crongiatadelta.input.hour", "Hour", "cms", "en");
    Translator.add("config.labels.crongiatadelta.input.dayofmonth", "Day of month", "cms", "en");
    Translator.add("config.labels.crongiatadelta.input.month", "Month", "cms", "en");
    Translator.add("config.labels.crongiatadelta.input.weekday", "Weekday", "cms", "en");
    Translator.add("config.labels.crongiatafull.crongiatafull", "Time interval for a full Giata import", "cms", "en");
    Translator.add("config.labels.crongiatafull.input.minute", "Minute", "cms", "en");
    Translator.add("config.labels.crongiatafull.input.hour", "Hour", "cms", "en");
    Translator.add("config.labels.crongiatafull.input.dayofmonth", "Day of month", "cms", "en");
    Translator.add("config.labels.crongiatafull.input.month", "Month", "cms", "en");
    Translator.add("config.labels.crongiatafull.input.weekday", "Weekday", "cms", "en");
    Translator.add("config.labels.subtypes.subtypes", "Select subtypes that should be excluded", "cms", "en");
    Translator.add("config.labels.exportcronjobs.exportcronjobs", "Custom export-cronjobs", "cms", "en");
    Translator.add("config.labels.exportcronjobs.type.selection.GOOGLE", "Google", "cms", "en");
    Translator.add("config.labels.exportcronjobs.type.selection.GLOBALTYPES", "Globaltypes", "cms", "en");
    Translator.add("config.labels.exportcronjobs.type.selection.GLOBALTYPE_TRANSLATIONS", "Globaltype Translations", "cms", "en");
    Translator.add("config.labels.exportcronjobs.type.selection.LANGUAGES", "Languages", "cms", "en");
    Translator.add("config.labels.exportcronjobs.type.selection.FULL_HUB", "Full HUB", "cms", "en");
    Translator.add("config.labels.exportcronjobs.type.selection.HUB_GIATA_GEO", "HUB Giata Geo", "cms", "en");
    Translator.add("config.labels.exportcronjobs.type.selection.HUB_AIRPORT", "HUB Airport", "cms", "en");
    Translator.add("config.labels.exportcronjobs.type.selection.GOAL_GIATA_MAPPING", "Goal Giata Mapping", "cms", "en");
    Translator.add("config.labels.exportcronjobs.type.selection.PORTFOLIO_FULL_HOTEL", "Portfolio Full Hotel", "cms", "en");
    Translator.add("config.labels.exportcronjobs.type.selection.PORTFOLIO_FULL_GT", "Portfolio Full Globaltypes", "cms", "en");
    Translator.add("config.labels.exportcronjobs.type.selection.PRIORITY_CONTENT_EXPORT", "Peakwork Content Export", "cms", "en");
    Translator.add("config.labels.exportcronjobs.type.selection.CONTENT_PLAYER_GOAL_EXPORT", "Content Player Goal Export", "cms", "en");
    Translator.add("config.labels.exportcronjobs.type.selection.PORTFOLIO_DELTA_HOTEL", "Portfolio Delta Hotel", "cms", "en");
    Translator.add("config.labels.exportcronjobs.type.selection.GOAL_EXPORT", "Goal Export", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.type", "Type", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.name", "Name", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.cronstring", "Cron string", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.targettype", "Target type", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.action", "Action", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.includeextern", "Include Extern", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.tocodes", "Tocodes", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.includedeprecated", "Include deprecated", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.greaterthan", "Greater than", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.lessthan", "Less than", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.greaterthanorequalto", "Greater than or equal to", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.lessthanorequalto", "Less than or equal to", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.equalto", "Equal to", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.notequalto", "Not equal to", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.inclang", "Included Languages", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.exclang", "Excluded Languages", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.language", "Language", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.productmatchingcount", "ProductCode Matching Count", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.productmatching", "ProductCode Matching", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.countrycode", "Country Code", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.filesystem", "FILESYSTEM", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.ftp", "FTP", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.directory", "Directory", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.host", "Host", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.port", "Port", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.user", "User", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.header.password", "Password", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.placeholder.countrycode", "e.g. en_US", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.placeholder.multicountrycode", "e.g. en_US,de_DE", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.error.empty", "Field must not be empty!", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.error.countrycode", "Check spelling \"e.g. en_US\"!", "cms", "en");
    Translator.add("config.labels.exportcronjobs.table.error.multicountrycode", "Check spelling \"e.g. en_US,de_DE\"!", "cms", "en");
    Translator.add("config.labels.exportcronjobs.buttons.addtolist", "Add to list", "cms", "en");
    Translator.add("config.labels.exportcronjobs.buttons.applychanges", "Apply changes", "cms", "en");
    Translator.add("config.labels.exportcronjobs.message.target.warning", "You have to select a target type!", "cms", "en");
    Translator.add("config.labels.exportcronjobs.message.save.warning", "All fields with * are mandatory!", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.delete.successmessage", "Brand configuration has been successfully deleted", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.delete.errormessage", "An error occurred while deleting the brand configuration! Please try again later!", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.delete.title", "Delete Brand Configuration?", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.delete.description", "Do you really want to delete the configuration of the identifier (%brandidentifiers%)?", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.save.successmessage", "Brand configuration has been successfully saved", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.save.errormessage", "An error occurred while saving the brand configuration! Please try again later!", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.table.brandidentifiers", "Brand (Usage)", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.table.brandname", "Brand Name", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.table.brandusage", "Brand Usage", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.table.images", "Images (ordered by priority)", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.table.descriptions", "Descriptions (ordered by priority)", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.table.languages", "Languages", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.table.additionalflags", "Additional Flags", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.table.actions", "Actions", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.overview.defaultconfiguration", "Default Configuration (Brand\/Usage combinations without a custom configuration)", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.overview.customconfiguration", "Custom Configuration (Brand\/Usage combinations with a custom configuration)", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.overview.incompletecustomconfiguration", "Incomplete Custom Configuration (At least one of the selected Brand\/Usage combinations is inactive)", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.overview.inactivecustomconfiguration", "Inactive Custom Configuration (All of the selected Brand\/Usage combinations are inactive)", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.buttons.addbrandconfiguration", "Add New Brand Configuration", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.modal.title", "Create\/Edit Brand Configuration", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.modal.brandselect.title", "Brands", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.modal.button.close", "Close", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.modal.button.save", "Save", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.modal.images.title", "Images", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.modal.descriptions.title", "Descriptions", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.modal.languages.title", "Languages", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.modal.additionalflags.title", "Additional flags", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.modal.validation.brand.save.title", "Empty BrandIdentifier", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.modal.validation.brand.save.description", "You have to select at least one brand.", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.modal.validation.language.save.title", "Empty Languages", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.modal.validation.language.save.description", "You have to select at least one language.", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.modal.validation.descriptions.addtolist.title", "Duplicate Entry", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.modal.validation.descriptions.addtolist.description", "Supplier and language already exists.", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.modal.warning.brandconfignotexists.title", "Configuration does not exist.", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.modal.warning.brandconfignotexists.description", "The configuration for this brand does not exist yet. The default configuration will be loaded.", "cms", "en");
    Translator.add("config.labels.prioritycontentexport.modal.warning.brandconfigoverwrite", "Custom Configuration already exists! If you continue, the existing config will be overwritten!", "cms", "en");
    Translator.add("config.labels.suppliercodemappings.delete.title", "Delete Supplier Code Mapping", "cms", "en");
    Translator.add("config.labels.suppliercodemappings.delete.description", "To delete the chosen Supplier Code Mapping eventually, you have to click the save button!", "cms", "en");
    Translator.add("config.labels.globaltypecategorytranslationfilter.default", "Default Behavior", "cms", "en");
    Translator.add("config.labels.globaltypecategorytranslationfilter.descriptiondefault", "If content is requested with the BrandRequest and GoalID, all available GlobalTypes will be included in the response.", "cms", "en");
    Translator.add("config.labels.globaltypecategorytranslationfilter.filtered", "Adapt To Content Export", "cms", "en");
    Translator.add("config.labels.globaltypecategorytranslationfilter.descriptionfiltered", "If content is requested with BrandRequest and GoalID, only Translations of GlobalTypes that have a translation to the requested language and are assigned to a GlobalType category will be included in the BrandResponse. BrandRequests must be made with exactly one language.", "cms", "en");
    Translator.add("config.contentexport.main.title", "Content Export Configuration", "cms", "en");
    Translator.add("goal.buttons.close", "Close", "cms", "en");
    Translator.add("goal.navigation", "Goal", "cms", "en");
    Translator.add("goal.main.title", "Goal", "cms", "en");
    Translator.add("goal.goalsummary.title", "Goal Summary", "cms", "en");
    Translator.add("goal.goaltable.title", "Goal Table", "cms", "en");
    Translator.add("goal.goaltable.goalid.title", "Goal Id", "cms", "en");
    Translator.add("goal.goaltable.gardid.title", "Gard Id", "cms", "en");
    Translator.add("goal.goaltable.giataid.title", "Giata Id", "cms", "en");
    Translator.add("goal.goaltable.bookingid.title", "Booking Id", "cms", "en");
    Translator.add("goal.goaltable.name.title", "Name", "cms", "en");
    Translator.add("goal.goaltable.street.title", "Street", "cms", "en");
    Translator.add("goal.goaltable.zipcode.title", "Zip code", "cms", "en");
    Translator.add("goal.goaltable.cityedf.title", "City (EDF)", "cms", "en");
    Translator.add("goal.goaltable.cityname.title", "City", "cms", "en");
    Translator.add("goal.goaltable.regionname.title", "Region", "cms", "en");
    Translator.add("goal.goaltable.countrycode.title", "Country code", "cms", "en");
    Translator.add("goal.goaltable.airport.title", "Arrival airport", "cms", "en");
    Translator.add("goal.goaltable.status.title", "Status", "cms", "en");
    Translator.add("goal.goaltable.status.createdautomatically", "Auto-created", "cms", "en");
    Translator.add("goal.goaltable.status.uncertain", "Uncertain", "cms", "en");
    Translator.add("goal.goaltable.status.needsrevision", "Needs revision", "cms", "en");
    Translator.add("goal.goaltable.status.deprecated", "Deprecated", "cms", "en");
    Translator.add("goal.goaltable.status.released", "Released", "cms", "en");
    Translator.add("goal.goaltable.status.unreleased", "Unreleased", "cms", "en");
    Translator.add("goal.goaltable.action.title", "Action", "cms", "en");
    Translator.add("goal.goaltable.create.newgoal", "Create new Goal", "cms", "en");
    Translator.add("goal.detail.basicdata.title", "Basicdata", "cms", "en");
    Translator.add("goal.detail.basicdata.editablefields", "Die mit (*) gekennzeichneten Felder sind editierbar!", "cms", "en");
    Translator.add("goal.detail.basicdata.message.savefailerror.title", "Could not save basicdata!", "cms", "en");
    Translator.add("goal.detail.basicdata.message.savefailerror.text", "An Error occured while saving basicdata!", "cms", "en");
    Translator.add("goal.detail.title", "Goal Detail", "cms", "en");
    Translator.add("goal.detail.statusdata.title", "Status", "cms", "en");
    Translator.add("goal.detail.actions.needsrevision.button", "Needs revision", "cms", "en");
    Translator.add("goal.detail.actions.needsrevision.message", "Set \"needs revision\" status?", "cms", "en");
    Translator.add("goal.detail.actions.needsrevision.confirmbutton", "Yes, it needs revision", "cms", "en");
    Translator.add("goal.detail.actions.deprecate.button", "Deprecate", "cms", "en");
    Translator.add("goal.detail.actions.deprecate.message", "Set this entry to deprecated?", "cms", "en");
    Translator.add("goal.detail.actions.deprecate.confirmbutton", "Yes, deprecate it!", "cms", "en");
    Translator.add("goal.detail.actions.release.button", "Release", "cms", "en");
    Translator.add("goal.detail.actions.release.message", "Realease this entry?", "cms", "en");
    Translator.add("goal.detail.actions.release.confirmbutton", "Yes, release it!", "cms", "en");
    Translator.add("goal.detail.actions.tocodeedit.button", "Edit Matchings", "cms", "en");
    Translator.add("goal.detail.actions.editrevision.button", "Edit Revision", "cms", "en");
    Translator.add("goal.detail.actions.ischecked.button", "Goal is verified", "cms", "en");
    Translator.add("goal.detail.actions.isnotchecked.button", "Verify Goal", "cms", "en");
    Translator.add("goal.detail.actions.ischeckedbutupdated.button", "Re Verify Goal", "cms", "en");
    Translator.add("goal.detail.revision.error.nocomment.title", "Error: Saving Revision Info", "cms", "en");
    Translator.add("goal.detail.revision.error.nocomment.text", "You have to add a comment!", "cms", "en");
    Translator.add("goal.detail.revision.error.notag.title", "Error: Saving Revision Info", "cms", "en");
    Translator.add("goal.detail.revision.error.notag.text", "You have to choose at least one tag!", "cms", "en");
    Translator.add("goal.detail.revision.warning.delete.title", "Warning: Delete Revision", "cms", "en");
    Translator.add("goal.detail.revision.warning.delete.text", "You are about to delete the current Revision", "cms", "en");
    Translator.add("goal.detail.revision.warning.delete.confirmbutton", "Delete", "cms", "en");
    Translator.add("goal.detail.revision.warning.delete.cancelbutton", "Cancel", "cms", "en");
    Translator.add("goal.detail.cat.initialproduct.title", "Initial Product", "cms", "en");
    Translator.add("goal.detail.cat.infos.title", "Infos", "cms", "en");
    Translator.add("goal.detail.cat.ids.title", "ID", "cms", "en");
    Translator.add("goal.detail.cat.addressdata.title", "Address Data", "cms", "en");
    Translator.add("goal.detail.cat.communication.title", "Communication", "cms", "en");
    Translator.add("goal.detail.cat.additional.title", "Additional Data", "cms", "en");
    Translator.add("goal.detail.map.title", "Map", "cms", "en");
    Translator.add("goal.detail.map.freetextsearchtab.title", "FreeTextSearch", "cms", "en");
    Translator.add("goal.detail.map.reversesearchtab.title", "Reverse Search", "cms", "en");
    Translator.add("goal.detail.map.reversesearchtab.latlng", "Latitude, Longitude | GoogleMapsLink", "cms", "en");
    Translator.add("goal.detail.map.reversesearchtab.latitude", "Latitude", "cms", "en");
    Translator.add("goal.detail.map.reversesearchtab.longitude", "Longitude", "cms", "en");
    Translator.add("goal.detail.map.reversesearchtab.submit", "Search", "cms", "en");
    Translator.add("goal.detail.map.reversesearchtab.errormessage", "Invalid input (i.e.: 51.2281,6.718536)", "cms", "en");
    Translator.add("goal.detail.map.edit", "Edit", "cms", "en");
    Translator.add("goal.detail.map.reset", "Reset", "cms", "en");
    Translator.add("goal.detail.map.form.title", "Goal Location Details", "cms", "en");
    Translator.add("goal.detail.map.form.latitude", "Latitude", "cms", "en");
    Translator.add("goal.detail.map.form.longitude", "Longitude", "cms", "en");
    Translator.add("goal.detail.map.form.country", "Country", "cms", "en");
    Translator.add("goal.detail.map.form.countrycode", "Country code", "cms", "en");
    Translator.add("goal.detail.map.form.city", "City", "cms", "en");
    Translator.add("goal.detail.map.form.coords", "Coordinates", "cms", "en");
    Translator.add("goal.detail.map.form.address", "AdressData", "cms", "en");
    Translator.add("goal.detail.map.form.editabledata", "Editable Data", "cms", "en");
    Translator.add("goal.detail.map.form.save", "Save", "cms", "en");
    Translator.add("goal.detail.map.modifycoords.title", "Modify Coordinates", "cms", "en");
    Translator.add("goal.detail.map.modifycoords.latitude", "Latitude", "cms", "en");
    Translator.add("goal.detail.map.modifycoords.longitude", "Longitude", "cms", "en");
    Translator.add("goal.detail.map.modifycoords.buttons.reset", "Reset", "cms", "en");
    Translator.add("goal.detail.map.modifycoords.buttons.change", "Change", "cms", "en");
    Translator.add("goal.detail.map.modifycoords.buttons.jumptoposition", "Jump To Position", "cms", "en");
    Translator.add("goal.detail.map.modifycoords.success", "Coordinates have been successfully saved!", "cms", "en");
    Translator.add("goal.detail.map.modifycoords.error.empty", "%field% must not be empty!", "cms", "en");
    Translator.add("goal.detail.map.modifycoords.error.notvalid", "%field% must be valid!", "cms", "en");
    Translator.add("goal.detail.map.modifycoords.error.requestfailed", "Coordinates could not be saved!", "cms", "en");
    Translator.add("goal.detail.goalid.label", "Goal Id", "cms", "en");
    Translator.add("goal.detail.goalid.hreftitle", "Enter Goal Id", "cms", "en");
    Translator.add("goal.detail.gardid.label", "Gard Id", "cms", "en");
    Translator.add("goal.detail.gardid.hreftitle", "Enter Gard Id", "cms", "en");
    Translator.add("goal.detail.giataid.label", "Giata Id", "cms", "en");
    Translator.add("goal.detail.giataid.hreftitle", "Enter Giata Id", "cms", "en");
    Translator.add("goal.detail.bookingid.label", "Booking Id", "cms", "en");
    Translator.add("goal.detail.bookingid.hreftitle", "Enter Booking Id", "cms", "en");
    Translator.add("goal.detail.created.label", "Created", "cms", "en");
    Translator.add("goal.detail.updated.label", "Updated", "cms", "en");
    Translator.add("goal.detail.verifiedat.label", "Verified At", "cms", "en");
    Translator.add("goal.detail.status.label", "Status", "cms", "en");
    Translator.add("goal.detail.status.createdautomatically", "Auto-created", "cms", "en");
    Translator.add("goal.detail.status.uncertain", "Uncertain", "cms", "en");
    Translator.add("goal.detail.status.needsrevision", "Needs revision", "cms", "en");
    Translator.add("goal.detail.status.deprecated", "Deprecated", "cms", "en");
    Translator.add("goal.detail.status.released", "Released", "cms", "en");
    Translator.add("goal.detail.status.unreleased", "Unreleased", "cms", "en");
    Translator.add("goal.detail.status.empty", "-", "cms", "en");
    Translator.add("goal.detail.type.label", "Type", "cms", "en");
    Translator.add("goal.detail.type.hreftitle", "Enter Type", "cms", "en");
    Translator.add("goal.detail.subtype.label", "Subtype", "cms", "en");
    Translator.add("goal.detail.subtype.hreftitle", "Enter Subtype", "cms", "en");
    Translator.add("goal.detail.name.label", "Name", "cms", "en");
    Translator.add("goal.detail.name.hreftitle", "Enter Name", "cms", "en");
    Translator.add("goal.detail.street.label", "Street", "cms", "en");
    Translator.add("goal.detail.street.hreftitle", "Enter Street", "cms", "en");
    Translator.add("goal.detail.zipcode.label", "Zip code", "cms", "en");
    Translator.add("goal.detail.zipcode.hreftitle", "Enter Zip code", "cms", "en");
    Translator.add("goal.detail.cityname.label", "City", "cms", "en");
    Translator.add("goal.detail.cityname.hreftitle", "Enter City", "cms", "en");
    Translator.add("goal.detail.cityid.label", "City Id", "cms", "en");
    Translator.add("goal.detail.regionname.label", "Political Region", "cms", "en");
    Translator.add("goal.detail.regionid.label", "Political Region Id", "cms", "en");
    Translator.add("goal.detail.defaultregionname.label", "Default Region", "cms", "en");
    Translator.add("goal.detail.defaultregionid.label", "Default Region Id", "cms", "en");
    Translator.add("goal.detail.countrycode.label", "Country code", "cms", "en");
    Translator.add("goal.detail.countrycode.hreftitle", "Enter Country", "cms", "en");
    Translator.add("goal.detail.countryname.label", "Country", "cms", "en");
    Translator.add("goal.detail.countryname.hreftitle", "Enter Country", "cms", "en");
    Translator.add("goal.detail.website.label", "Website", "cms", "en");
    Translator.add("goal.detail.website.hreftitle", "Enter Website", "cms", "en");
    Translator.add("goal.detail.telephone.label", "Telephone", "cms", "en");
    Translator.add("goal.detail.telephone.hreftitle", "Enter Telephone", "cms", "en");
    Translator.add("goal.detail.email.label", "Email", "cms", "en");
    Translator.add("goal.detail.email.hreftitle", "Enter Email", "cms", "en");
    Translator.add("goal.detail.airport.label", "Arrival airport", "cms", "en");
    Translator.add("goal.detail.airport.hreftitle", "Enter Arrival airport", "cms", "en");
    Translator.add("goal.detail.relevantairports.label", "Arrival airports", "cms", "en");
    Translator.add("goal.detail.category.label", "Category", "cms", "en");
    Translator.add("goal.detail.category.hreftitle", "Enter Category", "cms", "en");
    Translator.add("goal.detail.longitude.label", "Longitude", "cms", "en");
    Translator.add("goal.detail.longitude.hreftitle", "Enter Longitude", "cms", "en");
    Translator.add("goal.detail.latitude.label", "Latitude", "cms", "en");
    Translator.add("goal.detail.latitude.hreftitle", "Enter Latitude", "cms", "en");
    Translator.add("goal.detail.revisioninfo.label", "Revision-Info", "cms", "en");
    Translator.add("goal.detail.action", "Action", "cms", "en");
    Translator.add("goal.detail.gt.title", "Globaltypes", "cms", "en");
    Translator.add("goal.detail.suppliercode.label", "Supplier Code", "cms", "en");
    Translator.add("goal.detail.productcode.label", "Product Code", "cms", "en");
    Translator.add("goal.tocodetable.title", "Matching Source With Matchtype", "cms", "en");
    Translator.add("goal.tocodetable.providercode.title", "Supplier code", "cms", "en");
    Translator.add("goal.tocodetable.providertype.title", "Provider type", "cms", "en");
    Translator.add("goal.tocodetable.hotelcode.title", "Product code", "cms", "en");
    Translator.add("goal.tocodetable.status.title", "Status", "cms", "en");
    Translator.add("goal.tocodetable.status.uncertain", "Uncertain", "cms", "en");
    Translator.add("goal.tocodetable.release.title", "Release", "cms", "en");
    Translator.add("goal.tocodetable.release.released", "Released", "cms", "en");
    Translator.add("goal.tocodetable.release.unreleased", "Unreleased", "cms", "en");
    Translator.add("goal.tocodetable.detailslink.title", "Link to details", "cms", "en");
    Translator.add("goal.tocodetable.movetoothergoal.title", "Move to another Goal Id", "cms", "en");
    Translator.add("goal.tocodetable.movetoothergoal.swal.title", "Move Matching To Goal", "cms", "en");
    Translator.add("goal.tocodetable.movetoothergoal.swal.text", "Do you really want to match the product (%supplier%, %productcode%) to the Goal ID %id%?", "cms", "en");
    Translator.add("goal.tocodetable.movetoothergoal.swal.confirmbutton", "Yes, Move To Goal", "cms", "en");
    Translator.add("goal.tocodetable.movetoothergoal.swal.cancelbutton", "No", "cms", "en");
    Translator.add("goal.tocodetable.movetoothergoal.swal.error", "An error occoured to move the product %productCode% to another Goal Id. Please try  it again", "cms", "en");
    Translator.add("goal.mappingtable.title", "Matchings", "cms", "en");
    Translator.add("goal.mappingtable.supplier.title", "Supplier Code", "cms", "en");
    Translator.add("goal.mappingtable.code.title", "Product Code", "cms", "en");
    Translator.add("goal.mappingtable.lastupdate.title", "Last Update", "cms", "en");
    Translator.add("goal.mappingtable.giataid.title", "GiataId", "cms", "en");
    Translator.add("goal.mappingtable.goalidreleased.title", "GoalId released", "cms", "en");
    Translator.add("goal.mappingtable.mappingreleased.title", "Matching released", "cms", "en");
    Translator.add("goal.filter.goalid", "Goal Id", "cms", "en");
    Translator.add("goal.filter.tocode", "Supplier code", "cms", "en");
    Translator.add("goal.filter.dlc", "Arrival airport", "cms", "en");
    Translator.add("goal.filter.title", "Filter", "cms", "en");
    Translator.add("goal.filter.name", "Name", "cms", "en");
    Translator.add("goal.filter.street", "Street", "cms", "en");
    Translator.add("goal.filter.region", "Region", "cms", "en");
    Translator.add("goal.filter.cityname", "City", "cms", "en");
    Translator.add("goal.filter.cityname-switch", "City Id", "cms", "en");
    Translator.add("goal.filter.cityid", "City Id", "cms", "en");
    Translator.add("goal.filter.regionname", "Political Region", "cms", "en");
    Translator.add("goal.filter.regionname-switch", "Political Region Id", "cms", "en");
    Translator.add("goal.filter.regionid", "Political Region Id", "cms", "en");
    Translator.add("goal.filter.countryname", "Country", "cms", "en");
    Translator.add("goal.filter.countryname-switch", "Country code", "cms", "en");
    Translator.add("goal.filter.countrycode", "Country code", "cms", "en");
    Translator.add("goal.filter.longitude", "Longitude", "cms", "en");
    Translator.add("goal.filter.latitude", "Latitude", "cms", "en");
    Translator.add("goal.filter.radius", "Search Radius (Amount of neighbors to analyze)", "cms", "en");
    Translator.add("goal.filter.category", "Category", "cms", "en");
    Translator.add("goal.filter.zipcode", "Zip code", "cms", "en");
    Translator.add("goal.filter.website", "Website", "cms", "en");
    Translator.add("goal.filter.email", "Email", "cms", "en");
    Translator.add("goal.filter.telephone", "Telephone", "cms", "en");
    Translator.add("goal.filter.subtype", "SubType", "cms", "en");
    Translator.add("goal.filter.giataid", "Giata Id", "cms", "en");
    Translator.add("goal.filter.bookingid", "Booking Id", "cms", "en");
    Translator.add("goal.filter.status", "Status", "cms", "en");
    Translator.add("goal.filter.goalstates", "Goal Status", "cms", "en");
    Translator.add("goal.filter.hotelcode", "Product code", "cms", "en");
    Translator.add("goal.filter.needsrevision", "Needs Revision", "cms", "en");
    Translator.add("goal.filter.uncertain", "Uncertain", "cms", "en");
    Translator.add("goal.filter.deprecated", "Show Deprecated", "cms", "en");
    Translator.add("goal.filter.createdautomatically", "Auto-Created", "cms", "en");
    Translator.add("goal.filter.showexcludedsubtypes", "Show Excluded Subtypes", "cms", "en");
    Translator.add("goal.filter.released", "Released", "cms", "en");
    Translator.add("goal.filter.unreleased", "Unreleased", "cms", "en");
    Translator.add("goal.filter.all", "All", "cms", "en");
    Translator.add("goal.filter.revisiontag", "Revision Tag", "cms", "en");
    Translator.add("goal.filter.statusitem.needsrevision.label", "Revision", "cms", "en");
    Translator.add("goal.filter.statusitem.needsrevision.yes", "Needs Revision", "cms", "en");
    Translator.add("goal.filter.statusitem.needsrevision.no", "Does not need a revision", "cms", "en");
    Translator.add("goal.filter.statusitem.needsrevision.none", "Ignore revision status", "cms", "en");
    Translator.add("goal.filter.statusitem.deprecated.label", "Deprecated", "cms", "en");
    Translator.add("goal.filter.statusitem.deprecated.yes", "Is deprecated", "cms", "en");
    Translator.add("goal.filter.statusitem.deprecated.no", "Is not deprecated", "cms", "en");
    Translator.add("goal.filter.statusitem.deprecated.none", "Ignore deprecation status", "cms", "en");
    Translator.add("goal.filter.statusitem.released.label", "Released", "cms", "en");
    Translator.add("goal.filter.statusitem.released.yes", "Is released", "cms", "en");
    Translator.add("goal.filter.statusitem.released.no", "Is not released", "cms", "en");
    Translator.add("goal.filter.statusitem.released.none", "Ignore release status", "cms", "en");
    Translator.add("goal.filter.statusitem.matched.label", "Matched", "cms", "en");
    Translator.add("goal.filter.statusitem.matched.yes", "Is matched", "cms", "en");
    Translator.add("goal.filter.statusitem.matched.no", "Is not matched", "cms", "en");
    Translator.add("goal.filter.statusitem.matched.none", "Ignore match status", "cms", "en");
    Translator.add("goal.filter.modal.tab.tocode", "MultiCodes Matching", "cms", "en");
    Translator.add("goal.filter.modal.tab.google", "Google Localfeed", "cms", "en");
    Translator.add("goal.filter.modal.tab.playermatching", "Player Matching", "cms", "en");
    Translator.add("goal.filter.modal.download.title", "Download", "cms", "en");
    Translator.add("goal.filter.modal.download.submit", "Request Download", "cms", "en");
    Translator.add("goal.filter.modal.download.infomessage", "Your request is in process!", "cms", "en");
    Translator.add("goal.filter.modal.tocodes.title", "Supplier codes", "cms", "en");
    Translator.add("goal.filter.modal.includeExtern.title", "Include Extern", "cms", "en");
    Translator.add("goal.filter.modal.format.csv.title", "CSV", "cms", "en");
    Translator.add("goal.filter.modal.format.xml.title", "XML", "cms", "en");
    Translator.add("goal.filter.individual.dlc.searchoption.relevant", "Relevant", "cms", "en");
    Translator.add("goal.filter.individual.dlc.searchoption.potential", "Potential", "cms", "en");
    Translator.add("goal.filter.individual.dlc.searchoption.both", "Both", "cms", "en");
    Translator.add("goal.filter.geoname.showname", "Show Names", "cms", "en");
    Translator.add("goal.map.filter.title", "Edit Goal Data", "cms", "en");
    Translator.add("goal.map.filter.street", "Street", "cms", "en");
    Translator.add("goal.map.filter.city", "City", "cms", "en");
    Translator.add("goal.map.filter.country", "Country", "cms", "en");
    Translator.add("goal.map.filter.longitude", "Longitude", "cms", "en");
    Translator.add("goal.map.filter.latitude", "Latitude", "cms", "en");
    Translator.add("goal.airportlist.title", "Airports", "cms", "en");
    Translator.add("goal.airportlist.relevanttab.title", "Relevant arrivalairports", "cms", "en");
    Translator.add("goal.airportlist.potentialtab.title", "Potential arrivalairports", "cms", "en");
    Translator.add("goal.airportlist.alltab.title", "All arrivalairports", "cms", "en");
    Translator.add("goal.airportlist.searchall.title", "Search", "cms", "en");
    Translator.add("goal.airportlist.searchall.placeholder", "Enter airport IATA code", "cms", "en");
    Translator.add("goal.airportlist.searchall.resultcount", "Showing %count% of %total% airports", "cms", "en");
    Translator.add("goal.airportlist.tooltip.relevant", "Relevant", "cms", "en");
    Translator.add("goal.airportlist.tooltip.potential", "Potential", "cms", "en");
    Translator.add("goal.airportlist.tooltip.label.iata", "IATA", "cms", "en");
    Translator.add("goal.airportlist.tooltip.label.relevance", "Relevance", "cms", "en");
    Translator.add("goal.airportlist.tooltip.label.distance", "Distance", "cms", "en");
    Translator.add("goal.airportlist.tooltip.label.citymatch", "City-match", "cms", "en");
    Translator.add("goal.airportlist.tooltip.label.regionmatch", "Region-match", "cms", "en");
    Translator.add("goal.airportlist.tooltip.label.countrymatch", "Country-match", "cms", "en");
    Translator.add("goal.airportlist.tooltip.label.osmpolygonmatch", "Osmpolygon-match", "cms", "en");
    Translator.add("goal.airportlist.message.error.alreadyexists", "The selected Airport is already relevant!", "cms", "en");
    Translator.add("goal.airportlist.message.error.savefailed", "Could not save relevant airport data!", "cms", "en");
    Translator.add("goal.revision.popup.headline", "Revision-info", "cms", "en");
    Translator.add("goal.revision.tag.headline", "Tags:", "cms", "en");
    Translator.add("goal.revision.tag.GEOLOCATION", "Check geolocation", "cms", "en");
    Translator.add("goal.revision.tag.COUNTRY", "Check Country\/Country code", "cms", "en");
    Translator.add("goal.revision.tag.REGION", "Check region", "cms", "en");
    Translator.add("goal.revision.tag.CITY", "Check city", "cms", "en");
    Translator.add("goal.revision.tag.NO_CITY", "No city found", "cms", "en");
    Translator.add("goal.revision.tag.NEW_CITY", "New city", "cms", "en");
    Translator.add("goal.revision.tag.PASSENGERS_TOTAL", "Check total passengers", "cms", "en");
    Translator.add("goal.revision.tag.NEW", "New Goal Id", "cms", "en");
    Translator.add("goal.revision.tag.MATCHED_AIRPORTS", "Check matched airports", "cms", "en");
    Translator.add("goal.revision.tag.TYPE", "Check type\/subtype", "cms", "en");
    Translator.add("goal.revision.tag.OTHER", "Other", "cms", "en");
    Translator.add("goal.revision.comment.headline", "Comment:", "cms", "en");
    Translator.add("goal.revision.buttons.save", "Save Revision", "cms", "en");
    Translator.add("goal.revision.buttons.reset", "Reset", "cms", "en");
    Translator.add("goal.revision.buttons.delete", "Delete Revision", "cms", "en");
    Translator.add("goal.goalhistorytable.title", "Goal history", "cms", "en");
    Translator.add("goal.goalhistorytable.detail", "Detail", "cms", "en");
    Translator.add("goal.goalhistorytable.goalid.title", "Goal Id", "cms", "en");
    Translator.add("goal.goalhistorytable.timestamp.title", "Timestamp", "cms", "en");
    Translator.add("goal.goalhistorytable.contributor.title", "Contributor", "cms", "en");
    Translator.add("goal.goalhistorytable.field.title", "Field", "cms", "en");
    Translator.add("goal.goalhistorytable.action.title", "Action", "cms", "en");
    Translator.add("goal.goalhistorytable.oldvalue.title", "Old value", "cms", "en");
    Translator.add("goal.goalhistorytable.newvalue.title", "New value", "cms", "en");
    Translator.add("giata.navigation.title", "Giata", "cms", "en");
    Translator.add("giata.navigation.data", "Giata Data", "cms", "en");
    Translator.add("giata.navigation.geodata", "Giata Geo data", "cms", "en");
    Translator.add("giata.main.title", "Giata", "cms", "en");
    Translator.add("giata.giatatable.title", "Giata table", "cms", "en");
    Translator.add("giata.giatatable.giataid.title", "Giata Id", "cms", "en");
    Translator.add("giata.giatatable.name.title", "Name", "cms", "en");
    Translator.add("giata.giatatable.city.title", "City", "cms", "en");
    Translator.add("giata.giatatable.region.title", "Region", "cms", "en");
    Translator.add("giata.giatatable.country.title", "Country", "cms", "en");
    Translator.add("giata.giatatable.chain.title", "Chain", "cms", "en");
    Translator.add("giata.giatatable.action.title", "Action", "cms", "en");
    Translator.add("giata.giatatable.action.show.title", "Show", "cms", "en");
    Translator.add("giata.detail.basicdata.title", "Basicdata", "cms", "en");
    Translator.add("giata.detail.title", "Giata detail", "cms", "en");
    Translator.add("giata.detail.cat.infos.title", "Infos", "cms", "en");
    Translator.add("giata.detail.cat.addressdata.title", "Address data", "cms", "en");
    Translator.add("giata.detail.cat.communication.title", "Communication", "cms", "en");
    Translator.add("giata.detail.cat.additional.title", "Additional data", "cms", "en");
    Translator.add("giata.detail.map.title", "Map", "cms", "en");
    Translator.add("giata.detail.giataid.label", "Giata Id", "cms", "en");
    Translator.add("giata.detail.giataid.hreftitle", "Enter Giata Id", "cms", "en");
    Translator.add("giata.detail.updated.label", "Updated", "cms", "en");
    Translator.add("giata.detail.name.label", "Name", "cms", "en");
    Translator.add("giata.detail.street.label", "Street", "cms", "en");
    Translator.add("giata.detail.zipcode.label", "Zip code", "cms", "en");
    Translator.add("giata.detail.city.label", "City", "cms", "en");
    Translator.add("giata.detail.region.label", "Region", "cms", "en");
    Translator.add("giata.detail.country.label", "Country", "cms", "en");
    Translator.add("giata.detail.website.label", "Website", "cms", "en");
    Translator.add("giata.detail.phones.voice.label", "Telephone", "cms", "en");
    Translator.add("giata.detail.phones.fax.label", "Fax", "cms", "en");
    Translator.add("giata.detail.email.label", "Email", "cms", "en");
    Translator.add("giata.detail.airport.label", "Arrivalairport", "cms", "en");
    Translator.add("giata.detail.longitude.label", "Longitude", "cms", "en");
    Translator.add("giata.detail.longitude.hreftitle", "Enter longitude", "cms", "en");
    Translator.add("giata.detail.latitude.label", "Latitude", "cms", "en");
    Translator.add("giata.detail.latitude.hreftitle", "Enter latitude", "cms", "en");
    Translator.add("giata.detail.chain.label", "Chain", "cms", "en");
    Translator.add("giata.detail.airports.label", "Airports", "cms", "en");
    Translator.add("giata.detail.action", "Action", "cms", "en");
    Translator.add("giata.tocodetable.title", "Matching source with matchtype", "cms", "en");
    Translator.add("giata.tocodetable.providercode.title", "Supplier code", "cms", "en");
    Translator.add("giata.tocodetable.providertype.title", "Provider type", "cms", "en");
    Translator.add("giata.tocodetable.hotelcode.title", "Product code", "cms", "en");
    Translator.add("giata.tocodetable.status.title", "Status", "cms", "en");
    Translator.add("giata.tocodetable.status.uncertain", "Uncertain", "cms", "en");
    Translator.add("giata.mappingtable.title", "Mappings", "cms", "en");
    Translator.add("giata.mappingtable.supplier.title", "Supplier Code", "cms", "en");
    Translator.add("giata.mappingtable.code.title", "Product Code", "cms", "en");
    Translator.add("giata.mappingtable.lastupdate.title", "Last Update", "cms", "en");
    Translator.add("giata.filter.goalid", "Goal Id", "cms", "en");
    Translator.add("giata.filter.tocode", "Supplier code", "cms", "en");
    Translator.add("giata.filter.dlc", "Arrvivalairport", "cms", "en");
    Translator.add("giata.filter.title", "Filter", "cms", "en");
    Translator.add("giata.filter.name", "Name", "cms", "en");
    Translator.add("giata.filter.street", "Street", "cms", "en");
    Translator.add("giata.filter.city", "City", "cms", "en");
    Translator.add("giata.filter.country", "Country", "cms", "en");
    Translator.add("giata.filter.longitude", "Longitude", "cms", "en");
    Translator.add("giata.filter.latitude", "Latitude", "cms", "en");
    Translator.add("giata.filter.radius", "Search radius (amount of neighbors to analyze)", "cms", "en");
    Translator.add("giata.filter.category", "Category", "cms", "en");
    Translator.add("giata.filter.zipcode", "Zip code", "cms", "en");
    Translator.add("giata.filter.website", "Website", "cms", "en");
    Translator.add("giata.filter.email", "Email", "cms", "en");
    Translator.add("giata.filter.telephone", "Telephone", "cms", "en");
    Translator.add("giata.filter.subtype", "SubType", "cms", "en");
    Translator.add("giata.filter.giataid", "Giata Id", "cms", "en");
    Translator.add("giata.filter.bookingid", "Booking Id", "cms", "en");
    Translator.add("giata.filter.status", "Status", "cms", "en");
    Translator.add("giata.filter.region", "Region", "cms", "en");
    Translator.add("giata.filter.hotelcode", "Product code", "cms", "en");
    Translator.add("giata.filter.modal.tab.tocode", "Multi codes matching", "cms", "en");
    Translator.add("giata.filter.modal.tab.google", "Google localfeed", "cms", "en");
    Translator.add("giata.filter.modal.download.title", "Download", "cms", "en");
    Translator.add("giata.filter.modal.download.submit", "Request download", "cms", "en");
    Translator.add("giata.filter.modal.download.infomessage", "Your request is in process!", "cms", "en");
    Translator.add("giata.filter.modal.tocodes.title", "Supplier code", "cms", "en");
    Translator.add("giata.filter.modal.includeExtern.title", "Include extern", "cms", "en");
    Translator.add("giata.filter.modal.format.csv.title", "CSV", "cms", "en");
    Translator.add("giata.filter.modal.format.xml.title", "XML", "cms", "en");
    Translator.add("giata.geodata.overview.title", "Giata Geo data", "cms", "en");
    Translator.add("giata.geodata.overview.searchcountry.maintitle", "Country", "cms", "en");
    Translator.add("giata.geodata.overview.searchcountry.title", "Search by country", "cms", "en");
    Translator.add("giata.geodata.overview.searchcountry.placeholder", "Country name or id", "cms", "en");
    Translator.add("giata.geodata.overview.searchcountry.resultcount", "Showing %count% of %total% countries", "cms", "en");
    Translator.add("giata.geodata.overview.searchregion.maintitle", "Region", "cms", "en");
    Translator.add("giata.geodata.overview.searchregion.title", "Search by region", "cms", "en");
    Translator.add("giata.geodata.overview.searchregion.placeholder", "Region name or id", "cms", "en");
    Translator.add("giata.geodata.overview.searchregion.resultcount", "Showing %count% of %total% regions", "cms", "en");
    Translator.add("giata.geodata.overview.searchcity.maintitle", "City", "cms", "en");
    Translator.add("giata.geodata.overview.searchcity.title", "Search by city", "cms", "en");
    Translator.add("giata.geodata.overview.searchcity.placeholder", "City name or id", "cms", "en");
    Translator.add("giata.geodata.overview.searchcity.resultcount", "Showing %count% of %total% cities", "cms", "en");
    Translator.add("giata.geodata.detail.title", "Giata details", "cms", "en");
    Translator.add("giata.geodata.detail.basicdata.title", "Basic data", "cms", "en");
    Translator.add("giata.geodata.detail.basicdata.cat.addressdata.title", "Giata details", "cms", "en");
    Translator.add("giata.geodata.detail.basicdata.name.label", "Name", "cms", "en");
    Translator.add("giata.geodata.detail.basicdata.cityid.label", "City Id", "cms", "en");
    Translator.add("giata.geodata.detail.basicdata.regionid.label", "Region Id", "cms", "en");
    Translator.add("giata.geodata.detail.basicdata.countrycode.label", "Country code", "cms", "en");
    Translator.add("giata.geodata.translations.title", "Giata translations", "cms", "en");
    Translator.add("giata.geodata.translations.empty", "Empty", "cms", "en");
    Translator.add("basicdata.navigation", "GOAL Basicdata", "cms", "en");
    Translator.add("basicdata.airports.navigation", "Airports", "cms", "en");
    Translator.add("basicdata.airports.table.title", "Airports", "cms", "en");
    Translator.add("basicdata.airports.table.name.title", "Name", "cms", "en");
    Translator.add("basicdata.airports.table.city.title", "City", "cms", "en");
    Translator.add("basicdata.airports.table.countrycode.title", "Country code", "cms", "en");
    Translator.add("basicdata.airports.table.iatacode.title", "Iata code", "cms", "en");
    Translator.add("basicdata.airports.table.stationtypename.title", "Station type", "cms", "en");
    Translator.add("basicdata.airports.table.action.title", "Action", "cms", "en");
    Translator.add("basicdata.airports.filter.title", "Filter", "cms", "en");
    Translator.add("basicdata.airports.filter.iatacodes", "Iata codes", "cms", "en");
    Translator.add("basicdata.airports.filter.iata", "Iata code", "cms", "en");
    Translator.add("basicdata.airports.filter.name", "Name", "cms", "en");
    Translator.add("basicdata.airports.filter.city", "City or City ID", "cms", "en");
    Translator.add("basicdata.airports.filter.region", "Region or Region ID", "cms", "en");
    Translator.add("basicdata.airports.filter.country", "Country or Country code", "cms", "en");
    Translator.add("basicdata.airports.filter.stationtype", "Station type", "cms", "en");
    Translator.add("basicdata.airports.filter.passengers", "Passengers", "cms", "en");
    Translator.add("basicdata.airports.filter.any", "", "cms", "en");
    Translator.add("basicdata.airports.filter.lessthan100", "less than 100", "cms", "en");
    Translator.add("basicdata.airports.filter.lessthan1000", "less than 1000", "cms", "en");
    Translator.add("basicdata.airports.filter.lessthan10000", "less than 10000", "cms", "en");
    Translator.add("basicdata.airports.filter.lessthan100000", "less than 100000", "cms", "en");
    Translator.add("basicdata.airports.filter.lessthan1000000", "less than 1000000", "cms", "en");
    Translator.add("basicdata.airports.filter.greaterthan1000000", "greater than 1000000", "cms", "en");
    Translator.add("basicdata.airports.detail.title", "Airport Details", "cms", "en");
    Translator.add("basicdata.airports.detail.cat.information.title", "Information", "cms", "en");
    Translator.add("basicdata.airports.detail.cat.passengers.title", "Passenger Data", "cms", "en");
    Translator.add("basicdata.airports.detail.cat.location.title", "Location", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.title", "Basicdata", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.name.label", "Name", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.city.label", "City", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.country.label", "Country", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.iatacode.label", "Iata code", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.icaocode.label", "Icao code", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.latitude.label", "Latitude", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.longitude.label", "Longitude", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.altitude_ft.label", "Altitude ft", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.timezone.label", "Timezone", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.dst.label", "DST", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.tzdatabasetime.label", "Tz database time", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.cityid.label", "City Id", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.regionid.label", "Region Id", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.countrycode.label", "Country code", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.osmpolygonid.label", "Osmpolygon Id", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.relevance.label", "Relevance", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.stationtype.label", "Station type", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.stationtypename.label", "Station typename", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.passengerstotal.label", "Passengers (total)", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.passengersterminal.label", "Passengers (terminal)", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.passengerdomestic.label", "Passengers (domestic)", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.passengerinternational.label", "Passengers (international)", "cms", "en");
    Translator.add("basicdata.airports.detail.basicdata.passengertransit.label", "Passengers (transit)", "cms", "en");
    Translator.add("basicdata.geodata.navigation", "Geo data", "cms", "en");
    Translator.add("basicdata.geodata.overview.title", "Geo data", "cms", "en");
    Translator.add("basicdata.geodata.overview.searchcountry.maintitle", "Country", "cms", "en");
    Translator.add("basicdata.geodata.overview.searchcountry.title", "Search by country code", "cms", "en");
    Translator.add("basicdata.geodata.overview.searchcountry.placeholder", "Enter country code", "cms", "en");
    Translator.add("basicdata.geodata.overview.searchcountry.resultcount", "Showing %count% of %total% countries", "cms", "en");
    Translator.add("basicdata.geodata.overview.searchregion.maintitle", "Region", "cms", "en");
    Translator.add("basicdata.geodata.overview.searchregion.title", "Search by region name", "cms", "en");
    Translator.add("basicdata.geodata.overview.searchregion.placeholder", "Enter region name", "cms", "en");
    Translator.add("basicdata.geodata.overview.searchregion.resultcount", "Showing %count% of %total% regions", "cms", "en");
    Translator.add("basicdata.geodata.overview.searchcity.maintitle", "City", "cms", "en");
    Translator.add("basicdata.geodata.overview.searchcity.title", "Search by city name", "cms", "en");
    Translator.add("basicdata.geodata.overview.searchcity.placeholder", "Enter city name", "cms", "en");
    Translator.add("basicdata.geodata.overview.searchcity.resultcount", "Showing %count% of %total% cities", "cms", "en");
    Translator.add("basicdata.geodata.detail.title", "Details", "cms", "en");
    Translator.add("basicdata.geodata.detail.cat.addressdata.title", "Address Data", "cms", "en");
    Translator.add("basicdata.geodata.detail.cat.additionaldata.title", "Additional Data", "cms", "en");
    Translator.add("basicdata.geodata.detail.basicdata.title", "Basicdata", "cms", "en");
    Translator.add("basicdata.geodata.detail.basicdata.name.label", "Name", "cms", "en");
    Translator.add("basicdata.geodata.detail.basicdata.cityid.label", "City Id", "cms", "en");
    Translator.add("basicdata.geodata.detail.basicdata.regionid.label", "Region Id", "cms", "en");
    Translator.add("basicdata.geodata.detail.basicdata.countrycode.label", "Country code", "cms", "en");
    Translator.add("basicdata.geodata.detail.basicdata.latitude.label", "Latitude", "cms", "en");
    Translator.add("basicdata.geodata.detail.basicdata.longitude.label", "Longitude", "cms", "en");
    Translator.add("basicdata.poidata.navigation", "Poi data", "cms", "en");
    Translator.add("basicdata.poidata.table.title", "Poi data", "cms", "en");
    Translator.add("basicdata.poidata.table.id.title", "Poi Id", "cms", "en");
    Translator.add("basicdata.poidata.table.countrycode.title", "Country code", "cms", "en");
    Translator.add("basicdata.poidata.table.regionname.title", "Region", "cms", "en");
    Translator.add("basicdata.poidata.table.subareaname.title", "Subarea name", "cms", "en");
    Translator.add("basicdata.poidata.table.themeid.title", "Theme Id", "cms", "en");
    Translator.add("basicdata.poidata.table.name.title", "Name", "cms", "en");
    Translator.add("basicdata.poidata.table.cityname.title", "City", "cms", "en");
    Translator.add("basicdata.poidata.table.highlight.title", "Highlight", "cms", "en");
    Translator.add("basicdata.poidata.table.topbeach.title", "Top beach", "cms", "en");
    Translator.add("basicdata.poidata.table.action.title", "Action", "cms", "en");
    Translator.add("basicdata.poidata.filter.title", "Filter", "cms", "en");
    Translator.add("basicdata.poidata.filter.any", "", "cms", "en");
    Translator.add("basicdata.poidata.filter.id", "Poi Id", "cms", "en");
    Translator.add("basicdata.poidata.filter.countrycode", "Country code", "cms", "en");
    Translator.add("basicdata.poidata.filter.regionname", "Region", "cms", "en");
    Translator.add("basicdata.poidata.filter.subareaname", "Subarea name", "cms", "en");
    Translator.add("basicdata.poidata.filter.cityname", "City", "cms", "en");
    Translator.add("basicdata.poidata.filter.themeid", "Theme Id", "cms", "en");
    Translator.add("basicdata.poidata.filter.highlight", "Highlight", "cms", "en");
    Translator.add("basicdata.poidata.filter.topbeach", "Top beach", "cms", "en");
    Translator.add("basicdata.poidata.filter.ishighlight", "Is Highlight", "cms", "en");
    Translator.add("basicdata.poidata.filter.nohighlight", "No Highlight", "cms", "en");
    Translator.add("basicdata.poidata.filter.istopbeach", "Is top beach", "cms", "en");
    Translator.add("basicdata.poidata.filter.notopbeach", "No top beach", "cms", "en");
    Translator.add("map.sidebar.title", "Map Sidebar", "cms", "en");
    Translator.add("map.sidebar.geosearch.headline", "Geo search", "cms", "en");
    Translator.add("map.sidebar.geosearch.hotel.label", "Hotels in radius", "cms", "en");
    Translator.add("map.sidebar.geosearch.airports.label", "Airports in radius", "cms", "en");
    Translator.add("map.sidebar.geosearch.km", "km", "cms", "en");
    Translator.add("map.sidebar.zoombounds.headline", "Zoom\/center", "cms", "en");
    Translator.add("map.sidebar.zoombounds.cat.center", "Goal hotel", "cms", "en");
    Translator.add("map.sidebar.zoombounds.cat.matchedaptrelevant", "Arrivalairports", "cms", "en");
    Translator.add("map.sidebar.zoombounds.cat.matchedaptpotential", "Potential Arrivalairports1", "cms", "en");
    Translator.add("map.sidebar.zoombounds.cat.searchairport", "Geosearch airports", "cms", "en");
    Translator.add("map.sidebar.zoombounds.cat.searchgoal", "Geosearch hotels", "cms", "en");
    Translator.add("map.infowindow.title", "Map Infowindow", "cms", "en");
    Translator.add("map.infowindow.errortext", "Could not render marker information.", "cms", "en");
    Translator.add("map.infowindow.cat.matchedapt.relevant.label", "Arrivals", "cms", "en");
    Translator.add("map.infowindow.cat.matchedapt.potential.label", "Pot arrivals", "cms", "en");
    Translator.add("map.infowindow.cat.matchedapt.attr.label.iata", "IATA", "cms", "en");
    Translator.add("map.infowindow.cat.matchedapt.attr.label.relevance", "Relevance", "cms", "en");
    Translator.add("map.infowindow.cat.matchedapt.attr.label.distance", "Distance", "cms", "en");
    Translator.add("map.infowindow.cat.matchedapt.attr.label.citymatch", "City-match", "cms", "en");
    Translator.add("map.infowindow.cat.matchedapt.attr.label.regionmatch", "Region-match", "cms", "en");
    Translator.add("map.infowindow.cat.matchedapt.attr.label.countrymatch", "Country-match", "cms", "en");
    Translator.add("map.infowindow.cat.matchedapt.attr.label.osmpolygonmatch", "Osmpolygon-match", "cms", "en");
    Translator.add("map.infowindow.cat.airport.label", "Airport", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.iata", "IATA", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.relevance", "Relevance", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.distance", "Distance", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.citymatch", "City-match", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.regionmatch", "Region-match", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.countrymatch", "Country-match", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.osmpolygonmatch", "Osmpolygon-match", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.iatacode", "IATA", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.city", "City", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.cityid", "City Id", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.countrycode", "Country code", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.latitude", "Latitude", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.longitude", "Longitude", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.name", "Name", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.osmpolygonid", "OSMPolygon Id", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.regionid", "Region Id", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.stationtypename", "Station typename", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.passengerinternational", "Passengers (international)", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.passengersterminal", "Passengers (terminal)", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.passengerstotal", "Passengers (total)", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.passengerstransit", "Passengers (transit)", "cms", "en");
    Translator.add("map.infowindow.cat.airport.attr.label.timezone", "Timezone", "cms", "en");
    Translator.add("map.infowindow.cat.airport.action.setrelevant", "Set airport for all Goal Ids in polygon to relevant", "cms", "en");
    Translator.add("map.infowindow.cat.airport.action.alert.title", "Set airport %apt% to relevant for all Goal Ids", "cms", "en");
    Translator.add("map.infowindow.cat.airport.action.alert.message", "Do you really want to set all the airport as relevant for all Goal Ids in the catchment? This might change a lot of Goal Ids!", "cms", "en");
    Translator.add("map.infowindow.cat.airport.action.alert.confirmbutton", "Set airport %apt% relevant", "cms", "en");
    Translator.add("map.infowindow.cat.airport.action.alert.message_ok", "The request was accepted and will be processed by the server.", "cms", "en");
    Translator.add("map.infowindow.cat.airport.action.alert.message_error", "ERROR: The request was not accepted.", "cms", "en");
    Translator.add("map.infowindow.cat.airport.action.alert.confirmbutton_ok", "Ok", "cms", "en");
    Translator.add("map.infowindow.cat.airport.action.alert.title_missing", "Missing information", "cms", "en");
    Translator.add("map.infowindow.cat.airport.action.alert.missingcatchment", "Missing polygon area information. ", "cms", "en");
    Translator.add("map.infowindow.cat.airport.action.alert.missingiata", "Missing airport IATA code.", "cms", "en");
    Translator.add("map.infowindow.cat.airport.action.alert.ok_missing", "Ok", "cms", "en");
    Translator.add("map.infowindow.cat.goal.label", "Goal Id", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.goalid", "Goal Id", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.airport", "Arrivalairports", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.bookingid", "Booking Id", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.category", "Category", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.city", "City", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.cityedf", "EDF-City", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.cityid", "City Id", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.regionid", "Region Id", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.countrycode", "County code", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.country", "Country", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.createdautomatically", "Auto-created", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.deprecated", "Deprecated", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.released", "Released", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.unreleased", "Unreleased", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.email", "Email", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.gardid", "Gard Id", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.giataid", "Giata Id", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.latitude", "Latitude", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.longitude", "Longitude", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.name", "Name", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.needsrevision", "Needs revision", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.street", "Street", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.subtype", "Subtype", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.telephone", "Telephone", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.type", "Type", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.uncertain", "Uncertain", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.website", "Website", "cms", "en");
    Translator.add("map.infowindow.cat.goal.attr.label.zipcode", "ZipCode", "cms", "en");
    Translator.add("map.buttons.defaultcenter.text", "Reset Zoom", "cms", "en");
    Translator.add("map.buttons.defaultcenter.title", "Reset Zoom", "cms", "en");
    Translator.add("map.buttons.zoomtogoal.text", "Zoom To Goal", "cms", "en");
    Translator.add("map.buttons.zoomtogoal.title", "Zoom To Goal", "cms", "en");
    Translator.add("map.buttons.zoomtogiata.text", "Zoom To Giata", "cms", "en");
    Translator.add("map.buttons.zoomtogiata.title", "Zoom To Giata", "cms", "en");
    Translator.add("map.buttons.zoomtoproduct.text", "Zoom To Product", "cms", "en");
    Translator.add("map.buttons.zoomtoproduct.title", "Zoom To Product", "cms", "en");
    Translator.add("geomatchingservicestatus.filter.valid.label", "Valid", "cms", "en");
    Translator.add("goalmatching.buttons.assignnewgoal", "Assign To New Goal ID", "cms", "en");
    Translator.add("goalmatching.buttons.go", "Go!", "cms", "en");
    Translator.add("goalmatching.navigation.group", "Goal matching", "cms", "en");
    Translator.add("goalmatching.navigation.home", "Goal matching", "cms", "en");
    Translator.add("goalmatching.navigation.geomatchservicestatus", "Goal Matching Status", "cms", "en");
    Translator.add("goalmatching.filter.title", "Filter", "cms", "en");
    Translator.add("goalmatching.filter.goalid", "Goal Id", "cms", "en");
    Translator.add("goalmatching.filter.tocode", "Supplier code", "cms", "en");
    Translator.add("goalmatching.filter.code", "Product code", "cms", "en");
    Translator.add("goalmatching.filter.includeextern", "Include extern", "cms", "en");
    Translator.add("goalmatching.filter.referenceid", "Reference Id", "cms", "en");
    Translator.add("goalmatching.table.title", "Goal matching", "cms", "en");
    Translator.add("goalmatching.table.goalid.title", "Goal Id", "cms", "en");
    Translator.add("goalmatching.table.tocode.title", "Supplier code", "cms", "en");
    Translator.add("goalmatching.table.hotelcode.title", "Product code", "cms", "en");
    Translator.add("goalmatching.table.action.title", "Action", "cms", "en");
    Translator.add("goalmatching.edit.title", "Goal matching release\/edit", "cms", "en");
    Translator.add("goalmatching.edit.edf.title", "EDF", "cms", "en");
    Translator.add("goalmatching.edit.edf.select.title", "Select EDF by key", "cms", "en");
    Translator.add("goalmatching.edit.edf.name.label", "Name", "cms", "en");
    Translator.add("goalmatching.edit.edf.street.label", "Street", "cms", "en");
    Translator.add("goalmatching.edit.edf.zipcode.label", "Zip code", "cms", "en");
    Translator.add("goalmatching.edit.edf.website.label", "Website", "cms", "en");
    Translator.add("goalmatching.edit.edf.email.label", "Email", "cms", "en");
    Translator.add("goalmatching.edit.edf.telephone.label", "Telephone", "cms", "en");
    Translator.add("goalmatching.edit.edf.city.label", "City", "cms", "en");
    Translator.add("goalmatching.edit.edf.country.label", "Country", "cms", "en");
    Translator.add("goalmatching.edit.edf.references.label", "References", "cms", "en");
    Translator.add("goalmatching.edit.edf.GIATA.label", "Giata Id", "cms", "en");
    Translator.add("goalmatching.edit.edf.code.label", "Code", "cms", "en");
    Translator.add("goalmatching.edit.edf.source.label", "Source", "cms", "en");
    Translator.add("goalmatching.edit.edf.tocode.label", "Supplier code", "cms", "en");
    Translator.add("goalmatching.edit.edf.toname.label", "Supplier name", "cms", "en");
    Translator.add("goalmatching.edit.edf.airports.label", "Arrivalairports", "cms", "en");
    Translator.add("goalmatching.edit.edf.latitude.label", "Latitude", "cms", "en");
    Translator.add("goalmatching.edit.edf.longitude.label", "Longitude", "cms", "en");
    Translator.add("goalmatching.edit.edf.edfkey.label", "EDF-key", "cms", "en");
    Translator.add("goalmatching.edit.goal.title", "Goal", "cms", "en");
    Translator.add("goalmatching.edit.goal.name.label", "Name", "cms", "en");
    Translator.add("goalmatching.edit.goal.street.label", "Street", "cms", "en");
    Translator.add("goalmatching.edit.goal.zipcode.label", "Zip code", "cms", "en");
    Translator.add("goalmatching.edit.goal.website.label", "Website", "cms", "en");
    Translator.add("goalmatching.edit.goal.email.label", "Email", "cms", "en");
    Translator.add("goalmatching.edit.goal.telephone.label", "Telephone", "cms", "en");
    Translator.add("goalmatching.edit.goal.category.label", "Category", "cms", "en");
    Translator.add("goalmatching.edit.goal.cityname.label", "CityName", "cms", "en");
    Translator.add("goalmatching.edit.goal.cityedf.label", "City EDF", "cms", "en");
    Translator.add("goalmatching.edit.goal.cityid.label", "City Id", "cms", "en");
    Translator.add("goalmatching.edit.goal.regionname.label", "Region", "cms", "en");
    Translator.add("goalmatching.edit.goal.regionid.label", "Region Id", "cms", "en");
    Translator.add("goalmatching.edit.goal.countryname.label", "Country", "cms", "en");
    Translator.add("goalmatching.edit.goal.countrycode.label", "Country code", "cms", "en");
    Translator.add("goalmatching.edit.goal.airport.label", "Airport", "cms", "en");
    Translator.add("goalmatching.edit.goal.relevantairports.label", "Arrivalairports", "cms", "en");
    Translator.add("goalmatching.edit.goal.type.label", "Type", "cms", "en");
    Translator.add("goalmatching.edit.goal.subtype.label", "Sub type", "cms", "en");
    Translator.add("goalmatching.edit.goal.latitude.label", "Latitude", "cms", "en");
    Translator.add("goalmatching.edit.goal.longitude.label", "Longitude", "cms", "en");
    Translator.add("goalmatching.edit.goal.goalid.label", "Goal Id", "cms", "en");
    Translator.add("goalmatching.edit.goal.gardid.label", "Gard Id", "cms", "en");
    Translator.add("goalmatching.edit.goal.giataid.label", "Giata Id", "cms", "en");
    Translator.add("goalmatching.edit.goal.bookingid.label", "Booking Id", "cms", "en");
    Translator.add("goalmatching.edit.goal.createdautomatically.label", "Created automatically", "cms", "en");
    Translator.add("goalmatching.edit.goal.uncertain.label", "Uncertain", "cms", "en");
    Translator.add("goalmatching.edit.goal.needsrevision.label", "Needs revision", "cms", "en");
    Translator.add("goalmatching.edit.goal.deprecated.label", "Deprecated", "cms", "en");
    Translator.add("goalmatching.edit.map.title", "Map Goal Id:", "cms", "en");
    Translator.add("goalmatching.assign.title", "Goal matching assignment", "cms", "en");
    Translator.add("goalmatching.assign.goal.radius.title", "Radius (km)", "cms", "en");
    Translator.add("goalmatching.assign.goal.button.assign.title", "Assign EDF to Goal Id", "cms", "en");
    Translator.add("goalmatching.assign.goal.button.showdetails.title", "Show Goal details", "cms", "en");
    Translator.add("goalmatching.assign.goal.button.gotopage.title", "Open Goal detailspage", "cms", "en");
    Translator.add("goalmatching.assign.goal.button.refresh.title", "Refresh Goal", "cms", "en");
    Translator.add("goalmatching.assign.goal.swal.assigntogoaltitle", "Assign EDF to Goal ID %id%", "cms", "en");
    Translator.add("goalmatching.assign.goal.swal.assigntogoaltext", "Do you really want to assign the EDF to the Goal Id %id%?", "cms", "en");
    Translator.add("goalmatching.assign.goal.swal.assigntonewgoaltitle", "Assign EDF to new Goal Id", "cms", "en");
    Translator.add("goalmatching.assign.goal.swal.assigntonewgoaltext", "Do you really want to assign the EDF to a new Goal Id?", "cms", "en");
    Translator.add("goalmatching.assign.goal.swal.assigntogoalconfirmbutton", "Yes, assign it", "cms", "en");
    Translator.add("goalmatching.assign.goal.swal.assigntogoalcancelbutton", "No", "cms", "en");
    Translator.add("goalmatching.assign.goal.swal.error.assigntogoaltitle", "Assignment to Goal Id %id% did not work", "cms", "en");
    Translator.add("goalmatching.assign.goal.swal.error.assigntogoaltext", "An Error occured while assigning EDF to Goal Id %id%!", "cms", "en");
    Translator.add("goalmatching.assign.goal.swal.error.assigntonewgoaltitle", "Assignment to a new Goal Id did not work", "cms", "en");
    Translator.add("goalmatching.assign.goal.swal.error.assigntonewgoaltext", "An Error occured while assigning EDF to a new Goal Id", "cms", "en");
    Translator.add("goalmatching.assign.goal.swal.error.alreadyexiststitle", "EDF already has a existing Goal", "cms", "en");
    Translator.add("goalmatching.assign.goal.swal.error.alreadyexiststext", "The EDF already has a existing Goal", "cms", "en");
    Translator.add("goalmatching.assign.goal.swal.error.goalnotreleasedtitle", "EDF is already matched to a unreleased Goal", "cms", "en");
    Translator.add("goalmatching.assign.goal.swal.error.goalnotreleasedtext", "EDF is already matched to a unreleased Goal. Click the Button to edit the unreleased Goal.", "cms", "en");
    Translator.add("goalmatching.assign.goal.swal.error.assignmentfailedtitle", "Assignment to new Goal Id did not work", "cms", "en");
    Translator.add("goalmatching.assign.goal.swal.error.assignmentfailedtext", "An Error occured while assigning EDF to a new Goal Id!", "cms", "en");
    Translator.add("goalmatching.assign.map.title", "EDF map", "cms", "en");
    Translator.add("goalmatching.geomatchresults.table.title", "Goal Matching Status Results", "cms", "en");
    Translator.add("goalmatching.geomatchresults.table.header.valid", "Valid", "cms", "en");
    Translator.add("goalmatching.geomatchresults.table.header.matchedat", "Matched at", "cms", "en");
    Translator.add("goalmatching.geomatchresults.table.header.created", "Created", "cms", "en");
    Translator.add("goalmatching.geomatchresults.table.header.matched", "Matched", "cms", "en");
    Translator.add("goalmatching.geomatchresults.table.header.matchedgoalid", "Matched Goal Id", "cms", "en");
    Translator.add("goalmatching.geomatchresults.table.header.candidates", "Candidates count", "cms", "en");
    Translator.add("geomatchingservicestatus.filter.title", " Matching Status Results Filter", "cms", "en");
    Translator.add("geomatchingservicestatus.filter.supplier", "Supplier", "cms", "en");
    Translator.add("geomatchingservicestatus.filter.productcode", "Productcode", "cms", "en");
    Translator.add("geomatchingservicestatus.filter.status", "Status", "cms", "en");
    Translator.add("geomatchingservicestatus.filter.matchedatfrom", "Matched At (From)", "cms", "en");
    Translator.add("geomatchingservicestatus.filter.matchedatto", "Matched At (To)", "cms", "en");
    Translator.add("geomatchingservicestatus.filter.createdfrom", "Created (From)", "cms", "en");
    Translator.add("geomatchingservicestatus.filter.createdto", "Created (To)", "cms", "en");
    Translator.add("geomatchingservicestatus.filter.states", "States", "cms", "en");
    Translator.add("geomatchingservicestatus.filter.valid.yes", "Valid", "cms", "en");
    Translator.add("geomatchingservicestatus.filter.valid.no", "Not valid", "cms", "en");
    Translator.add("geomatchingservicestatus.filter.valid.none", "Ignore validity", "cms", "en");
    Translator.add("geomatchingservicestatus.filter.matched.label", "Matched", "cms", "en");
    Translator.add("geomatchingservicestatus.filter.matched.yes", "Matched", "cms", "en");
    Translator.add("geomatchingservicestatus.filter.matched.no", "Not matched", "cms", "en");
    Translator.add("geomatchingservicestatus.filter.matched.none", "Ignore matched state", "cms", "en");
    Translator.add("globaltype.buttons.abort", "Abort", "cms", "en");
    Translator.add("globaltype.buttons.close", "Close", "cms", "en");
    Translator.add("globaltype.buttons.reset", "Reset", "cms", "en");
    Translator.add("globaltype.buttons.save", "Save", "cms", "en");
    Translator.add("globaltype.buttons.selectfile", "Select files...", "cms", "en");
    Translator.add("globaltype.buttons.start", "Start", "cms", "en");
    Translator.add("globaltype.navigation.home", "GlobalType", "cms", "en");
    Translator.add("globaltype.navigation.categories", "Categories", "cms", "en");
    Translator.add("globaltype.navigation.translations", "Translations", "cms", "en");
    Translator.add("globaltype.tables.action", "Action", "cms", "en");
    Translator.add("globaltype.tables.translations.title", "Translations", "cms", "en");
    Translator.add("globaltype.tables.translations.id", "id", "cms", "en");
    Translator.add("globaltype.tables.translations.globaltype", "GlobalType", "cms", "en");
    Translator.add("globaltype.tables.translations.subtype", "SubType", "cms", "en");
    Translator.add("globaltype.tables.translations.additionaltype", "AdditionalType", "cms", "en");
    Translator.add("globaltype.tables.translations.translations", "Translations", "cms", "en");
    Translator.add("globaltype.tables.translations.description", "Description", "cms", "en");
    Translator.add("globaltype.tables.translations.language", "Language Code", "cms", "en");
    Translator.add("globaltype.tables.translations.country", "Country Code", "cms", "en");
    Translator.add("globaltype.tables.translations.addelement", "Add Element", "cms", "en");
    Translator.add("globaltype.tables.translations.addtranslation", "Add Translation", "cms", "en");
    Translator.add("globaltype.tables.translations.name", "Name", "cms", "en");
    Translator.add("globaltype.tables.translations.placeholder.language", "Language Code - i.e en", "cms", "en");
    Translator.add("globaltype.tables.translations.placeholder.country", "Country Code - i.e GB", "cms", "en");
    Translator.add("globaltype.tables.translations.placeholder.description", "Description", "cms", "en");
    Translator.add("globaltype.tables.translations.placeholder.name", "Name", "cms", "en");
    Translator.add("globaltype.tables.translations.placeholder.file", "File", "cms", "en");
    Translator.add("globaltype.modal.title", "Create\/Edit GlobalType Translations", "cms", "en");
    Translator.add("globaltype.modal.download.translations.title", "Download Translations In The Selected Languages", "cms", "en");
    Translator.add("globaltype.modal.download.translations.description", "You will receive a zip file, containing csv files differ by language!", "cms", "en");
    Translator.add("globaltype.modal.download.categories.title", "Download The Global Type Categories", "cms", "en");
    Translator.add("globaltype.modal.download.categories.description", "You will receive a XML file, containing all the listed GlobalType categories with their translations and globaltypes!", "cms", "en");
    Translator.add("globaltype.modal.download.categories.start", "Start", "cms", "en");
    Translator.add("globaltype.modal.upload.translations.title", "Upload Translations in CSV-File Format", "cms", "en");
    Translator.add("globaltype.modal.upload.translations.description", "Upload a CSV file with GlobalType translations.", "cms", "en");
    Translator.add("globaltype.modal.upload.translations.sendrequest", "Upload Translation", "cms", "en");
    Translator.add("globaltype.modal.upload.categories.title", "Upload The Global Type Categories", "cms", "en");
    Translator.add("globaltype.modal.upload.categories.description", "Upload a XML file with GlobalType categories. All existing categories that are not within the uploaded file will be lost!!!", "cms", "en");
    Translator.add("globaltype.modal.upload.categories.start", "Upload", "cms", "en");
    Translator.add("globaltype.modal.upload.categories.uploadbarlabel", "Upload File:", "cms", "en");
    Translator.add("globaltype.messages.general.success.title", "Success", "cms", "en");
    Translator.add("globaltype.messages.general.warning.title", "Warning", "cms", "en");
    Translator.add("globaltypetranslations.filter.title", "Filter", "cms", "en");
    Translator.add("globaltypetranslations.filter.globaltype", "GlobalType", "cms", "en");
    Translator.add("globaltypetranslations.filter.subtype", "SubType", "cms", "en");
    Translator.add("globaltypetranslations.filter.additionaltype", "AdditionalType", "cms", "en");
    Translator.add("globaltypetranslations.sweetalert.delete.title", "Delete Translation", "cms", "en");
    Translator.add("globaltypetranslations.sweetalert.delete.text", "Do You Really Want To Delete The Translation?", "cms", "en");
    Translator.add("globaltypetranslations.sweetalert.confirmButton", "YES", "cms", "en");
    Translator.add("globaltypetranslations.sweetalert.cancelButton", "NO", "cms", "en");
    Translator.add("globaltypetranslations.errormessage.resource_conflict", "There is already an existing translation for this GlobalType!", "cms", "en");
    Translator.add("globaltypetranslations.errormessage.noglobaltype", "GlobalType is mandatory!", "cms", "en");
    Translator.add("globaltypetranslations.errormessage.notranslation", "At least one Translation must be set!", "cms", "en");
    Translator.add("globaltypetranslations.errormessage.twolettererror", "Country Code and Language Code have to be 2 letters long!", "cms", "en");
    Translator.add("globaltypetranslations.errormessage.mandatorylanguage", "You have to insert a Language Code", "cms", "en");
    Translator.add("globaltypetranslations.errormessage.mandatorycountry", "You have to insert a Country Code", "cms", "en");
    Translator.add("globaltypetranslations.messages.upload.success", "Your file was uploaded regurarly. Il will be imported as soon as possible.", "cms", "en");
    Translator.add("globaltypetranslations.messages.upload.insuccess", "It seems that your file wasn't uploaded regurarly. Check if it will be imported correctly.", "cms", "en");
    Translator.add("globaltypecategories.messages.error.title", "Error", "cms", "en");
    Translator.add("globaltypecategories.messages.error.requiresgt.text", "All categories require at least one GlobalType", "cms", "en");
    Translator.add("globaltypecategories.messages.success.title", "Success", "cms", "en");
    Translator.add("globaltypecategories.messages.success.saved.text", "All categories were saved successfully!", "cms", "en");
    Translator.add("globaltypecategories.categoriytab.title", "Categories", "cms", "en");
    Translator.add("globaltypecategories.categoriytab.name", "Name (en_GB)", "cms", "en");
    Translator.add("globaltypecategories.categoriytab.translations", "Translation", "cms", "en");
    Translator.add("globaltypecategories.categoriytab.globaltype", "GlobalType", "cms", "en");
    Translator.add("globaltypecategories.categoriytab.buttons.addglobaltype", "Add GlobalType", "cms", "en");
    Translator.add("globaltypecategories.categoriytab.buttons.addtranslation", "Add Translation", "cms", "en");
    Translator.add("globaltypecategories.categoriytab.buttons.addcategory", "Add Category", "cms", "en");
    Translator.add("usermanagement.navigation", "User Management", "cms", "en");
    Translator.add("usermanagement.table.id", "ID", "cms", "en");
    Translator.add("usermanagement.table.title", "Userlist", "cms", "en");
    Translator.add("usermanagement.table.username", "Username", "cms", "en");
    Translator.add("usermanagement.table.email", "Email", "cms", "en");
    Translator.add("usermanagement.table.created", "Created", "cms", "en");
    Translator.add("usermanagement.table.roles", "Roles", "cms", "en");
    Translator.add("usermanagement.table.action", "Action", "cms", "en");
    Translator.add("usermanagement.edituser.id", "ID", "cms", "en");
    Translator.add("usermanagement.edituser.name", "Username", "cms", "en");
    Translator.add("usermanagement.edituser.email", "Email", "cms", "en");
    Translator.add("usermanagement.edituser.created", "Created", "cms", "en");
    Translator.add("usermanagement.edituser.roles", "Roles", "cms", "en");
})(Translator);
