$(function () {
    var context = pwNamespace('CMS.GLOBALTYPECATEGORIES');
    context.GlobalTypeList = (function () {
        var instance = null;

        var injection = function() {
            return {}
        };

        var initInstance = function() {
            var me = {
                config: {
                    loader: Loader.getInstance()
                }
                ,categoriesTabId: "#globaltypecategories-globaltypes-tab"
                ,globalTypeListItemClass: ".js-globaltypecategories-globaltypes"
                ,addGlobalTypeButtonClass: ".js-globaltypecategories-globaltypes-add-button"
                ,deleteGlobalTypeButtonClass: '.js-globaltypes__typedeletebutton'
                ,modalDownloadCategoriesButtonClass: '.js-downloadcategories'
                ,sortIndex : 1
                ,gtCounter : 0
                ,currentId: null
                ,currentGTS: {}
                ,unsavedInputIds: {}
                ,loader: Loader.getInstance()
                ,init: function() {
                    me.addEventListeners();
                }

                ,addEventListeners: function() {
                    var body = $('body');
                    body.on('click', me.addGlobalTypeButtonClass, me.addGlobalType);
                    body.on('click', me.deleteGlobalTypeButtonClass, me.removeGlobalType);
                    body.on('change', '.globaltypes-textbox', me.updateJson);
                    body.on('change', '#globaltypecategories-globaltypes', me.updateSortOrder);
                    body.on('click', me.modalDownloadCategoriesButtonClass, me.requestDownloadCategories);
                }

                ,requestDownloadCategories: function(e) {
                    var button = $(e.currentTarget)
                        ,modalContainer = button.parents('.modal');

                    $.ajax({
                        url: Routing.generate('cms_globaltype_requestcategoriesdownload'),
                        beforeSend: function(e, xhr, options) {
                            e.loaderKey = "submitGlobaltypeCategoriesRequestDownload";
                            me.config.loader.startLoader(e.loaderKey, $('.js-download-globaltype-categories'));
                        }
                    })
                    .done(function(data){
                        var url = Routing.generate('cms_globaltype_do_categories_download');
                        window.location = url;
                        modalContainer.modal('hide');
                    });
                }

                ,updateSortOrder: function(e) {
                    var div = $(e.currentTarget)
                        ,list = div.find('.dd-list')
                        ,listItems = list.find('li')
                        ,newOrder = [];
                    $.each(listItems, function(k,v) {
                        var li = $(v);
                        var key = k+1;
                        var allTextBoxes = li.find('input.globaltypes-textbox');
                        var gtTextBox = li.find('input.globaltypes-textbox[data-type="globaltype"]');
                        var stTextBox = li.find('input.globaltypes-textbox[data-type="subtype"]');
                        var atTextBox = li.find('input.globaltypes-textbox[data-type="additionaltype"]');
                        var gt =  gtTextBox.val();
                        var st =  stTextBox.val();
                        var at =  atTextBox.val();
                        var newObject = {};
                        if (gt) {
                            newObject.GT = gt;
                        }
                        if (st) {
                            newObject.ST = st;
                        }
                        if (at) {
                            newObject.AT = at;
                        }
                        if (Object.keys(newObject).length > 0) {
                            newOrder.push(newObject);
                        }
                        $(v).attr('data-id', key);
                        $.each(allTextBoxes, function(k1,v1) {
                            $(v1).attr('data-id', key);
                        });
                    });
                    me.currentGTS = newOrder;
                    context.CategoriesList.getInstance().updateGlobalTypes(newOrder);
                }

                ,updateJson: function(e) {
                    if (me.currentId === null) {
                        return;
                    }
                    var input = $(this)
                        ,gtId = input.data('id')
                        ,type = input.data('type')
                        ,gtTypes = {
                            'globaltype':'GT'
                            ,'subtype':'ST'
                            ,'additionaltype':'AT'
                        }
                        ,gtArray = []
                        ,tmp = {'GT':'','ST':'','AT':''};
                    if (!me.currentGTS[gtId] ) {
                        me.currentGTS[gtId] = {};
                    }
                    me.currentGTS[gtId][gtTypes[type]] = input.val();
                    tmp.GT = (me.currentGTS[gtId]['GT'])?me.currentGTS[gtId]['GT']:'';
                    tmp.ST = (me.currentGTS[gtId]['ST'])?me.currentGTS[gtId]['ST']:'';
                    tmp.AT = (me.currentGTS[gtId]['AT'])?me.currentGTS[gtId]['AT']:'';
                    me.currentGTS[gtId] = tmp;
                    $.each(me.currentGTS, function(k,v) {
                        if (v.hasOwnProperty('GT') && v.GT !== "") {
                            gtArray.push(v);
                        }
                    });

                    me.currentGTS = gtArray;
                }

                ,removeGlobalType: function(e) {
                    var button = $(this);
                    var li = button.parents('li.globaltypecategories-globaltypes');
                    var id = li.attr('data-id');
                    var parent = li.parents('#globaltypecategories-globaltypes');
                    li.remove();
                    me.currentGTS.splice(id-1,1);
                    var list = parent.find('.dd-list');
                    me.recreateIds(list);
                    context.CategoriesList.getInstance().updateGlobalTypes(me.currentGTS);
                }

                ,recreateIds: function(list) {
                    var listItems = list.find('li');
                    $.each(listItems, function(k,v) {
                        var listItem = $(v);
                        var allTextBoxes = listItem.find('input.globaltypes-textbox');
                        var key = k+1;
                        $(v).attr('data-id',key);
                        $.each(allTextBoxes, function(k1,v1) {
                            $(v1).attr('data-id', key);
                        });
                    });
                }
                ,addGlobalType: function(el) {
                    if (!el.hasOwnProperty('globaltype')) {
                        me.currentGTS.push({'GT':'', 'ST':'', 'AT':''});
                        me.currentGTS.push({});
                        el.preventDefault();
                    }
                    var elm = el || {}
                        ,div = $('#globaltypecategories-globaltypes')
                        ,list = div.find('.dd-list');
                    me.gtCounter = list.children().length;
                    me.sortIndex = list.children().length +1;
                    var gtContent = me.generateGtTableContent('globaltype', me.gtCounter, me.gtCounter, elm)
                        ,stContent = me.generateGtTableContent('subtype', me.gtCounter, me.gtCounter, elm)
                        ,atContent = me.generateGtTableContent('additionaltype', me.gtCounter, me.gtCounter, elm)
                        ,rowGlobalTypes = '<div class="flexform__row">' +
                            '<div class="flexform__shrink">' +
                            '<a class="btn btn-xs btn-default globaltypes-handle-btn" type="button"><span class="fa fa-reorder"></span></a>' +
                            '</div>' +
                            '<div class="flexform__grow flexform__grow--even">' +
                            '<div class="row">' +
                            '<div class="col-md-4">' + gtContent + '</div>' +
                            '<div class="col-md-4">' + stContent + '</div>' +
                            '<div class="col-md-4">' + atContent + '</div>' +
                            '</div></div></div>';

                    var li = $( "<li>", {
                        'class': "dd-item globaltypecategories-globaltypes js-globaltypecategories-globaltypes"
                        ,'data-id': me.sortIndex
                        ,'data-globaltype': ''
                    }).append(
                        $(rowGlobalTypes)
                    ).appendTo(list);

                    //me.recalculateGtItemEvenOdd(me.gtCounter);
                    me.attachSelectControlsForGlobalTypes(li.find('.globaltypes-textbox'));
                    div.nestable({
                        group:1
                        ,maxDepth:1
                        ,handleClass: 'globaltypes-handle-btn'
                    });
                }

                ,recalculateGtItemEvenOdd : function(id) {
                    var types = ['globaltype', 'subtype', 'additionaltype'];
                    types.forEach(function(type){
                        var even = false;
                        $('.featurematching__typecontainer--' + type + '.featurematching__typecontainer--' + id + '[data-active="true"]').each(function() {
                            var $item = $(this);
                            if (even) {
                                $item.addClass('featurematching__typecontainer--even');
                            } else {
                                $item.removeClass('featurematching__typecontainer--even');
                            }
                            even = !even;
                        });
                    });
                }

                ,attachSelectControlsForGlobalTypes : function($elements) {
                    $elements = $elements || $( list + ' li .cms-fm-datatable-textbox');
                    $elements.each(function(){
                        var $element = $(this);
                        var id = $element.data('id'),
                            index = $element.data('index'),
                            type = $element.data('type'),
                            parent = '',
                            searchFor = '',
                            search_mapping =  {
                                'globaltype': 'globaltype',
                                'subtype': 'globaltype',
                                'additionaltype': 'subtype' },
                            type_mapping = {
                                'globaltype': 'globalType',
                                'subtype': 'subType',
                                'additionaltype': 'additionalType' };

                        switch(type) {
                            case 'globaltype':
                                parent = '';
                                break;
                            case 'subtype':
                                parent = 'globaltype';
                                break;
                            case 'additionaltype':
                                parent = 'subtype';
                                break;
                        }
                        $(this).select2({
                            minimumInputLength: 2,
                            placeholder: ' ',
                            dropdownCssClass : 'bigdrop',
                            allowClear: true,
                            quietMillis: 250,
                            ajax: {
                                url: function() {

                                    if (type!=='globaltype') {
                                        searchFor = $('#' + id + '-' + search_mapping[type] + '-' + index).val();
                                        // searchFor = $('#' + id + '-' + fieldCount + '-' + search_mapping[type]).val();
                                    }
                                    if (type==='additionaltype' && searchFor ==='') {
                                        searchFor = $('#' + id + '-' + search_mapping['globaltype'] + '-' + index).val();
                                        // searchFor = $('#' + id + '-' + fieldCount + '-' + search_mapping['globaltype']).val();
                                    }
                                    var uri = Routing.generate('cms_featurematching_grouptypelist', {
                                        'type': type_mapping[type],
                                        'gt': searchFor,
                                        'count': 20
                                    });
                                    return uri;
                                },

                                dataType: 'json',
                                data: function (term, page) {
                                    return {
                                        query: term,
                                        page: page
                                    };
                                },
                                results: function (data, page) {
                                    var more = (page * 20) < data.total_count;
                                    return {
                                        results: data.items,
                                        more: more
                                    };
                                }
                            },
                            escapeMarkup: function (m) { return m; },
                            initSelection: function(element, callback) {
                                callback({
                                    'id': $(element).data('org'),
                                    'label': $(element).data('text')
                                });
                            },
                            formatSelection: function(element) {
                                var item = '',
                                    caption = '',
                                    label = '';
                                if (typeof element.id  !== 'undefined') {
                                    item = item + element.id;
                                    caption = $( "<div/>", {
                                        'class': 'select2_option_item_text'
                                    }).append(element.id)[0].outerHTML;
                                    item = caption;
                                }
                                if (typeof element.label  !== 'undefined' && element.label.length > 0) {
                                    label = $( "<div/>", {
                                        'class': 'select2_option_item_label',
                                        'title': element.label
                                    }).append(element.label)[0].outerHTML;
                                    item = item + ' ' + label;
                                }
                                return item;
                            },
                            formatResult: function(result) {

                                var color = 'iherit';
                                if (result.alternative==="true") {
                                    color = '#005DCC';
                                }

                                var item = $(
                                    '<span style="font-weight:bold;color:'+ color +';">' + result.id + '</span>'  +
                                    '<div>' + result.label + '</div>'
                                );

                                return item;
                            }
                        }).on("change", function(e) {
                            var $input,
                                $el = $(this),
                                index = $el.data('index'),
                                id = $el.data('id');

                            if (e.val) {
                                // $('#' + id + '-'+type).parent().addClass('recordEdited');
                                if( parent==='') {
                                    $('#' + id + '-subtype-'+index).prop('disabled',false).select2('val', null);
                                    // .parent().removeClass('cellDisabled');

                                    $('#' + id + '-additionaltype-'+index).prop('disabled',false).select2('val', null);
                                    // .parent().removeClass('cellDisabled');
                                }
                                me.addIdToUnsavedChanges(e.currentTarget.getAttribute('id'));
                            } else {
                                if ($el.data('type') === 'globaltype') {
                                    var orgValue = $el.data('org') || false;
                                    if (!orgValue) {
                                        me.removeIdFromUnsavedChanges(id + '-globaltype-'+index);
                                    } else {
                                        me.addIdToUnsavedChanges(e.currentTarget.getAttribute('id'));
                                    }

                                    $('#' + id + '-subtype-'+index)
                                        .prop('disabled',true)
                                        .select2('val', null);
                                    me.removeIdFromUnsavedChanges(id + '-subtype-'+index);

                                    $('#' + id + '-additionaltype-'+index)
                                        .prop('disabled',true)
                                        .select2('val', null);
                                    me.removeIdFromUnsavedChanges(id + '-additionaltype-'+index);

                                    // .parent().removeClass('cellDisabled');
                                }
                            }
                            me.checkUnsavedChangesCount();

                        });
                    });
                }

                ,generateGtTableContent : function(type, rowId, index, el, additionalGt) {
                    var gtValue = el.globaltype || '';
                    additionalGt = additionalGt || false;
                    var additionalInputClass = '';
                    if (additionalGt) {
                        additionalInputClass = ' globaltypes-textbox--added';
                    }
                    var label = "GT";
                    if (type === 'subtype') {
                        label = "ST"
                    }
                    if (type === 'additionaltype') {
                        label = "AT"
                    }
                    var $action;
                    if (type === 'globaltype') {
                        $action =  $('<button/>', {
                            'text': label,
                            'type': 'button',
                            'class': 'btn btn-xs btn-info fa featurematching__typedeletebutton js-globaltypes__typedeletebutton',
                            'data-index': index,
                            'data-id': rowId
                        });
                    } else {
                        $action = $('<span/>', {
                            'text': label,
                            'class': 'featurematching__typeinfo',
                            'data-index': index
                        });
                    }
                    var displayInput = $('<div/>', {
                        'class': 'featurematching__typecontainer featurematching__typecontainer--active featurematching__typecontainer--' + type + ' featurematching__typecontainer--' + rowId
                        ,'data-index': index
                        ,'data-id': rowId
                        ,'data-active': true
                        ,'data-reset': !additionalGt
                    }).append(
                        $('<label/>', {
                            'class': 'featurematching__typelabel',
                            'for': rowId + '-'+ type + '-' + index,
                        }).append(
                            $action
                        ).append(
                            $( "<input/>", {
                                'id':rowId + '-'+ type + '-' + index,
                                'value':((el[type])?el[type]:''),
                                'class':rowId + '-type gtype globaltypes-textbox' + additionalInputClass,
                                'data-org':((el[type])?el[type]:''),
                                'data-text':((el[type + 'label'])?el[type + 'label']:''),
                                'data-index': index,
                                'data-type': type,
                                'data-id': rowId,
                                'disabled': (type !== 'globaltype' && gtValue === '') ? true : false
                            })
                        )
                    );
                    if (additionalGt) {
                        return displayInput;
                    }
                    return displayInput[0].outerHTML;
                }

                ,getUnsavedChangesCount : function() {
                    return Object.keys(me.unsavedInputIds).length;
                }

                ,clearUnsavedChanges : function () {
                    me.unsavedInputIds = {};
                    me.checkUnsavedChangesCount();
                }

                ,checkUnsavedChangesCount : function() {
                    var unsavedCount = Object.keys(me.unsavedInputIds).length;
                    if (0 === unsavedCount) {
                        $('#fm_saveall').addClass('h-hidden');
                        $('#fm_cancelall').addClass('h-hidden');
                    } else {
                        $('#fm_saveall').removeClass('h-hidden');
                        $('#fm_cancelall').removeClass('h-hidden');
                    }
                }

                ,addIdToUnsavedChanges : function(id) {
                    if (!me.unsavedInputIds.hasOwnProperty(id)) {
                        me.unsavedInputIds[id] = 0;
                    }
                    me.unsavedInputIds[id]++;
                }

                ,removeIdFromUnsavedChanges : function(id) {
                    if (me.unsavedInputIds.hasOwnProperty(id)) {
                        delete me.unsavedInputIds[id];
                    }
                }

                ,getIdsFromUnsavedChanges : function() {
                    return Object.keys(me.unsavedInputIds);
                }

                ,loadGlobalTypes: function(id, gts) {
                    me.currentId = id;
                    me.currentGTS = $.extend(true, [], gts);

                    $.ajax({
                        url: Routing.generate('cms_globaltype_generateglobaltypelist')
                        ,method: 'POST'
                        ,data: { 'globalTypes':gts }
                        ,beforeSend: function(e, xhr, options) {
                            e.loaderKey = me.loader.getGeneratedKeyByPrefix("generateGlobalTypes");
                            me.loader.startLoader(e.loaderKey, $('#globaltypecategories-globaltypes-tab'));
                        }
                    })
                    .done(function(data) {
                        if (data.gtView) {
                            $('#globaltypecategories-globaltypes').html(data.gtView);
                            var div = $('#globaltypecategories-globaltypes')
                                ,list = div.find('.dd-list');

                            $.each(list.children(), function(k,v){
                                me.recalculateGtItemEvenOdd($(v).data('id'));
                                $(v).find('.js-featurematching-previewfield').on('click', function(){
                                    var removableContainer = $(this).parents('.js-globaltypecategories-globaltypes').find('.js-featurematching-previewfield').parent()
                                        ,parent = removableContainer.parent();
                                    removableContainer.remove();
                                    me.attachSelectControlsForGlobalTypes($(v).find('.globaltypes-textbox'));
                                });
                            });
                            div.nestable({
                                group:1
                                ,maxDepth:1
                                ,handleClass: 'globaltypes-handle-btn'
                            });
                        }
                    });
                }

                ,resetAddedGlobalTypes: function() {
                    me.currentGTS = {};
                    me.sortIndex = 1;
                    me.gtCounter = 0;
                }

                ,resetGlobalTypesList: function() {
                    $('#globaltypecategories-globaltypes').find('.dd-list').empty();
                    me.resetAddedGlobalTypes();
                }

            };
            /* public */
            var baseObj = {
                'loadGlobalTypes' : me.loadGlobalTypes
                ,'resetGlobalTypesList': me.resetGlobalTypesList
            };

            /* attach public functions to singleton */
            $.each(injection.call(me, me), function(idx, prop) {
                baseObj[idx] = prop;
            });

            /* execute init method */
            me.init();

            return baseObj;
        };

        return {
            inject: function(inject) {
                injection = inject;
            }
            ,getInstance: function() {
                if (instance === null) {
                    instance = initInstance();
                }
                return instance;
            }
        }
    })();
});

$(function() {
    if ($('.js-cmsglobaltypecategories').length > 0) {
        CMS.GLOBALTYPECATEGORIES.GlobalTypeList.getInstance();
    }
});
