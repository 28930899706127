/**
 * This, let's just call it "Class" is responsible for almost everything related to the
 * edit/view image block
 * Shouldn't be used (initialized) without the respective Element(s) since references could be NuPEs and result into javascript errors
 * requires: jQuery (http://jquery.com/), cropper (http://fengyuanchen.github.io/cropper/)
 *
 *
 * What's where to find/to put? (strg/cmd+F + #stub)
 * private scope:                       #prvscp
 *   callbacks and handlers:            #cllbcks
 *
 * public scope:                        #pblcscp
 *
 * static scope:                        #stcscp
 */
var GalleryEdit = (function(){

    /**
     * gallery edit constructor
     *
     * @param {Object} config               the configuration container / see initial variable definitions below (optional)
     * @constructor
     */
    var GalleryEditController = function(extconfig){

        var config = extconfig || {};

        var previewEditImage;

        var imageProxyUrl = config.imageproxyurl || "http://vm127-mesh.peakwork.lan/";

        var GalleryElementContainer;
        var ImageEditModal;
        var EditImage;

        var cropperoptions = config.cropperconfig || {
            background: false,
            checkImageOrigin: false,
            guides:false,
            scaleable: false,
            zoomable: false
        };

        // private scope ::>                                            (#prvscp)

        /**
         * loads the Gallery Editblock base from the applications controller
         *
         * @returns {void}
         */
        var fetchGalleryDataDom = function(){

            var url = Routing.generate('cms_contentstore_gallery_edit');

            var key = $('.basicdata-detailchart .edf-field span[data-name="key"]').text();
            var isgroup = ('' === key)?true:false;
            var groupid = $('#EditCompareImages').data('groupid');

            var data = {
                group: isgroup,
                key: key,
                groupid: groupid
            };

            var ajaxCall = {
                url: url,
                data: data,
                success: fetchGalleryDataSuccessHandler
            };

            $.ajax(ajaxCall);
        };


        /**
         * binds (mostly) user interaction events to the asyncronous loaded Content
         *
         * @returns {void}
         */
        var bindGalleryEvents =  function(){

            GalleryElementContainer.find('#CloseGallerydit').on('click', onGalleryEditCloseButtonClickHandler);
            GalleryElementContainer.find('.edit-images-thumbnail-list').sortable();
            GalleryElementContainer.find('li.thumbcontainer').on('click',thumbnailOnClickHandler);
            GalleryElementContainer.find('li.thumbcontainer img').on('error',thumbnailOnErrorHandler);
            GalleryElementContainer.find('.edit-images-toselection button').on('click', onToSelectionClickHandler);
            GalleryElementContainer.find('#edit-images-cropper').on('click',onEditImageClickHandler);
            GalleryElementContainer.find('#edit-images-master').on('click',onSetFlagClickHandler);
            GalleryElementContainer.find('#edit-images-cover').on('click',onSetFlagClickHandler);

            GalleryElementContainer.find('.edit-images-preview img').on('error',onPreviewErrorHandler);

            // draggable & sorting
            GalleryElementContainer.find('ul.ui-sortable').on('sortchange',onSortableListChangeHandler);
            $("#edit-images-updatesorting").on('click', onSortingSubmitHandler);


            // modal events:
            ImageEditModal.on('shown.bs.modal', onShowEditImageModalHandler );
            ImageEditModal.on('hidden.bs.modal', onHideEditImageModalHandler);

            $('#GalleryEditImageScale').on('click', onModalScaleClickHandler);
            $('#GalleryEditImageUpdate').on('click', onModalUpdateCropClickHandler);
            $('#GalleryEditImageAspect').on('click', onModalToggleAspectClickHandler);
            $('#GalleryEditImageReset').on('click', onModalResetClickHandler );
            $('#GalleryEditImageCancel').on('click', onModalCancelCloseClickHandler);
            $('#GalleryEditImageSave').on('click', onModalImageEditSaveClickHandler);
        };

        /**
         * removes Modal Events out of two reasons
         * a.) prevent pseudo overhead and possible namespace conflicts
         * b.) prevent double binding (and eventually double triggering) of handlers (since modal is no part of the Gallery DOM "chunk" and won't be removed as such)
         *
         * @returns {Void}
         */
        var unbindModalEvents = function(){
            ImageEditModal.off('shown.bs.modal', onShowEditImageModalHandler );
            ImageEditModal.off('hidden.bs.modal', onHideEditImageModalHandler);

            $('#GalleryEditImageScale').off('click',onModalScaleClickHandler);
            $('#GalleryEditImageUpdate').off('click', onModalUpdateCropClickHandler);
            $('#GalleryEditImageAspect').off('click', onModalToggleAspectClickHandler);
            $('#GalleryEditImageReset').off('click', onModalResetClickHandler );
            $('#GalleryEditImageCancel').off('click', onModalCancelCloseClickHandler);
            $('#GalleryEditImageSave').off('click', onModalImageEditSaveClickHandler);
        };

        /**
         * sets button to checked, green state / primary, outline state respectively
         *
         * @param {Object} buttonref               the jquery dom button reference
         * @param {Boolean} active                 switch for active / inactive states
         * @returns {Void}
         */
        var updateEditCheckButtonClasses = function(buttonref, active){
            if(active){
                buttonref.removeClass('btn-primary btn-outline').addClass('btn-success');
                buttonref.find('span.fa').removeClass('fa-square-o').addClass('fa-check-square-o');
            }else{
                buttonref.removeClass('btn-success').addClass('btn-primary btn-outline');
                buttonref.find('span.fa').removeClass('fa-check-square-o').addClass('fa-square-o');
            }
        };


        // callbacks and handlers                                       (#cllbcks)

        /**
         * fetchGalleryDataDom ajax call success handler
         *
         * @param {String} data                 ajax response;
         */
        var fetchGalleryDataSuccessHandler = function(data){
            GalleryElementContainer = $(data);
            //$('#TextCompareAndMasterForm').before(GalleryElementContainer);
            $('.js-contentstore-mediapanel').after(GalleryElementContainer);

            var active = $("<div/>",{
                class: "edit-images-thumbnails-active",
                id: "ActiveThumbnailOverlay"
            });

            previewEditImage = GalleryElementContainer.find('.edit-images-preview img');

            GalleryElementContainer.find('.edit-images-thumbnail-list:first li:first').prepend(active);
            bindGalleryEvents();

        };

        /**
         * close button handler / triggers parent openclose toggle
         *
         * @param {Event} e                     the onclick event
         * @returns {void}
         */
        var onGalleryEditCloseButtonClickHandler = function(e){
            $('#EditCompareImages').click();
        };

        var onPreviewErrorHandler = function(e){
            var img = $(this);
                parent = img.parent();
            img.hide();
            parent.append(
                $('<div/>', {'style':'text-align:center;'})
                    .append(
                        $('<i/>', {class:'fa fa-frown-o'}))
                            .append(
                                $('<span/>', { 'text': ' image not found'})
                            )
                    );
        }

        /**
         * sets preview/active image and swaps coverimage button if needed
         *
         * @param {Event} e                     the onclick event
         * @returns {void}
         */
        var thumbnailOnClickHandler = function(e){

            var ato = $("#ActiveThumbnailOverlay");
            if(ato == $(this)) {
                return;
            }

            ato.detach();
            $(this).prepend(ato);

            //var thumb = $(this).find('img');
            var thumb = $(this).find('.thumbs');
            //var thumbImageSource = thumb.attr('src');
            var coverImageButton = $('#edit-images-cover');

            if("1" === thumb.attr('data-cover')){
                updateEditCheckButtonClasses(coverImageButton, true);
            }else{
                updateEditCheckButtonClasses(coverImageButton, false);
            }

            var imageSource = thumb.attr('data-edited');
            var isDuplicate = thumb.attr('data-isduplicateof');

            var previewContainer = GalleryElementContainer.find('.edit-images-preview');
                previewContainer.empty();

            if (isDuplicate) {
                previewEditImage = $('<div/>', {class:"inbox", style:"position:relative;"})
                .append(
                    $('<div/>', {class:"infont overlay", style:"position:absolute;top:0px;left:0px;"}).append(
                        $('<span/>', {class:"fa fa-files-o btn-danger2", style:"font-size:45px;"})
                    )
                ).append(
                    $('<img/>', {src:imageSource})
                );
            } else {
                previewEditImage = $('<img/>',{ src: imageSource});
            }

            previewContainer.append(previewEditImage);
        };


        var thumbnailOnErrorHandler = function(e) {

            var img = $(this),
                parent = img.parent();
            img.hide();
            parent.append(
                    $('<div/>', {'style':'text-align:center;'})
                        .append(
                            $('<i/>', {class:'fa fa-frown-o'}))
                                .append(
                                    $('<span/>', { 'style':'font-size:10px;', 'text': ' image not found'})
                                )
                        );
            parent.off('click');
            parent.removeClass('thumbcontainer');
            parent.addClass('nothumbcontainer infont');
        }

        /**
         * button triggered touroperator/supplier selection within the Gallery
         *
         * @param {Event} e             the onclick event
         * @returns {void}
         */
        var onToSelectionClickHandler = function(e){

            GalleryElementContainer.find('.edit-images-toselection button').addClass('btn-outline').removeClass('active');

            var masterbutton = $("#edit-images-master");

            if(masterbutton.hasClass('btn-success')){
                updateEditCheckButtonClasses(masterbutton, false);
            }

            if("1" === $(this).attr('data-master')){
                updateEditCheckButtonClasses(masterbutton, true);
            }

            $(this).removeClass('btn-outline').addClass('active');
            $('.edit-images-thumbnails ul').removeClass('active').hide();
            var stoimages = $('#'+$(this).data('target')).addClass('active').show();
            stoimages.find("li:first").click();

        };

        /**
         * triggers and initializes image edit functionalities
         *
         * @param {Event} e                        click event
         * @return {void}
         */
        var onEditImageClickHandler = function(e){

            EditImage = $('<img/>',{"src":"", "alt":""});
            $('#cropper-container').append(EditImage);
            EditImage.attr('src', previewEditImage.attr('src'));
            EditImage.on('crop.cropper',onCropperEventHandler);

        };

        /**
         * handles various cropper events/and respective updates
         *
         * @param {Event} e                     the cropper event
         * @returns {Void}
         */
        var onCropperEventHandler = function(e){

            $('#GalleryEditCroppedImageWidth').val(e.width);
            $('#GalleryEditCroppedImageHeight').val(e.height);
        };

        /**
         * initializes the cropper functionalities on the image within the modal
         *
         * @returns {Void}
         */
        var onShowEditImageModalHandler = function () {
            EditImage.cropper();
            //var canvasData = EditImage.cropper('getCanvasData');
            var imageData = EditImage.cropper('getImageData');

            $('#GalleryEditScaledImageWidth').val(imageData.naturalWidth);
            $('#GalleryEditScaledImageHeight').val(imageData.naturalHeight);

        };

        /**
         * removes cropper functionalities / resets elements
         *
         * @returns {Void}
         */
        var onHideEditImageModalHandler = function () {
            //cropBoxData = $image.cropper('getCropBoxData');
            //canvasData = $image.cropper('getCanvasData');

            EditImage.cropper('destroy');
            $('#cropper-container').empty();

        };

        /**
         * triggers and executes scaling .. while preserving already present editing data [!pls rev!]
         *
         * @param {Event} e                     onlick event
         * @return {Void}
         */
        var onModalScaleClickHandler = function(e){

            var editchunk, npath;

            var imageData = EditImage.cropper('getImageData');
            var targetWidth = $('#GalleryEditScaledImageWidth').val();
            var targetHeight = $('#GalleryEditScaledImageHeight').val();

            var cpath = EditImage.attr('src');
            var ta = cpath.split('http://');

            // checking changes / setup respective server command (chunk)

            if(imageData.naturalWidth != targetWidth){
                editchunk = targetWidth+"x";
            }else if(imageData.naturalHeight != targetHeight){
                editchunk = "x"+targetHeight;
            }else{
                return;
            }

            // check for existing imageserver information and enhance if true (confusing due to possible scaling and cropping combination)

            if(ta.length > 2){
                var tc = ta[1].split('/');
                    // tc = [imageserveruri], [code (unsafe)]  [cropinfo - OPTIONAL] [scaleinfo]  [trailing slash]
                if(tc.length == 4 && tc[2].indexOf(":") === -1){
                    tc[2] = editchunk;
                }else if(tc.length == 5){
                    tc[3] = editchunk;
                }else{
                    tc[3] = editchunk;
                    tc.push(""); // adding trailing slash
                }
                npath = "http://" + tc.join("/")+ "http://" +ta[2];
            }else{
                npath = imageProxyUrl+"unsafe/"+editchunk+"/http://"+ta[1];
            }

            // send update to backend and update DOM
            $("#ActiveThumbnailOverlay").parent().find('img').attr('data-edited',npath);
            $('.edit-images-preview img').attr('src',npath);

            ImageEditModal.modal('hide');

        };

        /**
         *  sets crop rect to set form coordinates
         *
         * @param {Event} e                     the onclick event
         * @returns {Void}
         */
        var onModalUpdateCropClickHandler = function(e){

            var w = parseFloat($('#GalleryEditCroppedImageWidth').val());
            var h = parseFloat($('#GalleryEditCroppedImageHeight').val());

            var aspectButton = $('#GalleryEditImageAspect');
            if("true" === aspectButton.attr('data-aspect')){
                var cbd = EditImage.cropper('getCropBoxData');

                var aspect = cbd.width/cbd.height;

                if(cbd.width !== w){
                    h = w/aspect;
                }else if(cbd.height != h){
                    w = h*aspect;
                }

            }

            var cropBoxData = {
                left: 0,
                top: 0,
                width: w,
                height: h
            };

            EditImage.cropper('setCropBoxData',cropBoxData);
        };

        /**
         * sets aspect keeping for croprect scaling
         *
         * @param {Event} e                     onclick event
         * @returns {Void}
         */
        var onModalToggleAspectClickHandler = function(e){
            var btn = $(this);
            if("false" === btn.attr('data-aspect')){
                btn.attr('data-aspect', 'true');
                btn.find('span').removeClass('fa-square-o').addClass('fa-check-square-o');

                var cbd = EditImage.cropper('getCropBoxData');
                EditImage.cropper('setAspectRatio', cbd.width/cbd.height);

            }else{
                btn.attr('data-aspect', 'false');
                btn.find('span').removeClass('fa-check-square-o').addClass('fa-square-o');
                EditImage.cropper('setAspectRatio', NaN);
            }
        };

        /**
         * reset cropper .. and restore the original image
         *
         * @param {Event} e                 click Event
         * @returns {Void}
         */
        var onModalResetClickHandler = function(e){
            EditImage.cropper('reset');
            var orignal = "http://" + $('#ActiveThumbnailOverlay').parent().find('img').attr('src').split('http://')[2];

            $("#ActiveThumbnailOverlay").parent().find('img').attr('data-edited',orignal);
            $('.edit-images-preview img').attr('src',orignal);

            ImageEditModal.modal('hide');

        };

        /**
         * closes the modal and stops image editing
         *
         * @param {Event} e                 click Event
         * @returns {Void}
         */
        var onModalCancelCloseClickHandler = function(e){

            ImageEditModal.modal('hide');
        };

        /**
         * saving/sending the editing informations
         *
         * @param {Event} e                 click Event
         * @returns {Void}
         */
        var onModalImageEditSaveClickHandler = function(e){

            // check current image source for editing information

            var cpath = EditImage.attr('src');
            var ta = cpath.split('http://');
            var tc;

            var actualScalingSPX = 0;
            var actualScalingSPY = 0;
            var actualScalingEPX = 0;
            var actualScalingEPY = 0;

            if(ta.length > 2){
                tc = ta[1].split('/');
                // tc = [imageserveruri], [code (unsafe)], [cropinfo - OPTIONAL], [scaleinfo], [trailing slash]

                if(tc[2].indexOf(":") > -1){
                    // cropinfo already set
                    var spc = tc[2].split(':');
                    var epc = spc[1];
                        spc = spc[0];

                    actualScalingSPX = parseInt(spc.split('x')[0]);
                    actualScalingSPY = parseInt(spc.split('x')[1]);
                    actualScalingEPX = parseInt(epc.split('x')[0]);
                    actualScalingEPY = parseInt(epc.split('x')[1]);

                }else if(tc.length == 4){
                    tc.splice(2,0,[]);
                }

            }


            // gather cropping instructions

            var canvasData = EditImage.cropper('getCanvasData');

            var cropBoxData = EditImage.cropper('getCropBoxData');

            var startPointX = Math.round(cropBoxData.left) - Math.round(canvasData.left) + actualScalingSPX;
            var startPointY = Math.round(cropBoxData.top) - Math.round(canvasData.top) + actualScalingSPY;

            var endPointX = startPointX + Math.round(cropBoxData.width) + actualScalingEPX;
            var endPointY = startPointY + Math.round(cropBoxData.height) + actualScalingEPY;

            var editedMixin = startPointX+"x"+startPointY+":"+endPointX+"x"+endPointY;

            var npath;

            if(2 < ta.length){
                tc[2] = editedMixin;
                npath = "http://" + tc.join("/")+"http://"+ta[2];
            }else{
                npath = imageProxyUrl+"unsafe/"+editedMixin+"/"+EditImage.attr('src')
            }



            // send update to backend and update DOM
            $("#ActiveThumbnailOverlay").parent().find('img').attr('data-edited',npath);
            $('.edit-images-preview img').attr('src',npath);

            ImageEditModal.modal('hide');

        };

        /**
         * manages flag updates for cover and master flags
         *
         * @param {Event} e                 clickevent
         * @return {Void}
         */
        var onSetFlagClickHandler = function(e){

            if($(this).hasClass('btn-success')){
                return;
            }
            updateEditCheckButtonClasses($(this), true);

            var type ,code;

            if("edit-images-cover" === $(this).attr('id')){


                //param = $('.edit-images-preview img').attr('src');

                var matchingImage = $('#ActiveThumbnailOverlay').parent().find('img');
                //var matchingImage = $('.edit-images-preview img');

                $("#GalleryEditElement ul.active li img[data-cover='1']").attr('data-cover','0');

                matchingImage.attr('data-cover','1');
                orgImageUri = decodeURIComponent(matchingImage.attr('src')).split('http://');

                type = "cover";
                param = encodeURIComponent("http://"+orgImageUri[orgImageUri.length-1]);
                code = $('.edit-images-thumbnails ul.active').attr('data-key');
            }else{


                $(".edit-images-toselection button").attr('data-master','0');
                $(".edit-images-toselection button.active").attr('data-master','1');

                type = "master";
                param = $('.edit-images-toselection button.active').text();
                code = $('.basicdata-panel .basicdata-detailchart span[data-name="groupid"]').text();
            }

            var url = Routing.generate('cms_contentstore_gallery_update');

            var data = {
                type: type,
                code: code,
                param: param
            };

            var ajaxCall = {
                url: url,
                data: data,
                success: onGalleryUpdateAjaxSuccessHandler
            };

            $.ajax(ajaxCall);

        };


        /**
         * actives (well, makes visible) "update-list" savebutton
         *
         * @param {Event} e                 sortchange Event
         * @param {Object} ui               jquery ui event info object
         *
         * @returns {Void}
         */
        var onSortableListChangeHandler = function(e, ui){
            $("#edit-images-updatesorting").show();
        };


        /**
         * communicates updated list to the backend
         *
         * @params {Event} e                onclick event
         *
         * @returns {Void}
         */
        var onSortingSubmitHandler = function(e){

            var paths = [];

            var list = $('#GalleryEditContainer ul.edit-images-thumbnail-list.active li img');
            for(var i = 0; i<list.length; i++){

                var t = $(list[i]).attr('src').split('http://')[2];

                paths.push('http://'+t);
            }

            var code = $('.edit-images-thumbnails ul.active').attr('data-key');
            var url = Routing.generate('cms_contentstore_gallery_update');

            var data = {
                type: "sort",
                code: code,
                param: paths
            };

            $("#edit-images-updatesorting").hide();

            var ajaxCall = {
                url: url,
                data: data,
                success: onGalleryUpdateAjaxSuccessHandler
            };

            $.ajax(ajaxCall);

        };


        /**
         * the prime ajax success handler for updating gallery informations
         *
         * @param {String} data                         ajax response
         *
         * @returns {Void}
         */
        var onGalleryUpdateAjaxSuccessHandler = function(data){

        };


        // public scope ::>                                             (#pblcscp)

        /**
         * initialising of basic gallery functions
         *
         * @returns {void}
         */
        this.init = function(){

            $.fn.cropper.setDefaults(cropperoptions);
            ImageEditModal = $('#image-edit-modal');

            fetchGalleryDataDom();
        };

        /**
         * removes rendered template chunks, events and variables
         *
         * @returns {Boolean}           returns true if succeeded / false if no DOM Element was found
         */
        this.removeClassAndDOMElements = function(){

            if(undefined === GalleryElementContainer){
                return false;
            }else{
                unbindModalEvents();
                GalleryElementContainer.remove();
                return true;
            }
        };

    };

    var instance;

    var _static = {

        // static scope ::>                                             (#stcscp)

        /**
         * self explanatory get singleton
         *
         * @returns {GalleryEditController}
         */
        getInstance: function(){
            if(undefined === instance){
                instance = new GalleryEditController();
            }
            return instance;
        },

        /**
         * some kind of destructor removes the traces of its existence
         *
         * @returns {undefined}           the now empty/undefined instance value
         */
        destroy: function(){
            if(undefined !== instance){
                instance.removeClassAndDOMElements();
                instance = undefined;
            }
            return instance;
        }
    };


    return _static;


})();