$(function () {
    var context = pwNamespace('CMS.CONTENTSTORE');
    context.AssignManualGoalID = (function () {

        var instance = null;

        var injection = function() {
            return {}
        };

        var initInstance = function() {
            var me = {
                config: {
                    loader: Loader.getInstance()
                    ,detailContainer: $('.cmscontentstore--productdetail')
                    ,loaderTargetContainer: $('.basicdata-panel')
                    ,editBtn: $('.assignGoalIDManually')
                    ,form: $('.js-cmsformassigngoalidmanually')
                    ,newGoalIdField: {}
                    ,submitBtn: $('.js-btnassigngoalidmanuallysubmit')
                    ,resetBtn: $('.js-btnassigngoalidmanuallyreset')
                    ,params: {}
                    ,currentgoalid: ''
                }

                ,init: function() {
                    me.config.newGoalIdField = me.config.detailContainer.find('input[name="newgoalid"]');
                    me.addEventListeners();
                }

                ,setMandatoryParameters: function(data) {
                    me.currentgoalid = data['currentgoalid'];
                    me.config.params['supplier'] = data['suppliercode'];
                    me.config.params['productcode'] = data['productcode'];
                }

                ,addEventListeners: function() {
                    me.config.detailContainer.on('click', '.assignGoalIDManually', me.editEnabled);
                    me.config.detailContainer.on('click', '.js-btnassigngoalidmanuallyreset', me.editDisabled);
                    me.config.detailContainer.on('click', '.js-btnassigngoalidmanuallysubmit', me.onSubmit);
                    me.config.detailContainer.on('keyup', 'input[name="newgoalid"]', me.onChangeFieldGoalId);
                }

                /**
                 * prepare the mandatory parameters for the ajax request
                 * show the form to assign a new GOALID
                 * disable the edit button
                 */
                ,editEnabled: function(e) {
                    me.setMandatoryParameters(e.currentTarget.dataset);
                    $('.js-cmsformassigngoalidmanually').removeClass('hidden');
                    $('.assignGoalIDManually').addClass('disabled');
                }

                /**
                 * reset the parameters for the ajax request
                 * hide the form used to assign a new GOALID
                 * enable the edit button
                 */
                ,editDisabled: function(e) {
                    e.preventDefault();
                    me.config.params = {};
                    $('.js-cmsformassigngoalidmanually').addClass('hidden');
                    $('.assignGoalIDManually').removeClass('disabled');
                    //me.config.newGoalIdField[0].value = '';
                    me.config.detailContainer.find('input[name="newgoalid"]').val(null);
                }

                /**
                 * check if goalId is not empty
                 * send the ajax request to update the GOALID
                 * show message success/failure
                 * redirect to the url search/toCode/productCode
                 */
                ,onSubmit: function(e) {
                    e.preventDefault();
                    var newGoalId;

                    if (me.config.detailContainer.find('input[name="newgoalid"]').length > 0) {
                        newGoalId = $.trim(me.config.detailContainer.find('input[name="newgoalid"]').val());
                    }

                    if ('' === newGoalId) {
                        swal({
                                title:  Translator.trans('', {}, 'cms'),
                                text:  Translator.trans('contentstore.general.feedback.removegoalidmatching.confirmtext', {
                                    goalid: me.currentgoalid,
                                    supplier: me.config.params.supplier,
                                    productcode: me.config.params.productcode
                                }, 'cms'),
                                type: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: Translator.trans('Yes', {}, 'cms'),
                                cancelButtonText: Translator.trans('No', {}, 'cms'),
                                closeOnConfirm: true
                            },
                            function(isConfirm) {
                                if (isConfirm !== true) {
                                    return false;
                                }
                                me.removeGoalIdMatching();
                            });
                        return false;
                    }
                    swal({
                            title:  Translator.trans('goal.tocodetable.movetoothergoal.swal.title', {}, 'cms'),
                            text:  Translator.trans('goal.tocodetable.movetoothergoal.swal.text', {
                                id:newGoalId,
                                supplier: me.config.params.supplier,
                                productcode: me.config.params.productcode
                            }, 'cms'),
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: Translator.trans('goal.tocodetable.movetoothergoal.swal.confirmbutton', {}, 'cms'),
                            cancelButtonText: Translator.trans('goal.tocodetable.movetoothergoal.swal.cancelbutton', {}, 'cms'),
                            closeOnConfirm: true
                        },
                        function(isConfirm) {
                            if (isConfirm !== true) {
                                return false;
                            }
                            me.assignGoalIdManually();
                        });
                }

                ,removeGoalIdMatching: function() {
                    $.ajax({
                        url: Routing.generate('cms_goal_removematchingtogoal'),
                        data: me.config.params,
                        beforeSend: function(e, xhr, options) {
                            e.loaderKey = me.config.loader.getGeneratedKeyByPrefix("submitRequestRemoveGoalID");
                            me.config.loader.startLoader(e.loaderKey, me.config.loaderTargetContainer);
                        }
                    })
                    .done(function(data){
                        me.refreshProduct('contentstore.general.feedback.removegoalidmatching.success');
                    });
                }

                ,assignGoalIdManually: function() {
                    $.ajax({
                        url: Routing.generate('cms_goal_movematchingtogoal'),
                        data: me.config.params,
                        beforeSend: function(e, xhr, options) {
                            e.loaderKey = me.config.loader.getGeneratedKeyByPrefix("submitRequestAssignNewGoalID");
                            me.config.loader.startLoader(e.loaderKey, me.config.loaderTargetContainer);
                        }
                    })
                    .done(function(data){
                        me.refreshProduct('contentstore.general.feedback.assigngoalid.done');
                    });
                }

                ,refreshProduct: function(successMessageKey) {
                    var url = Routing.generate('cms_contentstore_product_search', {
                        'tocode': me.config.params['supplier']
                        ,'productcode': me.config.params['productcode']
                    });
                    swal({
                            title:  Translator.trans('', {}, 'cms'),
                            text:  Translator.trans(successMessageKey, {}, 'cms'),
                            type: 'success',
                            confirmButtonColor: '#3085d6',
                            closeOnConfirm: true
                        },
                        function() {
                            document.location.href = url;
                        }
                    );
                }

                /**
                 * validate the input field newgoalid
                 * save it in the me.config.params['goalid'] variable
                 */
                ,onChangeFieldGoalId: function(e) {
                    me.config.params['goalid'] = e.currentTarget.value;
                }

            };

            /* public methods */
            var baseObj = {};

            /* attach public functions to singleton */
            $.each(injection.call(me, me), function(idx, prop) {
                baseObj[idx] = prop;
            });

            /* execute init method */
            me.init();
            return baseObj;
        };

        return {
            inject: function(inject) {
                injection = inject;
            }
            ,getInstance: function() {
                if (instance === null) {
                    instance = initInstance();
                }
                return instance;
            }
        }
    })();
});
