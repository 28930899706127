var GoalEditController = (function(){

    var instance;
    var loader;
    var valuefieldwrapperclass = "goaldata-field-editable";
    var valuefieldclass = "goaldata-field-editablevalue";
    var valuefieldlabelclass = "goaldata-field-editablelabel";
    var dirtyflagclass = "dirty-flag";
    var lastclickededitablelinkid = "";
    var fieldcount = 0;
    var urlregex = /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/;
    var emailregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([\S]+\.)+[a-zA-Z]{2,}))$/;

    function createInstance() {

        function addHandlers() {
            $('.cmscontentstore--goaldetail').off('click', '#EditGoalButton', initEditBasicData );
            $('.cmscontentstore--goaldetail').on('click', '#EditGoalButton', initEditBasicData );
            $(document).off('keydown','.editable-input input', onEditableFieldKeyDownHandler);
            $(document).on('keydown','.editable-input input', onEditableFieldKeyDownHandler);
        }

        var validateURL = function(value) {
            return urlregex.test(value);
        };

        var validateEmail = function(email) {
            return emailregex.test(email);
        };

        /**
         * save the edf with updated texts
         *
         * @param Event e               click Event (jQuery, as usual)
         * @return void
         */
        var onSaveBasicDataButtonClickHandler = function(e){

            var button = $(this)
                ,parent = button.parent().parent()
                ,data = {}
                ,key = button.data('key')
                //,loaderTargetContainer = $('.content');
                ,loaderTargetContainer = $('.cmscontentstore--goaldetail');
                //loaderId = loader.add(loaderTargetContainer);


            parent.find('.bd-row .'+valuefieldwrapperclass).each(function(){
                var self = $(this).find("a");
                var label = self.data('name');

                if(self.hasClass("editable-unsaved") || self.hasClass("editable-empty")){
                    data[label] = (self.editable('getValue'))[label];
                }
            });
            data.goalid = key;

            $.ajax({
                url: Routing.generate("cms_goal_save_goal", {data: data})
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = loader.getGeneratedKeyByPrefix("saveBasicData");
                    loader.startLoader(e.loaderKey, loaderTargetContainer);
                }
            })
                .done(function(data) {
                    GoalController.getInstance().loadGoalDetailPageByKey(key, loaderTargetContainer, false);
                    //GoalController.getInstance().updateGoalList();
                })
                .fail(function(data) {
                    swal(
                        Translator.trans('goal.detail.basicdata.message.savefailerror.title', {}, 'cms'),
                        Translator.trans('goal.detail.basicdata.message.savefailerror.text', {}, 'cms'),
                        "error"
                    );
                });
        };

        /**
         * replaces current element with a respective x-editable representation (well ... domwise it is just an anchor)
         *
         * @return void
         */
        var replaceElementWithXEditable = function(){

            fieldcount++;
            var type = 'text';
            var text = $(this).text();
            if ($(this).data('name') === 'type' || $(this).data('name') === 'subtype') {
                type = "select";
            }
            if ($(this).data('name') === 'email') {
                text = $(this).data('email');
            }
            if ($(this).data('name') === 'website') {
                text = $(this).data('web');
            }
            var xeditableanchor = $('<a/>',{
                "data-type": type,
                "data-title": "Enter "+$(this).data('name'),
                "data-name": $(this).data('name'),
                "id": "Goal"+$(this).data('name').toUpperCase()+"Edit",
                "data-pk": "2000"+fieldcount,
                "href": "#",
                "data-typeid":$(this).data('typeid'),
                "data-email": $(this).data('email'),
                "data-web": $(this).data('web'),
                click: onEditableFieldClickHandler,
                text: text
            });
            var $parent = $(this).parent();
            var label = $parent.find('.'+valuefieldlabelclass);
            label.data('original', $(this).text());
            $(this).remove();
            $parent.append(" ");
            $parent.append(xeditableanchor);
        };

        /**
         * creates and initializes x-editables for current elements
         *
         * @return void
         */
        var createXEditableFields = function(){
            var $parent = $('.goaldata-field-editablevalue').parent();
            $('.goaldata-field-editablevalue').each(replaceElementWithXEditable);

            $.each($parent.find('a'), function() {
                var editableLink = $(this);
                if (editableLink.attr('id') === 'GoalTYPEEdit') {
                    var sources = editableLink.parent().data('typedata');
                    var subtypes = editableLink.parent().data('subtypedata');
                    $('#GoalTYPEEdit').editable({
                        type: 'select',
                        source: sources,
                        //onblur: 'submit',
                        emptytext: 'Empty',
                        success: function(response, newValue) {
                            var subTypeSource = (subtypes[newValue])?subtypes[newValue]:[];
                            $('#GoalSUBTYPEEdit').editable('option', 'source', subTypeSource);
                            $('#GoalSUBTYPEEdit').editable('setValue', "");
                        }
                    });
                } else if (editableLink.attr('id') === 'GoalSUBTYPEEdit') {
                    var id = editableLink.data('typeid');
                    $.ajax({
                        url:Routing.generate('cms_goal_getsubtypes', {type:id})
                    }).done(function(data) {
                        $('#GoalSUBTYPEEdit').editable({
                            source: (data && data.subtypes)?data.subtypes:[],
                            type: 'select',
                            sourceError: 'Please, select value in first list'
                        });
                    });

                } else if (editableLink.attr('id') === 'GoalWEBSITEEdit') {
                    $('#GoalWEBSITEEdit').editable({
                        validate: function(value){
                            if(!validateURL(value) && value!=="") {
                                return 'Please insert valid url!';
                            }
                        }
                    });
                } else if (editableLink.attr('id') === 'GoalEMAILEdit') {
                    $('#GoalEMAILEdit').editable({
                        validate: function(value){
                            if(!validateEmail(value) && value!=="") {
                                return 'Please insert valid email!';
                            }
                        }
                    });
                } else {
                    editableLink.editable();
                }
            });

            // remove handler if already exist
            $(document).off('keydown','.editable-input input', onEditableFieldKeyDownHandler);
            $(document).on('keydown','.editable-input input', onEditableFieldKeyDownHandler);

        };

        initEditBasicData = function(e) {
            var button = $(this);
            $('.js-goaldata-field--novalidurl').removeClass('js-goaldata-field--novalidurl');
            if(button.hasClass('btn-default')) {
                button.removeClass('btn-default btn-outline').addClass('btn-success');
                var saveButton = $('.cmscontentstore--goaldetail .basicdata-panel .bd-row #GoalSaveButton');
                saveButton.on('click', onSaveBasicDataButtonClickHandler );
                saveButton.show();
                $('.cmscontentstore--goaldetail .basicdata-panel .bd-row .'+valuefieldlabelclass+' .star').show();
                $('.cmscontentstore--goaldetail .basicdata-panel .bd-row span.info').show();
                createXEditableFields();
            } else {
                button.removeClass('btn-success').addClass('btn-default btn-outline');
                $('.cmscontentstore--goaldetail .basicdata-panel .bd-row button').hide();
                $('.cmscontentstore--goaldetail .basicdata-panel .bd-row .'+valuefieldlabelclass+' .star').hide();
                $('.cmscontentstore--goaldetail .basicdata-panel .bd-row span.info').hide();
                $('.'+valuefieldwrapperclass).off('dblclick', onEditableFieldDoubleClickHandler);
                resetEditableFields($('.'+valuefieldwrapperclass));
            }
            return false;
        };

        var resetEditableFields = function(fields) {
            fields.each(function(k,v){
                $(v).find('.'+valuefieldclass).focusout();
                var label = $(v).find('.'+valuefieldlabelclass);
                label.removeClass(dirtyflagclass);
                $(v).find('span.'+valuefieldclass).text(label.data('original'));
                var link = $(v).find('a');
                var text = label.data('original');
                var resetContainer = $('<span/>', {
                    'class':valuefieldclass,
                    'data-name':link.data('name'),
                    'text':text,
                    'data-typeid':link.data('typeid')
                });
                if (link.data('name') == 'email') {
                    resetContainer = $(v).append($('<a/>', {
                        'class':valuefieldclass,
                        'data-name':link.data('name'),
                        'text':text,
                        'data-email':link.data('email'),
                        'href': 'mailto:' + link.data('email')
                    }));
                }
                if (link.data('name') == 'website') {
                    if (link.data('web').substr(0,4) !== 'http') {
                        valuefieldclass = valuefieldclass + ' js-goaldata-field--novalidurl';
                    }
                    resetContainer = $(v).append($('<a/>', {
                        'class':valuefieldclass,
                        'data-name':link.data('name'),
                        'text':text,
                        'data-web':link.data('web'),
                        'href': link.data('web')
                    }));
                }

                $(v).append(resetContainer);
                link.remove();
            });
        };

        /**
         * registers clicked element for tab and arrowkey navigation later on
         *
         * @param Event e                       Click Event
         * @return void
         */
        var onEditableFieldClickHandler = function(e){
            lastclickededitablelinkid = $(this).attr('id');
        };

        /**
         * Looks for the next Element to be edited and selects it by boubleclick
         *
         * @param Boolean shiftFlag                     reverse the search direction
         * @return void
         */
        var findNextEditableElement = function (shiftFlag){

            if(shiftFlag){
                $('#'+lastclickededitablelinkid).parent().prevAll('div.'+valuefieldwrapperclass+':first').find('a').click();
            }else{
                $('#'+lastclickededitablelinkid).parent().nextAll('div.'+valuefieldwrapperclass+':first').find('a').click();
            }

        };

        /**
         * enables keyboard selection and escaping
         *
         * @param Event e                               the (jQuery enriched) keydown event
         * @return void
         */
        var onEditableFieldKeyDownHandler = function(e){

            // tab / shift tab
            if(9 == e.which){
                e.preventDefault();
                $('.editable-submit').click();

                findNextEditableElement(e.shiftKey);
            }

            // arrow up/down return key
            if(38 == e.which || 40 == e.which || 13 == e.which){
                e.preventDefault();
                $('.editable-submit').click();

                findNextEditableElement((38 == e.which));
            }

        };

        /**
         * triggers exchange of plain text to input node / binds events to new element
         *
         * @param Event e                               jQuery dblclick Event
         *
         * @return void
         */
        var onEditableFieldDoubleClickHandler = function(e){

            $container = $(this);

            var input = setEditableField($container);
            input.on('keydown', onEditableFieldKeyDownHandler);
            input.focus();
            input.select();

        };

        var init = function() {
            loader = Loader.getInstance();
            addHandlers();
        };
        
        return {
            init: init
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();