var ProductGlobalTypesController = (function(){

    var instance
        ,loader;

    function createInstance() {

        var addHandlers = function () {
            var wrapper = $('.js-contentstore-productglobaltypes')
                ,gtContainer = wrapper.find('#ProductGlobalTypesContainer')
                ,supplier = gtContainer.data('supplier')
                ,productCode = gtContainer.data('productcode')
                ,table = wrapper.find('#ProductGlobalTypesDataTable');
            initDatatable(table, supplier, productCode)
        };

        var initDatatable = function(table, supplier, productCode) {
            table
                .dataTable({
                    ajax: {
                        url: Routing.generate(
                            'cms_contentstore_productglobaltypeslist',
                            {
                                'supplier': supplier,
                                'productcode': productCode
                            }
                        ),
                        beforeSend  : function(e, xhr, options) {
                            e.loaderKey = "productGlobalTypesList";
                            loader.startLoader(e.loaderKey, table);
                        }
                    },
                    serverSide: false,
                    lengthChange: false,
                    searching: false,
                    pagingType: 'simple_numbers',
                    pageLength: 10,
                    "order": [],
                    columns: [
                        {data: 'globaltype',  sortable: false}
                        ,{data: 'subtype',  sortable: false}
                        ,{data: 'additionaltype',  sortable: false}
                    ],
                    language: {
                        paginate: {
                            next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
                            previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
                        },
                        info: Translator.trans('contentstore.producttable.info', {}, 'cms'),
                        infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms'),
                        emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
                    }
                });
        };

        var init = function() {
            loader = Loader.getInstance();
            addHandlers();
        };

        return {
            init: init
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();