var ConfigContentExportModalController = (function(){

    var instance
        ,loader
        ,modal
        ,contentExportImagesController
        ,contentExportDescriptionsController
        ,contentExportLanguagesController
        ,contentExportAdditionalFlagsController
        ,isNew = true
        ,isDefault = true
        ,select
        ,currentBrandIdentifiers
        ,loaderKey;

    function createInstance() {
        var addHandlers = function () {
            modal = $('#contentexportbrandconfigmodal');
            modal.on('show.bs.modal', onShowModal);
            modal.on('hidden.bs.modal', onHideModal);
            modal.on('click', '.js-cms-config-contentexport-brandconfig-save-modal', onSubmit);
        };

        var initBrandSelector = function() {
            let brandSelectWrapper = modal.find('.js-brand-select-wrapper');
            let deferred = $.Deferred();
            if (isDefault) {
                return deferred.resolve('true').promise();
            }
            $.ajax({
                url: Routing.generate('cms_config_contentexport_brands')
            })
            .done(function(data) {
                if (data.brands) {
                    var brands = data.brands;
                    select = $('<select/>', {
                        'class': 'form-control m-b js-select-brandidentifiers',
                        'name': 'brands',
                        'multiple': "multiple"
                    });
                    for (var brandIndex in brands) {
                        let brand = brands[brandIndex],
                            outputLabel = brand.name + ' (' + brand.usage + ')';
                        select.append($('<option/>', {
                            'data-identifier': brand.name + '-' + brand.usage,
                            'value': brand.name + '-' + brand.usage,
                            'text': outputLabel
                        }));
                    }
                    brandSelectWrapper.append(select);

                    select.multipleSelect({
                        nonSelectedText: 'Select a brand',
                        selectAll: false,
                        filter: true,
                        minimumCountSelected: 10,
                        onClick: checkIfConfigExists
                    });

                }
                deferred.resolve('true');
            })
            .fail(function(e) {
                deferred.reject(e);
            });
            return deferred.promise();
        };

        var checkIfConfigExists = function(view) {
            let values = select.multipleSelect('getSelects')
                ,currentSelectedBrands = values.join(',')
                ,existingBrandIdentifiers = modal.attr('data-brandidentifiers')
                ,existingBrandIdentifiersArray = existingBrandIdentifiers.split('|')
                ,warningContainer = modal.find('.js-brand-exists-warning')
                ,configExists = false;
            for(let i = 0; i < existingBrandIdentifiersArray.length; i++) {
                var brands = existingBrandIdentifiersArray[i];
                if (brands === currentSelectedBrands && currentBrandIdentifiers !== currentSelectedBrands) {
                    warningContainer.slideDown('slow');
                    configExists = true;
                    return;
                }
            }

            if (!configExists) {
                warningContainer.slideUp('slow');
            }
        };

        var renderCurrentBrandIdentifier = function(brands, status) {
            let titleContainer = modal.find('.js-cms-config-contentexport-brandconfig-modal-currentbrands');
            if (isDefault) {
                titleContainer.html('(Default)');
                return;
            }
            if (brands) {
                let outputBrands = [];
                for (let index in brands) {
                    let brand = brands[index];
                    if (brand.active) {
                        let span =  $('<span/>', {
                            'class': '',
                            'text': brand.name + ' (' + brand.usage + ')'
                        });
                        outputBrands.push(span[0].outerHTML);
                    } else {
                        let span = $('<span/>', {
                            'class': status + '-brand',
                            'text': brand.name + ' (' + brand.usage + ')'
                        });
                        outputBrands.push(span[0].outerHTML);
                    }
                };
                titleContainer.html(
                    '(' + outputBrands.join(', ') + ')'
                );
            }
        };

        var onShowModal = function(event) {
            let button = $(event.relatedTarget)
                ,brandIdentifiers = button.data('brandidentifiers') || null
                ,brands = button.data('brands') || null
                ,status = button.data('status')
                ,loaderContainer = modal.find('.js-cms-config-contentexport-brandconfig-modalbody-content');
            if (brandIdentifiers === 'default') {
                isDefault = true;
                isNew = false;
            } else if (!brandIdentifiers) {
                brandIdentifiers = 'default';
                isNew = true;
                isDefault = false;
            } else {
                currentBrandIdentifiers = brandIdentifiers;
                isNew = false;
                isDefault = false;
            }
            loaderKey = loader.getGeneratedKeyByPrefix("brandconfigurationmodal");
            loader.startLoader(loaderKey, loaderContainer);
            renderCurrentBrandIdentifier(brands, status);
            initBrandSelector().done(function() {
                onChangeBrandSelect(brandIdentifiers);
                if (!isDefault && !isNew) {
                    checkIfConfigExists();
                }
            });
        };

        var onHideModal = function() {
            select = null;
            currentBrandIdentifiers = null;
            loaderKey = null;
            modal.find('.js-brand-select-wrapper').html(null);
            modal.find('.js-cms-config-contentexport-brandconfig-modalbody-content').html(null);
            modal.find('.js-cms-config-contentexport-brandconfig-modal-currentbrands').html(null);
            modal.find('.js-brand-exists-warning').hide();
        };

        var onChangeBrandSelect = function(brandIdentifiers) {
            if (brandIdentifiers && !isDefault && !isNew) {
                select.multipleSelect('setSelects', brandIdentifiers.split(','));
            }
            changeBrandConfig(brandIdentifiers);
        };

        var changeBrandConfig = function(brandIdentifiers) {
            resetInstances();

            let params = {};
            if (brandIdentifiers) {
                params['brandidentifiers'] = brandIdentifiers;
            }

            $.ajax({
                'url': Routing.generate('cms_config_contentexport_brandconfiguration', params)
            })
            .done(function(data) {
                var contentContainer = modal.find('.modal-body .js-cms-config-contentexport-brandconfig-modalbody-content');
                contentContainer.html(data.view);
                loadInstances(contentContainer);
                loader.remove(loaderKey);
            })
            .fail(function(e) {
                swal({
                    title: Translator.trans('config.labels.prioritycontentexport.modal.warning.brandconfignotexists.title', {}, 'cms')
                    ,text: Translator.trans('config.labels.prioritycontentexport.modal.warning.brandconfignotexists.description', {}, 'cms')
                    ,type: "warning"
                    ,showCancelButton: false
                    ,closeOnConfirm: true
                });
                loader.remove(loaderKey);
            })
        };

        var getBrandIdentifier = function() {
            let brandIdentifiers = 'default';
            if (!isDefault) {
                brandIdentifiers = select.multipleSelect('getSelects').join(',');
            }
            return brandIdentifiers;
        };

        var onSubmit = function(e) {
            e.preventDefault();
            let brandIdentifiers = getBrandIdentifier()
                ,loaderContainer = $('.js-cms-config-contentexport-brandconfig-modal-content')
                ,route = 'cms_config_contentexport_save_brand'
                ,data;

            if (!brandIdentifiers) {
                swal({
                    title: Translator.trans('config.labels.prioritycontentexport.modal.validation.brand.save.title', {}, 'cms')
                    ,text: Translator.trans('config.labels.prioritycontentexport.modal.validation.brand.save.description', {}, 'cms')
                    ,type: "warning"
                    ,showCancelButton: false
                    ,closeOnConfirm: true
                });
                return false;
            }

            var languageList = contentExportLanguagesController.getPriorityContentExportLanguagesList();
            if (languageList.length === 0) {
                swal({
                    title: Translator.trans('config.labels.prioritycontentexport.modal.validation.language.save.title', {}, 'cms')
                    ,text: Translator.trans('config.labels.prioritycontentexport.modal.validation.language.save.description', {}, 'cms')
                    ,type: "warning"
                    ,showCancelButton: false
                    ,closeOnConfirm: true
                });
                return false;
            };

            var additionalFlagList = contentExportAdditionalFlagsController.getPriorityContentExportAdditionalFlagsList();
            data = {
                brandIdentifiers: brandIdentifiers,
                alwaysincludeglobaltypes : contentExportDescriptionsController.getAlwaysAddGlobalType(),
                prioritySupplierList: contentExportImagesController.getPrioritySupplierList(),
                priorityRuleSetList: contentExportDescriptionsController.getPriorityRuleSetList(),
                priorityContentExportLanguagesList: languageList,
                priorityContentExportAdditionalFlagsList: additionalFlagList
            };

            // if the variable currentBrandIdentifiers is set, an update request must be called!
            if (currentBrandIdentifiers) {
                route = 'cms_config_contentexport_update_brand';
                data['currentBrandIdentifiers'] = currentBrandIdentifiers;
            }

            $.ajax({
                url: Routing.generate(route)
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = loader.getGeneratedKeyByPrefix("saveContenExport");
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
                ,data: data
                ,method: 'POST'
            })
            .done(function(data){
                if (data.success === true) {
                    toastr.success(
                        Translator.trans('def.term.success', {}, 'cms'),
                        Translator.trans('config.labels.prioritycontentexport.save.successmessage', {}, 'cms')
                    );
                    modal.modal('hide');
                    ConfigContentExportController.getInstance().refresh();
                }
            })
            .fail(function(e) {
                toastr.error(
                    Translator.trans('def.term.error', {}, 'cms'),
                    Translator.trans('config.labels.prioritycontentexport.save.errormessage', {}, 'cms')
                );
            });
        };

        var resetInstances = function() {
            if (contentExportImagesController) {
                contentExportImagesController.reset();
            }
            if (contentExportDescriptionsController) {
                contentExportDescriptionsController.reset();
            }
            if (contentExportLanguagesController) {
                contentExportLanguagesController.reset();
            }
        };

        var loadInstances = function(parentContainer) {
            if (parentContainer.find('.js-cms-config-prioritycontentexport-images').length > 0) {
                contentExportImagesController = ConfigPriorityContentExportImagesController.getInstance();
                contentExportImagesController.init();
            }
            if (parentContainer.find('.js-cms-config-prioritycontentexport-descriptions').length > 0) {
                contentExportDescriptionsController = ConfigPriorityContentExportDescriptionsController.getInstance();
                contentExportDescriptionsController.init();
            }
            if (parentContainer.find('.js-cms-config-prioritycontentexport-languages').length > 0) {
                contentExportLanguagesController = ConfigPriorityContentExportLanguagesController.getInstance();
                contentExportLanguagesController.init();
            }
            if (parentContainer.find('.js-cms-config-prioritycontentexport-additionalflags').length > 0) {
                contentExportAdditionalFlagsController = ConfigPriorityContentExportAdditionalFlagsController.getInstance();
                contentExportAdditionalFlagsController.init();
            }
        };

        var init = function() {
            loader = Loader.getInstance();
            addHandlers();
        };

        return {
            init: init
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();
