var ConfigLanguagesController = (function(){

    var instance
        ,loader;

    function createInstance() {

        var addHandlers = function () {
            var wrapper = $('.js-cms-config-languages');
            wrapper.find('.js-select2-extcheckbox').select2();
            wrapper.on('submit', '.js-cms-config-form-language', onSubmit);
            wrapper.on('click', '.js-config-openaddcheckboxitemdropdown', onOpenDropDown);
            wrapper.on('click', '.js-config-addcheckboxitembtn', onAddCheckBoxItem);
            wrapper.on('click', '.btn-reset-language', onReset);
        };

        var onReset = function(e) {
            e.preventDefault();
            var btn = $(this)
                ,loaderContainer = btn.parents('.js-cms-config-languages');
            $.ajax({
                    url: Routing.generate('cms_config_resetlanguages')
                    ,beforeSend: function(e, xhr, options) {
                        e.loaderKey = "resetLanguages";
                        loader.startLoader(e.loaderKey, loaderContainer);
                    }
                })
                .done(function(data) {
                    if (data.view) {
                        btn.parents('.js-config-formwrapper').html(data.view);
                    }
                });
        };

        var onOpenDropDown = function(e) {
            var btn = $(e.currentTarget)
                ,btnIcon = btn.find('.fa')
                ,parent = btn.parent()
                ,select = parent.find('select')
                ,input = parent.find('input');
            if (select.length > 0) {
                select.select2('val', null);
            }
            if (input.length > 0) {
                input.val(null);
            }
        };

        var onAddCheckBoxItem = function() {
            var btn = $(this)
                ,name = btn.data('name')
                ,input  = btn.parent().parent().find('select')
                ,values = input.val()
                ,parent = btn.parents('td')
                ,formfields = parent.find('.js-cms-config-form-language-fields');

            for (var val in values) {
                var value = values[val]
                    ,existingItem = checkboxItemAlreadyExists(formfields.find('input[type=checkbox]'), value);
                if (existingItem) {
                    existingItem.prop('checked', true);
                }
                if (value && !existingItem) {
                    formfields.append($('<div/>', {class:'checkbox-item'})
                        .append($('<div/>', {class:'checkbox checkbox-primary'})
                            .append($('<input/>', {
                                    'id': value
                                    ,'name': name+'[]'
                                    ,'type': 'checkbox'
                                    ,'value': value
                                    ,'checked': 'checked'
                                })
                            )
                            .append($('<label/>', {'for': value, 'text':value}))
                        )
                    );
                }
            }
            parent.find('.js-config-openaddcheckboxitemdropdown').click();
            return false;
        };

        var checkboxItemAlreadyExists = function(checkboxes, item) {
            for (var i=0; i<checkboxes.length; i++) {
                if ($(checkboxes[i]).val() == item) {
                    return $(checkboxes[i]);
                }
            }
            return false;
        };


        var onSubmit = function(e) {
            e.preventDefault();
            var form = $(this)
                ,loaderContainer = form.parents('.js-cms-config-languages')
                ,checkbox = form.find('input')
                ,languages = []
                ,url = Routing.generate('cms_config_savelanguages');

            $.each($(checkbox).serializeArray(), function (idx, kV) {
                if (kV.value !== '') {
                    languages.push(kV.value);
                }
            });

            $.ajax(url, {
                data: {
                    languages: languages
                },
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "saveConfigLanguages";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
                ,method: 'POST'
            })
            .done(function(data){
                if (data.success === false) {
                    toastr.error(
                        Translator.trans('def.term.error', {}, 'cms'),
                        Translator.trans('config.section.languages.save.errormessage', {}, 'cms')
                    );
                }
                if (data.success === true) {
                    loaderContainer.find('.btn-reset-language').click();
                    toastr.success(
                        Translator.trans('def.term.success', {}, 'cms'),
                        Translator.trans('config.section.languages.save.successmessage', {}, 'cms')
                    );
                }
            });
        };

        var init = function() {
            loader = Loader.getInstance();
            addHandlers();
        };

        return {
            init: init
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();