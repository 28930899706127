(function() {
    var contextToolkit = pwNamespace('PEAKWORK.TOOLKIT');
    contextToolkit.FlotChartContainer = function (config, chartOptions) {
        config = config || {};
        chartOptions = chartOptions || {};

        this.config = {
            loaderCls: '.loader'
            ,flotChartCls: '.flot-chart'
            ,flotChartContentCls: '.flot-chart-content'
        };
        $.extend(true, this.config, config);

        this.containers = $(this.config.containerCls);

        this.chartOptions = {
            series: {
                grow: {
                    active: true
                    ,growings: [{
                        reanimate: 'none'
                        ,valueIndex: 1
                    }]
                }
                ,lines: {
                    show: false
                }
                ,points: {
                    show: false
                }
                ,bars: {
                    show: false
                }
            }
            ,xaxis: {
                ticks: []
            }
            ,yaxis: {
                min: 0,
                tickDecimals: 0
            }
            ,colors: ['#62cb31']
            ,grid: {
                color: '#e4e5e7'
                ,hoverable: true
                ,tickColor: '#D4D4D4'
                ,borderWidth: 0
                ,borderColor: '#e4e5e7'
                ,clickable: true
            }
            ,legend: {
                show: false
            }
            ,tooltip: true
            ,tooltipOpts: {
                content: function(label, x, y, item) {
                    if (
                        'undefined' !== typeof item.series.pointTooltips &&
                        'undefined' !== typeof item.series.pointTooltips[item.seriesIndex] &&
                        'undefined' !== typeof item.series.pointTooltips[item.seriesIndex][item.dataIndex]
                    ) {
                        return item.series.pointTooltips[item.seriesIndex][item.dataIndex];
                    } else if ('undefined' !== typeof label) {
                        return label + ': ' + y;
                    } else {
                        return '%y';
                    }
                }
            }
        };
        $.extend(true, this.chartOptions, chartOptions);

        this.init();
    };

    contextToolkit.FlotChartContainer.prototype.init = function() {
        var i = 0
            ,containerLength = this.containers.length;

        for (i; i < containerLength; i++) {
            this.loadChart($(this.containers[i]));
        }
    };

    /**
     * Loads the charts content.
     * @param {Element} container
     */
    contextToolkit.FlotChartContainer.prototype.loadChart = function(container) {
        var me = this
            ,chartLoader = container.find(this.config.loaderCls)
            ,flotchart = container.find(this.config.flotChartCls);

        $.ajax({
            url: container.data('url'),
            method: 'POST',
            beforeSend: function() {
                flotchart.addClass('hidden');
                chartLoader.html(PeakworkBackendHelper.getInstance().createLoader());
                chartLoader.removeClass('hidden');
            },
            success: function(response) {
                chartLoader.addClass('hidden');
                chartLoader.html('');
                flotchart.removeClass('hidden');

                if (null !== response) {
                    me.updateChart(response, container);
                }
            }
        });
    };

    /**
     * Creates the chart with the data.
     * @param {Object} response
     * @param {Element} container
     */
    contextToolkit.FlotChartContainer.prototype.updateChart = function(response, container) {
        var chartOptions = this.getChartOptions(response, container)
            ,data;

        if (
            'undefined' !== typeof chartOptions.series.pie ||
            true === chartOptions.series.stacked ||
            true === chartOptions.series.stackpercent ||
            true === container.data('multilines') ||
            (chartOptions.hasOwnProperty('pwType') && chartOptions.pwType == 'separatebars')
        ) {
            data = response.values;
        } else {
            data = [{
                data: response.values
            }];
        }

        // Fix grow JS error with empty flot data
        if (!!data
            && data.length < 1
            && chartOptions.hasOwnProperty('series')
            && chartOptions.series.hasOwnProperty('grow')) {
            chartOptions.series.grow = false;
        }

        return $.plot(container.find(this.config.flotChartContentCls), data, chartOptions);
    };

    /**
     * Creates the options for the charts. Adds the series types as defined in
     * the data attributes of the item.
     * @param {Object} response
     * @param {Element} container
     * @returns {Object}
     */
    contextToolkit.FlotChartContainer.prototype.getChartOptions = function(response, container) {
        var chartOptions = $.extend(true, {}, this.chartOptions),
            chartTypes = container.data('charttype').split(','),
            position = 'nw';

        if ('undefined' !== typeof response.labels) {
            chartOptions.xaxis.ticks = response.labels;
        }

        if ('undefined' !== typeof response.pointTooltips) {
            chartOptions.series.pointTooltips = response.pointTooltips;
        }
        chartOptions.itemIndex = container.data('index');

        if (-1 !== chartTypes.indexOf('lines')) {
            chartOptions = this.addLinesDefinitionToOptions(chartOptions);
        }

        if (-1 !== chartTypes.indexOf('points')) {
            chartOptions = this.addPointsDefinitionToOptions(chartOptions);
        }

        if (-1 !== chartTypes.indexOf('bars')) {
            chartOptions = this.addBarsDefinitionToOptions(chartOptions);
        }

        if (-1 !== chartTypes.indexOf('stack')) {
            // Left series.stacked in because it might still be needed for some plugin.
            chartOptions.series.stacked = true;
            chartOptions.series.stack = true;
        }

        if (-1 !== chartTypes.indexOf('separatebars')) {
            chartOptions = this.addSeparateBarsDefinitionToOptions(chartOptions);
            chartOptions.pwType = 'separatebars';
        }

        if (true === container.data('legend')) {
            if ('undefined' !== typeof container.data('legend-position')) {
                position = container.data('legend-position');
            }
            chartOptions.legend = {
                show: true
                ,position: position
                ,noColumns: 0
            };
        }

        if (-1 !== chartTypes.indexOf('pie')) {
            chartOptions = this.addPieDefinitionToOptions(chartOptions);
        }

        if (-1 !== chartTypes.indexOf('donut')) {
            chartOptions = this.addPieDefinitionToOptions(chartOptions, true);
        }

        return chartOptions;
    };

    /**
     * Adds the definition for lines to the chart options.
     * @param {Object} chartOptions
     * @returns {Object}
     */
    contextToolkit.FlotChartContainer.prototype.addLinesDefinitionToOptions = function(chartOptions) {
        chartOptions.series.lines = {
            show: true
            ,fill: false
        };

        return chartOptions;
    };

    /**
     * Adds the definition for points to the chart options.
     * @param {Object} chartOptions
     * @returns {Object}
     */
    contextToolkit.FlotChartContainer.prototype.addPointsDefinitionToOptions = function(chartOptions) {
        chartOptions.series.points = {
            show: true
            ,fill: false
        };

        return chartOptions;
    };

    /**
     * Adds the definition for bars to the chart options.
     * @param {Object} chartOptions
     * @returns {Object}
     */
    contextToolkit.FlotChartContainer.prototype.addBarsDefinitionToOptions = function(chartOptions) {
        chartOptions.series.bars = {
            show: true
            ,barWidth: 0.7
            ,fill: true
            ,align: 'center'
            ,fillColor: {
                colors: [
                    {opacity: 0.6}
                    ,{opacity: 0.6}
                ]
            }
            ,lineWidth: 0.1
        };

        return chartOptions;
    };

    /**
     * Adds the definition for bars to the chart options.
     * @param {Object} chartOptions
     * @returns {Object}
     */
    contextToolkit.FlotChartContainer.prototype.addSeparateBarsDefinitionToOptions = function(chartOptions) {
        chartOptions.series.bars = {
            show: true
            ,barWidth: 0.7
            ,fill: true
            ,align: 'center'
            ,lineWidth: 0.1
        };

        return chartOptions;
    };

    /**
     * Adds the definition for a pie to the chart options.
     * @param {Object} chartOptions
     * @param {undefined|Boolean} isDonut
     * @returns {Object}
     */
    contextToolkit.FlotChartContainer.prototype.addPieDefinitionToOptions = function(chartOptions, isDonut) {
        chartOptions.series.pie = {
            show: true
            ,radius: 1
            ,tilt: 0.5
            ,label: {
                show: true
                ,formatter: function(label, series) {
                    return '<div class="flotcharttooltip">' +
                        label + '<br/>' +
                        Math.round(series.percent) + '%' +
                    '</div>';
                }
                ,background: {
                    opacity: 0.8
                }
            }
        };

        if (true === isDonut) {
            chartOptions.series.pie.innerRadius = 0.3;
        }

        return chartOptions;
    };
})();
