var DownloadController = (function(){

    var instance
        ,loader;

    var createInstance = function() {

        var intervalId = null
            ,headerListItem = $('.headerlistItem__downloadstatuscontainer')
            ,downloadList = headerListItem.find('.headerlistItem__downloadstatuslist');

        var addHandlers = function () {
            headerListItem.on('click', '.headerlistItem__downloadstatuscontainertoggle', function(e){
                onClickToggle(e);
            });
            downloadList.on('click', '.downloadstatuslistitem__link', function(e){
                onClickDownloadStatusItem(e);
            });
            downloadList.on('click', '.downloadstatuslistitem__removebtn', function(e){
                onClickRemoveItem(e);
            });
            if (downloadList.data('checkstatus')) {
                startCheckStatus();
            }
        };

        var onClickToggle = function(e) {
            var self = $(e.currentTarget)
                ,li = self.parent();
            if (li.hasClass('headerlistItem__downloadstatuscontainer--changed')) {
                li.removeClass('headerlistItem__downloadstatuscontainer--changed');
            }
        };

        var startCheckStatus = function(withNofication) {
            if (!intervalId) {
                intervalId = setInterval(function(e) {
                    checkDownloadStatus();
                }, 10000);
            }
            if (withNofication) {
                notificationEffect();
            }
        };

        var stopCheckStatus = function() {
            if (intervalId) {
                clearInterval(intervalId);
                intervalId = null;
            }
        };

        var notificationEffect = function(){
            toastr.info(
                Translator.trans('core.header.download.notification', {}, 'core')
            );
            $(toastr.getContainer().selector).addClass('download-toastr');
            headerListItem.addClass('headerlistItem__downloadstatuscontainer--changed');
            headerListItem.effect('pulsate', {times:5}, 3000, function(){
                headerListItem.removeClass('headerlistItem__downloadstatuscontainer--changed');
            });
        };

        var onClickRemoveItem = function(e) {
            e.stopPropagation();
            var li = $(e.currentTarget).parent().parent()
            $.ajax({
                url: Routing.generate('cms_basic_removedownloadstatus')
                ,data: {
                    id: li.data('id')
                }
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "removeItem";
                    loader.startLoader(e.loaderKey, downloadList);
                }
            })
            .done(function(data){
                if (data.status === 'success') {
                    li.remove();
                }
                if(downloadList.find('li').length === 0) {
                    downloadList.append($('<li/>', {
                        text: Translator.trans('core.header.download.status.empty', {}, 'core')
                    }));
                }
            });
        };

        var onClickDownloadStatusItem = function(e) {
            e.stopPropagation();
            var link = $(e.currentTarget);
            if (link.hasClass('downloadstatuslistitem__linkdownloadable')) {
                downloadFile(link.parent());
            }

        };

        var downloadFile = function(li) {
            $.ajax({
                url: Routing.generate('cms_basic_preparefile')
                ,data: {
                    'file': {
                        id: li.data('id')
                        ,filename: li.data('filename')
                        ,type: li.data('type')
                        ,status: li.data('status')
                    }
                }
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "downloadFile";
                    loader.startLoader(e.loaderKey, li);
                }
            })
            .done(function(data){
                window.location = Routing.generate('cms_basic_downloadfile', {
                    path: data.path
                    ,filename: li.data('filename')
                });
            });
        };

        var checkDownloadStatus = function(newFile){
            var headerListItemContainer = headerListItem
                ,filenames= {}
                ,deferred = $.Deferred();
            if (newFile) {
                filenames[newFile.filename] = {
                    id: newFile.id
                    ,filename: newFile.filename
                    ,type: newFile.type
                    ,status: newFile.status
                    ,createdAt: (newFile.createdAt.date)?newFile.createdAt.date:newFile.createdAt
                };
            }
            $.each(downloadList.find('.headerlistItem__downloadstatuslistitem'), function(k,v){
                var li = $(this);
                filenames[li.data('filename')] = {
                    id: li.data('id')
                    ,filename: li.data('filename')
                    ,type: li.data('type')
                    ,status: li.data('status')
                    ,createdAt: li.data('createdat')
                };
            });

            $.ajax({
                url: Routing.generate('cms_basic_checkdownloadstatus')
                ,data: {
                    'filenames': filenames
                },
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "checkDownloadStatus";
                    loader.startLoader(e.loaderKey, downloadList);
                }
            })
            .done(function(data){
                deferred.resolve(true);
                if(!data.checkForStatus) {
                    stopCheckStatus();
                }
                if (data.statusChanged) {
                    headerListItemContainer.addClass('headerlistItem__downloadstatuscontainer--changed');
                }
                downloadList.html(data.view);
            });
            return deferred.promise();
        };

        var init = function () {
            loader = Loader.getInstance();
            addHandlers();
        };

        return {
            init: init
            ,startCheckStatus: function(newFile) {
                var promise = checkDownloadStatus(newFile)
                    ,withNotification = (newFile)?true:false;
                startCheckStatus(withNotification);
                return promise;
            }
            ,stopCheckStatus: function() {
                stopCheckStatus();
            }
        }
    };

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

$(function() {
    DownloadController.getInstance().init();
});