var FilterForm = (function() {

    var instance;
    var forms =  {};
    var buttons = {};

    var options = {
        filterFormStartvaluesClass: 'js-filterform--has-startvalues'
        ,defaultDatePickerOptions: {
            format: 'dd.mm.yyyy'
        }
        ,radioInputContainerSelector: '.form-control'
        ,addAllFieldsSelector: 'input, select, textarea'
    };

    function createInstance() {

        var addHandlers = function () {
        };

        var init = function (formOptions) {
            formOptions = formOptions || false;

            if (!formOptions
                || typeof formOptions !== 'object') {
                throw 'FormOptions not an object';
            }

            var formId = formOptions.hasOwnProperty('formid') ? formOptions.formid : false;
            var submitFn = formOptions.hasOwnProperty('submit') ? formOptions.submit : false;
            var resetFn = formOptions.hasOwnProperty('reset') ? formOptions.reset : false;
            var resetAddFn = formOptions.hasOwnProperty('resetAddFn') ? formOptions.resetAddFn : false;
            var addFields = formOptions.hasOwnProperty('addfields') ? formOptions.addfields : false;

            if (typeof submitFn !== 'function') {
                throw 'FormOption.submit must be a function';
            }

            if (typeof resetFn !== 'function') {
                resetFn = _getResetFunction(formId, resetAddFn);
            }

            if (!formId) {
                throw 'FormOption.formid missing';
            }

            if (!checkFormExists(formId)) {
                var $theForm = $('#' + formId);
                if ($theForm.length < 1) {
                    throw 'Form not found';
                }
                forms[formId] = {};
                forms[formId]['form'] = $theForm;
                forms[formId]['fields'] = {};
            }

            $theForm.on('submit', submitFn);
            $theForm.on('reset', resetFn);

            $theForm.on('click', '[type=button]', buttonClick);

            if (addFields === true) {
                addFields = options.addAllFieldsSelector;
            }

            if (addFields) {
                _addFieldsBySelector(formId, addFields);
            }

            return forms[formId];
        };

        function addFieldsBySelector(formId, selector) {
            selector = selector || options.addAllFieldsSelector;

            if (!checkFormExists(formId)) {
                return false;
            }
            _addFieldsBySelector(formId, selector);
        }

        function _addFieldsBySelector(formId, selector) {

            var $fields = $(selector, forms[formId].form);

            if ($fields.length < 1) {
                return false;
            }

            $fields.each(function() {
                var fieldId;
                var $input = $(this);
                if ($input.attr('type') == 'radio') {
                    var $formControl = $input.closest(options.radioInputContainerSelector);
                    fieldId = _getFieldId($formControl);
                    addField(formId, fieldId + ' input');
                    return;
                }
                fieldId = _getFieldId($input);
                _addFormField(formId, fieldId);
            });
        }

        function _getFieldId($field) {
            fieldId = $field.attr('id') || false;
            if (!fieldId) {
                fieldId = $.uuid();
                $field.attr('id', fieldId);
            }
            return fieldId;
        }

        function buttonClick(e) {
            var $button = $(this)
                ,id = $button.attr('id') || false;
            if (!id) {
                return;
            }
            if (!buttons.hasOwnProperty(id)
                || !buttons[id].hasOwnProperty('fn')
                || typeof buttons[id].fn !== 'function') {
                return;
            }
            buttons[id].fn(e);
            console.log('Button clicked: ' + id);
            console.log(buttons[id]);
        }

        function _getResetFunction(formId, resetAddFn) {
            var resetFn = function(e) {
                e.preventDefault();
                forms[formId].form[0].reset();
                resetSelect2Fields(formId);
                $(".form-control", forms[formId].form).val('');
                resetCheckboxFields(formId);
                resetRadioFields(formId);
                forms[formId].form.submit();
                if (typeof resetAddFn === 'function') {
                    // console.log('Call resetAddFn');
                    resetAddFn();
                }
            };
            return resetFn;
        }

        var resetSelect2Fields = function(formId) {
            $(".select2", forms[formId].form).each(function() {
                var $select2 = $(this);
                var defaultValue = $select2.data('placeholder') || '';
                $select2.select2('val', defaultValue).trigger('change.select2');
            });
        };

        var resetCheckboxFields = function(formId) {
            $('input[type=checkbox]', forms[formId].form).each(function() {
                var $checkbox = $(this);
                var defaultValue = $checkbox.data('default') || false;
                $checkbox.prop('checked', defaultValue);
            });
        };

        var resetRadioFields = function(formId) {
            var parentDefaults = {};
            $('input[type=radio]', forms[formId].form).each(function(){
                var radio = $(this);
                var parentId = radio.data('parent');
                if (!parentDefaults.hasOwnProperty(parentId)) {
                    parentDefaults[parentId] = $('#' + parentId).data('default');
                }
                if (radio.val() == parentDefaults[parentId]) {
                    radio.prop('checked', true);
                    return true;
                }
                radio.prop('checked', false);
            });
        };

        function addButton(formId, buttonId, clickFn) {
            if (!checkFormExists(formId)) {
                return false;
            }
            if (typeof clickFn !== 'function' ) {
                return false;
            }
            var $button = $('#' + buttonId, forms[formId].form);
            if ($button.length < 1) {
                return false;
            }
            buttons[buttonId] = {
                fn: clickFn
                ,formId: formId
            };
            return $button;
        }

        var addField = function(formId, fieldId) {
            if (!checkFormExists(formId)) {
                return false;
            }
            var input = _addFormField(formId, fieldId);
            if (!input) {
                return false;
            }
            return input;
        };

        var addRadioField = function(formId, fieldId) {
            var inputs = addField(formId, fieldId + ' input');
            return inputs;
        };

        var addThreeWaySwitch = function(formId, fieldId) {
            if (!checkFormExists(formId)) {
                return false;
            }
            $container = $('#' + fieldId);
            if ($container.length !== 1) {
                return false;
            }
            $('.js-threeway-filter', $container).each(function() {
                var $threewayFilter = $(this);
                var subFieldId = $threewayFilter.attr('id');
                addRadioField(formId, subFieldId);
            });
            $container.on('click', '.js-threeway-filter__controllabel', getHandleThreeWayFilterControlLabelClick($container));
        };

        var getHandleThreeWayFilterControlLabelClick = function() {
            return function(e) {
                var $clickedLabel = $(e.currentTarget);
                var $threewayFilter = $clickedLabel.closest('.js-threeway-filter');
                var $checkedRadio = $('input:checked', $threewayFilter);
                var value = parseInt($checkedRadio.val()|| $threewayFilter.data('default') - 1);
                var newValue = value + 1;
                if (newValue > 1) {
                    newValue = -1;
                }
                $checkedRadio.prop('checked', false);
                $(':radio[value="' + newValue +'"]', $threewayFilter).prop('checked', true);
            };
        };

        var addToCodeSelect2Field = function(formId, fieldId) {
            var input = addField(formId, fieldId);
            if (!input) {
                return false;
            }

            var theValues = [];
            $.each(input.data('values'), function (k, v) {
                theValues.push({
                    id: k,
                    text: v
                });
            });
            input.select2({
                data: theValues,
                /*createSearchChoice: function (term) {
                    return {
                        id: term,
                        text: term + ' (new)'
                    };
                }*/
            });
            return input;
        };

        var addDatepickerField = function(formId, fieldId, datePickerOptions) {
            datePickerOptions = datePickerOptions || options.defaultDatePickerOptions;
            if (!checkFormExists(formId)) {
                return false;
            }
            var input = _addFormField(formId, fieldId);
            if (!input) {
                return false;
            }
            return input.datepicker(datePickerOptions);
        };

        var addInputLabelSwitch = function(formId, fieldId, switchFn) {
            if (!checkFormExists(formId)) {
                return false;
            }
            if (typeof switchFn !== 'function') {
                return false;
            }
            var $field = _addFormField(formId, fieldId);
            if (!$field) {
                return false;
            }
            prepareSwitchField(formId, fieldId, $field);
            $field.on('keyup', getCodeSwitchFunction(formId, fieldId, switchFn));
        };

        var getCodeSwitchFunction = function (formId, fieldId, fn) {
            var theFunction = function (e) {
                var $label =  $('.js-filter-label--' + fieldId);
                var inputVal = forms[formId].fields[fieldId].val();
                if (fn(inputVal)) {
                    $label.text($label.data('switchlabel'));
                } else {
                    $label.text($label.data('orglabel'));
                }
            };
            return theFunction;
        };

        var prepareSwitchField = function (formId, fieldId) {
            $label = _addLabel(formId, fieldId);
            if (!$label) {
                return false;
            }
            var translationKey = $label.data('translationkey');
            var switchlabel = Translator.trans(translationKey + '-switch', {}, 'cms');

            if (!$label.data('orglabel')) {
                $label.data('orglabel', $label.text());
            }
            if (!$label.data('switchlabel')) {
                $label.data('switchlabel', switchlabel);
            }

            var placeholderText = forms[formId].fields[fieldId].attr('placeholder');
            forms[formId].fields[fieldId].attr('placeholder', placeholderText + ' or ' + switchlabel);
        };


        var getValues = function(formId) {

            var params = {};

            if (!checkFormExists(formId)) {
                return false;
            }

            for(var index in forms[formId].fields) {
                var $item = forms[formId].fields[index];
                $item.each(function(index){
                    var value;
                    $element = $(this);
                    var itemName = $element.prop('name');
                    if ($element.is(':checkbox')) {
                        if ($element.is(':checked')) {
                            value = $element.val() || true;
                            params[itemName] = value;
                        }
                    } else if ($element.is('[type=radio]')) {
                        if ($element.is(':checked')) {
                            value = $element.val();
                            params[itemName] = value;
                        }
                    } else {
                        value = $item.val();
                        if (value !== '') {
                            params[itemName] = $item.val();
                        }
                    }
                });
            }
            // console.log(params);
            return params;
        };

        var getStartValues = function(formId) {
            if (!checkFormExists(formId)) {
                return false;
            }
            var startvalues = false;
            // var $formWithStartvalues = $('.' + options.filterFormStartvaluesClass, forms[formId].form);
            if (forms[formId].form.hasClass(options.filterFormStartvaluesClass)) {
                startvalues = forms[formId].form.data('startvalues') || false;
            }
            return startvalues
        };

        function _addFormField(formId, fieldId) {
            if (!forms[formId].hasOwnProperty('fields')) {
                forms[formId].fields = {};
            }
            var theField = $('#' + fieldId, forms[formId].form);
            if (theField.length < 1) {
                return false;
            }
            if (forms[formId].fields.hasOwnProperty(fieldId)) {
                return forms[formId].fields[fieldId];
            }
            forms[formId].fields[fieldId] = theField;
            return theField;
        }

        function _addLabel(formId, fieldId) {
            if (!forms[formId].hasOwnProperty('labels')) {
                forms[formId].labels = {};
            }
            if (forms[formId].labels.hasOwnProperty(fieldId)) {
                return forms[formId].labels[fieldId];
            }
            var $label = $('.js-filter-label--' + fieldId);
            if ($label.length < 1) {
                return false;
            }
            forms[formId].labels[fieldId] = $label;
            return $label;
        }

        function checkFormExists(formId) {
            return forms.hasOwnProperty(formId);
        }

        function hideForm(formId) {
            if (!checkFormExists(formId)) {
                return false;
            }
            forms[formId].form.css('visibility','hidden');
            return true;
        }

        function showForm(formId) {
            if (!checkFormExists(formId)) {
                return false;
            }
            forms[formId].form.css('visibility','visible');
            return true;
        }

        return {
            init: init
            ,addField: addField
            ,addRadioField: addRadioField
            ,addToCodeSelect2Field: addToCodeSelect2Field
            ,addDatepickerField: addDatepickerField
            ,addFieldsBySelector: addFieldsBySelector
            ,addInputLabelSwitch: addInputLabelSwitch
            ,addThreeWaySwitch: addThreeWaySwitch
            ,addButton: addButton
            ,getValues: getValues
            ,getStartValues: getStartValues
            ,hideForm: hideForm
            ,showForm: showForm
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

