var StatisticController = (function(){
	var instance
		,statisticoverviewclassname = '.cmsstatistic--overview'
		,diffcontainerclassname = '.cmsstatistic--diffcontainer'
		,overviewDatatable = '#StatisticOverviewTable'
		,diffDatatable = '#Statisticdifftable'
		,loader;
	
	function createInstance() {
		
		var isInitDatatable = true; 

        /**
        * function creates/loads detail datatable.
        *
        * @param e Event    Jquery Event
        *
        * @return void  
        */
		var openDetailDataTable = function(e) {
			var btn = $(this),
                parent = btn.parent(),
                result;

            $(overviewDatatable).find('td').removeClass('selected');
            parent.addClass('selected');

			var tocode = btn.data('tocode');
			var lang = btn.data('lang');
      
			var params = {
				'TOCode' : tocode,
				'HotelDescriptionLanguage' : lang
			};
			data = prepareData(params);
			if(isInitDatatable) {
				result = initDatatable(data);
				isInitDatatable = false;
			} else {
				result = reloadDatatable(data);
			}
            result
                .done(function(){
                    var table = $(diffDatatable).DataTable(),
                        info = table.page.info(),
                        title = Translator.trans('statistic.table.title', {'totalcount':info.recordsTotal, 'language':lang.toUpperCase()}, 'cms');
                    $(diffcontainerclassname).find('.panel-heading').text(title);
                    $(diffcontainerclassname).show();
                    handlePanel(diffcontainerclassname, true);
                });
		};
				
		var handlePanel = function(selector, openPanel) {
			var panel = $(selector);
			
			var panelToogleClass = (openPanel)?'fa-chevron-up':'fa-chevron-down';
			if(panel.find('.panel-tools .fa').hasClass(panelToogleClass)){
				panel.find('.panel-tools .showhide').click();
			}
		};
		
        /**
        * function adds filter from filterform to existing filter parameters
        *
        * @param params object  current filter parameters
        *
        * @return void
        */
		var prepareData = function(params) {
			var dlcFilter = "";
			$.each($('#statistic-filter-form').serializeArray(),function(k,v){
				if(v.name == 'dlc'){
                    dlcFilter = v.value;
                }
			});
			params.dlc = dlcFilter;
			return params;
		};
        
		/**
        * function reloads the datatable and returns a promise object which will be resolved, if the datatable has been loaded
        * 
        * @param params array of filterparameters
        *
        * @return promise object
        */
		var reloadDatatable = function(params) {
            var result = new $.Deferred()
				,loaderTargetContainer = $('.content')
				,loaderId = loader.add(loaderTargetContainer);
			$(diffDatatable).DataTable()
				.ajax
				.url(Routing.generate('cms_statistic_diff_list', params))
				.load(function(){
                    result.resolve(true);
					loader.remove(loaderId, loaderTargetContainer);
				});
            return result.promise();
		};
		
        /**
        * function initializes the datatable and returns a promise object which will be resolved, if the datatable has been created.
        * Additionally adds EventListener to datatable.
        * 
        * @param params array of filterparameters
        *
        * @return promise object
        */
		var initDatatable = function(params) {
            var result = new $.Deferred()
				,loaderTargetContainer = $('.content')
				,loaderId = loader.add(loaderTargetContainer);

			$(diffDatatable)
				.on( 'init.dt', function () {
                    result.resolve(true);
					if (loaderId) {
						loader.remove(loaderId);
					}
				})
				.on('xhr.dt', function ( e, settings, data ) {
					if(e.currentTarget.clickedPaging) {
						if (loaderId) {
							loader.remove(loaderId);
						}
					}
					e.currentTarget.clickedPaging = false;
				})
				.on( 'page.dt', function(e, settings) {
					loaderTargetContainer = $(e.delegateTarget);
					loaderId = loader.add(loaderTargetContainer);
					e.currentTarget.clickedPaging = true;
				})
				.dataTable({
					ajax: {
						url: Routing.generate('cms_statistic_diff_list', params)
					},
					serverSide: true,
					lengthChange: false,
					searching: false,
					pagingType: 'simple_numbers',
					pageLength: 10,
					"order": [],
					columns: [
						{data: 'name'},
						{data: 'tocode'},
						{data: 'code'},	
						{data: 'key', sorting:false}
					],
					language: {
						paginate: {
							next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
							previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
						},
						info: Translator.trans('contentstore.producttable.info', {}, 'cms'),
						infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms'),
						emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
					},
                    fnDrawCallback: function(){
                        
                        var oSettings = this.fnSettings();
                        
                        if( oSettings.fnRecordsTotal() <= oSettings._iDisplayLength){
                            $(diffDatatable+'_paginate').hide();
                        }else{
                            $(diffDatatable+'_paginate').show();
                        }
                        //$(diffDatatable+'_info').html($(diffDatatable+'_info').text()+' ('+oSettings.fnRecordsTotal()+' Total Entries)');
                    }
				});
            return result.promise();
		};
		
        /**
        * function creates overviewlist 
        *
        * @param params object      filterparameters
        *
        * @return void
        */
		var reloadOverviewList = function(params){
			var loaderTargetContainer = $('.pw-tablebufferwrapper')
				,loaderId = loader.add(loaderTargetContainer);
			$.ajax(Routing.generate('cms_statistic_overviewlist_list', params))
				.done(function(data){
					$(overviewDatatable).find('tbody').html(data);
					loader.remove(loaderId, loaderTargetContainer);
				});
		};
		
        /**
        * function aggregates filter parameters and calls reloadOverviewList
        *
        * @param e jquery Event
        *
        * @return false
        */
		var loadTableByFilter = function(e) {
			var formFields = {};
			$.each($(e.currentTarget).serializeArray(), function(k,v){
				formFields[v.name] = v.value;
			});
			reloadOverviewList(formFields);
			$(diffcontainerclassname).hide();
			return false;
		};
		
		return {
			init: function() {
				loader = Loader.getInstance();
				reloadOverviewList({});
				$(statisticoverviewclassname)
					.on('click', 'table tr td a', openDetailDataTable )
					.on('submit', '#statistic-filter-form', loadTableByFilter );
			}
		};
	}
	return {
		getInstance: function() {
			if (!instance) {
				instance = new createInstance();
			}
			
			return instance;
		}
	};
})();

$(function() {
	if(0 < $('.js-init-statistic').length) {
		StatisticController.getInstance().init();
	}
});