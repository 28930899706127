(function() {
    var ctx = pwNamespace('PEAKWORK.STORECMS.GIATA.GEODATA.COMPONENTS');
    this.GiataGeoDataSearchListController = function () {

        this.instance = null;
        this.searchComponent = null;
        this.defaults = {
            type:''
            ,routeId:''
            ,component:''
            ,minLength:0
            ,start:0
            ,length: 5
            ,data: ''
        };

        if (arguments[0] && typeof arguments[0] === "object") {
            this.options = extendDefaults(this.defaults, arguments[0]);
        }

        init.call(this, this.options || this.defaults);
    };

    GiataGeoDataSearchListController.prototype.reset = function(data) {
        this.options.data = data;
        init.call(this, this.options);
    };

    GiataGeoDataSearchListController.prototype.deactivate = function(data) {
        this.options.data = data;
        this.searchComponent.reset();
    };

    var init = function(options) {
        if(this.searchComponent) {
            this.searchComponent.init(options.data);
        } else {
            this.searchComponent = getSearchComponent(options.component, { data:options.data });
        }
        if(this.instance) {
            this.instance.element.autocomplete("destroy");
        }
        this.instance = initAutocompletion(options, this.searchComponent.getInputId());
        addAutocompleteEvents(this.instance, this.searchComponent);
        renderItem(this.instance, this.searchComponent);
        renderMenu(this.instance, this.searchComponent);
        if(this.instance.element) {
            this.instance.element.autocomplete('search', '');
        }
    };


    var getSearchComponent = function(component, data) {
        if (!ctx[component].prototype instanceof ctx['AbstractGiataSearchComponent']) {
            throw new Error(component + 'must be an instance of AbstractGiataSearchComponent');
        }
        return new ctx[component](data);
    };

    var extendDefaults = function(source, properties) {
        var property;
        for (property in properties) {
            if (properties.hasOwnProperty(property)) {
                source[property] = properties[property];
            }
        }
        return source;
    };

    var initAutocompletion = function(options, inputId) {
        return  $( inputId ).autocomplete({
            source: function( request, response ) {
                $.ajax({
                    url: Routing.generate(options.routeId, { type: options.type }),
                    dataType: "json",
                    data: {
                        term: request.term
                        ,start: options.start
                        ,length: options.length
                        ,data: options.data
                    },
                    beforeSend: function(e, xhr, options) {
                        var loader = Loader.getInstance();
                        e.loaderKey = "geoAutoComplete";
                        loader.startLoader(e.loaderKey, $( inputId).parents(".nestedlist"));
                    },
                    success: function( data ) {
                        response( data );
                    }
                });
            },
            minLength: options.minLength
            ,messages: {
                noResults: ''
            }
        }).autocomplete( "instance" );
    };

    var addAutocompleteEvents = function(instance, searchComponent) {
        searchComponent.addAutocompleteEvents(instance);
    };
    
    var renderItem = function(instance, searchComponent) {
        instance._renderItem = function( ul, item, index ){
            return searchComponent.renderItem( ul, item, index );
        }
    };

    var renderMenu = function(instance, searchComponent) {
        instance._renderMenu = function( ul, items ){
            return searchComponent.renderMenu( this, ul, items );
        }
    };
})();