(function() {
    var ctx = pwNamespace('PEAKWORK.CMS.IMAGE');
    var internalSupplier = getInternalSupplier();
    ctx.GroupList = function(namespace, options) {
        this.namespace = namespace || '';
        this.loader = {};
        this.data = {};
        this.currentGroup = false;
        this.$groupListContainer = false;

        this.defaults = {
            jsclass: {
                groupListContainer: 'js-imagepresenter__header'
                ,groupList: 'js-grouplist'
                ,groupListItem: 'js-grouplist__item'
                ,groupListItemValue: 'js-grouplistitem__group'
                ,groupListItemCount: 'js-grouplistitem__groupcount'
                ,groupButton: 'js-grouplist__button'
            }
            ,dataAttr: {
                groupListItemActive: 'active'
                ,groupListItemKey: 'groupkey'
                ,groupListIsAll: 'isall'
            }
            ,propKeys: {
                groupValues: {
                    groupList: 'suppliers'
                    ,count: 'count'
                    ,supplier: 'supplier'
                    ,code: 'code'
                    ,key: 'key'
                    ,images: 'images'
                    ,isall: 'isall'
                }
            }
            ,events: {
               groupchange: this.namespace === '' ? 'presentergroupchange' : 'presentergroupchange.' + this.namespace
               ,datachange: this.namespace === '' ? 'presenterdatachange' : 'presenterdatachange.' + this.namespace
               ,addImage: this.namespace === '' ? 'imagelistaddimage' : 'imagelistaddimage.' + this.namespace
            }
            ,listOptions: false
            ,groupListContainerId: 'imagepresenter__grouplist'
            ,groupListTemplate: ''
                + '<li class="grouplist__item js-grouplist__item" data-active="" data-groupkey="" data-isall="">'
                + '<button class="btn btn-default btn-xs grouplist__button js-grouplist__button">'
                + '<span class="grouplistitem__group js-grouplistitem__group"></span> (<span class="grouplistitem__groupcount js-grouplistitem__groupcount"></span>)'
                + '</button>'
                + '</li>'
            ,values: false
        };

        // Create options by extending defaults with the passed in arguments
        if (options && typeof options === "object") {
            this.options = $.extend(true, {}, this.defaults, options);
        }
        return init.call(this);
    };

    ctx.GroupList.prototype.constructor = ctx.GroupList;

    function init() {
        this.loader = Loader.getInstance();

        if (!this.initGroupListContainer()
            || !this.initGroupList()) {
            return false;
        }
        this.setInitialData();
        this.addHandlers();
        this.cleanup();
        return this.getExports();
    }

    ctx.GroupList.prototype.cleanup = function() {
        var addImageContainer = $('#content__detailsimagescontainer-addImage');
        if(addImageContainer.length > 0) {
            addImageContainer.remove();
        }
    };

    ctx.GroupList.prototype.initGroupListContainer = function() {
        var $groupListContainer = $('#' + this.options.groupListContainerId);
        if ($groupListContainer.length < 1) {
            return false;
        }
        this.$groupListContainer = $groupListContainer;
        return true;
    };

    ctx.GroupList.prototype.addHandlers = function() {
        var listenerSelector = '.' + this.options.jsclass.groupButton;
        this.$groupListContainer.on('click.' + this.namespace, listenerSelector, this.getHandleClick(this));
        $.subscribe(this.options.events.datachange, this.getHandleDataChange(this));
    };

    ctx.GroupList.prototype.getHandleDataChange = function(self) {
        return function(e, data) {
            self.data = data;
            self.updateGroupList(data);
            self.activateGroup();

        }
    };

    ctx.GroupList.prototype.getHandleClick = function(self) {
        return function(e) {
            var $target = $(e.currentTarget);
            if ($target.hasClass(self.options.jsclass.groupButton)) {
                self.handleImageGroupClick.call(self, $target);
            }
        }
    };

    ctx.GroupList.prototype.handleImageGroupClick = function($target) {
        this.groupList.reIndex();
        var $el = $target.closest('.' + this.options.jsclass.groupListItem);
        var group = $el.data(this.options.dataAttr.groupListItemKey) || false;
        if (!group || group === this.currentGroup) {
            return;
        }
        this.activateGroup(group);
        this.addDropField();
        this.addAdditionalImageButton();
    };

    ctx.GroupList.prototype.addAdditionalImageButton = function() {
        var addImageContainer = $('#content__detailsimagescontainer-addImage');
        if (this.currentGroup == internalSupplier) {
            if (addImageContainer.length == 0) {
                $('#contentimagespresenter__imagelist').append(
                    $('<div/>', {
                        'id':'content__detailsimagescontainer-addImage'
                        ,'class':'form-inline content__detailsimagescontainer-addImage'
                    })
                    .append(
                        $('<form/>', {
                            'class':'content__detailsimagescontainer-addImageform'
                        })
                            .prepend(
                                $('<label/>', {
                                    'for':'addimageurl'
                                    ,'text': Translator.trans('def.images.addimage.label', {}, 'cms')
                                    ,'class':'content__detailsimagescontainer-addImagelabel'})
                            )
                            .append(
                                $('<input/>', {
                                    'class':'form-control content__detailsimagescontainer-addImagefield js-detailsimagescontainer-addImagefield'
                                    ,'name':'addimageurl'
                                    ,'placeholder': Translator.trans('def.images.addimage.placeholder', {}, 'cms')
                                })
                            )
                            .append(
                                $('<button/>', {
                                    'class':'btn btn-default content__detailsimagescontainer-addImagebutton js-detailsimagescontainer-addImagebutton'
                                    ,'type': 'submit'
                                }).append($('<span/>', {'class':'fa fa-plus'}))
                        )
                    )
                );
                this.initAddImageFormValidation();
            }
        }
        if (this.currentGroup !== internalSupplier) {
            addImageContainer.remove();
        }
    };

    ctx.GroupList.prototype.initAddImageFormValidation = function() {
        var self = this;
        $(".content__detailsimagescontainer-addImageform").validate({
            rules: {
                addimageurl: {
                    required: true,
                    url: true
                }
            }
            ,submitHandler: function(form) {
                var $form = $(form)
                    ,imageUrl = $form.find('input').val();
                $.publish(self.options.events.addImage, {'imageUrl':imageUrl});
                return false;
            }
            ,errorPlacement: function(error, element) {
                $( element )
                    .closest( "form" )
                    .find( "label[for='" + element.attr( "name" ) + "']" )
                    .append( error );
            },
            errorElement: "span"
        });
    };

    ctx.GroupList.prototype.addDropField = function() {
        var panelContainer = $(this.groupList.list).parents('#contentimagespresenter')
            ,groupId = panelContainer.data('groupid')
            ,servertype = panelContainer.data('servertype')
            ,dropField = panelContainer.find('#content__detailsimagescontainer-dropfield');

        if (this.isDropFieldVisible(groupId, servertype)) {
            if (dropField.length == 0) {
                $('#contentimagespresenter__imagelist').append(
                    $('<div/>', {
                        'id':'content__detailsimagescontainer-dropfield'
                        ,'class':'ui-widget-content ui-state-default content__detailsimagescontainer-dropfield'
                    }).append(
                        $('<p/>', {
                            'class':'content__detailsimagescontainer-dropfieldtitle'
                            ,'text': Translator.trans('def.images.dropfield.addtoint', {}, 'cms')
                        }).prepend(
                            $('<span/>', {'class':'fa fa-arrow-down'})
                        )
                    )
                );
            }
        } else {
            dropField.remove();
        }
    };

    ctx.GroupList.prototype.isDropFieldVisible = function(groupId, servertype) {
        return groupId && servertype !== 'central' && this.currentGroup !== internalSupplier;
    };

    ctx.GroupList.prototype.initGroupList = function() {
        var groupListOptions = this.options.listOptions || {
            valueNames: [
                this.options.jsclass.groupListItemValue
                ,this.options.jsclass.groupListItemCount
                ,{data: [this.options.dataAttr.groupListItemActive]}
                ,{data: [this.options.dataAttr.groupListItemKey]}
                ,{data: [this.options.dataAttr.groupListIsAll]}
            ]
            ,listClass: this.options.jsclass.groupList
            ,item: this.options.groupListTemplate
        };
        this.groupList = new List(this.options.groupListContainerId, groupListOptions);
        return !!this.groupList;
    };

    ctx.GroupList.prototype.getExports = function() {
        return {

        };
    };

    ctx.GroupList.prototype.setInitialData = function() {
        if (this.options.values) {
            this.data = this.options.values;
            return this.updateGroupList(this.options.values);
        }
        return false;
    };

    ctx.GroupList.prototype.setCurrentGroup = function(group, publish) {
        group = group || false;
        if (group) {
            this.currentGroup = group;
            if (publish) {
                $.publish(this.options.events.groupchange, { group: group });
            }
            return true;
        }
        return false;
    };

    ctx.GroupList.prototype.activateGroup = function(group) {
        if (!group && this.groupList.items.length === 0 ) {
            return false;
        }
        group = group || this.groupList.items[0].values()[this.options.dataAttr.groupListItemKey];
        var oldActive = this.groupList.get(this.options.dataAttr.groupListItemActive, true);
        if (oldActive.length > 0) {
            var oldActiveValues = oldActive[0].values();
            oldActiveValues[this.options.dataAttr.groupListItemActive] = false;
            oldActive[0].values(oldActiveValues);
        } else {
            $(this.groupList.list).children().each(function() {
                $(this).attr('data-active', false);
            });
        }
        var newActive = this.groupList.get(this.options.dataAttr.groupListItemKey, group);
        if (newActive.length > 0) {
            newActive = newActive[0];
            newActiveValues = newActive.values();
            newActiveValues[this.options.dataAttr.groupListItemActive] = true;
            newActive.values(newActiveValues);
            this.setCurrentGroup(group, true);
            return true;
        }
        return false;
    };

    ctx.GroupList.prototype.updateGroupList = function(data) {
        var self = this;
        data = data || false;

        if (!data
            || !data.hasOwnProperty(this.options.propKeys.groupValues.groupList)
            || data[this.options.propKeys.groupValues.groupList].length === 0) {
            return false;
        }
        data = data[this.options.propKeys.groupValues.groupList];
        var groups = Object.keys(data);
        var currentGroup = this.currentGroup || groups[0];

        this.groupList.clear();
        groups.forEach(function(element, index, array) {
            var item = {};

            if (!data[element].hasOwnProperty(self.options.propKeys.groupValues.images) && !data[element].isall) {
                return false;
            }
            if (currentGroup === element && self.currentGroup !== element) {
                self.setCurrentGroup.call(self, element);
            }
            var imagesCount = data[element][self.options.propKeys.groupValues.images]
                ? data[element][self.options.propKeys.groupValues.images].length : 0;

            var contentCount = data[element].hasOwnProperty(self.options.propKeys.groupValues.count)
                ? data[element][self.options.propKeys.groupValues.count]
                : imagesCount;

            var code = data[element].hasOwnProperty(self.options.propKeys.groupValues.code)
                ? data[element][self.options.propKeys.groupValues.code]
                : '';

            var supplier = (data[element].hasOwnProperty(self.options.propKeys.groupValues.supplier)
                && data[element][self.options.propKeys.groupValues.supplier])
                ? data[element][self.options.propKeys.groupValues.supplier]
                : false;

            if (!supplier) {
                supplier = (data[element].hasOwnProperty(self.options.propKeys.groupValues.key)
                && data[element][self.options.propKeys.groupValues.key])
                    ? data[element][self.options.propKeys.groupValues.key]
                    : Translator.trans('def.term.unknown', {}, 'cms');
            }
            var isall = data[element].hasOwnProperty(self.options.propKeys.groupValues.isall)
                ? data[element][self.options.propKeys.groupValues.isall]
                : '';

            var active = (currentGroup && currentGroup === element);
            item[self.options.jsclass.groupListItemValue] = supplier + ' ' + code;
            item[self.options.jsclass.groupListItemCount] = contentCount;
            item[self.options.dataAttr.groupListItemKey] = element;
            item[self.options.dataAttr.groupListItemActive] = active;
            item[self.options.dataAttr.groupListIsAll] = isall;
            self.groupList.add(item);
        });

        this.addDropField();
        return true;
    }
})();
