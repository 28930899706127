var GoalMatchingHistoryController = (function() {

    var instance;

    function createInstance() {

        var panelContainer;

        var addHandlers = function() {
            panelContainer = $('.js-goalmatchinghistory-panel');
            panelContainer.on('click', '.showhide', function(e) {
                let btn = $(this);
                if (btn.find('.fa').hasClass('fa-chevron-down')) {
                    loadDatatable();
                }
            });
        };

        var loadDatatable = function() {
            let productCode = panelContainer.data('productcode');
            let supplier = panelContainer.data('supplier');
            let table = panelContainer.find('#cmsgoalmatchinghistorytable').DataTable();
            panelContainer.show();
            if(table) {
                table.destroy();
            }
            if (productCode && supplier) {
                table = $('#cmsgoalmatchinghistorytable'),
                loader = Loader.getInstance();
                table
                .on( 'init.dt', function () {
                    $('.cmscontentstore--goalmatchinghistory-btn').click();
                } )
                .dataTable({
                    ajax: {
                        url: Routing.generate('cms_contentstore_goalmatching_history', {
                            'productCode': productCode,
                            'supplier': supplier
                        })
                        ,beforeSend: function(e, xhr, options) {
                            e.loaderKey = loader.getGeneratedKeyByPrefix("goalHistory");
                            loader.startLoader(e.loaderKey, table);
                        }
                    },
                    serverSide: true,
                    lengthChange: true,
                    searching: false,
                    pagingType: 'simple_numbers',
                    pageLength: 10,
                    order: [],
                    columns: [
                        {data: 'goalId', name:'goalId', sortable: false},
                        {data: 'productCode', name:'productCode', sortable: false},
                        {data: 'supplierCode', name:'supplierCode', sortable: false},
                        {data: 'matchedAt', name:'matchedAt', sortable: true},
                        {data: function(row, type, set, meta) {
                            if (row.matchingSource === 'User') {
                                return $('<span/>', {
                                    'class': 'fa fa-user matchingsource-icon',
                                    'aria-hidden': 'true',
                                    'data-toggle': 'tooltip',
                                    'data-original-title': Translator.trans('contentstore.goalmatchinghistorytable.tooltips.user', {}, 'cms')
                                })[0].outerHTML;
                            } else if (row.matchingSource === 'MatchingService') {
                                return $('<span/>', {
                                    'class': 'fa fa-gears matchingsource-icon',
                                    'aria-hidden': 'true',
                                    'data-toggle': 'tooltip',
                                    'data-original-title': Translator.trans('contentstore.goalmatchinghistorytable.tooltips.matchingservice', {}, 'cms')
                                })[0].outerHTML;
                            } else if (row.matchingSource === 'Supplier') {
                                return $('<span/>', {
                                    'class': 'fa fa-plane matchingsource-icon',
                                    'aria-hidden': 'true',
                                    'data-toggle': 'tooltip',
                                    'data-original-title': Translator.trans('contentstore.goalmatchinghistorytable.tooltips.supplier', {}, 'cms')
                                })[0].outerHTML;
                            } else if (row.matchingSource === 'Roundtrip') {
                                return $('<span/>', {
                                    'class': 'fa fa-refresh  matchingsource-icon',
                                    'aria-hidden': 'true',
                                    'data-toggle': 'tooltip',
                                    'data-original-title': Translator.trans('contentstore.goalmatchinghistorytable.tooltips.roundtrip', {}, 'cms')
                                })[0].outerHTML;
                            }
                        }, 'className': 'matchingsource-wrapper', sortable: false}
                    ],
                    language: {
                        paginate: {
                            next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
                            previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
                        },
                        thousands: '.',
                        info: Translator.trans('contentstore.producttable.info', {}, 'cms'),
                        infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms'),
                        emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
                    },
                });
            }
        };

        var destroyTable = function() {
            if (panelContainer) {
                panelContainer.hide();
                let table = panelContainer.find('#cmsgoalmatchinghistorytable').DataTable();
                if(table) {
                    table.destroy();
                }
            }
        };

        return {
            init: function() {
                addHandlers();
            }
            ,loadDatatable: function() {
                loadDatatable();
            }
            ,destroyTable: function() {
                destroyTable();
            }
        };
    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();