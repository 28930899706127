/**
 *
 * RegionSearchComponent
 * extends AbstractGiataSearchComponent
 *
 */
(function(){
var ctx = pwNamespace('PEAKWORK.STORECMS.GIATA.GEODATA.COMPONENTS');
ctx.RegionSearchComponent = function() {
    var opts = {
        wrapperClassName: '#GiataGeodataRegionTab-1'
        ,inputId: '#GiataSearchRegionInput'
        ,chain:['city']
    };
    if (arguments[0] && typeof arguments[0] === "object") {
        opts = this.extendDefaults(opts, arguments[0]);
    }

    ctx.AbstractGiataSearchComponent.call(this, opts);
}
ctx.RegionSearchComponent.prototype = $.extend(
    Object.create(ctx.AbstractGiataSearchComponent.prototype), {
        constructor : ctx.RegionSearchComponent
        ,onClickItem : function(obj, e) {
            ctx.AbstractGiataSearchComponent.prototype.onClickItem.call(this, obj, e);
            var target = $(e.currentTarget);
            this.initNewSearchListController('city', 'cms_giata_geodata_list', 'CitySearchComponent',{
                countrycode: target.data('countrycode')
                ,regionid: target.data('regionid')
            });
            $('.giatageo__detail').html('');
        }
        ,resetSearchList: function() {
            var wrapper = $(this.options.wrapperClassName)
                ,nestableContainer = wrapper.find('.dd')
                ,ddlist = nestableContainer.find('.dd-list');
            nestableContainer.find('.resultcount').html(
                Translator.trans(
                    'basicdata.geodata.overview.searchregion.resultcount',
                    {
                        'count':0
                        ,'total':0
                    },
                    'cms'
                )
            );
            ddlist.html('');
            ddlist.attr('data-countrycode', '');
        }
        ,renderItem : function ( ul, item, index ) {
            var li = $( "<li>", {
                'class': "dd-item  basicdata-geodata-region-list"
                ,'data-id': item._attributes.regionname
            })
                .append(
                    $('<div/>', {
                        'class':'dd-handle'
                        ,'data-countrycode': ul.attr('data-countrycode')
                        ,'data-regionid': item._attributes.regionid
                    })
                    .append(' '+item._attributes.regionname)
                    //.append(this.addCostumButton('remove', 'country', 'right'))
                ).appendTo( ul );
            return li;
        }
        ,renderMenu : function( acObj, ul, items ) {
            var that = acObj
                ,ddList = $('#nestable-geodata-region .dd-list')
                ,resultCountContainer = ddList.parent().find('.resultcount')
                ,regions = items[0]
                ,count = (items[1].value)?items[1].value:0
                ,regionsCount = Object.keys(regions).length;
            ul.addClass('hide');
            resultCountContainer.html(
                Translator.trans(
                    'basicdata.geodata.overview.searchregion.resultcount',
                    {
                        'count':regionsCount
                        ,'total':count
                    },
                    'cms'
                )
            );

            if(ddList.length > 0) {
                ddList.html('');
            } else {
                $('<ol/>', { 'class':'dd-list'}).appendTo($('#nestable-geodata-region'));
            }
            $.each( regions, function( index, item ) {
                that._renderItemData( ddList, item, index );
            });
            this.addButtonEvents(ddList);
        }
        ,addAutocompleteEvents : function(instance) {
            var self = this;
            if (instance.element) {
                var container = instance.element;
                container.on( "autocompleteopen", function(event, ui){
                    self.onOpen(self, event, ui);
                } );
            }
        }
        ,onOpen : function(obj, event, ui) {
            obj.deactivateChildren();
        }
    }
);
})();
