var StatisticGoalkeeperExportsController = (function() {

    var instance
        ,loader
        ,filterInstance
        ,options = {
            filterFormId: 'statistic-goalkeeperexports-filter-form'
            ,filterFromDate: 'statistic-goalkeeperexports-filter-from'
            ,filterToDate: 'statistic-goalkeeperexports-filter-to'
            ,filterSupplier: 'statistic-goalkeeperexports-filter-supplier'
            ,filterSuccessState: 'statistic-goalkeeperexports-filter-success'
        };

    function createInstance() {

        var initFilter = function() {
            var datpickerOptions = {
                format: 'dd.mm.yyyy'
            };

            filterInstance = FilterForm.getInstance();
            filterInstance.init({
                formid: options.filterFormId
                ,submit: onFormSubmitHandler
            });
            filterInstance.addDatepickerField(options.filterFormId, options.filterFromDate, datpickerOptions);
            filterInstance.addDatepickerField(options.filterFormId, options.filterToDate, datpickerOptions);
            filterInstance.addField(options.filterFormId, options.filterSupplier);
            filterInstance.addThreeWaySwitch(options.filterFormId, options.filterSuccessState);
        };

        var onFormSubmitHandler = function(e) {
            e.preventDefault();
            var params = filterInstance.getValues(options.filterFormId)
                ,tableAction = "cms_statistic_export_list"
                ,tableId = "#cmsexportstable"
                ,table =$(tableId).DataTable();

            loader.startLoader("filter", $('.cmsstatistic--goalkeeperexports--filter'), ["goalkeeperExportsTable"]);

            table
                .ajax
                .url(Routing.generate(tableAction, params))
                .load(function() {
                });
            return false;
        };

        var onFormResetHandler = function() {
            $("#statistic-goalkeeperexports-filter-form")[0].reset();
            $("#FilterExportsFormSubmit").trigger('click');
        };

        return {
            init: function() {
                loader = Loader.getInstance();
                initFilter();
                statisticgoalkeeperexportstable_ini();
            }
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();

$(function() {
    if ($('.js-init-statisticgoalkeeperexports').length > 0) {
        StatisticGoalkeeperExportsController.getInstance().init();
    }

});

