var GoalMapController = (function(){
    var mapsCtx = pwNamespace('PEAKWORK.BASIC.MAP');
    var instance
        ,loader;

    function createInstance() {

        var map
            ,mapDrawWrapper;

        var addHandlers = function () {
            map = $('.flexmap__map');
        };

        var onCheckPosition = function(e) {
            var form = $('.js-goalmap-modifycoordsform')
                ,inputLat = form.find('[name="latitude"]')
                ,inputLng = form.find('[name="longitude"]')
                ,data = handleErrors(form);
            if (data) {
                mapDrawWrapper.positionMarker(1, 'center', {
                    lat: parseFloat(inputLat.val()), lng: parseFloat(inputLng.val())
                });
                mapDrawWrapper.zoomToSingleMarker(10, {
                    lat: parseFloat(inputLat.val()), lng: parseFloat(inputLng.val())
                });
            }
            return false;
        };

        var onResetForm = function(e) {
            var form = $('.js-goalmap-modifycoordsform')
                ,inputLat = form.find('[name="latitude"]')
                ,inputLng = form.find('[name="longitude"]');
            inputLat.val(inputLat.data('orglat'));
            inputLng.val(inputLng.data('orglng'));
            resetErrorFields(form);
            mapDrawWrapper.positionMarker(1, 'center', {
                lat:parseFloat(inputLat.data('orglat')), lng:parseFloat(inputLng.data('orglng'))});
            mapDrawWrapper.zoomToSingleMarker(5, {
                lat:parseFloat(inputLat.data('orglat')), lng:parseFloat(inputLng.data('orglng'))});
            return false;
        };

        var onSaveForm = function(e) {
            e.stopImmediatePropagation();
            e.preventDefault();
            var form = $(this)
                ,fullScreenContainer = $('#FullScreenMap')
                ,parent = (fullScreenContainer.hasClass('fullscreenmap--active'))
                    ?fullScreenContainer:form.parents('.js-goaldetail-map')
                ,loaderTargetContainer = $('.cmscontentstore--goaldetail')
                ,successContainer = form.find('.goalmap-modifycoordsform-success')
                ,params = handleErrors(form)
                ,buttonCheckPosition = form.find('.btn-checkPosition')
                ,buttonReset = form.find('.btn-reset')
                ,buttonSave = form.find('.btn-save');
            buttonCheckPosition.attr('disabled', true);
            buttonReset.attr('disabled', true);
            buttonSave.attr('disabled', true);
            if (data) {
                params.goalid = form.data('goalid');
                $.ajax({
                    url: Routing.generate("cms_goal_save_goal", {data: params})
                    ,beforeSend: function(e, xhr, options) {
                        e.loaderKey = loader.getGeneratedKeyByPrefix("saveGeoData");
                        loader.startLoader(e.loaderKey, parent);
                    }
                })
                .done(function(data) {
                    if (data.error) {
                        handleRequestError(form);
                        buttonCheckPosition.removeAttr('disabled');
                        buttonReset.removeAttr('disabled');
                        buttonSave.removeAttr('disabled');
                    } else {
                        successContainer.slideDown(1000, function() {
                            var container = $(this);
                            setTimeout(function() {
                                container.slideUp(500, function() {
                                    GoalController.getInstance().loadGoalDetailPageByKey(
                                        params.goalid
                                        ,loaderTargetContainer
                                        ,false
                                    );
                                    if (fullScreenContainer.hasClass('fullscreenmap--active')) {
                                        buttonCheckPosition.removeAttr('disabled');
                                        buttonReset.removeAttr('disabled');
                                        buttonSave.removeAttr('disabled');
                                        fullScreenContainer.find('button.fullscreen').click();
                                    }
                                });
                            }, 1000);
                        });
                    }
                })
                .fail(function(data) {
                    handleRequestError(form);
                    buttonCheckPosition.removeAttr('disabled');
                    buttonReset.removeAttr('disabled');
                    buttonSave.removeAttr('disabled');
                });
            }
            return false;
        };

        var handleRequestError = function(form) {
            var errorContainer = form.find('.goalmap-modifycoordsform-error')
                ,errorTable = form.find('.goalmap-modifycoordsform-errortable')
            addErrorMessage(
                errorTable
                ,Translator.trans('goal.detail.map.modifycoords.error.requestfailed', {}, 'cms')
            );
            errorContainer.slideDown(1000, function() {
                var container = $(this);
                setTimeout(function() {
                    container.slideUp(1000);
                }, 2000);
            });
        };

        var resetErrorFields = function(form) {
            var errorContainer = form.find('.goalmap-modifycoordsform-error')
                ,errorTable = form.find('.goalmap-modifycoordsform-errortable')
                ,inputLat = form.find('[name="latitude"]')
                ,inputLng = form.find('[name="longitude"]');
            errorContainer.hide();
            errorTable.html(null);
            inputLat.removeClass('goalmap-modifycoordsform-fielderror');
            inputLng.removeClass('goalmap-modifycoordsform-fielderror');
        };

        var handleErrors = function(form) {
            var formData = form.serializeArray()
                ,errorContainer = form.find('.goalmap-modifycoordsform-error')
                ,errorTable = form.find('.goalmap-modifycoordsform-errortable')
                ,data = {}
                ,errors = [];
            resetErrorFields(form);
            $.each(formData, function(k,v) {
                var input = form.find('[name="'+v.name+'"]')
                    ,label = input.prev('label');
                if (v.value === '') {
                    errors.push({
                        'inputfield':v.name
                        ,'message':Translator.trans('goal.detail.map.modifycoords.error.empty', {'field':label.text()}, 'cms')
                    });
                } else if (!checkIfValidCoord(v.value)) {
                    errors.push({
                        'inputfield':v.name
                        ,'message':Translator.trans('goal.detail.map.modifycoords.error.notvalid', {'field':label.text()}, 'cms')
                    });
                }
                if (errors.length == 0) {
                    data[v.name] = v.value;
                }
            });
            if (errors.length !== 0) {
                errorContainer.show();
                $.each(errors, function(k,v) {
                    var input = form.find('[name="'+v.inputfield+'"]');
                    input.addClass('goalmap-modifycoordsform-fielderror');
                    addErrorMessage(errorTable, v.message);
                });
                return null;
            }
            return data;
        };

        var addErrorMessage = function (errorContainer, message) {
            var container =
                    $('<div/>', {
                        'class':'goalmap-modifycoordsform-errorcontent'
                        ,'text': ' ' + message
                    })
                    .prepend(
                        $('<span/>', {'class':'fa fa-exclamation-triangle'})
                    );
            errorContainer.append(container);
        };

        var checkIfValidCoord = function(str) {
            var regex = /^[+-]?\d+(\.\d+)?$/
                ,exp = new RegExp(regex);
            return str.match(exp);
        };

        var initGoalMap = function(data) {
            var options = map.data('mapoptions');
            options['centerMarkerOptions']['pwOnDragendFn'] = onDragMarker;
            options['center'] = [parseFloat(options.latitude), parseFloat(options.longitude)];
            options['data'] = data;

            mapDrawWrapper = new mapsCtx.MapDrawWrapper(options);
            $('body').on('click', '.js-goalmap-modifycoordsform .form-group .btn-reset', onResetForm);
            $('body').on('submit', '.js-goalmap-modifycoordsform', onSaveForm);
            $('body').on('click', '.js-goalmap-modifycoordsform .form-group .btn-checkPosition', onCheckPosition);
        };

        var onDragMarker = function(e) {
            var latLong = e.target.getLatLng()
                ,form = $('.js-goalmap-modifycoordsform')
                ,inputLat = form.find('[name="latitude"]')
                ,inputLng = form.find('[name="longitude"]');
            inputLat.val(latLong.lat);
            inputLng.val(latLong.lng);
        };

        var initFilter = function() {
        };

        return {
            init: function(data) {
                loader =  Loader.getInstance();
                addHandlers();
                initGoalMap(data);
                initFilter();
            }
        };
    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();










