var GiataMapController = (function(){
    var mapsCtx = pwNamespace('PEAKWORK.BASIC.MAP');
    var instance;

    function createInstance() {

        var map;

        var addHandlers = function () {
            map = $('.flexmap__map');
        };


        var initGoalMap = function() {
            var options = map.data('mapoptions');
            //options['center'] = new google.maps.LatLng(parseFloat(options.latitude), parseFloat(options.longitude));
            options['center'] = [parseFloat(options.latitude), parseFloat(options.longitude)];
                options['data'] = data;
            new mapsCtx.MapDrawWrapper(options);
        };


        var initFilter = function() {
        }

        return {
            init: function() {
                addHandlers();
                initGoalMap();
                initFilter();
            }
        };
    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();










