/**
 * Manages Twin View/New/Edit Text Field Panel and respective options
 *
 */
var RoomsController = (function(){

    var instance;

    var $container,
        $roomList,
        $langCount,
        $imgCount,
        $langList,
        $imgList,
        $roomText,
        contentKey,
        contentSupplier,
        contentProductCode,
        langList,
        roomList,
        imageList,
        $imageDetailImage,
        $imageDetailText
        ,loader
        ,imageData = false;

    var o = {
        parmCdnBaseUrl: 'https://cdn1.peakwork.com/'
        ,parmPreviewImageSize: 100
        ,requestRoomLanguages: 'cms_contentstore_requestroomlanguages'
        ,requestRoomImages: 'cms_contentstore_requestroomimages'
        ,containerJsClass: 'js-content__rooms'
        ,roomId: 'roomtext__rooms'
        ,roomListClass: 'roomtext__roomlist'
        ,roomListJsClass: 'js-roomtext__roomlist'
        ,roomListItemJsClass: 'js-roomtext__roomlistitem'
        ,roomListItemActiveClass: 'roomtext__roomlistitem--active'
        ,langId: 'roomtext__langs'
        ,roomListTemplate: '<li class="roomtext__roomlistitem js-roomtext__roomlistitem" data-roomname="" data-roomkey=""></li>'
        ,langLangCountClass: 'js-roomtext__langcount'
        ,langListClass: 'roomtext__langlist'
        ,langListJsClass: 'js-roomtext__langlist'
        ,langListItemJsClass: 'js-roomtext__langlistitem'
        ,langlistItemCodeClass: 'langlist__code'
        ,langListTemplate: '<li class="roomtext__langlistitem js-roomtext__langlistitem" data-lang="" data-roomkey="" data-active=""><span class="langlist__code"></span></li>'
        ,roomTextJsClass: 'js-roomtext__detail'
        ,hiddenClass: 'content__rooms--hidden'
        ,dataContentKey: 'contentkey'
        ,dataContentSupplier: 'contentsupplier'
        ,dataContentProductCode: 'contentproductcode'
        ,dataRoomId: 'roomid'
        ,dataActive: 'active'
        ,dataLang: 'lang'
        ,dataRoomKey: 'roomkey'
        ,dataRoomName: 'roomname'
        ,requestLanguagesType: 'languages'
        ,requestDescriptionsType: 'description'
        ,requestImagesType: 'images'
        ,imagesId: 'roomtext__images'
        ,imageCountJsClass: 'js-roomtext__imgcount'
        ,imageListJsClass: 'js-roomtext__imagelist'
        ,imageListItemJsClass: 'js-roomtext__imagelistitem'
        ,imageListPreviewJsClass: 'js-roomtext__preview'
        ,imageListPreviewImageNoneClass: 'roomtext__previewnone'
        ,imageListPreviewImageJsClass: 'js-roomtext__previewimage'
        ,imageListPreviewImageAltJsClass: 'js-roomtext__previewimagealt'
        ,imageListPreviewImageTitleJsClass: 'js-roomtext__previewimagetitle'
        ,imageListPreviewDescriptionJsClass: 'js-roomtext__previewdescription'
        ,dataImageOriginalUrl: 'originurl'
        ,dataImageEncodedUrl: 'encodedurl'
        ,dataImageOriginalEncodedUrl: 'encodedoriginalurl'
        ,dataImageTitle: 'imagetitle'
        ,dataImageIsCover: 'iscover'
        ,dataImageIndex: 'index'
        ,dataImageIsDuplicateOf: 'isduplicateof'
        ,dataImageIsActive: 'active'
        ,attrSrc: 'src'
        ,attrAlt: 'alt'
        ,attrTitle: 'title'
        ,imageListTemplate: '<li class="roomtext__imagelistitem js-roomtext__imagelistitem" data-originurl data-encodedurl data-encodedoriginalurl data-imagetitle data-iscover><figure class="roomtext__preview js-roomtext__preview"><img class="roomtext__previewimage js-roomtext__previewimage js-roomtext__previewimagetitle js-roomtext__previewimagealt" src="" title="" alt=""><figcaption class="roomtext__previewdescription js-roomtext__previewdescription"></figcaption></figure></li>'
        ,imagePreviewJsClass: 'js-roomtext__imagepreview'
        ,imageDetailsInfoJsClass: 'js-roomtext__imageinfo'
        ,imageDetailsImageJsClass: 'js-roomtext__image'
    };

    var createInstance = function() {

        function addEventHandlers() {
            initEvents();
            initRoomList();
            initLangList();
            initImageList();
        }

        function initEvents() {
            var clickEventClasses = '.' + o.roomListItemJsClass
                + ', .' + o.langListItemJsClass
                + ', .' + o.imageListItemJsClass;

            $container.on('click', clickEventClasses, handleClickEvents);
        }

        function initImagesLoaded() {
            $imgList.imagesLoaded()
                .progress( function( instance, image ) {
                    if (!image.isLoaded) {
                        setImageLoadError(image);
                    }
                });
        }

        function setImageLoadError(image) {
            var $img = $(image.img);
            $parent = $img.parent('.' + o.imageListPreviewJsClass);
            $img.hide();
            $parent.prepend($('<i />', {
                class: 'fa fa-ban ' + o.imageListPreviewImageNoneClass
                ,title: Translator.trans('contentstore.rooms.imageinfo.notavailable', {}, 'cms')
            }));
        }

        function initRoomList() {
            roomList = new List(o.roomId, {
                valueNames: [
                    { data: [ o.dataRoomKey ] }
                    ,{ data: [ o.dataRoomName ] }
                    ,{ data: [ o.dataActive ] }
                ]
                ,listClass: o.roomListJsClass
                ,item: o.roomListTemplate
            });
            roomList.sort(o.dataRoomKey, { order: 'asc' });
        }

        function initLangList() {
            langList = new List(o.langId, {
                valueNames: [
                    o.langlistItemCodeClass
                    ,{ data: [ o.dataLang ] }
                    ,{ data: [ o.dataRoomKey ] }
                    ,{ data: [ o.dataActive ] }
                ]
                ,listClass: o.langListJsClass
                ,item: o.langListTemplate
            });
        }

        function initImageList() {
            imageList = new List(o.imagesId, {
                valueNames: [
                    o.imageListPreviewDescriptionJsClass
                    ,{ data: [o.dataImageTitle]}
                    ,{ data: [o.dataImageIndex]}
                    ,{ data: [o.dataImageIsCover]}
                    ,{ data: [o.dataImageIsActive]}
                    ,{ data: [o.dataImageIsDuplicateOf]}
                    ,{ name: o.imageListPreviewImageAltJsClass, attr: o.attrAlt }
                    ,{ name: o.imageListPreviewImageTitleJsClass, attr: o.attrTitle }
                    ,{ name: o.imageListPreviewImageJsClass, attr: o.attrSrc }
                ]
                ,listClass: o.imageListJsClass
                ,item: o.imageListTemplate
            });
        }

        function handleClickEvents(e) {
            e.preventDefault();
            var $target = $(e.currentTarget);
            if ($target.hasClass(o.roomListItemJsClass)) {
                handleRoomClick($target);
            }
            if ($target.hasClass(o.langListItemJsClass)) {
                handleLangClick($target);
            }
            if ($target.hasClass(o.imageListItemJsClass)) {
                handlePreviewImageListItemClick($target);
            }
        }

        function handlePreviewImageListItemClick($element) {
            var index = $element.data('index');
            if (index === '' || index === false) {
                return false;
            }
            if (!imageData.hasOwnProperty(index)) {
                return false;
            }
            var originalUrl = imageData[index].hasOwnProperty('originurl')
                ? imageData[index]['originurl']
                : false;

            if (originalUrl) {
                activatePreviewImage(index);
                setImageDetailImage(originalUrl);
                setImageDetailText(imageData[index]);
            }

        }

        function activatePreviewImage(imageIndex) {
            if (imageIndex === '') {
                return false;
            }
            var oldActive = imageList.get(o.dataImageIsActive, true);
            if (oldActive.length > 0) {
                var oldActiveValues = oldActive[0].values();
                oldActiveValues[o.dataImageIsActive] = false;
                oldActive[0].values(oldActiveValues);
            }
            var newActive = imageList.get(o.dataImageIndex, imageIndex);
            if (newActive.length > 0) {
                newActive = newActive[0];
                var newActiveValues = newActive.values();
                newActiveValues[o.dataImageIsActive] = true;
                newActive.values(newActiveValues);
                return true;
            }
            return false;
        }

        function handleRoomClick($element) {
            var roomKey = String($element.data(o.dataRoomKey)) || false;

            if (!roomKey || !roomKey.length) {
                return;
            }
            var getLang = getLanguages(roomKey).pipe(getUpdateList(roomKey));
            var getImg = getImages(roomKey).pipe(getUpdateImages(roomKey));
            loader.startGroupLoader("handleRoom", $container, ["langData", "imgData"]);
            $.when(getLang, getImg)
                .done(function(langSuccess, imgSuccess) {
                    //if (langSuccess || imgSuccess) {
                        setActiveRoom(roomKey);
                    //}
                });
        }

        function handleLangClick($element) {
            var lang = String($element.data(o.dataLang)) || false;
            var roomKey = String($element.data(o.dataRoomKey)) || false;

            if (!lang || !lang.length) {
                return;
            }
            getDescription(roomKey, lang)
                .pipe(showRoomText)
                .done(function(success) {
                    if (success) {
                        setActiveLang(lang);
                    }
                });
        }

        function showRoomText(data) {
            clearRoomText();
            if (data.hasOwnProperty('pw_fe_error') || data.pw_fe_error) {
                return false;
            }

            if (!data.hasOwnProperty('description')) {
                return false;
            }

            $roomText.text(data.description);

            return true;
        }

        function clearRoomText() {
            $roomText.text('');
        }

        function setImageDetailImage(originalUrl) {
            $imageDetailImage.attr('src', '');
            $imageDetailImage.attr('src', originalUrl);
        }

        function setImageDetailText(imageData) {
            imageData = imageData || false;
            $imageDetailText.text('');
            if (imageData === false) {
                return false;
            }
            var html = ImageDetailsRenderer.getInstance().renderImageData(imageData);
            $imageDetailText.html(html);
        }

        function getUpdateImages(roomKey) {

            var updateImages = function(data) {
                imageList.clear();
                $imgCount.text(0);
                setImageDetailImage('');
                setImageDetailText(false);

                if (data.hasOwnProperty('pw_fe_error') || data.pw_fe_error) {
                    return false;
                }

                if (!data.hasOwnProperty('suppliers')) {
                    $imgCount.text('0');
                    return false;
                }

                var supplierkeys = Object.keys(data.suppliers);

                if (supplierkeys.length === 0
                    || !data.suppliers[supplierkeys[0]].hasOwnProperty('images')) {
                    $imgCount.text('0');
                    return false;
                }

                var images = data.suppliers[supplierkeys[0]].images;
                imageData = data.suppliers[supplierkeys[0]].images;

                if (images.length < 1) {
                    return false;
                }

                $imgCount.text(images.length);

                images.forEach(function(element, index, array) {
                    var text = element.name || Translator.trans('contentstore.rooms.nodescription', {}, 'cms');
                    var src = o.parmCdnBaseUrl + element.encodedurl + '/' + o.parmPreviewImageSize + 'x' + o.parmPreviewImageSize + '/smart/' + element.encodedoriginalurl;
                    var item = {};
                    var duplicateOf = element.hasOwnProperty(o.dataImageIsDuplicateOf)
                        ? element[o.dataImageIsDuplicateOf]
                        : '';

                    item[o.imageListPreviewDescriptionJsClass] = text;
                    item[o.dataImageTitle] = text;
                    item[o.dataImageIndex] = index;
                    item[o.dataImageIsCover] = element.cover;
                    item[o.dataImageIsDuplicateOf] = duplicateOf;
                    item[o.dataImageIsActive] = false;
                    item[o.imageListPreviewImageAltJsClass] = text;
                    item[o.imageListPreviewImageTitleJsClass] = text;
                    item[o.imageListPreviewImageJsClass] = src;
                    imageList.add(item);
                });

                initImagesLoaded();

                return true;
            };

            return updateImages;

        }

        function getUpdateList(roomKey) {

            var updateList = function(data) {
                langList.clear();
                clearRoomText();
                $langCount.text(0);

                if (data.hasOwnProperty('pw_fe_error') || data.pw_fe_error) {
                    return false;
                }

                if (!data.hasOwnProperty('langlist')) {
                    return false;
                }

                var langKeys = Object.keys(data.langlist);

                if (langKeys.length < 1) {
                    return false;
                }

                $langCount.text(langKeys.length);

                langKeys.forEach(function(element, index, array) {
                    var item = {};
                    item[o.langlistItemCodeClass] = element;
                    item[o.dataLang] = element;//data.langlist[element];
                    item[o.dataRoomKey] = roomKey;
                    item[o.dataContentKey] = contentKey;
                    item[o.dataContentSupplier] = contentSupplier;
                    item[o.dataContentProductCode] = contentProductCode;
                    item[o.dataActive] = false;
                    langList.add(item);
                });

                langList.sort(o.langlistItemCodeClass, { order: 'asc' });

                const selectedLang = $('.js-room-language-select').val();
                const selectedLangItem = $('.roomtext__langlistitem[data-lang="' + selectedLang + '"]');
                if (selectedLangItem) {
                    selectedLangItem.click();
                }

                return true;
            };

            return updateList;
        }

        function setActiveRoom(roomKey) {
            var options = {
                list: roomList
                ,dataActiveSelector: o.dataActive
                ,dataNewValueSelector: o.dataRoomKey
                ,newValue: roomKey
            };
            setListItemActive(options);
        }

        function setActiveLang(lang) {
            var options = {
                list: langList
                ,dataActiveSelector: o.dataActive
                ,dataNewValueSelector: o.dataLang
                ,newValue: lang
            };
            setListItemActive(options);
        }

        function setListItemActive(a) {
            var oldActive = a.list.get(a.dataActiveSelector, true);
            if (oldActive.length > 0) {
                var oldActiveValues = oldActive[0].values();
                oldActiveValues.active = false;
                oldActive[0].values(oldActiveValues);
            }
            var newActive = a.list.get(a.dataNewValueSelector, a.newValue)[0];
            var newActiveValues = newActive.values();
            newActiveValues.active = true;

            if (a.hasOwnProperty('change')) {
                for (var key in a.change) {
                    if (!a.change.hasOwnProperty(key)) {
                        continue;
                    }
                    newActiveValues[key] = a.change[key];
                }
            }
            newActive.values(newActiveValues);
        }

        function getLanguages(roomKey) {
            return $.ajax(Routing.generate(o.requestRoomLanguages) , {
                data: {
                    type: o.requestLanguagesType
                    ,supplier: contentSupplier
                    ,productcode: contentProductCode
                    ,contentkey: contentKey
                    ,roomkey: roomKey
                },
                beforeSend: function(e, xhr, options) {
                    e.groupLoaderKey = "handleRoom";
                    e.groupLoaderMemberKey = "langData";
                }
            });
        }

        function getImages(roomKey) {
            return $.ajax(Routing.generate(o.requestRoomImages) , {
                data: {
                    supplier: contentSupplier
                    ,productcode: contentProductCode
                    ,roomkey: roomKey
                    ,previewsize: o.parmPreviewImageSize
                },
                beforeSend: function(e, xhr, options) {
                    e.groupLoaderKey = "handleRoom";
                    e.groupLoaderMemberKey = "imgData";
                }
            });
        }

        function getDescription(roomKey, lang) {
            return $.ajax(Routing.generate("cms_contentstore_requestroomlanguages") , {
                data: {
                    type: o.requestDescriptionsType
                    ,contentkey: contentKey
                    ,supplier: contentSupplier
                    ,productcode: contentProductCode
                    ,languagecode: lang
                    ,roomkey: roomKey
                },
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "descriptionData";
                    loader.startLoader(e.loaderKey, $container);
                }
            });
        }

        function changeEditButtonRoomLocalizationAttribute(roomLocale) {
            const table = $('#cmscontentstoreproducttable');
            const editButtons = table.find('.edit-btn');
            editButtons.each((index, button) => {
                $(button).data('roomlocalization', roomLocale);
            });
        }

        function initLocalizationSelect(mainContainer) {
            const select = mainContainer.find('.js-room-language-select');
            select.val(getRoomLocalization());
            select.on('change', () => {
                var roomLocale = select.val();
                setRoomLocalization(roomLocale);
                changeEditButtonRoomLocalizationAttribute(roomLocale);
                CmsContentStore.getInstance().loadProductDetailPageByKeyAndRoomLocalization(
                    contentSupplier,
                    contentProductCode,
                    roomLocale,
                    false,
                    '.js-content__rooms-btn'
                );
            });

        }

        function init() {
            loader = Loader.getInstance();
            $container = $('.' + o.containerJsClass);
            if ($container.length < 1) {
                return;
            }
            initLocalizationSelect($container);
            contentKey = $container.data(o.dataContentKey) || false;
            contentSupplier = $container.data(o.dataContentSupplier) || false;
            contentProductCode = $container.data(o.dataContentProductCode) || false;

            if (!contentKey) {
                return;
            }

            $roomList = $('.' + o.roomListJsClass);
            $langList = $('.' + o.langListJsClass);
            $roomText = $('.' + o.roomTextJsClass);
            if ($roomList.length < 1 ||
                $langList.length < 1 ||
                $roomText.length < 1 ) {
                return;
            }

            $langCount = $('.' + o.langLangCountClass);
            $imgCount = $('.' + o.imageCountJsClass);

            $imgList = $('.' + o.imageListJsClass);
            $imageDetailImage = $('.' + o.imageDetailsImageJsClass);
            $imageDetailText = $('.' + o.imageDetailsInfoJsClass);

            addEventHandlers();
        }

        function hide() {
            $container.addClass('o.hiddenClass');
        }

        function show() {
            $container.removeClass('o.hiddenClass');
        }

        return {
            init: init,
            hide: hide,
            show: show
        };
    };

    return {
        getInstance: function(){
            if(!instance){
                instance = createInstance();
            }

            return instance;
        }
    };

})();
