/**
 * This function is deprecated.
 * @deprecated use PeakworkCommonToolkitBundle:xeditable instead.
 */
var PeakworkBackendEditable = (function() {
    return {
        /**
         * Initializes the editable fields
         * @param {Element} container
         */
        initEditable: function(container) {
            PeakworkBackendEditable.initEditableFields(container);
            PeakworkBackendEditable.initReadonlyFields(container);
            PeakworkBackendEditable.initHiddenValuesFields(container);
        },

        /**
         * Adds editable fields.
         * @param {Element} container
         */
        initEditableFields: function(container) {
            var elements = container.find('[data-modus="editable"]'), i,
                elementsLength = elements.length, el, uuid = $.uuid();
            
            for (i = 0; i < elementsLength; i++) {
                el = $(elements[i]);
                
                /* prepare elements to be openable automatically */
                el.attr('data-uuid', uuid);
                
                /* add input field next to item */
                el.on('init', function(e, editable) {
                    var item = $(this);
                    
                    PeakworkBackendEditable.createHiddenFieldByElement(item);
                    if ('true' === item.attr('data-valueoninit')) {
                        editable.setValue(editable.value);
                    }
                });

                /* init editable */
                el.editable({
                    mode: 'inline',
                    pk: i,
                    showbuttons: false,
                    onblur: 'submit',
                    validate: function(value) {
                        var item = $(this).get(0),
                            element = 'undefined' !== typeof item.$element ? 
                                item.$element : $(item);
                        
                        if ('true' === element.attr('data-required') && $.trim(value) === '') {
                            return Translator.trans('backend.editable.fieldisrequired', {}, 'peakwork');
                        } else {
                            element.removeClass('text-danger');
                        }
                    }
                });

                /* change input field item */
                el.on('save', function(event, params) {
                    event.preventDefault();
                    PeakworkBackendEditable.saveHiddenFieldValue($(this), params);
                    
                    /* check for custom function after saved the new value */
                    var item = $(this).get(0)
                        ,element = 'undefined' !== typeof item.$element ? 
                            item.$element : $(item)
                        ,onsave = element.attr('data-onsave')
                        ,fn;
                    
                    if ('undefined' !== typeof onsave) {
                        fn = PeakworkBackendEditable.getFunctionFromString(onsave);
                        if ('function' === typeof fn) {
                            fn.call(this, params, $(this), element);
                        }
                    }
                });
                
                el.on('focus', function() {
                    $(this).editable('show', true);
                });
            }
        },
        
        /**
         * Tries to find a function for the given string.
         * @param {String} functionName
         * @returns function|undefined
         */
        getFunctionFromString: function(functionName)
        {
            var scope = window
                ,i = 0
                ,scopeSplit = functionName.split('.')
                ,scopeLength = scopeSplit.length;
            
            for (i = 0; i < scopeLength - 1; i++) {
                scope = scope[scopeSplit[i]];
                if (scope === undefined) {
                    return;
                }
            }

            return scope[scopeSplit[scopeSplit.length - 1]];
        },

        /**
         * Registers a click event to readonly links to stop event default.
         * @param {Element} container
         */
        initReadonlyFields: function(container) {
            container.on('click', '[data-modus="readonly"]', function(event) {
                event.preventDefault();
            });
        },

        /**
         * Registers a click event to readonly links to stop event default.
         * @param {Element} container
         */
        initHiddenValuesFields: function(container) {
            container.on('click', '[data-modus="hiddenvalues"]', function(event) {
                event.preventDefault();
            });

            container.find('[data-modus="hiddenvalues"]').each(function(index, item) {
                PeakworkBackendEditable.createHiddenFieldByElement($(item));
            });
        },
        
        /**
         * Set the new input value.
         * @param {Element} el
         * @param {Object} params
         */
        saveHiddenFieldValue: function(el, params) {
            switch (el.data('type')) {
                case 'textarea': {
                    $('textarea[name="' + el.data('name') + '"]').val(params.newValue);
                    break;
                }
                case 'checklist': {
                    $('input[name="' + el.data('name') + '"]').val(params.newValue.join());
                    break;
                }
                default: {
                    $('input[name="' + el.data('name') + '"]').val(params.newValue);
                }
            }
            
            
            if ('textarea' === el.data('type')) {
                
            } else {
                $('input[name="' + el.data('name') + '"]').val(params.newValue);
            }

            el.trigger('pw-editable-saved', [el, params]);
        },
        
        /**
         * Creates the correct hidden value field depending on the element data.
         * @param {Element} el
         */
        createHiddenFieldByElement: function(el) {
            switch (el.data('type')) {
                case 'textarea': {
                    el.after(
                        '<textarea class="hidden" ' + 
                        'name="' + el.data('name') + '">' +
                        el.data('value') + 
                        '</textarea>'
                    );
                    break;
                }
                case 'checklist': {
                    el.after(
                        '<input class="hidden" ' + 
                        'type="text" name="' + el.data('name') + '" ' +
                        'value="' + el.data('value').replace('[\'', '').replace('\']', '') + '" />'
                    );
                    break;
                }
                default: {
                    el.after(
                        '<input class="hidden" ' + 
                        'type="text" name="' + el.data('name') + '" ' +
                        'value="' + el.data('value') + '" />'
                    );
                }
            }
        },

        /**
         * Checks for a valid form
         * @param {Element} form
         * @param {Boolean} success
         * @param [] errorLabels
         * @param {Function} showErrors
         * @returns {Boolean}
         */
        isFormValid: function(form, success, errorLabels, showErrors) {
            var editFields = form.find('[data-modus="editable"]'), i = 0, 
                editFieldsLength = editFields.length,
                element, validation,
                requiredFields = form.find('input[data-required="true"]'),
                requiredFieldsLength = requiredFields.length;
        
            success = 'undefined' === typeof success ? true : success;
            errorLabels = 'undefined' === typeof errorLabels ? [] : errorLabels;
            
            /* check all editable fields */
            if (editFieldsLength > 0) {
                for (i = 0; i < editFieldsLength; i++) {
                    element = $(editFields[i]);
                    validation = element.editable('validate');

                    if (false === $.isEmptyObject(validation)) {
                        success = false;
                        element.addClass('text-danger');
                        errorLabels.push(
                            'undefined' !== typeof element.data('label') ? 
                            element.data('label') :
                            element.closest('.form-group').find('label').text().trim()
                        );
                    } else {
                        element.removeClass('text-danger');
                    }
                }
            }

            /* check all required fields */
            if (requiredFieldsLength > 0) {
                for (i = 0; i < requiredFieldsLength; i++) {
                    element = $(requiredFields[i]);

                    if (false === PeakworkBackendEditable.isInputFieldNotEmpty(element)) {
                        success = false;
                        element.addClass('text-danger');
                        errorLabels.push(element.data('label'));
                    } else {
                        element.removeClass('text-danger');
                    }
                }
            }
            
            if ('function' === typeof showErrors && false === success && errorLabels.length > 0) {
                showErrors.call(this, errorLabels);
            }

            return success;
        },

        /**
         * Checks, if html form items are empty.
         * @param {Object} element
         * @returns {Boolean}
         */
        isInputFieldNotEmpty: function(element) {
            if (element.is(':checkbox')) {
                return element.is(':checked');
            }

            if (element.is('textarea')) {
                return '' !== element.val().trim();
            }

            if (element.is('input')) {
                return '' !== element.val().trim();
            }
        }
    };
})();
