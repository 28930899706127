(function() {
    var ctx = pwNamespace('PEAKWORK.CMS.IMAGE');
    ctx.ImageDetail = function(namespace, options) {
        this.namespace = namespace || '';
        this.loader = {};
        this.data = {};
        this.currentImage = false;
        this.currentGroup = false;
        this.$imageDetailContainer = false;

        this.defaults = {
            jsclass: {
                imageDetailContainer: 'js-imagepresenter__header'
                ,imageDetailImage: 'js-imagedetails__image'
                ,imageDetailInfo: 'js-imagedetails__imageinfo'
                ,imageDetailFilterDuplicateButton: 'js-imagedetails__detailduplicatebutton'
            }
            ,attr: {
                src: 'src'
                ,alt: 'alt'
                ,title: 'title'
            }
            ,events: {
                groupchange: this.namespace === '' ? 'presentergroupchange' : 'presentergroupchange.' + this.namespace
                ,datachange: this.namespace === '' ? 'presenterdatachange' : 'presenterdatachange.' + this.namespace
                ,imagechange: this.namespace === '' ? 'presenterimagechange' : 'presenterimagechange.' + this.namespace
            }
            ,propKeys: {
                data: 'data'
                ,originUrl: 'originurl'
                ,name: 'name'
                ,isCover: 'iscover'
                ,isDuplicateOf: 'isduplicateof'
            }
            ,imageDetailContainerId: 'imagepresenter__details'
            ,values: false
            ,imageTextRenderFn: false
        };

        // Create options by extending defaults with the passed in arguments
        if (options && typeof options === "object") {
            this.options = $.extend(true, {}, this.defaults, options);
        }
        return init.call(this);
    };

    ctx.ImageDetail.prototype.constructor = ctx.ImageDetail;

    function init() {
        this.loader = Loader.getInstance();

        if (!this.initImageDetailContainer()) {
            return false;
        }
        this.addHandlers();
        return this.getExports();
    }

    ctx.ImageDetail.prototype.initImageDetailContainer = function() {
        var $imageDetailContainer = $('#' + this.options.imageDetailContainerId);
        if ($imageDetailContainer.length < 1) {
            return false;
        }
        this.$imageDetailContainer = $imageDetailContainer;
        this.$imageDetailImage = $('.' + this.options.jsclass.imageDetailImage, $imageDetailContainer);
        this.$imageDetailInfo = $('.' + this.options.jsclass.imageDetailInfo, $imageDetailContainer);
        if (this.$imageDetailInfo.length < 1 || this.$imageDetailImage.length < 1) {
            return false;
        }
        return true;
    };

    ctx.ImageDetail.prototype.updateImageDetail = function(data) {
        if (!data.hasOwnProperty(this.options.propKeys.data)) {
            this.setImageDetailImage();
            this.setImageDetailText();
            return false;
        }
        var imageData = data[this.options.propKeys.data];
        var imageUrl = imageData.hasOwnProperty(this.options.propKeys.originUrl) ? imageData[this.options.propKeys.originUrl] : false;
        var altText = imageData.hasOwnProperty(this.options.propKeys.name) ? imageData[this.options.propKeys.name] : false;
        if (imageUrl) {
            this.setImageDetailImage(imageUrl, altText);
        }
        this.setImageDetailText(imageData);
    };

    ctx.ImageDetail.prototype.setImageDetailImage = function(originalUrl, altText) {
        altText = altText || false;
        originalUrl = originalUrl || false;
        this.$imageDetailImage.attr('title', '');
        this.$imageDetailImage.attr('src', '');
        if (altText) {
            this.$imageDetailImage.attr('title', altText);
        }
        if (originalUrl) {
            this.$imageDetailImage.attr('src', originalUrl);
        }
    };

    ctx.ImageDetail.prototype.setImageDetailText = function(imageData) {
        imageData = imageData || false;
        this.$imageDetailInfo.text('');
        var html = ImageDetailsRenderer.getInstance().renderImageData(imageData, this.options.imageTextRenderFn);
        this.$imageDetailInfo.html(html);
    };


    ctx.ImageDetail.prototype.addHandlers = function() {
        var listenerSelector = '.' + this.options.jsclass.imageDetailFilterDuplicateButton;
        this.$imageDetailContainer.on('click.' + this.namespace, listenerSelector, this.getHandleClick.call(this));
        $.subscribe(this.options.events.datachange, this.getClearDetails.call(this));
        $.subscribe(this.options.events.groupchange, this.getClearDetails.call(this));
        $.subscribe(this.options.events.imagechange, this.getHandleImageChange.call(this));
    };

    ctx.ImageDetail.prototype.getExports = function() {
        return {
        };
    };

    ctx.ImageDetail.prototype.getHandleClick = function () {
        var self = this;
        return function(e) {
            e.preventDefault();
            var $target = $(e.currentTarget);
            if ($target.hasClass(self.options.jsclass.imageDetailFilterDuplicateButton)) {
                self.onDetailDuplicateButtonClickHandler($target);
            }
        }
    };

    ctx.ImageDetail.prototype.onDetailDuplicateButtonClickHandler = function($target) {
        var duplicateUrl = $target.data('duplicateurl');
        $.publish('presentersearch.contentimagespresenter', duplicateUrl);
    };


    ctx.ImageDetail.prototype.getHandleImageChange = function() {
        var self = this;
        return function(e, data) {
            self.data = data;
            self.updateImageDetail.call(self, data);
        }
    };

    ctx.ImageDetail.prototype.getClearDetails = function() {
        var self = this;
        return function(e) {
            self.updateImageDetail.call(self, false);
        }
    };

})();
