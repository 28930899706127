/**
 *
 * CountrySearchComponent
 * extends AbstractGiataSearchComponent
 *
 */
(function(){
var ctx = pwNamespace('PEAKWORK.STORECMS.GIATA.GEODATA.COMPONENTS');

ctx.CountrySearchComponent = function() {
    var opts = {
        wrapperClassName: '#GiataGeodataCountryTab-1'
        ,inputId: '#GiataSearchCountryInput'
        ,chain:['region','city']
    };
    if (arguments[0] && typeof arguments[0] === "object") {
        opts = this.extendDefaults(opts, arguments[0]);
    }

    ctx.AbstractGiataSearchComponent.call(this, opts);
}
ctx.CountrySearchComponent.prototype = $.extend(
    Object.create(ctx.AbstractGiataSearchComponent.prototype), {
        constructor : ctx.CountrySearchComponent
        ,onClickItem : function(obj, e) {
            ctx.AbstractGiataSearchComponent.prototype.onClickItem.call(this, obj, e);
            this.initNewSearchListController('region', 'cms_giata_geodata_list', 'RegionSearchComponent', {
                countrycode: $(e.currentTarget).data('countrycode')
            });
            $('.giatageo__detail').html('');
            return false;
        }
        ,addButtonEvents: function(ddList) {
            ctx.AbstractGiataSearchComponent.prototype.addButtonEvents.call(this, ddList);
            ddList.find('.dd-handle').on('click', '.remove-country-item-btn', function(e) {
                e.stopPropagation();
                console.log('removed');
            });
        }
        ,resetSearchList: function() {
            var wrapper = $(this.options.wrapperClassName)
                ,nestableContainer = wrapper.find('.dd')
                ,ddlist = nestableContainer.find('.dd-list');
            nestableContainer.find('.resultcount').html(
                Translator.trans(
                    'basicdata.geodata.overview.searchcountry.resultcount',
                    {
                        'count':0
                        ,'total':0
                    },
                    'cms'
                )
            );
            ddlist.html('');
        }
        ,renderItem : function ( ul, item, index ) {
            var li = $( "<li>", {
                'class': "dd-item basicdata-geodata-country-list"
                ,'data-id': item.countrycode.value
            })
                .append(
                    $('<div/>', {
                        'class':'dd-handle'
                        ,'data-countrycode':item.countrycode.value
                    })
                    .append(' '+item.countrycode.value)
                    //.append(this.addCostumButton('remove', 'country', 'right'))
                ).appendTo( ul );
            return li;
        }
        ,renderMenu : function( acObj, ul, items ) {
            var that = acObj
                ,ddList = $('#nestable-geodata-country .dd-list')
                ,resultCountContainer = ddList.parent().find('.resultcount')
                ,countries = items[0]
                ,count = (items[1].value) ? items[1].value : 0
                ,countriesCount = Object.keys(countries).length;
            ul.addClass('hide');
            resultCountContainer.html(
                Translator.trans(
                    'basicdata.geodata.overview.searchcountry.resultcount',
                    {
                        'count':countriesCount
                        ,'total':count
                    },
                    'cms'
                )
            );

            if(ddList.length > 0) {
                ddList.html('');
            } else {
                $('<ol/>', { 'class':'dd-list'}).appendTo($('#nestable-geodata-country'));
            }
            $.each( countries, function( index, item ) {
                that._renderItemData( ddList, item, index );
            });
            this.addButtonEvents(ddList);
        }
        ,addAutocompleteEvents : function(instance) {
            var self = this;
            if (instance.element) {
                var container = instance.element;
                container.on( "autocompleteopen", function(event, ui){
                    self.onOpen(self, event, ui);
                } );
            }
        }
        ,onOpen : function(obj, event, ui) {
            obj.deactivateChildren();
        }
    }
);
})();