$(function () {
    var context = pwNamespace('CMS.GLOBALTYPE.TRANSLATIONS');

    context.TranslationUpload = (function () {
        var instance = null;

        var injection = function() {
            return {}
        };

        var initInstance = function() {
            var me = {
                config: {
                    inputFieldId: '.fileinput-button #globaltypeuploadcsvfileinput'
                    ,uploadButton: $('.js-uploadcsvtranslations')
                    ,progressBar: $('#progress .progress-bar')
                    ,progressBarProcess: $('#progressProcess .progress-bar')
                    ,errorField: $('.globaltypetranslationsdownload-error')
                    ,successField: $('.globaltypetranslationsdownload-success')
                    ,closeButton: $('.js-globaltypetranslationuploadclosebutton')
                    ,modalContent: $('#cmsglobaltypeuploadtranslationsmodal')
                    ,modalErrorContainerCss: 'globaltypetranslationsdownload-error'
                    ,modalErrorContentCss: 'globaltypetranslationsdownload-errorcontent'
                    ,modalSuccessContainerCss: 'globaltypetranslationsdownload-success'
                    ,modalSuccessContentCss: 'globaltypetranslationsdownload-successcontent'
                    ,tableId: '#GlobaltypeTranslationsTable'
                    ,resetButton: $('.js-globaltypetranslationuploadresetbutton')
                    ,progressBarsContainer: $(".js-globaltypetranslationuploadprogressbars")
                }
                ,init: function() {
                    me.hideErrorField();
                    me.hideSuccessField();
                    me.initFileUploadField();
                    me.addEventListeners();
                }

                ,addEventListeners: function()
                {
                    me.config.closeButton.on('click', me.closeButtonUpload);
                    me.config.resetButton.on('click', me.resetUploadForm);
                    me.config.modalContent.on('hidden.bs.modal', function () {
                        me.closeButtonUpload();
                    });
                    $(me.config.inputFieldId).on('change', function(e){
                        me.config.progressBarsContainer.show();
                        setTimeout(function(){
                            var input = $(me.config.inputFieldId)
                                ,button = input.parents('.fileinput-button');
                            input.attr('disabled', true);
                            button.addClass('fileinput-button__deactivated');
                        }, 1);

                    });
                }

                ,resetUploadForm: function(e) {
                    me.closeButtonUpload();
                    $(e.currentTarget).hide();
                }

                ,closeButtonUpload: function(e) {
                    var input = $(me.config.inputFieldId)
                        ,button = input.parents('.fileinput-button')
                    input.attr('disabled', false);
                    button.removeClass('fileinput-button__deactivated');
                    me.setProgressStatus(me.config.progressBar, 0);
                    me.setProgressStatus(me.config.progressBarProcess, 0);
                    me.config.progressBar.css('background-color', '#337ab7');
                    me.config.progressBarProcess.css('background-color', '#337ab7');
                    me.setErrorMessage('');
                    me.hideErrorField();
                    me.hideSuccessField();
                    me.config.progressBarsContainer.hide();
                    $(me.config.resetButton).hide();
                }

                ,getErrorContentArea: function() {
                    var errorContentArea = me.config.modalContent.find('.' + me.config.modalErrorContainerCss).find('.' + me.config.modalErrorContentCss);
                    return errorContentArea;
                }

                ,setErrorMessage: function(message) {
                    me.getErrorContentArea().html(message);
                }

                ,hideErrorField: function() {
                    me.config.errorField.hide();
                    me.setErrorMessage(null);
                }

                ,getSuccessContentArea: function() {
                    var successContentArea = me.config.modalContent.find('.' + me.config.modalSuccessContainerCss).find('.' + me.config.modalSuccessContentCss);
                    return successContentArea;
                }

                ,setSuccessMessage: function(message) {
                    me.getSuccessContentArea().html(message);
                }

                ,hideSuccessField: function() {
                    me.config.successField.hide();
                    me.setSuccessMessage(null);
                }

                ,setProgressStatus: function(progressBar, $percent) {
                    progressBar.css(
                        'width',
                        $percent + '%'
                    );
                }

                ,showMessages: function(title, text, type) {
                    swal({
                        title: Translator.trans(title, {}, 'cms')
                        ,type: type
                        ,text: Translator.trans(text, {}, 'cms')
                        ,html: true
                        ,customClass: 'fe-message__swal'
                    });
                }

                ,initFileUploadField: function() {
                    $(me.config.inputFieldId).fileupload({
                        dataType: 'json',
                        progress: function(e, data) {
                            me.setProgressStatus(me.config.progressBar, 50);
                        },
                        progressall: function (e, data) {
                            e.preventDefault();
                            var progress = parseInt(data.loaded / data.total * 100, 10);
                            me.setProgressStatus(me.config.progressBar, progress);
                        },
                        done: function(e, data) {
                            me.setProgressStatus(me.config.progressBar, 100);
                            if (data && data.result && data.result.fileName) {
                                var deferred = $.Deferred();
                                var promise = deferred.promise();
                                var interval = setInterval(function() {
                                    $.ajax(Routing.generate("cms_statistic_import_list"
                                        ,{
                                            'supplier':data.result.fileName
                                            ,'importType':'GLOBALTYPE_TRANSLATIONS'
                                        }
                                    ))
                                    .done(function(data) {
                                        if (data.data && data.data[0]) {
                                            var status = data.data[0];
                                            var progress = parseInt(status.processed / status.total * 100, 10);
                                            me.setProgressStatus(me.config.progressBarProcess, progress);
                                            if (status.success === 'false' && status.end) {
                                                deferred.resolve(false);
                                                var msgError = 'Please check your translation file!!!';
                                                if (status.errorMsg) {
                                                    msgError = status.errorMsg;
                                                }
                                                me.setErrorMessage(msgError);
                                                me.config.errorField.show();
                                                me.config.progressBarProcess.css('background-color','red');
                                                me.setProgressStatus(me.config.progressBarProcess, 100);
                                            }

                                            if (progress == 100) {
                                                deferred.resolve(true);
                                                var msgSuccess = 'Upload was successful!';
                                                me.setSuccessMessage(msgSuccess);
                                                me.config.successField.show();
                                            }
                                        } else {
                                            deferred.resolve(false);
                                            var msgError = 'Please check your translation file!!!';
                                            me.setErrorMessage(msgError);
                                            me.config.errorField.show();
                                            me.config.progressBarProcess.css('background-color','red');
                                            me.setProgressStatus(me.config.progressBarProcess, 100);
                                        }
                                        $(me.config.resetButton).show();
                                    });

                                }, 1000);
                                promise.done(function(reloadDataTable) {
                                    clearInterval(interval);
                                    if(reloadDataTable) {
                                        var table =$(me.config.tableId).DataTable();

                                        table.ajax.reload(function() {}, false );
                                    }
                                });
                            }
                            /*if (data.textStatus === 'success') {
                                me.showMessages('globaltype.messages.general.success.title'
                                    ,'globaltypetranslations.messages.upload.success'
                                    ,'success');
                                //me.config.closeButton.trigger('click');
                            } else {
                                me.showMessages('globaltype.messages.general.warning.title'
                                    ,'globaltypetranslations.messages.upload.insuccess'
                                    ,'warning');
                            }*/
                        },
                        fail: function(e, data) {
                            var resp = data.response().jqXHR.responseJSON
                                ,msgError;

                            if (resp.msgError) {
                                msgError = '<p>' + resp.msgError + '</p>';
                            }
                            me.setErrorMessage(msgError);
                            me.config.errorField.show();
                        }
                    });
                }
            };

            /* public */
            var baseObj = {

            };

            /* attach public functions to singleton */
            $.each(injection.call(me, me), function(idx, prop) {
                baseObj[idx] = prop;
            });

            /* execute init method */
            me.init();

            return baseObj;
        };

        return {
            inject: function(inject) {
                injection = inject;
            }
            ,getInstance: function() {
                if (instance === null) {
                    instance = initInstance();
                }
                return instance;
            }
        }
    })();
});

$(function() {
    if ($('.js-uploadcsvglobaltypetranslation').length > 0) {
        CMS.GLOBALTYPE.TRANSLATIONS.TranslationUpload.getInstance();
    }
});
