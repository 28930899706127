var ConfigGlobaltypeCategoryTranslationFilterController = (function(){

    var instance
        ,loader;

    function createInstance() {

        var addHandlers = function () {
            var wrapper = $('.js-cms-config-apiconfiguration-globaltypecategorytranslationfilter');
            wrapper.on('submit', '.js-cms-config-form-apiconfiguration-globaltypecategorytranslationfilter', onSubmit);
            wrapper.on('click', '.btn-reset-globaltypecategorytranslationfilter', onReset);
        };

        var onReset = function(e) {
            e.preventDefault();
            var btn = $(this)
                ,loaderContainer = btn.parents('.js-cms-config-apiconfiguration-globaltypecategorytranslationfilter');
            $.ajax({
                url: Routing.generate('cms_config_resetglobaltypecategorytranslationfilter')
                ,beforeSend: function(e, xhr, options) {
                    e.loaderKey = "resetglobaltypecategorytranslationfilter";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
            })
            .done(function(data) {
                if (data.view) {
                    btn.parents('.js-config-formwrapper').html(data.view);
                }
            });
        };

        var onSubmit = function(e) {
            e.preventDefault();
            var form = $(this)
                ,loaderContainer = form.parents('.js-cms-config-apiconfiguration-globaltypecategorytranslationfilter')
                ,globaltypecategorytranslationfilter = form.find("input[name='globaltypecategorytranslationfilter']:checked").val()
                ,url = Routing.generate('cms_config_saveglobaltypecategorytranslationfilter');
            $.ajax(url, {
                data: {
                    globaltypecategorytranslationfilter: globaltypecategorytranslationfilter
                },
                beforeSend: function(e, xhr, options) {
                    e.loaderKey = "saveGlobaltypeCategoryTranslationFilter";
                    loader.startLoader(e.loaderKey, loaderContainer);
                }
                ,method: 'POST'
            })
            .done(function(data){
                if (data.success === false) {
                    toastr.error(
                        Translator.trans('def.term.error', {}, 'cms'),
                        Translator.trans('config.section.globaltypecategorytranslationfilter.save.errormessage', {}, 'cms')
                    );
                }
                if (data.success === true) {
                    loaderContainer.find('.btn-reset-globaltypecategorytranslationfilter').click();
                    toastr.success(
                        Translator.trans('def.term.success', {}, 'cms'),
                        Translator.trans('config.section.globaltypecategorytranslationfilter.save.successmessage', {}, 'cms')
                    );
                }
            });
        };

        var init = function() {
            loader = Loader.getInstance();
            addHandlers();
        };

        return {
            init: init
        };

    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();