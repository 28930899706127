var FeatureDetailsController = (function(){
    var instance;
    var featuresContainer = '#FeaturesContainer',
        featuresDatatable = '#FeaturesDataTable',
        globalTypesDatatable = '#GlobalTypesDataTable',
        groupFeaturesDatatable = '#GroupFeaturesDataTable';

    function createInstance() {

        var isFeatureTableInit = false,
            isGroupedFeatureTableInit = false,
            isGlobaltypeTableInit = false,
            gtfields = [],
            globalTypeObjects = {},
            loader;

        /**
         * function adds existing Touroperatorcodes to headercolumn
         *
         * @return void
         */
        var createGTHeaderColumn = function() {
            var featureGTHeaderCol = $(globalTypesDatatable).find('thead > tr');
            gtfields = ['globaltype','subtype','additionaltype'];
            $('#TextCompareAndMasterForm table .cell-tocode').each(function(k,v){
                if(!$(this).data('internal')) {
                    gtfields.push($(this).text());
                    featureGTHeaderCol
                        .append(
                            $('<th/>', { 'text':$(this).text(), class: 'featuregt___tocode featuregt___tocode--head' })
                        );
                }
            });
            // gtfields.push('INT');
            // featureGTHeaderCol
            //     .append(
            //         $('<th/>', { 'text':'INT', class: 'featuregt___tocode featuregt___tocode--head' })
            //     );
        };

        /**
         * function creates/loads featurelist with key as param
         *
         * @return void
         */
        var initProductFeatureDataTable = function() {
            var table = $(featuresDatatable),
                key = table.data('key'),
                params = {'key':key};
            //table.append($('<div/>',{ 'class': 'loadblock' }));
            if(!isFeatureTableInit) {
                initFeatureDataTable(table, params);
                isFeatureTableInit = true;
            } else {
                loadDatatable(table, 'cms_contentstore_featurelist', params, true);
            }
        };

        /**
         * function creates/loads featurelist with groupid as param
         *
         * @return void
         */
        var initGroupFeaturesDatatable = function() {
            var table = $(groupFeaturesDatatable),
                groupid = table.data('groupid'),
                params = {'groupid':groupid};
            //table.append($('<div/>',{ 'class': 'loadblock' }));
            if(!isGroupedFeatureTableInit) {
                initFeatureDataTable(table, params);
                isGroupedFeatureTableInit = true;
            } else {
                loadDatatable(table, 'cms_contentstore_featurelist', params, true);
            }
        };

        /**
         * initial call for featurelist datatable
         *
         * @param table string       tablecontainer name (id/class)
         * @param params object      parameters
         *
         * @return void
         */
        var initFeatureDataTable = function(table, params) {
            initDatatable(table, 'cms_contentstore_featurelist', params,
                [
                    {data: 'touroperator',  sortable: false},
                    {data: 'feature',  sortable: false},
                    {data: 'text',  sortable: false},
                    {data: 'description',  sortable: false},
                    // {data: 'globaltype',  sortable: false},
                    // {data: 'subtype',  sortable: false},
                    // {data: 'additionaltype',  sortable: false}
                    {data: function (row, type, set, meta) {
                        var output = '';
                        row.gts.forEach(function(el, index, array){
                            output = output + generateGtTableContent('globaltype', el);
                        });
                        return output;
                    },  sortable: false},

                    {data: function (row, type, set, meta) {
                        var output = '';
                        row.gts.forEach(function(el, index, array){
                            output = output + generateGtTableContent('subtype', el);
                        });
                        return output;
                    },  sortable: false},

                    {data: function (row, type, set, meta) {
                        var output = '';
                        row.gts.forEach(function(el, index, array){
                            output = output + generateGtTableContent('additionaltype', el);
                        });
                        return output;
                    },  sortable: false}
                ], true);
        };


        var generateGtTableContent = function(type, el) {
            var text = el[type] || '&nbsp;';
            return $('<div/>', {
                'class': 'contentfeaturestable__gt'
                ,'html': text
            })[0].outerHTML;
        };
        /**
         * function creates internalfield checkboxes for datatable columns
         *
         * @return void
         */
        // var handleInternalDTColumns = function() {
        //     return {data: function(row, type, set, meta) {
        //         var tocodes = (row.touroperator)?row.touroperator.split(','):"";
        //         var checkBoxAttr = {
        //             'id':'GtCheckBox-'+meta.row,
        //             'type':'checkbox',
        //             'data-gt': (row.globaltype)?row.globaltype:"",
        //             'data-st': (row.subtype)?row.subtype:"",
        //             'data-at': (row.additionaltype)?row.additionaltype:""
        //         };
        //         if(-1 < $.inArray(gtfields[meta.col], tocodes)){
        //             checkBoxAttr.checked = 'true';
        //         }
        //         return $('<div/>',{'class':'checkbox'})
        //                     .append($('<input/>', checkBoxAttr))
        //                     .append(
        //                         $('<label/>', {'for':'GtCheckBox-'+meta.row})
        //                     )[0].outerHTML;
        //     },  sortable: false};
        // };

        /**
         * function creates tocodefield checkboxes for datatable columns
         *
         * @return void
         */
        var handleTocodeDTColumns = function() {
            return {data: function(row, type, set, meta) {
                var tocodes = (row.touroperator)?row.touroperator.split(','):"";
                if(-1 < $.inArray(gtfields[meta.col], tocodes)){
                    // return $('<div/>',{'class':'checkbox'})
                    //             .append($('<input/>',{
                    //                 'type':'checkbox',
                    //                 'checked':'true',
                    //                 'disabled':'true',
                    //                 'name': 'cb_' + gtfields[meta.col]
                    //             }))
                    //     .append(
                    //         $('<label/>', {'for':'cb_' + gtfields[meta.col]})
                    //     )[0].outerHTML;
                    return $('<div/>', { class: 'featuregt___tocode featuregt___tocode--checked' })
                        .append($('<span />', { class: 'fa fa-check-circle' })
                        )[0].outerHTML;
                }
                // return $('<div/>',{'class':'icons-box'})
                // .append($('<div/>',{'class':'infont'})
                //     .append($('<i/>',{'class':'pe-7s-close'}))
                // )[0].outerHTML;
                return $('<div/>', { class: 'featuregt___tocode featuregt___tocode--notavailable' })
                    .append($('<span />', { class: 'fa fa-ban' })
                    )[0].outerHTML;
            },  sortable: false};
        };

        /**
         * function initializes/loads grouptype datatable and additionally creates tocode/internal columns dynamically.
         *
         * @return void
         */
        var initGroupTypeDataTable = function() {
            var table = $(globalTypesDatatable),
                groupid = table.data('groupid');
            //table.append($('<div/>',{ 'class': 'loadblock' }));
            if(!isGlobaltypeTableInit) {
                var columns =  [];
                if(0 < gtfields.length) {
                    for(var gtfield in gtfields) {
                        if(gtfield < 3) {
                            columns.push({data: gtfields[gtfield],  sortable: false});
                            // } else if(gtfields[gtfield] === 'INT') {
                            //     columns.push(handleInternalDTColumns());
                        } else {
                            columns.push(handleTocodeDTColumns());
                        }
                    }
                }
                initDatatable(table, 'cms_contentstore_globaltypelist', {'groupid':groupid},
                    columns, false);
                isGlobaltypeTableInit = true;
            } else {
                loadDatatable(table, 'cms_contentstore_globaltypelist', {'groupid':groupid}, true);
            }
        };

        var loadDatatable  = function(table, tableAction, params, removeLoader) {
            var loaderId = loader.add(table);
            table
                .DataTable()
                .ajax
                .url(Routing.generate(tableAction, params))
                .load(function(){
                    if(removeLoader) {
                        loader.remove(loaderId, table);
                    }
                });
        };

        /**
         * function initializes datatable and adds eventListener for handling loading animation.
         *
         * @param table      string      tablecontainer name (id/class)
         * @param action     string      action name
         * @param params     object      parameters
         * @param columns    object      columns
         *
         * @return void
         */
        var initDatatable = function(table, action, params, columns, serverSide) {
            var loaderTargetContainer = $(featuresContainer);
            //var loaderId = loader.add(loaderTargetContainer);
            table
                .on( 'init.dt', function(e) {
                    //loader.remove(loaderId, loaderTargetContainer);
                })
                .on('xhr.dt', function(e, settings, data) {
                    //loader.remove(loaderId, loaderTargetContainer);
                    e.currentTarget.clickedPaging = false;
                })
                .on( 'page.dt', function(e, settings) {
                    //loaderTargetContainer = $(e.delegateTarget);
                    ///loaderId = loader.add(loaderTargetContainer);
                    e.currentTarget.clickedPaging = true;
                })
                .dataTable({
                    ajax: {
                        url: Routing.generate(action, params),
                        type: "GET",
                        async: true,beforeSend  : function(e, xhr, options) {
                            e.loaderKey = "featureList";
                            loader.startLoader(e.loaderKey, loaderTargetContainer);
                        }
                    },
                    serverSide: serverSide,
                    lengthChange: false,
                    searching: false,
                    pagingType: 'simple_numbers',
                    pageLength: 10,
                    "order": [],
                    columns: columns,
                    language: {
                        paginate: {
                            next: Translator.trans('contentstore.producttable.paginate.next', {}, 'cms'),
                            previous: Translator.trans('contentstore.producttable.paginate.previous', {}, 'cms')
                        },
                        info: Translator.trans('contentstore.producttable.info', {}, 'cms'),
                        infoEmpty: Translator.trans('contentstore.producttable.emptyinfo', {}, 'cms'),
                        emptyTable: Translator.trans('contentstore.producttable.emptyTable', {}, 'cms')
                    }
                });
        };

        var onMasterCheckChangeHandler = function() {
            if(0 === $(featuresContainer+' .unsavedcontentpop').length){
                createUnsavedContentpop($(featuresContainer+" .panel-heading")[0]);
            }
            var input = $(this),
                action = (input.prop( "checked" ))?'save':'delete';
            if(!globalTypeObjects[input.attr('id')]) {
                globalTypeObjects[input.attr('id')] = {
                    'globaltype':input.data('gt'),
                    'subtype':input.data('st'),
                    'additionaltype':input.data('at')
                };
            }
            onSaveGTHandler(action);
            //$('#SaveGTButton').removeClass('disabled btn-default btn-outline').addClass('btn-danger');
        };

        /**
         * function creates and renders warning message, if unsaved content (registered content) exists
         *
         * @param
         * @return void
         */
        var createUnsavedContentpop = function(targetselect) {
            var ccs = CmsContentStore.getInstance();
            var messagePop = $('<div/>',{
                'class': 'alert alert-danger unsavedcontentpop',
                'text': ' '+Translator.trans('contentstore.general.feedback.unsavedcontent', {}, 'cms'),
                'data-name': 'featureUpdates',
                click: ccs.getOnClickUnsavedWarningMessageHandler()
            }).prepend($('<i/>',{ 'class': 'fa fa-warning' }));

            messagePop.hide();

            var popmessagequeue = ccs.getPopmessagequeue();

            popmessagequeue.featureUpdates = messagePop;
            popmessagequeue.count++;

            ccs.setPopmessagequeue(popmessagequeue);

            messagePop.insertAfter(targetselect);
        };

        /**
         * function calls saveNewEdf to create a new internal edf, if internalkey doesn't exist.
         * Otherwise calls updateGlobalTypeByInternalKey to update an existing internal edf with globaltypes
         *
         * @param action string      action route for updating or deleting globaltype in existing internal edf
         *
         * @return void
         */
        var onSaveGTHandler = function(action) {
            var internalkey = $(globalTypesDatatable).data('internalkey');
            if(!internalkey){
                saveNewEdf();
            } else {
                updateGlobalTypeByInternalKey(internalkey, action);
            }
        };

        var createAlertBlock = function() {
            return $('<div/>',{
                'class': 'alert alert-success',
                text: ' '+Translator.trans('contentstore.general.feedback.update.success', {}, 'cms'),
                click: function(e){
                    $(this).slideUp(100, function(){
                        $(this).remove();
                    });
                }
            }).prepend($('<i>',{ 'class': 'fa fa-bolt' }));
        };

        /**
         * function creating a new internal edf after gathering all the necessary params
         *
         * @param
         *
         * @return void
         */
        var saveNewEdf = function() {
            if(0 === $(featuresContainer+' .alert-danger').length){
                createUnsavedContentpop(featuresContainer+' div.panel-heading');
            }
            // trimming
            var internalTexts = CompareTextDetailsController.getInstance().getDescriptionTexts(),
                alertBlock = createAlertBlock(),
                data = new Object({
                    "GroupId": $(globalTypesDatatable).data('groupid'),
                    "Name": $('.basicdata-detailchart').find('[data-name="name"]').text(),
                    "dlc": $('.basicdata-detailchart').find('[data-name="airport"]').text(),
                    "Id": $('.basicdata-detailchart').find('[data-name="giataid"]').text(),
                    "descriptions": internalTexts,
                    "globaltypes": globalTypeObjects
                });

            if(0 < $('.basicdata-detailchart').find('[data-name="key"]').length){
                data.key = $('.basicdata-detailchart').find('[data-name="key"]').text();
            }
            var loaderTargetContainer = $(featuresContainer);
            //var loaderId = loader.add(loaderTargetContainer);

            $.ajax({
                url: Routing.generate("cms_contentstore_create_edf_send", {data: data}),
                beforeSend  : function(e, xhr, options) {
                    e.loaderKey = "saveNewEdf";
                    loader.startLoader(e.loaderKey, loaderTargetContainer);
                }
            })
                .done(function() {
                    if(0 < $(featuresContainer+' .unsavedcontentpop').length){
                        CmsContentStore.getInstance().doUnregisterWarningMessage("featureUpdates");
                    }
                    if(0 === $(featuresContainer+' .alert').length) {
                        alertBlock.insertAfter(featuresContainer+' div.panel-heading');
                        alertBlock.hide().slideDown();
                    }
                    $(globalTypesDatatable).DataTable().ajax.reload(function() {
                        loader.remove(loaderId, loaderTargetContainer);
                    }, false );
                    globalTypeObjects = {};
                })
                .fail(function() {
                    loader.remove(loaderId, loaderTargetContainer);
                });
        };

        /**
         * function adds/deletes globaltypes in an existing internal edf
         *
         * @param internalkey string     internal edf key
         * @param action string          action route for updating or deleting globaltype in existing internal edf
         *
         * @return void
         */
        var updateGlobalTypeByInternalKey = function(internalkey, action) {
            var alertBlock = createAlertBlock(),
                data = new Object({
                    "key": internalkey,
                    "action": action,
                    "globaltypes": globalTypeObjects
                });
            var loaderTargetContainer = $(featuresContainer);
            var loaderId = loader.add(loaderTargetContainer);

            // ajax call redundant - need revisiting
            $.ajax(Routing.generate("cms_contentstore_updateglobaltype", data))
                .done(function() {
                    if(0 < $(featuresContainer+' .unsavedcontentpop').length){
                        CmsContentStore.getInstance().doUnregisterWarningMessage("featureUpdates");
                    }

                    if(0 === $(featuresContainer+' .alert').length) {
                        alertBlock.insertAfter(featuresContainer+' div.panel-heading');
                        alertBlock.hide().slideDown();
                    }
                    $(globalTypesDatatable).DataTable().ajax.reload(function() {
                        loader.remove(loaderId, loaderTargetContainer);
                    }, false );
                    globalTypeObjects = {};
                })
                .fail(function(){
                    loader.remove(loaderId, loaderTargetContainer);
                });
        };

        return {
            init: function(){
                isFeatureTableInit = false;
                isGroupedFeatureTableInit = false;
                isGlobaltypeTableInit = false;
                loader = Loader.getInstance();

                createGTHeaderColumn();
                if (0 < $(featuresDatatable).length) {
                    initProductFeatureDataTable();
                } else {
                    initGroupFeaturesDatatable();
                }

                $('#FTab').on('click',function(){
                    if (0 < $(featuresDatatable).length) {
                        if(!isFeatureTableInit) {
                            initProductFeatureDataTable();
                        }
                    } else {
                        //$('#SaveGTButton').hide();
                        if(!isGroupedFeatureTableInit) {
                            initGroupFeaturesDatatable();
                        }
                    }
                });
                $('#GtypeTab').on('click',function(){
                    //$('#SaveGTButton').show();
                    if(!isGlobaltypeTableInit) {
                        initGroupTypeDataTable();
                    }
                });
                // $('#SaveGTButton').on('click', onSaveGTHandler );

                $(featuresContainer).on('change', '.panel-gt-form  input', onMasterCheckChangeHandler );
            }
        };
    }
    return {
        getInstance: function() {
            if (!instance) {
                instance = new createInstance();
            }
            return instance;
        }
    };
})();